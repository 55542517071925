import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";

import moment from 'moment'
import { css } from '@emotion/core';
import Select from 'react-select';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { paramTesoreria, tesoreria } from 'store/Apis'

import { pdf } from "@react-pdf/renderer";
import { reporteCheques } from "./ReporteCheques";
// import { NumeroALetras } from "views/efectivo/conversor";
import { NumeroALetras } from "views/efectivo/conversor2";

import { saveAs } from 'file-saver';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ConsultaCheques extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'cheques',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      fechaini: moment(moment().startOf('week').toDate()).format('YYYY-MM-DD'),
      fechafin: moment(moment().endOf('week').toDate()).format('YYYY-MM-DD'),
      isLoading: true,
      isLoading2: false,
      isChequera: false,
      base: "",
      marcador: 0,
      periodo: "",
      numero: "",
      years: [],
      monto: ""
    }
    this.movimientos = []

    this.GetActionFormat= this.GetActionFormat.bind(this);

    this.handleFechaini = this.handleFechaini.bind(this);
    this.handleFechafin = this.handleFechafin.bind(this);
    this.handlePeriodo = this.handlePeriodo.bind(this);
    this.handleNumero = this.handleNumero.bind(this);
    this.handleMonto = this.handleMonto.bind(this);

  }

  handleFechaini(event) {
    this.setState({fechaini: event.target.value});
  }

  handleFechafin(event) {
    this.setState({fechafin: event.target.value});
  }

  handlePeriodo = periodo => {
    this.setState(
    { periodo },
    );
  };

  handleNumero(event) {
    this.setState({numero: event.target.value});
  }

  handleMonto(event) {
    this.setState({monto: event.target.value});
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              if(localStorage.getItem("fechasmoviche") !== null){
                  this.setState({
                    fechaini: JSON.parse(localStorage.getItem('fechasmoviche')).fechaini,
                    fechafin: JSON.parse(localStorage.getItem('fechasmoviche')).fechafin
                  });
              }
              if(paramTesoreria.cheques[0].fechaini == this.state.fechaini && paramTesoreria.cheques[1].fechafin == this.state.fechafin){
                let fechas = {
                  "fechaini": this.state.fechaini,
                  "fechafin": this.state.fechafin
                }
                localStorage.setItem('fechasmoviche', JSON.stringify(fechas))
                this.movimientos = paramTesoreria.cheques[2].data
                this.setState({isLoading: false})
              }else{
                this.listarMovimientos();
              }
              this.generarPeriodos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  generarPeriodos(){
    let year =  2000;
    let cont = 26;
    let array = Array.from(new Array(cont),(val, index) => index + year);
    let cont2 = 0;
    let lista = [];
    array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
            this.setState({
              periodo: {value: cont2, label: data}
            })
        }
    })
    this.setState({years: lista})
  }

  validaCadena(numero){
    if(numero.length == 1){
      return "0000" + numero
    }else if(numero.length == 2){
      return "000" + numero
    }else if(numero.length == 3){
      return "00" + numero
    }else if(numero.length == 4){
      return "0" + numero
    }else if(numero.length == 5){
      return numero
    }else{
      return "00000"
    }
  }

  async listarMovimientos() {
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${tesoreria}` + 'cheques/listCheques?fechaini=' + this.state.fechaini + '&fechafin=' + this.state.fechafin)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { 
                item: data.item,
                numero: data.cnum_numero, 
                nombre: data.ctxt_nombre,
                concepto: data.ctxt_concepto,
                monto: data.ctxt_monto != null ? 'S/.    ' + data.ctxt_monto.toFixed(2) : 'S/. 0.00', 
                liquido: data.ctxt_monto != null ? 'S/.    ' + data.ctxt_monto.toFixed(2) : 'S/. 0.00', 
                fecha: Calendar.calendario12(data.dfec_ingreso)
            })
          })
          let fechas = {
            "fechaini": this.state.fechaini,
            "fechafin": this.state.fechafin
          }
          localStorage.setItem('fechasmoviche', JSON.stringify(fechas))
          paramTesoreria.cheques[0].fechaini = fechas.fechaini
          paramTesoreria.cheques[1].fechafin = fechas.fechafin
          paramTesoreria.cheques[2].data = lista
          localStorage.setItem('data', JSON.stringify(paramTesoreria))
          this.movimientos = lista;
          this.setState({isLoading: false})
        }else{
          this.movimientos = [];
          this.setState({isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async buscarMovimiento() {
    if(this.state.numero != "" && this.validaCadena(this.state.numero) != "00000"){
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${tesoreria}` + 'comprobantes/buscarMovimiento?anio=' + this.state.periodo.label + '&numero=' + this.validaCadena(this.state.numero) + '&tipo=0')
      .then((res) => {
        let lista = res.data.response;
        let contador = this.movimientos.length + 1
        let montaso = this.state.monto
        if(lista != null){
          lista.map((data) => {
            this.movimientos.push(
              { 
                item: contador,
                numero: data.cnum_numero, 
                nombre: data.ctxt_nombre,
                concepto: data.ctxt_concepto,
                monto: montaso != "" ? 'S/.    ' + Number(montaso).toFixed(2) : data.cnum_liquido != null ? 'S/.    ' + data.cnum_liquido.toFixed(2) : 'S/. 0.00', 
                liquido: data.cnum_liquido != null ? 'S/.    ' + data.cnum_liquido.toFixed(2) : 'S/. 0.00', 
                fecha: Calendar.calendario12(data.dfec_ingreso)
            })
          })
          this.setState({isLoading: false})
        }else{
          this.movimientos = [];
          this.setState({isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async limpiar(){
    this.setState({isLoading: true})
    setTimeout(
      function() {
        this.movimientos = []
        this.setState({isLoading: false})
        this.props.handleClick("tl", "warning", "Se han removido los cheques seleccionados!", "pe-7s-info")
      }
      .bind(this),
      1000
    );
  }

  async remover(data){
    this.setState({isLoading: true})
    setTimeout(
      function() {
        let lista = this.movimientos.filter((param) => param.item !== data.item);
        this.movimientos = []
        let contador = 1
        lista.map((data) => {
          this.movimientos.push(
            { 
              item: contador,
              numero: data.numero, 
              nombre: data.nombre,
              concepto: data.concepto,
              monto: data.monto, 
              liquido: data.liquido, 
              fecha: data.fecha
          })
          contador ++
        })
        this.setState({isLoading: false})
        this.props.handleClick("tl", "warning", "Se ha removido el cheque seleccionado!", "pe-7s-info")
      }
      .bind(this),
      1000
    );
  }

  async detalle(){
    if(this.node.selectionContext.selected.length > 0){
      this.setState({isLoading2: true})
      let lista = this.node.selectionContext.selected;
      let lista2 = [];
      setTimeout(
        function() {
        lista.map((data) => {
            for(var i = 0; i < this.movimientos.length; i++){
              if(this.movimientos[i].item == data){
                let monto = "";
                if(this.movimientos[i].monto == 'S/. 0.00'){
                  monto = this.movimientos[i].monto.replace('S/. ', '');
                }else{
                  monto = this.movimientos[i].monto.replace('S/.    ', '');
                }
                let fecha2 = this.movimientos[i].fecha.replace('-', ' ');
                fecha2 = fecha2.replace('-', ' ');
                let money = "";
                if(monto != "0" && monto != "0.00"){
                  money = NumeroALetras(monto).replace('SOLES', '')
                }else{
                  money = "CERO"
                }
                lista2.push(
                  { 
                    numero: this.movimientos[i].numero, 
                    nombre: this.movimientos[i].nombre,
                    concepto: this.movimientos[i].concepto,
                    liquido: monto,
                    liquido2: money,
                    fecha: fecha2,
                    fecha2: 'LIMA, ' + fecha2
                })
              }
            }
        });
        this.generarReporte(lista2);
        }
        .bind(this),
        2000
      );
    }else{
      this.props.handleClick("tl", "warning", "Debe seleccionar un cheque a emitir!", "pe-7s-info")
    }
  }

  async generarReporte(movimientos){
    const blob = pdf(reporteCheques(movimientos)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data, marcador: 0, isLoading2: false});
          }
        }
        return result;
    }); 
  }

  zfill(liquido, width) {
    var zero = " ";
    var cont = 12 - width;
    return zero.repeat(cont) + liquido;
  }

  createFile(){
    this.setState({isLoading: true})
    // let cheques = '';
    // let espaciado = '\n\n\n\n\n\n\n\n\n\n\n\n';
    // for (var i = 0; i < this.movimientos.length; i++) {
    //   let monto = "";
    //   console.log(this.movimientos)
    //   if(this.movimientos[i].monto == 'S/. 0.00'){
    //     monto = this.movimientos[i].monto.replace('S/. ', '');
    //   }else{
    //     monto = this.movimientos[i].monto.replace('S/.    ', '');
    //   }
    //   let liquido = monto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //   if(liquido.length < 12){
    //     liquido = this.zfill(liquido, liquido.length);
    //   }
    //   let fecha2 = this.movimientos[i].fecha.replace('-', ' ');
    //   fecha2 = 'LIMA, ' + fecha2.replace('-', ' ');
    //   let money = "";
    //   if(monto != "0" && monto != "0.00"){
    //     money = NumeroALetras(monto).replace('SOLES', '')
    //   }else{
    //     money = "CERO"
    //   }
    //   let cheque = ' ' + this.movimientos[i].fecha.substring(0,2) + '  ' + this.movimientos[i].fecha.substring(3,5) + '  ' + this.movimientos[i].fecha.substring(6,10) + '                                             ' + fecha2.substring(0,5) + '  ' + fecha2.substring(6,8) + '  ' + fecha2.substring(9,11) + '  ' + fecha2.substring(12,16) + ' ' + liquido + '\n\n' + 
    //   '                          ' + this.movimientos[i].nombre + '\n' + 
    //   ' ' + liquido + '\n' +
    //   '                       ' + money + '\n\n' +   
    //   ' CP  :  ' + this.movimientos[i].numero;
    //   if(i != this.movimientos.length - 1){
    //     cheques = cheques + cheque + espaciado
    //   }else{
    //     cheques = cheques + cheque;
    //   }
    // }                    
    // const blob = new Blob([cheques], { type: 'text/plain; charset=utf-8'})
    // saveAs(blob, 'Cheques.txt')
    setTimeout(
      function() {
        window.open("generador://")
        // this.movimientos = []
        this.setState({isLoading: false})
      }
      .bind(this),
      1000
    );
  }

  generar(){
    if(this.state.isChequera == true){
      this.setState({isChequera: false})
    }else{
      this.setState({isChequera: true})
    }
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
            <p style={{fontSize: 13}}>N° {row.numero}</p>
            <p style={{fontSize: 13}}>{row.nombre}</p>
            <p style={{fontSize: 13, fontWeight: 200}}>CONCEPTO: {row.concepto}</p>
            <p style={{fontSize: 13, fontWeight: 200}}>TOTAL: {row.liquido}</p>
            {/* <p style={{fontSize: 13, fontWeight: 500}}>MONTO CHEQUE: {row.monto}</p> */}
            <p style={{fontSize: 13, fontWeight: 200}}>{row.fecha}</p>
            {/* <p onClick={() => { this.remover(row)}} style={{fontSize: 13, fontWeight: 200, cursor: "pointer", color: "red"}}>Remover Cheque</p> */}
          </div>
      );
    }else{}
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width: 50}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "numero", text: "COMPROBANTES", filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPage: 5,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.movimientos.length
    };
    // const selectRow = {
    //     mode: 'checkbox',
    //     clickToSelect: true
    // };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              ref={ n => this.node = n }
              data={ this.movimientos }
              columns={ columns }
              // selectRow={ selectRow }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Generados de Cheques</h4>
                <div className="row">
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE INICIO</ControlLabel>
                        <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaini}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                        <FormControl type="date" value={this.state.fechafin} onChange= {this.handleFechafin}/>
                    </div>
                    <div className="col-md-6">
                        <ControlLabel>ACCIONES</ControlLabel><br></br>
                        <Button style={{width: 200, marginBottom: 10, marginRight: 15}} bsStyle="success" fill type="submit" onClick={() => { this.listarMovimientos() }}>Buscar Cheques</Button>
                        {/* <Button style={{width: 200, marginBottom: 10, marginRight: 15}} bsStyle="warning" fill type="submit" onClick={() => { this.generar() }}>Generar Cheques</Button> */}
                        <Button style={{width: 200, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.createFile() }}>Generar Cheques</Button>
                    </div>
                    {/* <div className="col-md-2">
                        <Button style={{width: 150}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.detalle() }}>Generar Cheques</Button>
                    </div> */}
                </div>
                {this.state.isChequera ? (
                  <div className="row" style={{marginTop: 10}}>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>PERIODO</ControlLabel>
                        <Select value={this.state.periodo} onChange={this.handlePeriodo} options={this.state.years}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                      <ControlLabel>NÚMERO</ControlLabel>
                      <FormControl type="number" value={this.state.numero} onChange= {this.handleNumero}/>
                    </div>
                    <div className="col-md-4" style={{marginBottom: 18}}>
                      <ControlLabel>IMPORTE</ControlLabel>
                      <div class="input-group">
                      <FormControl type="number" value={this.state.monto} onChange= {this.handleMonto}/>
                        <span class="input-group-btn">
                          <Button style={{height: 40, width: 50, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.buscarMovimiento() }}>
                            <i className="fa fa-plus" style={{fontSize: 18, paddingTop: 2, paddingRight: 15}}></i>
                          </Button> 
                          <Button style={{height: 40, width: 50, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.limpiar() }}>
                            <i className="fa fa-trash-o" style={{fontSize: 18, paddingTop: 2, paddingRight: 15}}></i>
                          </Button> 
                        </span>
                      </div>
                    </div>
                  </div>
                    ) : (null)
                }
                {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                )}
              </div>
            </Col>
            {/* <Col md={8}>
              {this.state.isLoading2 ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading2}
                      />
                    ) : (
                      <Card
                        title="Visor de Cheques Generados"
                        content={
                        <div>
                         {this.state.marcador == 0 ? (
                            <iframe src={this.state.base} style={{height: 1195, width: "100%"}}></iframe>
                          ) : (
                            <iframe src={this.state.base} style={{height: 1195, width: "100%"}}></iframe>
                          )}
                        </div>
                        }
                      />
                 )}
            </Col> */}
            {/* <Button style={{width: 200, marginRight: 15}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.createFile() }}>Descargar Cheques</Button> */}
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ConsultaCheques;