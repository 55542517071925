import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {cec} from 'store/Apis'
import 'react-tabs/style/react-tabs.css';
import InputFiles from 'react-input-files';
import 'pell/dist/pell.css';
import pell from 'pell';

import 'react-accessible-accordion/dist/fancy-example.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class AdminEspacio extends Component {
    editorRef = React.createRef();
    editorRef2 = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      path: 'publicaciones',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      titulo : "", 
      descripcion:"",     
      archivo: "",
      ruta:"",
      file: [],
      isLoading: false,
      update:false,
      vimagen: false,
      seccion:""
    }
    this.handleTitulo = this.handleTitulo.bind(this);
    this.handleDescripcion = this.handleDescripcion.bind(this);
  }
    handleTitulo(event) {this.setState({titulo: event.target.value});}
    handleDescripcion(event) {this.setState({descripcion: event.target.value});}
   

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal()
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
  
  async validarLocal(){
    if(JSON.parse(localStorage.getItem("cec")).internacional[0].data.ctxt_parrafo != undefined ){
        this.setState({
                isLoading:true,
                titulo: JSON.parse(localStorage.getItem('cec')).internacional[0].data.ctxt_titulo ,
                descripcion: JSON.parse(localStorage.getItem('cec')).internacional[0].data.ctxt_parrafo,
                nid: JSON.parse(localStorage.getItem('cec')).internacional[0].data.nid,
                seccion:JSON.parse(localStorage.getItem('cec')).internacional[0].data.cnum_seccion,
                update:true  
            });
        if(JSON.parse(localStorage.getItem("cec")).internacional[0].data.tipo=="ver"){
            console.log("VER")
          this.setState({vimagen: true,isLoading:false})
        }else{
            console.log("editar")
          this.setState({vimagen: false,isLoading:false})
           this.obtener("D")  
        }  

    }else{
        console.log("nuevo")
        this.obtener("S")
        this.setState({    
            seccion:JSON.parse(localStorage.getItem('cec')).internacional[0].data,
        });
 
    }
  }
  handleContentChange = htmlContent => {
    this.setState({titulo:htmlContent })
  }

  handleContentChange2 = htmlContent => {
    this.setState({descripcion:htmlContent })
  }
  async validar(){
    if(this.state.titulo != ''    && this.state.descripcion != ''){
      if(this.state.update==true){
        if(this.state.file.length != 0){
         let estado = "0";
          this.actualizar(estado)
        }else{
         let  estado = "1";
           this.actualizar(estado)
        }
      }else{
         this.agregar();
      }

    }else{
      this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close") 
    }
  }

  obtener(param){ 
     
      const editor2 = pell.init({
        element: this.editorRef2.current,
        onChange: this.handleContentChange2
      });
    
      const contentElement2 = editor2.content;
      contentElement2.style.border = '1px solid grey';
      contentElement2.style.height = '1000px'; 
        if(param=="D"){
        //   const titulo = this.state.titulo;
        //   editor.content.innerHTML = titulo;
          const parrafo = this.state.descripcion;
          editor2.content.innerHTML = parrafo;
          this.setState({update:true})
        }
   
   }

  async agregar(){  
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('titulo', this.state.titulo);
    fromData.append('parrafo', this.state.descripcion);
    fromData.append('seccion', this.state.seccion);
    fromData.append('usuario',this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'internacional/insertSecciones', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Contenido registrado correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async actualizar(estad){  
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', this.state.nid);
    fromData.append('titulo', this.state.titulo);
    fromData.append('parrafo', this.state.descripcion);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'internacional/updateSecciones', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Contenido actualizado correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  regresar(){
    this.props.history.push('/secciones');
  }

  subir = (files) => {
      if(files.length !== 0){
          this.setState({file: files[0], archivo: files[0].name});
      }
  }

  async limpiar(){
    this.setState({archivo: "", file: ""})
  }

  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Administrar Sección</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                  <div>
                        <div className="row"> 
                            {this.state.vimagen==false ?
                            <div>
                              <div className="col-md-12" style={{ marginBottom:18, borderColor:"black"}}>
                                <ControlLabel >TÍTULO</ControlLabel> 
                                <FormControl componentClass="textarea" style={{  height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%", marginBottom: 18}} value={this.state.titulo} onChange= {this.handleTitulo}/>

                              </div>
                              <div className="col-md-12" >
                                    <ControlLabel >CONTENIDO</ControlLabel>  
                                  <div ref={this.editorRef2} style={{borderColor:"black", marginTop:25}}></div>
                              </div>
                            </div>
                            :
                            <div>
                              <div className="col-md-12" style={{ marginBottom:18, borderColor:"black"}}>
                                    <ControlLabel >TÍTULO</ControlLabel> 
                                    <FormControl componentClass="textarea" style={{  height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%", marginBottom: 18}} value={this.state.titulo} onChange= {this.handleTitulo} disabled/>

                              </div>
                              <div className="col-md-12" >
                                    <ControlLabel >CONTENIDO</ControlLabel>  
                                    <div dangerouslySetInnerHTML={{ __html: this.state.descripcion }} style={{marginTop:50}}/>
                              </div>
                            </div>
                            }

                            <Button style={{marginRight: 10, marginTop: 20, width:200}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                            {this.state.vimagen==false ? 
                            <Button style={{marginRight: 10, marginTop: 20, width:200}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.validar() }}>Guardar</Button>
                            : null }
                       </div>
                  </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
export default AdminEspacio;