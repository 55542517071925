import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col , Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { jurisdiccional} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import InputFiles from 'react-input-files';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Miembro extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'votaciones',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      nid:"",
      nidopcion:"",
      persona: "",
      cargo: "",
      isLoading: true,
      foto:"",
      rutafoto:"",
      archivo:"",
      file:[],
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.verimagen=this.verimagen.bind(this);
    this.handlePersona=this.handlePersona.bind(this);
    this.handleCargo=this.handleCargo.bind(this);

  }
    handlePersona(event) {this.setState({persona: event.target.value});}
    handleCargo(event) {this.setState({cargo: event.target.value});}
  
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
             this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
  async validarLocal(){
    if(JSON.parse(localStorage.getItem("jurisdiccional")).opcion[0].data !== null){
        this.setState({opcion: JSON.parse(localStorage.getItem('jurisdiccional')).opcion[0].data.ctxt_opcion,
                       nidopcion: JSON.parse(localStorage.getItem('jurisdiccional')).opcion[0].data.nidopcion});
        this.listarMiembros();
    }else{
      this.props.history.push('/opciones');
    }
  }
  async listarMiembros() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${jurisdiccional}` + 'votaciones/listMiembros?nidopcion='+ this.state.nidopcion)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                persona: data.ctxt_persona,
                cargo: data.ctxt_cargo,
                foto: data.ctxt_foto,
                rutafoto: data.ctxt_rutafoto,
                item: data.item,
                nid: data.nid
             })
          })
         this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea anular el miembro?',
      message: 'Se anulará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidmiembro', data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'votaciones/anularMiembros', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 1){
            this.listarMiembros();
            this.props.handleClick("tl", "info", "El miembro ha sido anulado!", "pe-7s-smile")
        }else{
            this.props.handleClick("tl", "error", "No se puede anular el miembro!", "pe-7s-close")
            this.setState({isLoading: false})
        }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async obtener(data){
    this.setState({
    persona: data.persona,
    cargo: data.cargo,
    archivo: data.foto,
    nid: data.nid,
    rutafoto: data.rutafoto
   })
 

  }
  
  async save(){
    if(this.state.persona != "" && this.state.cargo != "" && this.state.archivo != ""){
      if(this.state.nid == ''){
        this.guardar()
      }else{
        this.validar()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  } 

  async guardar(){  
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('file', this.state.file);
    fromData.append('imagen', this.state.archivo);
    fromData.append('persona',this.state.persona);
    fromData.append('cargo',this.state.cargo);
    fromData.append('nidopcion',this.state.nidopcion);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'votaciones/insertarMiembro', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar();         
          this.props.handleClick("tl", "info", "El miembro ha sido registrado!", "pe-7s-smile") 
  console.log(this.state.file)
     }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
   
  }
  async validar(){
    let estado = "";
    let ruta = "";
    let param1 = "";
   if(this.state.persona != "" && this.state.cargo!= "" &&  this.state.archivo!= ""){
     if(this.state.file.length != 0){
          estado = "0";
          ruta = this.state.rutafoto;
          param1 = this.state.archivo;
          this.update(estado, param1, ruta)
      }else{
          estado = "3";
          this.update(estado, this.state.archivo, this.state.rutafoto)
      } 
    }else{
     this.props.handleClick("tl", "error", "Debe completar todos los campos!", "pe-7s-close")
    }
  }

  async update(estado, param1, ruta){ 
    let fromData = new FormData();
    fromData.append('file', this.state.file);
    fromData.append('imagen', param1);
    fromData.append('persona', this.state.persona);
    fromData.append('cargo', this.state.cargo);
    fromData.append('oldrutaimagen', ruta);
    fromData.append('estado', estado);
    fromData.append('nidopcion', this.state.nidopcion);
    fromData.append('nidmiembro', this.state.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'votaciones/editarMiembro', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Miembro actualizado correctamente!", "pe-7s-close")
        this.limpiar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async limpiar(){
    this.listarMiembros();
    this.cancelar();
  }

  async cancelar(){
    this.setState({persona: "", cargo: "", nid: "",archivo:""})
  }


  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Miembro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row, "E") }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
             
              <span data-tip="Anular Miembro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }
  
  async regresar(){
    this.props.history.push('/opciones');
  }
  
  verimagen(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Ver Foto" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(row.rutafoto) }}>
               <img src={row.rutafoto} alt="" style={{width:"150px"}}></img>
              </span>
             
          </div>
      );
    }else{}
  }
  abrirImagen(link){
    window.open(link, '_blank');
  }

  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], archivo: files[0].name});
    }
  }

  async limpiar2(){
    this.setState({archivo: "", file: ""})
  }

  render() {
    const columns = [
    {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "persona", text: "NOMBRES Y APELLIDOS", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'justify'},
    {dataField: "cargo", text: "CARGO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:400}, headerAlign: (column, colIndex) => 'center', align: 'justify'},
    {dataField: "foto", text: "FOTO",  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.verimagen},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
          <Col md={12}>
            <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
             <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Miembros de : <a style={{fontWeight: "bold", color: "black",fontSize: 20}}>{this.state.opcion}</a></h4>
                <div className="row" style={{marginBottom: 10}}>
                    <div className="col-md-4" style={{marginBottom: 18}}>
                        <ControlLabel><i class="fa fa-user"></i> NOMBRES Y APELLIDOS</ControlLabel>
                        <FormControl type="text" value={this.state.persona} onChange= {this.handlePersona}/>
                    </div>
                    <div className="col-md-4" style={{marginBottom: 18}}>
                        <ControlLabel><i class="fa fa-address-book-o"></i> CARGO</ControlLabel>
                        <FormControl type="text" value={this.state.cargo} onChange= {this.handleCargo}/>
                    </div>
                    <div className="col-md-3">
                        <ControlLabel><i class="fa fa-picture-o"></i> FOTO DEL MIEMBRO</ControlLabel>
                        <Alert bsStyle="info">
                          <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo}</span>
                        </Alert>
                        <Button style={{width: '100%', marginTop: -10}} bsStyle="info" pullRight fill type="submit">
                          <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar la foto</InputFiles>
                        </Button>
                        {this.state.archivo != "" ? (
                          <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar2() }}>Limpiar Archivo</Button>
                        ) : ( null )}
                    </div>
                    <Button style={{width: 120,marginTop:20, marginBottom: 5 , marginRight: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.save() }}>Guardar</Button>
                    <Button style={{width: 120,marginTop:15, marginBottom: 5 , marginRight: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Cancelar</Button>
                </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
            </div>
            <Button style={{width: 150, marginTop:15}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>    
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Miembro;