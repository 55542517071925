import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";

import moment from 'moment'
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import Select from 'react-select';
import { paramTesoreria, tesoreria } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class AudiCajaChica extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'cajachica',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      movimientos: [],
      fechaini: moment(moment().startOf('week').toDate()).format('YYYY-MM-DD'),
      fechafin: moment(moment().endOf('week').toDate()).format('YYYY-MM-DD'),
      total: '',
      anio: '',
      isLoading: true
    }
    this.handleFechaini = this.handleFechaini.bind(this);
    this.handleFechafin = this.handleFechafin.bind(this);

  }

  handleFechaini(event) {
    this.setState({fechaini: event.target.value});
  }

  handleFechafin(event) {
    this.setState({fechafin: event.target.value});
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.obtenerTotal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async obtenerTotal() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'comprobantes/obtenerTotalxAnio')
    .then((res) => {
      let lista = res.data.response;
      if(lista != null){
        this.setState({anio: lista[0].anio, total: lista[0].total != null ? 'S/.    ' + lista[0].total.toFixed(2) : 'S/. 0.00'})
        this.listarMovimientos(0);
      }else{
        this.setState({anio: "", total: "", isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarMovimientos(param) {
      if(param == 1){
        this.setState({isLoading: true})
      }
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${tesoreria}` + 'comprobantes/listMovimientos?tipo=1&fechaini=' + this.state.fechaini + '&fechafin=' + this.state.fechafin)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { 
                item: data.item,
                nidmovimiento: data.nid,
                numero: data.cnum_numero, 
                tipocaja: data.tipocaja,
                numerocaja: data.cnum_numero_caja,
                documento: data.cnum_documento,
                nombre: data.ctxt_nombre,
                concepto: data.ctxt_concepto,
                liquido: data.cnum_liquido,
                totalgastos: data.cnum_total_gastos != null ? 'S/.    ' + data.cnum_total_gastos.toFixed(2) : 'S/. 0.00', 
                fecha: Calendar.calendario12(data.dfec_ingreso),
                meta: data.meta,
                area: data.area,
                responsable: data.responsable,
                activo: data.item == 1 ? '1' : '0'
            })
          })
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Movimiento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              {/* {row.activo == '1' ? (
              <span data-tip="Anular Movimiento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)} */}
          </div>
      );
    }else{}
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "numero", text: "NÚMERO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipocaja", text: "EMISIÓN", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "documento", text: "DOCUMENTO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nombre", text: "PROVEEDOR", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "totalgastos", text: "TOTAL", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha", text: "FECHA", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Movimientos de Fondos para Pagos en Efectivo</h4>
              <h5 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Búsqueda por Rango de Fechas</h5>
              <div className="row">
                <div className="col-md-2" style={{marginBottom: 18}}>
                    <ControlLabel>FECHA DE INICIO</ControlLabel>
                    <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaini}/>
                </div>
                <div className="col-md-2" style={{marginBottom: 18}}>
                    <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                    <FormControl type="date" value={this.state.fechafin} onChange= {this.handleFechafin}/>
                </div>
                <div className="col-md-5" style={{marginBottom: 18}}>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 200, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.listarMovimientos(1) }}>Buscar Movimientos</Button>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>TOTAL PERIODO ACTUAL {this.state.anio}</ControlLabel>
                    <FormControl type="text" value={this.state.total} disabled/>
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AudiCajaChica;