import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col, Thumbnail } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, fechasBusqueda} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactExport from 'react-export-excel'


const ExcelFile = ReactExport.ExcelFile; // indica el archivo excel que vamos a crear
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;//nombre de hoja de excel
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;//nombre de las columnas de excel

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ListadoBandejas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: 'utilitariosStd',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      anio: '',     
      years: [],
      areas:[],
      area:"",
      bandeja: {value:"I", label :"INGRESADOS"},
      bandejas:[
        {value:"I", label :"INGRESADOS"},
        {value:"G", label :"GENERADOS"},
        {value:"P", label :"PENDIENTES"},
        {value:"D", label :"DERIVADOS"},
        {value:"F", label :"FINALIZADOS"},
      ],
      
       datosColum:[],
       columnas:[{dataField: "", text: "NO HAY REGISTROS",  headerStyle: {backgroundColor: '#337AB7', color: "white",width:50}, headerAlign: (column, colIndex) => 'center', align: 'center'}],
       isLoading: true,
       t: []
     
    }
 
 }
  handleAnio = anio => {
    this.setState(
    { anio },
    );
  };

  handleArea = area => {
    this.setState(
    { area },
    );
  };

  handleBandeja = bandeja => {
    this.setState(
    { bandeja },
    );
  };
  
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                
               this.generarPeriodos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
  generarPeriodos(){
    let year =  2000;
    let cont = 26;
    let array = Array.from(new Array(cont),(val, index) => index + year);
    let cont2 = 0;
    let lista = [];
   if(fechasBusqueda.contador[0].nidperiodo != "" && fechasBusqueda.contador[1].periodo != ""){
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
      })
      this.setState({anio: {
        value: fechasBusqueda.contador[0].nidperiodo, 
        label: fechasBusqueda.contador[1].periodo
      }})
    }else{
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
          this.setState({anio: {value: cont2, label: data}})
        }
      })
    }
    this.setState({years: lista})
    this.area()
 }

 async area() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'documentario/listAreaAutorizacion')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.nid_area,
                label: data.ctxt_descripcion,
            })
          });
          this.setState({areas: lista,area: {value: lista[0].value, label: lista[0].label}})
          this.listBandejas()
        }else{
        this.setState({areas: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
   }




  async listBandejas() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'documentario/listBandejas?anio=' + this.state.anio.label + '&area=' + this.state.area.value + '&bandeja=' + this.state.bandeja.value)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        this.state.datosColum = res.data.response;  
        if(lista2 != null){
          lista2.map((data) => {  
            lista.push({             
              tramite: data.tramite,
              asunto: data.asunto== null ? "--": data.asunto ,
              documento: data.documento,
              remitente:data.remitente,
              fecha: data.fecha,
              item:data.item,
              destinatarios: data.destinatarios == null ? "--": data.destinatarios ,
              observacion:data.observacion == null ? "--": data.observacion ,
              motivo: data.motivo == null ? "--": data.motivo ,
              recepcion:data.recepcion== null ? "--": data.recepcion
            })
          })
          this.setState({movimientos: lista, isLoading: false})
          this.cargarColumnas()
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        
        this.setState({isLoading: false})
      }
    });
  }

  async regresar(){
    this.props.history.push('/utilitariosStd');
  }

  cargarColumnas(){
    if(this.state.datosColum.length != 0){
        let e = [];   
        let d =[]
        d = Object.keys(this.state.datosColum[0])  
        const a = [
        d.find( x => x == 'item'), d.find( x => x == 'tramite'), d.find( x => x == 'documento'),d.find( x => x == 'asunto'), 
        d.find( x => x == 'remitente'), d.find( x => x == 'destinatarios'),d.find( x => x == 'obervacion'), d.find( x => x == 'motivo'),
        d.find( x => x == 'fecha'), d.find( x => x == 'recepcion')
        ]      
        this.state.t = a.filter(word => word != undefined) 
        this.state.t.map((nom) =>{
          e.push(
            {dataField: nom, text: nom, filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"},
            headerAlign: (column, colIndex) => 'center', align: 'center'}
          )
        })  
        this.setState({columnas : e})
    }else{
      this.state.columnas =[
        {dataField: "item", text: "NO HAY REGISTROS",  headerStyle: {backgroundColor: '#337AB7', color: "white",width:50}, headerAlign: (column, colIndex) => 'center', align: 'center'}
      ]
    }}


  render() {
     const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ this.state.columnas}
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }   
            />           
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>LISTADO DE BANDEJAS</h4>
                <div className="row">
                    <div className="col-md-6" style={{marginBottom: 18}}>
                        <ControlLabel>AREA</ControlLabel>
                        <Select value={this.state.area} onChange={this.handleArea} options={this.state.areas}/>
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel>BANDEJA</ControlLabel>
                        <Select value={this.state.bandeja} onChange={this.handleBandeja} options={this.state.bandejas} />
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel>PERIODO</ControlLabel>
                        <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel></ControlLabel><br></br>
                        <Button style={{width: 230, marginTop: 5, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.listBandejas() }}>Buscar Bandejas</Button>
                    </div>
                   
                </div>
                {this.state.isLoading ? (
                    <div>
                        <div className="row text-center">
                        <h4>CARGANDO BANDEJA</h4>
                        <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading}
                        />
                        <p>Este proceso puede durar unos minutos</p>
                        <h5>Espere por favor...</h5>
                        </div>
                    </div>
                ) : (
                    <div>
                    {this.state.movimientos.length > 0 ? 
                    <ExcelFile element={<Button style={{backgroundColor:"green" ,border:0,width: 250,height:40,marginBottom:5, marginTop:25}} fill  type="submit">Exportar a Excel</Button>} filename= {"BANDEJA - "  + this.state.bandeja.label + 
                    " DEL AREA  '" +this.state.area.label + "' DEL AÑO  " + this.state.anio.label}>
                      <ExcelSheet data={this.state.movimientos}  name ={ this.state.bandeja.label + " - " + this.state.anio.label + " - " + this.state.area.label}>
                          <ExcelColumn label="ITEM" value="item"></ExcelColumn>
                          <ExcelColumn label="N° TRAMITE" value="tramite" ></ExcelColumn>
                          <ExcelColumn label="ASUNTO" value="asunto" ></ExcelColumn>
                          <ExcelColumn label="N° DOCUMENTO" value="documento" ></ExcelColumn>
                          <ExcelColumn label="REMITENTE" value="remitente" ></ExcelColumn>
                          <ExcelColumn label="FECHA DE CREACION" value="fecha" ></ExcelColumn>
                          <ExcelColumn label="DESTINATARIO" value="destinatarios" ></ExcelColumn>
                          <ExcelColumn label="FECHA DE RECEPCION" value="recepcion" ></ExcelColumn>
                          <ExcelColumn label="OBSERVACION" value="observacion" ></ExcelColumn>
                          <ExcelColumn label="MOTIVO" value="motivo" ></ExcelColumn>
                        </ExcelSheet>
                    </ExcelFile>   
                  : null  }
                        <PaginationProvider
                            pagination={
                            paginationFactory(options)
                            }>
                            { contentTable }
                        </PaginationProvider>
                        <Button style={{width: 250, marginTop: 18}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                    </div>
                )}
   
              </div>

            </Col>

          </Row>
        </Grid>
      </div>
    );
  }
}

export default ListadoBandejas;