import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import axios from 'axios';
import { gestor,  sisperplus } from 'store/Apis'
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import InputFiles from 'react-input-files';
import Select from 'react-select';
import moment from 'moment';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { validator } from 'store/Apis'
import "react-confirm-alert/src/react-confirm-alert.css";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class NuevoDescansoMedico extends Component {

constructor(props) {
super(props);
  this.state = {
    path: 'administrarDesMedicos',
    dni: localStorage.getItem('dni'),
    usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
    id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
    token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
    fechaini: "",
    fechafin:"",
    descanso: "",
    descansos: [],
    type:"",
    file: [],
    fileName: '',
    diasM:[],  
    dias:"0",
    isLoading: false,

    dni2: "",
    nombre:"",
    idpersona:"",
    diagnostico:"",
    centro:"",
    medico:"",
    diasUtilizados:"",
    entidad:"",
    seguro:"",
    observacion:"",
    contingencia:[{value:"1", label: "ENFERMEDAD"},{value:"2", label: "MATERNIDAD"}],
    selectedContingencia:{value:"1", label: "ENFERMEDAD"},
  }
  this.handleDNI = this.handleDNI.bind(this);
  this.handleFechaIni = this.handleFechaIni.bind(this);
  this.handleFechaFin = this.handleFechaFin.bind(this);
  this.handleDiagnostico = this.handleDiagnostico.bind(this);
  this.handleCentro = this.handleCentro.bind(this);
  this.handleMedico = this.handleMedico.bind(this);
  this.handleDias= this.handleDias.bind(this);
  this.handleObservacion = this.handleObservacion.bind(this);
}
  handleDNI(event) {this.setState({dni2: event.target.value});}
  handleDias(event) {this.setState({dias: event.target.value});}
  handleFechaIni(event) {this.setState({fechaini: event.target.value});}
  handleFechaFin(event) {this.setState({fechafin: event.target.value});}
  handleDiagnostico(event) {this.setState({diagnostico: event.target.value});}
  handleCentro(event) {this.setState({centro: event.target.value});}
  handleMedico(event) {this.setState({medico: event.target.value});}
  changeContingencia = selectedContingencia =>{ this.setState({selectedContingencia}, () => this.cargaView());}
  handleObservacion(event) {this.setState({observacion: event.target.value});}

  handleChange = descanso => {
      this.setState(
      { descanso },
      );
  };


  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
              this.validarLocal();  
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  async validarLocal(){ 
    if(JSON.parse(localStorage.getItem("recursoshumanos")).trabajadoresAdm[0].data !== null){
          this.setState({
                  idpersona: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.person,
                  dni2: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.documentNumber,
                  nombre: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.nombreCompleto,
                  ruta: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.ruta,
                  enlace: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[1].enlace,
          });
          this.cargaView();     
    }}


  async cargaView() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    //axios.get(`${sisperplus}` + 'descanso/listTipoDescanso')
    axios.get(`${sisperplus}` + 'descanso/listTipoDescansoxContingencia?tipo=1&idcontingencia=' + this.state.selectedContingencia.value)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.MedicalRestType,
                label: data.Description,
            })
          });
          this.setState({descansos: lista, descanso :{value : lista[0].value,label : lista[0].label },isLoading: false})    
      }else{
        this.setState({descansos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
   }

   onKeyUpValue() {
    if(this.state.dni2.length == 8){
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'papeletaAdm/listPerson?dni=' + this.state.dni2)
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
          if(lista2 != null){
            lista2.map((data) => {
              lista.push(
                { nombres: data.Name,
                  idpersona : data.person
                })
            });
              if(lista[0] == null ){       
                  this.props.handleClick("tl", "error", "El DNI ingresado no se encuentra registrado!", "pe-7s-info")
                  this.setState({nombre: "", idpersona: "", isLoading: false})
              }else{
                  this.setState({nombre: lista[0].nombres, idpersona: lista[0].idpersona, isLoading: false})     
              }
          }else{
            this.props.handleClick("tl", "error", "Error de conexión!", "pe-7s-info")
          }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }else if(this.state.dni2.length > 8){
        this.props.handleClick("tl", "error", "El DNI ingresado tiene más de 8 dígitos!", "pe-7s-info")
    }else {
      this.setState({nombre: "",idpersona:""})
    }
  }

  cerrar(){
    this.setState({visFile: false});
  } 

  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], fileName: files[0].name, type: files[0].type, visFile: true});
    }
  }

  async cancelar(){
    if(this.state.enlace == "DT"){
      this.props.history.push('/administrarDesMedicos');
    }else{ 
      this.props.history.push('/verDescansoTrabajador');
    }
   
  }

  validar(){
    if(this.state.type == 'application/pdf' || this.state.type == 'image/jpeg' || this.state.type == 'image/png'){

      if(this.state.diagnostico != '' && this.state.medico != '' && this.state.centro != '' && this.state.dni2 !=  '' && this.state.nombre != '' && this.state.fechafin != "" && this.state.fechaini!=""){
          
          const ndias20 = Number.parseInt(this.state.dias) + Number.parseInt(this.state.diasUtilizados)            
          if(this.state.descanso.value == 'LIMABGT 000000000016' && ndias20 >= 21){              
            //if(ndias20 >= 21){                
              this.props.handleClick("tl", "warning", "Registro no permitido por haber llegado a los 20 primeros días de descando médico. ¡Comuníquese con personal de RRHH!", "pe-7s-info")    
              return;
            //}
          }

          if(ndias20 <= 20 && this.state.descanso.value != 'LIMABGT 000000000016' ){
            this.props.handleClick("tl", "warning", "Registro no permitido, no ha superado los 20 primeros días de descanso médico", "pe-7s-info")    
            return;
          }

         this.verificarDM()
                  
      }else{
        this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
      }
    }else{this.props.handleClick("tl", "warning", "El archivo a subir debe ser un pdf o una imagen!", "pe-7s-info")
    }

  }

  async verificarDM() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'descanso/verificarExistenciaDM?idpersona=' + this.state.idpersona + "&fechaini=" + this.state.fechaini + "&fechafin=" + this.state.fechafin)
    .then((res) => {
      let lista = res.data.response;
      if(lista[0].total == 0){
        this.guardar()
      }else{
        this.props.handleClick("tl", "warning", "Existe un descanso médico vigente para la fecha ingresada!", "pe-7s-info")                         
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

    guardar(){
      this.setState({isLoading: true})
      let fromData = new FormData();
      fromData.append('persona', this.state.idpersona);
      fromData.append('tipodescanso', this.state.descanso.value);
      fromData.append('fechainicio', this.state.fechaini);
      fromData.append('fechafin', this.state.fechafin);
      fromData.append('dia', this.state.dias);
      fromData.append('usuario', this.state.usuario);
      fromData.append('centro', this.state.centro.toUpperCase());
      fromData.append('medico', this.state.medico.toUpperCase());
      fromData.append('diagnostico', this.state.diagnostico.toUpperCase());
      fromData.append('observacion', this.state.observacion.toUpperCase());
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${sisperplus}` + 'descanso/insertDescansoMedico', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){         
          axios.defaults.headers.common['Authorization'] = this.state.token
          axios.get(`${sisperplus}` + 'descanso/listTop?person=' + this.state.idpersona)
          .then((res) => {
            let lista = res.data.response;
              if(this.state.file != ''){
                this.subirArchivo(lista[0].Person,lista[0].line)
              }else{
                this.props.handleClick("tl", "info", "Ha sido registrado con éxito!", "pe-7s-smile")
                this.cancelar()                   
              }
          })
          .catch((error) => { 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              this.setState({isLoading: false})
            }
          });
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });         
       
    }

    subirArchivo(person,line){
      let fromData = new FormData();
      fromData.append('persona', person);
      fromData.append('line', line);
      fromData.append('nombreFile', this.state.fileName);
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${sisperplus}` + 'descanso/insertArchivo', fromData)
      .then((response) => {
        var datos = response.data.response 
        console.log()
        if(datos.archivo != null){
          this.uploadArchivo(datos.archivo)     
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al subir archivo!", "pe-7s-close")
          this.setState({isLoading: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
    }

  uploadArchivo(archivo){
      let anio = new Date().getFullYear()
      let mes = parseInt(new Date().getMonth()) + 1
      if(mes < 10){mes = '0'+ mes}
      let fromData = new FormData();
      fromData.append('usuario', this.state.usuario);
      fromData.append('archivo_file', this.state.file);
      fromData.append('anio', anio);
      fromData.append('mes', mes);
      fromData.append('cnomArchivo', archivo);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${gestor}` + 'document/subirArchivoDes', fromData)
      .then((response) => {
        if(response.data.result == null){
          this.props.handleClick("tl", "info", "Ha sido registrado con éxito!", "pe-7s-smile")
          this.cancelar(); 
                
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al guardar archivo!", "pe-7s-close")
          this.setState({isLoading2: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  verificarI(param){
    /*console.log(param)
      let fromData = new FormData();
          fromData.append('fechainicio', param);
          fromData.append('fechafin', this.state.fechafin== "" ? param :this.state.fechafin);
          axios.defaults.headers.common['Authorization'] = this.state.token
          axios.post(`${sisperplus}` + 'descanso/diasHabiles', fromData)
          .then((response) => {
            var datos = response.data.response 
            this.setState({ fechaini:param, dias : datos.codigo})
            this.listDias(datos.codigo)
            //this.setState({ fechaini:param, dias : datos.codigo})
            if(this.state.fechafin=="" ){
              this.setState({ fechafin:param})
            }
          })  
          .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              this.setState({isLoading: false})
            }
          });*/

    if(this.state.fechafin<param){
      var fecha1 = moment(param);
      var fecha2 = moment(param);
      var dia = (fecha2.diff(fecha1, 'days'))+1;
      this.setState({dias: dia, fechaini:param,fechafin:param})
    }else{
      var fecha1 = moment(param);
      var fecha2 = moment(this.state.fechafin);
      var dia = (fecha2.diff(fecha1, 'days'))+1;
      this.setState({dias: dia, fechaini:param})
    }
    this.listDias(param,dia)
  }


  verificarF(param){
      /*let fromData = new FormData();
          fromData.append('fechainicio', this.state.fechaini);
          fromData.append('fechafin', param);
          axios.defaults.headers.common['Authorization'] = this.state.token
          axios.post(`${sisperplus}` + 'descanso/diasHabiles', fromData)
          .then((response) => {
            var datos = response.data.response 
            this.listDias(datos.codigo)
            this.setState({ fechafin:param,dias : datos.codigo})
          })  
          .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              this.setState({isLoading: false})
            }
          }); */
    var fecha1 = moment(this.state.fechaini);
    var fecha2 = moment(param);
    var dia = (fecha2.diff(fecha1, 'days'))+1;
    this.setState({dias: dia, fechafin:param})
    this.listDias(this.state.fechaini,dia)
  }





  async listDias(fecini,dia) {
  
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${sisperplus}` + 'descanso/listConsultarDM?persona=' + this.state.idpersona +'&dia='+dia + '&fechainicio=' +fecini)
      .then((res) => {
          let lista = [];
          let lista2 = res.data.response;
          if(lista2 != null){
            lista2.map((data) => {
              lista.push(
                { 
                  diasUtilizados:data.diasUtilizados,
                  entidad:data.entidad,
                  seguro:data.seguro
              })
            });
            this.setState({diasUtilizados: lista[0].diasUtilizados,entidad: lista[0].entidad, seguro: lista[0].seguro,     isLoading: false})
            
        }else{
          this.setState({diasM: [], isLoading: false})
          this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      }); 
   
    
  }

  async limpiar(){
    this.setState({fileName: "", file: ""})
  }
  
  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
            <Card
                title="Registrar Descanso Médico"
                content={
                  this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                  <div>
                    <div className="row">
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>INGRESE EL DNI</ControlLabel>
                            <FormControl  type="number" value={this.state.dni2} onChange={this.handleDNI} onKeyUp={this.onKeyUpValue.bind(this)} disabled={this.state.enlace == "T"}/>
                        </div>
                        <div className="col-md-9" style={{marginBottom: 18}}>
                            <ControlLabel>NOMBRE Y APELLIDOS</ControlLabel>
                            <FormControl type="text" value={this.state.nombre} disabled/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>CONTINGENCIA</ControlLabel>
                            <Select value={this.state.selectedContingencia} onChange={this.changeContingencia} options={this.state.contingencia}/>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>TIPO DE DESCANSO MÉDICO</ControlLabel>
                            <Select value={this.state.descanso} onChange={this.handleChange} options={this.state.descansos}/>
                        </div>
                        <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DE INICIO</ControlLabel>
                            <FormControl type="date" value={this.state.fechaini} onChange= {(e) => this.verificarI(e.target.value)}  />
                        </div>
                        <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                            <FormControl type="date" value={this.state.fechafin} onChange= {(e) => this.verificarF(e.target.value)}  min={this.state.fechaini}/>
                        </div>
                        <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>DÍAS DE DESCANSO</ControlLabel>
                            <FormControl type="number" value={this.state.dias} onChange= {this.handleDias}     disabled/>
                        </div>
                    </div>                    
                  <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>CENTRO MÉDICO</ControlLabel>
                            <FormControl componentClass="textarea" style={{ height: 75, maxHeight: 75, minHeight: 75, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.centro} onChange= {this.handleCentro}/>  
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>MÉDICO</ControlLabel>
                            <FormControl componentClass="textarea" style={{ height: 75, maxHeight: 75, minHeight: 75, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.medico} onChange= {this.handleMedico}/>                      
                         </div>

                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>DIAGNÓSTICO</ControlLabel>
                            <FormControl componentClass="textarea" style={{ height: 75, maxHeight: 75, minHeight: 75, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.diagnostico} onChange= {this.handleDiagnostico}/>
                        </div>
                  </div> 
                 
                    <div className="row">
                        <div className="col-md-6" style={{marginBottom: 18}}>
                            <ControlLabel>OBSERVACIÓN <a>(OPCIONAL)</a></ControlLabel>
                            <FormControl componentClass="textarea" style={{ height: 75, maxHeight: 75, minHeight: 75, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.observacion} onChange= {this.handleObservacion}/>  
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>Días de descanso médico al año:</ControlLabel>
                            <FormControl type="number" value={this.state.diasUtilizados} onChange= {this.handleDias}     disabled/>                        
                        </div>
                        {/* <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>Días cubiertos por la Entidad:</ControlLabel>
                            <FormControl type="number" value={this.state.entidad} onChange= {this.handleDias}     disabled/> 
                        </div> */}
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>Días de subsidio:</ControlLabel>
                            <FormControl type="number" value={this.state.seguro} onChange= {this.handleDias}     disabled/>                        
                        </div>
                    </div>
                  
                 
                  <div className="row" style={{marginTop: 18, marginBottom: 20}}>
                    <div className="col-md-3"></div>
                      <div className="col-md-6" style={{marginBottom: 18}}>
                            <ControlLabel>ARCHIVOS SUBIDOS <a>(OPCIONAL)</a></ControlLabel>
                            <Alert bsStyle="warning">
                              <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.fileName}</span>
                            </Alert>
                            <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                                <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                            </Button>
                            {this.state.fileName != "" ? (
                                  <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar Archivo</Button>
                            ) : (  null )}
                      </div>
                  </div>
                    <Button style={{width: 200, marginLeft: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Ver Descansos Médicos</Button>
                    <Button style={{width: 200, marginLeft: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Guardar Registro</Button>
                  </div>
                 )}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default NuevoDescansoMedico;
