import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';

import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { tesoreria } from 'store/Apis'

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class EditarLibro extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'cajachica',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      cheque: '',
      cnum_1: '',
      cnum_2: '',
      cnum_3: '',
      cnum_4: '',
      cnum_7: '',
      concepto: '',
      numero: '',
      patrimonial: '',
      presupuestal: '',
      reten_cuarta: '',
      reten_otros: '',
      metas: '',
      nidlibro: 3,
      nombre: '',
      partidas: '',
      siaf: '',
      orden: '',
      origen: '',
      isLoading: true
    }

    this.handleMetas = this.handleMetas.bind(this);
    this.handlePartidas = this.handlePartidas.bind(this);
    this.handleSiaf = this.handleSiaf.bind(this);
    this.handleOrden = this.handleOrden.bind(this);
    this.handleCnum1 = this.handleCnum1.bind(this);
    this.handleCnum2 = this.handleCnum2.bind(this);
    this.handleCnum3 = this.handleCnum3.bind(this);
    this.handleCnum4 = this.handleCnum4.bind(this);
    this.handleCnum7 = this.handleCnum7.bind(this);
    this.handleCuarta = this.handleCuarta.bind(this);

  }

  handleMetas(event) {
    this.setState({metas: event.target.value});
  }

  handlePartidas(event) {
    this.setState({partidas: event.target.value});
  }

  handleSiaf(event) {
    this.setState({siaf: event.target.value});
  }

  handleOrden(event) {
    this.setState({orden: event.target.value});
  }

  handleCnum1(event) {
    this.setState({cnum_1: event.target.value});
  }

  handleCnum2(event) {
    this.setState({cnum_2: event.target.value});
  }

  handleCnum3(event) {
    this.setState({cnum_3: event.target.value});
  }

  handleCnum4(event) {
    this.setState({cnum_4: event.target.value});
  }

  handleCnum7(event) {
    this.setState({cnum_7: event.target.value});
  }

  handleCuarta(event) {
    this.setState({reten_cuarta: event.target.value});
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(localStorage.getItem("libro") !== null){
        this.setState({movimiento: JSON.parse(localStorage.getItem('libro')).nidlibro,
                       origen: JSON.parse(localStorage.getItem('libro')).origen});
        this.listarMovimiento();
    }else{
      this.props.history.push('/libro');
    }
  }

  async listarMovimiento() {
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${tesoreria}` + 'comprobantes/listLibroxNid?nidlibro=' + this.state.movimiento)
      .then((res) => {
        let lista = res.data.response;
        if(lista != null){
            this.setState({
                cheque: lista[0].cheque,
                cnum_1: lista[0].cnum_1 != '0' && lista[0].cnum_1 != null ? lista[0].cnum_1.toFixed(2) : '',
                cnum_2: lista[0].cnum_2 != '0' && lista[0].cnum_2 != null ? lista[0].cnum_2.toFixed(2) : '',
                cnum_3: lista[0].cnum_3 != '0' && lista[0].cnum_3 != null ? lista[0].cnum_3.toFixed(2) : '',
                cnum_4: lista[0].cnum_4 != '0' && lista[0].cnum_4 != null ? lista[0].cnum_4.toFixed(2) : '',
                cnum_7: lista[0].cnum_7 != '0' && lista[0].cnum_7 != null ? lista[0].cnum_7.toFixed(2) : '',
                concepto: lista[0].concepto,
                numero: lista[0].cp,
                patrimonial: lista[0].ctxt_patrimonial != null ? 'S/.    ' + lista[0].ctxt_patrimonial.toFixed(2) : 'S/. 0.00',
                presupuestal: lista[0].ctxt_presupuestal != null ? 'S/.    ' + lista[0].ctxt_presupuestal.toFixed(2) : 'S/. 0.00',
                reten_otros: lista[0].ctxt_reten_otros != null ? 'S/.    ' + lista[0].ctxt_reten_otros.toFixed(2) : 'S/. 0.00',
                reten_cuarta: lista[0].ctxt_reten_cuarta != '0' && lista[0].ctxt_reten_cuarta != null ? lista[0].ctxt_reten_cuarta.toFixed(2) : '',
                metas: lista[0].metas,
                partidas: lista[0].partidas,
                siaf: lista[0].siaf,
                orden: lista[0].orden,
                nidlibro: lista[0].nid,
                nombre: lista[0].nombre,
                isLoading: false
            })
        }else{
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
          this.setState({isLoading: false})
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
        }
      });
  }

  async guardar(){
      if(this.state.metas != '' && this.state.metas != null && this.state.partidas != '' && this.state.partidas != null && 
      this.state.siaf != '' && this.state.siaf != null && this.state.orden != '' && this.state.orden != null){
        let num1 = 0;
        let num2 = 0;
        let num3 = 0;
        let num4 = 0;
        let num7 = 0;
        let cuarta = 0;
        if(this.state.cnum_1 != ''){
          num1 = this.state.cnum_1
        }
        if(this.state.cnum_2 != ''){
          num2 = this.state.cnum_2
        }
        if(this.state.cnum_3 != ''){
          num3 = this.state.cnum_3
        }
        if(this.state.cnum_4 != ''){
          num4 = this.state.cnum_4
        }
        if(this.state.cnum_7 != ''){
          num7 = this.state.cnum_7
        }
        if(this.state.reten_cuarta != ''){
          cuarta = this.state.reten_cuarta
        }
        this.setState({isLoading: true})
        let fromData = new FormData();
        fromData.append('metas',  this.state.metas);
        fromData.append('partidas', this.state.partidas);
        fromData.append('siaf', this.state.siaf);
        fromData.append('orden', this.state.orden);
        fromData.append('num1', num1);
        fromData.append('num2',  num2);
        fromData.append('num3', num3);
        fromData.append('num4', num4);
        fromData.append('num7', num7);
        fromData.append('cuarta', cuarta);
        fromData.append('usuario', this.state.usuario);
        fromData.append('nidlibro', this.state.nidlibro);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${tesoreria}` + 'comprobantes/editarLibro', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            this.props.handleClick("tl", "info", "El comprobante ha sido actualizado en el libro!", "pe-7s-smile")
            this.volver();
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
          }
          })
          .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
          }else{
              console.log(error);
              this.setState({isLoading: false})
          }
        });
      }else{
        this.props.handleClick("tl", "warning", "Debe completar los campos principales!", "pe-7s-info")
      }
  }

  async volver(){
    if(this.state.origen == 'L'){
      this.props.history.push('/libro');
    }else{
      this.props.history.push('/editarcomprobante');
    }
  }

  render() {
    const {numero} = this.state
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Detalle del Comprobante para el Libro Contable: N° {numero}</h4>
              <div className="row" style={{marginBottom: 10}}>
                  <div className="col-md-12">
                  <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Datos del Comprobante</h4>
                      {this.state.isLoading ? (
                        <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading}
                        />
                      ) : (
                        <div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE CP</ControlLabel>
                                <FormControl type="text" value={this.state.numero} disabled/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>NOMBRE</ControlLabel>
                                <FormControl type="text" value={this.state.nombre} disabled/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>CONCEPTO</ControlLabel>
                                <FormControl componentClass="textarea" style={{  height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.concepto} onChange= {this.handleConcepto} disabled/>

                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE CHEQUE</ControlLabel>
                                <FormControl type="text" value={this.state.cheque} disabled/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>REGISTRO SIAF *</ControlLabel>
                                <FormControl type="text" value={this.state.siaf} onChange= {this.handleSiaf}/>
                            </div> 
                          </div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE  ORDEN DE SERVICIO / COMPRA *</ControlLabel>
                                <FormControl type="text" value={this.state.orden} onChange= {this.handleOrden}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE METAS *</ControlLabel>
                                <FormControl type="text" value={this.state.metas} onChange= {this.handleMetas}/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE PARTIDA *</ControlLabel>
                                <FormControl type="text" value={this.state.partidas} onChange= {this.handlePartidas}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>N° 1</ControlLabel>
                                <FormControl type="text" value={this.state.cnum_1} onChange= {this.handleCnum1}/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>N° 2</ControlLabel>
                                <FormControl type="text" value={this.state.cnum_2} onChange= {this.handleCnum2}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>N° 3</ControlLabel>
                                <FormControl type="text" value={this.state.cnum_3} onChange= {this.handleCnum3}/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>N° 4</ControlLabel>
                                <FormControl type="text" value={this.state.cnum_4} onChange= {this.handleCnum4}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>N° 7</ControlLabel>
                                <FormControl type="text" value={this.state.cnum_7} onChange= {this.handleCnum7}/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>PRESUPUESTAL</ControlLabel>
                                <FormControl type="text" value={this.state.presupuestal} disabled/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>PATRIMONIAL</ControlLabel>
                                <FormControl type="text" value={this.state.patrimonial} disabled/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>CUARTA CATEGORÍA</ControlLabel>
                                <FormControl type="text" value={this.state.reten_cuarta} onChange= {this.handleCuarta}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>OTRAS RETENCIONES</ControlLabel>
                                <FormControl type="text" value={this.state.reten_otros} disabled/>
                            </div>
                          </div>
                        </div>
                      )}
                      <Button style={{width: 200, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.guardar() }}>Actualizar</Button>
                  </div>
                </div>
              </div>
            </Col>
            <Button style={{width: 200, marginTop: 25, marginRight: 30}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.volver() }}>Volver a Anterior</Button>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarLibro;