import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory  from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, cec} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { paramCec ,paramJurisdiccional} from "store/Apis";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class VideoCEC extends Component {

    constructor(props) {
        super(props);
        this.state = {
          path: 'utilitarioscec',
          usuario: localStorage.getItem('nickname'),
          token: 'Bearer ' + localStorage.getItem('ConcurToken'),
          token2: 'Bearer ' + localStorage.getItem('AccessToken'),
          id: JSON.parse(localStorage.getItem('datos'))[0].Person,
          movimientos: [],
          isLoading: true,
          nid:"",
          causa:"",
        }
        this.GetActionFormat= this.GetActionFormat.bind(this);
        this.verimagen=this.verimagen.bind(this);
        this.handleCausa=this.handleCausa.bind(this);
   
      }
      handleCausa(event) {this.setState({causa: event.target.value});}
      
     
      async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token2
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{
                 this.listarCarouselAdm()
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
      }

    
    
      async listarCarouselAdm() {
        this.setState({isLoading: true})
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${cec}` + '/public/listPrincipal')
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({ 
                    item:data.item,
                    nid: data.nid,
                    ctxt_video: data.ctxt_video
                })
              })
              this.setState({movimientos: lista, isLoading: false})
            }else{
              this.setState({movimientos: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
      }

  

  

    async obtener(data, param){
        this.setState({
         causa: data.ctxt_video,
         nid: data.nid,
        })
   }
  
    
    GetActionFormat(cell, row) {
        if(row != undefined){
          return (
              <div>
               <span data-tip="Editar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row, "E") }}>
                    <ReactTooltip />
                    <i className="fa fa-circle fa-stack-2x text-info"></i>
                    <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 18}}></i>
                  </span>
               </div>
          );
        }else{}
      }

      async limpiar(){
        this.listarCarouselAdm();
        this.cancelar();
      }
    
      async cancelar(){
        this.setState({ causa: "", nid: ""})
      }
    
      verimagen(cell, row) {
        if(row != undefined){
          return (
            <div>
                  <span data-tip="Ver Video"  className="fa-stack fa-2x"  style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(row.ctxt_video) }}>
                  <ReactTooltip />
                
                  <i className="fa fa-youtube-play" style={{fontSize: 28}}></i>
                  </span>
                  </div>
          );
        }else{}
      }
      abrirImagen(link){
        window.open('https://youtu.be/'+link, '_blank');
      } 

      async regresar(){
        this.props.history.push('/utilitarioscec');
      }
      async save(){
        if(this.state.causa != "" ){
          if(this.state.nid == ''){
            this.props.handleClick("tl", "warning", "Solo puede haber un registro, para ello haga clic en editar del registro existente!", "pe-7s-info")
          }else{
            this.update()
          }
        }else{
          this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
        }
      }

    
    
      async update(){  
        let fromData = new FormData();
        fromData.append('video', this.state.causa);
        fromData.append('nide', this.state.nid)
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${cec}` + 'contenido/editarVideoCEC', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            this.props.handleClick("tl", "success", "Causa actualizada correctamente!", "pe-7s-close")
            this.limpiar();
          }else{
              this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
              this.setState({isLoading: false})
          }
          })
          .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
          }else{
              console.log(error);
              this.setState({isLoading: false})
          }
        });
      }

      
    
      render() {
        const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "ctxt_video", text: "ENLACE",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white" }, headerAlign: (column, colIndex) => 'center',align: 'center',formatter: this.verimagen},
        {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
        ]
        const options = {
          custom: true,
          paginationSize: 4,
          pageStartIndex: 1,
          firstPageText: 'Primera',
          prePageText: 'Anterior',
          nextPageText: 'Siguiente',
          lastPageText: 'Última',
          showTotal: true,
          totalSize: this.state.movimientos.length
        };
        const contentTable = ({ paginationProps, paginationTableProps }) => (
          <div>
            <div>
              <div>
                <BootstrapTable
                  striped
                  hover
                  keyField="item"
                  data={ this.state.movimientos }
                  columns={ columns }
                  filter={ filterFactory() }
                  noDataIndication="No existen registros para mostrar"
                  { ...paginationTableProps }
                />
              </div>
              <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
            </div>
          </div>
        );
        return (
          <div className="content">
            <Grid fluid>
            <Row>
                <Col md={12}>
                  <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                  <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Video del CEC</h4>
                  <div className="row">
                  
                    <div className="row" style={{marginBottom: 10}}>
                  <div className="col-md-10" style={{marginBottom: 18}}>
                      <ControlLabel><i className="fa fa-th-large"></i>Ingresar el código de video</ControlLabel>
                      <FormControl type="text" value={this.state.causa} onChange= {this.handleCausa}/>
                  </div>
                  <div className="col-md-2">
                      <Button style={{width: 150, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.save() }}>Actualizar</Button>
                      <Button style={{width: 150, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Cancelar</Button>
                  </div>
              </div>
                    <ControlLabel></ControlLabel><br></br>
                        <Button style={{width: 250, marginTop: 5, marginRight: 10,marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                       
                   
                  </div>
                  {this.state.isLoading ? (
                          <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading}
                          />
                        ) : (
                          <PaginationProvider
                            pagination={
                              paginationFactory(options)
                            }
                          >
                            { contentTable }
                          </PaginationProvider>
                     )}
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        );
      }
    }
export default VideoCEC;