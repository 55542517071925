import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { confirmAlert } from 'react-confirm-alert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class GestionarDM extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarDesMedicos',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      listado: [],
      accion: {value: "A", label: "AUTORIZAR DESCANSO MÉDICO"},
      acciones: [{value: "A", label: "AUTORIZAR DESCANSO MÉDICO"},
                 {value: "D", label: "DESAUTORIZAR DESCANSO MÉDICO"}],
      isLoading: true
    }
 }
    handleAccion = accion => {
        this.setState(
        { accion },
        );
    };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.listarPendientes();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
  async listarPendientes() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'descanso/listPendientes')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              fechaIni : Calendar.calendarioA(data.DateBegin),
              fechaFin: Calendar.calendarioA(data.DateEnd),
              dias : data.Days,
              nomTipoDescanso :data.Description,
              idTipoDescanso: data.MedicalRestType,
              idperson :data.Person,
              dni: data.DocumentNumber,
              nombres:data.Name,
              estado : data.Status=="A" ? "AUTORIZADO" : "PENDIENTE",
              fechaCreacion : Calendar.calendarioA(data.XLastDate),
              centro : data. centromedico,
              medico: data.medico ,
              diagnostico: data.diagnostico,
              line:data.line,
              item:data.item
             }) 
          }) 
          this.setState({listado: lista, isLoading: false})
          console.log(this.state.listado)     
        }else{
          this.setState({listado: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  confirmar = () => {
    let cuenta = this.node.selectionContext.selected
    if(cuenta.length >= 1){
      confirmAlert({
        title: '¿Seguro que desea realizar el proceso?',
        message: 'Proceso a realizar: ' +  this.state.accion.label,
        buttons: [
          {
            label: 'Aceptar',
            onClick: () => this.procesar()
          },
          {
            label: 'Cancelar',
            onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
          }
        ]
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe seleccionar algún registro para procesar!", "pe-7s-info")
    }
  };

  async procesar(){
    this.setState({isLoading: true});
    let lista = this.node.selectionContext.selected;
    setTimeout(
      function() {
      let cont = 1;  
      lista.map((data) => {
          for(var i = 0; i < this.state.listado.length; i++){
            if(this.state.listado[i].item == data){
                let fromData = new FormData();
                let idperson = this.state.listado[i].idperson;
                let line = this.state.listado[i].line;
                fromData.append('person', idperson);
                fromData.append('line', line);
                fromData.append('estado', this.state.accion.value);
                axios.defaults.headers.common['Authorization'] = this.state.token
                axios.post(`${sisperplus}` +   'descanso/gestionarDM', fromData)
                .then((response) => {
                  var datos = response.data
                  if(datos.response != null){
                       if(lista.length == cont++){
                          this.listarPendientes();
                          this.props.handleClick("tl", "info","El descanso médico han sido procesado con éxito", "pe-7s-smile")
                          this.regresar()
                        }
                    }else{
                    this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
                    this.setState({isLoading: false})
                  }
                })
                .catch((error) =>{ 
                  if(auth0Client.isAuthenticated() === true){
                    auth0Client.signOut()
                    this.props.history.replace('/');
                  }else{
                    console.log(error);
                    this.setState({isLoading: false})
                  }
                });
              
            }
          }
      });
      }
      .bind(this),
      3000
    );
  }

  async regresar(){
    this.props.history.push('/administrarDesMedicos')
  }

  render() {
    const columns = [
    {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white",width:40}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "dni", text: "DNI",headerStyle: {backgroundColor: '#337AB7', color: "white",width:90}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "nombres", text: "NOMBRES Y APELLIDOS", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:250}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "nomTipoDescanso", text: "DESCANSO MÉDICO", headerStyle: {backgroundColor: '#337AB7', color: "white", width:230}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "fechaIni", text: "FEC. INICIO",  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "fechaFin", text: "FEC. TÉRMINO",  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "dias", text: "DIAS", headerStyle: {backgroundColor: '#337AB7', color: "white",width:40}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "centro", text: "CENTRO MÉDICO",headerStyle: {backgroundColor: '#337AB7', color: "white",width:185}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "medico", text: "MÉDICO",  headerStyle: {backgroundColor: '#337AB7', color: "white",width:160}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "diagnostico", text: "DIAGNÉSTICO", headerStyle: {backgroundColor: '#337AB7', color: "white",width:185}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "fechaCreacion", text: "FEC. CREACIÓN", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.listado.length
    };
    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true
      };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
          <BootstrapTable
              striped
              hover
              keyField="item"
              ref={ n => this.node = n }
              data={ this.state.listado }
              columns={ columns }
              selectRow={ selectRow }
              tabIndexCell
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Descansos Médicos Pendientes</h4>
              <div className="row">
                <div className="col-md-4" style={{marginBottom: 18}}>
                    <ControlLabel>ACCIÓN A REALIZAR</ControlLabel>
                    <Select value={this.state.accion} onChange= {this.handleAccion} options={this.state.acciones} />
                 </div>
                 <div className="col-md-8" style={{marginBottom: 18}}>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 220, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.confirmar() }}>Procesar</Button>
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>      
              <Button style={{width: 220, marginTop: 5, marginLeft: 10, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>

            </Col>

          </Row>

        </Grid>
      </div>
    );
  }
}

export default GestionarDM;