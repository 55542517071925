import React, { Component } from "react";
import { ControlLabel, Grid, Row, Col } from "react-bootstrap";
import { FormControl } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator, sisperplus } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import Select from 'react-select';
import { cec } from 'store/Apis';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class AreaTrabajadores extends Component {

    constructor(props) {
        super(props);
        this.state = {
            path: 'aplicaciones',
            usuario: localStorage.getItem('nickname'),
            token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
            token2: 'Bearer ' + localStorage.getItem('AccessToken'),
            id: JSON.parse(localStorage.getItem('datos'))[0].Person,
            movimientos: [],
            movimientos2: [],
            nflg_defecto: "",
            item: "",
            Name: "",
            Person: "",
            cnum_dni: "",
            nid_usuario: "",
            item2: "",
            ctxt_descripcion: "",
            nid_area: "",
            isLoading: true,
            isLoading2: false,
            area1: "",
            areas: [],
        }

        this.GetActionFormat = this.GetActionFormat.bind(this);
        this.GetActionFormat2 = this.GetActionFormat2.bind(this);
        this.GetActionFormat3 = this.GetActionFormat3.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(event) {
        this.setState({ nflg_defecto: event.target.value });
    }

    changeArea = area1 => {
        this.setState(
            { area1 },
        );
    };

    async componentDidMount() {
        if (localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null) {
            axios.get(validator + this.state.path + '/' + this.state.usuario, {
                headers: {
                    Authorization: this.state.token
                }
            }).then((response) => {
                let permisos = response.data;
                if (permisos.codError != 1) {
                    this.props.history.goBack()
                } else {
                    this.listarTrabajadores()
                }
            }, (error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    this.props.history.goBack()
                }
            });
        } else {
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }

    async listarTrabajadores() {
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'areaAdmin/listTrabajadores')
            .then((res) => {
                let lista = [];
                let lista2 = res.data.response;
                if (lista2 != null) {
                    lista2.map((data) => {
                        lista.push({
                            Name: data.Name,
                            Person: data.Person,
                            cnum_dni: data.cnum_dni,
                            item: data.item,
                            nid_usuario: data.nid_usuario
                        })
                    })
                    this.setState({ movimientos: lista, isLoading: false, nid_usuario: res.nid_usuario })

                } else {
                    this.setState({ movimientos: [], isLoading: false })
                    this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })
                }
            });
    }

    async listarAreaXTrabajador() {
        this.setState({ isLoading2: true })
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'areaAdmin/listAreaxTrabajador?p_nidusuario=' + this.state.nid_usuario)
            .then((res) => {
                let lista = [];
                console.log(lista)
                let lista2 = res.data.response;
                if (lista2 != null) {
                    lista2.map((data) => {
                        lista.push({
                            nid_area: data.nid_area,
                            nflg_defecto: data.nflg_defecto,
                            nflg_seteo: data.nflg_seteo,
                            nflg_activo: data.nflg_activo,
                            ctxt_descripcion: data.ctxt_descripcion,
                            item2: data.item
                        })
                    })
                    this.setState({ movimientos2: lista, isLoading2: false })

                    this.areaCombo();
                } else {

                    this.setState({ movimientos2: [], isLoading2: false })
                    this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading2: false })
                }
            });
    }

    async areaCombo() {
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'documentario/listAreaAutorizacion')
            .then((res) => {
                let lista = [];
                let lista2 = res.data.response;
                if (lista2 != null) {
                    lista2.map((data) => {
                        lista.push(
                            {
                                value: data.nid_area,
                                label: data.ctxt_descripcion,
                            })
                    });
                    this.setState({ areas: lista, area1: { value: lista[0].value, label: lista[0].label } })

                } else {
                    this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                }
            });
    }

    async guardar() {
        this.setState({ isLoading2: true });
        let fromData = new FormData();
        fromData.append('p_nidusuario', this.state.nid_usuario);
        fromData.append('p_nidarea', this.state.area1.value);
        fromData.append('p_cnomusuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token;
        try {
            const response = await axios.post(`${sisperplus}` + 'areaAdmin/insertAreaxTrabajador', fromData);
            var datos = response.data;
            console.log(datos);
            if (datos.response != null) {
                if (datos.response.estado === '0') {
                    this.props.handleClick("tl", "error", datos.response.descripcion.replace("., Total = 1", ""), "pe-7s-close");
                    this.listarAreaXTrabajador();
                } else {
                    this.props.handleClick("tl", "info", "El área a sido " + datos.response.descripcion.replace(", Total = 1", ""), "pe-7s-smile");
                    this.setState({ isLoading2: false });
                    this.listarAreaXTrabajador();
                }
            } else {
                this.props.handleClick("tl", "error", "Hubo un problema con el registro!", "pe-7s-close");
                this.setState({ isLoading2: false });

            }
        } catch (error) {
            if (auth0Client.isAuthenticated() === true) {
                auth0Client.signOut();
                this.props.history.replace('/');
            } else {
                console.log(error);
                this.setState({ isLoading2: false });
            }
        }
    }

    async delete(data) {
        let fromData = new FormData();
        fromData.append('p_nidusuario', this.state.nid_usuario);
        fromData.append('p_nidarea', data.nid_area);
        fromData.append('p_cnomusuario', this.state.usuario);
        console.log(fromData)
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'areaAdmin/updateAnularAreaxTrabajador', fromData)
            .then((response) => {
                var datos = response.data
                if (datos.response != null) {
                    if (datos.response.estado == 1) {
                        this.listarAreaXTrabajador();
                        this.props.handleClick("tl", "info", "El área ha sido anulado!", "pe-7s-smile")
                    } else {
                        this.props.handleClick("tl", "error", "No se puede anular el área!", "pe-7s-close")
                        this.setState({ isLoading2: false })
                    }
                } else {
                    this.props.handleClick("tl", "error", "Hubo un problema con manejar!", "pe-7s-close")
                    this.setState({ isLoading2: false })
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading2: false })
                }
            });
    }

    async actualizarDefecto(data) {
        const { movimientos2 } = this.state;
        if (data.nflg_defecto === 1) {
            const updatedMovimientos2 = movimientos2.map((item) => ({
                ...item,
                nflg_defecto: item === data ? 1 : 0
            }));
            this.setState({ movimientos2: updatedMovimientos2 });
        }
        let fromData = new FormData();
        fromData.append('p_nidusuario', this.state.nid_usuario);
        fromData.append('p_nidarea', data.nid_area);
        fromData.append('p_cnomusuario', this.state.usuario);
        console.log(fromData)
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'areaAdmin/updateDefectoAreaTra', fromData)
            .then((response) => {
                var datos = response.data
                if (datos.response != null) {
                    if (datos.response.estado == 1) {
                        this.listarAreaXTrabajador();
                        this.props.handleClick("tl", "info", "Actualizado", "pe-7s-smile")
                    } else {
                        this.props.handleClick("tl", "error", "No se pudo actualizar", "pe-7s-close")
                    }
                } else {
                    this.props.handleClick("tl", "error", "Hubo un problema con la acción!", "pe-7s-close")
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                }
            });
    }

    async save() {
        if (this.state.area1 != '') {
            this.guardar()
        } else {
            this.props.handleClick("tl", "info", "Seleccione a un trabajador!", "pe-7s-smile")
        }
    }

    async guardar() {
        this.setState({ isLoading2: true });
        let fromData = new FormData();
        fromData.append('p_nidusuario', this.state.nid_usuario);
        fromData.append('p_nidarea', this.state.area1.value);
        fromData.append('p_cnomusuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token;
        try {
            const response = await axios.post(`${sisperplus}` + 'areaAdmin/insertAreaxTrabajador', fromData);
            var datos = response.data;
            console.log(datos);
            if (datos.response != null) {
                if (datos.response.estado === '0') {//var texto_sin_izquierda = texto.replace("Hola ", "");
                    this.props.handleClick("tl", "error", datos.response.descripcion.replace("., Total = 1", ""), "pe-7s-close");
                    this.listarAreaXTrabajador();
                } else {
                    this.props.handleClick("tl", "info", "El área a sido " + datos.response.descripcion.replace(", Total = 1", ""));
                    this.setState({ isLoading2: false });
                    this.listarAreaXTrabajador();
                }
            } else {
                this.props.handleClick("tl", "error", "Hubo un problema con el registro!", "pe-7s-close");
                this.setState({ isLoading2: false });

            }
        } catch (error) {
            if (auth0Client.isAuthenticated() === true) {
                auth0Client.signOut();
                this.props.history.replace('/');
            } else {
                console.log(error);
                this.setState({ isLoading2: false });
            }
        }
    }

    async delete(data) {
        let fromData = new FormData();
        fromData.append('p_nidusuario', this.state.nid_usuario);
        fromData.append('p_nidarea', data.nid_area);
        fromData.append('p_cnomusuario', this.state.usuario);
        console.log(fromData)
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'areaAdmin/updateAnularAreaxTrabajador', fromData)
            .then((response) => {
                var datos = response.data
                if (datos.response != null) {
                    if (datos.response.estado == 1) {
                        this.listarAreaXTrabajador();
                        this.props.handleClick("tl", "info", "El área ha sido anulado!", "pe-7s-smile")
                    } else {
                        this.props.handleClick("tl", "error", "No se puede anular el área!", "pe-7s-close")
                        this.setState({ isLoading2: false })
                    }
                } else {
                    this.props.handleClick("tl", "error", "Hubo un problema con manejar!", "pe-7s-close")
                    this.setState({ isLoading2: false })
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading2: false })
                }
            });
    }

    async actualizarDefecto(data) {
        const { movimientos2 } = this.state;

        if (data.nflg_defecto === 1) {
            const updatedMovimientos2 = movimientos2.map((item) => ({
                ...item,
                nflg_defecto: item === data ? 1 : 0
            }));
            this.setState({ movimientos2: updatedMovimientos2 });
        }
        let fromData = new FormData();//nid_area
        fromData.append('p_nidusuario', this.state.nid_usuario);
        fromData.append('p_nidarea', data.nid_area);
        fromData.append('p_cnomusuario', this.state.usuario);
        console.log(fromData)
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'areaAdmin/updateDefectoAreaTra', fromData)
            .then((response) => {
                var datos = response.data
                if (datos.response != null) {
                    if (datos.response.estado == 1) {
                        this.listarAreaXTrabajador();
                        this.props.handleClick("tl", "info", "Actualizado", "pe-7s-smile")
                    } else {
                        this.props.handleClick("tl", "error", "No se pudo actualizar", "pe-7s-close")
                    }
                } else {
                    this.props.handleClick("tl", "error", "Hubo un problema con la acción!", "pe-7s-close")
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                }
            });
    }

    async save() {
        if (this.state.area1 != '') {
            this.guardar()
        } else {
            this.props.handleClick("tl", "info", "Seleccione a un trabajador!", "pe-7s-smile")
        }
    }

    async obtener(data) {
        if (data && data.nid_usuario) {
            this.setState({ nid_usuario: data.nid_usuario, Name: data.Name }, () => {
                this.listarAreaXTrabajador();
            });
        }
    }

    confirmarDelete = (data) => {
        confirmAlert({
            title: '¿Seguro que desea anular el área del trabajador?',
            message: 'Se anulará el área seleccionado.',
            buttons: [
                {
                    label: 'Aceptar',
                    onClick: () => this.delete(data)
                },
                {
                    label: 'Cancelar',
                    onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
                }
            ]
        });
    };
    GetActionFormat(cell, row) {
        if (row != undefined) {
            return (
                <div>
                    <span data-tip="Asignar Area" className="fa-stack fa-2x" style={{ cursor: 'pointer', fontSize: 20 }} onClick={() => { this.obtener(row) }}>
                        <ReactTooltip />
                        <i className="fa fa-circle fa-stack-2x text-green"></i>
                        <i className="fa fa-th-large fa-stack-1x fa-inverse" style={{ fontSize: 15 }}></i>
                    </span>
                </div>
            );
        } else { }
    }

    GetActionFormat2(cell, row) {
        const isSelected = row.nflg_defecto === 1;
        return (
            <div>
                <Button
                    style={{
                        backgroundColor: isSelected ? 'red' : 'black',
                        color: 'white',
                        width: 90,
                        borderRadius: 5,
                        border: 'none',
                        padding: 2
                    }}
                    onClick={() => this.actualizarDefecto(row)}
                >
                    {row.nflg_defecto === 1 ? "Principal" : "Acceso"}
                </Button>
            </div>
        );
    }

    GetActionFormat3(cell, row) {
        if (row != undefined) {
            return (
                <div>
                    <span data-tip="Anular Area" className="fa-stack fa-2x" style={{ cursor: 'pointer', fontSize: 20 }} onClick={() => { this.confirmarDelete(row) }}>
                        <ReactTooltip />
                        <i className="fa fa-circle fa-stack-2x text-black"></i>
                        <i className="fa fa-trash fa-stack-1x fa-inverse" style={{ fontSize: 15 }}></i>
                    </span>
                </div>
            );
        } else { }
    }

    render() {
        const columns = [
            { dataField: "item", text: "ITEM", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white", width: 55 }, headerAlign: (column, colIndex) => 'center', align: 'center' },
            { dataField: "Name", text: "NOMBRE Y APELLIDO", filter: textFilter(), headerStyle: { backgroundColor: '#337AB7', color: "white", width: 350 }, headerAlign: (column, colIndex) => 'center', align: "center" },
            { dataField: "cnum_dni", text: "DNI", filter: textFilter(), headerStyle: { backgroundColor: '#337AB7', color: "white", width: 130 }, headerAlign: (column, colIndex) => 'center', align: 'center' },
            { dataField: "detail", text: "ASIGNAR AREA", headerStyle: { backgroundColor: '#337AB7', color: "white", width: 90 }, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat }
        ]
        const columns2 = [
            { dataField: "item2", text: "ITEM", headerStyle: { backgroundColor: '#337AB7', color: "white", width: 15 }, headerAlign: (column, colIndex) => 'center', align: 'center' },
            { dataField: "ctxt_descripcion", text: "AREA", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white", width: 85 }, headerAlign: (column, colIndex) => 'center', align: 'center' },
            { dataField: "detail", text: "DEFECTO", headerStyle: { backgroundColor: '#337AB7', color: "white", width: 35 }, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2 },
            { dataField: "detail", text: "ACCIONES", headerStyle: { backgroundColor: '#337AB7', color: "white", width: 20 }, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat3 },
        ]

        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'Primera',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Última',
            showTotal: true,
            totalSize: this.state.movimientos.length
        };

        const options2 = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'Primera',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Última',
            showTotal: true,
            totalSize: this.state.movimientos2.length
        };

        const contentTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <div>
                    <div>
                        <BootstrapTable
                            striped
                            hover
                            keyField="item"
                            data={this.state.movimientos}
                            columns={columns}
                            filter={filterFactory()}
                            noDataIndication="No existen registros para mostrar"
                            {...paginationTableProps}
                        />
                    </div>
                    <div style={{ float: "right" }}><PaginationListStandalone {...paginationProps} /></div>
                </div>
            </div>
        );

        const contentTable2 = ({ paginationProps, paginationTableProps }) => (
            <div>
                <div>
                    <div>
                        <BootstrapTable
                            striped
                            hover
                            keyField="item2"
                            data={this.state.movimientos2}
                            columns={columns2}
                            filter={filterFactory()}
                            noDataIndication="No existen registros para mostrar"
                            {...paginationTableProps}
                        />
                    </div>
                    <div style={{ float: "right" }}><PaginationListStandalone {...paginationProps} /></div>
                </div>
            </div>
        );
        return (
            <div className="content">
                 <Grid fluid>
        <Row>  
            <div className="row" >      
          <h4 className="title text-center" style={{ fontWeight: 250, marginTop: '18px', marginBottom: '30px', fontSize: '35px' }}>Listado de Trabajadores por Asignar Área</h4>
       </div> 
        <Col md={7}>
         <div style={{ borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 5 }}>
                                {this.state.isLoading ? (
                                    <ClipLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={50}
                                        color={'#123abc'}
                                        loading={this.state.loading}
                                    />
                                ) : (
                                    <div className="row" style={{ marginTop: 30, marginBottom: 10 }}>
                                        <div className="col-md-12" >
                                            <PaginationProvider
                                                pagination={
                                                    paginationFactory(options)
                                                }>
                                                {contentTable}
                                            </PaginationProvider>
                                        </div>
                                    </div>

                                )
                                }
                            </div>
                
                        </Col>
                        {this.state.isLoading==false ? 
                        <Col md={5}>
                            <div style={{ borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 18, paddingBottom: 5 }}>
                                <h4 className="title" style={{ fontWeight: 135, marginTop: 18, marginBottom: 30 }}>Asignar área para : {this.state.Name}</h4>
                                <div className="row">
                                <div className="col-md-9" style={{ marginBottom: 18 }}>
                                    <ControlLabel>AREA</ControlLabel>
                                    <Select value={this.state.area1} onChange={this.changeArea} options={this.state.areas} />
                                </div>
                                <div className="col-md-3" style={{ marginBottom: 18 }}>
                                    <ControlLabel></ControlLabel><br></br>
                                    <Button style={{width:150,  marginTop: 5, marginBottom: 10 ,float:"right"}} bsStyle="info" fill type="submit" onClick={() => { this.save() }}>GUARDAR</Button>
                                </div></div>
                                {this.state.isLoading2 ? (
                                    <ClipLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={50}
                                        color={'#123abc'}
                                        loading={this.state.loading2}
                                    />
                                ) : (
                                    <div className="row" style={{ marginBottom: 10 }}>
                                        <div className="col-md-12">
                                            <PaginationProvider
                                                pagination={
                                                    paginationFactory(options2)
                                                }>
                                                {contentTable2}
                                            </PaginationProvider>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Col>
                      : null }
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default AreaTrabajadores;