import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { jurisdiccional , paramJurisdiccional} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';



const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Opciones extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'votaciones',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      evento:"",
      nidevento:"",
      nidopcion:"",
      opcion:"",
      isLoading: true,
      isGoing: false,
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.listadoPersonas=this.listadoPersonas.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleOpcion = this.handleOpcion.bind(this);
   
    }
    handleOpcion(event) { this.setState({opcion: event.target.value});}
    handleCheck(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value });
    }
  
 
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(JSON.parse(localStorage.getItem("jurisdiccional")).evento[0].data !== null){
        this.setState({evento: JSON.parse(localStorage.getItem('jurisdiccional')).evento[0].data.evento,
                       nidevento: JSON.parse(localStorage.getItem('jurisdiccional')).evento[0].data.nid});
        this.listarOpciones(0);
    }else{
      this.props.history.push('/evento');
    }
  }

  async listarOpciones(param) {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${jurisdiccional}` + 'votaciones/listOpciones?nidevento='+ this.state.nidevento)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                item: data.item,
                nidopcion: data.nid,
                ctxt_opcion: data.ctxt_opcion,
                flg_tipo: data.flg_tipo
            })
          })
         this.setState({movimientos: lista, isLoading: false})
         if(param == 1){
          this.props.handleClick("tl", "info", "La opción ha sido anulada!", "pe-7s-smile")  
         }
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async guardar(){  
    if(this.state.opcion != "" ){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('opcion', this.state.opcion.toUpperCase());
    fromData.append('tipo', this.cambioCheck(this.state.isGoing));
    fromData.append('nidevento', this.state.nidevento);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'votaciones/insertarOpciones', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar();
          this.props.handleClick("tl", "info", "La opción ha sido registrada!", "pe-7s-smile")
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }else {
      this.props.handleClick("tl", "warning", "Debe completar el campo solicitado!", "pe-7s-info")
   }
  }
  async limpiar(){
    this.listarOpciones();
    this.cancelar();
  }

  async cancelar(){
    this.setState({ opcion: "", nid: "" , isGoing :false})
  }

  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea anular la opción?',
      message: 'Se anulará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
 };
 async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidopcion', data.nidopcion);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'votaciones/anularOpciones', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 1){ 
           this.listarOpciones(1);
        }else{
            this.props.handleClick("tl", "error", "No se puede anular la opción!", "pe-7s-close")
            this.setState({isLoading: false})  
        }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async obtener(data){
    paramJurisdiccional.opcion[0].data = data
    localStorage.setItem('jurisdiccional', JSON.stringify(paramJurisdiccional))
    this.props.history.push('/listadoMiembros');  
 }

  async regresar(){
    this.props.history.push('/eventos');
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Anular Opción" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }
   
  listadoPersonas(cell, row){
    if(row != undefined){
      return (
          <div>
              {row.flg_tipo != "0" ? (
               <span data-tip="Ver Miembros" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }} >
               <ReactTooltip />
               <i className="fa fa-circle fa-stack-2x text-warning"></i>
               <i className="fa fa-th-list fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
               </span>
              ) : (null)}     
        </div>
      );
    }else{}
}

cambioCheck(check){
    if(check == true){
     return '1';
    }else{
     return '0';
    }
  }

  render() {
   const columns = [
    {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "ctxt_opcion", text: "OPCIONES", sort: true,  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "listado", text: "LISTA DE MIEMBROS", headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.listadoPersonas},
    // {dataField: "lista", text: "LISTA DE VOTOS", headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Opciones del Evento : <a style={{fontWeight: "bold", color: "black"}}>{this.state.evento}</a></h4>
              <div className="row">
                <div className="col-md-5" style={{marginBottom: 18}}>
                    <ControlLabel><i class="fa fa-th-large"></i>Opción</ControlLabel>
                    <FormControl type="text" value={this.state.opcion} onChange= {this.handleOpcion}/>
                </div>
                <div className="col-md-5" style={{letterSpacing: 1}}>
                    <ControlLabel>Listado</ControlLabel><br></br>
                    <div>
                    <input onChange={this.handleCheck} name="isGoing" type="checkbox" checked={this.state.isGoing} style={{marginRight: 5}}/>
                    <a style={{color:"black"}}>Marque solamente SI desea mostrar la lista de miembros.</a>
                    </div>
                </div>
                <div className="col-md-2">
                      <Button style={{width: 150, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.guardar() }}>Guardar Registro</Button>
                      <Button style={{width: 150, marginBottom: 10}} bsStyle="danger" pullRight type="submit" onClick={() => { this.cancelar() }}>Cancelar</Button>
                </div>
              </div>

              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
              <Button style={{width: 150, marginTop:15}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>    

            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Opciones;