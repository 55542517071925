import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {  cec, tipos} from 'store/Apis';
import InputFiles from 'react-input-files';
import 'react-tabs/style/react-tabs.css';
import Select from 'react-select';
import 'react-accessible-accordion/dist/fancy-example.css';
import identificador  from 'assets/img/identificador.png';

import 'react-accessible-accordion/dist/fancy-example.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class EditarCarousel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'utilitarioscec',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      archivo: "",
      identificador:"",
      file: [],
      archivo2: "",
      archivo3:"",
      archivo4:"",
      file2:[],
      ruta: "",
      ruta2: "",
      nidcarousel: "",
      estado: 0,
      isLoading: true,
      show: true, 
      value:"",
      label:"",
      flg_tipo: '',
      tipos: [    
        {value: "N", label: "SIN ARCHIVO"} , 
        {value: "A", label: "CON ARCHIVO"},
        {value: "L", label: "LINK DE NOTICIA"},
        {value: "F", label: "LINK DE FORMULARIO"}
      ]      
    }
     this.handleArchivo4 = this.handleArchivo4.bind(this)
     this.handleArchivo3 = this.handleArchivo3.bind(this)
     this.handleArchivo2 = this.handleArchivo2.bind(this)
  }
     handleArchivo4(event) {this.setState({archivo4: event.target.value});}
     handleArchivo3(event) {this.setState({archivo3: event.target.value});}
     handleArchivo2(event) {this.setState({archivo2: event.target.value});}

    handleTipo = flg_tipo => {
        this.setState(
            {flg_tipo},
            () => this.conversor(flg_tipo)
        );
    };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();}
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }}

  async validarLocal(){
    if(JSON.parse(localStorage.getItem("cec")).carousel[0].data !== null){
        this.setState({
          nidcarousel: JSON.parse(localStorage.getItem('cec')).carousel[0].data.nid, 
          archivo: JSON.parse(localStorage.getItem('cec')).carousel[0].data.ctxt_imagen,
          ruta: JSON.parse(localStorage.getItem('cec')).carousel[0].data.ctxt_ruta,
          archivo2: JSON.parse(localStorage.getItem('cec')).carousel[0].data.combo.value == "A" ? JSON.parse(localStorage.getItem('cec')).carousel[0].data.ctxt_archivo : "",
          archivo3: JSON.parse(localStorage.getItem('cec')).carousel[0].data.combo.value == "L" ? JSON.parse(localStorage.getItem('cec')).carousel[0].data.ctxt_archivo : "",
          archivo4: JSON.parse(localStorage.getItem('cec')).carousel[0].data.combo.value == "F" ? JSON.parse(localStorage.getItem('cec')).carousel[0].data.ctxt_archivo : "",
          ruta2: JSON.parse(localStorage.getItem('cec')).carousel[0].data.ctxt_rutaarchivo,
          flg_tipo: {value: JSON.parse(localStorage.getItem('cec')).carousel[0].data.combo.value, label: JSON.parse(localStorage.getItem('cec')).carousel[0].data.combo.label},
        
        isLoading:false});
        }else{
      this.props.history.push('/consultarcarousel');
  }}

  conversor(param){
    if(param.value == "L" || param.value == "N"){
      this.limpiar2()
      this.limpiar3()
    }
  }

  async validar(){
   let estado = "";
   let ruta = "";
   if(this.state.archivo != "" && this.state.archivo != "--" && this.state.archivo != null && this.state.archivo != undefined){
    if(this.state.file.length != 0){
      estado = "1";
      ruta = "--"
    }else{
      estado = "0";
      ruta = this.state.ruta
    }
    if(this.state.flg_tipo.value == "A"){
      if(this.state.file2.length != 0){
        this.actualizar(estado, this.state.archivo2, ruta, "--", "A");
      }else{
        if(this.state.archivo2 != "" && this.state.archivo2 != "--" && this.state.archivo2 != null && this.state.archivo2 != undefined){
          this.actualizar(estado, this.state.archivo2, ruta, this.state.ruta2, "N");
        }else{
          this.props.handleClick("tl", "error", "Debe seleccionar un archivo a subir!", "pe-7s-close")
        }
      }
    }else if(this.state.flg_tipo.value == "F"){
      console.log(this.state.archivo4)
      if(this.state.archivo4 != "" ){
        this.actualizar(estado, this.state.archivo4, ruta, "--", "F");
      }else{
        this.props.handleClick("tl", "error", "Debe ingresar el link del formulario!", "pe-7s-close")
      }
  
    }else if(this.state.flg_tipo.value == "L"){
        if(this.state.archivo3 != "" && this.state.archivo3 != "--" && this.state.archivo3 != null && this.state.archivo3 != undefined){
          this.actualizar(estado, this.state.archivo3, ruta, "--", "L");
        }else{
          this.props.handleClick("tl", "error", "Debe ingresar el identificador de la noticia!", "pe-7s-close")
        }
    }else{
      this.actualizar(estado, "--", ruta, "--", "N");
    }
   }else{
    this.props.handleClick("tl", "error", "Debe seleccionar una imagen a subir!", "pe-7s-close")
   }
   
  }

  async actualizar(estado, param, ruta, ruta2, tipo){  
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('file', this.state.file)  //ruta de imagen
    fromData.append('file2', this.state.file2)  //ruta de documento o noticia
    fromData.append('imagen', this.state.archivo) //nombre de imagen
    fromData.append('archivo', param) //nombre del documento o identificador
    fromData.append('oldrutaimagen', ruta);
    fromData.append('oldrutaarchivo', ruta2);
    fromData.append('estado', estado);
    fromData.append("tipo", tipo);
    fromData.append('usuario',this.state.usuario);
    fromData.append('nidcarousel',this.state.nidcarousel);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'carousel/editarCarousel', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Carousel actualizado correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
    })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }});
  }

  async regresar(){
    this.props.history.push('/consultarcarousel');
  }

 // documento pdf
 subir2 = (files2) => {
    if(files2.length !== 0){
         this.setState({file2: files2[0], archivo2: files2[0].name});
 }}

async limpiar2(){
   this.setState({archivo2: "", file2: ""})
 }

 async limpiar3(){
  this.setState({archivo3: ""})
}

//Imagen carousel
 subir = (files) => {
   if(files.length !== 0){
         this.setState({file: files[0], archivo: files[0].name});
   }}
 async limpiar(){
   this.setState({archivo: "", file: ""})
 }

//  archivo (){
//   if(this.state.file2.replace("https://cec.sedetc.gob.pe/#/detallenoticia/", "").length <= 5 && this.state.flg_tipo.value == "L" && this.state.file2 != ""){//noticia
//      return  this.state.archivo3 
//   }else if(this.state.file2.replace("https://cec.sedetc.gob.pe/#/detallenoticia/", "").length >= 5 && this.state.flg_tipo.value == "A" && this.state.file2 != "") {// documento
//     return this.state.archivo2 
//   }
//  }

 async mostrarimagen(){
  if(this.state.imagen == true){
    this.setState({imagen: false})
  }else{
    this.setState({imagen: true})
  }
}
  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Editar:  <a style={{fontWeight: "bold", color: "black",fontSize: 17}}>{this.state.asunto}</a></h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                        <div>
                        <div className="row">
                            <div className="col-md-6">
                                <ControlLabel>Imagen del Carousel</ControlLabel>
                                <Alert bsStyle="info">
                                    <span style={{letterSpacing: 1}}><b> Imagen Seleccionada : </b> {this.state.archivo}</span>
                                    </Alert>
                                <Button style={{width: '100%', marginTop: -10}} bsStyle="info" pullRight fill type="submit">
                                    <InputFiles  onChange={files => this.subir(files)}>
                                    <i className="fa fa-file-image-o"  style={{fontSize: 15}}></i>Seleccionar Imagen
                                    </InputFiles>
                                </Button> 
                            </div>
                            {this.state.archivo != "" ? (
                                <div  className="col-md-2">
                                    <Button style={{width: '100%', marginTop:25}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar</Button>
                                </div>
                            ) : ( null )}  
                        </div>
                        <div className="row">
                            <div className="col-md-4" style={{marginTop:30 }}>
                               <Select value={this.state.flg_tipo} onChange={this.handleTipo} options={this.state.tipos}/>
                            </div >
                        </div>
                      <div className="row">
                       {  this.state.flg_tipo.value == "F"  ? (
                        <div>
                            <div className="col-md-4" style={{marginTop: 30}}>
                               <ControlLabel>Ingrese el link del formulario:</ControlLabel>
                               <FormControl type="text" value={this.state.archivo4} onChange= {this.handleArchivo4}/>                             
                            </div>
                        </div>        
                        ) : this.state.flg_tipo.value == "L"? (//..
                            <div>
                                <div className="col-md-4" style={{marginTop: 30}}>
                                   <ControlLabel>Ingrese el Identificador de la noticia :</ControlLabel>                              
                                   <FormControl type="text" value={this.state.archivo3} onChange= {this.handleArchivo3}/>
                                    <div style={{marginTop: 10}}>
                                      <i className="fa fa-exclamation-circle"  style={{ marginRight:-10}}>  </i> 
                                        <Button style={{border:0}} onClick={() => { this.mostrarimagen() }}> ¿Dónde se encuentra el identificador de la noticia?</Button>
                                    </div> 
                                </div>
                                <div className="col-md-12">
                                    {this.state.imagen ? (
                                      <div className="row" style={{marginBottom: 20}}>
                                        <img src={identificador} style={{width:1000,height:300}}></img>
                                      </div>
                                    ) : (null)}
                                </div>
                            </div>        
                        ) : this.state.flg_tipo.value == "A"? (
                            <div className="col-md-4"  style={{marginTop: 30}}>
                                <ControlLabel>Subir Documento:</ControlLabel>
                                <Alert bsStyle="info">
                                    <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo2} </span>
                                </Alert>
                                <Button style={{width: '100%', marginTop:-10}} bsStyle="info" pullRight fill type="submit">
                                    <InputFiles accept={'pdf/*'}  onChange={files2 => this.subir2(files2)}> Seleccionar Documento </InputFiles>
                                </Button> 
                                {this.state.archivo2 != "" ? (
                                 <Button style={{width: '100%', marginTop:15}} bsStyle="danger" type="submit" onClick={() => { this.limpiar2() }}>Limpiar</Button>
                                ) : ( null )}  
                            </div>
                        ) : ( null )
                        }  
                    </div>
                        <Button style={{ marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Actualización</Button>
                        <Button style={{marginTop: 20, marginRight: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Actualizar Carousel</Button>
                     </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarCarousel;