import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus,paramRRHH,paramLegajo} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import ReactExport from 'react-export-excel';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ListadoTrabajadores extends Component {

    constructor(props) {
        super(props);
        this.state = {
          path: 'legajo',
          usuario: localStorage.getItem('nickname'),
          token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
          id: JSON.parse(localStorage.getItem('datos'))[0].Person,
          movimientos: [],
          multiDataSet: [],
          legajos: [],
          isLoading: true
        }
        this.GetActionFormat= this.GetActionFormat.bind(this);
    }

    async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{
                  this.listarTrabajadores();
                  //this.reporteLegajo(0);
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }

    async listarTrabajadores(){
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'legajo/listTrabajadores')
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({
                  birthDate:Calendar.calendarioA(data.BirthDate), 
                  documentNumber: data.DocumentNumber,
                  eMail: data.EMail,
                  lastName1: data.LastName1,
                  lastName2: data.LastName2, 
                  name1: data.Name1,
                  name2: data.Name2,
                  nombreCompleto: data.LastName1+" "+data.LastName2+" "+data.Name1+" "+(data.Name2==null?"":data.Name2),
                  person: data.Person,
                  secTelephone: data.SecTelephone,
                  telefono :data.Telephone == null ? "" : data.Telephone,
                  area: data.area,
                  cargo: data.cargo,
                  idarea: data.idarea,
                  idcargo: data.idcargo,
                  idtipoperson: data.idtipoperson,
                  item: data.item,
                  sex: data.sex,
                  tipoperson: data.tipoperson,
                  address:data.Address,
                  fechaingreso: data.EntryDate,
                  fechasalida: data.ReEntryDate,
                  fechapension : data.PensionInscriptionDate,
                  numcuenta: data.CTSAccount,
                  tgenero:data.tgenero,
                  ruta: "T",
                  line: data.line,
                  lineAnio:data.lineAnio,
                  fechaCese2 : data.CeaseDate,
                  nombreCese : data.LiquidationReason,
                  vCese:data.vCese,
                  nidCese: data.CeaseReason,
                  idlegajo: data.id_legajo,
                  estadolegajo: data.des_estadolegajo
                })
            })
             this.setState({movimientos: lista, isLoading: false})
            }else{
              this.setState({movimientos: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
          })
        .catch((error) => { 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              this.setState({isLoading: false})
            }
        });
    }

  reporteLegajo(codigo){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'legajo/generarReporteLegajo?idpersona='+ codigo)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({                
              item: data.item,    
              nombre: data.nombrecompleto,
              nomcategoria: data.nomcategoria,
              nomtipodocumento: data.nomtipodocumento,
              nomarchivo: data.nom_archivo,
              nomdocumento: data.des_documento,
              fecharegistro: data.fecha_registro,
              estado: data.estado,
              motivoanula: data.motivo_anula  
            })
        })           
          this.generarExcel(lista)
        }else{
          this.setState({legajos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
    .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
    });
  }

  generarExcel(lista){
    let dataExcel = [];
    lista.map((data) => {
      dataExcel.push(
        [          
          {value: JSON.stringify(data.item), style: {font: {sz: "10.5", bold: true}, alignment: {horizontal: "center"}}},
          {value: data.nombre == null ? '' : data.nombre},
          {value: data.nomcategoria == null ? '' : data.nomcategoria},
          {value: data.nomtipodocumento == null ? '' : data.nomtipodocumento},
          {value: data.nomarchivo == null ? '' : data.nomarchivo},
          {value: data.nomdocumento == null ? '' : data.nomdocumento},
          {value: data.fecharegistro == null ? '' : data.fecharegistro},
          {value: data.estado == null ? '' : data.estado},
          {value: data.motivoanula == null ? '' : data.motivoanula},      
        ]
      )
    });
    const dataSet = [
      {
        xSteps: 5,
        columns: ["REPORTE DE LEGAJOS"],
        data: []
      },
      {
          columns: ["TRIBUNAL CONSTITUCIONAL DEL PERÚ"],   
          data: []       
      },
      {
          columns: ["ITEM", "TRABAJADOR", "LEGAJO", "DOCUMENTO", "ARCHIVO", "DESCRIPCIÓN", "FECHA REGISTRO", "ESTADO", "MOTIVO ANULACIÓN"],
          data: dataExcel
      },     
    ];
    this.setState({
      legajos: lista, 
      multiDataSet: dataSet,      
      isLoading: true
    })
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
        <div>      
            <span data-tip="Ver legajo" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.enlaceRegistro(row,'C') }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x text-primary"></i>
              <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>   
            {row.idlegajo != 0 ? 
              <span data-tip="Generar carátula" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.enlaceRegistro(row,'R') }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-warning"></i>
                <i className="fa fa-file fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span> 
              :
              null
            }
                           
        </div>     
      );
    }else{}
  }

  async enlaceRegistro(data,tipo){
    paramLegajo.legajo[0].idpersona = data.person
    paramLegajo.legajo[1].nombre = data.nombreCompleto
    paramLegajo.legajo[5].idlegajo = data.idlegajo
    localStorage.setItem('legajo', JSON.stringify(paramLegajo))     
    if(tipo == 'C'){      
      this.props.history.push('/legajoTrabajador');     
    }else{
      this.props.history.push('/generarCaratula');     
    }    
  } 

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white",width:40}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "documentNumber",text: "DNI", filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white",width:100}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nombreCompleto", text: "NOMBRES Y APELLIDOS", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:250}, headerAlign: (column, colIndex) => 'center', align: 'center'},        
      {dataField: "area", text: "ÁREA", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "cargo", text: "CARGO", filter : textFilter(),sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipoperson", text: "RÉGIMEN", filter : textFilter(),sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "estadolegajo", text: "LEGAJO", filter : textFilter(),sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:130}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white", width:120}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Trabajadores</h4>
              {/* <div style={{paddingBottom: 50}}>                
                <ExcelFile filename="ReporteLegajo" element={<Button style={{width: 150, marginLeft: 10}} bsStyle="success" pullRight fill type="submit">Exportar legajos</Button>} >
                    <ExcelSheet dataSet={this.state.multiDataSet} name="Reporte">                      
                    </ExcelSheet>
                </ExcelFile>
              </div> */}
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                  )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ListadoTrabajadores;