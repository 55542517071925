import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {cec,paramCec ,fechasBusqueda} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Select from 'react-select';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class DirectorioCertificado extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'certificados',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      anio: '',
      mes: '',
      years: [],
      meses: [],
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
   
  }

 handleAnio = anio => {
      this.setState(
      { anio },
      );
    };

  handleMes = mes => {
      this.setState(
      { mes },
      );
  };


  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.generarPeriodos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }


  generarPeriodos(){
    let year =  2000;
    let cont = 26;
    let array = Array.from(new Array(cont),(val, index) => index + year);
    let arrayMes = [
      {"nid": "01", "descripcion": "ENERO"},
      {"nid": "02", "descripcion": "FEBRERO"},
      {"nid": "03", "descripcion": "MARZO"},
      {"nid": "04", "descripcion": "ABRIL"},
      {"nid": "05", "descripcion": "MAYO"},
      {"nid": "06", "descripcion": "JUNIO"},
      {"nid": "07", "descripcion": "JULIO"},
      {"nid": "08", "descripcion": "AGOSTO"},
      {"nid": "09", "descripcion": "SETIEMBRE"},
      {"nid": "10", "descripcion": "OCTUBRE"},
      {"nid": "11", "descripcion": "NOVIEMBRE"},
      {"nid": "12", "descripcion": "DICIEMBRE"},
    ]
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    if(fechasBusqueda.certificadoDir[0].nidperiodo != "" && fechasBusqueda.certificadoDir[1].periodo != ""){
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
      })
      this.setState({anio: {
        value: fechasBusqueda.certificadoDir[0].nidperiodo, 
        label: fechasBusqueda.certificadoDir[1].periodo
      }})
    }else{
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
          this.setState({anio: {value: cont2, label: data}})
        }
      })
    }
    if(fechasBusqueda.certificadoDir[2].nidmes != "" && fechasBusqueda.certificadoDir[3].mes != ""){
      arrayMes.map((data) => {
        cont3++
        lista2.push({
            item: data.cont3,
            value: data.nid,
            label: data.descripcion,
        })
      })
      this.setState({mes: {
        value: fechasBusqueda.certificadoDir[2].nidmes, 
        label: fechasBusqueda.certificadoDir[3].mes, 
      }})
    }else{
      arrayMes.map((data) => {
        cont3++
        lista2.push({
            item: data.cont3,
            value: data.nid,
            label: data.descripcion,
        })
        if(cont3 == (new Date()).getMonth() + 1){
          this.setState({mes: {value: data.nid, label: data.descripcion}})
        }
      })
    }
    this.setState({years: lista, meses: lista2})
    this.listCertificadoDirectorio()
 }


  async listCertificadoDirectorio() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${cec}` + 'aula/listCertificadoDirectorio?anio='+ this.state.anio.label + '&mes='+ this.state.mes.value)
    .then((res) => {
      if(res.data.response[0].data!= null){
         let m =[]; 
        (res.data.response[0].data).forEach((value, index) => {
           m.push(
          (res.data.response[0].data).slice(index,index+1)
          ) })
      
        let lista2 = m;
       
        if(lista2 != null){
              fechasBusqueda.certificadoDir[0].nidperiodo = this.state.anio.value
              fechasBusqueda.certificadoDir[1].periodo = this.state.anio.label
              fechasBusqueda.certificadoDir[2].nidmes = this.state.mes.value
              fechasBusqueda.certificadoDir[3].mes = this.state.mes.label
              localStorage.setItem('fechasBusqueda', JSON.stringify(fechasBusqueda))
          this.setState({movimientos: lista2, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        } }else{
        this.setState({movimientos: [], isLoading: false})
      } })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  GetActionFormat(cell,row){
    if(row != undefined){
      return(
        <div>
          <span data-tip="Eliminar Certificado" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDeleteIndividual(row) }}>
            <ReactTooltip />
            <i className="fa fa-circle fa-stack-2x"></i>
            <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
          </span>
      </div>
      )
    }else{}

  }


  confirmarDeleteMasivo = () => {
    confirmAlert({
      title: "¿Seguro que desea eliminar los certificados?",
      message: "Se eliminarán todos los certificados de " + this.state.mes.label + " del año " +  this.state.anio.label ,
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.deleteMasivo()
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async deleteMasivo(){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('anio', this.state.anio.label);
    fromData.append('mes', this.state.mes.value);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'aula/eliminarCertificadosDirectorio', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.data[0] == "ok"){
            this.listCertificadoDirectorio();
            this.props.handleClick("tl", "info", "Los certificados han sido eliminados!", "pe-7s-smile")
        }else{
            this.props.handleClick("tl", "error", "No se puede eliminar los certificados!", "pe-7s-close")
            this.setState({isLoading: false})
        }
    }else{
      this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
      this.setState({isLoading: false})
    }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }



  confirmarDeleteIndividual = (data) => {
    confirmAlert({
      title: "¿Seguro que desea eliminar el certificado?",
      message: "Se eliminará el certificado seleccionado",
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.deleteIndividual(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async deleteIndividual(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('anio', this.state.anio.label);
    fromData.append('mes', this.state.mes.value);
    fromData.append('archivo', data);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'aula/eliminarCertificadoDirIndividual', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.data[0] == "ok"){
            this.listCertificadoDirectorio();
            this.props.handleClick("tl", "info", "El certificado ha sido eliminado!", "pe-7s-smile")
        }else{
            this.props.handleClick("tl", "error", "No se puede eliminar el certificado!", "pe-7s-close")
            this.setState({isLoading: false})
        }
    }else{
      this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
      this.setState({isLoading: false})
    }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
  
  async detalle2(){
    this.props.history.push('/certificados');
  }

  render() {
    const columns = [
   // {dataField: "", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white" ,width:50}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "0", text: "CERTIFICADOS", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:300}, headerAlign: (column, colIndex) => 'center',formatter: this.GetActionFormat, align: 'center'}

    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de certificados</h4>
              <div className="row">
                  <div className="col-md-3" style={{marginBottom: 18}}>
                      <ControlLabel>PERIODO</ControlLabel>
                      <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                  </div>
                  <div className="col-md-3" style={{marginBottom: 18}}>
                      <ControlLabel>MES</ControlLabel>
                      <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses}/>
                  </div>
                  <div className="col-md-6" style={{marginBottom: 18}}>
                      <ControlLabel></ControlLabel><br></br>
                      <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.listCertificadoDirectorio() }}>Buscar Cursos</Button>
                      <Button style={{width: 250, marginTop: 5, marginBottom: 10}} bsStyle="danger" fill pullRight type="submit" onClick={() => { this.confirmarDeleteMasivo() }}>Eliminar todos los Certificados</Button>
                  </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <div>
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                          <Button style={{width: 250, marginTop: 5, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.detalle2() }}>Regresar</Button>

                      </div>
                       )}
              </div>
               </Col>
            </Row>
          </Grid>
      </div>
    );
  }
}

export default DirectorioCertificado;