import React, { Component } from "react";
import { ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import { FormControl } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, cec, fechasBusqueda, paramCec,paramAulaVirtual } from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactExport from 'react-export-excel'

const ExcelFile = ReactExport.ExcelFile; // indica el archivo excel que vamos a crear
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;//nombre de hoja de excel
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;//nombre de las columnas de excel

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Certificados extends Component {

    constructor(props) {
        super(props);
        this.state = {
            path: 'certificados',
            usuario: localStorage.getItem('nickname'),
            token: 'Bearer ' + localStorage.getItem('ConcurToken'),
            token2: 'Bearer ' + localStorage.getItem('AccessToken'),
            id: JSON.parse(localStorage.getItem('datos'))[0].Person,
            movimientos: [],
            ruta: '',
            item: '',
            code: '',
            name: '',
            course: '',
            signed: '',
            verify: '',
            nid: '',
            dni: '',
            nombres: '',
            anio: '',
            mes: '',
            years: [],
            meses: [],
            editable: true,
            isLoading: true,
            vercurso:false,
            curso:"",
            cursos:[],
            nombreIns:"",
            cursoIns:"",
            dniIns:"",
            data:[],
        }
        this.GetActionFormat = this.GetActionFormat.bind(this);
        this.publicacion = this.publicacion.bind(this);
        this.handleCode = this.handleCode.bind(this);
        this.handleNombres = this.handleNombres.bind(this);
        this.handleDni = this.handleDni.bind(this);
        this.handleRuta = this.handleRuta.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    handleCode(event) { this.setState({ code: event.target.value }); }
    handleNombres(event) { this.setState({ nombres: event.target.value }); }
    handleDni(event) { this.setState({ dni: event.target.value }); }
    handleRuta(event) { this.setState({ ruta: event.target.value }); }

    handleCurso = curso => {
        this.setState(
            { curso },
        );
    };

    handleAnio = anio => {
        this.setState(
            { anio },
        );
    };

    handleMes = mes => {
        this.setState(
            { mes },
        );
    };

    async componentDidMount() {
        if (localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null) {
            axios.get(validator + this.state.path + '/' + this.state.usuario, {
                headers: {
                    Authorization: this.state.token2
                }
            }).then((response) => {
                let permisos = response.data;
                if (permisos.codError != 1) {
                    this.props.history.goBack()
                } else {
                    this.validarLocal()
                }
            }, (error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    this.props.history.goBack()
                }
            });
        } else {
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }

    async validarLocal(){
        if(JSON.parse(localStorage.getItem("aulaVirtual")) != null){
            this.setState({
              dni:  JSON.parse(localStorage.getItem('aulaVirtual')).inscripciones[0].data.numero,
              nombres: JSON.parse(localStorage.getItem('aulaVirtual')).inscripciones[0].data.nombres,
              cursoIns: JSON.parse(localStorage.getItem('aulaVirtual')).inscripciones[0].data.nid_curso    
            });
            this.listCertificacionesGenerarData();
         }else {
          
            this.generarPeriodos()
        }
    }
    
    async listCertificacionesGenerarData() {
        this.setState({ isLoading: true })
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${cec}` + 'aula/listCertificacionesGenerarData?dni=' + this.state.dni + '&nombres=' + this.state.nombres + '&curso=' + this.state.cursoIns)
            .then((res) => {
                let lista = [];
                let lista2 = res.data.response;
                if (lista2 != null) {
                    lista2.map((data) => {
                        lista.push({
                            anio: data.anio,
                            codigo: data.codigo,
                            curso: data.curso,
                            dni: data.dni,
                            mes: data.mes,
                            nombres: data.nombres,
                            path: data.path
                        })
                    })
                    this.setState({ data: lista, code: lista[0].codigo, ruta: lista[0].path })
                    this.generarPeriodos();
                } else {
                    this.setState({ data: [], isLoading: false })
                    this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })
                }
            });
       }

       async guardarCertificadoIndividual(){  
        this.setState({isLoading: true})
          let fromData = new FormData();
            fromData.append('codigo', this.state.data[0].codigo);
            fromData.append('path', this.state.data[0].path);
            fromData.append('dni', this.state.data[0].dni);
            fromData.append('nombres', this.state.nombres);
            fromData.append('mes', this.state.data[0].mes);
            fromData.append('anio', this.state.data[0].anio); 
            fromData.append('curso', this.state.data[0].curso); 
          axios.defaults.headers.common['Authorization'] = this.state.token
          axios.post(`${cec}` + 'aula/insertCertificadosIndividual', fromData) 
          .then((response) => {
             this.state.c ++
             this.setState({c : this.state.c ++})
            var datos = response.data
            if(datos.response != null){
                this.limpiar();
                this.signOut();
                this.props.handleClick("tl", "info", "Ha sido registrado con éxito!", "pe-7s-smile")
            }else{
                this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")      
            }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })
                }
            });
    }

    generarPeriodos() {
        let year = 2000;
        let cont = 26;
        let array = Array.from(new Array(cont), (val, index) => index + year);
        let arrayMes = [
            { "nid": "01", "descripcion": "ENERO" },
            { "nid": "02", "descripcion": "FEBRERO" },
            { "nid": "03", "descripcion": "MARZO" },
            { "nid": "04", "descripcion": "ABRIL" },
            { "nid": "05", "descripcion": "MAYO" },
            { "nid": "06", "descripcion": "JUNIO" },
            { "nid": "07", "descripcion": "JULIO" },
            { "nid": "08", "descripcion": "AGOSTO" },
            { "nid": "09", "descripcion": "SETIEMBRE" },
            { "nid": "10", "descripcion": "OCTUBRE" },
            { "nid": "11", "descripcion": "NOVIEMBRE" },
            { "nid": "12", "descripcion": "DICIEMBRE" },
        ]
        let cont2 = 0;
        let cont3 = 0;
        let lista = [];
        let lista2 = [];
        if (fechasBusqueda.certificados[0].nidperiodo != "" && fechasBusqueda.certificados[1].periodo != "") {
            array.map((data) => {
                cont2++
                lista.push({
                    value: cont2,
                    label: data,
                })
            })
            this.setState({
                anio: {
                    value: fechasBusqueda.certificados[0].nidperiodo,
                    label: fechasBusqueda.certificados[1].periodo
                }
            })
        } else {
            array.map((data) => {
                cont2++
                lista.push({
                    value: cont2,
                    label: data,
                })
                if (data == (new Date()).getFullYear()) {
                    this.setState({ anio: { value: cont2, label: data } })
                }
            })
        }
        if (fechasBusqueda.certificados[2].nidmes != "" && fechasBusqueda.certificados[3].mes != "") {
            arrayMes.map((data) => {
                cont3++
                lista2.push({
                    item: data.cont3,
                    value: data.nid,
                    label: data.descripcion,
                })
            })
            this.setState({
                mes: {
                    value: fechasBusqueda.certificados[2].nidmes,
                    label: fechasBusqueda.certificados[3].mes,
                }
            })
        } else {
            arrayMes.map((data) => {
                cont3++
                lista2.push({
                    item: data.cont3,
                    value: data.nid,
                    label: data.descripcion,
                })
                if (cont3 == (new Date()).getMonth() + 1) {
                    this.setState({ mes: { value: data.nid, label: data.descripcion } })
                }
            })
        }
        this.setState({ years: lista, meses: lista2 })
        this.listarCertificados()
    }

    async listarCertificados() {
        this.signOut();
        this.setState({ isLoading: true })
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${cec}` + 'aula/listCertificado?anio=' + this.state.anio.label + '&mes=' + this.state.mes.value)
            .then((res) => {
                let lista = [];
                let lista2 = res.data.response;
                if (lista2 != null) {
                    lista2.map((data) => {
                        lista.push({
                            ruta: data.path,
                            item: data.item,
                            code: data.code,
                            name: data.name,
                            course: data.course,
                            publicado: data.signed,
                            verify: data.verify,
                            nid: data.id,
                            dni: data.dni,
                            nombres: data.nombres,
                        })
                    })
                    fechasBusqueda.certificados[0].nidperiodo = this.state.anio.value
                    fechasBusqueda.certificados[1].periodo = this.state.anio.label
                    fechasBusqueda.certificados[2].nidmes = this.state.mes.value
                    fechasBusqueda.certificados[3].mes = this.state.mes.label
                    localStorage.setItem('fechasBusqueda', JSON.stringify(fechasBusqueda))
                    this.setState({ movimientos: lista, vercurso: false })
                    this.listarCursos()
                } else {
                    this.setState({ movimientos: [], isLoading: false })
                    this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })
                }
            });
    }

    signOut() {   
        localStorage.removeItem('aulaVirtual')  
    }
    
    async listarCursos(){

        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${cec}` + 'aula/listCursos')
            .then((res) => {
                let lista = [];
                let lista2 = res.data.response;
                if (lista2 != null) {
                    lista2.map((data) => {
                        lista.push(
                            {
                                value: data.id,
                                label: data.name,
                            })
                    })
                    this.setState({ cursos: lista, curso: { value: lista[0].value, label: lista[0].label }, curso2: { value: lista[0].value, label: lista[0].label }, isLoading: false })
                } else {
                    this.setState({ cursos: [], isLoading: false })
                    this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })
                }
            });
    }

    async obtener(data) {
        this.setState({
            nid: data.nid,
            code: data.code,
            nombres: data.nombres,
            dni: data.dni,
            ruta: data.ruta,
            editable: false
        })
    }
    
    async save(){
        if(this.state.nid != '' && this.state.code != "" && this.state.nombres != "" && this.state.dni != "" && this.state.ruta != ""){
            this.update()       
        }else{
            this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
        }
    }

    update() {
        this.setState({ isLoading: true })
        let fromData = new FormData();
        fromData.append('nid', this.state.nid);
        fromData.append('codigo', this.state.code);
        fromData.append('ruta', this.state.ruta);
        fromData.append('dni', this.state.dni);
        fromData.append('name', this.state.nombres);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${cec}` + 'aula/updateCertificado', fromData)
            .then((response) => {
                var datos = response.data
                if (datos.response != null) {
                    this.limpiar();
                    this.props.handleClick("tl", "info", "El registro ha sido actualizado!", "pe-7s-smile")
                } else {
                    this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })
                }
            });
    }

    async limpiar() {
        this.cancelar();
        this.listarCertificados();
    }

    async cancelar() {
        this.setState({ nid: "", code: "", nombres: "", dni: "", ruta: "", cursoIns: "", editable: true, isLoading: false })
    }

    async regresar(){
        this.props.history.push('/inscripciones');
        this.setState({ cursoIns :""})
        this.signOut()
    }
    
   //ANULAR CERTIFICADO

    confirmarDelete = (data) => {
        confirmAlert({
            title: '¿Seguro que desea anular el certificado?',
            message: 'Se anulará el registro seleccionado.',
            buttons: [
                {
                    label: 'Aceptar',
                    onClick: () => this.delete(data)
                },
                {
                    label: 'Cancelar',
                    onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
                }
            ]
        });
    };

    async delete(data) {
        this.setState({ isLoading: true })
        let fromData = new FormData();
        fromData.append('nid', data.nid);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${cec}` + 'aula/anularCertificado', fromData)
            .then((response) => {
                var datos = response.data
                if (datos.response != null) {
                    if (datos.response.estado == 1) {
                        this.listarCertificados();
                        this.props.handleClick("tl", "info", "El certificado ha sido anulado!", "pe-7s-smile")
                    } else {
                        this.props.handleClick("tl", "error", "No se puede anular el certificado", "pe-7s-close")
                        this.setState({ isLoading: false })
                    }
                } else {
                    this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })
                }
            });
    }

    //PUBLICAR CERTIFICADOS POR CURSO
    confirmarPublicacionCurso = () => {
        confirmAlert({
            title: '¿Seguro que desea publicar los certificados?',
            message: 'Se publicarán todos los certificados del curso ' + this.state.curso.label,
            buttons: [
                {
                    label: 'Aceptar',
                    onClick: () => this.publicarCertificados()
                },
                {
                    label: 'Cancelar',
                    onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
                }
            ]
        });
    };

    async publicarCertificados() {
        this.setState({ isLoading: true })
        let fromData = new FormData();
        fromData.append('nid', this.state.curso.value);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${cec}` + 'aula/updatePublicacion', fromData)
            .then((response) => {
                var datos = response.data
                if (datos.response != null) {
                    if (datos.response.estado == 1) {
                        this.listarCertificados();
                        this.props.handleClick("tl", "info", "Los certificados han sido publicados!", "pe-7s-smile")
                    } else {
                        this.props.handleClick("tl", "error", "No se puede publicar los certificado!", "pe-7s-close")
                        this.setState({ isLoading: false })
                    }
                } else {
                    this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })
                }
            });
    }

    async verCursos(){
        if(this.state.vercurso == true){
          this.setState({vercurso: false})
        }else{
          this.setState({vercurso: true})
        }
    }

    GetActionFormat(cell, row) {
        if (row != undefined) {
            return (
                <div>
                    <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{ cursor: 'pointer', fontSize: 20 }} onClick={() => { this.obtener(row) }}>
                        <ReactTooltip />
                        <i className="fa fa-circle fa-stack-2x text-primary"></i>
                        <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{ fontSize: 15 }}></i>
                    </span>
                    <span data-tip="Anular Registro" className="fa-stack fa-2x" style={{ cursor: 'pointer', fontSize: 20 }} onClick={() => { this.confirmarDelete(row) }}>
                        <ReactTooltip />
                        <i className="fa fa-circle fa-stack-2x"></i>
                        <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{ fontSize: 15 }}></i>
                    </span>
                </div>
            );
        } else { }
    }

    publicacion(cell, row) {
        if (row != undefined) {
            return (
                <div>
                    {row.publicado == "0" ?
                        <Button style={{ color: "red", border: 0, fontSize: 13 }}>SIN PUBLICAR</Button>
                        :
                        <Button style={{ color: "BLUE", border: 0, fontSize: 13 }}>PUBLICADO</Button>
                    }
                </div>
            );
        } else { }
    }

    async directorioCertificados() {
        this.props.history.push('/directoriocertificado');
    }

    //DELETE CERTIFICADOS POR CURSO - MASIVO

    confirmarDeleteCurso = () => {
        confirmAlert({
            title: '¿Seguro que desea eliminar  los certificados?',
            message: 'Se eliminarán todos los certificados del curso ' + this.state.curso.label,
            buttons: [
                {
                    label: 'Aceptar',
                    onClick: () => this.deleteCertificados()
                },
                {
                    label: 'Cancelar',
                    onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
                }
            ]
        });
    };

    async deleteCertificados() {
        this.setState({ isLoading: true })
        let fromData = new FormData();
        fromData.append('nidcurso', this.state.curso.value);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${cec}` + 'aula/deleteCertificados', fromData)
            .then((response) => {
                var datos = response.data
                if (datos.response != null) {
                    if (datos.response.estado == 1) {
                        this.listarCertificados();
                        this.setState({ vercurso: false })
                        this.props.handleClick("tl", "info", "Los certificados han sido eliminados!", "pe-7s-smile")
                    } else {
                        this.props.handleClick("tl", "error", "No se puede eliminar los certificados!", "pe-7s-close")
                        this.setState({ isLoading: false })
                    }
                } else {
                    this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })
                }
            });
    }


    render() {
        const columns = [
            { dataField: "item", text: "ITEM", headerStyle: { backgroundColor: '#337AB7', color: "white", width: 50 }, headerAlign: (column, colIndex) => 'center', align: 'center' },
            { dataField: "dni", text: "DNI", filter: textFilter(), headerStyle: { backgroundColor: '#337AB7', color: "white", width: 90 }, headerAlign: (column, colIndex) => 'center', align: 'center' },
            { dataField: "nombres", text: "PERSONA", filter: textFilter(), sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white" }, headerAlign: (column, colIndex) => 'center', align: 'center' },
            { dataField: "code", text: "CODIGO", filter: textFilter(), sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white" , width: 190 }, headerAlign: (column, colIndex) => 'center', align: 'center' },
            { dataField: "name", text: "CURSO", filter: textFilter(), sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white" }, headerAlign: (column, colIndex) => 'center', align: 'center' },           
            { dataField: "ruta", text: "ENLACE", headerStyle: { backgroundColor: '#337AB7', color: "white", width: 365}, headerAlign: (column, colIndex) => 'center', align: 'center' },
            { dataField: "publicado", text: "ESTADO",   headerStyle: { backgroundColor: '#337AB7', color: "white" ,width:130}, headerAlign: (column, colIndex) => 'center',  formatter: this.publicacion , align: 'center' },
            { dataField: "detail", text: "ACCIONES", headerStyle: { backgroundColor: '#337AB7', color: "white", width: 120}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat }
        ]

        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'Primera',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Última',
            showTotal: true,
            totalSize: this.state.movimientos.length
        };
        const contentTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <div>
                    <div>
                        <BootstrapTable
                            striped
                            hover
                            keyField="item"
                            data={this.state.movimientos}
                            columns={columns}
                            filter={filterFactory()}
                            noDataIndication="No existen registros para mostrar"
                            {...paginationTableProps}
                        />
                    </div>
                    <div style={{ float: "right" }}><PaginationListStandalone {...paginationProps} /></div>
                </div>
            </div>
        );
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <div style={{ borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5 }}>
                                <h4 className="title" style={{ fontWeight: 200, marginTop: 18, marginBottom: 25 }}>CERTIFICADOS</h4>
                                <div className="row">
                                    <div className="col-md-3" style={{ marginBottom: 18 }}>
                                        <ControlLabel>DNI</ControlLabel>
                                        <FormControl type="number" value={this.state.dni} onChange={this.handleDni} disabled={this.state.editable} />
                                    </div>
                                    <div className="col-md-7" style={{ marginBottom: 18 }}>
                                        <ControlLabel>NOMBRES Y APELLIDOS</ControlLabel>
                                        <FormControl type="text" value={this.state.nombres} onChange={this.handleNombres} disabled={this.state.editable} />
                                    </div>
                                    <div className="col-md-3" style={{ marginBottom: 18 }}>
                                        <ControlLabel>CÓDIGO</ControlLabel>
                                        <FormControl type="text" value={this.state.code} onChange={this.handleCode} disabled={this.state.editable}/>
                                    </div> 
                                    <div className="col-md-7" style={{ marginBottom: 18 }}>
                                        <ControlLabel>ENLACE</ControlLabel>
                                        <FormControl type="text" value={this.state.ruta} onChange={this.handleRuta} disabled={this.state.editable} />
                                    </div >
                                {this.state.cursoIns != "" ? 
                                    <div>
                                        <div className="col-md-2" style={{ marginBottom: 18 }}>
                                            <Button style={{ width: 250, marginTop: -83, marginBottom: 20 }} bsStyle="info" fill type="submit"  onClick={() => { this.guardarCertificadoIndividual() }}>Guardar</Button>
                                            <Button style={{ width: 250, marginTop: 10, marginBottom: 20 }} bsStyle="danger" fill type="submit"  onClick={() => { this.regresar() }}>Cancelar </Button>   
                                        </div>
                                        <div className="col-md-10" style={{ marginBottom: 18 }}>
                                            <ControlLabel style={{fontStyle: 'italic'}}>Nota:</ControlLabel>
                                            <p style={{fontStyle: 'italic'}}>Los datos del alumno seleccionado en inscripciones están reflejados en los campos, haga click en GUARDAR para generar el certificado</p>
                                        </div >
                                    </div>
                                :
                                    <div className="col-md-2" style={{ marginBottom: 18 }}>
                                       <Button style={{ width: 250, marginTop: -83, marginBottom: 20}} bsStyle="success" fill type="submit"  onClick={() => { this.save() }}disabled={this.state.editable}>Guardar</Button> 
                                       <Button style={{ width: 250, marginTop: 10, marginBottom: 20 }} bsStyle="danger" fill type="submit"  disabled={this.state.editable} onClick={() => { this.cancelar() } }>Cancelar</Button>                                          
                                    </div>
                                }
                                </div>
                                <div className="row"> 
                                <hr  style={{ background: "#337AB7", height: "2px",border: "none" }} />

                                    <h4 className="title" style={{ fontWeight: 200, marginTop: 18, marginBottom: 25, marginLeft:15 }}>Lista de certificados por periodo</h4>
                                    <div className="row-md-12">
                                        <div className="col-md-2" style={{ marginBottom: 18 }}>
                                            <ControlLabel>PERIODO</ControlLabel>
                                            <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years} />
                                        </div>
                                        <div className="col-md-2" style={{ marginBottom: 18 }}>
                                            <ControlLabel>MES</ControlLabel>
                                            <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses} />
                                        </div>
                                        <div className="col-md-2" style={{ marginBottom: 18 }}>
                                            <ControlLabel></ControlLabel><br></br>
                                            <Button style={{ width: 250, marginTop: 5, marginBottom: 10 }} bsStyle="info" fill type="submit" onClick={() => { this.listarCertificados() }}>Buscar Certificados</Button>
                                        </div>
                                        <div className="col-md-2" style={{ marginBottom: 18 }}>
                                            <ControlLabel></ControlLabel><br></br>
                                            <ExcelFile element={<Button style={{ backgroundColor: "green", marginTop: 5, border: 0, width: 250, height: 40, marginBottom: 5 }} fill type="submit">Exportar a Excel</Button>} filename={"CERTIFICADOS DE " + this.state.mes.label + " DEL AÑO " + this.state.anio.label}>
                                                <ExcelSheet data={this.state.movimientos} name={"CERTIFICADOS DE " + this.state.mes.label + "-" + this.state.anio.label}>
                                                    <ExcelColumn label="ITEM" value="item" ></ExcelColumn>
                                                    <ExcelColumn label="DNI" value="dni" ></ExcelColumn>
                                                    <ExcelColumn label="PERSONA" value="nombres" ></ExcelColumn>
                                                    <ExcelColumn label="CÓDIGO" value="code" ></ExcelColumn>
                                                    <ExcelColumn label="CURSO" value="name" ></ExcelColumn>
                                                </ExcelSheet>
                                            </ExcelFile>
                                        </div>
                                        <div className="col-md-2" style={{ marginBottom: 18 }}>
                                            <ControlLabel></ControlLabel><br></br>
                                            <Button style={{ width: 235, marginTop: 5, marginRight: 20, marginBottom: 10 }} bsStyle="primary" fill type="submit" onClick={() => { this.verCursos() }}>Cursos</Button>
                                        </div>
                                        <div className="col-md-2" style={{ marginBottom: 18 }}>
                                            <ControlLabel></ControlLabel><br></br>
                                            <Button style={{ width: 240, marginTop: 5, marginRight: 5, marginBottom: 10 }} bsStyle="warning" fill type="submit" onClick={() => { this.directorioCertificados() }}>Directorios</Button>
                                        </div>
                                    {this.state.vercurso ? (
                                        <div>
                                            <h5 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25, marginLeft:15}}>Publicación y eliminación de certificados por curso</h5>
                                            <div className="col-md-8" style={{ marginBottom: 18 }}>
                                                <ControlLabel>CURSOS</ControlLabel>
                                                <Select value={this.state.curso} onChange={this.handleCurso} options={this.state.cursos} />
                                            </div>
                                            <div className="col-md-2" style={{marginBottom: 18}}>
                                                <ControlLabel></ControlLabel><br></br>
                                                <Button style={{width: 200, marginTop: 5,  marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.confirmarPublicacionCurso() }}>PUBLICAR CERTIFICADOS</Button>
                                            </div>
                                            <div className="col-md-2" style={{marginBottom: 18}}>
                                                <ControlLabel></ControlLabel><br></br>
                                                <Button style={{width: 200, marginTop: 5, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.confirmarDeleteCurso() }}>ELIMINAR CERTIFICADOS</Button>
                                            </div>
                                        </div>
                                    ) : (null)}
                                    </div>
                                </div>
                                {this.state.isLoading ? (
                                    <ClipLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={50}
                                        color={'#123abc'}
                                        loading={this.state.loading}
                                    />
                                ) : (
                                    <PaginationProvider
                                        pagination={
                                            paginationFactory(options)
                                        }
                                    >
                                        {contentTable}
                                    </PaginationProvider>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default Certificados;