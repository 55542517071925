import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import auth0Client from 'login/Auth';
import axios from 'axios';
import { gestor, sisper, sisperplus ,paramRRHH} from 'store/Apis'
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Calendar from "components/Calendar/Calendar.jsx";
import InputFiles from 'react-input-files';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { validator } from 'store/Apis'
import './lista.css';
import { confirmAlert } from 'react-confirm-alert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class VerPapeletaAdm extends Component {

constructor(props) {
super(props);
  this.state = {
    path: 'administrarPapeletas',
    dni: localStorage.getItem('dni'),
    usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
    id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
    token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
    fechaini: '',
    fechafin: '',
    archivo:"",
    selectedOption: '',
    documentcomments:"",
    observacion:"",
    fecharegistro:"",
    estado:"",
    isGoing: "",
    idpapeleta: '',
    idperson:"",
    papeletas: [],
    archivos: [],
    file: [],
    fileName: '',
    type: '',
    isLoading: true,
    isLoading2: true,
    editable: true,
    enlace:"",
    nombreeee:""
  }

  this.handleFechaIni = this.handleFechaIni.bind(this);
  this.handleFechaFin = this.handleFechaFin.bind(this);
  this.handleMotivo = this.handleMotivo.bind(this);
  this.handleSustento = this.handleSustento.bind(this);
  this.handleCheck = this.handleCheck.bind(this);
}

  handleFechaIni(event) {this.setState({fechaini: event.target.value});}
  handleFechaFin(event) {this.setState({fechafin: event.target.value});}
  handleMotivo(event) {this.setState({observacion: event.target.value});}
  handleSustento(event) {this.setState({documentcomments: event.target.value});
}

  handleCheck(event) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      this.setState({
        [name]: value
      });
  }

  handleChange = selectedOption => {
      this.setState(
      { selectedOption },
      );
  };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
              this.validarLocal();
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(JSON.parse(localStorage.getItem("recursoshumanos")).papeletaAdm[0].data !== null){
        this.setState({
            fechaini : Calendar.calendario11(JSON.parse(localStorage.getItem('recursoshumanos')).papeletaAdm[0].data.fechaIni),
            fechafin : Calendar.calendario11(JSON.parse(localStorage.getItem('recursoshumanos')).papeletaAdm[0].data.fechaFin),
            archivo: JSON.parse(localStorage.getItem('recursoshumanos')).papeletaAdm[0].data.archivo,
            selectedOption: {value: JSON.parse(localStorage.getItem('recursoshumanos')).papeletaAdm[0].data.idtipopapeleta, label: JSON.parse(localStorage.getItem('recursoshumanos')).papeletaAdm[0].data.tipopapeleta},
            documentcomments: JSON.parse(localStorage.getItem('recursoshumanos')).papeletaAdm[0].data.documentcomments,
            isGoing: JSON.parse(localStorage.getItem('recursoshumanos')).papeletaAdm[0].data.flagjustifica == 'N' ? false : true,
            idpapeleta: JSON.parse(localStorage.getItem('recursoshumanos')).papeletaAdm[0].data.idpapeleta,
            observacion: JSON.parse(localStorage.getItem('recursoshumanos')).papeletaAdm[0].data.observacion,
            fecharegistro: JSON.parse(localStorage.getItem('recursoshumanos')).papeletaAdm[0].data.fecharegistro,
            dni: JSON.parse(localStorage.getItem('recursoshumanos')).papeletaAdm[0].data.dni,
            nombres: JSON.parse(localStorage.getItem('recursoshumanos')).papeletaAdm[0].data.nombres,
            estado: JSON.parse(localStorage.getItem('recursoshumanos')).papeletaAdm[0].data.estado,
            idperson: JSON.parse(localStorage.getItem('recursoshumanos')).papeletaAdm[0].data.idperson,
            enlace: JSON.parse(localStorage.getItem('recursoshumanos')).papeletaAdm[1].enlace,
            isLoading: false,
            isLoading2: false
        })
        this.tiposPapeletas();
    }else{
      this.props.history.push('/administrarPapeletas');
    }
  }


  async tiposPapeletas(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'papeletas/tiposPapeletas?param=0')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.tipopapeleta,
                label: data.descripcion,
            })
          });
          this.setState({papeletas: lista})
          this.cargaView();
      }else{
        this.setState({papeletas: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async cargaView() {
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'papeletas/listaPapeletas?id=' + this.state.idpapeleta)
        .then((res) => {
            let lista = res.data.response;
            if(lista != null){
            this.setState({archivos: lista, isLoading: false})
        }else{
            this.setState({archivos: [], isLoading: false})
            this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
        }
        })
        .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
        });
    }


    guardar(){
        if(this.state.type == '' || this.state.type == 'application/pdf' || this.state.type == 'image/jpeg' || this.state.type == 'image/png'){
          if(this.state.fechaini != '' && this.state.fechafin != '' && this.state.observacion != ''){
              this.setState({isLoading: true})
              let fromData = new FormData();
              fromData.append('pape', this.state.idpapeleta);
              fromData.append('tipopapeleta', this.state.selectedOption.value);
              fromData.append('observaciones', this.state.observacion);
              fromData.append('fechaini', this.cambioFormat(this.state.fechaini));
              fromData.append('fechafin', this.cambioFormat(this.state.fechafin));
              fromData.append('documentcomments', this.state.documentcomments == "" ? "" : this.state.documentcomments);
              fromData.append('flagjustifica', this.cambioCheck(this.state.isGoing));
              axios.defaults.headers.common['Authorization'] = this.state.token
              axios.post(`${sisperplus}` + 'papeletaAdm/editarPapeletaAdm', fromData)
              .then((response) => {
                var datos = response.data
                if(datos.response == null){
                  if(this.state.file != ''){
                      this.subirArchivo()
                  }else{
                      this.props.handleClick("tl", "info", "Tu papeleta ha sido actualizada!", "pe-7s-smile")
                      this.cancelar()
                  }
                }else{
                  this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
                  this.setState({isLoading: false})
                }
              })
              .catch((error) =>{ 
                if(auth0Client.isAuthenticated() === true){
                  auth0Client.signOut()
                  this.props.history.replace('/');
                }else{
                  console.log(error);
                  this.setState({isLoading: false})
                }
              });
          }else{
            this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
          }
        }else{
          this.props.handleClick("tl", "warning", "El archivo a subir debe ser un pdf o una imagen!", "pe-7s-info")
        }
    }

    subirArchivo(){
        let fromData = new FormData();
        fromData.append('pape', this.state.idpapeleta);
        fromData.append('filename', this.state.fileName);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'papeletaAdm/insertarArchivoAdm', fromData)
        .then((response) => {
          var datos = response.data.response
          if(datos.archivo != ''){
            this.uploadArchivo(datos.archivo)
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al subir archivo!", "pe-7s-close")
            this.setState({isLoading: false})
          }
        })
        .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }

    uploadArchivo(archivo){
        let fecha = this.state.fecharegistro;
        let mes = fecha.substring(3,5)
        let anio = fecha.substring(6,10)
        let fromData = new FormData();
        fromData.append('usuario', this.state.usuario);
        fromData.append('archivo_file', this.state.file);
        fromData.append('anio', anio);
        fromData.append('mes', mes);
        fromData.append('cnomArchivo', archivo);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${gestor}` + 'document/subirArchivoPap', fromData)
        .then((response) => {
        if(response.data.result == null){
            this.props.handleClick("tl", "info", "Tu papeleta ha sido actualizada!", "pe-7s-smile")
            this.cancelar()
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al guardar archivo!", "pe-7s-close")
            this.setState({isLoading2: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
        });
  }
 
  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea eliminar el archivo?',
      message: 'Se eliminará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.remove(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  remove(data){
    this.setState({isLoading2: true})
    let fromData = new FormData();
    fromData.append('pape', data.idpapeleta);
    fromData.append('archivo', data.filename);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'papeletas/eliminarArchivo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response == null){
         this.removerArchivo(data);
      }else{
         this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
         this.setState({isLoading2: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading2: false})
      }
    });
   }


   removerArchivo(archivo){
    let anio = Calendar.calendario10(archivo.xlastdate).substring(0,4)
    let mes = Calendar.calendario10(archivo.xlastdate).substring(5,7)
    let fromData = new FormData();
    fromData.append('usuario', this.state.usuario);
    fromData.append('anio', anio);
    fromData.append('mes', mes);
    fromData.append('cnomArchivo', archivo.filesource);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${gestor}` + 'document/bajarArchivoPap', fromData)
    .then((response) => {
    if(response.data.result == null){
        this.cargaView()
        this.props.handleClick("tl", "info", "Tu archivo ha sido eliminado!", "pe-7s-smile")
        this.setState({isLoading2: false})
    }else{
        this.props.handleClick("tl", "error", "Hubo un problema al guardar archivo!", "pe-7s-close")
        this.setState({isLoading2: false})
    }
    })
    .catch((error) =>{ 
    if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
    }else{
        console.log(error);
        this.setState({isLoading: false})
    }
    });
  }

  async cancelar(){
    if(this.state.enlace == "T"){
      this.props.history.push('/verPapeletaTrabajador');     
    }else{
         this.props.history.push('/administrarPapeletas');
    }   
  }

  subir = (files) => {
      if(files.length !== 0){
          this.setState({file: files[0], fileName: files[0].name, type: files[0].type, visFile: true});
      }
  }

  cambioCheck(check){
      if(check == true){
      return 'Y';
      }else{
      return 'N';
      }
  }

  cerrar(){
      this.setState({visFile: false});
  } 

  ver(data){
      let datosvisor = [{filename: data.filename,
                        filesource: data.filesource,
                        mes: Calendar.calendario10(data.xlastdate).substring(5,7),
                        anio: Calendar.calendario10(data.xlastdate).substring(0,4),
                        estado : "2",
                        tipo :"/adjuntos/" 
                        }]
                        window.open('http://181.177.234.7/papeletas/adjuntos/' + datosvisor[0].anio + "/" + datosvisor[0].mes + "/" + datosvisor[0].filesource)
     // window.open( "https://gestordocumentaltc.sedetc.gob.pe/index.php/s/ag7Dq3JcdRKr5Zg/download?path=%2F&files="+ datosvisor[0].anio + "/" + datosvisor[0].mes + "/" + datosvisor[0].filesource)
//     localStorage.setItem('visor', JSON.stringify(datosvisor))
   //  this.props.history.push('/visordetalle');
  }

  cambioFormat(fecha){
      var dia = fecha.substring(8,10);
      var mes = fecha.substring(5,7);
      var anio = fecha.substring(0,4);
      var newfecha = dia + '-' + mes + '-' + anio
      return newfecha
  }

  editar(enabled){
      if(enabled == true){
          this.setState({editable: false})
      }else{
          this.guardar()
      }
  }

  async limpiar(){
    this.setState({fileName: "", file: ""})
  }


  async download(data){
    console.log(data)
    let anio = Calendar.calendario10(data.xlastdate).substr(0,4)
    let mes =  Calendar.calendario10(data.xlastdate).substr(5,2) 
    let archivo = data.filesource
    window.open("https://gestordocumentaltc.sedetc.gob.pe/index.php/s/ag7Dq3JcdRKr5Zg/download?path=%2F&files="+ anio + "/"+ mes +"/"+ archivo, "_blank")
  }

render() {
    const { selectedOption } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
            <Card
                 content={
                  this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                  <div>
                   <h4 className="title" style={{fontWeight: 200, marginBottom: 25}}>PAPELETA DE  <a style={{fontWeight: "bold", color: "black",fontSize: 21}}>{this.state.nombres}</a></h4>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>DNI</ControlLabel>
                            <FormControl type="text" value={this.state.dni} disabled/>
                        </div>
                        <div className="col-md-8" style={{marginBottom: 18}}>
                            <ControlLabel>NOMBRES Y APELLIDOS</ControlLabel>
                            <FormControl type="text" value={this.state.nombres} disabled/>
                        </div>            
                    </div>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>TIPO DE PAPELETA<a style={{color:"red"}}> *</a></ControlLabel>
                            <Select value={selectedOption} onChange={this.handleChange} options={this.state.papeletas} isDisabled={this.state.editable}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DE INICIO<a style={{color:"red"}}> *</a></ControlLabel>
                            <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaIni} disabled={this.state.editable}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DE TERMINO<a style={{color:"red"}}> *</a></ControlLabel>
                            <FormControl type="date" value={this.state.fechafin} onChange= {this.handleFechaFin} disabled={this.state.editable}/>
                        </div>
                    </div>
                    <FormInputs
                      ncols={["col-md-4", "col-md-4"]}
                      properties={[
                        {label: "FECHA DE REGISTRO", type: "text", bsClass: "form-control", defaultValue: this.state.fecharegistro, disabled: true},
                        {label: "AUTORIZACIÓN", type: "text", bsClass: "form-control", defaultValue: this.state.estado, disabled: true},
                      ]}
                    />
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>Motivo<a style={{color:"red"}}> *</a></ControlLabel>
                            <FormControl componentClass="textarea" style={{  height: 150, maxHeight: 150, minHeight: 150, width: "100%", maxWidth:"100%", minWidth:"100%" ,textAlign: "justify"}} value={this.state.observacion} onChange= {this.handleMotivo} disabled={this.state.editable}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>DOCUMENTO SUSTENTO <a>(OPCIONAL)</a></ControlLabel>
                            <FormControl componentClass="textarea" style={{ height: 150, maxHeight: 150, minHeight: 150, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.documentcomments} onChange= {this.handleSustento} disabled={this.state.editable}/>
                        </div>
                        <div className="col-md-4" style={{letterSpacing: 1}}>
                        <ControlLabel>JUSTIFICA INGRESO FUERA DE HORARIO</ControlLabel><br></br>
                        <div>
                            <input onChange={this.handleCheck} name="isGoing" type="checkbox" checked={this.state.isGoing} style={{marginRight: 5}} disabled={this.state.editable}
                            /><a>Marque solamente cuando esta papeleta justificará un ingreso fuera de horario.</a>
                        </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: 18, marginBottom: 20}}>
                        <div className="col-md-3"></div>
                        <div className="col-md-6" style={{marginBottom: 18}}>
                            <ControlLabel>ARCHIVOS SUBIDOS <a>(OPCIONAL)</a></ControlLabel>
                            {this.state.isLoading2 ? (
                            <ClipLoader
                                css={override}
                                sizeUnit={"px"}
                                size={50}
                                color={'#123abc'}
                                loading={this.state.loading2}
                            />
                            ) : (
                            this.state.archivos.length != 0 ? (
                            <ul className="todo-list">
                            {this.state.archivos.map((data,index) =>
                                <li key={index}>
                                    {/* <div className="icon">
                                        <img src={require(`../images/${this.props.data.image}`)}  alt="hambuerger" />
                                    </div> */}
                                   <div className="price">
                                        <h3>{data.item}</h3>
                                    </div>
                                    <div className="name">
                                        <span className="item-name">{data.filename}</span>
                                        <span className="item-price">{data.idpapeleta}</span>
                                    </div>
                                    {/* <div className="price">
                                        <h3>{data.item}</h3>
                                    </div> */}
                                     <span className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20, marginRight: 5}} onClick={() => { this.download(data) }}>
                                        <i className="fa fa-circle fa-stack-2x" style={{color:"#1B4F72"}}></i>
                                        <i className="fa fa-download fa-stack-1x fa-inverse" style={{fontSize: 18, color:"white"}}></i>
                                    </span>
                                    <span  className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20, marginRight: 5}} onClick={() => { this.ver(data) }}>
                                        <i className="fa fa-circle fa-stack-2x text-danger"></i>
                                        <i className="fa fa-file-pdf-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                    </span>
                                    <span  className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(data) }}>
                                        <i className="fa fa-circle fa-stack-2x"></i>
                                        <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                    </span>
                                </li>
                                )
                            }
                            </ul>): (null)
                            )}
                            <Alert bsStyle="warning">
                                {/* <button onClick={() => { this.cerrar() }} type="button" aria-hidden="true" className="close">&#x2715;</button> */}
                                <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.fileName}</span>
                            </Alert>
                            {this.state.editable == false ? (
                            <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit" >
                                <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                            </Button>
                            
                            ): (null)}
                            {this.state.fileName != "" ? (
                                  <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar Archivo</Button>
                                ) : (  null )}
                            <h4 className="category" style={{fontSize: 15, marginTop: 5}}>Los documentos adjuntos de tu papeleta se reflejaran en el sistema despues de 15 minutos de haberse subido debido a la sincronización con la web.</h4>
                        </div>
                    </div>
                        <Button style={{width: 200, marginLeft: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Cancelar Actualización</Button>
                        {this.state.estado != 'AUTORIZADO' ? (
                        this.state.editable == true ? (
                         <Button style={{width: 150, marginLeft: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.editar(this.state.editable) }}>Editar Papeleta</Button>): (
                         <Button style={{width: 150, marginLeft: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar(this.state.editable) }}>Guardar Papeleta</Button>)
                    ): (null)}
                  </div>
                 )}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default VerPapeletaAdm;