import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { jurisdiccional} from 'store/Apis'
import 'react-tabs/style/react-tabs.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class CrearPenalidades extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'penalidades',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      nombre: "", 
      expediente:"",
      resolucion:"",
      dni:"",
      penalidad:"",
      estado: 0,
      isLoading: false,
      show: true,
      condicion: {value: "Abogado", label: "Abogado"},
      condiciones: [    
        {value: "Abogado", label: "Abogado"},
        {value: "Demandante", label: "Demandante"},
        {value: "Demandado", label: "Demandado"}
      ] ,
      fecha:  moment(new Date()).format('YYYY-MM-DD'),  
      colegiado:""
        
    }
    this.handleNombre = this.handleNombre.bind(this);
    this.handleExpediente = this.handleExpediente.bind(this);
    this.handleDni = this.handleDni.bind(this);
    this.handlePenalidad = this.handlePenalidad.bind(this);
    this.handleResolucion = this.handleResolucion.bind(this);
    this.handleFecha = this.handleFecha.bind(this);
    this.handleColegiado = this.handleColegiado.bind(this);
  
  }
  handleCondicion = condicion => {
    this.setState(
        {condicion},
    );
   };
    handleNombre(event) {this.setState({nombre: event.target.value});}
    handleExpediente(event) {this.setState({expediente: event.target.value});}
    handleDni(event) {this.setState({dni: event.target.value});}
    handlePenalidad(event) {this.setState({penalidad: event.target.value});}
    handleResolucion(event) {this.setState({resolucion: event.target.value});}
    handleFecha(event) {this.setState({fecha: event.target.value});}
    handleColegiado(event) {this.setState({colegiado: event.target.value});}

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
  
  async validar(){
    if(this.state.nombre != ''  && this.state.expediente != ''  && this.state.dni != '' 
       && this.state.penalidad != '' && this.state.resolucion !=''&& this.state.fecha != '' && this.state.colegiado!=''){
      this.agregar();
     }else{
       this.props.handleClick("tl", "warning", "Debe completar los campos solicitados!", "pe-7s-close") 
     }
   }

  async agregar(){
    this.setState({isLoading: true})  
    let fromData = new FormData();
    fromData.append('nombre', this.state.nombre);
    fromData.append('dni', this.state.dni);
    fromData.append('penalidad', this.state.penalidad);
    fromData.append('expediente', this.state.expediente);
    fromData.append('colegiado', this.state.colegiado);
    fromData.append('condicion', this.state.condicion.value);
    fromData.append('resolucion', this.state.resolucion);
    fromData.append('fecha', this.state.fecha);
    fromData.append('usuario',this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'penalidades/insertarPenalidades', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Se ha registrado correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async regresar(){
    this.props.history.push('/penalidades');
  }
  abrir(ruta){
    window.open(ruta, '_blank');
  } 
  mostrar = () => {
    confirmAlert({
      title: <i class="fa fa-user" style={{ color :"black", marginLeft: 130,fontSize: 100}}></i>,
      message:<div><p>USUARIO : Usuario_01</p><p>CONTRASEÑA : Tribunal2022$</p> </div> ,
      buttons: [
        {
          label: 'OK',
        },        
      ],
      closeOnClickOutside: false,
    });}


  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Nuevo Registro</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                      <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-file-text-o"></i> NÚMERO DE EXPEDIENTE </ControlLabel>
                                <FormControl type="text" value={this.state.expediente} onChange= {this.handleExpediente}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-file-text-o"></i> COLEGIADO</ControlLabel>
                                <FormControl type="text" value={this.state.colegiado} onChange= {this.handleColegiado}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-user"></i> NOMBRES Y APELLIDOS</ControlLabel>
                                <FormControl type="text" value={this.state.nombre} onChange= {this.handleNombre}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel><i class="fa fa-gavel"></i>CONDICIÓN</ControlLabel>
                                <Select value={this.state.condicion} onChange={this.handleCondicion} options={this.state.condiciones}/>
                            </div>
                     
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-address-card-o"></i> DNI </ControlLabel>
                                <FormControl type="text" value={this.state.dni} onChange= {this.handleDni}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-usd"></i> PENALIDAD</ControlLabel>
                                <FormControl type="text" value={this.state.penalidad} onChange= {this.handlePenalidad}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel><i class="fa fa-calendar" aria-hidden="true"></i> FECHA</ControlLabel>
                                <FormControl type="date" value={this.state.fecha} onChange= {this.handleFecha}/>
                            </div>
                            <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-link"></i> LINK DE LA RESOLUCIÓN</ControlLabel>
                                <FormControl type="text" value={this.state.resolucion} onChange= {this.handleResolucion}/>
                            </div>
                            {/* <div className="col-md-12" style={{marginBottom: 18}}>
                              <Button style={{width: 150}} bsStyle="primary" pullRight  type="submit" onClick={() => { this.mostrar() }}>Ver Credenciales <i class="fa fa-unlock-alt"></i></Button>
                             <Button style={{width: 500,marginRight:5}} bsStyle="primary" pullRight type="submit" onClick={() => { this.abrir("https://www.tc.gob.pe/wp-login.php") }}>
                              Haga click aquí para subir del documento de la resolución <i class="fa fa-upload"></i></Button>
                            </div> */}
                      </div>
                      <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Registro</Button>
                      <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Guardar Registro</Button>
                    </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CrearPenalidades;