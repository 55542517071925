import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { sisperplus} from 'store/Apis'
import 'react-tabs/style/react-tabs.css';
import moment from 'moment'
import Select from 'react-select';
import Calendar from "components/Calendar/Calendar";
import 'react-accessible-accordion/dist/fancy-example.css';
import { confirmAlert } from 'react-confirm-alert';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class CrearTrabajadores extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarTrabajador',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      isLoading: true,
      fechaingreso:  moment(new Date()).format('YYYY-MM-DD'),
      cargo:"",
      modalidad:"",
      modalidades:[],
      cargos:[],
      tipo:"",
      tipos:[],
      area: {value: "2940", label: "CEC - DIRECCION ACADEMICA"},
      areas:[],
      selectedOption:'',
      dni:'',
      name1:'',
      name2:'',
      lastName1:'',
      lastName2:'',
      address:'',
      telefono:'',
      email:'',
      celular:'',
      fechacumple:'',
      fechasalida:'',
      fechapension:'',
      numcuenta:'',
      nombre:'',
      genero: {value: "1", label: "MASCULINO"},
      generos: [    
        {value: "1", label: "MASCULINO"},
        {value: "2", label: "FEMENINO"}
      ]
    
    }
    this.handleModalidad = this.handleModalidad.bind(this);
    this.handleFechaIngreso = this.handleFechaIngreso.bind(this);
    this.handleCargo = this.handleCargo.bind(this);
    this.handleTipo = this.handleTipo.bind(this);
    this.handleArea = this.handleArea.bind(this);
    this.handleDNI = this.handleDNI.bind(this);
    this.handlePrimerNombre = this.handlePrimerNombre.bind(this);
    this.handleSegundoNombre = this.handleSegundoNombre.bind(this);
    this.handlePrimerApellido = this.handlePrimerApellido.bind(this);
    this.handleSegundoApellido = this.handleSegundoApellido.bind(this);
    this.handleAddress = this.handleAddress.bind(this);
    this.handleTelefono = this.handleTelefono.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleCelular = this.handleCelular.bind(this);
    this.handleFechaCumple = this.handleFechaCumple.bind(this);
    this.handleFechaIngreso = this.handleFechaIngreso.bind(this);
    this.handleFechaSalida = this.handleFechaSalida.bind(this);
    this.handleFechaPension= this.handleFechaPension.bind(this);
    this.handleNumCuenta = this.handleNumCuenta.bind(this);
  }

  handleGenero = genero => {
    this.setState(
        {genero},
    );
   };
    handleModalidad(event){this.setState({modalidad: event.target.value});}
    handleCargo(event){this.setState({cargo: event.target.value});}
    handleTipo(event){this.setState({tipo: event.target.value});}
    handleArea(event){this.setState({area: event.target.value});}
    handleFechaIngreso(event){this.setState({fechaingreso: event.target.value});}
    handleDNI(event){this.setState({dni: event.target.value});}
    handlePrimerNombre(event){this.setState({name1: event.target.value});}
    handleSegundoNombre(event){this.setState({name2: event.target.value});}
    handlePrimerApellido(event){this.setState({lastName1: event.target.value});}
    handleSegundoApellido(event){this.setState({lastName2: event.target.value});}
    handleAddress(event){this.setState({address: event.target.value});}
    handleTelefono(event){this.setState({telefono: event.target.value});}
    handleEmail(event){this.setState({email: event.target.value});}
    handleCelular(event){this.setState({celular: event.target.value});}
    handleFechaCumple(event){this.setState({fechacumple: event.target.value});}
    handleFechaSalida(event){this.setState({fechasalida: event.target.value});}
    handleFechaPension(event){this.setState({fechapension: event.target.value});}
    handleFechaIngreso(event){this.setState({fechaingreso: event.target.value});}
    handleNumCuenta(event){this.setState({numcuenta: event.target.value});}

    changeCargo = cargo => {
      this.setState(
      { cargo },
      );
    };
     changeTipo= tipo =>{
      this.setState(
        {tipo},
      );
    };

    changeArea= area =>{
      this.setState(
        {area},
      );
    };

    changeModalidad= modalidad =>{
      this.setState(
        {modalidad},
      );
    };
    
    
    async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{
                  this.listarAreas();
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }
     
    async listarAreas() {
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'trabajadores/listArea')
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push(
                  { value: data.ReplicationUnit,
                    label: data.name,
                })
              });
              this.setState({areas: lista})
              this.listarCargo()
          }else{
            this.setState({areas: [], isLoading: false})
            this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
          }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }

  async listarCargo(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'trabajadores/listCargo')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.Position,
              label: data.Description,       
            })
        })
        this.setState({cargos: lista, cargo: {value: lista[0].value, label: lista[0].label}})
        this.listTipoPerson()
      }else{
        this.setState({cargos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
 
  async listTipoPerson(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'trabajadores/listTipoPerson')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.PayRollType,
              label: data.Description,       
            })
        })
        this.setState({tipos: lista, tipo: {value: lista[0].value, label: lista[0].label}})
        this.listTipoTrabajo()
      }else{
        this.setState({tipos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listTipoTrabajo(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'trabajadores/listModalidades')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.cnom_modalidad,       
            })
        })
        this.setState({modalidades: lista, modalidad: {value: lista[0].value, label: lista[0].label} , isLoading: false})
      }else{
        this.setState({modalidades: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => {
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }


  validaEmail (email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  validaCelular (cel) {
    var re = /^9[\d]{8}$/;
   return re.test(cel);
  }

  async validar(){
   if(this.state.dni != '' && this.state.name1 != ''  && this.state.lastName1 != '' && this.state.lastName2 != '' && 
   this.state.email != ''  && this.state.celular != ''  && this.state.fechacumple != '' && this.state.address != '' ){
      if(this.validaEmail(this.state.email)==true){
          if(this.validaCelular(this.state.celular)==true){
              this.agregar();
          }else{
              this.props.handleClick("tl", "warning", "El celular ingresado no es válido!", "pe-7s-close") 
          }
      }else{
        this.props.handleClick("tl", "warning", "El correo ingresado no es válido!", "pe-7s-close")  
      }
  }else{
      this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close") 
    }
  }

  async agregar(){ 
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('dni', this.state.dni);
    fromData.append('name1', this.state.name1.toUpperCase());
    fromData.append('name2', this.state.name2 == "" ? "" : this.state.name2.toUpperCase());
    fromData.append('lastName1', this.state.lastName1.toUpperCase())
    fromData.append('lastName2', this.state.lastName2.toUpperCase());
    fromData.append('address', this.state.address.toUpperCase());
    fromData.append('telefono', this.state.telefono);
    fromData.append('email', this.state.email);
    fromData.append('usuario', this.state.usuario);
    fromData.append('area', this.state.area.value)
    fromData.append('celular', this.state.celular);
    fromData.append('fechacumple', this.state.fechacumple);
    fromData.append('genero', this.state.genero.value);
    fromData.append('fechaingreso', this.state.fechaingreso);
    fromData.append('fechasalida', this.state.fechasalida);
    fromData.append('fechapension', this.state.fechapension);
    fromData.append('numcuenta', this.state.numcuenta)
    fromData.append('position', this.state.cargo.value);
    fromData.append('tipoperson', this.state.tipo.value);
    fromData.append('modalidadtrabajo', this.state.modalidad.value);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'trabajadores/insertarTrabajador', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Trabajador registrado correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  onKeyUpValue() {
    if(this.state.dni.length == 8){
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'trabajadores/listPersonEstado?dni=' + this.state.dni)
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { nombres: data.Name,
                idpersona : data.person,
                estado: data.STATUS
              })
             });
            if(lista[0].estado == "A"){       
                this.estadoActivo();
            }else if (lista[0].estado == "I"){
                this.estadoAnulado(lista[0].nombres);
            }
        }else{
           this.props.handleClick("tl", "error", "Error de conexión!", "pe-7s-info")
        }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }else if(this.state.dni.length > 8){
        this.props.handleClick("tl", "error", "El DNI ingresado tiene más de 8 dígitos!", "pe-7s-info")
    }
  }

  
  estadoActivo = () => {
      confirmAlert({
        title: <span>
                <i className="fa fa-exclamation-triangle" style={{fontSize: 80, color:"grey",marginLeft:125}}></i>
              </span>,
        message: 'El DNI ingresado ya existe ¿Desea ingresar otro DNI?',
        buttons: [
          {
            label: 'SI',
            onClick: () =>  this.setState({dni: ""})
          },
          {
            label: 'NO',
            onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info" ,
            this.props.history.push('/administrarTrabajador'))}
        ]
      });
  };

  
  estadoAnulado = (nombre) => {
    confirmAlert({
      title: <span>
              <i className="fa fa-exclamation-triangle" style={{fontSize: 80, color:"grey",marginLeft:125}}></i>
             </span>
          ,
      message: 'El DNI ingresado ya existe pero esta deshabilitado.¿Desea habilitar al trabajador '+ nombre +' ?',
      buttons: [
        {
          label: 'SI',
          onClick: () => this.activar()
        },
        {
          label: 'NO',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info" ,this.setState({dni: ""})) 
        }
      ]
    });
  };


  async activar(){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('person', this.state.dni);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'trabajadores/activarTrabajador', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "info", "El trabajador ha sido habilitado!", "pe-7s-smile")
        this.props.history.push('/administrarTrabajador')
        }else{
        this.props.handleClick("tl", "error", "Hubo un problema al activar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async regresar(){
    this.props.history.push('/administrarTrabajador');
  }
  
  render() {
    const {cargo, tipo,area, modalidad} = this.state
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>REGISTRAR TRABAJADOR</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                       <div className="row">
                            <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>DNI<a style={{color:"red"}}> *</a></ControlLabel>
                                <FormControl type="text"  value={this.state.dni} onChange= {this.handleDNI}  onKeyUp={this.onKeyUpValue.bind(this)}/>
                            </div>
                             <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>PRIMER NOMBRE<a style={{color:"red"}}> *</a></ControlLabel>
                                <FormControl type="text" value={this.state.name1} onChange= {this.handlePrimerNombre}/>
                             </div>
                             <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>SEGUNDO NOMBRE</ControlLabel>
                                <FormControl type="text" value={this.state.name2} onChange= {this.handleSegundoNombre}/>
                             </div>
                             <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>APELLIDO PATERNO<a style={{color:"red"}}> *</a></ControlLabel>
                                <FormControl type="text" value={this.state.lastName1} onChange= {this.handlePrimerApellido}/>
                             </div>
                             <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>APELLIDO MATERNO<a style={{color:"red"}}> *</a></ControlLabel>
                                <FormControl type="text" value={this.state.lastName2} onChange= {this.handleSegundoApellido}/>
                             </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>CORREO<a style={{color:"red"}}> *</a></ControlLabel>
                                <FormControl type="text" value={this.state.email} onChange= {this.handleEmail}/>
                            </div>
                             <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>N° CELULAR<a style={{color:"red"}}> *</a></ControlLabel>
                                <FormControl type="text" value={this.state.celular} onChange= {this.handleCelular}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>N° TELÉFONO <a>(OPCIONAL)</a></ControlLabel>
                                <FormControl type="text" value={this.state.telefono} onChange= {this.handleTelefono}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE NACIMIENTO<a style={{color:"red"}}> *</a></ControlLabel>
                                <FormControl type="date" value={this.state.fechacumple} onChange= {this.handleFechaCumple}/>
                            </div>
                            <div className="col-md-9" style={{marginBottom: 18}}>
                                <ControlLabel>DIRECCIÓN<a style={{color:"red"}}> *</a></ControlLabel>
                                <FormControl type="text" value={this.state.address} onChange= {this.handleAddress}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}> 
                                  <ControlLabel>GÉNERO<a style={{color:"red"}}> *</a></ControlLabel> 
                                  <Select value={this.state.genero} onChange={this.handleGenero} options={this.state.generos}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE INGRESO<a style={{color:"red"}}> *</a></ControlLabel>
                                <FormControl type="date" value={this.state.fechaingreso} onChange= {this.handleFechaIngreso}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE SALIDA <a>(OPCIONAL)</a></ControlLabel>
                                <FormControl type="date" value={this.state.fechasalida} onChange= {this.handleFechaSalida}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE INSCRIPCIÓN DE PENSIÓN <a>(OPCIONAL)</a></ControlLabel>
                                <FormControl type="date" value={this.state.fechapension} onChange= {this.handleFechaPension}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>NÚMERO DE CUENTA <a>(OPCIONAL)</a></ControlLabel>
                                <FormControl type="text" value={this.state.numcuenta} onChange= {this.handleNumCuenta}/>
                            </div>
                       </div>
                      <div className="row">
                           <div className="col-md-3" style={{marginBottom: 18}}>
                              <ControlLabel>ÁREA<a style={{color:"red"}}> *</a></ControlLabel>
                              <Select value={area} onChange={this.changeArea} options={this.state.areas}/>
                           </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                              <ControlLabel>CARGO<a style={{color:"red"}}> *</a></ControlLabel>
                              <Select value={cargo} onChange={this.changeCargo} options={this.state.cargos}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                              <ControlLabel>TIPO DE CONTRATO<a style={{color:"red"}}> *</a></ControlLabel>
                              <Select value={tipo} onChange={this.changeTipo} options={this.state.tipos}/>
                           </div>
                           <div className="col-md-3" style={{marginBottom: 18}}>
                              <ControlLabel>MODALIDAD DE TRABAJO<a style={{color:"red"}}> *</a></ControlLabel>
                              <Select value={modalidad} onChange={this.changeModalidad} options={this.state.modalidades}/>
                           </div>
                      </div>
                      <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Registro</Button>
                      <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Guardar Registro</Button>
                    </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    
    );
   
  }
   
}

export default CrearTrabajadores;