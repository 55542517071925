import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {sisperplus, fechasBusqueda,paramRRHH} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Select from 'react-select';
import ReactExport from 'react-export-excel'

const ExcelFile = ReactExport.ExcelFile; // indica el archivo excel que vamos a crear
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;//nombre de hoja de excel
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;//nombre de las columnas de excel



const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Resoluciones extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'resoluciones',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      ver:"",
      anio: '',
      mes: '',
      years: [],
      meses: [],
      resolucion:"",
      categoria:{value:'', label :"SIN CATEGORIA"},
      categorias:[],
      fechaini: "",
      fechafin:"",
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.verBoleta= this.verBoleta.bind(this);
    this.notificar= this.notificar.bind(this);
    this.pdf= this.pdf.bind(this);
    this.handleResolucion = this.handleResolucion.bind(this);
    this.handleFechaIni = this.handleFechaIni.bind(this);
    this.handleFechaFin = this.handleFechaFin.bind(this);
  }
    handleResolucion(event) {this.setState({resolucion: event.target.value});}
    handleFechaIni(event) {this.setState({fechaini: event.target.value});}
    handleFechaFin(event) {this.setState({fechafin: event.target.value});}

    handleCategoria = categoria =>{
        this.setState(
        {categoria},
        );
    };

    handleAnio = anio => {
        this.setState(
        { anio },
        );
      };
  
      handleMes = mes => {
        this.setState(
        { mes },
        );
      };
 

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.mostrar(1);
                this.generarPeriodos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  generarPeriodos(){
    let year =  2000;
    let cont = 26;
    let a = Array.from(new Array(cont),(val, index) => index + year);
    let array = a.reverse()
    let arrayMes = [
      {"nid": "1", "descripcion": "ENERO"},
      {"nid": "2", "descripcion": "FEBRERO"},
      {"nid": "3", "descripcion": "MARZO"},
      {"nid": "4", "descripcion": "ABRIL"},
      {"nid": "5", "descripcion": "MAYO"},
      {"nid": "6", "descripcion": "JUNIO"},
      {"nid": "7", "descripcion": "JULIO"},
      {"nid": "8", "descripcion": "AGOSTO"},
      {"nid": "9", "descripcion": "SETIEMBRE"},
      {"nid": "10", "descripcion": "OCTUBRE"},
      {"nid": "11", "descripcion": "NOVIEMBRE"},
      {"nid": "12", "descripcion": "DICIEMBRE"},
    ]
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    if(fechasBusqueda.resoluciones[0].nidperiodo != "" && fechasBusqueda.resoluciones[1].periodo != ""){
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
      })
      this.setState({anio: {
        value: fechasBusqueda.resoluciones[0].nidperiodo, 
        label: fechasBusqueda.resoluciones[1].periodo
      }})
    }else{
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
          this.setState({anio: {value: cont2, label: data}})
        }
      })
    }
    if(fechasBusqueda.resoluciones[2].nidmes != "" && fechasBusqueda.resoluciones[3].mes != ""){
      arrayMes.map((data) => {
        cont3++
        lista2.push({
            item: data.cont3,
            value: data.nid,
            label: data.descripcion,
        })
      })
      this.setState({mes: {
        value: fechasBusqueda.resoluciones[2].nidmes, 
        label: fechasBusqueda.resoluciones[3].mes, 
      }})
    }else{
      arrayMes.map((data) => {
        cont3++
        lista2.push({
            item: data.cont3,
            value: data.nid,
            label: data.descripcion,
        })
        if(cont3 == (new Date()).getMonth() + 1){
          this.setState({mes: {value: data.nid, label: data.descripcion}})
        }
      })
    }
    this.setState({years: lista, meses: lista2})
    this.listResoluciones()
 }


  async listResoluciones() {
    var param1=""
    var param2=""
    if(this.state.ver==1){
      param1=this.state.anio.label
      param2=this.state.mes.value
    }else if(this.state.ver==2){
      param1=this.state.fechaini
      param2=this.state.fechafin
    }else{
      param1=this.state.resolucion
      param2=this.state.categoria.value
    }
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'resoluciones/listResoluciones?param=' + this.state.ver + '&filtro1=' + param1 + '&filtro2=' + param2)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                cnum_resolucion: data.cnum_resolucion,
                cnom_resolucion: data.cnom_resolucion,
                fecha: data.fecha != null ? Calendar.calendario12B(data.fecha) : "",
                cnom_archivo: data.cnom_archivo,
                ruta_archivo:data.ruta_archivo,
                ver_archivo:data.ver_archivo,
                flg_notificado:data.flg_notificado,
                nombreCat: data.nombre,
                categoria: data.categoria,
                nid: data.nid,
                item: data.item,
                ctxt_linkExterno:data.ctxt_linkExterno  
            })
          })
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

    confirmarDelete = (data) => {
      confirmAlert({
        title: '¿Seguro que desea eliminar la resolución?',
        message: 'Se anulará el registro seleccionado.',
        buttons: [
          {
            label: 'Aceptar',
            onClick: () => this.delete(data)
          },
          {
            label: 'Cancelar',
            onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
          }
        ]
      });
    };

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidRes', data.nid);
    fromData.append('usuario',this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'resoluciones/anularResoluciones', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listResoluciones()
        this.props.handleClick("tl", "info", "El registro ha sido eliminado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
  

  async ir(param){
    if(param=="CR"){
         this.props.history.push("/nuevaResolucion")
    }else {
        this.props.history.push("/categorias")
    }
  }

  async obtener(data, param){
      paramRRHH.resoluciones[0].data = data
      localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH))  
    if(param=="E"){
         this.props.history.push("/editarResolucion")
    }else if(param=="N"){
         this.props.history.push("/notificar")
   }
    else{
      this.props.history.push("/verNotificados")
    }
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row, "E") }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Anular Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x "></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  verBoleta(cell, row) {
 console.log(row)
    return (
      row.ctxt_linkExterno !=null  || row.cnom_archivo!= null ?    
        <div> 
            <span data-tip="Ver Resolución" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver2(row) }}>
              <ReactTooltip />
                <i className="fa fa-file-pdf-o fa-3x" style={{fontSize: 27, color:"#142D8A"}}></i>   
            </span>
        </div>
        : null
    );
  }

  pdf(cell, row) {
    return (
        <div>
           {row.cnom_archivo!= null ? 
            <span data-tip="Descargar Resolución" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver(row) }}>
              <ReactTooltip />  
              <i className="fa fa-download fa-3x" style={{fontSize: 27, color:"#142D8A"}}></i>
            </span>
            :
            null}
        </div>
    );
  }

  async ver2(row){
    console.log(row)
    if(row.cnom_archivo!=null){
      // localStorage.setItem('visor', JSON.stringify(row))
      // this.props.history.push('/visorResoluciones');
      
      window.open(row.ver_archivo, '_blank');
    }else{
      window.open(row.ruta_archivo, '_blank');
    }
 }

  notificar(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Notificar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20, float:"left"}} onClick={() => { this.obtener(row,"N") }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x" style={{color: "#1B4F72"}}></i>
                <i className="fa fa-envelope fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
           {row.flg_notificado!='0' ?       
            <span data-tip="Ver Notificados" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row,"V") }}>
            <ReactTooltip />
            <i className="fa fa-circle fa-stack-2x" style={{color: "#B7950B "}}></i>
            <i className="fa fa-list-ul fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
          </span>:  null}
          </div>
      );
    }else{}
  }

  mostrar(param){
    if(param==1){
        this.setState({ver : "1"})
    }else if(param==2){
        this.setState({ver : "2"})
    }else if(param==3){
        this.listCategorias()
        this.setState({ver : "3"})  
    }else{
        this.setState({ver : null})
    }
  }
 
  async listCategorias() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'resoluciones/listCategorias')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              label: data.nombre,   
              value: data.nid,
            })
          })
          this.setState({categorias: lista })
        }else{
          this.setState({categorias: []})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async ver(row){
    window.open(row.ruta_archivo, "_blank") 
  }

  render() {
    const columns = [
    {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "cnum_resolucion", text: "N° RESOLUCIÓN", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    // {dataField: "cnom_resolucion", text: "RESOLUCIÓN", filter: textFilter(),  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align: 'center'},
    {dataField: "fecha", text: "FECHA",  sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align: 'center'},
    {dataField: "nombreCat", text: "CATEGORÍA", filter: textFilter(),  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align: 'center'},
    {dataField: "ver_archivo", text: "VER",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align: 'center', formatter: this.verBoleta},
    {dataField: "ruta_archivo", text: "DESCARGAR",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align: 'center', formatter: this.pdf},
    {dataField: "detail", text: "NOTIFICAR",headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.notificar},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };

    const expandRow = {
      onlyOneExpanding: true,
      renderer: row => (
        <div> 
          <div className="row">
            <div className="col-md-12">
              <p style={{fontWeight: 'bold', fontSize: 15}}>RESOLUCIÓN: <a style={{color: '#333'}}>{ row.cnom_resolucion }</a></p>
            </div>
          </div>
        </div>
      )
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              expandRow={ expandRow }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
      <Grid fluid>
      <Row>
          <Col md={12}>
            <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
            <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Administrador de Resoluciones</h4>
            <div className="row" style={{marginBottom: 10}}>
                <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue}>
                    <ControlLabel>Búsqueda por :</ControlLabel><br></br>
                    <label htmlFor="dependiente1" style={{ marginRight: 10 }}>
                      <input type="radio" value="1" name="dependiente" id="dependiente1" style={{ marginRight: 5 }} onChange={(e) => this.mostrar(e.target.value)}defaultChecked />
                       Periodo/Mes
                    </label>
                    <label htmlFor="dependiente2" style={{ marginRight: 10 }}>
                      <input type="radio" value="2"  name="dependiente"  id="dependiente2" style={{ marginRight: 5 }} onChange={(e) => this.mostrar(e.target.value)} />
                       Fecha Inicio/Fin
                    </label>
                    <label htmlFor="dependiente3" style={{ marginRight: 10 }}>
                       <input  type="radio"  value="3"  name="dependiente"  id="dependiente3"  style={{ marginRight: 5 }}  onChange={(e) => this.mostrar(e.target.value)}  />
                        Texto
                    </label>
                </div>
                <div className="col-md-8" >
                {this.state.ver=="1" ? 
                   <div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>PERIODO</ControlLabel>
                            <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>MES</ControlLabel>
                            <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses}/>
                        </div>
                    </div> 
                :this.state.ver=="2" ? 
                    <div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DE INICIO</ControlLabel>
                            <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaIni}  />
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                            <FormControl type="date" value={this.state.fechafin}  onChange= {this.handleFechaFin} min={this.state.fechaini}/>
                        </div>
                    </div> 
               :this.state.ver=="3" ? 
                    <div>
                        <div className="col-md-7" style={{marginBottom: 18}}>
                            <ControlLabel>N°RESOLUCIÓN  y/o DESCRIPCIÓN</ControlLabel>
                            <FormControl type="text" value={this.state.resolucion} onChange={this.handleResolucion}/>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>CATEGORÍA</ControlLabel>
                            <Select value={this.state.categoria} onChange={this.handleCategoria} options={this.state.categorias}/>
                        </div>    
                    </div>: null }
                  </div>   
                    <div className="col-md-1" >
                {this.state.ver=="1" || this.state.ver=="2" || this.state.ver=="3" ?
                    <Button style={{width: 180, marginBottom: 10, marginTop:25, float:"right"}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.listResoluciones() }}>Buscar</Button>   
                : null} </div>
            </div>
                   <Button style={{width: 200, marginBottom: 10, marginRight:10}} bsStyle="success"  fill type="submit" onClick={() => { this.ir("CR") }}>Nueva Resolución</Button>
                   <Button style={{width: 200, marginBottom: 10}} bsStyle="info"  fill type="submit" onClick={() => { this.ir("CC") }}>Categorías</Button>
                {this.state.isLoading ? (
                        <ClipLoader
                          css={override}
                          sizeUnit={"px"}
                          size={50}
                          color={'#123abc'}
                          loading={this.state.loading}
                        />
                      ) : (
                        <div>
                          <PaginationProvider
                            pagination={ paginationFactory(options) } >
                            { contentTable }
                          </PaginationProvider>
                          <ExcelFile element={<Button style={{backgroundColor:"green" ,border:0,width: 250,height:40,marginBottom:5}}  fill type="submit">Exportar a Excel</Button>} filename= {"REPORTE DE RESOLUCIONES" }>
                            <ExcelSheet data={this.state.movimientos}  name ={"Resoluciones" }>
                                <ExcelColumn label="ITEM" value="item" ></ExcelColumn>
                                <ExcelColumn label="N° RESOLUCIÓN" value="cnum_resolucion" ></ExcelColumn>
                                <ExcelColumn label="RESOLUCIÓN" value="cnom_resolucion" ></ExcelColumn>
                                <ExcelColumn label="FECHA" value="fecha"  ></ExcelColumn>
                                <ExcelColumn label="CATEGORÍA" value="nombreCat"  ></ExcelColumn>
                                <ExcelColumn label="DOCUMENTO" value="ruta_archivo"  ></ExcelColumn>
                              </ExcelSheet>
                          </ExcelFile>
                        </div>
                  )}
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
    );
  }
}

export default Resoluciones;