import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import moment from 'moment';
import 'moment/locale/es';

const styles = StyleSheet.create({
    body: {
      paddingTop: 5,
      paddingBottom: 65,
      paddingHorizontal: 35,
      flexGrow: 1,
    },
    title: {
      fontSize: 15,
      textAlign: 'center',
    },
    author2: {
      fontSize: 16,
      textAlign: 'center',
      marginTop: 10
    },
    subtitle3: {
      fontSize: 10,
      marginBottom: 8,
      textAlign: 'right',
    },
    subtitle4: {
      fontSize: 10,
      marginBottom: 8,
      textAlign: 'right',
      marginTop: 40,
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    image: {
      marginVertical: 10,
      marginHorizontal: 230,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0,
        marginTop: 15, 
        marginBottom: 30 
      }, 
      tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
      }, 
      tableColHeader: { 
        width: "25%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      }, 
      tableColHeader2: { 
        width: "10%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      }, 
      tableColHeader3: { 
        width: "15%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },  
      tableCol: { 
        width: "25%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      },  
      tableCol2: { 
        width: "10%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        textAlign: 'right'
      }, 
      tableCol3: { 
        width: "15%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      }, 
      tableCol4: { 
        width: "10%", 
        borderStyle: "solid", 
        borderColor: '#F1C40F',
        backgroundColor: '#F1C40F',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        textAlign: 'right' 
      },
      tableCellHeader: {
        margin: "auto", 
        margin: 5, 
        fontSize: 9,
        fontWeight: 500,
        textAlign: 'center'
      },  
      tableCell: { 
        margin: "auto", 
        margin: 5, 
        fontSize: 8
      },
      row: {
        flexDirection: 'row',
        marginBottom: 30,
      },
});

export function reporteProyeccion(movimientos, sumatoria, row) {
    const fecha = moment(new Date()).locale('es').format('LLLL')
    let arrayMes = [
      {"nid": 1, "descripcion": "Enero"},
      {"nid": 2, "descripcion": "Febrero"},
      {"nid": 3, "descripcion": "Marzo"},
      {"nid": 4, "descripcion": "Abril"},
      {"nid": 5, "descripcion": "Mayo"},
      {"nid": 6, "descripcion": "Junio"},
      {"nid": 7, "descripcion": "Julio"},
      {"nid": 8, "descripcion": "Agosto"},
      {"nid": 9, "descripcion": "Setiembre"},
      {"nid": 10, "descripcion": "Octubre"},
      {"nid": 11, "descripcion": "Noviembre"},
      {"nid": 12, "descripcion": "Diciembre"},
    ]
    let meses = [];
    let contini = 0;
    let contfin = 0;
    arrayMes.map((data, i) => {
       if(data.descripcion == row[0].mesini){
          contini = data.nid
       }
       if(data.descripcion == row[0].mesfin){
          contfin = data.nid
       }
    })
    if(row[0].mesini == row[0].mesfin){
        meses.push(row[0].mesini)
    }else{
        arrayMes.map((data, i) => {
          if(data.nid >= contini && data.nid <= contfin){
            meses.push(data.descripcion)
          }
        })
    }
    return (
        <Document>
        <Page size="A4" orientation="portrait" style={styles.body}>
          <Image style={styles.image} src="./icon.png"/>
          <Text style={styles.title}>Tribunal Constitucional del Perú</Text>
          <Text style={styles.author2}>Fondo para Pagos en Efectivo
          {meses.map((data, i) => {
            if(row[0].mesini == row[0].mesfin){
                return (" del Mes de " + data);
            }else{
                if(row[0].mesini == data){
                    return (" de los Meses de " + data);
                }else if(row[0].mesfin == data){
                    return (" y " + data);
                }else{
                    return (", " + data);
                }
            }
          })} del Año {row[0].anio}</Text>
          <Text style={styles.author2}>N° {row[0].numero1}</Text>
          <View style={styles.table}> 
                <View style={styles.tableRow}> 
                  <View style={styles.tableColHeader3}> 
                      <Text style={styles.tableCellHeader}>COD/ESPEC</Text> 
                  </View> 
                  <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>DESCRIPCION</Text> 
                  </View>
                  <View style={styles.tableColHeader2}> 
                      <Text style={styles.tableCellHeader}>META 01</Text> 
                  </View>
                  <View style={styles.tableColHeader2}> 
                      <Text style={styles.tableCellHeader}>POR {row[0].numero2} MESES</Text> 
                  </View>
                  <View style={styles.tableColHeader2}> 
                      <Text style={styles.tableCellHeader}>META 02</Text> 
                  </View> 
                  <View style={styles.tableColHeader2}> 
                      <Text style={styles.tableCellHeader}>POR {row[0].numero2} MESES</Text> 
                  </View>
                  <View style={styles.tableColHeader2}> 
                      <Text style={styles.tableCellHeader}>TOTAL</Text> 
                  </View>
                  <View style={styles.tableColHeader2}> 
                      <Text style={styles.tableCellHeader}>POR {row[0].numero2} MESES</Text> 
                  </View>
                </View>
                {movimientos.map((data, i) => {
                    return (
                        <View style={styles.tableRow}> 
                          <View style={styles.tableCol3}> 
                              <Text style={styles.tableCell}>{data.codigo}</Text> 
                          </View> 
                          <View style={styles.tableCol}> 
                              <Text style={styles.tableCell}>{data.descripcion}</Text> 
                          </View> 
                          <View style={styles.tableCol2}> 
                              <Text style={styles.tableCell}>{data.meta1.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                          </View> 
                          <View style={styles.tableCol4}> 
                              <Text style={styles.tableCell}>{data.meta1x.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                          </View> 
                          <View style={styles.tableCol2}> 
                              <Text style={styles.tableCell}>{data.meta2.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                          </View> 
                          <View style={styles.tableCol4}> 
                              <Text style={styles.tableCell}>{data.meta2x.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                          </View> 
                          <View style={styles.tableCol2}> 
                              <Text style={styles.tableCell}>{data.total.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                          </View> 
                          <View style={styles.tableCol2}> 
                              <Text style={styles.tableCell}>{data.totalx.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                          </View> 
                        </View> 
                    );
                 })}
                 <View style={styles.tableRow}> 
                    <View style={styles.tableCol3}> 
                        <Text style={styles.tableCell}>TOTALES</Text> 
                    </View> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}></Text> 
                    </View> 
                    <View style={styles.tableCol2}> 
                        <Text style={styles.tableCell}>{sumatoria[0].meta1.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                    </View> 
                    <View style={styles.tableCol4}> 
                        <Text style={styles.tableCell}>{sumatoria[0].meta1x.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                    </View> 
                    <View style={styles.tableCol2}> 
                        <Text style={styles.tableCell}>{sumatoria[0].meta2.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                    </View> 
                    <View style={styles.tableCol4}> 
                        <Text style={styles.tableCell}>{sumatoria[0].meta2x.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                    </View> 
                    <View style={styles.tableCol2}> 
                        <Text style={styles.tableCell}>{sumatoria[0].total.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                    </View> 
                    <View style={styles.tableCol2}> 
                        <Text style={styles.tableCell}>{sumatoria[0].totalx.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                    </View> 
                  </View> 
            </View>
          <Text style={styles.subtitle4}>
            Lima, {fecha}
          </Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
        </Page>
      </Document>
    );
}