import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import moment from 'moment';
import 'moment/locale/es';

const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
      flexGrow: 1,
    },
    title: {
      fontSize: 20,
      textAlign: 'center',
      paddingTop: 20,
    },
    title2: {
        fontSize: 10,
        textAlign: 'center',
    },
    author: {
      fontSize: 15,
      textAlign: 'center',
      marginBottom: 20,
      marginTop: 10
    },
    author2: {
      fontSize: 16,
      textAlign: 'center',
      marginTop: 10
    },
    subtitle: {
      fontSize: 13,
      margin: 12,
    },
    subtitle1: {
      fontSize: 10,
      margin: 6,
      textAlign: 'left',
    },
    subtitle2: {
        fontSize: 10,
        margin: 6,
        textAlign: 'left',
    },
    subtitle3: {
      fontSize: 10,
      marginBottom: 8,
      textAlign: 'right',
    },
    subtitle4: {
      fontSize: 10,
      marginBottom: 8,
      textAlign: 'left',
      marginTop: 80,
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    image: {
      marginVertical: 10,
      marginHorizontal: 230,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0,
        marginTop: 15, 
        marginBottom: 30 
      }, 
      tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
      }, 
      tableColHeader: { 
        width: "15%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      }, 
      tableColHeader2: { 
        width: "12%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },   
      tableColHeader3: { 
        width: "23%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },   
      tableColHeader4: { 
        width: "5%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },
      tableCol: { 
        width: "15%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      },
      tableCol2: { 
        width: "12%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      }, 
      tableCol3: { 
        width: "23%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0, 
      }, 
      tableCol4: { 
        width: "5%", 
        borderStyle: "solid", 
        borderColor: '#27AE60',
        backgroundColor: '#27AE60',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0, 
      }, 
      tableCol5: { 
        width: "5%", 
        borderStyle: "solid", 
        borderColor: '#F1C40F',
        backgroundColor: '#F1C40F',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      }, 
      tableCellHeader: {
        margin: "auto", 
        margin: 5, 
        fontSize: 7.8,
        fontWeight: 500,
        textAlign: 'center'
      },  
      tableCell: { 
        margin: "auto", 
        margin: 5, 
        fontSize: 9
      },
      row: {
        flexDirection: 'row',
        marginBottom: 30,
      },
      fill1: {
        width: '5%',
        height: 25,
        backgroundColor: '#27AE60',
      },
      fill2: {
        width: '5%',
        height: 25,
        backgroundColor: '#F1C40F',
      },
      tableColHeaderA: { 
        width: 228, 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },
      tableCol2A: { 
        width: 228, 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      },
      tableColHeaderB: { 
        width: 150, 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },
      tableCol2B: { 
        width: 150, 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
      },
});

export function PdfDocument2(labores, row) {
    const fecha = moment(new Date()).locale('es').format('LLLL')
    return (
        <Document>
        <Page style={styles.body}>
          {/* <Text style={styles.header} fixed>Elaborado por</Text> */}
          <Image style={styles.image} src="./icon.png"/>
          <Text style={styles.title2}>“Decenio de la Igualdad de Oportunidades para Mujeres y Hombres”</Text>
          <Text style={styles.title2}>“Año de la unidad, la paz y el desarrollo”</Text>
          <Text style={styles.title}>Tribunal Constitucional del Perú</Text>
          {/* <Text style={styles.author2}>Formato</Text> */}
          <Text style={styles.author}>Resumen de Teletrabajo</Text>
          <Text style={styles.subtitle1}>
            ÓRGANO: {row[0].organo}
          </Text>
          <Text style={styles.subtitle1}>
            UNIDAD ORGÁNICA: {row[0].area}
          </Text>
          <Text style={styles.subtitle1}>
            NOMBRES Y APELLIDOS: {row[0].datos}
          </Text>
          <Text style={styles.subtitle1}>
            CARGO: {row[0].cargo}
          </Text>
          <Text style={styles.subtitle1}>
            PERIODO DE TELETRABAJO:
          </Text>
          <Text style={styles.subtitle1}>
            {row[0].fechaini} al {row[0].fechafin}
          </Text>
          <View style={styles.table}> 
                <View style={styles.tableRow}> 
                  <View style={styles.tableColHeader4}> 
                      <Text style={styles.tableCellHeader}></Text> 
                  </View> 
              
                  <View style={styles.tableColHeaderA}> 
                      <Text style={styles.tableCellHeader}>Actividad</Text> 
                  </View> 
                  <View style={styles.tableColHeaderB}> 
                      <Text style={styles.tableCellHeader}>Producto</Text> 
                  </View> 
                  <View style={styles.tableColHeader3}> 
                      <Text style={styles.tableCellHeader}>Detalle</Text> 
                  </View>
                 

                  {/* <View style={styles.tableColHeader2}> 
                      <Text style={styles.tableCellHeader}>Día</Text> 
                  </View>
                  <View style={styles.tableColHeader2}> 
                      <Text style={styles.tableCellHeader}>Hora Inicio</Text> 
                  </View>
                  <View style={styles.tableColHeader2}> 
                      <Text style={styles.tableCellHeader}>Hora Fin</Text> 
                  </View>
                  <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>Observaciones</Text> 
                  </View> */}


                </View>
                {labores.map((data, i) => {
                    return (
                        <View style={styles.tableRow}> 
                          {data.estado == "PENDIENTE" ? (
                          <View style={styles.tableCol5}> 
                              <Text style={styles.tableCell}></Text> 
                          </View> 
                          ) : (
                          <View style={styles.tableCol4}> 
                              <Text style={styles.tableCell}></Text> 
                          </View>
                          )}
                          
                          <View style={styles.tableCol2A}> 
                              <Text style={styles.tableCell}>{data.actividad}</Text>
                          </View> 
                          <View style={styles.tableCol2B}> 
                              <Text style={styles.tableCell}>{data.producto}</Text> 
                          </View> 
                          <View style={styles.tableCol3}> 
                              <Text style={styles.tableCell}>Fecha de registro: {data.creacion}</Text> 
                              <Text style={styles.tableCell}>Fecha de teletrabajo: {data.fecha}</Text> 
                              <Text style={styles.tableCell}>Hora inicio: {data.horainicio}</Text> 
                              <Text style={styles.tableCell}>Hora fin: {data.horafinal}</Text> 
                              <Text style={styles.tableCell}>Horas Laborales: {data.horas}</Text> 
                              <Text style={styles.tableCell}>Comentario: {data.comentario}</Text> 
                          </View> 

                          {/* <View style={styles.tableCol2}> 
                              <Text style={styles.tableCell}>{data.fecha}</Text> 
                          </View> 
                          <View style={styles.tableCol2}> 
                              <Text style={styles.tableCell}>{data.horainicio}</Text> 
                          </View> 
                          <View style={styles.tableCol2}> 
                              <Text style={styles.tableCell}>{data.horafinal}</Text> 
                          </View> 
                          <View style={styles.tableCol}> 
                              <Text style={styles.tableCell}>{data.comentario}</Text> 
                          </View>  */}
                        </View> 
                    );
                 })}
            </View>
          <Text style={styles.subtitle3}>
            Tiempo Total: {row[0].minutos} Hrs
          </Text>
          <Text style={styles.subtitle4}>
              LEYENDA:
          </Text>
          <View style={styles.row}>
            <View style={styles.fill1} />
            <Text style={styles.subtitle2}>
              Aprobado
            </Text>
            <View style={styles.fill2} />
            <Text style={styles.subtitle2}>
              Pendiente
            </Text>
          </View>
          <Text style={styles.subtitle3}>
            Lima, {fecha}
          </Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
        </Page>
      </Document>
    );
}