import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import axios from 'axios';

import { Card } from "components/Card/Card.jsx";

import { validator } from 'store/Apis'

import SockJsClient from 'react-stomp';

class Chat extends Component {

  state = {
    path: 'chat',
    usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
    token: localStorage.getItem('AccessToken')
  }

  async componentDidMount() {
  if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
      headers: {
          Authorization: 'Bearer ' + this.state.token
      }
      }).then((response) => {
          let permisos = response.data;
          if(permisos.codError != 1){
              this.props.history.goBack()
          }else{
              // this.cargaView();
          }
      },(error) => {
          if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
          }else{
          this.props.history.goBack()
          }
      });
  }else{
      auth0Client.signOut();
      this.props.history.replace('/');
  }
  }
  
  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={3}>
              <Card
                title="Chats"
                content={<div></div>}
              />
            </Col>
            <Col md={5}>
              <Card
                title="Mensajes"
                content={<div></div>}
              />
            </Col>
            <Col md={4}>
              <Card
                title="Historial"
                content={<div></div>}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Chat;