import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import axios from 'axios';
import {sisperplus } from 'store/Apis'
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { validator } from 'store/Apis'

import "react-confirm-alert/src/react-confirm-alert.css";
;

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class CrearArea extends Component {

constructor(props) {
super(props);
  this.state = {
    path: 'administrarAreas',
    dni: localStorage.getItem('dni'),
    usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
    id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
    token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
    descripcion :"",
    name:"",
    referencia: "",
    jefeF: "",
    jefeS: "",//{value: "100125", label: "ABAD CACERES NERY DEL CARMEN"}
    jefes: [],
    isLoading:true,

   

  }
  
  this.handleDescripcion = this.handleDescripcion.bind(this);
  this.handleName = this.handleName.bind(this);
  this.handleReferencia = this.handleReferencia.bind(this);


}

handleDescripcion(event) {this.setState({descripcion: event.target.value});}
handleName(event) {this.setState({name: event.target.value});}
handleReferencia(event) {this.setState({referencia: event.target.value});}


handleJefeF = jefeF => {
    this.setState(
    { jefeF },
    );
};

handleJefeS = jefeS => {
    this.setState(
    { jefeS },
    );
};

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
              this.listJefeF();
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  async listJefeF() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'area/listPerson')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.Person,
                label: data.Name,
            })
          });
          this.setState({jefes: lista, isLoading: false})
    }else{
        this.setState({jefes: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
   }

async regresar(){
   this.props.history.push('/adminAreas');
}

  validar(){
    if(this.state.descripcion != ''  &&  this.state.name != '' && this.state.jefeS != '' && this.state.jefeF != ''){
        this.guardar();
       }else{
         this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close") 
       }
  }
  guardar(){
          this.setState({isLoading: true})
          let fromData = new FormData();
          fromData.append('descripcion', this.state.descripcion.toUpperCase());
          fromData.append('nameArea', this.state.name.toUpperCase());
          fromData.append('referencia', this.state.referencia);
          fromData.append('firstperson', this.state.jefeF.value );
          fromData.append('secondperson', this.state.jefeS.value);
          fromData.append('namefirstperson', this.state.jefeF.label);
          fromData.append('namesecondperson', this.state.jefeS.label);
          fromData.append('usuario', this.state.usuario);
          axios.defaults.headers.common['Authorization'] = this.state.token
          axios.post(`${sisperplus}` + 'area/insertarArea', fromData)
           .then((response) => {
                var datos = response.data
                if(datos.response != null){
                  this.props.handleClick("tl", "success", "Area registrada correctamente!", "pe-7s-close")
                    this.regresar();
                }else{
                    this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
                    this.setState({isLoading: false})
                }
                })
                .catch((error) =>{ 
                if(auth0Client.isAuthenticated() === true){
                    auth0Client.signOut()
                    this.props.history.replace('/');
                }else{
                    console.log(error);
                    this.setState({isLoading: false})
                }
              });
        }

 
  render() {
  
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
            <Card
                title="NUEVA ÁREA DEL TC"
                content={
                  this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                  <div>
                    <div className="row">
                        <div className="col-md-12" style={{marginBottom: 18}}>
                            <ControlLabel>NOMBRE DEL ÁREA</ControlLabel>
                            <FormControl  type="text" value={this.state.descripcion}  onChange={this.handleDescripcion} />
                        </div>
                        <div className="col-md-12" style={{marginBottom: 18}}>
                            <ControlLabel>DESCRIPCIÓN</ControlLabel>
                            <FormControl componentClass="textarea" 
                            style={{ height: 50, maxHeight: 50, minHeight: 50, width: "100%", maxWidth:"100%", minWidth:"100%" }} 
                            value={this.state.name} onChange={this.handleName}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>JEFE PRINCIPAL </ControlLabel>
                            <Select value={this.state.jefeF} onChange={this.handleJefeF} options={this.state.jefes}  placeholder="Seleccione el jefe de área"/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>JEFE SECUNDARIO</ControlLabel>
                            <Select value={this.state.jefeS} onChange={this.handleJefeS} options={this.state.jefes}  placeholder="Seleccione el jefe de área"/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>REFERENCIA <a>(OPCIONAL)</a></ControlLabel>
                            <FormControl componentClass="textarea" 
                            style={{ height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%" }} 
                            value={this.state.referencia} onChange= {this.handleReferencia}/>
                        </div>
                    </div>
                    <Button style={{width: 150, marginLeft: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Registro</Button>
                    <Button style={{width: 150, marginLeft: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Guardar Registro</Button>
                  </div>
                 )}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CrearArea;