import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import moment from 'moment';
import 'moment/locale/es';

const styles = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 45,
      paddingHorizontal: 35,
      flexGrow: 1,
    },
    title: {
      fontSize: 15,
      textAlign: 'center',
    },
    author2: {
      fontSize: 16,
      textAlign: 'center',
      marginTop: 10
    },
    subtitle3: {
      fontSize: 10,
      marginBottom: 8,
      textAlign: 'right',
    },
    subtitle4: {
      fontSize: 10,
      marginBottom: 8,
      textAlign: 'right',
      marginTop: 40,
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 350,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderRightWidth: 0, 
        marginTop: 15, 
        marginBottom: 30,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderBottomWidth: 0
      }, 
      tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
      }, 
      tableColHeader: { 
        width: "10%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      }, 
      tableColHeader2: { 
        width: "20%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },   
      tableColHeader3: { 
        width: "5%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },   
      tableColHeader4: { 
        width: "15%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },   
      tableCol: { 
        width: "10%", 
        borderStyle: "solid", 
        textAlign: 'center',
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,  
        borderTopWidth: 0,
        borderBottomWidth: 0  
      },  
      tableCol2: { 
        width: "20%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        borderBottomWidth: 0 
      }, 
      tableCol3: { 
        width: "5%", 
        borderStyle: "solid", 
        textAlign: 'center',
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0, 
        borderBottomWidth: 0
      },
      tableCol4: { 
        width: "15%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        borderBottomWidth: 0
      },
      tableCol5: { 
        width: "10%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0,
        borderRightWidth: 0,  
        borderBottomWidth: 0  
      },  
      tableCol6: { 
        width: "20%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderRightWidth: 0,  
        borderBottomWidth: 0 
      }, 
      tableCol7: { 
        width: "5%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderRightWidth: 0,  
        borderBottomWidth: 0
      },
      tableCol8: { 
        width: "15%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0,
        borderRightWidth: 0,   
        borderBottomWidth: 0
      },
      tableCol9: { 
        width: "10%", 
        borderStyle: "solid", 
        textAlign: 'center',
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderTopWidth: 0,
        borderBottomWidth: 0  
      }, 
      tableCol10: { 
        width: "10%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0,
        borderRightWidth: 0, 
        borderBottomWidth: 0 
      }, 
      tableCol11: { 
        width: "10%", 
        borderStyle: "solid", 
        textAlign: 'right',
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,  
        borderTopWidth: 0,
        borderBottomWidth: 0  
      },
      tableCellHeader: {
        margin: "auto", 
        margin: 5, 
        fontSize: 9,
        fontWeight: 500,
        textAlign: 'center'
      },  
      tableCell: { 
        margin: "auto", 
        margin: 5, 
        fontSize: 8
      },
      row: {
        flexDirection: 'row',
        marginBottom: 30,
      },
});

export function reporteLibro(movimientos, row) {
    const fecha = moment(new Date()).locale('es').format('LLLL')
    return (
        <Document>
        <Page size="A4" orientation="landscape" style={styles.body}>
          <Image style={styles.image} src="./icon.png"/>
          <Text style={styles.title}>Tribunal Constitucional del Perú</Text>
          <Text style={styles.author2}>Libro Auxiliar de Fondos para Pagos en Efectivo: {row[0].mes + " del " + row[0].anio}</Text>
          <View style={styles.table}> 
                <View style={styles.tableRow}> 
                  <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>NÚMERO</Text> 
                  </View> 
                  <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>FECHA</Text> 
                  </View> 
                  <View style={styles.tableColHeader4}> 
                      <Text style={styles.tableCellHeader}>DOCUMENTO</Text> 
                  </View> 
                  <View style={styles.tableColHeader2}> 
                      <Text style={styles.tableCellHeader}>NOMBRE</Text> 
                  </View>
                  <View style={styles.tableColHeader2}> 
                      <Text style={styles.tableCellHeader}>CONCEPTO</Text> 
                  </View>
                  <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>C. GASTOS</Text> 
                  </View>
                  <View style={styles.tableColHeader3}> 
                      <Text style={styles.tableCellHeader}>META</Text> 
                  </View>
                  <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>IMPORTE</Text> 
                  </View>
                </View>
                {movimientos.map((data, i) => {
                    return (
                        <View style={styles.tableRow}> 
                          <View style={styles.tableCol9}> 
                              <Text style={styles.tableCell}>{data.numero}</Text> 
                          </View> 
                          <View style={styles.tableCol}> 
                              <Text style={styles.tableCell}>{data.fecha}</Text> 
                          </View> 
                          <View style={styles.tableCol4}> 
                              <Text style={styles.tableCell}>{data.documento}</Text> 
                          </View> 
                          <View style={styles.tableCol2}> 
                              <Text style={styles.tableCell}>{data.nombre}</Text> 
                          </View> 
                          <View style={styles.tableCol2}> 
                              <Text style={styles.tableCell}>{data.concepto}</Text> 
                          </View> 
                          <View style={styles.tableCol}> 
                              <Text style={styles.tableCell}>{data.clasificador}</Text> 
                          </View> 
                          <View style={styles.tableCol3}> 
                              <Text style={styles.tableCell}>{data.meta}</Text> 
                          </View> 
                          <View style={styles.tableCol11}> 
                              <Text style={styles.tableCell}>{data.importe.replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('S/.', '')}</Text> 
                          </View> 
                        </View> 
                    );
                 })}
                 <View style={styles.tableRow}> 
                    <View style={styles.tableCol10}> 
                        <Text style={styles.tableCell}></Text> 
                    </View> 
                    <View style={styles.tableCol5}> 
                        <Text style={styles.tableCell}></Text> 
                    </View> 
                    <View style={styles.tableCol8}> 
                        <Text style={styles.tableCell}></Text> 
                    </View> 
                    <View style={styles.tableCol6}> 
                        <Text style={styles.tableCell}></Text> 
                    </View> 
                    <View style={styles.tableCol6}> 
                        <Text style={styles.tableCell}></Text> 
                    </View> 
                    <View style={styles.tableCol5}> 
                        <Text style={styles.tableCell}></Text> 
                    </View> 
                    <View style={styles.tableCol7}> 
                        <Text style={styles.tableCell}></Text> 
                    </View> 
                    <View style={styles.tableCol5}> 
                        <Text style={styles.tableCell}></Text> 
                    </View> 
                  </View> 
            </View>
          {/* <Text style={styles.subtitle3}>
            Fondo a Reponer Mes Anterior: {row[0].gastado2}
          </Text>
          <Text style={styles.subtitle3}>
            Diferencia con Fondo Fijo de Caja: {row[0].saldo2}
          </Text>
          <Text style={styles.subtitle3}>
            Reembolso del Fondo para Pagos en Efectivo: {row[0].gastado}
          </Text>
          <Text style={styles.subtitle3}>
            Saldo del Fondo para Pagos en Efectivo: {row[0].saldo}
          </Text> */}
          <Text style={styles.subtitle3}>
            TOTAL MES ACTUAL: {row[0].gastado.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
          <Text style={styles.subtitle3}>
            TOTAL MES ANTERIORES: {row[0].gastado2.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
          <Text style={styles.subtitle4}>
            Lima, {fecha}
          </Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
        </Page>
      </Document>
    );
}