import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import axios from 'axios';
import { sisperplus } from 'store/Apis'
import { validator ,gestor} from 'store/Apis'
import ReactTooltip from 'react-tooltip'
import Select from 'react-select';
import Button from "components/CustomButton/CustomButton.jsx";
import InputFiles from 'react-input-files';
import Calendar from "components/Calendar/Calendar.jsx";
import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Boletas extends Component {

  constructor(props) {
      super(props);
      this.state = {
      path: 'boletas',
      dni: localStorage.getItem('dni'),
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      selectedOption: {value: new Date().getFullYear(), label: new Date().getFullYear()},
      anios: [],
      boletas: [],
      isLoading: false,
      archivo:"",
      ver:false,
      nid:"",
      file:"",
      fileName:"",
      mes:"",
      numMes:"",
      numAnio:""
    }
      this.GetActionFormat= this.GetActionFormat.bind(this);
      this.GetActionFormat2= this.GetActionFormat2.bind(this);
      this.GetActionFormat3= this.GetActionFormat3.bind(this);
      this.subir= this.subir.bind(this);
  }

      handleChange = selectedOption => {
          this.setState(
          { selectedOption },
          () => this.listar()
          );
      };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.cargaView();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async cargaView(){
    let lista = []
    var anioini = 2017
    var aniofin = new Date().getFullYear()
    var cont = (aniofin - anioini) + 1
    for(var i = 0; i < cont; i++){
      lista.push(
        { value: anioini + i,
          label: anioini + i
      })
    }
    this.setState({anios: lista, isLoading: true})
    this.listar();
  }

  async listar(){
    let anio = this.state.selectedOption.value
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'boletas/lista?dni=' + this.state.dni + '&anio=' + anio)
    .then((res) => {
      let lista = res.data.response;
      if(lista != null){
        this.setState({boletas: lista, isLoading: false})
      }else{
        this.setState({boletas: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  subirArchivo(){
    if(this.state.archivo != ""){
      let nuevoNombre =   this.state.fileName.replace('.pdf', '[R][R].pdf')
      this.setState({ isLoading: true,archivo: nuevoNombre})
      let fromData = new FormData();
      fromData.append('nid', this.state.nid);
      fromData.append('archivo', nuevoNombre);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${sisperplus}` + 'boletas/insertArchivoFirmado', fromData)
      .then((response) => {
        var datos = response.data.response
          this.uploadArchivo()   
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Adjunte la boleta firmada!", "pe-7s-close")
    }  
  }
  
  uploadArchivo(){
    let fromData = new FormData();
    fromData.append('usuario', this.state.usuario);
    fromData.append('archivo_file', this.state.file);
    fromData.append('anio', this.state.numAnio);
    fromData.append('mes', this.state.numMes);
    fromData.append('cnomArchivo', this.state.archivo);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${gestor}` + 'document/subirArchivoBol', fromData)
    .then((response) => {
      if(response.data.result == null){
        this.props.handleClick("tl", "info", "Tu boleta firmada ha sido registrada!", "pe-7s-smile")
        this.cancelar()
        this.listar()
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al guardar la boleta!", "pe-7s-close")
        this.setState({isLoading2: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });   
}

confirmarDelete = (data) => {
  confirmAlert({
    title: '¿Seguro que desea eliminar el archivo?',
    message: 'Se eliminará la boleta firmada.',
    buttons: [
      {
        label: 'Aceptar',
        onClick: () => this.remove(data)
      },
      {
        label: 'Cancelar',
        onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
      }
    ]
  });
};

remove(data){
  this.setState({isLoading: true})
  let fromData = new FormData();
  fromData.append('nid', data.NID_BOLPAGOINTRA);
  axios.defaults.headers.common['Authorization'] = this.state.token
  axios.post(`${sisperplus}` + 'boletas/eliminarArchivo', fromData)
  .then((response) => {
    var datos = response.data
       this.removerArchivo(data); 
  })
  .catch((error) =>{ 
    if(auth0Client.isAuthenticated() === true){
      auth0Client.signOut()
      this.props.history.replace('/');
    }else{
      console.log(error);
      this.setState({isLoading2: false})
    }
  });
 }

 removerArchivo(data){
  let nuevoNombreUrl =   data.CTXT_ARCHIVOFIRMADO.replace('[R][R].pdf', '%5BR%5D%5BR%5D.pdf')
  let fromData = new FormData();
  fromData.append('usuario', this.state.usuario);
  fromData.append('anio', data.CNUM_ANO);
  fromData.append('mes', data.CNUM_MES);
  fromData.append('cnomArchivo', nuevoNombreUrl);
  axios.defaults.headers.common['Authorization'] = this.state.token
  axios.post(`${gestor}` + 'document/bajarArchivoBol', fromData)
  .then((response) => {
    if(response.data.result == null){
        this.cargaView()
        this.props.handleClick("tl", "info", "Tu boleta firmada ha sido eliminada!", "pe-7s-smile")  
    }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar la boleta!", "pe-7s-close")
        this.setState({isLoading: false})
    }
  })
  .catch((error) =>{ 
  if(auth0Client.isAuthenticated() === true){
      auth0Client.signOut()
      this.props.history.replace('/');
  }else{
      console.log(error);
      this.setState({isLoading: false})
  }
  });

}

  async ver(row){
    if(row.CTXT_ARCHIVOFIRMADO==null){
       window.open( row.TXTFILE, "_blank")
    }else{
      window.open( row.TXTFIRMADO, "_blank")
    }
 //  
    // localStorage.setItem('visor', JSON.stringify(row))
    // this.props.history.push('/visorboletas');
  }


  subir2 = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0],archivo: files[0].name, type: files[0].type, visFile: true});
    }
  }

  GetActionFormat(cell, row) {
    return (
        <div>
            <span data-tip="Ver Boleta" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver(row) }}>
              <ReactTooltip />
        
              <i className="fa fa-file-pdf-o fa-3x" style={{fontSize: 27, color:"#DEA011"}}></i>
            </span>
        </div>
    );
  }
  
  GetActionFormat2(cell, row) {
    return (
        <div>
          {row.CTXT_ARCHIVOFIRMADO== null ? 
            <span data-tip="Descargar Boleta" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver2(row) }}>
              <ReactTooltip />
              <i className="fa fa-download fa-stack-1x fa-inverse" style={{fontSize: 25, color:"#1B4F72"}}></i>
            </span> :
            <span data-tip="Descargar Boleta Firmada" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver2(row) }}>
              <ReactTooltip />
              <i className="fa fa-download fa-stack-1x fa-inverse" style={{fontSize: 25, color:"#20AB10"}}></i>
            </span>
             }
        </div>
    );
  }

  GetActionFormat3(cell, row) {
    if(row != undefined){
      return (
          <div> {row.CTXT_ARCHIVOFIRMADO!=null ? 
              <span data-tip="Eliminar boleta firmada" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x "></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              : null}
          </div>
      );
    }else{}
  }

  async ver2(row){
    var archivo = row.CTXT_ARCHIVOFIRMADO==null ? row.TXTFILE : row.TXTFIRMADO
    archivo = archivo.replace('http://181.177.234.7/papeletas/boletas/firmados/', '')
    window.open("https://gestordocumentaltc.sedetc.gob.pe/index.php/s/MSA22243tna4k7i/download?path=%2F&files=" + archivo, "_blank")
  }

  subir(cell, row) {
    return (
        <div>
          {row.CTXT_ARCHIVOFIRMADO==null ? 
            <span data-tip="Subir Boleta Firmada" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.habilitar(row) }}>
              <ReactTooltip />
              <i className="fa fa-arrow-circle-o-up fa-stack-1x fa-inverse text-info" style={{fontSize: 32}}></i>
            </span>
            : null}
        </div>
    );
  }

  habilitar(data){
    this.setState({
      ver : true,
      nid : data.NID_BOLPAGOINTRA,
      fileName: data.CTXT_ARCHIVO,
      mes: data.TXTMES,
      numMes: data.CNUM_MES,
      numAnio: data.CNUM_ANO
      }) 
  }

  cancelar(){this.setState({ver : false, archivo: ""})}

  limpiar(){this.setState({ archivo: "" })}

  render() {
    const { selectedOption } = this.state;
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "TXTMES", text: "MES", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ver", text: "VER", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat},
      {dataField: "a", text: "DESCARGAR", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},
      {dataField: "b", text: "SUBIR ARCHIVO", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.subir},
      {dataField: "c", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat3}
    ]
 

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Consulta tus Boletas de Pago</h4>
                <div className="row">
                    <div className="col-md-4" >
                      <ControlLabel style={{fontWeight: 100, fontSize: 12}}>FECHA DE BÚSQUEDA</ControlLabel>
                      <Select value={selectedOption} onChange={this.handleChange} options={this.state.anios} />
                    </div>
                  
                    <div className="col-md-12 text-center" style={{ marginBottom: 18}}>
                      <h4 className="category" style={{fontStyle:"italic", fontSize: 14,  color:"grey"}}>
                        Para subir su boleta firmada deberá dar clic en el botón Subir Archivo y luego adjuntar el documento para finalmente guardarlo.
                      </h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                      {this.state.isLoading ? (
                        <ClipLoader
                          css={override}
                          sizeUnit={"px"}
                          size={50}
                          color={'#123abc'}
                          loading={this.state.loading}
                        />
                      ) : (
                        <div>
                          {this.state.ver  ? 
                            <div className="col-md-12">
                                <ControlLabel>SUBIR BOLETA FIRMADA DEL MES DE {this.state.mes}</ControlLabel>
                                <Alert bsStyle="warning">
                                    <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo}</span>
                                </Alert>
                                <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                                    <InputFiles accept={'pdf/*'} onChange={files => this.subir2(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                                </Button>
                                {this.state.archivo != "" ? (
                                  <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar Archivo</Button>
                                ) : (  null )}
                                <Button style={{width: 200,marginTop:18,marginLeft:5,marginBottom:18}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Cancelar</Button>   
                                <Button style={{width: 200,marginTop:18,marginBottom:18}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.subirArchivo() }}>Guardar Documento</Button>     
                            </div>
                          : null }
                          <div>
                            <BootstrapTable
                              striped
                              hover
                              keyField="item"
                              data={ this.state.boletas }
                              columns={ columns }
                              noDataIndication="No existen registros para mostrar"
                            />
                          </div>
                          <div className="col-md-12 text-center" style={{ marginBottom: 18}}>
                            <h4 className="category" style={{fontStyle:"italic", fontSize: 14,  color:"grey"}}>
                            Las boletas firmadas se reflejarán en el sistema después de 15 minutos de haberse subido debido a la sincronización con la web.                      </h4>
                          </div>
                        </div>
                      )}
                    </div>    
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Boletas;