import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator,sisperplus} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Select from 'react-select';
import InputFiles from 'react-input-files';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class DeclaracionJurada extends Component {

    constructor(props) {
        super(props)
        this.state = {
            path: 'miLegajo',
            usuario: localStorage.getItem('nickname'),
            token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
            id: JSON.parse(localStorage.getItem('legajo')).legajo[0].idpersona,
            nombre: JSON.parse(localStorage.getItem('legajo')).legajo[1].nombre,
            codcategoria: JSON.parse(localStorage.getItem('legajo')).legajo[2].idcategoria,
            descategoria: JSON.parse(localStorage.getItem('legajo')).legajo[3].descategoria,
            enlace: JSON.parse(localStorage.getItem('legajo')).legajo[4].enlace,
            idlegajo: JSON.parse(localStorage.getItem('legajo')).legajo[5].idlegajo,
            movimientos: [],
            tipodocumentos: [],                                    
            iddeclaracionjurada: "",
            nid:"",
            descripcion: "",  
            idcategoria: "",
            descategoria: "",
            file: [],
            archivo: "",
            archivoold: "",
            type: "",
            selectedTipoDocumento: "", 
            isLoading: true,             
        }

        this.GetActionFormat= this.GetActionFormat.bind(this);
        //this.handleDescripcion=this.handleDescripcion.bind(this);
    }
    
    changeTipoDocumento = selectedTipoDocumento =>{ this.setState({selectedTipoDocumento});};
    
    async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{                    
                    this.listarTipoDocumentos();
                    this.listarDeclaracionJurada();
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }

    async listarTipoDocumentos() {        
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'legajo/listTipoDocumentos?filtro=2')        
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({ 
                    value: data.id_tipodocumento,
                    label: data.descripcion,     
                })
              })
    
              this.setState({tipodocumentos: lista, selectedTipoDocumento: {value: lista[0].value, label: lista[0].label}})
            }else{
              this.setState({tipodocumentos: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }

    async listarDeclaracionJurada() {   
        this.setState({isLoading: true})     
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'legajo/listDeclaracionJurada?idlegajo=' + this.state.idlegajo)
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({  
                    iddeclaracionjurada: data.nid_declaracionjurada,                     
                    nid: data.nid_documento,                
                    idtipodocumento: data.id_tipodocumento,
                    destipodocumento: data.des_tipodocumento,    
                    idestado: data.estado_solicitud,
                    estado: data.nomestado,              
                    archivo: data.nom_archivo,
                    archivoold: data.nom_archivo,
                    archivonuevo: data.nom_archivonuevo,
                    rutaarchivo: data.ruta_archivo,
                    item: data.item,
                    idlegajo: data.id_legajo
                })
              })
              this.setState({movimientos: lista, isLoading: false})
            }else{
              this.setState({movimientos: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);  
            this.setState({isLoading: false})
          }
        });
    }

    GetActionFormat(cell, row) {
        if(row != undefined){
            return (
              <div>
                    <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
                        <ReactTooltip />
                        <i className="fa fa-circle fa-stack-2x text-success"></i>
                        <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                    </span>   
                    <span data-tip="Descargar archivo" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.download(row) }}>
                        <ReactTooltip />
                        <i className="fa fa-circle fa-stack-2x" style={{color:"#1B4F72"}}></i>
                        <i className="fa fa-download fa-stack-1x fa-inverse" style={{fontSize: 18, color:"white"}}></i>
                    </span>                           
                    <span  data-tip="Eliminar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                    <ReactTooltip />
                    <i className="fa fa-circle fa-stack-2x"></i>
                    <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                    </span>
              </div>
            );
        }else{}
    }

    confirmarDelete = (data) => {
        confirmAlert({
          title: '¿Seguro que desea eliminar el registro?',
          message: 'Se eliminará el registro seleccionado.',
          buttons: [
            {
              label: 'Aceptar',
              onClick: () => this.delete(data)
            },
            {
              label: 'Cancelar',
              onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
            }
          ]
        });
    };

    async delete(data){
        this.setState({isLoading: true})
        const iddoc = data.nid
        const idleg = data.idlegajo
        let fromData = new FormData();
        fromData.append('nid', data.iddeclaracionjurada);
        fromData.append('idlegajo', data.idlegajo);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'legajo/eliminarDeclaracionJurada', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            this.deleteDocumento(iddoc,idleg)
            this.props.handleClick("tl", "info", "El registro ha sido eliminado!", "pe-7s-smile")
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
            this.setState({isLoading: false})
          }
        })
        .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }

    async deleteDocumento(iddoc,idleg){
        this.setState({isLoading: true})
        let fromData = new FormData();
        fromData.append('nid', iddoc);
        fromData.append('idlegajo', idleg);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'legajo/eliminarDocumentoLegajo', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            this.listarTipoDocumentos()
            this.listarDeclaracionJurada()
            this.props.handleClick("tl", "info", "El documento ha sido eliminado!", "pe-7s-smile")
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
            this.setState({isLoading: false})
          }
        })
        .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }
    
    async obtener(data){
        this.setState({
            iddeclaracionjurada: data.iddeclaracionjurada,
            nid: data.nid,
            selectedTipoDocumento: {value: data.idtipodocumento, label: data.destipodocumento},
            archivo: data.archivo,
            archivoold: data.archivoold,
        });
        console.log('id--> ' + this.state.nid)
        console.log('iddeclaracionjurada--> ' + this.state.iddeclaracionjurada)
    }
    
    async download(data){         
        let archivo = data.rutaarchivo       
        window.open(archivo, "_blank")
    }

    async regresar(){
        this.props.history.push("/fichaPersonal")
    }
      
    async limpiar2(){               
        this.setState({archivo: "", file: "", type: "", nid: ""})        
    }
     
    async cancelar(){
        this.setState({ archivo: "", file: "", type: ""})
    }

    subir = (files) => {
        if(files.length !== 0){
            this.setState({file: files[0], archivo: files[0].name, type: files[0].type, visFile: true});
        }
    }

    async validar(){        
        if(this.state.archivo != ''){
            if(this.state.type == '' || this.state.type == 'application/pdf') {
                if(this.state.nid == ''){
                    this.guardar();
                }else{
                    this.actualizar();                    
                }                              
            }else{
                this.props.handleClick("tl", "warning", "El archivo a subir debe ser un PDF!", "pe-7s-info")                
            }
        }else{
            this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
        }
    }

    async guardar(){
        this.setState({isLoading: true})
        let fromData = new FormData();         
        fromData.append('idlegajo', this.state.idlegajo);        
        fromData.append('idtipodocumento', this.state.selectedTipoDocumento.value);                
        fromData.append('usuario', this.state.usuario);              
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'legajo/insertDeclaracionJuradaLegajo', fromData)
        .then((response) => {  
            var datos = response.data              
            if(datos.response != null){
                if(datos.response.codigo == "0"){
                    this.setState({isLoading: false})
                    this.props.handleClick("tl", "error", "No se pudo completar el registro!", "pe-7s-close")
                }else{
                    this.props.handleClick("tl", "success", "Datos registrados correctamente!", "pe-7s-close")
                    this.guardarDocumento(datos.response.codigo);    
                }                    
            }else{
                this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
                this.setState({isLoading: false})
            }
        })
        .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
            }else{
                console.log(error);
                this.setState({isLoading: false})
            }
        });  
    }

    async guardarDocumento(idregistro){
        this.setState({isLoading: true})
        let fromData = new FormData();                      
        fromData.append('nid', idregistro);
        fromData.append('idlegajo', this.state.idlegajo);
        fromData.append('idseccion', this.state.codcategoria);
        fromData.append('idtipodocumento', '3');        
        fromData.append('file', this.state.file);
        fromData.append('archivo', this.state.archivo)                      
        fromData.append('usuario', this.state.usuario);              
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'legajo/insertDocumentoLegajo', fromData)
        .then((response) => {                
            if(response.data.result == null){
                this.props.handleClick("tl", "info", "Se registro documento al legajo!", "pe-7s-smile")
                this.listarDeclaracionJurada();
                this.limpiar2();
            }else{
            this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
            this.setState({isLoading: false})
            }
        })
        .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
            }else{
                console.log(error);
                this.setState({isLoading: false})
            }
        });  
    }

    async actualizar(){
        this.setState({isLoading: true})
        let fromData = new FormData();         
        fromData.append('nid', this.state.iddeclaracionjurada);        
        fromData.append('idlegajo', this.state.idlegajo);        
        fromData.append('idtipodocumento', this.state.selectedTipoDocumento.value);                
        fromData.append('usuario', this.state.usuario);              
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'legajo/updateDeclaracionJuradaLegajo', fromData)
        .then((response) => {  
            var datos = response.data              
            if(datos.response != null){
                this.props.handleClick("tl", "success", "Datos actualizados correctamente!", "pe-7s-close")                
                if(this.state.archivo != this.state.archivoold){
                    this.actualizarDocumento();
                }else{
                    this.listarDeclaracionJurada();
                    this.limpiar2();
                }                
            }else{
                this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
                this.setState({isLoading: false})
            }
        })
        .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
            }else{
                console.log(error);
                this.setState({isLoading: false})
            }
        });  
    }

    async actualizarDocumento(){
        this.setState({isLoading: true})
        let fromData = new FormData();                      
        //fromData.append('id', this.state.idreg);
        fromData.append('nid', this.state.nid);
        fromData.append('idlegajo', this.state.idlegajo);
        fromData.append('idseccion', this.state.codcategoria);
        fromData.append('idtipodocumento', '3');        
        fromData.append('file', this.state.file);
        fromData.append('archivo', this.state.archivo)                      
        fromData.append('usuario', this.state.usuario);              
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'legajo/updateDocumentoLegajo', fromData)
        .then((response) => {                
            if(response.data.result == null){
                this.props.handleClick("tl", "info", "Se actualizó documento!", "pe-7s-smile")  
                this.listarDeclaracionJurada();
                this.limpiar2();              
            }else{
                this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
                this.setState({isLoading: false})
            }
        })
        .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
            }else{
                console.log(error);
                this.setState({isLoading: false})
            }
        });  
    }

    render(){
        const columns = [
            {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "destipodocumento", text: "TIPO DE DOCUMENTO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:400}, headerAlign: (column, colIndex) => 'center', align: 'center'},            
            {dataField: "archivo", text: "ARCHIVO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:600}, headerAlign: (column, colIndex) => 'center', align: 'center'},            
            // {dataField: "estado", text: "ESTADO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
        ]
        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'Primera',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Última',
            showTotal: true,
            totalSize: this.state.movimientos.length
        };
        const contentTable = ({ paginationProps, paginationTableProps }) => (
            <div>
              <div>
                <div>
                  <BootstrapTable
                    striped
                    hover
                    keyField="item"
                    data={ this.state.movimientos }
                    columns={ columns }
                    filter={ filterFactory() }
                    noDataIndication="No existen registros para mostrar"
                    { ...paginationTableProps }
                  />
                </div>
                <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
              </div>
            </div>
        );
        return(
            <div className="content">
                <Grid fluid>
                <Row>
                    <Col md={12}>
                        <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                        <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>DECLARACIÓN JURADA</h4>
                        <div className="row" style={{marginBottom: 10}}>                            
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>TIPO DE DOCUMENTO</ControlLabel>
                                <Select value={this.state.selectedTipoDocumento} onChange={this.changeTipoDocumento} options={this.state.tipodocumentos}/>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <ControlLabel>DOCUMENTO</ControlLabel>
                                <Alert bsStyle="info">
                                <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo}</span>
                                </Alert>
                                <Button style={{width: '100%', marginTop: -10}} bsStyle="info" pullRight fill type="submit">
                                <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar un documento</InputFiles>
                                </Button>
                                {this.state.archivo != "" ? (
                                <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar2() }}>Limpiar Archivo</Button>
                                ) : ( null )}
                            </div>     
                            <div className="col-md-2">                                                 
                                <Button style={{width: 200, marginBottom: 10, marginRight :10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>CANCELAR</Button>
                                <Button style={{width: 200, marginBottom: 10, marginRight :10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.validar() }}>GUARDAR</Button>                                  
                            </div>
                        </div>   
                        {this.state.isLoading ? (
                                <ClipLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    size={50}
                                    color={'#123abc'}
                                    loading={this.state.loading}
                                />
                                ) : (
                                <PaginationProvider
                                    pagination={
                                    paginationFactory(options)
                                    }
                                >
                                    { contentTable }
                                </PaginationProvider>
                            )}
                        </div>
                        <Button style={{width: 250, marginTop: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                    </Col>
                </Row>
                </Grid>
            </div>
        );
    }

}

export default DeclaracionJurada;