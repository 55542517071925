import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { sisperplus} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import ReactExport from 'react-export-excel'

const ExcelFile = ReactExport.ExcelFile; // indica el archivo excel que vamos a crear
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;//nombre de hoja de excel
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;//nombre de las columnas de excel

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ConsultarTesauros extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'tesauros',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      show: true,
      listasTesa:[],
      tipoTesauro:"",
      identificador: {value: "S", label: "SENTENCIA"},
      iden: "S",
      identificadores: [
        {value: "S", label: "SENTENCIA"},
        {value: "I", label: "INTERLOCUTORIA"},
        {value: "A", label: "AUTO"}
      ]      
    }
       this.verenlace=this.verenlace.bind(this);    
  } 

   handleIdentificador = identificador => {
    this.setState(
       { identificador },
       () => this.ListarTipoTesauros()
       );
    };

    handleListarTesauros = tipoTesauro => {
        this.setState(
          { tipoTesauro }
         );
    };
    

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.ListarTipoTesauros();            
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
 
  async ListarTipoTesauros(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'tesaurio/listTesaurio?tipo='+ this.state.identificador.value)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;   
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
            { value: data.id,          
              label: data.nombre
            })        
          })   
          this.setState({listasTesa: lista, tipoTesauro: {value: lista[0].value, label: lista[0].label}, isLoading: false})               
        }else{
          this.setState({listasTesa: []})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")       
        }
      })
    .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
    });
 }

 async listarRegistro() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'tesaurio/reporteTesaurio?tipo='+this.state.identificador.value+'&tesaurio='+this.state.tipoTesauro.value)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;   
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
            { 
              fechapublicacion :Calendar.calendario12(data.fecha_publicacion),
              fechasentencia: Calendar.calendario12(data.fecha_sentencia),
              nombreDemandado: data.nombre_demandado,
              nombreDemandante: data.nombre_demandante,
              numeroExpediente :data.numero_expediente,
              url_archivo : data.url_archivo,
              item: data.item
            })        
          })   
         this.setState({movimientos: lista, isLoading: false})               
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")       
        }
      })
    .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
    });
  }

  
  verenlace(cell, row) {
    if(row != undefined){
      return (
          <div>
              {row.url_archivo != null && row.url_archivo != ''? (
               <span data-tip="Ver Documento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(row.url_archivo) }}>
               <ReactTooltip />
               <i className="fa fa-file-pdf-o fa-3x" style={{fontSize: 35, color:"red"}}></i>
             </span>
              ) : (null)}
          </div>
      );
    }else{}
  }

  abrirImagen(ruta){
    window.open(ruta, '_blank');
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:100}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "numeroExpediente", sort: true,text: "EXPEDIENTE", filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white",width:120}, headerAlign: (column, colIndex) => 'center', align: 'center'},     
      {dataField: "nombreDemandante", text: "DEMANDANTE", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nombreDemandado", text: "DEMANDADO", filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechapublicacion", text: "FECHA DE PUBLICACIÓN" ,headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechasentencia", text: "FECHA DE SENTENCIA", headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "url_archivo", text: "ARCHIVO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:250}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter:this.verenlace}
     ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const { tipoTesauro,identificador} = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                  <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de expedientes</h4>
                  <div className="row">
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>TIPOS</ControlLabel>
                          <Select value={identificador} onChange={this.handleIdentificador} options={this.state.identificadores}/>
                      </div>
                      <div className="col-md-6" style={{marginBottom: 18}}>
                          <ControlLabel>LISTA DE TESAUROS</ControlLabel>
                          <Select value={tipoTesauro} onChange= {this.handleListarTesauros} options={this.state.listasTesa}/>
                      </div>
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel></ControlLabel><br></br>
                          <Button style={{width: 180, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.listarRegistro() }}>Buscar</Button>
                          <ExcelFile element={<Button style={{backgroundColor:"green" ,border:0,width: 175,height:40,marginBottom:5}}  fill type="submit">Exportar a Excel</Button>} filename= {"REPORTE DE EXPEDIENTES TESAURO"}>
                            <ExcelSheet data={this.state.movimientos}  name ={this.state.identificador.label + "-" + this.state.tipoTesauro.label }>
                                <ExcelColumn label="ITEM" value="item" ></ExcelColumn>
                                <ExcelColumn label="EXPEDIENTE" value="numeroExpediente" ></ExcelColumn>
                                <ExcelColumn label="DEMANDANTE" value="nombreDemandante" ></ExcelColumn>
                                <ExcelColumn label="DEMANDADO" value="nombreDemandado" ></ExcelColumn>
                                <ExcelColumn label="FECHA DE PUBLICACION" value="fechapublicacion" ></ExcelColumn>
                                <ExcelColumn label="FECHA DE SENTENCIA" value="fechasentencia" ></ExcelColumn>
                                <ExcelColumn label="ARCHIVO" value="url_archivo" ></ExcelColumn>
                            </ExcelSheet>
                          </ExcelFile>
                      </div>
                  </div>

                  {this.state.isLoading ? (
                          <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading}
                          />
                        ) : (
                          <PaginationProvider
                            pagination={
                              paginationFactory(options)
                            }
                          >
                            { contentTable }
                          </PaginationProvider>
                    )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ConsultarTesauros;