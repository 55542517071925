import React, { Component } from "react";
import { Grid, Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap';
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory  from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, cec} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { paramCec , sisperplus} from "store/Apis";

import { Card } from "components/Card/Card.jsx";
import CheckboxGroup from 'react-checkbox-group';


import Swal from 'sweetalert2'
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Notificar extends Component {

    constructor(props) {
        super(props);
        this.state = {
          path: 'resoluciones',    
          usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
          id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
          token: localStorage.getItem('AccessToken') != null ? 'Bearer '+ localStorage.getItem('AccessToken') : 0,
          movimientos: [],
          isLoading: true,
          checked: [],
          expanded: [],
          data:[],
          ruta_archivo:"",
          nid:"",
          cnum_resolucion:"",
          cnom_resolucion:"",
          ver_archivo:"",
          fecha:"",
          ctxt_linkExterno:"",
          cnom_archivo:"",
          selectAll: false,
        }
      }

      handleCheck = (checked) => {
        this.setState({ checked, selectAll: false });
      };

      handleExpand = (expanded) => {
        this.setState({ expanded });
      };

      toggleSelectAll = () => {
        const { data, checked, selectAll } = this.state;
        let updatedChecked = [...checked];
      
        if (selectAll) {
          // Si el checkbox "Seleccionar todo" está marcado, deseleccionar todos los registros
          updatedChecked = [];
        } else {
          // Si el checkbox "Seleccionar todo" está desmarcado, seleccionar todos los registros
          data.forEach((area) => {
            if (!updatedChecked.includes(area.value)) {
              updatedChecked.push(area.value);
            }
            area.children.forEach((trabajador) => {
              if (!updatedChecked.includes(trabajador.value)) {
                updatedChecked.push(trabajador.value);
              }
            });
          });
        }
      
        this.setState({
          checked: updatedChecked,
          selectAll: !selectAll,
        });
      };
      
     
      async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{
                this.validarLocal()
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
      }

      async validarLocal(){
        if(JSON.parse(localStorage.getItem("recursoshumanos")).resoluciones[0].data !== null){
            this.setState({
                nid: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.nid,
                ruta_archivo: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.ruta_archivo,
                cnum_resolucion: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.cnum_resolucion,
                cnom_resolucion: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.cnom_resolucion,
                ver_archivo: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.ver_archivo,
                fecha: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.fecha,
                ctxt_linkExterno: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.ctxt_linkExterno,
                cnom_archivo: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.cnom_archivo
              })
            this.listNotificar();
        }else{
          this.props.history.push('/resoluciones');
        }
      }

      async listNotificar() {
        this.setState({isLoading: true})
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'resoluciones/listPersonsNotificar')
        .then((res) => {     
          let lista2 = res.data.response[0].tree_structure;
            if(lista2 != null){
              let lista2 = res.data.response[0].tree_structure;
              const dataArray = JSON.parse(lista2);
              this.setState({data: dataArray, isLoading: false})
            }else{
              this.setState({data: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
      }
     
      async regresar(){
        this.props.history.push('/resoluciones');
      }

      confirmarNotificacion = () => {
        if(this.state.checked.length>0){
              confirmAlert({
                title: '¿Seguro que desea enviar la resolución?',
                message: 'Se enviarán a los registros seleccionados.',
                buttons: [
                  {
                    label: 'Aceptar',
                    onClick: () => this.confirmar2()
                  },
                  {
                    label: 'Cancelar',
                    onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
                  }
                ]
              });
          }else{
            this.props.handleClick("tl", "warning", "No se ha seleccionado ningún registro!", "pe-7s-smile")
          }
      };

      confirmar2 = () => {
        Swal.fire({
            title: "La resolución se está enviando a las personas seleccionadas",
            text: "Este proceso dura aproximadamente 5 minutos. Espere por favor...",
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading()
            },
            allowOutsideClick:false
          })
          this.notificar()
      };
  
    async notificar(){
        let fromData = new FormData();
        fromData.append('nidperson',this.state.checked);
        fromData.append('nidres',this.state.nid);
        fromData.append('usuario',this.state.usuario);
        fromData.append('enlaceDes',this.state.cnom_archivo != null ? this.state.ruta_archivo : "");  
        fromData.append('fecha',this.state.fecha);  
        fromData.append('cnum_resolucion',this.state.cnum_resolucion);
        fromData.append('cnom_resolucion',this.state.cnom_resolucion);
        fromData.append('enlaceVer',this.state.ver_archivo!= null ? this.state.ver_archivo :  this.state.ctxt_linkExterno);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'resoluciones/obtenerPerson', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            this.regresar()
            this.props.handleClick("tl", "info", "Se envió correctamente la resolución!", "pe-7s-smile")
             Swal.close();
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al notificar!", "pe-7s-close")
            this.setState({isLoading: false})
            Swal.close();
          }
         })
        .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }

    async regresar(){
      this.props.history.push('/resoluciones');
    }
    
    render() {
      const { selectAll } = this.state;
      return (
        <div className="content">
          <Grid fluid>
            <Row>
            <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 30 }}>
                <h4 className="title" style={{ fontWeight: 200, marginTop: 18, marginBottom: 35, textAlign:"center" }}>LISTA DE ÁREAS Y TRABAJADORES</h4>
                 
               <input type="checkbox" checked={selectAll}  onChange={this.toggleSelectAll} /> 
                  <span style={{ marginLeft: 5 , fontWeight: "bold",  color: "black", fontFamily: '"Roboto","Helvetica Neue",Arial,sans-serif', fontSize: 14}}>SELECCIONAR TODOS</span>
                <Button style={{ width: 220, marginLeft: 10 }} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                <Button style={{ width: 220, marginLeft: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.confirmarNotificacion() }}>Notificar Resoluciones</Button>
             </div>
              {this.state.data.map((area) => (
                <Col md={4} key={area.value}   >
                  <Card
                    title={
                      <div>
                        <input
                          type="checkbox"
                          value={area.value}
                          checked={this.state.checked.includes(area.value)}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            const value = area.value;
                            let updatedChecked = [...this.state.checked];
                            if (isChecked) {
                              area.children.forEach((trabajador) => {
                                if (!updatedChecked.includes(trabajador.value)) {
                                  updatedChecked.push(trabajador.value);
                                }
                              });
                              if (!updatedChecked.includes(value)) {
                                updatedChecked.push(value);
                              }
                            } else {
                              area.children.forEach((trabajador) => {
                                const index = updatedChecked.indexOf(
                                  trabajador.value
                                );
                                if (index !== -1) {
                                  updatedChecked.splice(index, 1);
                                }
                              });
                              const parentIndex = updatedChecked.indexOf(value);
                              if (parentIndex !== -1) {
                                updatedChecked.splice(parentIndex, 1);
                              }
                            }
                            this.setState({ checked: updatedChecked });
                          }}
                        />
                        <a style={{  marginLeft: 5, fontWeight: "bold",   color: "black", fontFamily: '"Roboto","Helvetica Neue",Arial,sans-serif',fontSize: 14  }} >
                          {area.label}
                        </a>
                      </div>
                    }
                    content={
                     <div style={{ height: 345, marginTop:-20}}>
                        {area.children.map((trabajador) => (
                          <div key={trabajador.value}  style={{ marginLeft: 20, fontFamily: '"Roboto","Helvetica Neue",Arial,sans-serif', fontSize: 11 }} >
                            <input
                              type="checkbox"
                              value={trabajador.value}
                              checked={this.state.checked.includes(trabajador.value)}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                const value = trabajador.value;
                                let updatedChecked = [...this.state.checked];
                                if (isChecked) {
                                  if (!updatedChecked.includes(value)) {
                                    updatedChecked.push(value);
                                  }
                                  const allChildrenChecked = area.children.every(
                                    (child) =>
                                      updatedChecked.includes(child.value)
                                  );
                                  if (
                                    allChildrenChecked &&
                                    !updatedChecked.includes(area.value)
                                  ) {
                                    updatedChecked.push(area.value);
                                  }
                                } else {
                                  const index = updatedChecked.indexOf(value);
                                  if (index !== -1) {
                                    updatedChecked.splice(index, 1);
                                  }
                                  const parentIndex = updatedChecked.indexOf(
                                    area.value
                                  );
                                  if (parentIndex !== -1) {
                                    updatedChecked.splice(parentIndex, 1);
                                  }
                                }
                                this.setState({ checked: updatedChecked });
                              }}
                            />
                            <a style={{  marginLeft: 5,fontFamily: '"Roboto","Helvetica Neue",Arial,sans-serif',fontSize: 11,  color: "grey"
                              }}
                            >
                              {trabajador.label}
                            </a>
                          </div>
                        ))}
                      </div>
                    }
                  />
                </Col>   
              ))}
                {/* <Button style={{ width: 250, marginLeft: 10, marginTop: 15 }} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                  <Button style={{ width: 250, marginLeft: 10, marginTop: 15 }} bsStyle="success" pullRight fill type="submit" onClick={() => { this.confirmarNotificacion() }}>Notificar Resoluciones</Button> */}
            </Row>
          </Grid>
        </div>
      );
    }
}
export default Notificar;
