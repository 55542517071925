import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import axios from 'axios';
import { gestor, sisperplus } from 'store/Apis'

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from 'react-select';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { validator } from 'store/Apis'

import { pdf } from "@react-pdf/renderer";
import { PdfDocument } from "./mipdf";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import Calendar from "components/Calendar/Calendar.jsx";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
    margin-TOP: 100px;
`;

class Autorizar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'autorizar',
      dni: localStorage.getItem('dni'),
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      nombre: JSON.parse(localStorage.getItem('datos'))[0].nombre,
      area:"",// {value: "1", label: JSON.parse(localStorage.getItem('datos'))[0].area}
      url: '',
      accion: {value: "1", label: "AUTORIZAR PAPELETA"},
      acciones: [{value: "1", label: "AUTORIZAR PAPELETA"},{value: "0", label: "DESAUTORIZAR PAPELETA"}],
      areas: [],
      listado: [],
      isLoading: false,
      tipo:{value: 'A', label: "AUTORIZADAS"},
      tipos:[{value: 'A', label: "AUTORIZADAS"},{value: 'D', label: "DESAUTORIZADAS"}],
      mes :"",
      anio:"",
      years:[],
      meses:[],
      listadoApro:[]
    }
  }

  handleArea = area => {
    this.setState(
    { area },
    () => this.listar()
    );
    
  };

  handleAccion = accion => {
    this.setState(
    { accion },
    );
  };

  handleAnio = anio => {
    this.setState(
    { anio },
    () => this.listarAprobados()
    );
  };

  handleMes = mes => {
    this.setState(
    { mes },
    () => this.listarAprobados()
    );
  };

  handleTipo = tipo => {
    this.setState( {tipo},
      () => this.listarAprobados());
  };

  submit = () => {
    let cuenta = this.node.selectionContext.selected
    if(cuenta.length >= 1){
      confirmAlert({
        title: '¿Seguro de procesar?',
        message: 'Se procesarán las papeletas seleccionadas.',
        buttons: [
          {
            label: 'Aceptar',
            onClick: () => this.procesar()
          },
          {
            label: 'Cancelar',
            onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
          }
        ]
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe seleccionar algún registro para procesar!", "pe-7s-info")
    }
  };

  async componentDidMount() {
  if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
      headers: {
          Authorization: this.state.token
      }
      }).then((response) => {
          let permisos = response.data;
          if(permisos.codError != 1){
              this.props.history.goBack()
          }else{
              this.generarPeriodos();
          }
      },(error) => {
          if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
          }else{
          this.props.history.goBack()
          }
      });
  }else{
      auth0Client.signOut();
      this.props.history.replace('/');
  }
  }

  generarPeriodos(){
    let year =  2023;
    let cont = 3;
    let array = Array.from(new Array(cont),(val, index) => index + year);
  //  let array = a.reverse()
    let arrayMes = [
      {"nid": "01", "descripcion": "ENERO"},
      {"nid": "02", "descripcion": "FEBRERO"},
      {"nid": "03", "descripcion": "MARZO"},
      {"nid": "04", "descripcion": "ABRIL"},
      {"nid": "05", "descripcion": "MAYO"},
      {"nid": "06", "descripcion": "JUNIO"},
      {"nid": "07", "descripcion": "JULIO"},
      {"nid": "08", "descripcion": "AGOSTO"},
      {"nid": "09", "descripcion": "SETIEMBRE"},
      {"nid": "10", "descripcion": "OCTUBRE"},
      {"nid": "11", "descripcion": "NOVIEMBRE"},
      {"nid": "12", "descripcion": "DICIEMBRE"},
    ]
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
                  })
        if(data == (new Date()).getFullYear())
          {
            this.setState({ anio: {value: cont2, label: data}})
          }
        })
    arrayMes.map((data) => {
        cont3++
        lista2.push({
         item: data.cont3,
          value: data.nid,
          label: data.descripcion,
                   })
        if(cont3 == (new Date()).getMonth() + 1)
        {
          this.setState({
          mes: {value: data.nid, label: data.descripcion}})
        }
    })
    this.setState({years: lista, meses: lista2})
    this.cargaView()
  }


  cargaView(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'autorizar/areas?dni=' + this.state.dni)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.ReplicationUnit,
                label: data.name,
            })
          });
          this.setState({areas: lista , area : {value : lista[0].value , label : lista[0].label}} )
          this.listar()
      }else{
        this.setState({areas: []})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }


  async listar(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'autorizar/listaPendientes?area=' + this.state.area.value + '&nid=' + this.state.id)
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { item: data.item,
              idpapeleta: data.idpapeleta,
              trabajador: data.nombre,
              estado: data.estado,
              tipopapeleta: data.descripcion,
              fechaini: Calendar.calendario9(data.FechaInicio),
              fechatermino: Calendar.calendario9(data.fechaFin),
              motivo: data.observacion,
              registro: Calendar.calendario9(data.registerdate),
              area: data.area,
              file: data.filesource
          })
        });
        this.setState({listado: lista, isLoading: false})
        this.listarAprobados()
      }else{
        this.setState({listado: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarAprobados(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'autorizar/listaAutorizados?area=' + this.state.area.value + '&nid=' + this.state.id +'&estado=' + this.state.tipo.value +'&mes=' + this.state.mes.value + '&anio=' + this.state.anio.label   )
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { item: data.item,
              idpapeleta: data.idpapeleta,
              trabajador: data.nombre,
              estado: data.estado,
              tipopapeleta: data.descripcion,
              fechaini: Calendar.calendario9(data.FechaInicio),
              fechatermino: Calendar.calendario9(data.fechaFin),
              motivo: data.observacion,
              registro: Calendar.calendario9(data.registerdate),
              area: data.area,
              file: data.filesource
          })
        });
        this.setState({listadoApro: lista, isLoading: false})
      }else{
        this.setState({listadoApro: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }


  async procesar(){
    this.setState({isLoading: true});
    let lista = this.node.selectionContext.selected;
    if(this.state.accion.value == 1){
      this.setState({url: 'autorizar/aprobarPapeleta'})
    }else{
      this.setState({url: 'autorizar/desaprobarPapeleta'})
    }
    setTimeout(
      function() {
      let cont = 1;  
      lista.map((data) => {
          for(var i = 0; i < this.state.listado.length; i++){
            if(this.state.listado[i].item == data){
              if(this.state.listado[i].file == null && this.state.listado[i].tipopapeleta == 'CITA MEDICA'){
                this.props.handleClick("tl", "warning", "La papeleta no cuenta con archivo adjunto!", "pe-7s-close")
                if(lista.length == cont++){
                  this.listar();
                }
              }else{
                let fromData = new FormData();
                let reg = this.state.listado[i];
                let pape = this.state.listado[i].idpapeleta;
                let fecha = this.state.listado[i].registro;
                fromData.append('pape', pape);
                fromData.append('nid', this.state.id);
                axios.defaults.headers.common['Authorization'] = this.state.token
                axios.post(`${sisperplus}` + this.state.url, fromData)
                .then((response) => {
                  var datos = response.data
                  if(datos.response == null){
                    if(this.state.accion.value == 1){
                      this.adjuntarArchivo(reg, cont++, fecha, lista, pape);
                    }else{
                      if(lista.length == cont++){
                        this.listar();
                      }
                      var noty = "La papeleta " + pape + " ha sido desaprobada!"
                      this.props.handleClick("tl", "info", noty, "pe-7s-smile")
                    }
                  }else{
                    this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
                    this.setState({isLoading: false})
                  }
                })
                .catch((error) =>{ 
                  if(auth0Client.isAuthenticated() === true){
                    auth0Client.signOut()
                    this.props.history.replace('/');
                  }else{
                    console.log(error);
                    this.setState({isLoading: false})
                  }
                });
              }
            }
          }
      });
      }
      .bind(this),
      3000
    );
  }

  adjuntarArchivo(reg, cont, fecha, lista, pape){
      let fromData = new FormData();
      fromData.append('pape', pape);
      fromData.append('archivo', 'procesado.pdf');
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${sisperplus}` + 'autorizar/updateNomArchivo', fromData)
      .then((response) => {
        var datos = response.data.response
        if(datos.archivo != ''){
          this.generarPdf(reg, cont, fecha, lista, pape, datos.archivo)
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al subir archivo!", "pe-7s-close")
          this.setState({isLoading: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  generarPdf(reg, cont, fecha, lista, pape, archivo){
    const blob = pdf(PdfDocument(this.state.nombre, this.state.dni, reg, archivo)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.uploadArchivo(cont, fecha, lista, pape, base64data, archivo)
          }
        }
        return result;
    });
  }

  uploadArchivo(cont, fecha, lista, pape, base64, archivo){
    const base = base64.replace("data:application/pdf;base64,", "")
    let mes = fecha.substring(3,5)
    let anio = fecha.substring(6,10)
    // let fromData = new FormData();
    // fromData.append('usuario', this.state.usuario);
    // fromData.append('archivo_base', base);
    // fromData.append('anio', anio);
    // fromData.append('mes', mes);
    // fromData.append('cnomArchivo', archivo);
    axios.defaults.headers.common['Authorization'] = this.state.token
    // axios.post(`${gestor}` + 'document/subirArchivoBase', fromData)
    axios.post(`${gestor}` + 'document/subirArchivoBase', {
      usuario: this.state.usuario,
      archivo_base: base,
      anio: anio,
      mes: mes,
      cnomArchivo: archivo
    })
    .then((response) => {
      if(response.data.result == null){
        if(lista.length == cont++){
          this.listar();
        }
        var noty = "La papeleta " + pape + " ha sido aprobada!"
        this.props.handleClick("tl", "info", noty, "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al guardar archivo!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  render() {
    const columns = [
      {dataField: "trabajador", text: "TRABAJADOR", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "estado", text: "ESTADO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipopapeleta", text: "TIPO DE PAPELETA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechaini", text: "FEC. INICIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechatermino", text: "FEC. TERMINO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "motivo", text: "MOTIVO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "registro", text: "FEC. REGISTRO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "area", text: "ÁREA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const columnsA = [
      {dataField: "trabajador", text: "TRABAJADOR", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "estado", text: "ESTADO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipopapeleta", text: "TIPO DE PAPELETA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechaini", text: "FEC. INICIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechatermino", text: "FEC. TERMINO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "motivo", text: "MOTIVO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "registro", text: "FEC. REGISTRO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "area", text: "ÁREA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.listado.length
    };
    const options2 = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.listado.length
    };
    const columns2 = [{
      dataField: 'CheckDate',
      headerAlign: (column, colIndex) => 'center',
      text: 'Fecha'
    }, {
      dataField: 'descripcion',
      headerAlign: (column, colIndex) => 'center',
      text: 'Observación'
    }];
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
        {/* <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div> */}
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              ref={ n => this.node = n }
              data={ this.state.listado }
              columns={ columns }
              selectRow={ selectRow }
              tabIndexCell
              noDataIndication="No existen registros para mostrar"
              // cellEdit={ cellEditFactory({ mode: 'click' }) }
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const contentTable2 = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
        {/* <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div> */}
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              ref={ n => this.node = n }
              data={ this.state.listadoApro }
              columns={ columnsA }
              tabIndexCell
              noDataIndication="No existen registros para mostrar"
              // cellEdit={ cellEditFactory({ mode: 'click' }) }
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const { area, accion } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <Card
                title="Procesar Papeletas del Personal"
                content={
                  <div style={{marginBottom: -20}}>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>ÁREAS DE APLICACIÓN</ControlLabel>
                            <Select value={area} onChange={this.handleArea} options={this.state.areas}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>ACCIÓN A REALIZAR</ControlLabel>
                            <Select value={accion} onChange= {this.handleAccion} options={this.state.acciones} />
                        </div>
                    </div>
                   <Button style={{width: 150, marginLeft: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.submit() }}>Procesar</Button>
                  </div>}
              />
            </Col>
          </Row>

          {/* <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Papeletas Pendientes</h4>
              <h4 className="category" style={{fontSize: 15, marginTop: -10}}>Para autorizar o desautorizar las papeletas deberás seleccionar las opciones y luego pulsar el boton Procesar para iniciar el proceso.</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.isLoading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row> */}
           <Row>
            <Col md={12}>
              <Tabs style={{marginTop: 30}}>
                <TabList>
                  <Tab>PAPELETAS PENDIENTES</Tab>
                  <Tab>PAPELETAS AUTORIZADAS Y DESAUTORIZADAS</Tab>
                </TabList>
                <TabPanel>
                    <div className="content" style={{marginTop: 20}}>
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Papeletas Pendientes</h4>
                    <h4 className="category" style={{fontSize: 15, marginTop: -10}}>Para autorizar o desautorizar las papeletas deberás seleccionar las opciones y luego pulsar el boton Procesar para iniciar el proceso.</h4>
                    {this.state.isLoading ? (
                            <ClipLoader
                              css={override}
                              sizeUnit={"px"}
                              size={50}
                              color={'#123abc'}
                              loading={this.state.isLoading}
                            />
                          ) : (
                            <PaginationProvider
                              pagination={
                                paginationFactory(options)
                              }
                            >
                              { contentTable }
                            </PaginationProvider>
                      )}
                    </div>
                </TabPanel>
                <TabPanel>
                <div className="content" style={{marginTop: 20}}>
                        <h4 className="title" style={{fontWeight: 200}}>Lista de Papeletas {this.state.tipo.value=='A' ? 'Autorizadas' : 'Desautorizadas'}</h4>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>PERIODO</ControlLabel>
                            <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>MES</ControlLabel>
                            <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses}/>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>TIPO</ControlLabel>
                            <Select value={this.state.tipo} onChange={this.handleTipo} options={this.state.tipos}/>
                        </div>
                        {this.state.isLoading ? (
                                <ClipLoader
                                  css={override}
                                  sizeUnit={"px"}
                                  size={50}
                                  color={'#123abc'}
                                  loading={this.state.isLoading}
                                />
                              ) : (
                                <PaginationProvider
                                  pagination={
                                    paginationFactory(options2)
                                  }
                                >
                                  { contentTable2 }
                                </PaginationProvider>
                          )}
                    </div>
                </TabPanel>

              </Tabs>
            </Col>
         </Row>

        </Grid>
      </div>
    );
  }
}

export default Autorizar;