import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import Calendar from "components/Calendar/Calendar.jsx";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import InputFiles from 'react-input-files';
import axios from 'axios';
import { sisperplus} from 'store/Apis'
import Select from 'react-select';

import 'react-confirm-alert/src/react-confirm-alert.css';



const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class EditarBoletin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'boletines',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      descripcion:'--',
      ctxt_imagen:'',
      ctxt_ruta: '',
      fecha: '',
      nid:'',
      movimientos: [],
      boletin: [],
      archivo:"",
      file: [],
      isLoading: false,
      ctxt_enlace:"",
      referencia: "",
      referencias: [{value: 1 , label : "SI"},{value: 0 , label : "NO"} ],
   
    }
    this.handleDescripcion = this.handleDescripcion.bind(this);
    this.handleFecha = this.handleFecha.bind(this);
   
}
handleReferencia = referencia =>{
  this.setState(
  {referencia},
  );
};
handleDescripcion(event) {this.setState({descripcion: event.target.value});}
handleFecha(event) {this.setState({fecha: event.target.value});}
 
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
              this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(JSON.parse(localStorage.getItem("jurisdiccional")).boletin[0].data !== null){
      console.log(JSON.parse(localStorage.getItem('jurisdiccional')).boletin[0].data)
        this.setState({
            nid: JSON.parse(localStorage.getItem('jurisdiccional')).boletin[0].data.nid,
            fecha: Calendar.calendario11(JSON.parse(localStorage.getItem('jurisdiccional')).boletin[0].data.ctxt_fecha),
            ctxt_enlace: JSON.parse(localStorage.getItem('jurisdiccional')).boletin[0].data.ctxt_enlace,
            archivo:JSON.parse(localStorage.getItem('jurisdiccional')).boletin[0].data.cnom_archivo,
            referencia: {value : JSON.parse(localStorage.getItem('jurisdiccional')).boletin[0].data.referencia,label: JSON.parse(localStorage.getItem('jurisdiccional')).boletin[0].data.flg_referencia },
            enviado: JSON.parse(localStorage.getItem('jurisdiccional')).boletin[0].data.flg_enviado,
        });
    }else{
      this.props.history.push('/boletines');
    }
  }

  async validar(){
    let estado = "";
    let param1 = "";
   if(this.state.descripcion != "" && this.state.archivo!= "" ){
     if(this.state.file.length != 0){
          estado = "0";
          param1 = this.state.archivo;
          this.update(estado, param1)
      }else{
          estado = "3";
          this.update(estado, this.state.archivo)
      } 
    }else{
     this.props.handleClick("tl", "error", "Debe completar todos los campos!", "pe-7s-close")
    }
  }

  async update(estado, param1){  
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', this.state.nid);
    fromData.append('descripcion', this.state.descripcion);
    fromData.append('fecha', this.state.fecha);
    fromData.append('file', this.state.file);
    fromData.append('archivo', param1);
    fromData.append('estado', estado);
    fromData.append('referencia', this.state.referencia.value);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'boletin/editarBoletin', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Boletin actualizado correctamente!", "pe-7s-close")      
          this.limpiar();
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async regresar(){
    this.props.history.push('/boletines');
  }

  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], archivo: files[0].name});
    }
  }

  async limpiar(){
    this.setState({archivo: "", file: ""})
  }
 
  render() {
 
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>EDITAR : <a style={{fontWeight: "bold", color: "black"}}></a></h4>
                  {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                       <div className="row">                          
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA</ControlLabel>
                                <FormControl type="date" value={this.state.fecha} onChange= {this.handleFecha}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>¿CONTIENE INFORMACIÓN DEL TC?</ControlLabel>
                              <Select value={this.state.referencia} onChange={this.handleReferencia} options={this.state.referencias}/>
                            </div>
                       </div>
                        {/* <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>DESCRIPCIÓN</ControlLabel>
                                <FormControl componentClass="textarea" style={{  height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.descripcion} onChange= {this.handleDescripcion} />
                            </div>
                        </div> */}

                        <div className="row">
                            
                            <div className="col-md-12">
                                <ControlLabel>ARCHIVO ADJUNTO</ControlLabel>
                                <Alert bsStyle="warning">
                                    <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo}</span>
                                </Alert>
                                <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                                    <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                                </Button>
                                {this.state.archivo != "" ? (
                                  <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar Archivo</Button>
                                ) : ( 
                                  null
                                )}
                            </div>
                        </div>
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Actualización</Button>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Actualizar Registro</Button> 
                        </div>
                  </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarBoletin;