import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import Button from "components/CustomButton/CustomButton.jsx";
import { Card } from "components/Card/Card.jsx";
import { validator } from 'store/Apis';

import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { tesoreria } from 'store/Apis'

import { pdf } from "@react-pdf/renderer";
import { reporteCheques } from "./ReporteCheques";
import { reporteComprobantes } from "./ReporteComprobantes";
import { NumeroALetras } from "views/efectivo/conversor";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class GenerarReporte extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'comprobantes',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      isLoading: true,
      base: "",
      marcador: 0,
      movimiento: '',
      tiporeporte: '',
      comprobante: '',
      nidsupersiguiente: '',
      nidsiguiente: '',
      nidanterior: '', 
      nidsuperanterior: '', 
      origen: ''
    }

  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(localStorage.getItem("movimientocomp") !== null){
        this.setState({movimiento: JSON.parse(localStorage.getItem('movimientocomp')).nidmovimiento,
                       tiporeporte: JSON.parse(localStorage.getItem('movimientocomp')).tiporeporte,
                       origen: JSON.parse(localStorage.getItem('movimientocomp')).origen});
        if(this.state.tiporeporte == 'CHEQUE DE PAGO'){
            this.listarCheque();
        }else{
            this.listarComprobante(JSON.parse(localStorage.getItem('movimientocomp')).nidmovimiento);
        }
    }else{
      this.props.history.push('/comprobantes');
    }
  }

  async superadelante(){
    if(this.state.nidsupersiguiente != null){
      let data2 = {
        "nidmovimiento": this.state.nidsupersiguiente,
        "tiporeporte": this.state.tiporeporte,
        "origen": this.state.origen
      }
      localStorage.setItem('movimientocomp', JSON.stringify(data2))
      this.listarComprobante(this.state.nidsupersiguiente)
    }
  }

  async adelante(){
    if(this.state.nidsiguiente != null){
      let data2 = {
        "nidmovimiento": this.state.nidsiguiente,
        "tiporeporte": this.state.tiporeporte,
        "origen": this.state.origen
      }
      localStorage.setItem('movimientocomp', JSON.stringify(data2))
      this.listarComprobante(this.state.nidsiguiente)
    }
  }

  async atras(){
    if(this.state.nidanterior != null){
      let data2 = {
        "nidmovimiento": this.state.nidanterior,
        "tiporeporte": this.state.tiporeporte,
        "origen": this.state.origen
      }
      localStorage.setItem('movimientocomp', JSON.stringify(data2))
      this.listarComprobante(this.state.nidanterior)
    }
  }

  async superatras(){
    if(this.state.nidsuperanterior != null){
      let data2 = {
        "nidmovimiento": this.state.nidsuperanterior,
        "tiporeporte": this.state.tiporeporte,
        "origen": this.state.origen
      }
      localStorage.setItem('movimientocomp', JSON.stringify(data2))
      this.listarComprobante(this.state.nidsuperanterior)
    }
  }

  async listarComprobante(param) {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'comprobantes/listMovimientoxNid?nidmovimiento=' + param + '&tipo=0')
    .then((res) => {
      let comprobante = [];
      let presupuestal = [];
      let identificador = [];
      let estadistica = [];
      let deducciones = [];
      let codificaciones = [];
      let patrimonial = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.listMovimientoxNid.map((data) => {
            comprobante.push({ 
                nidsupersiguiente: data.supersiguiente,
                nidsiguiente: data.nidsiguiente,
                nidanterior: data.nidanterior, 
                nidsuperanterior: data.superanterior,  
                numero: data.cnum_numero, 
                numerocaja: data.ctxt_cheque_carta,
                documento: data.cnum_documento,
                nombre: data.ctxt_nombre,
                concepto: data.ctxt_concepto,
                totalgastos: data.cnum_total_gastos != null ? 'S/.    ' + data.cnum_total_gastos.toFixed(2) : 'S/. 0.00', 
                totalretenciones: data.cnum_total_retenciones != null ? 'S/.    ' + data.cnum_total_retenciones.toFixed(2) : 'S/. 0.00', 
                liquido: data.cnum_liquido != null ? 'S/.    ' + data.cnum_liquido.toFixed(2) : 'S/. 0.00', 
                fecha: data.dfec_ingreso,
                usuariox: data.usuario_registro,
                orden: data.cnum_orden,
                siaf: data.cnum_siaf,
            })
        })
        lista2.listPresupuestal.map((data) => {
            presupuestal.push({ 
                cuentadebe2: data.ctxt_cuenta_debe != '0' ? data.ctxt_cuenta_debe : '94', 
                cuentahaber2: data.ctxt_cuenta_haber != '0' ? data.ctxt_cuenta_haber : '95', 
                importedebe2: 'S/.    ' + data.ctxt_importe_debe.toFixed(2),
                importehaber2: 'S/.    ' + data.ctxt_importe_haber.toFixed(2),
            })
        })
        lista2.listIdentificadores.map((data) => {
            identificador.push({ 
                nombre: data.ctxt_nombre,
                concepto: data.ctxt_concepto,
                documento: data.cnum_documento,
            })
        })
        lista2.listEstadistica.map((data) => {
            estadistica.push({ 
                partida: data.ctxt_partida,
                parcial: 'S/.    ' + data.ctxt_parcial.toFixed(2),
            })
        })
        if(lista2.listEstadistica.length < 14){
          var cont = 14 - lista2.listEstadistica.length
          for (var i = 0; i < cont; i++) {
            estadistica.push({ 
                partida: '.',
                parcial: '',
            })
          }
        }
        lista2.listDeducciones.map((data) => {
          deducciones.push({ 
              deduccion: data.ctxt_descripcion,
              parcial: 'S/.    ' + data.ctxt_importe.toFixed(2),
          })
        })
        if(lista2.listDeducciones.length < 15){
          var cont = 15 - lista2.listDeducciones.length
          for (var i = 0; i < cont; i++) {
            deducciones.push({ 
                deduccion: '.',
                parcial: '',
            })
          }
        }
        lista2.listCodificacion.map((data) => {
            codificaciones.push({ 
                actproy: data.ctxt_actproy,
                comp: data.ctxt_comp,
                fin: data.ctxt_fin,
                fn: data.ctxt_fn,
                meta: data.ctxt_meta,
                pf: data.ctxt_pf,
                prg: data.ctxt_prg,
                spf: data.ctxt_spf
            })
        })
        if(lista2.listCodificacion.length < 7){
          var cont = 7 - lista2.listCodificacion.length
          for (var i = 0; i < cont; i++) {
            codificaciones.push({ 
                actproy: '',
                comp: '',
                fin: '',
                fn: '.',
                meta: '',
                pf: '',
                prg: '',
                spf: ''
            })
          }
        }
        lista2.listPatrimonial.map((data) => {
            patrimonial.push({ 
                concepto: data.concepto,
                patrimonial: data.nid_patrimonial,
                cuentadebe: data.ctxt_cuenta_debe,
                importedebe: 'S/.    ' + data.ctxt_importe_debe.toFixed(2),
                cuentahaber: data.ctxt_cuenta_haber,
                importehaber: 'S/.    ' + data.ctxt_importe_haber.toFixed(2),
            })
        })
        if(lista2.listPatrimonial.length < 7){
          var cont = 7 - lista2.listPatrimonial.length
          for (var i = 0; i < cont; i++) {
            patrimonial.push({ 
                concepto: '',
                patrimonial: 0,
                cuentadebe: '.',
                importedebe: '',
                cuentahaber: '.',
                importehaber: '',
            })
          }
        }
        this.setState({                
          nidsupersiguiente: comprobante[0].nidsupersiguiente,
          nidsiguiente: comprobante[0].nidsiguiente,
          nidanterior: comprobante[0].nidanterior, 
          nidsuperanterior: comprobante[0].nidsuperanterior
        })
        let lista = {
            'comprobante' : comprobante,
            'presupuestal' : presupuestal,
            'identificador' : identificador,
            'estadistica' : estadistica,
            'deducciones' : deducciones,
            'codificaciones' : codificaciones,
            'patrimonial' : patrimonial
        };
        this.generarComprobante(lista);
      }else{
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarCheque() {
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${tesoreria}` + 'cheques/listChequexNid?nidcheque=' + this.state.movimiento)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            let liquido = data.ctxt_monto != null ? 'S/.    ' + data.ctxt_monto.toFixed(2) : 'S/. 0.00'; 
            let monto = "";
            if(liquido == 'S/. 0.00'){
              monto = liquido.replace('S/. ', '');
            }else{
              monto = liquido.replace('S/.    ', '');
            }
            let fecha = Calendar.calendario12(data.dfec_ingreso);
            let fecha2 = fecha.replace('-', ' ');
            fecha2 = fecha2.replace('-', ' ');
            lista.push(
              { 
                numero: data.cnum_numero, 
                nombre: data.ctxt_nombre,
                concepto: data.ctxt_concepto,
                liquido: monto,
                liquido2: NumeroALetras(monto) != "undefined SOLES " ? NumeroALetras(monto) : "CERO SOLES",
                fecha: fecha2,
                fecha2: 'LIMA, ' + fecha2
            })
          })
          this.generarCheque(lista);
        }else{
          this.setState({isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async generarCheque(movimientos){
    const blob = pdf(reporteCheques(movimientos)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data, marcador: 0, comprobante: movimientos[0].numero, isLoading: false});
          }
        }
        return result;
    }); 
  }

  async generarComprobante(movimientos){
    const blob = pdf(reporteComprobantes(movimientos)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data, marcador: 0, comprobante: movimientos.comprobante[0].numero, isLoading: false});
          }
        }
        return result;
    }); 
  }

  async volver(){
    if(this.state.origen == 'C'){
      this.props.history.push('/comprobantes');
    }else{
      this.props.history.push('/editarcomprobante');
    }
  }

  render() {
    const {tiporeporte, comprobante} = this.state
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <Card 
                        title="Reporte de Comprobantes de Pagos"
                        content={
                        <div>
                          <div className="row">
                            <div className="col-md-8">
                                <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>{tiporeporte + ': N° '+ comprobante}</h4>
                            </div>
                            <div className="col-md-4" style={{marginTop: 10}}>
                              <Button style={{width: 50, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.superadelante() }}>
                                <i className="fa fa-fast-forward" style={{fontSize: 18, paddingTop: 5}}></i>
                              </Button>
                              <Button style={{width: 50, marginRight: 10, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.adelante() }}>
                                <i className="fa fa-forward" style={{fontSize: 18, paddingTop: 5}}></i>
                              </Button>
                              <Button style={{width: 50, marginRight: 10, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.atras() }}>
                                <i className="fa fa-backward" style={{fontSize: 18, paddingRight: 10, paddingTop: 5}}></i>
                              </Button>   
                              <Button style={{width: 50, marginRight: 10, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.superatras() }}>
                                <i className="fa fa-fast-backward" style={{fontSize: 18, paddingRight: 10, paddingTop: 5}}></i>
                              </Button>              
                            </div>
                          </div>
                          {this.state.marcador == 0 ? (
                              <iframe src={this.state.base} style={{height: 1195, width: "100%"}}></iframe>
                            ) : (
                              <iframe src={this.state.base} style={{height: 1195, width: "100%"}}></iframe>
                            )
                          }
                        </div>
                        }
                      />
                 )}
            </Col>
            <Button style={{width: 200, marginRight: 15}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.volver() }}>Volver a Anterior</Button>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default GenerarReporte;