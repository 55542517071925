import React, { Component } from "react";
import {FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import moment from 'moment'
import Select from 'react-select';
import { Card } from "components/Card/Card.jsx";
import { validator, sisperplus} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
//import 'react-accessible-accordion/dist/fancy-example.css';
//import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class DatosGenerales extends Component {

  constructor(props){
    super(props);
    this.state = {      
      path: 'miLegajo',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,          
      movimientos: [],
      isLoading: true,   
      id: JSON.parse(localStorage.getItem('legajo')).legajo[0].idpersona,
      nombre: JSON.parse(localStorage.getItem('legajo')).legajo[1].nombre,
      codcategoria: JSON.parse(localStorage.getItem('legajo')).legajo[2].idcategoria,
      descategoria: JSON.parse(localStorage.getItem('legajo')).legajo[3].descategoria,
      enlace: JSON.parse(localStorage.getItem('legajo')).legajo[4].enlace,
      idlegajo: JSON.parse(localStorage.getItem('legajo')).legajo[5].idlegajo,
      iddatopersonal: "",
      tipodocumentos: [], numdocumento: "",
      nombres: "", apepaterno: "", apematerno: "",
      estadocivil: [], genero: [],
      fechanacimiento: "",
      dept_nac: [], prov_nac: [], dist_nac: [],
      direccion: "", calle: "", numdomicilio: "", urbanizacion: "",
      dept_dom: [], prov_dom: [], dist_dom: [],
      correo: "", telefono: "", celular: "",
      //colegiopro: [], numcolegiatura: "", fechacolegiatura: moment(new Date()).format('YYYY-MM-DD'),
      selectedTipoDocumento: "", selectedEstadoCivil: "", selectedGenero: "",
      selectedDeptNac: "", selectedProvNac: "", selectedDistNac: "",
      selectedDeptDom: "", selectedProvDom: "", selectedDistDom: "",
      //selectedColPro: "",
    }

    this.handleNumDocumento = this.handleNumDocumento.bind(this);
    this.handleNombres = this.handleNombres.bind(this);
    this.handleApePaterno = this.handleApePaterno.bind(this);
    this.handleApeMaterno = this.handleApeMaterno.bind(this);
    this.handleFecNacimiento = this.handleFecNacimiento.bind(this);
    this.handleDireccion = this.handleDireccion.bind(this);
    this.handleCalle = this.handleCalle.bind(this);
    this.handleNumDomicilio = this.handleNumDomicilio.bind(this);
    this.handleUrbanizacion = this.handleUrbanizacion.bind(this);
    this.handleCorreo = this.handleCorreo.bind(this);
    this.handleTelefono = this.handleTelefono.bind(this);
    this.handleCelular = this.handleCelular.bind(this);
    //this.handleNumColegiatura = this.handleNumColegiatura.bind(this);
    //this.handleFecColegiatura = this.handleFecColegiatura.bind(this);
  }

  changeTipoDocumento = selectedTipoDocumento =>{ this.setState({selectedTipoDocumento});};
  changeEstadoCivil   = selectedEstadoCivil =>{ this.setState({selectedEstadoCivil});};
  changeGenero        = selectedGenero =>{ this.setState({selectedGenero});};
  changeDeptNac       = selectedDeptNac =>{ this.setState({selectedDeptNac}, () => this.listarProvincias());};
  changeProvNac       = selectedProvNac =>{ this.setState({selectedProvNac}, () => this.listarDistritos());};
  changeDistNac       = selectedDistNac =>{ this.setState({selectedDistNac});};
  changeDeptDom       = selectedDeptDom =>{ this.setState({selectedDeptDom}, () => this.listarProvinciasDom());};
  changeProvDom       = selectedProvDom =>{ this.setState({selectedProvDom}, () => this.listarDistritosDom());};
  changeDistDom       = selectedDistDom =>{ this.setState({selectedDistDom});};
  //changeColPro        = selectedColPro =>{ this.setState({selectedColPro});};

  handleNumDocumento(event) { 
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({numdocumento: event.target.value})
    }
    //this.setState({numdocumento: event.target.value});
  };
  handleNombres(event) { this.setState({nombres: event.target.value});};
  handleApePaterno(event) { this.setState({apepaterno: event.target.value});};
  handleApeMaterno(event) { this.setState({apematerno: event.target.value});};
  handleFecNacimiento(event) { this.setState({fechanacimiento: event.target.value});};
  handleDireccion(event) { this.setState({direccion: event.target.value});};
  handleCalle(event) { this.setState({calle: event.target.value});};
  handleNumDomicilio(event) {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({numdomicilio: event.target.value})
    } 
    //this.setState({numdomicilio: event.target.value});
  };
  handleUrbanizacion(event) { this.setState({urbanizacion: event.target.value});};
  handleCorreo(event) { this.setState({correo: event.target.value});};
  handleTelefono(event) { 
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({telefono: event.target.value})
    }
    //this.setState({telefono: event.target.value});
  };
  handleCelular(event) { 
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({celular: event.target.value})
    }
    //this.setState({celular: event.target.value});
  };
  //handleNumColegiatura(event) { this.setState({numcolegiatura: event.target.value});};
  //handleFecColegiatura(event) { this.setState({fechacolegiatura: event.target.value});};

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                console.log("codigo: " + this.state.codcategoria);
                this.obtenerDatos();
                this.listarTipoDocumentos();   
                this.listarEstadoCivil();
                this.listarDepartamentos();
                this.listarDepartamentosDom();
                //this.listarColegioProfesional();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async obtenerDatos() {       
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'legajo/obtenerDatosGeneralesLegajo?idlegajo=' + this.state.idlegajo)        
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2.length != 0){
          lista2.map((data) => {
            this.setState({
              iddatopersonal: data.nid_datopersonal,
              idlegajo: data.id_legajo,
              selectedTipoDocumento: {value: data.id_tipodocumento, label: data.destipodocumento},
              numdocumento: data.numero_documento,
              apepaterno: data.apellido_paterno,
              apematerno: data.apellido_materno,
              nombres: data.nombres,
              selectedEstadoCivil: {value: data.id_estadocivil, label: data.desestadocivil},
              fechanacimiento: Calendar.calendario10(data.fecha_nacimiento),
              selectedDeptNac: {value: data.dept_nac, label: data.nomdept_nac},
              selectedProvNac: {value: data.prov_nac, label: data.nomprov_nac},
              selectedDistNac: {value: data.dist_nac, label: data.nomdist_nac},
              direccion: data.direccion_domicilio,
              calle: data.calle_dom,
              numdomicilio: data.numero_dom,
              urbanizacion: data.urbanizacion_domicilio,
              selectedDeptDom: {value: data.dept_dom, label: data.nomdept_dom},
              selectedProvDom: {value: data.prov_dom, label: data.nomprov_dom},
              selectedDistDom: {value: data.dist_dom, label: data.nomdist_dom},
              correo: data.correo,
              telefono: data.telefono_fijo,
              celular: data.telefono_celular
            })
          })   
          this.listarProvincias();
          this.listarDistritos();
          this.listarProvinciasDom();
          this.listarDistritosDom();                
        } else{
          this.state.isLoading = false
        }      
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarTipoDocumentos() {    
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'legajo/listTipoDocumentos?filtro=1')        
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                value: data.id_tipodocumento,
                label: data.descripcion,     
            })
          })

          this.setState({tipodocumentos: lista, selectedTipoDocumento: {value: lista[0].value, label: lista[0].label}})
        }else{
          this.setState({tipodocumentos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarEstadoCivil() {    
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'legajo/listEstadoCivil')        
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                value: data.id_estadocivil,
                label: data.descripcion,     
            })
          })
          this.setState({estadocivil: lista, selectedEstadoCivil: {value: lista[0].value, label: lista[0].label}})
        }else{
          this.setState({estadocivil: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarDepartamentos() {    
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'legajo/listDepartamentos')        
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                value: data.id_departamento,
                label: data.nomdepartamento,     
            })
          })
          this.setState({dept_nac: lista})          
        }else{
          this.setState({dept_nac: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarProvincias() {    
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'legajo/listProvincias?iddepartamento=' + this.state.selectedDeptNac.value)        
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                value: data.id_provincia,
                label: data.nomprovincia,     
            })
          })
          //this.setState({prov_nac: lista, selectedProvNac: {value: lista[0].value, label: lista[0].label}})
          this.setState({prov_nac: lista})
        }else{
          this.setState({prov_nac: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarDistritos() {    
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'legajo/listDistritos?idprovincia=' + this.state.selectedDeptNac.value+this.state.selectedProvNac.value)        
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                value: data.id_distrito,
                label: data.nomdistrito,     
            })
          })
          //this.setState({dist_nac: lista, selectedDistNac: {value: lista[0].value, label: lista[0].label}})
          this.setState({dist_nac: lista})
        }else{
          this.setState({dist_nac: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarDepartamentosDom() {    
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'legajo/listDepartamentos')        
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                value: data.id_departamento,
                label: data.nomdepartamento,     
            })
          })

          this.setState({dept_dom: lista})
        }else{
          this.setState({dept_dom: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarProvinciasDom() {    
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'legajo/listProvincias?iddepartamento=' + this.state.selectedDeptDom.value)        
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                value: data.id_provincia,
                label: data.nomprovincia,     
            })
          })
          //this.setState({prov_dom: lista, selectedProvDom: {value: lista[0].value, label: lista[0].label}})
          this.setState({prov_dom: lista})
        }else{
          this.setState({prov_dom: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarDistritosDom() {    
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'legajo/listDistritos?idprovincia=' + this.state.selectedDeptDom.value+this.state.selectedProvDom.value)        
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                value: data.id_distrito,
                label: data.nomdistrito,     
            })
          })
          //this.setState({dist_dom: lista, selectedDistDom: {value: lista[0].value, label: lista[0].label}})
          this.setState({dist_dom: lista, isLoading: false})
        }else{
          this.setState({dist_dom: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarColegioProfesional() {    
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'legajo/listColegioProfesional')        
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                value: data.id_colegioprofesional,
                label: data.descripcion,     
            })
          })

          this.setState({colegiopro: lista})
        }else{
          this.setState({colegiopro: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async regresar(){
    this.props.history.push('/fichaPersonal');
  }

  validaEmail (correo) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(correo);
  }

  validaCelular (celular) {
    var re = /^9[\d]{8}$/;
    return re.test(celular);
  }

  async validar(){
    if(this.state.numdocumento != '' && this.state.apepaterno != '' && this.state.apematerno != '' && 
      this.state.nombre != '' && this.state.fechanacimiento != '' && this.state.dept_nac != '' &&
      this.state.prov_nac != '' && this.state.dist_nac != '' && this.state.direccion != '' && 
      this.state.dept_dom != '' && this.state.prov_dom != '' && this.state.dist_dom != '' &&
      this.state.correo != '' && this.state.celular != '') {
        
        if(this.state.numdocumento.length == 8){
          
          if(this.validaEmail(this.state.correo) == true){   

            if(this.validaCelular(this.state.celular) == true){

              if(this.state.iddatopersonal != ''){
                this.editar();
              }else{
                this.agregar();
              }
              
            }else{
              this.props.handleClick("tl", "warning", "El celular ingresado no es válido!", "pe-7s-close");
            }

          }else{
            this.props.handleClick("tl", "warning", "El correo ingresado no es válido!", "pe-7s-close");
          }

        }else{
          this.props.handleClick("tl", "warning", "El DNI ingresado no es válido!", "pe-7s-close");
        }            
     
    }else{
      this.props.handleClick("tl", "warning", "Debe completar los campos solicitados!", "pe-7s-info");      
    }

  }

  async agregar(){
    this.setState({isLoading: true})
    let formdata = new FormData();
    formdata.append("idlegajo", this.state.idlegajo);
    formdata.append("idpersona", this.state.id);
    formdata.append("idtipodocumento", this.state.selectedTipoDocumento.value);
    formdata.append("numerodocumento", this.state.numdocumento);
    formdata.append("apepaterno", this.state.apepaterno.toUpperCase());
    formdata.append("apematerno", this.state.apematerno.toUpperCase());
    formdata.append("nombres", this.state.nombres.toUpperCase());
    formdata.append("idestadocivil", this.state.selectedEstadoCivil.value);
    formdata.append("fechanacimiento", this.state.fechanacimiento);
    formdata.append("deptnac", this.state.selectedDeptNac.value);
    formdata.append("provnac", this.state.selectedProvNac.value);
    formdata.append("distnac", this.state.selectedDistNac.value);
    formdata.append("direccion", this.state.direccion.toUpperCase());
    formdata.append("calle", this.state.calle.toUpperCase());
    formdata.append("numerodom", this.state.numdomicilio);
    formdata.append("urbanizacion", this.state.urbanizacion.toUpperCase());
    formdata.append("deptdom", this.state.selectedDeptDom.value);
    formdata.append("provdom", this.state.selectedProvDom.value);
    formdata.append("distdom", this.state.selectedDistDom.value);
    formdata.append("correo", this.state.correo.toUpperCase());
    formdata.append("telefono", this.state.telefono);
    formdata.append("celular", this.state.celular);
    formdata.append("usuario", this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'legajo/insertDatosPersonalesLegajo', formdata)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Datos registrados correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async editar(){
    this.setState({isLoading: true})
    let formdata = new FormData();
    formdata.append("nid", this.state.iddatopersonal);
    formdata.append("idlegajo", this.state.idlegajo);
    formdata.append("idpersona", this.state.id);
    formdata.append("idtipodocumento", this.state.selectedTipoDocumento.value);
    formdata.append("numerodocumento", this.state.numdocumento);
    formdata.append("apepaterno", this.state.apepaterno.toUpperCase());
    formdata.append("apematerno", this.state.apematerno.toUpperCase());
    formdata.append("nombres", this.state.nombres.toUpperCase());
    formdata.append("idestadocivil", this.state.selectedEstadoCivil.value);
    formdata.append("fechanacimiento", this.state.fechanacimiento);
    formdata.append("deptnac", this.state.selectedDeptNac.value);
    formdata.append("provnac", this.state.selectedProvNac.value);
    formdata.append("distnac", this.state.selectedDistNac.value);
    formdata.append("direccion", this.state.direccion.toUpperCase());
    formdata.append("calle", this.state.calle.toUpperCase());
    formdata.append("numerodom", this.state.numdomicilio);
    formdata.append("urbanizacion", this.state.urbanizacion.toUpperCase());
    formdata.append("deptdom", this.state.selectedDeptDom.value);
    formdata.append("provdom", this.state.selectedProvDom.value);
    formdata.append("distdom", this.state.selectedDistDom.value);
    formdata.append("correo", this.state.correo.toUpperCase());
    formdata.append("telefono", this.state.telefono);
    formdata.append("celular", this.state.celular);
    formdata.append("usuario", this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'legajo/updateDatosPersonalesLegajo', formdata)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Datos actualizados correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  render(){
      const {selectedTipoDocumento,selectedEstadoCivil,selectedColPro,
          selectedDeptDom,selectedProvDom,selectedDistDom,selectedDeptNac,selectedProvNac,selectedDistNac,
          selectedGenero} = this.state
      return(
          <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 5, paddingBottom: 5}}>                
                <h4 className="title" style={{fontWeight: 600, marginTop: 18, marginBottom: 25}}>DATOS GENERALES</h4>                             
                  
                  {this.state.isLoading ? (
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading}
                  />
                ) : (
                  <div>

                    <p style={{borderBottom: "solid", borderColor: "#1E75C6", borderWidth: 3, fontSize: 18}}><span>Datos personales</span></p> 
                    <div className="row"> 
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>TIPO DE DOCUMENTO<a style={{color:"red"}}> *</a></ControlLabel>
                            <Select value={selectedTipoDocumento} onChange={this.changeTipoDocumento} options={this.state.tipodocumentos} />
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>NÚMERO DE DOCUMENTO<a style={{color:"red"}}> *</a></ControlLabel>
                            <FormControl type="text" value={this.state.numdocumento} onChange= {this.handleNumDocumento} maxLength={8} />
                        </div>                                    
                    </div>                   
                    <div className="row">                                    
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>APELLIDO PATERNO<a style={{color:"red"}}> *</a></ControlLabel>
                          <FormControl type="text" value={this.state.apepaterno} onChange= {this.handleApePaterno} />
                      </div>
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>APELLIDO MATERNO<a style={{color:"red"}}> *</a></ControlLabel>
                          <FormControl type="text" value={this.state.apematerno} onChange= {this.handleApeMaterno} />
                      </div>
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>NOMBRES<a style={{color:"red"}}> *</a></ControlLabel>
                          <FormControl type="text" value={this.state.nombres} onChange= {this.handleNombres} />
                      </div>
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>ESTADO CIVIL<a style={{color:"red"}}> *</a></ControlLabel>
                          <Select value={selectedEstadoCivil} onChange={this.changeEstadoCivil} options={this.state.estadocivil} />
                      </div>  
                    </div>                               
                    <div className="row">                                        
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>FECHA DE NACIMIENTO<a style={{color:"red"}}> *</a></ControlLabel>
                          <FormControl type="date" value={this.state.fechanacimiento} onChange= {this.handleFecNacimiento} />
                      </div>
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>DEPARTAMENTO<a style={{color:"red"}}> *</a></ControlLabel>
                          <Select value={selectedDeptNac} onChange={this.changeDeptNac} options={this.state.dept_nac} />
                      </div>
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>PROVINCIA<a style={{color:"red"}}> *</a></ControlLabel>
                          <Select value={selectedProvNac} onChange={this.changeProvNac} options={this.state.prov_nac} />
                      </div>
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>DISTRITO<a style={{color:"red"}}> *</a></ControlLabel>
                          <Select value={selectedDistNac} onChange={this.changeDistNac} options={this.state.dist_nac} />
                      </div>
                    </div>         
                    <p style={{borderBottom: "solid", borderColor: "#1E75C6", borderWidth: 3, fontSize: 18}}><span>Datos de domicilio</span></p> 
                    <div className="row">
                      <div className="col-md-6" style={{marginBottom: 18}}>
                          <ControlLabel>DIRECCIÓN<a style={{color:"red"}}> *</a></ControlLabel>
                          <FormControl type="text" value={this.state.direccion} onChange= {this.handleDireccion} />
                      </div>   
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>CALLE <a>(OPCIONAL)</a></ControlLabel>
                          <FormControl type="text" value={this.state.calle} onChange= {this.handleCalle} />
                      </div>  
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>NÚMERO <a>(OPCIONAL)</a></ControlLabel>
                          <FormControl type="text" value={this.state.numdomicilio} onChange= {this.handleNumDomicilio} />
                      </div>                                                                   
                  </div>                    
                  <div className="row">   
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>URBANIZACIÓN <a>(OPCIONAL)</a></ControlLabel>
                          <FormControl type="text" value={this.state.urbanizacion} onChange= {this.handleUrbanizacion} />
                      </div>                                 
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>DEPARTAMENTO<a style={{color:"red"}}> *</a></ControlLabel>
                          <Select value={selectedDeptDom} onChange={this.changeDeptDom} options={this.state.dept_dom} />
                      </div>
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>PROVINCIA<a style={{color:"red"}}> *</a></ControlLabel>
                          <Select value={selectedProvDom} onChange={this.changeProvDom} options={this.state.prov_dom} />
                      </div>
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>DISTRITO<a style={{color:"red"}}> *</a></ControlLabel>
                          <Select value={selectedDistDom} onChange={this.changeDistDom} options={this.state.dist_dom} />
                      </div>                                    
                  </div>
                  <p style={{borderBottom: "solid", borderColor: "#1E75C6", borderWidth: 3, fontSize: 18}}><span>Datos de contacto</span></p> 
                  <div className="row">
                      <div className="col-md-4" style={{marginBottom: 18}}>
                          <ControlLabel>CORREO ELECTRÓNICO<a style={{color:"red"}}> *</a></ControlLabel>
                          <FormControl type="text" value={this.state.correo} onChange= {this.handleCorreo} />
                      </div>
                      <div className="col-md-4" style={{marginBottom: 18}}>
                          <ControlLabel>TELÉFONO FIJO <a>(OPCIONAL)</a></ControlLabel>
                          <FormControl type="text" value={this.state.telefono} onChange= {this.handleTelefono} maxLength={7} />
                      </div>
                      <div className="col-md-4" style={{marginBottom: 18}}>
                          <ControlLabel>TELÉFONO CELULAR<a style={{color:"red"}}> *</a></ControlLabel>
                          <FormControl type="text" value={this.state.celular} onChange= {this.handleCelular} maxLength={9} />
                      </div>
                  </div>                  
                  <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Registro</Button>                  
                  <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>{this.state.iddatopersonal !== '' ? 'Editar Registro' : 'Guardar Registro'}</Button>
                  
                  </div>
                )}                 
                  
              </div>
                                
              </Col>
            </Row>
          </Grid>
          </div>
      )
  }


}

export default DatosGenerales;