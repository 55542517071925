import React, { Component } from "react";
import { ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, fechasBusqueda,paramRRHH} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import Swal from 'sweetalert2'
import Calendar from "components/Calendar/Calendar.jsx";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ListadoVacaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: 'misVacaciones',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],    
      persona :"",
      nombre:"",
      isLoading: true,
      fechaI:"",
      diasRestantes:"",
      

    }

    this.GetActionFormat = this.GetActionFormat.bind(this);
    this.estado = this.estado.bind(this);
    }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null &&
     localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              //this.validarLocal();
              this.detalleVacacionesTrabajador()
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }


  async validarLocal(){ 
 
    if(JSON.parse(localStorage.getItem("recursoshumanos")).vacaciones[0].data != null){
          this.setState({
                  persona: JSON.parse(localStorage.getItem('recursoshumanos')).vacaciones[0].data.person,
                  dni: JSON.parse(localStorage.getItem('recursoshumanos')).vacaciones[0].data.dni,
                  nombre: JSON.parse(localStorage.getItem('recursoshumanos')).vacaciones[0].data.nombre,      
                });
          this.detalleVacacionesTrabajador();  
      }else{}
   }
  

 async detalleVacacionesTrabajador() {
    this.setState({isLoading:true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'vacaciones/detalleVacacionesTrabajador?persona=' + this.state.id)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                dni : data.DocumentNumber,
                anio: data.ControlYear,
                diasTotal: data.Days,
                diasConsumidos : data.ConsumedDays,
                diasRestante: data.restante,
                item: data.item,
                person:data.Person,
                line :data.line,
                nombre:data.Name, 
                ingreso : Calendar.calendario10(data.EntryDate),
                fechaV : Calendar.calendario10(data.DateBeginProvision),
                corresponde : data.corresponde,
                dias: data.dias + " dias"

            })        
          })
         
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        
        this.setState({isLoading: false})
      }
    });
  }
 
  GetActionFormat(cell, row) {
    if(row != undefined){
        return (
            <div>
                <span data-tip="Ver Detalle" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.verDetalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x " style={{color:"#1E436D"}}></i>
                <i className="fa fa-window-restore fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                </span>
            </div>
        );
    }else{}
  }


  async verDetalle(data){
    paramRRHH.vacaciones[1].enlace = "D"
    paramRRHH.trabajadoresAdm[1].enlace = "V"
    paramRRHH.vacaciones[0].data = data
    localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH))
    this.props.history.push('/consultarVacaciones');
  }


  async verReporte(){
   this.props.history.push("/reporteVacaciones")
  }



  estado(cell, row) {
    console.log(row.ingreso==row.fechaV)
    if(row != undefined){
        return (
            <div>
              {row.ingreso == row.fechaV  ? 
                  <span data-tip="Contrato" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} >
                    <ReactTooltip />
                    <i className="fa fa-circle fa-stack-2x " style={{color:"#626567"}}></i>
                    <i className="fa fa-handshake-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                  </span>
              :  row.corresponde == "no"  ? 
                  <span data-tip="Próximas Vacaciones" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} >
                    <ReactTooltip />
                    <i className="fa fa-circle fa-stack-2x " style={{color:"#641E16"}}></i>
                    <i className="fa fa-bell-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                  </span>

                : row.diasConsumidos < 30  ? 
                  <span data-tip="Vacaciones Pendientes" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} >
                    <ReactTooltip />
                    <i className="fa fa-circle fa-stack-2x " style={{color:"#B7950B"}}></i>
                    <i className="fa fa-exclamation fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                  </span>
                :  row.diasConsumidos == 30  ? 
                  <span data-tip="Vacaciones Completas" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} >
                    <ReactTooltip />
                    <i className="fa fa-circle fa-stack-2x " style={{color:"#0B5345"}}></i>
                    <i className="fa fa-check fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                  </span>
              
              : null
              }
            
               
            </div>
        );
    }else{}
  }
 
  
  render() {
    const columns = [
    {dataField: "item", text: "ITEM",  headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "anio", text: "AÑO", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "ingreso", text: "FECHA DE INGRESO", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "fechaV", text: "FECHA  DE VACACIONES PREVISTA", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "dias", text: "DIAS PARA LA PRÓXIMA VACACIÓN", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "diasTotal", text: "DIAS DE VACACIONES CORRESPONDIENTES", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "diasConsumidos", text: "DÍAS CONSUMIDOS", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "diasRestante", text: "DÍAS RESTANTES", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "estado", text: "ESTADO",   headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.estado},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                <div className="row">
                <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25, marginLeft: 20}}>Vacaciones del colaborador</h4>                
                </div>
                {this.state.isLoading ? (
                   <ClipLoader
                              css={override}
                              sizeUnit={"px"}
                              size={50}
                              color={'#123abc'}
                              loading={this.state.loading}
                          />                                  
                ) : (
                    <div>
                        <PaginationProvider
                            pagination={
                            paginationFactory(options)
                            }>
                            { contentTable }
                        </PaginationProvider>   
                    </div>
                )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ListadoVacaciones;