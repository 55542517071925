import React, { Component } from "react";
import {
  FormControl,
  ControlLabel,
  Grid,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import auth0Client from "login/Auth";
import { validator } from "store/Apis";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { sisperplus, fechasBusqueda } from "store/Apis";
import Select from "react-select";
import "react-confirm-alert/src/react-confirm-alert.css";
import Button from "components/CustomButton/CustomButton.jsx";
import Swal from "sweetalert2";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-bottom: 60px;
`;

class GenerarFirma extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "generarFirma",
      usuario: localStorage.getItem("nickname"),
      token:
        localStorage.getItem("AccessToken") != null
          ? "Bearer " + localStorage.getItem("AccessToken")
          : 0,
      token2: "Bearer " + localStorage.getItem("AccessToken"),
      id: JSON.parse(localStorage.getItem("datos"))[0].Person,
      movimientos: [],
      isLoading: true,
      nombre: JSON.parse(localStorage.getItem("datos"))[0].nombre,
      cargo: JSON.parse(localStorage.getItem("datos"))[0].cargo,
      area:
        JSON.parse(localStorage.getItem("datos"))[0].area == null
          ? ""
          : JSON.parse(localStorage.getItem("datos"))[0].area,
      files: [],
      files2: [],
      error: null,
      successMessage: "",
      anio: "",
      mes: "",
      years: [],
      meses: [],
      loading: false,
    };
  }

  handleAnio = (anio) => {
    this.setState({ anio });
  };

  handleMes = (mes) => {
    this.setState({ mes });
  };

  handleFolderUpload = (event) => {
    this.setState({ error: null, files: [] });
    const files = Array.from(event.target.files);

    if (files.length === 0) return;

    const dbfFiles = files.filter((file) =>
      file.name.toLowerCase().endsWith(".dbf")
    );

    const fileData = dbfFiles.map((file) => {
      const fullPath = file.webkitRelativePath;
      const pathParts = fullPath.split("/");
      const subcarpeta =
        pathParts.length > 1 ? pathParts[pathParts.length - 2] : "Desconocida";
      return { file, subcarpeta };
    });

    this.setState({ files: fileData });
  };

  handleFolderUpload2 = (event) => {
    this.setState({ error: null, files2: [] });
    const files = Array.from(event.target.files);
    if (files.length === 0) return;
    this.setState({ files2: files });
  };

  async componentDidMount() {
    if (
      localStorage.getItem("AccessToken") != null ||
      localStorage.getItem("expires_at") != null ||
      localStorage.getItem("operaciones") != null ||
      localStorage.getItem("nickname") != null
    ) {
      axios
        .get(validator + this.state.path + "/" + this.state.usuario, {
          headers: {
            Authorization: this.state.token2,
          },
        })
        .then(
          (response) => {
            let permisos = response.data;
            if (permisos.codError !== 1) {
              this.props.history.goBack();
            } else {
              this.setState({ isLoading: false });
              this.generarPeriodos();
            }
          },
          (error) => {
            if (auth0Client.isAuthenticated() === true) {
              auth0Client.signOut();
              this.props.history.replace("/");
            } else {
              this.props.history.goBack();
            }
          }
        );
    } else {
      auth0Client.signOut();
      this.props.history.replace("/");
    }
  }

  generarPeriodos() {
    let year = 2024;
    let cont = 26;
    let array = Array.from(new Array(cont), (val, index) => index + year);
    let arrayMes = [
      { nid: "01", descripcion: "ENERO" },
      { nid: "02", descripcion: "FEBRERO" },
      { nid: "03", descripcion: "MARZO" },
      { nid: "04", descripcion: "ABRIL" },
      { nid: "05", descripcion: "MAYO" },
      { nid: "06", descripcion: "JUNIO" },
      { nid: "07", descripcion: "JULIO" },
      { nid: "08", descripcion: "AGOSTO" },
      { nid: "09", descripcion: "SETIEMBRE" },
      { nid: "10", descripcion: "OCTUBRE" },
      { nid: "11", descripcion: "NOVIEMBRE" },
      { nid: "12", descripcion: "DICIEMBRE" },
    ];
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    if (
      fechasBusqueda.boletas[0].nidperiodo !== "" &&
      fechasBusqueda.boletas[1].periodo !== ""
    ) {
      array.forEach((data) => {
        cont2++;
        lista.push({
          value: cont2,
          label: data,
        });
      });
      this.setState({
        anio: {
          value: fechasBusqueda.boletas[0].nidperiodo,
          label: fechasBusqueda.boletas[1].periodo,
        },
      });
    } else {
      array.forEach((data) => {
        cont2++;
        lista.push({
          value: cont2,
          label: data,
        });
        if (data === new Date().getFullYear()) {
          this.setState({ anio: { value: cont2, label: data } });
        }
      });
    }
    if (
      fechasBusqueda.boletas[2].nidmes !== "" &&
      fechasBusqueda.boletas[3].mes !== ""
    ) {
      arrayMes.forEach((data) => {
        cont3++;
        lista2.push({
          item: cont3,
          value: data.nid,
          label: data.descripcion,
        });
      });
      this.setState({
        mes: {
          value: fechasBusqueda.boletas[2].nidmes,
          label: fechasBusqueda.boletas[3].mes,
        },
      });
    } else {
      arrayMes.forEach((data) => {
        cont3++;
        lista2.push({
          item: cont3,
          value: data.nid,
          label: data.descripcion,
        });
        if (cont3 === new Date().getMonth() + 1) {
          this.setState({ mes: { value: data.nid, label: data.descripcion } });
        }
      });
    }
    this.setState({ years: lista, meses: lista2 });
  }

  descargarZip = async () => {
    this.setState({ error: null, successMessage: "", loading: true });

    Swal.fire({
      title: "Descargando Archivos",
      html: "Preparando la descarga...",
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
      allowOutsideClick: false,
    });

    try {
      axios.defaults.headers.common["Authorization"] = this.state.token;
      const response = await axios.get(`${sisperplus}boletaAdm/descargarZip`, {
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded * 100) / total);
          const loadedMB = (loaded / (1024 * 1024)).toFixed(2);
          const totalMB = (total / (1024 * 1024)).toFixed(2);

          Swal.update({
            html: `Descargando: ${loadedMB} MB de ${totalMB} MB<br>${percentCompleted}% completado`,
          });
        },
      });

      if (response && response.data) {
        const blob = new Blob([response.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "reporte.zip";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        Swal.close();
        this.props.handleClick(
          "tl",
          "success",
          "Archivo ZIP descargado correctamente!",
          "pe-7s-smile"
        );
        this.setState({
          successMessage: "Archivo ZIP descargado correctamente!",
        });
      } else {
        Swal.close();
        this.props.handleClick(
          "tl",
          "error",
          "Hubo un problema al descargar el archivo!",
          "pe-7s-close"
        );
      }
    } catch (error) {
      console.error("Error al descargar el ZIP:", error);

      Swal.close();
      if (auth0Client.isAuthenticated()) {
        auth0Client.signOut();
        this.props.history.replace("/");
      }
    } finally {
      this.setState({ loading: false });
      Swal.close();
    }
  };

  enviarArchivosAprocesar = async () => {
    Swal.fire({
      title: "Procesando Archivos",
      text: "Espere por favor...",
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
      allowOutsideClick: false,
    });

    this.setState({ error: null, successMessage: "", loading: true });
    let formData = new FormData();
    this.state.files.forEach(({ file, subcarpeta }) => {
      formData.append("archivo", file);
      formData.append("carpeta", subcarpeta);
    });
    formData.append("anio", this.state.anio.label);
    formData.append("mes", this.state.mes.label);
    formData.append("id", this.state.mes.value);

    try {
      axios.defaults.headers.common["Authorization"] = this.state.token;
      const response = await axios.post(
        `${sisperplus}boletaAdm/recibirDbf`,
        formData
      );

      if (response.status === 200) {
        this.descargarZip();
        this.props.handleClick(
          "tl",
          "success",
          "Boletas generadas correctamente!",
          "pe-7s-smile"
        );
        this.props.handleClick(
          "tl",
          "info",
          "Descargando Archivo ZIP...",
          "pe-7s-smile"
        );
        this.setState({
          successMessage: "Boletas generadas correctamente!",
          files: [],
        });
      } else if (response.status === 204) {
        this.props.handleClick(
          "tl",
          "warning",
          "No hay datos para procesar.",
          "pe-7s-info"
        );
      } else {
        this.props.handleClick(
          "tl",
          "error",
          "Hubo un problema al registrar!",
          "pe-7s-close"
        );
      }
    } catch (error) {
      Swal.close();

      if (error.response) {
        if (error.response.status === 401) {
          this.props.handleClick(
            "tl",
            "error",
            "No autorizado. Cerrando sesión...",
            "pe-7s-close"
          );
          if (auth0Client.isAuthenticated()) {
            auth0Client.signOut();
            this.props.history.replace("/");
          }
        } else if (error.response.status === 500) {
          this.props.handleClick(
            "tl",
            "error",
            "Error interno del servidor.",
            "pe-7s-close"
          );
        } else {
          this.props.handleClick(
            "tl",
            "error",
            "Hubo un problema al procesar la solicitud.",
            "pe-7s-close"
          );
        }
      } else {
        this.props.handleClick(
          "tl",
          "error",
          "No se pudo conectar al servidor.",
          "pe-7s-close"
        );
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  subirArchivoLote = async () => {
    this.setState({ error: null, successMessage: "", loading: true });

    // Filtrar solo los archivos con extensión .pdf
    const archivosPdf = this.state.files2.filter((file) =>
      file.name.toLowerCase().endsWith(".pdf")
    );

    // Si no hay archivos PDF, mostrar un mensaje y detener la ejecución
    if (archivosPdf.length === 0) {
      this.setState({ loading: false });
      this.props.handleClick(
        "tl",
        "error",
        "No se encontraron archivos PDF para enviar.",
        "pe-7s-close"
      );
      return;
    }

    let formData = new FormData();
    archivosPdf.forEach((file) => {
      formData.append("archivos", file);
    });
    formData.append("year", this.state.anio.label);
    formData.append("mes", this.state.mes.value);

    try {
      axios.defaults.headers.common["Authorization"] = this.state.token;
      const response = await axios.post(
        `${sisperplus}boletaAdm/subirArchivosFirmados`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.response != null) {
        this.props.handleClick(
          "tl",
          "success",
          "Boletas generadas correctamente!",
          "pe-7s-smile"
        );
        this.props.handleClick(
          "tl",
          "info",
          "Descargando Archivo ZIP...",
          "pe-7s-smile"
        );
        this.setState({
          successMessage: "Boletas subidas correctamente!",
        });
      } else {
        this.props.handleClick(
          "tl",
          "error",
          "Hubo un problema al registrar!",
          "pe-7s-close"
        );
      }
    } catch (error) {
      console.error("Error al enviar los archivos:", error);
      this.props.handleClick(
        "tl",
        "error",
        "Hubo un problema al registrar!",
        "pe-7s-close"
      );
      if (auth0Client.isAuthenticated()) {
        auth0Client.signOut();
        this.props.history.replace("/");
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { files, error, successMessage, loading } = this.state;
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div
                style={{
                  borderRadius: 5,
                  backgroundColor: "white",
                  padding: 15,
                }}
              >
                <h4
                  className="title"
                  style={{ fontWeight: 200, marginTop: 18, marginBottom: 25 }}
                >
                  Seleccionar Carpeta con Archivos DBF
                </h4>
                <div className="row">
                  <div className="col-md-4">
                    <ControlLabel>Seleccionar Carpeta</ControlLabel>
                    <input
                      type="file"
                      webkitdirectory=""
                      directory=""
                      multiple
                      // accept=".dbf"
                      onChange={this.handleFolderUpload}
                      disabled={loading}
                    />
                  </div>
                  <div className="col-md-3">
                    <ControlLabel>PERIODO</ControlLabel>
                    <Select
                      value={this.state.anio}
                      onChange={this.handleAnio}
                      options={this.state.years}
                    />
                  </div>
                  <div className="col-md-3">
                    <ControlLabel>MES</ControlLabel>
                    <Select
                      value={this.state.mes}
                      onChange={this.handleMes}
                      options={this.state.meses}
                    />
                  </div>
                  <div className="col-md-2">
                    <br />
                    {files.length > 0 && (
                      <Button
                        variant="success"
                        onClick={this.enviarArchivosAprocesar}
                        disabled={loading}
                      >
                        {loading ? (
                          <div className="sweet-loading">
                            <ClipLoader
                              css={override}
                              loading={this.state.loading}
                            />
                            <p> Procesando...</p>
                          </div>
                        ) : (
                          "Procesar Archivos"
                        )}
                      </Button>
                    )}
                  </div>
                  {files.length > 0 && (
                    <div className="col-md-12">
                      <h5>Archivos Seleccionados: {files.length}</h5>
                      <ul
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          padding: 0,
                          listStyle: "none",
                          maxWidth: "1200px",
                        }}
                      >
                        {files.map(({ file, subcarpeta }, index) => (
                          <li
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "100%",
                            }}
                          >
                            <span style={{ marginRight: "5px", flexShrink: 0 }}>
                              {index + 1}.
                            </span>{" "}
                            <span
                              style={{
                                flex: 1,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {file.name.length > 20
                                ? "..." + file.name.slice(-60)
                                : file.name}{" "}
                              <strong>{subcarpeta}</strong>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="row" style={{ marginTop: 20 }}>
                  {error && <Alert variant="danger">{error}</Alert>}
                  {successMessage && (
                    <Alert variant="success">{successMessage}</Alert>
                  )}
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div
                style={{
                  borderRadius: 5,
                  backgroundColor: "white",
                  padding: 15,
                  marginTop: 20,
                }}
              >
                <h4
                  className="title"
                  style={{ fontWeight: 200, marginTop: 18, marginBottom: 25 }}
                >
                  Subir Documentos Firmados
                </h4>
                <div className="row">
                  <div className="col-md-6">
                    <ControlLabel>Seleccionar Carpeta</ControlLabel>
                    <input
                      type="file"
                      webkitdirectory=""
                      directory=""
                      multiple
                      // accept=".pdf"
                      onChange={this.handleFolderUpload2}
                      disabled={loading}
                    />
                  </div>
                  <div className="col-md-6">
                    {this.state.files2.length > 0 && (
                      <Button
                        variant="success"
                        onClick={this.subirArchivoLote}
                        disabled={loading}
                      >
                        {loading ? (
                          <div className="sweet-loading">
                            <ClipLoader
                              css={override}
                              loading={this.state.loading}
                            />
                            <p> Procesando...</p>
                          </div>
                        ) : (
                          "Subir Archivos"
                        )}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default GenerarFirma;
