import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import axios from 'axios';
import { gestor, sisperplus } from 'store/Apis'

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from 'react-select';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { validator } from 'store/Apis'

import { pdf } from "@react-pdf/renderer";
import { PdfDocument } from "views/autorizar/mipdf";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import Calendar from "components/Calendar/Calendar.jsx";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
    margin-TOP: 100px;
`;

class AutorizarDescansoM extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'autorizarDescanso',
      dni: localStorage.getItem('dni'),
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      nombre: JSON.parse(localStorage.getItem('datos'))[0].nombre,
      area:"",// {value: "1", label: JSON.parse(localStorage.getItem('datos'))[0].area}
      url: '',
      accion: {value: "A", label: "AUTORIZAR DESCANSO MÉDICO"},
      acciones: [{value: "A", label: "AUTORIZAR DESCANSO MÉDICO"},
                 {value: "D", label: "DESAUTORIZAR DESCANSO MÉDICO"}],
      areas: [],
      listado: [],
      isLoading: false,
      tipo:{value: 'A', label: "AUTORIZADAS"},
      tipos:[{value: 'A', label: "AUTORIZADAS"},{value: 'D', label: "DESAUTORIZADAS"}],
      mes :"",
      anio:"",
      years:[],
      meses:[],
      listadoApro:[]
    }
  }

  handleArea = area => {
    this.setState(
    { area },
    () => this.listar()
    );
    
  };

  handleAccion = accion => {
    this.setState(
    { accion },
    );
  };

  handleAnio = anio => {
    this.setState(
    { anio },
    () => this.listarAprobados()
    );
  };

  handleMes = mes => {
    this.setState(
    { mes },
    () => this.listarAprobados()
    );
  };

  handleTipo = tipo => {
    this.setState( {tipo},
      () => this.listarAprobados());
  };

  submit = () => {
    let cuenta = this.node.selectionContext.selected
    if(cuenta.length >= 1){
      confirmAlert({
        title: '¿Seguro de procesar?',
        message: 'Se procesarán los descansos médicos seleccionados.',
        buttons: [
          {
            label: 'Aceptar',
            onClick: () => this.procesar()
          },
          {
            label: 'Cancelar',
            onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
          }
        ]
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe seleccionar algún registro para procesar!", "pe-7s-info")
    }
  };

  async componentDidMount() {
  if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
      headers: {
          Authorization: this.state.token
      }
      }).then((response) => {
          let permisos = response.data;
          if(permisos.codError != 1){
              this.props.history.goBack()
          }else{
              this.generarPeriodos();
          }
      },(error) => {
          if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
          }else{
          this.props.history.goBack()
          }
      });
  }else{
      auth0Client.signOut();
      this.props.history.replace('/');
  }
  }

  generarPeriodos(){
    let year =  2023;
    let cont = 3;
    let a = Array.from(new Array(cont),(val, index) => index + year);
    let array = a.reverse()
    let arrayMes = [
      {"nid": "01", "descripcion": "ENERO"},
      {"nid": "02", "descripcion": "FEBRERO"},
      {"nid": "03", "descripcion": "MARZO"},
      {"nid": "04", "descripcion": "ABRIL"},
      {"nid": "05", "descripcion": "MAYO"},
      {"nid": "06", "descripcion": "JUNIO"},
      {"nid": "07", "descripcion": "JULIO"},
      {"nid": "08", "descripcion": "AGOSTO"},
      {"nid": "09", "descripcion": "SETIEMBRE"},
      {"nid": "10", "descripcion": "OCTUBRE"},
      {"nid": "11", "descripcion": "NOVIEMBRE"},
      {"nid": "12", "descripcion": "DICIEMBRE"},
    ]
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
                  })
        if(data == (new Date()).getFullYear())
          {
            this.setState({ anio: {value: cont2, label: data}})
          }
        })
    arrayMes.map((data) => {
        cont3++
        lista2.push({
         item: data.cont3,
          value: data.nid,
          label: data.descripcion,
                   })
        if(cont3 == (new Date()).getMonth() + 1)
        {
          this.setState({
          mes: {value: data.nid, label: data.descripcion}})
        }
    })
    this.setState({years: lista, meses: lista2})
    this.cargaView()
  }


  cargaView(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'autorizar/areas?dni=' + this.state.dni)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.ReplicationUnit,
                label: data.name,
            })
          });
          this.setState({areas: lista , area : {value : lista[0].value , label : lista[0].label}} )
          this.listarPendientes()          
      }else{
        this.setState({areas: []})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }


  async listarPendientes() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'descanso/listDescansosxEstado?area=' + this.state.area.value + '&estado=P')    
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              fechaIni : Calendar.calendarioA(data.DateBegin),
              fechaFin: Calendar.calendarioA(data.DateEnd),
              dias : data.Days,
              nomTipoDescanso :data.Description,
              idTipoDescanso: data.MedicalRestType,
              idperson :data.Person,
              dni: data.DocumentNumber,
              nombres:data.Name,
              estado : data.Status=="A" ? "AUTORIZADO" : "PENDIENTE",
              fechaCreacion : Calendar.calendarioA(data.XLastDate),
              centro : data. centromedico,
              medico: data.medico ,
              diagnostico: data.diagnostico,
              line:data.line,
              area: data.area,
              item:data.item
             }) 
          }) 
          this.setState({listado: lista, isLoading: false})
          console.log(this.state.listado) 
          this.listarAprobados()    
        }else{
          this.setState({listado: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarAprobados(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'descanso/listDescansosxEstado?area=' + this.state.area.value + '&estado=' + this.state.tipo.value + '&anio=' + this.state.anio.label + '&mes=' + this.state.mes.value )
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push({ 
            fechaIni : Calendar.calendarioA(data.DateBegin),
            fechaFin: Calendar.calendarioA(data.DateEnd),
            dias : data.Days,
            nomTipoDescanso :data.Description,
            idTipoDescanso: data.MedicalRestType,
            idperson :data.Person,
            dni: data.DocumentNumber,
            nombres:data.Name,
            estado : data.Status=="A" ? "AUTORIZADO" : data.Status=="D" ? "DESAUTORIZADO" : "PENDIENTE",
            fechaCreacion : Calendar.calendarioA(data.XLastDate),
            centro : data. centromedico,
            medico: data.medico ,
            diagnostico: data.diagnostico,
            line:data.line,
            area: data.area,
            item:data.item
          })
        });
        this.setState({listadoApro: lista, isLoading: false})
      }else{
        this.setState({listadoApro: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }


  async procesar(){
    this.setState({isLoading: true});
    let lista = this.node.selectionContext.selected;
    setTimeout(
      function() {
      let cont = 1;  
      lista.map((data) => {
          for(var i = 0; i < this.state.listado.length; i++){
            if(this.state.listado[i].item == data){
                let fromData = new FormData();
                let idperson = this.state.listado[i].idperson;
                let line = this.state.listado[i].line;
                fromData.append('person', idperson);
                fromData.append('line', line);
                fromData.append('estado', this.state.accion.value);
                axios.defaults.headers.common['Authorization'] = this.state.token
                axios.post(`${sisperplus}` +   'descanso/gestionarDM', fromData)
                .then((response) => {
                  var datos = response.data
                  if(datos.response != null){
                       if(lista.length == cont++){
                          this.listarPendientes();
                          this.props.handleClick("tl", "info","El descanso médico han sido procesado con éxito", "pe-7s-smile")                          
                        }
                    }else{
                    this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
                    this.setState({isLoading: false})
                  }
                })
                .catch((error) =>{ 
                  if(auth0Client.isAuthenticated() === true){
                    auth0Client.signOut()
                    this.props.history.replace('/');
                  }else{
                    console.log(error);
                    this.setState({isLoading: false})
                  }
                });
              
            }
          }
      });
      }
      .bind(this),
      3000
    );
  }



  render() {
    const columns = [
      {dataField: "nombres", text: "TRABAJADOR", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "estado", text: "ESTADO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nomTipoDescanso", text: "TIPO DE DESCANSO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechaIni", text: "FEC. INICIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechaFin", text: "FEC. TERMINO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "diagnostico", text: "DIAGNOSTICO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechaCreacion", text: "FEC. REGISTRO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "area", text: "ÁREA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const columnsA = [
      {dataField: "nombres", text: "TRABAJADOR", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "estado", text: "ESTADO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nomTipoDescanso", text: "TIPO DE DESCANSO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechaIni", text: "FEC. INICIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechaFin", text: "FEC. TERMINO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "diagnostico", text: "DIAGNOSTICO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechaCreacion", text: "FEC. REGISTRO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "area", text: "ÁREA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.listado.length
    };
    const options2 = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.listado.length
    };
    const columns2 = [{
      dataField: 'CheckDate',
      headerAlign: (column, colIndex) => 'center',
      text: 'Fecha'
    }, {
      dataField: 'descripcion',
      headerAlign: (column, colIndex) => 'center',
      text: 'Observación'
    }];
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
        {/* <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div> */}
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              ref={ n => this.node = n }
              data={ this.state.listado }
              columns={ columns }
              selectRow={ selectRow }
              tabIndexCell
              noDataIndication="No existen registros para mostrar"
              // cellEdit={ cellEditFactory({ mode: 'click' }) }
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const contentTable2 = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
        {/* <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div> */}
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              ref={ n => this.node = n }
              data={ this.state.listadoApro }
              columns={ columnsA }
              tabIndexCell
              noDataIndication="No existen registros para mostrar"
              // cellEdit={ cellEditFactory({ mode: 'click' }) }
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const { area, accion } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <Card
                title="Procesar Descansos médicos del Personal"
                content={
                  <div style={{marginBottom: -20}}>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>ÁREAS DE APLICACIÓN</ControlLabel>
                            <Select value={area} onChange={this.handleArea} options={this.state.areas}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>ACCIÓN A REALIZAR</ControlLabel>
                            <Select value={accion} onChange= {this.handleAccion} options={this.state.acciones} />
                        </div>
                    </div>
                   <Button style={{width: 150, marginLeft: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.submit() }}>Procesar</Button>
                  </div>}
              />
            </Col>
          </Row>

          
           <Row>
            <Col md={12}>
              <Tabs style={{marginTop: 30}}>
                <TabList>
                  <Tab>DESCANSOS MÉDICOS PENDIENTES</Tab>
                  <Tab>DESCANSOS MÉDICOS AUTORIZADAS Y DESAUTORIZADAS</Tab>
                </TabList>
                <TabPanel>
                    <div className="content" style={{marginTop: 20}}>
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Descansos médicos Pendientes</h4>
                    <h4 className="category" style={{fontSize: 15, marginTop: -10}}>Para autorizar o desautorizar los descansos médicos deberás seleccionar las opciones y luego pulsar el boton Procesar para iniciar el proceso.</h4>
                    {this.state.isLoading ? (
                            <ClipLoader
                              css={override}
                              sizeUnit={"px"}
                              size={50}
                              color={'#123abc'}
                              loading={this.state.isLoading}
                            />
                          ) : (
                            <PaginationProvider
                              pagination={
                                paginationFactory(options)
                              }
                            >
                              { contentTable }
                            </PaginationProvider>
                      )}
                    </div>
                </TabPanel>
                <TabPanel>
                <div className="content" style={{marginTop: 20}}>
                        <h4 className="title" style={{fontWeight: 200}}>Lista de Descanso médicos {this.state.tipo.value=='A' ? 'Autorizadas' : 'Desautorizadas'}</h4>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>PERIODO</ControlLabel>
                            <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>MES</ControlLabel>
                            <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses}/>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>TIPO</ControlLabel>
                            <Select value={this.state.tipo} onChange={this.handleTipo} options={this.state.tipos}/>
                        </div>
                        {this.state.isLoading ? (
                                <ClipLoader
                                  css={override}
                                  sizeUnit={"px"}
                                  size={50}
                                  color={'#123abc'}
                                  loading={this.state.isLoading}
                                />
                              ) : (
                                <PaginationProvider
                                  pagination={
                                    paginationFactory(options2)
                                  }
                                >
                                  { contentTable2 }
                                </PaginationProvider>
                          )}
                    </div>
                </TabPanel>

              </Tabs>
            </Col>
         </Row>

        </Grid>
      </div>
    );
  }
}

export default AutorizarDescansoM;