import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import Calendar from "components/Calendar/Calendar.jsx";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import InputFiles from 'react-input-files';
import axios from 'axios';
import { cec} from 'store/Apis'

import 'react-confirm-alert/src/react-confirm-alert.css';



const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class EditarCursos extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'cursos',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      descripcion:'',

      ctxt_ruta: '',
      titulo:'',
      fecha: '',
      nid:'',
      archivo:"",
      file: [],
      isLoading: true
    }
    this.handleDescripcion = this.handleDescripcion.bind(this);
    this.handleTitulo = this.handleTitulo.bind(this);
    this.handleFecha = this.handleFecha.bind(this);
}
    handleDescripcion(event) {this.setState({descripcion: event.target.value});}
    handleTitulo(event) {this.setState({titulo: event.target.value});}
    handleFecha(event) {this.setState({fecha: event.target.value});}

  
 
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
              this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(JSON.parse(localStorage.getItem("cec")).cursos[0].data !== null){
        this.setState({
            descripcion: JSON.parse(localStorage.getItem('cec')).cursos[0].data.ctxt_descripcion,
            archivo: JSON.parse(localStorage.getItem('cec')).cursos[0].data.ctxt_imagen,
            ctxt_ruta:JSON.parse(localStorage.getItem('cec')).cursos[0].data.ctxt_ruta,
            titulo: JSON.parse(localStorage.getItem('cec')).cursos[0].data.ctxt_titulo,
            fecha: Calendar.calendario11(JSON.parse(localStorage.getItem('cec')).cursos[0].data.dfec_fecha),
            nid: JSON.parse(localStorage.getItem('cec')).cursos[0].data.nid,
            isLoading:false
        });
    }else{
      this.props.history.push('/cursos');
    }
  }

 async actualizar(){
    if (this.state.descripcion != '' && this.state.titulo != ''  && this.state.fecha != ''){
      this.setState({isLoading: true})
      let estado = 0;
      if(this.state.file.length != 0){
          estado = 1;
      }
      let fromData = new FormData();
      fromData.append('file', this.state.file);
      fromData.append('titulo', this.state.titulo);
      fromData.append('descripcion', this.state.descripcion);
      fromData.append('imagen', this.state.archivo);
      fromData.append('oldruta', this.state.ctxt_ruta);
     
      fromData.append('fecha', this.state.fecha);	
      fromData.append('estado', estado);	
      fromData.append('usuario', this.state.usuario);	
      fromData.append('nidcurso', this.state.nid);	
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${cec}` + 'cursos/editarCurso', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          this.props.handleClick("tl", "success", "Curso actualizado correctamente!", "pe-7s-close")
            this.regresar();
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
     }else{
      this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close") 
     }
    
  }

  async regresar(){
    this.props.history.push('/cursos');
  }
  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], archivo: files[0].name});
    }
  }

  async limpiar(){
    this.setState({archivo: "", file: ""})
  }
 
  render() {
 
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>EDITAR : <a style={{fontWeight: "bold", color: "black"}}>{this.state.titulo}</a></h4>
                  {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                       <div className="row">
                            <div className="col-md-8" style={{marginBottom: 18}}>
                                <ControlLabel>TÍTULO</ControlLabel>
                                <FormControl type="text" value={this.state.titulo} onChange= {this.handleTitulo}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA</ControlLabel>
                                <FormControl type="date" value={this.state.fecha} onChange= {this.handleFecha}/>
                            </div>
                       </div>
                        <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>DESCRIPCIÓN</ControlLabel>
                                <FormControl componentClass="textarea" style={{  height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.descripcion} onChange= {this.handleDescripcion} />
                            </div>
                        </div>
                     
                        <div className="row">
                            
                            <div className="col-md-6">
                                <ControlLabel>ARCHIVO ADJUNTO</ControlLabel>
                                <Alert bsStyle="warning">
                                    <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo}</span>
                                </Alert>
                                <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                                    <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar la imagen</InputFiles>
                                </Button>
                                {this.state.archivo != "" ? (
                                  <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar Archivo</Button>
                                ) : ( 
                                  null
                                )}
                            </div>
                        </div>
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Actualización</Button>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.actualizar() }}>Actualizar Registro</Button> 
                        </div>
                  </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarCursos;