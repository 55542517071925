import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Calendar from "components/Calendar/Calendar.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { tesoreria, paramTesoreria } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ConsultaUtilitarios extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'tablasmaestras',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      clasificadores: [],
      retenciones: [],
      metas: [],
      saldos: [],
      areas: [],
      trabajadores: [],
      descripcion: "",
      partida: "",
      descripcion2: "",
      descripcion3: "",
      meta: "",
      fn: "",
      pf: "",
      spf: "",
      prg: "",
      actproy: "",
      comp: "",
      fin: "",
      nidcarga: "",
      saldo: "0.00",
      nidcarga2: "",
      isLoading: true,
      isLoading2: true,
      isLoading3: true,
      isLoading4: true,
      isLoading5: true,
      isLoading6: true,
      marcador: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);
    this.GetActionFormat3= this.GetActionFormat3.bind(this);
    this.GetActionFormat4= this.GetActionFormat4.bind(this);

    this.handleDescripcion = this.handleDescripcion.bind(this);
    this.handlePartida = this.handlePartida.bind(this);
    this.handleDescripcion2 = this.handleDescripcion2.bind(this);
    this.handleDescripcion3 = this.handleDescripcion3.bind(this);
    this.handleMeta = this.handleMeta.bind(this);
    this.handleFn = this.handleFn.bind(this);
    this.handlePf = this.handlePf.bind(this);
    this.handleSpf = this.handleSpf.bind(this);
    this.handlePrg = this.handlePrg.bind(this);
    this.handleActproy = this.handleActproy.bind(this);
    this.handleComp = this.handleComp.bind(this);
    this.handleFin = this.handleFin.bind(this);
    this.handleSaldo = this.handleSaldo.bind(this);

  }

    handleDescripcion(event) {
        this.setState({descripcion: event.target.value});
    }
    handlePartida(event) {
        this.setState({partida: event.target.value});
    }
    handleDescripcion2(event) {
        this.setState({descripcion2: event.target.value});
    }
    handleDescripcion3(event) {
        this.setState({descripcion3: event.target.value});
    }
    handleMeta(event) {
        this.setState({meta: event.target.value});
    }
    handleFn(event) {
        this.setState({fn: event.target.value});
    }
    handlePf(event) {
        this.setState({pf: event.target.value});
    }
    handleSpf(event) {
        this.setState({spf: event.target.value});
    }
    handlePrg(event) {
        this.setState({prg: event.target.value});
    }
    handleActproy(event) {
        this.setState({actproy: event.target.value});
    }
    handleComp(event) {
        this.setState({comp: event.target.value});
    }
    handleFin(event) {
        this.setState({fin: event.target.value});
    }
    handleSaldo(event) {
      this.setState({saldo: event.target.value});
    }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.listarClasificadores(0);
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listarClasificadores(param) {
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${tesoreria}` + 'tipos/listClasificadores?admin=1')
      .then((res) => {
        let lista = [];
        let lista3 = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { item: data.item,
                nidclasificador: data.nid,
                partida: data.ctxt_partida,
                descripcion: data.ctxt_descripcion
            })
            lista3.push(
              { value: data.nid,
                label: data.ctxt_partida + " (" + data.ctxt_descripcion + ")",       
              })
          })
          this.setState({clasificadores: lista, isLoading: false})
          paramTesoreria.clasificadores[0].data = lista3
          localStorage.setItem('data', JSON.stringify(paramTesoreria))
          if(param == 0){
            this.listarRetenciones(0)
          }else{
            this.cancelar();
          }
        }else{
          this.setState({clasificadores: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async listarRetenciones(param) {
    this.setState({isLoading2: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listRetenciones?admin=1')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { item: data.item,
              nidretencion: data.nid,
              descripcion2: data.ctxt_descripcion
          })
        })
        this.setState({retenciones: lista, isLoading2: false})
        if(param == 0){
          this.listarMetas(0)
        }else{
          this.cancelar2();
        }
      }else{
        this.setState({retenciones: [], isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading2: false})
      }
    });
  }

  async listarRetenciones2() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listRetenciones?admin=0')
    .then((res) => {
      let lista = [];
      let lista3 = [{value: 0, label: 'NINGUNO'}];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_descripcion,       
            })
          lista3.push(
            { value: data.nid,
              label: data.ctxt_descripcion,       
            })
        })
        paramTesoreria.retenciones[0].data = lista
        paramTesoreria.retenciones[1].data2 = lista3
        localStorage.setItem('data', JSON.stringify(paramTesoreria))
      }else{
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarMetas(param) {
    this.setState({isLoading3: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listMetas')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { item: data.item,
              nidmeta: data.nid,
              descripcion3: data.ctxt_descripcion,
              meta: data.ctxt_meta,
              fn: data.ctxt_fn,
              pf: data.ctxt_pf,
              spf: data.ctxt_spf,
              prg: data.ctxt_prg,
              actproy: data.ctxt_actproy,
              comp: data.ctxt_comp,
              fin: data.ctxt_fin,
          })
        })
        this.setState({metas: lista, isLoading3: false})
        if(param == 0){
          this.listarSaldos(0)
        }else{
          this.cancelar3();
        }
      }else{
        this.setState({metas: [], isLoading3: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading3: false})
      }
    });
  }

  async listarSaldos(param) {
    this.setState({isLoading6: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listSaldos')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { item: data.item,
              nid: data.nid,
              mes: Calendar.calendario13(data.cnum_mes),
              anio: data.cnum_periodo,
              monto: data.monto_inicial != null ? 'S/.    ' + data.monto_inicial.toFixed(2) : 'S/. 0.00', 
          })
        })
        this.setState({saldos: lista, isLoading6: false})
        if(param == 0){
          this.listarAreas(0)
        }else{
          this.cancelar4();
        }
      }else{
        this.setState({saldos: [], isLoading6: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading3: false})
      }
    });
  }

  async listarAreas(param) {
    this.setState({isLoading4: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listAreasActuales')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { item: data.item,
              nidarea: data.nid,
              descripcion4: data.ctxt_descripcion
          })
        })
        this.setState({areas: lista, isLoading4: false})
        this.listarTrabajadores()
      }else{
        this.setState({areas: [], isLoading4: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading3: false})
      }
    });
  }

  async listarTrabajadores() {
    this.setState({isLoading5: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listTrabajadoresActuales')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { item: data.item,
              nidtrabajador: data.nid,
              descripcion5: data.ctxt_descripcion,
              documento: data.cnum_documento
          })
        })
        this.setState({trabajadores: lista, isLoading5: false})
      }else{
        this.setState({trabajadores: [], isLoading5: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading5: false})
      }
    });
  }

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidclasificador', data.nidclasificador);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'tipos/anularClasificador', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarClasificadores(1);
        this.props.handleClick("tl", "info", "El clasificador ha sido eliminado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async delete2(data){
    this.setState({isLoading2: true})
    let fromData = new FormData();
    fromData.append('nidretencion', data.nidretencion);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'tipos/anularRetencion', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarRetenciones2();
        this.listarRetenciones(1);
        this.props.handleClick("tl", "info", "El concepto o retención ha sido eliminado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading2: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading2: false})
      }
    });
  }

  async delete3(data){
    this.setState({isLoading3: true})
    let fromData = new FormData();
    fromData.append('nidmeta', data.nidmeta);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'tipos/anularMeta', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarMetas(1);
        this.props.handleClick("tl", "info", "La meta ha sido eliminada!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading3: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading3: false})
      }
    });
  }

 async limpiar(){
    this.listarClasificadores(1);
    this.cancelar();
    this.props.handleClick("tl", "info", "El clasificador ha sido registrado!", "pe-7s-smile")
  }

  async limpiar2(){
    this.listarRetenciones2();
    this.listarRetenciones(1);
    this.cancelar2();
    this.props.handleClick("tl", "info", "El concepto o retención ha sido registrado!", "pe-7s-smile")
  }

  async limpiar3(){
    this.listarMetas(1);
    this.cancelar3();
    this.props.handleClick("tl", "info", "La meta ha sido registrada!", "pe-7s-smile")
  }

  async limpiar4(){
    this.listarSaldos(1);
    this.cancelar4();
    this.props.handleClick("tl", "info", "El saldo ha sido actualizado!", "pe-7s-smile")
  }

  async cancelar(){
    this.setState({descripcion: "", partida: ""})
  }

  async cancelar2(){
    this.setState({descripcion2: ""})
  }

  async cancelar3(){
    this.setState({descripcion3: "", meta: "", fn: "", pf: "", spf: "", prg: "", actproy: "", comp: "", fin: "", nidcarga: ""})
  }

  async cancelar4(){
    this.setState({saldo: "0.00", nidcarga2: "", marcador: true})
  }

  async guardar(){
      this.setState({isLoading: true})
      let fromData = new FormData();
      fromData.append('descripcion', this.state.descripcion);
      fromData.append('partida', this.state.partida);
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'tipos/insertarClasificador', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.limpiar();
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
      });
  }

  async guardar2(){
    this.setState({isLoading2: true})
    let fromData = new FormData();
    fromData.append('descripcion', this.state.descripcion2);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'tipos/insertarRetencion', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar2();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading2: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading2: false})
      }
    });
  }

  async guardar3(){
    this.setState({isLoading3: true})
    let fromData = new FormData();
    fromData.append('descripcion', this.state.descripcion3);
    fromData.append('meta', this.state.meta);
    fromData.append('fn', this.state.fn);
    fromData.append('pf', this.state.pf);
    fromData.append('spf', this.state.spf);
    fromData.append('prg', this.state.prg);
    fromData.append('actproy', this.state.actproy);
    fromData.append('comp', this.state.comp);
    fromData.append('fin', this.state.fin);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'tipos/insertarMeta', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar3();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading3: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading3: false})
      }
    });
  }

  async guardar4(){
    this.setState({isLoading6: true})
    let fromData = new FormData();
    fromData.append('monto', this.state.saldo);
    fromData.append('nidsaldo', this.state.nidcarga2);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'tipos/editarSaldo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar4();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading6: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading6: false})
      }
    });
  }

  async update(){
    this.setState({isLoading3: true})
    let fromData = new FormData();
    fromData.append('descripcion', this.state.descripcion3);
    fromData.append('meta', this.state.meta);
    fromData.append('fn', this.state.fn);
    fromData.append('pf', this.state.pf);
    fromData.append('spf', this.state.spf);
    fromData.append('prg', this.state.prg);
    fromData.append('actproy', this.state.actproy);
    fromData.append('comp', this.state.comp);
    fromData.append('fin', this.state.fin);
    fromData.append('usuario', this.state.usuario);
    fromData.append('nidmeta', this.state.nidcarga);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'tipos/editarMeta', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar3();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading3: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading3: false})
      }
    });
  }

  async save(){
    if(this.state.descripcion != "" && this.state.partida != ""){
      this.guardar()
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async save2(){
    if(this.state.descripcion2 != ""){
      this.guardar2()
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async save3(){
    if(this.state.descripcion3 != "" && this.state.meta != "" && this.state.fn != "" && this.state.pf != "" && this.state.spf != ""
    && this.state.prg != "" && this.state.actproy != "" && this.state.comp != "" && this.state.fin != ""){
      if(this.state.nidcarga == ''){
        this.guardar3()
      }else{
        this.update()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async save4(){
    if(this.state.marcador == false){
      if(this.state.nidcarga2 != '' && this.state.saldo != ''){
        this.guardar4()
      }else{
        this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
      }
    }else{
      this.props.handleClick("tl", "warning", "No se ha seleccionando ningun saldo para editar!", "pe-7s-info")
    }
  }

  async editar(datos){
    this.setState({
      descripcion3: datos.descripcion3,
      meta: datos.meta,
      fn: datos.fn,
      pf: datos.pf,
      spf: datos.spf,
      prg: datos.prg,
      actproy: datos.actproy,
      comp: datos.comp,
      fin: datos.fin,
      nidcarga: datos.nidmeta,
    })
  }

  async editar2(datos){
    this.setState({
      saldo: datos.monto.replace("S/.    ", ""),
      nidcarga2: datos.nid,
      marcador: false
    })
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Remover Clasificador" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  GetActionFormat2(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Remover Concepto o Retención" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete2(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  GetActionFormat3(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Meta" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Remover Meta" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete3(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  GetActionFormat4(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Saldo" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar2(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "partida", text: "PARTIDA", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "descripcion", text: "DESCRIPCIÓN", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const columns2 = [
        {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "descripcion2", text: "DESCRIPCIÓN", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "detail2", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2}
    ]
    const columns3 = [
        {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "descripcion3", text: "DESCRIPCIÓN", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "meta", text: "META", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "fn", text: "FN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "pf", text: "P/F", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "spf", text: "S/PF", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "prg", text: "PRG", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "actproy", text: "ACT/PROY", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "comp", text: "COMP", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "fin", text: "FIN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "detail3", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat3}
    ]
    const columns4 = [
      {dataField: "descripcion4", text: "ÁREAS", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center'},
    ]
    const columns5 = [
      {dataField: "descripcion5", text: "TRABAJADORES", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center'},
      {dataField: "documento", text: "N° DE DNI", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const columns6 = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "anio", text: "PERIODO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "mes", text: "MES", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "monto", text: "IMPORTE", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail4", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat4}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.clasificadores.length
    };
    const options2 = {
        custom: true,
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'Primera',
        prePageText: 'Anterior',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        showTotal: true,
        totalSize: this.state.retenciones.length
    };
    const options3 = {
        custom: true,
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'Primera',
        prePageText: 'Anterior',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        showTotal: true,
        totalSize: this.state.metas.length
    };
    const options4 = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.areas.length
    };
    const options5 = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.trabajadores.length
    };
    const options6 = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.saldos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.clasificadores }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen clasificadores para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const contentTable2 = ({ paginationProps, paginationTableProps }) => (
        <div>
          <div>
            <div>
              <BootstrapTable
                striped
                hover
                keyField="item"
                data={ this.state.retenciones }
                columns={ columns2 }
                filter={ filterFactory() }
                noDataIndication="No existen retenciones para mostrar"
                { ...paginationTableProps }
              />
            </div>
            <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
          </div>
        </div>
    );
    const contentTable3 = ({ paginationProps, paginationTableProps }) => (
        <div>
          <div>
            <div>
              <BootstrapTable
                striped
                hover
                keyField="item"
                data={ this.state.metas }
                columns={ columns3 }
                filter={ filterFactory() }
                noDataIndication="No existen metas para mostrar"
                { ...paginationTableProps }
              />
            </div>
            <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
          </div>
        </div>
    );
    const contentTable4 = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.areas }
              columns={ columns4 }
              filter={ filterFactory() }
              noDataIndication="No existen areas para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const contentTable5 = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.trabajadores }
              columns={ columns5 }
              filter={ filterFactory() }
              noDataIndication="No existen trabajadores para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const contentTable6 = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.saldos }
              columns={ columns6 }
              filter={ filterFactory() }
              noDataIndication="No existen saldos para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
        <Col md={12}>
            <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
            <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Tablas Maestras</h4>
            <Tabs>
                <TabList>
                    <Tab>CLASIFICADORES</Tab>
                    <Tab>CONCEPTOS / RETENCIONES</Tab>
                    <Tab>METAS</Tab>
                    <Tab>SALDOS DE FONDOS</Tab>
                    <Tab>ÁREAS Y TRABAJADORES</Tab>
                </TabList>
                <TabPanel>
                    <div className="row" style={{marginTop: 30, marginBottom: 10}}>
                        <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>PARTIDA</ControlLabel>
                                <FormControl type="text" value={this.state.partida} onChange= {this.handlePartida}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>DESCRIPCIÓN</ControlLabel>
                                <FormControl type="text" value={this.state.descripcion} onChange= {this.handleDescripcion}/>
                            </div>
                        </div>
                        <div>
                            <Button style={{width: 200, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.save() }}>Guardar</Button>
                            <Button style={{width: 200, marginRight: 10, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Limpiar</Button>
                        </div>
                        </div>
                    </div>
                    {this.state.isLoading ? (
                        <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading}
                        />
                        ) : (
                        <PaginationProvider
                            pagination={
                            paginationFactory(options)
                            }
                        >
                            { contentTable }
                        </PaginationProvider>
                    )}
                </TabPanel>
                <TabPanel>
                    <div className="row" style={{marginTop: 30, marginBottom: 10}}>
                        <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>DESCRIPCIÓN</ControlLabel>
                                <FormControl type="text" value={this.state.descripcion2} onChange= {this.handleDescripcion2}/>
                            </div>
                        </div>
                        <div>
                            <Button style={{width: 200, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.save2() }}>Guardar</Button>
                            <Button style={{width: 200, marginRight: 10, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.cancelar2() }}>Limpiar</Button>
                        </div>
                        </div>
                    </div>
                    {this.state.isLoading2 ? (
                        <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading2}
                        />
                        ) : (
                        <PaginationProvider
                            pagination={
                            paginationFactory(options2)
                            }
                        >
                            { contentTable2 }
                        </PaginationProvider>
                    )}
                </TabPanel>
                <TabPanel>
                    <div className="row" style={{marginTop: 30, marginBottom: 10}}>
                        <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>DESCRIPCIÓN</ControlLabel>
                                <FormControl type="text" value={this.state.descripcion3} onChange= {this.handleDescripcion3}/>
                            </div>
                            <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>META</ControlLabel>
                                <FormControl type="text" value={this.state.meta} onChange= {this.handleMeta}/>
                            </div>
                            <div className="col-md-1" style={{marginBottom: 18}}>
                                <ControlLabel>FN</ControlLabel>
                                <FormControl type="text" value={this.state.fn} onChange= {this.handleFn}/>
                            </div>
                            <div className="col-md-1" style={{marginBottom: 18}}>
                                <ControlLabel>PF</ControlLabel>
                                <FormControl type="text" value={this.state.pf} onChange= {this.handlePf}/>
                            </div>
                            <div className="col-md-1" style={{marginBottom: 18}}>
                                <ControlLabel>SPF</ControlLabel>
                                <FormControl type="text" value={this.state.spf} onChange= {this.handleSpf}/>
                            </div>
                            <div className="col-md-1" style={{marginBottom: 18}}>
                                <ControlLabel>PRG</ControlLabel>
                                <FormControl type="text" value={this.state.prg} onChange= {this.handlePrg}/>
                            </div>
                            <div className="col-md-1" style={{marginBottom: 18}}>
                                <ControlLabel>ACTPROY</ControlLabel>
                                <FormControl type="text" value={this.state.actproy} onChange= {this.handleActproy}/>
                            </div>
                            <div className="col-md-1" style={{marginBottom: 18}}>
                                <ControlLabel>COMP</ControlLabel>
                                <FormControl type="text" value={this.state.comp} onChange= {this.handleComp}/>
                            </div>
                            <div className="col-md-1" style={{marginBottom: 18}}>
                                <ControlLabel>FIN</ControlLabel>
                                <FormControl type="text" value={this.state.fin} onChange= {this.handleFin}/>
                            </div>
                        </div>
                        <div>
                            <Button style={{width: 200, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.save3() }}>Guardar</Button>
                            <Button style={{width: 200, marginRight: 10, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.cancelar3() }}>Limpiar</Button>
                        </div>
                        </div>
                    </div>
                    {this.state.isLoading3 ? (
                        <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading3}
                        />
                        ) : (
                        <PaginationProvider
                            pagination={
                            paginationFactory(options3)
                            }
                        >
                            { contentTable3 }
                        </PaginationProvider>
                    )}
                </TabPanel>
                <TabPanel>
                    <div className="row" style={{marginTop: 30, marginBottom: 10}}>
                        <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>SALDO</ControlLabel>
                                <FormControl type="text" value={this.state.saldo} onChange= {this.handleSaldo} disabled={this.state.marcador}/>
                            </div>
                        </div>
                        <div>
                            <Button style={{width: 200, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.save4() }}>Guardar</Button>
                            <Button style={{width: 200, marginRight: 10, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.cancelar4() }}>Limpiar</Button>
                        </div>
                        </div>
                    </div>
                    {this.state.isLoading6 ? (
                        <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading6}
                        />
                        ) : (
                        <PaginationProvider
                            pagination={
                            paginationFactory(options6)
                            }
                        >
                            { contentTable6 }
                        </PaginationProvider>
                    )}
                </TabPanel>
                <TabPanel>
                    <div className="row" style={{marginTop: 30, marginBottom: 10}}>
                        <div className="col-md-6">
                          {this.state.isLoading4 ? (
                            <ClipLoader
                                css={override}
                                sizeUnit={"px"}
                                size={50}
                                color={'#123abc'}
                                loading={this.state.loading4}
                            />
                            ) : (
                            <PaginationProvider
                                pagination={
                                paginationFactory(options4)
                                }
                            >
                                { contentTable4 }
                            </PaginationProvider>
                          )}
                        </div>
                        <div className="col-md-6">
                        {this.state.isLoading5 ? (
                            <ClipLoader
                                css={override}
                                sizeUnit={"px"}
                                size={50}
                                color={'#123abc'}
                                loading={this.state.loading5}
                            />
                            ) : (
                            <PaginationProvider
                                pagination={
                                paginationFactory(options5)
                                }
                            >
                                { contentTable5 }
                            </PaginationProvider>
                          )}
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
            </div>
        </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ConsultaUtilitarios;