import React, { Component } from "react";
import { ControlLabel, Grid, Row, Col } from "react-bootstrap";
import { FormControl } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import Select from 'react-select';
import { cec } from 'store/Apis';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Plantillas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            path: 'plantillas',
            usuario: localStorage.getItem('nickname'),
            token: 'Bearer ' + localStorage.getItem('ConcurToken'),
            token2: 'Bearer ' + localStorage.getItem('AccessToken'),
            id: JSON.parse(localStorage.getItem('datos'))[0].Person,
            movimientos: [],
            ctxt_combo: "",
            ctxt_descripcion: "",
            ctxt_sigla:"",
            nid: "",
            item: "",
            ctxt_formato: "",
            isLoading: true,
            cnum_nota: {value: "--", label: "SIN NOTA"},
            tiposNota: [
                {value: "--", label: "SIN NOTA"},
                {value: "1N", label: "1 NOTA"},
                {value: "2N", label: "2 NOTAS"},
                {value: "3N", label: "3 NOTAS"}
            ],
            plantillaWord:[],
        }
        this.GetActionFormat = this.GetActionFormat.bind(this);
        this.handleDescripcion = this.handleDescripcion.bind(this);
        this.handleFormato = this.handleFormato.bind(this);
        this.handleCombo = this.handleCombo.bind(this);
        this.handleSiglas = this.handleSiglas.bind(this);
        //// Para subir archivo excel
        this.handleInputChange = this.handleInputChange.bind(this)
    }

        ///// Para subir archivo excel
        handleInputChange = (e) => {
            let file = e.target.files[0];            
            let reader = new FileReader();
            reader.onload = async function (e) {
            let data = new Uint8Array(e.target.result);
            //let workbook = XLSX.read(data, { type: "array" });
            //let worksheet = workbook.Sheets[workbook.SheetNames[0]];            
            this.setState({ plantillaWord: file });  
            console.log("Plantilla Word listo para subir:", file);
        }.bind(this);
            reader.readAsArrayBuffer(file);
        };

        handleDescripcion(event) { this.setState({ ctxt_descripcion: event.target.value }); }
        handleFormato(event) { this.setState({ ctxt_formato: event.target.value }); }
        handleCombo(event) { this.setState({ ctxt_combo: event.target.value }); }
        handleSiglas(event) {this.setState({ctxt_sigla:event.target.value}); }

        handleTipoNota= cnum_nota =>{
            this.setState(
                {cnum_nota},
            );
        };
     

    async componentDidMount() {
        if (localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null) {
            axios.get(validator + this.state.path + '/' + this.state.usuario, {
                headers: {
                    Authorization: this.state.token2
                }
            }).then((response) => {
                let permisos = response.data;
                if (permisos.codError != 1) {
                    this.props.history.goBack()
                } else {
                    this.listarPlantillas()
                }
            }, (error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    this.props.history.goBack()
                }
            });
        } else {
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }

    async listarPlantillas() {
        this.setState({ isLoading: true })
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${cec}` + 'aula/listPlantilla')
            .then((res) => {
                let lista = [];
                let lista2 = res.data.response;
                if (lista2 != null) {
                    lista2.map((data) => {
                        lista.push({
                            ctxt_combo: data.ctxt_combo,
                            ctxt_descripcion: data.ctxt_descripcion,
                            ctxt_formato: data.ctxt_formato,
                            item: data.item,
                            usuario: data.usuario,
                            nid: data.nid,
                            ctxt_sigla: data.ctxt_sigla,
                            cnum_nota: data.cnum_nota,
                            nota: data.cnum_nota=="--"?"SIN NOTA" :data.cnum_nota=="1N"?"1 NOTA":data.cnum_nota=="2N"?"2 NOTAS":"3 NOTAS",
                        })
                    })
                    this.setState({ movimientos: lista, isLoading: false })
                } else {
                    this.setState({ movimientos: [], isLoading: false })
                    this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })}
            }); 
        }

        a(nota){
            console.log(nota)
            if(nota=="--"){
                return {value: "--", label: "SIN NOTA"}

            } else if (nota=="1N"){
                return {value: "1N", label: "1 NOTA"}
            } else if (nota=="2N"){
                return  {value: "2N", label: "2 NOTAS"}   
            } else {
                return {value: "3N", label:"3 NOTAS"}
            }
        }

    async save() {
        if (this.state.ctxt_combo != '' && this.state.ctxt_descripcion != '' && this.state.ctxt_formato != ''&& this.state.nota !='' && this.state.ctxt_sigla !='') {
            if (this.state.nid == '') {
                this.guardar()
            } else {
                this.update()
            }
        } else {
            this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
        }
    }

    async guardar() {
        this.setState({ isLoading: true })
        let fromData = new FormData();
        fromData.append('descripcion', this.state.ctxt_descripcion);
        fromData.append('formato', this.state.ctxt_formato);
        fromData.append('combo', this.state.ctxt_combo);
        fromData.append('nota', this.state.cnum_nota.value);
        fromData.append('sigla', this.state.ctxt_sigla);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${cec}` + 'aula/insertPlantilla', fromData)
            .then((response) => {
                var datos = response.data
                if (datos.response != null) {
                    this.limpiar();
                    this.props.handleClick("tl", "info", "La plantilla ha sido registrada!", "pe-7s-smile")
                } else {
                    this.props.handleClick("tl", "error", "Hubo un problema con la plantilla!", "pe-7s-close")
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })
                }
            });
    }

    update() {
        this.setState({ isLoading: true })
        let fromData = new FormData();
        fromData.append('nid', this.state.nid);
        fromData.append('descripcion', this.state.ctxt_descripcion);
        fromData.append('formato', this.state.ctxt_formato);
        fromData.append('combo', this.state.ctxt_combo);
        fromData.append('sigla', this.state.ctxt_sigla);
        fromData.append('nota', this.state.cnum_nota.value);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${cec}` + 'aula/updatePlantilla', fromData)
            .then((response) => {
                var datos = response.data
                if (datos.response != null) {
                    this.limpiar();
                    this.props.handleClick("tl", "info", "El registro ha sido actualizado!", "pe-7s-smile")
                } else {
                    this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })
                }
            });
    }

    confirmarDelete = (data) => {
        confirmAlert({
            title: '¿Seguro que desea anular el registro?',
            message: 'Se anulará el registro seleccionado.',
            buttons: [
                {
                    label: 'Aceptar',
                    onClick: () => this.delete(data)
                },
                {
                    label: 'Cancelar',
                    onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
                }
            ]
        });
    };

    async delete(data) {
        this.setState({ isLoading: true })
        let fromData = new FormData();
        fromData.append('nid', data.nid);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${cec}` + 'aula/anularPlantilla', fromData)
            .then((response) => {
                var datos = response.data
                if (datos.response != null) {
                    if (datos.response.estado == 1) {
                        this.listarPlantillas();
                        this.props.handleClick("tl", "info", "La plantilla ha sido anulada!", "pe-7s-smile")
                    } else {
                        this.props.handleClick("tl", "error", "No se puede anular la plantilla!", "pe-7s-close")
                        this.setState({ isLoading: false })
                    }
                } else {
                    this.props.handleClick("tl", "error", "Hubo un problema con manejar!", "pe-7s-close")
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })
                }
            });
    }

    async limpiar() {
        this.listarPlantillas();
        this.cancelar();
    }

    async cancelar() {
        this.setState({ nid: "", ctxt_combo: "", ctxt_descripcion: "", ctxt_formato: "", ctxt_sigla:"" ,
        cnum_nota : {value: "--", label: "SIN NOTA"} })
    }

    async obtener(data) {
        this.setState({
            nid: data.nid,
            ctxt_combo: data.ctxt_combo,
            ctxt_descripcion: data.ctxt_descripcion,
            ctxt_formato: data.ctxt_formato,
            ctxt_sigla: data.ctxt_sigla,
            cnum_nota: this.a(data.cnum_nota),
            item: data.item
        })
    }
  
    GetActionFormat(cell, row) {
        if (row != undefined) {
            return (
                <div>
                    <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{ cursor: 'pointer', fontSize: 20 }} onClick={() => { this.obtener(row) }}>
                        <ReactTooltip />
                        <i className="fa fa-circle fa-stack-2x text-primary"></i>
                        <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{ fontSize: 15 }}></i>
                    </span>
                    <span data-tip="Anular Registro" className="fa-stack fa-2x" style={{ cursor: 'pointer', fontSize: 20 }} onClick={() => { this.confirmarDelete(row) }}>
                        <ReactTooltip />
                        <i className="fa fa-circle fa-stack-2x"></i>
                        <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{ fontSize: 15 }}></i>
                    </span>
                </div>
            );
        } else { }
    }

    render() {
        const columns = [
            { dataField: "item", text: "ITEM", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white", width: 100 }, headerAlign: (column, colIndex) => 'center', align: 'center' },
            { dataField: "ctxt_descripcion", text: "DESCRIPCION", filter: textFilter(), headerStyle: { backgroundColor: '#337AB7', color: "white", width: 400 }, headerAlign: (column, colIndex) => 'center', align: "center" },
            { dataField: "ctxt_formato", text: "FORMATO", filter: textFilter(), sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white", width: 200 }, headerAlign: (column, colIndex) => 'center', align: 'center' },
            { dataField: "ctxt_combo", text: "PLANTILLA", filter: textFilter(), headerStyle: { backgroundColor: '#337AB7', color: "white", width: 300 }, headerAlign: (column, colIndex) => 'center', align: 'center' },
            { dataField: "ctxt_sigla", text: "SIGLAS", filter: textFilter(), headerStyle: { backgroundColor: '#337AB7', color: "white", width: 100 }, headerAlign: (column, colIndex) => 'center', align: 'center' },
            { dataField: "nota", text: "NOTA", filter: textFilter(), headerStyle: { backgroundColor: '#337AB7', color: "white", width: 100 }, headerAlign: (column, colIndex) => 'center', align: 'center' },
            { dataField: "detail", text: "ACCIONES", headerStyle: { backgroundColor: '#337AB7', color: "white", width: 120 }, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat }
        ]
        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'Primera',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Última',
            showTotal: true,
            totalSize: this.state.movimientos.length
        };
        const contentTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <div>
                    <div>
                        <BootstrapTable
                            striped
                            hover
                            keyField="item"
                            data={this.state.movimientos}
                            columns={columns}
                            filter={filterFactory()}
                            noDataIndication="No existen registros para mostrar"
                            {...paginationTableProps}
                        />
                    </div>
                    <div style={{ float: "right" }}><PaginationListStandalone {...paginationProps} /></div>
                </div>
            </div>
        );
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <div style={{ borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5 }}>
                                <h4 className="title" style={{ fontWeight: 200, marginTop: 18, marginBottom: 25 }}>Plantillas</h4>
                                <div className="row">
                                    <div className="col-md-12" style={{ marginBottom: 18 }}>
                                        <ControlLabel>DESCRIPCION</ControlLabel>
                                        <FormControl type="text" value={this.state.ctxt_descripcion} onChange={this.handleDescripcion} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" style={{ marginBottom: 18 }}>
                                        <ControlLabel>FORMATO</ControlLabel>
                                        <FormControl type="text" value={this.state.ctxt_formato} onChange={this.handleFormato} />
                                    </div>
                                    <div className="col-md-6" style={{ marginBottom: 18 }}>
                                        <ControlLabel>PLANTILLA</ControlLabel>
                                        <FormControl type="text" value={this.state.ctxt_combo} onChange={this.handleCombo} />
                                    </div>
                                </div> 
                                <div className="row">
                                    <div className="col-md-3" style={{marginBottom: 18}}>
                                        <ControlLabel>NOTA</ControlLabel>
                                        <Select value={this.state.cnum_nota} onChange={this.handleTipoNota} options={this.state.tiposNota}/>
                                    </div>
                                    <div className="col-md-3" style={{ marginBottom: 18 }}>
                                        <ControlLabel>SIGLAS</ControlLabel>
                                        <FormControl type="text" value={this.state.ctxt_sigla} onChange={this.handleSiglas} />
                                    </div>
                                    <div className="col-md-2" style={{marginBottom:18}}>
                                        <ControlLabel>ARCHIVO WORD</ControlLabel>
                                        <input accept=".docx" className="upload-box" style={{ marginBottom:20, cursor:"pointer"}} required  type="file" id="file" name="file" class="file"  onChange={this.handleInputChange} />
                                    </div>
                                    <Button style={{ width: 200, marginTop: 25, marginBottom: 20, marginRight: 15 }} bsStyle="danger" fill type="submit" pullRight onClick={() => { this.cancelar() }}>Cancelar</Button>
                                    <Button style={{ width: 200, marginTop: 25, marginBottom: 20, marginRight: 10 }} bsStyle="success" fill type="submit" pullRight onClick={() => { this.save() }}>Guardar</Button>
                                
                                </div>
                                {this.state.isLoading ? (
                                    <ClipLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={50}
                                        color={'#123abc'}
                                        loading={this.state.loading}
                                    />
                                ) : (
                                    <PaginationProvider
                                        pagination={
                                            paginationFactory(options)
                                        }
                                    >
                                        {contentTable}
                                    </PaginationProvider>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default Plantillas;