import React, { Component } from "react";
import { ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Select from 'react-select';

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { paramTesoreria, tesoreria } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ConsultaLibro extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'libro',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      movimientos: [],
      periodo: '',
      mes: '',
      anio: '',
      mesx: '',
      years: [],
      isLoading: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);

    this.handlePeriodo = this.handlePeriodo.bind(this);
    this.handleMes = this.handleMes.bind(this);

  }

  handlePeriodo = periodo => {
    this.setState(
    { periodo },
    );
  };

  handleMes = mes => {
    this.setState(
    { mes },
    );
  };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              if(localStorage.getItem("periolibro") !== null){
                  this.setState({
                    anio: JSON.parse(localStorage.getItem('periolibro')).periodo,
                    mesx: JSON.parse(localStorage.getItem('periolibro')).mes
                  });
                  this.generarPeriodos2();
              }else{
                this.generarPeriodos();
              }
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  generarPeriodos(){
    let year =  2000;
    let cont = 26;
    let array = Array.from(new Array(cont),(val, index) => index + year);
    let arrayMes = [
      {"nid": "01", "descripcion": "ENERO"},
      {"nid": "02", "descripcion": "FEBRERO"},
      {"nid": "03", "descripcion": "MARZO"},
      {"nid": "04", "descripcion": "ABRIL"},
      {"nid": "05", "descripcion": "MAYO"},
      {"nid": "06", "descripcion": "JUNIO"},
      {"nid": "07", "descripcion": "JULIO"},
      {"nid": "08", "descripcion": "AGOSTO"},
      {"nid": "09", "descripcion": "SETIEMBRE"},
      {"nid": "10", "descripcion": "OCTUBRE"},
      {"nid": "11", "descripcion": "NOVIEMBRE"},
      {"nid": "12", "descripcion": "DICIEMBRE"},
    ]
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    if(this.state.periodo.label == undefined){
        array.map((data) => {
            cont2++
            lista.push({ 
                value: cont2,
                label: data,
            })
            if(data == (new Date()).getFullYear()){
                this.setState({periodo: {value: cont2, label: data}})
            }
        })
    }else{
        array.map((data) => {
            cont2++
            lista.push({ 
                value: cont2,
                label: data,
            })
        })
    }
    if(this.state.mes.value == undefined){
        arrayMes.map((data) => {
            cont3++
            lista2.push({
                item: data.cont3,
                value: data.nid,
                label: data.descripcion,
            })
            if(cont3 == (new Date()).getMonth() + 1){
                this.setState({mes: {value: data.nid, label: data.descripcion}})
            }
        })
    }else{
        arrayMes.map((data) => {
            cont3++
            lista2.push({
                item: data.cont3,
                value: data.nid,
                label: data.descripcion,
            })
        })
    }
    if(paramTesoreria.libro[0].anio == this.state.periodo.label && paramTesoreria.libro[1].mes == this.state.mes.value){
      let fechas = {
        "periodo": this.state.periodo.label,
        "mes": this.state.mes.value
      }
      localStorage.setItem('periolibro', JSON.stringify(fechas))
      this.setState({years: lista, meses: lista2, movimientos: paramTesoreria.libro[2].data, isLoading: false})
    }else{
      this.setState({years: lista, meses: lista2})
      this.listarMovimientos(0);
    }
  }

  generarPeriodos2(){
    let year =  2000;
    let cont = 26;
    let array = Array.from(new Array(cont),(val, index) => index + year);
    let arrayMes = [
      {"nid": "01", "descripcion": "ENERO"},
      {"nid": "02", "descripcion": "FEBRERO"},
      {"nid": "03", "descripcion": "MARZO"},
      {"nid": "04", "descripcion": "ABRIL"},
      {"nid": "05", "descripcion": "MAYO"},
      {"nid": "06", "descripcion": "JUNIO"},
      {"nid": "07", "descripcion": "JULIO"},
      {"nid": "08", "descripcion": "AGOSTO"},
      {"nid": "09", "descripcion": "SETIEMBRE"},
      {"nid": "10", "descripcion": "OCTUBRE"},
      {"nid": "11", "descripcion": "NOVIEMBRE"},
      {"nid": "12", "descripcion": "DICIEMBRE"},
    ]
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];

    array.map((data) => {
        cont2++
        lista.push({ 
          value: cont2,
          label: data,
        })
        if(data == this.state.anio){
            this.setState({periodo: {value: cont2, label: data}})
        }
    })
    arrayMes.map((data) => {
        cont3++
        lista2.push({
          item: data.cont3,
          value: data.nid,
          label: data.descripcion,
        })
        if(data.nid == this.state.mesx){
            this.setState({mes: {value: data.nid, label: data.descripcion}})
        }
    })
    if(paramTesoreria.libro[0].anio == this.state.periodo.label && paramTesoreria.libro[1].mes == this.state.mes.value){
      let fechas = {
        "periodo": this.state.periodo.label,
        "mes": this.state.mes.value
      }
      localStorage.setItem('periolibro', JSON.stringify(fechas))
      this.setState({years: lista, meses: lista2, movimientos: paramTesoreria.libro[2].data, isLoading: false})
    }else{
      this.setState({years: lista, meses: lista2})
      this.listarMovimientos(0);
    }
  }

  async listarMovimientos(param) {
      if(param == 1){
        this.setState({isLoading: true})
      }
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${tesoreria}` + 'comprobantes/listLibro?mes=' + this.state.mes.value + '&anio=' + this.state.periodo.label)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { 
                item: data.item,
                nidlibro: data.nid,
                cp: data.cp,
                registro: data.siaf,
                orden: data.orden,
                nombre: data.nombre,
                concepto: data.concepto,
                cheque: data.cheque,
                metas: data.metas,
                partidas: data.partidas,
                presupuestal: data.ctxt_presupuestal != null ? 'S/.    ' + data.ctxt_presupuestal.toFixed(2) : 'S/. 0.00', 
                patrimonial: data.ctxt_patrimonial != null ? 'S/.    ' + data.ctxt_patrimonial.toFixed(2) : 'S/. 0.00', 
                cuarta: data.ctxt_reten_cuarta != null ? 'S/.    ' + data.ctxt_reten_cuarta.toFixed(2) : 'S/. 0.00', 
                retenciones: data.ctxt_reten_otros != null ? 'S/.    ' + data.ctxt_reten_otros.toFixed(2) : 'S/. 0.00', 
            })
          })
          let fechas = {
            "periodo": this.state.periodo.label,
            "mes": this.state.mes.value
          }
          localStorage.setItem('periolibro', JSON.stringify(fechas))
          paramTesoreria.libro[0].anio = fechas.periodo
          paramTesoreria.libro[1].mes = fechas.mes
          paramTesoreria.libro[2].data = lista
          localStorage.setItem('data', JSON.stringify(paramTesoreria))
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async reporteExcel(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'comprobantes/exportarLibro?mes=' + this.state.mes.value + '&anio=' + this.state.periodo.label)
    .then((res) => {
      let lista = res.data.response;
      if(lista != null){
        this.setState({isLoading: false})
        window.open(lista.ubicacion)
        this.props.handleClick("tl", "info", "El libro ha sido descargado correctamente!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async detalle(data){
    let data2 = {
      "nidlibro": data.nidlibro,
      "origen": 'L'
    }
    localStorage.setItem('libro', JSON.stringify(data2))
    this.props.history.push('/editarlibro');
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Comprobante" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "cp", text: "N° DE CP", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nombre", text: "NOMBRE", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "concepto", text: "CONCEPTO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "cheque", text: "N° DE CHEQUE", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "registro", text: "N° SIAF", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "orden", text: "N° DE ORDEN", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "metas", text: "METAS", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "partidas", text: "PARTIDA", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "presupuestal", text: "PRESUPUESTAL", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "patrimonial", text: "PATRIMONIAL", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "cuarta", text: "RETENCIÓN CUARTA CATEGORÍA", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "retenciones", text: "OTRAS RETENCIONES", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Libro Contable de Comprobantes de Pago</h4>
              <div className="row">
                <div className="col-md-2" style={{marginBottom: 18}}>
                    <ControlLabel>PERIODO</ControlLabel>
                    <Select value={this.state.periodo} onChange={this.handlePeriodo} options={this.state.years}/>
                </div>
                <div className="col-md-2" style={{marginBottom: 18}}>
                    <ControlLabel>MES</ControlLabel>
                    <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses}/>
                </div>
                <div className="col-md-5" style={{marginBottom: 18}}>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 200, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.listarMovimientos(1) }}>Buscar Comprobantes</Button>
                    {this.state.movimientos.length != 0 ? (
                      <Button style={{width: 200, marginTop: 5, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.reporteExcel() }}>Exportar a Excel</Button>
                    ) : ( null )}
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ConsultaLibro;