import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { jurisdiccional, paramJurisdiccional} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ConsultarCarouselPortal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'utilitariosPortal',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.verimagen=this.verimagen.bind(this);
    this.estado=this.estado.bind(this);
  
  }
 
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.listarCarousel();            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
  validatorTipo(param){
    if(param != null){
      if(param == 1){
        return { value : "1" , label : "VERSIÓN NORMAL"}
      }else if(param == 2){
        return { value : "2" , label : "VERSIÓN QUECHUA"}
      }else {
        return { value : "3" , label : "AMBAS VERSIONES"}        
      }
    }
    }

  async listarCarousel(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${jurisdiccional}` + 'carousel/listCarouselPortal')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                ctxt_imagen: data.ctxt_imagen,
                flg_estado: data.flg_estado == 0 ? 'ACTIVO' : 'INACTIVO',
                identificador: data.identificador,
                item: data.item,
                nid: data.nid,
                nombreIden: data.nombreIden,
                combo: this.validatorTipo(data.identificador)
            })
        })
         this.setState({movimientos: lista, isLoading: false})
        
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
    .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
    });
  }

  confirmarDelete = (data) => {
    var titulo = "";
    var mensaje = "";
    if(data.flg_estado == 'ACTIVO'){
        titulo = "¿Seguro que desea anular el registro?";
        mensaje = "Se anulará el registro seleccionado.";
    }else{
        titulo = "¿Seguro que desea activar el registro?";
        mensaje = "Se activará el registro seleccionado";
    }
    confirmAlert({
      title: titulo,
      message: mensaje,
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'carousel/manejarCarouselPortal', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 1){
            this.listarCarousel();
            if(data.flg_estado == 'ACTIVO'){
               this.props.handleClick("tl", "info", "El registro ha sido anulado!", "pe-7s-smile")
            }else{
              this.props.handleClick("tl", "info", "El registro ha sido activado!", "pe-7s-smile")

            }
        }else{
            this.props.handleClick("tl", "error", "No se puede anular el registro!", "pe-7s-close")
            this.setState({isLoading: false})
        }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async detalle(data){
    paramJurisdiccional.carruselPortal[0].data = data
    localStorage.setItem('jurisdiccional', JSON.stringify(paramJurisdiccional))
    this.props.history.push('/editarCarouselPortal');
  }

  async detalle2(){
    this.props.history.push('/crearCarouselPortal');
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
             
              {row.flg_estado == "INACTIVO" ? (
              <span data-tip="Activar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-power-off fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              ) : (
                <span data-tip="Anular Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
                
                )}
          </div>
      );
    }else{}
  }

  verimagen(cell, row) {
    if(row != undefined){
      return(
            <span data-tip="Ver Imagen" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(row.ctxt_imagen) }}>
               <img src={row.ctxt_imagen} alt="" style={{width:"500px", height:"250px"}}></img>
              </span>
         );
    }else{}
  }

  abrirImagen(ruta){
    window.open(ruta, '_blank');
  } 

  async regresar(){
    this.props.history.push('/utilitariosPortal');}
  
  estado(cell, row) {
      if(row != undefined){
        return (
            <div>
              {row.flg_estado == "ACTIVO" ? (
                <Button bsStyle="success" type="submit" style={{width: 100}}>{row.flg_estado}</Button>
               ) : (
                <Button bsStyle="danger" type="submit" style={{width: 100}}>{row.flg_estado}</Button>
                )}
            </div>
        );
      }else{}
    }

  render() {
    const columns = [
    {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "imagen", text: "IMAGEN", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.verimagen},
    {dataField: "nombreIden", text: "TIPO", filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "flg_estado", text: "ESTADO", filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter :this.estado},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de imágenes del Carousel</h4>
              <div className="row">
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 250, marginTop: 5, marginRight: 10,marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                    <Button style={{width: 250, marginTop: 5,marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.detalle2() }}>Nueva Imagen</Button>   
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ConsultarCarouselPortal;