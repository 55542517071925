import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import NotificationSystem from "react-notification-system";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import { style } from "variables/Variables.jsx";

import auth0Client from 'login/Auth';
import axios from 'axios';
import { sisperplus } from 'store/Apis'

import routes from "routes.js";

import PageNotFound from "otros/PageNotFound.jsx";

import moment from 'moment'
import Calendar from "components/Calendar/Calendar.jsx";
import { paramTesoreria, tesoreria } from 'store/Apis'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      _notificationSystem: null,
      image: '',
      color: '',
      hasImage: null,
      fixedClasses: "",
      opera: localStorage.getItem('operaciones') != null ? JSON.parse(localStorage.getItem('operaciones')) : [],
      token2: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0
    };
  }

  handleNotificationClick = (position, level, texto, icono) => {
    // level = "success";
    // level = "warning";
    // level = "error";
    // level = "info";
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className={icono} />,
      message: (
        // <div>
        //   Sistema de <b>Personal</b> - {texto}
        // </div>
        <div>
          <b>SIAJ</b> - {texto}
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 5
    });
  };

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          this.getBrandText() != 'NOTFOUND' ? (
            <Route
              path={prop.path}
              render={props => (
                <prop.component
                  {...props}
                  handleClick={this.handleNotificationClick}
                />
              )}
              key={key}
            />) : (<Route exact component={PageNotFound}/>)
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "NOTFOUND";
  };

  handleImageClick = (image, nidColor, nidImagen, visible) => {
    if(visible){visible = 1 }else{visible = 0}
    this.setState({ image: image });
    let fromData = new FormData();
    fromData.append('color', nidColor);
    fromData.append('imagen', nidImagen);
    fromData.append('visible', visible);
    fromData.append('usuario', this.state.usuario);
    axios.post(`${sisperplus}` + 'personalizado/actualizar', fromData,                 
    {headers: {
      Authorization: this.state.token
    }}
    ).then((response) => {})
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
        Swal.close(); 
      }else{
        console.log(error);
      }
    });
  };

  handleColorClick = (color, nidColor, nidImagen, visible) => {
    if(visible){visible = 1 }else{visible = 0}
    this.setState({ color: color });
    let fromData = new FormData();
    fromData.append('color', nidColor);
    fromData.append('imagen', nidImagen);
    fromData.append('visible', visible);
    fromData.append('usuario', this.state.usuario);
    axios.post(`${sisperplus}` + 'personalizado/actualizar', fromData,                 
    {headers: {
      Authorization: this.state.token
    }}
    ).then((response) => {})
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
        Swal.close(); 
      }else{
        console.log(error);
      }
    });
  };

  handleHasImage = hasImage => {
    this.setState({ hasImage: hasImage });
  };

  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
       this.cargaImagenes();
    }
  }

  cargaImagenes(){
    Swal.fire({
      title: "Cargando componentes",
      text: "Espere por favor...",
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
      allowOutsideClick:false
    })
    let fromData = new FormData();
    fromData.append('color', '1');
    fromData.append('imagen', '6');
    fromData.append('visible', '1');
    fromData.append('usuario', this.state.usuario);
    axios.post(`${sisperplus}` + 'personalizado/registrar', fromData,                 
    {headers: {
      Authorization: this.state.token
    }}
    ).then((response) => {
      this.setState({ _notificationSystem: this.refs.notificationSystem });
      this.obtenerImagen();
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
        Swal.close(); 
      }else{
        console.log(error);
        Swal.close(); 
      }
    });
  }

  obtenerImagen(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'personalizado/listPersonalizado?usuario=' + this.state.usuario)
    .then((res) => {
      this.setState({
        color: res.data.response[0].color,
        image: require('assets/img/' + res.data.response[0].imagen),
        hasImage: res.data.response[0].flg_visible
      })
      if(localStorage.getItem('datos') == null){
        this.detailPerson();
      }else{
        let cont = 0;
        this.state.opera.map((data) => {
          if(data.idOperacion == 707){
             cont = 1;
          }
        })
        if(cont == 1){
          if(localStorage.getItem('data') != null || localStorage.getItem('data') != undefined){
            this.enrutar(JSON.parse(localStorage.getItem('data')))
          }else{
            this.serviciosTesoreria()
          }
        }else{
          Swal.close(); 
        }
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
        Swal.close(); 
      }else{
        console.log(error);
        this.setState({
          isLoading: false
        })
        Swal.close(); 
      }
    });
  }

  enrutar(item){
    paramTesoreria.libro[0].anio = item.libro[0].anio
    paramTesoreria.libro[1].mes = item.libro[1].mes
    paramTesoreria.libro[2].data = item.libro[2].data
    paramTesoreria.cheques[0].fechaini = item.cheques[0].fechaini
    paramTesoreria.cheques[1].fechafin = item.cheques[1].fechafin
    paramTesoreria.cheques[2].data = item.cheques[2].data
    paramTesoreria.comprobantes[0].fechaini = item.comprobantes[0].fechaini
    paramTesoreria.comprobantes[1].fechafin = item.comprobantes[1].fechafin
    paramTesoreria.comprobantes[2].data = item.comprobantes[2].data
    paramTesoreria.cajachica[0].fechaini = item.cajachica[0].fechaini
    paramTesoreria.cajachica[1].fechafin = item.cajachica[1].fechafin
    paramTesoreria.cajachica[2].data = item.cajachica[2].data
    paramTesoreria.areas[0].data = item.areas[0].data
    paramTesoreria.areas[1].data2 = item.areas[1].data2
    paramTesoreria.trabajadores[0].data = item.trabajadores[0].data
    paramTesoreria.trabajadores[1].data2 = item.trabajadores[1].data2
    paramTesoreria.metas[0].data = item.metas[0].data
    paramTesoreria.tiposcaja[0].data = item.tiposcaja[0].data
    paramTesoreria.beneficiarios[0].data = item.beneficiarios[0].data
    paramTesoreria.conceptos[0].data = item.conceptos[0].data 
    paramTesoreria.clasificadores[0].data = item.clasificadores[0].data
    paramTesoreria.codificaciones[0].data = item.codificaciones[0].data
    paramTesoreria.retenciones[0].data = item.retenciones[0].data
    paramTesoreria.retenciones[1].data2 = item.retenciones[1].data2
    Swal.close(); 
  }

  detailPerson() {
    let datos2 = [{
      "Person": "000000",
      "area": "NO DEFINIDO",
      "cargo": "NO DEFINIDO",
      "item": 1,
      "nombre": "NO DEFINIDO",
      "nidarea": "NO DEFINIDO"
    }];
    let dni =  localStorage.getItem('dni')
    axios.get(sisperplus + 'perfil/detailperson?dni=' + dni, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('AccessToken')
      }
    }).then((response) => {
      let datos = response.data.response;
      if(datos.length != 0){
        localStorage.setItem('datos', JSON.stringify(datos))
      }else{
        localStorage.setItem('datos', JSON.stringify(datos2))
      }
      this.serviciosTesoreria()
    },(error) => {
      console.log(error)
      Swal.close(); 
    });
  }

  serviciosTesoreria(){
    let cont = 0;
    this.state.opera.map((data) => {
      if(data.idOperacion == 707){
         cont = 1;
      }
    })
    if(cont == 1){
      this.generarImputs()
    }else{
      Swal.close(); 
    }
  }

  generarImputs(){
    let anio = (new Date()).getFullYear()
    let mes = (new Date()).getMonth() + 1  < 10 ? "0" + ((new Date()).getMonth() + 1).toString() : (new Date()).getMonth() + 1
    let fechaini = moment(moment().startOf('week').toDate()).format('YYYY-MM-DD')
    let fechafin = moment(moment().endOf('week').toDate()).format('YYYY-MM-DD')
    this.obtenerDatosIniciales(mes, anio, fechaini, fechafin)
  }

  obtenerDatosIniciales(mes, anio, fechaini, fechafin){
    Swal.fire({
      title: "Actualizando registros",
      text: "Espere por favor...",
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
      allowOutsideClick:false
    })
    axios.defaults.headers.common['Authorization'] = this.state.token2
    axios.get(`${tesoreria}` + 'tipos/listDatosIniciales?anio=' + anio + '&mes=' + mes + '&fechaini=' + fechaini + '&fechafin=' + fechafin)
    .then((res) => {
      let listLibro = [];
      let listCheques = [];
      let listComprobantes = [];
      let listCajaChica = [];
      let listAreas = [];
      let listAreasAct = [];
      let listTrabajadores = [];
      let listTrabajadoresAct = [];
      let listMetas = [];
      let listTiposCaja = [];
      let listBeneficiarios = [];
      let listConceptos = [];
      let listClasificadores = [];
      let listCodificaciones = [];
      let listRetenciones = [];
      let listRetenciones2 = [{value: 0, label: 'NINGUNO'}];
      let lista2 = res.data.response;
      if(lista2.listLibro.length != 0){
        lista2.listLibro.map((data) => {
          listLibro.push(
            { 
              item: data.item,
              nidlibro: data.nid,
              cp: data.cp,
              registro: data.siaf,
              orden: data.orden,
              nombre: data.nombre,
              concepto: data.concepto,
              cheque: data.cheque,
              metas: data.metas,
              partidas: data.partidas,
              presupuestal: data.ctxt_presupuestal != null ? 'S/.    ' + data.ctxt_presupuestal.toFixed(2) : 'S/. 0.00', 
              patrimonial: data.ctxt_patrimonial != null ? 'S/.    ' + data.ctxt_patrimonial.toFixed(2) : 'S/. 0.00', 
              cuarta: data.ctxt_reten_cuarta != null ? 'S/.    ' + data.ctxt_reten_cuarta.toFixed(2) : 'S/. 0.00', 
              retenciones: data.ctxt_reten_otros != null ? 'S/.    ' + data.ctxt_reten_otros.toFixed(2) : 'S/. 0.00', 
          })
        })
        paramTesoreria.libro[0].anio = anio
        paramTesoreria.libro[1].mes = mes
        paramTesoreria.libro[2].data = listLibro
      }else{
        paramTesoreria.libro[0].anio = anio
        paramTesoreria.libro[1].mes = mes
        paramTesoreria.libro[2].data = []
      }
      if(lista2.listCheques.length != 0){
        lista2.listCheques.map((data) => {
          listCheques.push(
            { 
                item: data.item,
                numero: data.cnum_numero, 
                nombre: data.ctxt_nombre,
                concepto: data.ctxt_concepto,
                monto: data.ctxt_monto != null ? 'S/.    ' + data.ctxt_monto.toFixed(2) : 'S/. 0.00', 
                liquido: data.ctxt_monto != null ? 'S/.    ' + data.ctxt_monto.toFixed(2) : 'S/. 0.00', 
                fecha: Calendar.calendario12(data.dfec_ingreso)
          })
        })
        paramTesoreria.cheques[0].fechaini = fechaini
        paramTesoreria.cheques[1].fechafin = fechafin
        paramTesoreria.cheques[2].data = listCheques
      }else{
        paramTesoreria.cheques[0].fechaini = fechaini
        paramTesoreria.cheques[1].fechafin = fechafin
        paramTesoreria.cheques[2].data = []
      }
      if(lista2.listComprobantes.length != 0){
        lista2.listComprobantes.map((data) => {
          listComprobantes.push(
            { 
              item: data.item,
              nidmovimiento: data.nid,
              numero: data.cnum_numero, 
              cheque: data.ctxt_cheque_carta,
              registro: data.cnum_siaf,
              orden: data.cnum_orden,
              documento: data.cnum_documento,
              nombre: data.ctxt_nombre,
              concepto: data.ctxt_concepto,
              liquido: data.cnum_liquido != null ? 'S/.    ' + data.cnum_liquido.toFixed(2) : 'S/. 0.00', 
              fecha: Calendar.calendario12(data.dfec_ingreso),
              fecha2: data.dfec_ingreso,
              activo: data.item == 1 ? '1' : '0',
              documentox: data.documento
          })
        })
        paramTesoreria.comprobantes[0].fechaini = fechaini
        paramTesoreria.comprobantes[1].fechafin = fechafin
        paramTesoreria.comprobantes[2].data = listComprobantes
      }else{
        paramTesoreria.comprobantes[0].fechaini = fechaini
        paramTesoreria.comprobantes[1].fechafin = fechafin
        paramTesoreria.comprobantes[2].data = []
      }
      if(lista2.listCajaChica.length != 0){
        lista2.listCajaChica.map((data) => {
          listCajaChica.push(
            { 
              item: data.item,
              nidmovimiento: data.nid,
              numero: data.cnum_numero, 
              tipocaja: data.tipocaja,
              numerocaja: data.cnum_numero_caja,
              documento: data.cnum_documento,
              nombre: data.ctxt_nombre,
              concepto: data.ctxt_concepto,
              liquido: data.cnum_liquido,
              totalgastos: data.cnum_total_gastos != null ? 'S/.    ' + data.cnum_total_gastos.toFixed(2) : 'S/. 0.00', 
              fecha: Calendar.calendario12(data.dfec_ingreso),
              meta: data.meta,
              area: data.area,
              responsable: data.responsable,
              activo: data.item == 1 ? '1' : '0'
          })
        })
        paramTesoreria.cajachica[0].fechaini = fechaini
        paramTesoreria.cajachica[1].fechafin = fechafin
        paramTesoreria.cajachica[2].data = listCajaChica
      }else{
        paramTesoreria.cajachica[0].fechaini = fechaini
        paramTesoreria.cajachica[1].fechafin = fechafin
        paramTesoreria.cajachica[2].data = []
      }
      if(lista2.listAreas.length != 0){
        lista2.listAreas.map((data) => {
          listAreas.push(
            { 
              value: data.nid_area,
              label: data.ctxt_descripcion
          })
        })
        paramTesoreria.areas[0].data = listAreas
      }else{
        paramTesoreria.areas[0].data = []
      }
      if(lista2.listAreasAct.length != 0){
        lista2.listAreasAct.map((data) => {
          listAreasAct.push(
            { 
              value: data.nid_area,
              label: data.ctxt_descripcion
          })
        })
        paramTesoreria.areas[1].data2 = listAreasAct
      }else{
        paramTesoreria.areas[1].data2 = []
      }
      if(lista2.listTrabajadores.length != 0){
        lista2.listTrabajadores.map((data) => {
          listTrabajadores.push(
            { 
              value: data.nid,
              label: data.ctxt_descripcion 
          })
        })
        paramTesoreria.trabajadores[0].data = listTrabajadores
      }else{
        paramTesoreria.trabajadores[0].data = []
      }
      if(lista2.listTrabajadoresAct.length != 0){
        lista2.listTrabajadoresAct.map((data) => {
          listTrabajadoresAct.push(
            { 
              value: data.nid,
              label: data.ctxt_descripcion 
          })
        })
        paramTesoreria.trabajadores[1].data2 = listTrabajadoresAct
      }else{
        paramTesoreria.trabajadores[1].data2 = []
      }
      if(lista2.listMetas.length != 0){
        lista2.listMetas.map((data) => {
          listMetas.push(
            { 
              value: data.nid,
              label: data.codigo,
          })
        })
        paramTesoreria.metas[0].data = listMetas
      }else{
        paramTesoreria.metas[0].data = []
      }
      if(lista2.listTiposCaja.length != 0){
        lista2.listTiposCaja.map((data) => {
          listTiposCaja.push(
            { 
              value: data.nid,
              label: data.nombre,
          })
        })
        paramTesoreria.tiposcaja[0].data = listTiposCaja
      }else{
        paramTesoreria.tiposcaja[0].data = []
      }
      if(lista2.listBeneficiarios.length != 0){
        lista2.listBeneficiarios.map((data) => {
          listBeneficiarios.push(
            { 
              value: data.nid,
              label: data.cnum_documento + ' (' + data.ctxt_descripcion + ')', 
              tipo: data.nid_tipo,
              documento: data.cnum_documento,  
              clase: data.ctxt_descripcion
          })
        })
        paramTesoreria.beneficiarios[0].data = listBeneficiarios
      }else{
        paramTesoreria.beneficiarios[0].data = []
      }
      if(lista2.listConceptos.length != 0){
        lista2.listConceptos.map((data) => {
          listConceptos.push(
            { 
              value: data.nid,
              label: data.ctxt_descripcion, 
          })
        })
        paramTesoreria.conceptos[0].data = listConceptos
      }else{
        paramTesoreria.conceptos[0].data = []
      }
      if(lista2.listClasificadores.length != 0){
        lista2.listClasificadores.map((data) => {
          listClasificadores.push(
            { 
              value: data.nid,
              label: data.ctxt_descripcion, 
          })
        })
        paramTesoreria.clasificadores[0].data = listClasificadores
      }else{
        paramTesoreria.clasificadores[0].data = []
      }
      if(lista2.listCodificaciones.length != 0){
        lista2.listCodificaciones.map((data) => {
          listCodificaciones.push(
            { 
              value: data.nid,
              label: data.ctxt_meta + " " + data.ctxt_fn + " " + data.ctxt_pf + " " + data.ctxt_spf + " " + data.ctxt_prg + " " + data.ctxt_actproy + " " + data.ctxt_comp + " " + data.ctxt_fin, 
          })
        })
        paramTesoreria.codificaciones[0].data = listCodificaciones
      }else{
        paramTesoreria.codificaciones[0].data = []
      }
      if(lista2.listRetenciones.length != 0){
        lista2.listRetenciones.map((data) => {
          listRetenciones.push(
            { 
              value: data.nid,
              label: data.ctxt_descripcion, 
          })
          listRetenciones2.push(
            { 
              value: data.nid,
              label: data.ctxt_descripcion, 
          })
        })
        paramTesoreria.retenciones[0].data = listRetenciones
        paramTesoreria.retenciones[1].data2 = listRetenciones2
      }else{
        paramTesoreria.retenciones[0].data = []
        paramTesoreria.retenciones[1].data2 = []
      }
      localStorage.setItem('data', JSON.stringify(paramTesoreria))
      Swal.close(); 
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
        Swal.close(); 
      }else{
        console.log(error);
        Swal.close(); 
      }
    });
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  render() {
    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar {...this.props} routes={routes} 
        image={this.state.image}
        color={this.state.color}
        hasImage={this.state.hasImage}/>
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <Footer />
          <FixedPlugin
            handleImageClick={this.handleImageClick}
            handleColorClick={this.handleColorClick}
            handleHasImage={this.handleHasImage}
            bgHasImage={this.state["hasImage"]}
            bgColor={this.state["color"]}
            bgImage={this.state["image"]}
            mini={this.state["mini"]}
            handleFixedClick={this.handleFixedClick}
            fixedClasses={this.state.fixedClasses}
          />
        </div>
      </div>
    );
  }
}

export default Admin;
