import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import Select from 'react-select';

import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import Autocomplete from "react-autocomplete-select";
import Calendar from "components/Calendar/Calendar.jsx";

import moment from 'moment'

import axios from 'axios';
import { paramTesoreria, tesoreria } from 'store/Apis'

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class NuevoComprobante extends Component {

  inputRef = React.createRef();
  inputRef2 = React.createRef();
  
  constructor(props) {
    super(props);
    this.state = {
      path: 'comprobantes',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      selectedOption3: "",
      selectedOption5: "",
      selectedOption6: "",
      selectedOption7: "",
      beneficiarios: [],
      conceptos: [],
      movimiento: '',
      numero: '',
      fecha: moment(new Date()).format('YYYY-MM-DD'),
      correlativo: '',
      numerocaja: '',
      beneficiario: '',
      documento: '--',
      concepto: '',
      marcador1: false,
      marcador2: false,
      isLoading1: true,
      isLoading2: true,
      ordenservicio: '',
      registrosiaf: '',
      tiposgiro: [
        {value: "CHEQUE", label: "CHEQUE"},
        {value: "CO.", label: "CARTA ORDEN"},
        {value: "O-S/CH.", label: "OPERACIONES SIN CHEQUE"},
        {value: "CE.", label: "CARTA ORDEN ELECTRÓNICA"},
        {value: "OPE.", label: "ORDEN DE PAGO ELECTRÓNICO"},
        {value: "CCI.", label: "TRANSFERENCIA A CUENTA DE TERCEROS (CCI)"},
        {value: "PE. AFP", label: "PAGO ELECTRÓNICO DE APORTES AFP"},
        {value: "--", label: "OTROS"} 
      ],
      tiposorden: [
        {value: "O/C.", label: "ORDEN DE COMPRA"},
        {value: "O/S.", label: "ORDEN DE SERVICIO"},
        {value: "PLA.", label: "PLANILLA"},
        {value: "--", label: "OTROS"}
      ],
      selectedOption: {value: "CHEQUE", label: "CHEQUE"},
      selectedOption2: {value: "O/C.", label: "ORDEN DE COMPRA"},
    }

    this.handleFecha = this.handleFecha.bind(this);
    this.handleCorrelativo = this.handleCorrelativo.bind(this);
    this.handleNumerocaja = this.handleNumerocaja.bind(this);
    this.handleBeneficiario = this.handleBeneficiario.bind(this);
    this.handleDocumento = this.handleDocumento.bind(this);
    this.handleConcepto = this.handleConcepto.bind(this);
    this.handleOrdenservicio = this.handleOrdenservicio.bind(this);
    this.handleRegistrosiaf = this.handleRegistrosiaf.bind(this);
  }

  handleChange = selectedOption => {
    this.setState(
    { selectedOption },
    );
  };

  handleChange2 = selectedOption2 => {
    this.setState(
    { selectedOption2 },
    );
  };

  handleChange6 = selectedOption6 => {
    this.setState(
    { selectedOption6 },
    );
  };

  handleChange7 = selectedOption7 => {
    this.setState(
    { selectedOption7 },
    );
  };

  handleFecha(event) {
    this.setState({fecha: event.target.value});
  }

  handleCorrelativo(event) {
    this.setState({correlativo: event.target.value});
  }

  handleNumerocaja(event) {
    this.setState({numerocaja: event.target.value.toUpperCase()});
  }

  handleBeneficiario(event) {
    this.setState({beneficiario: event.target.value.toUpperCase()});
  }

  handleDocumento(event) {
    this.setState({documento: event.target.value});
  }

  handleConcepto(event) {
    this.setState({concepto: event.target.value});
  }

  handleOrdenservicio(event) {
    this.setState({ordenservicio: event.target.value.toUpperCase()});
  }

  handleRegistrosiaf(event) {
    this.setState({registrosiaf: event.target.value});
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.obtenerCorrelativo(0);
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async obtenerCorrelativo(param){
    if(this.state.fecha != ''){
      let fromData = new FormData();
      fromData.append('tipo', 0);
      fromData.append('fecha',  this.state.fecha);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'comprobantes/obtenerCorrelativo', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          this.setState({numero: datos.response.codigo, correlativo: datos.response.codigo}) 
          if(param == 0){
            this.obtenerDatos();
          }
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading1: false, isLoading2: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading1: false, isLoading2: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe seleccionar una fecha!", "pe-7s-info")
    }
  }

  async obtenerDatos(){
    if(paramTesoreria.beneficiarios[0].data.length != 0){
      this.setState({
        beneficiarios: paramTesoreria.beneficiarios[0].data, 
        conceptos: paramTesoreria.conceptos[0].data, 
        isLoading1: false, 
        isLoading2: false, 
        apunta3: true, 
        apunta2: true,
        apunta: true, 
        marcador1: false, 
        marcador2: false, 
        beneficiario: "", 
        documento: "--", 
        concepto: ""
      })
    }else{
      this.listarBeneficiarios()
    }
  }

  async listarBeneficiarios() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'beneficiarios/listBeneficiarios')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.cnum_documento + ' (' + data.ctxt_descripcion + ')', 
              tipo: data.nid_tipo,
              documento: data.cnum_documento,  
              clase: data.ctxt_descripcion   
            })
        })
        this.setState({
          beneficiarios: lista, 
          // selectedOption6: {
          //   value: lista[0].value, 
          //   label: lista[0].label, 
          //   tipo: lista[0].tipo, 
          //   documento: lista[0].documento,
          //   clase: lista[0].clase
          // }
        })
        paramTesoreria.beneficiarios[0].data = lista
        this.listarConceptos()
      }else{
        this.setState({beneficiarios: [], isLoading1: false, isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading1: false, isLoading2: false})
      }
    });
  }

  async listarConceptos() {
    this.setState({
      isLoading1: false, 
      isLoading2: false, 
      apunta3: true, 
      apunta2: true,
      apunta: true, 
      marcador1: false, 
      marcador2: false, 
      beneficiario: "", 
      documento: "--", 
      concepto: ""
    })
    localStorage.setItem('data', JSON.stringify(paramTesoreria))
    /*
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'beneficiarios/listConceptos')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2.length != 0){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_descripcion,       
            })
        })
        this.setState({
            conceptos: lista, 
            // selectedOption7: {value: lista[0].value, label: lista[0].label},
            isLoading1: false, 
            isLoading2: false, 
            apunta3: true, 
            apunta2: true,
            apunta: true, 
            marcador1: false, 
            marcador2: false, 
            beneficiario: "", 
            documento: "--", 
            concepto: ""
        })
      }else{
        this.setState({conceptos: [], isLoading1: false, isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading1: false, isLoading2: false})
      }
    });
    */
  }

  async limpiar(nid){
    let data = {
        "nidmovimiento": nid
    }
    localStorage.setItem('movimientocomp', JSON.stringify(data))
    this.props.history.push('/editarcomprobante');
  }

  async agregar(){
    if(this.state.marcador1 == true){
      this.setState({marcador1: false})
    }else{
      this.setState({marcador1: true})
    }
  }

  async agregar2(){
    if(this.state.marcador2 == true){
      this.setState({marcador2: false})
    }else{
      this.setState({marcador2: true})
    }
  }

  validaCadena(numero){
    if(numero.length == 1){
      return "0000" + numero
    }else if(numero.length == 2){
      return "000" + numero
    }else if(numero.length == 3){
      return "00" + numero
    }else if(numero.length == 4){
      return "0" + numero
    }else if(numero.length == 5){
      return numero
    }else{
      return "00000"
    }
  }

  validaCadena2(numero){
    if(numero == ""){
      return "--"
    }else{
      if(Number.isInteger(numero) == false){
        return this.validaCadena(numero)
      }else{
        return "--"
      }
    }
  }

  validaCadena3(numero){
    if(numero == ""){
      if(this.state.selectedOption.value == "--"){
        return "--"
      }else{
        return this.state.selectedOption.value
      }
    }else{
      if(this.state.selectedOption.value == "--"){
        return numero
      }else{
        return this.state.selectedOption.value + " N° " + numero
      }
    }
  }

  validaCadena4(numero){
    if(numero == ""){
      return this.state.selectedOption2.value
    }else{
      return this.state.selectedOption2.value + " N° " + numero
    }
  }

  // async guardar2(){
  //   if(this.state.correlativo != '' && this.validaCadena(this.state.correlativo) != "00000"){
  //     if(this.state.selectedOption6 != '' && this.state.concepto != '' && this.state.fecha != ''){
  //       this.setState({isLoading1: true, isLoading2: true})
  //       let fromData = new FormData();
  //       fromData.append('correlativo', this.validaCadena(this.state.correlativo));
  //       fromData.append('fecha',  this.state.fecha);
  //       fromData.append('cheque', this.validaCadena3(this.state.numerocaja).toUpperCase());
  //       fromData.append('orden',  this.validaCadena4(this.state.ordenservicio).toUpperCase());
  //       fromData.append('registro', this.validaCadena2(this.state.registrosiaf));
  //       fromData.append('codificacion', '');
  //       fromData.append('identificador', '');
  //       fromData.append('contabilidad', '');
  //       fromData.append('deduccion', '');
  //       fromData.append('estadistica', '');
  //       fromData.append('usuario', this.state.usuario);
  //       axios.defaults.headers.common['Authorization'] = this.state.token
  //       axios.post(`${tesoreria}` + 'comprobantes/insertarComprobante', fromData)
  //       .then((response) => {
  //         var datos = response.data
  //         if(datos.response != null){
  //           if(datos.response.codigo == "0"){
  //             this.setState({isLoading1: false, isLoading2: false})
  //             this.props.handleClick("tl", "error", "El correlativo ya existe!", "pe-7s-close")
  //           }else{
  //             let nid = datos.response.codigo;
  //             this.guardar(nid);
  //           }
  //         }else{
  //           this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
  //           this.setState({isLoading1: false, isLoading2: false})
  //         }
  //         })
  //         .catch((error) =>{ 
  //         if(auth0Client.isAuthenticated() === true){
  //             auth0Client.signOut()
  //             this.props.history.replace('/');
  //         }else{
  //             console.log(error);
  //             this.setState({isLoading1: false, isLoading2: false})
  //         }
  //       });
  //     }else{
  //       this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
  //     }
  //   }else{
  //     this.props.handleClick("tl", "warning", "Debe ingresar el correlativo correctamente!", "pe-7s-info")
  //   }
  // }

  // async guardar(nid){
  //     let fromData = new FormData();
  //     fromData.append('beneficiario',  this.state.selectedOption6.value);
  //     fromData.append('tipo', this.state.selectedOption6.tipo);
  //     fromData.append('documento', this.state.selectedOption6.documento);
  //     fromData.append('nombre', this.state.selectedOption6.clase);
  //     // fromData.append('nidconcepto',  this.state.selectedOption7.value);
  //     // fromData.append('concepto', this.state.selectedOption7.label);
  //     fromData.append('nidconcepto',  1189);
  //     fromData.append('concepto', this.state.concepto);
  //     fromData.append('comprobante', nid);
  //     fromData.append('usuario', this.state.usuario);
  //     axios.defaults.headers.common['Authorization'] = this.state.token
  //     axios.post(`${tesoreria}` + 'detallecomprobante/insertarIdentificador', fromData)
  //     .then((response) => {
  //       var datos = response.data
  //       if(datos.response != null){
  //         this.actualizarComprobante(nid);
  //       }else{
  //         this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
  //         this.setState({isLoading1: false, isLoading2: false})
  //       }
  //       })
  //       .catch((error) =>{ 
  //       if(auth0Client.isAuthenticated() === true){
  //           auth0Client.signOut()
  //           this.props.history.replace('/');
  //       }else{
  //           console.log(error);
  //           this.setState({isLoading1: false, isLoading2: false})
  //       }
  //     });
  // }

  async guardar2(){
    if(this.state.correlativo != '' && this.validaCadena(this.state.correlativo) != "00000"){
      if(this.state.selectedOption6 != '' && this.state.concepto != '' && this.state.fecha != ''){
        this.setState({isLoading1: true, isLoading2: true})
        let fromData = new FormData();

        //Sanitizar el apostrófe
        let claseSanitizada = this.state.selectedOption6.clase.replace(/'/g, "&#39;");
        
        this.setState(prevState => ({
          selectedOption6: {
              ...prevState.selectedOption6,
              clase: claseSanitizada
            }
        }));

        fromData.append('correlativo', this.validaCadena(this.state.correlativo));
        fromData.append('fecha',  this.state.fecha);
        fromData.append('cheque', this.validaCadena3(this.state.numerocaja).toUpperCase());
        fromData.append('orden',  this.validaCadena4(this.state.ordenservicio).toUpperCase());
        fromData.append('registro', this.validaCadena2(this.state.registrosiaf));
        fromData.append('beneficiario',  this.state.selectedOption6.value);
        fromData.append('tipo', this.state.selectedOption6.tipo);
        fromData.append('documento', this.state.selectedOption6.documento);
        //fromData.append('nombre', this.state.selectedOption6.clase);
        fromData.append('nombre', claseSanitizada);
        fromData.append('nidconcepto',  1189);
        fromData.append('concepto', this.state.concepto);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${tesoreria}` + 'comprobantes/insertarComprobante2', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            if(datos.response.codigo == "0"){
              this.setState({isLoading1: false, isLoading2: false})
              this.props.handleClick("tl", "error", "El correlativo ya existe!", "pe-7s-close")
            }else{
              let nid = datos.response.codigo;
              this.actualizarComprobante(nid);
            }
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading1: false, isLoading2: false})
          }
          })
          .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
          }else{
              console.log(error);
              this.setState({isLoading1: false, isLoading2: false})
          }
        });
      }else{
        this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe ingresar el correlativo correctamente!", "pe-7s-info")
    }
  }

  async actualizarComprobante(nid) {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'comprobantes/listMovimientos2?tipo=0&fechaini=' + paramTesoreria.comprobantes[0].fechaini + 
    '&fechafin=' + paramTesoreria.comprobantes[1].fechafin + 
    '&mes=' + paramTesoreria.libro[1].mes + 
    '&anio=' + paramTesoreria.libro[0].anio)
    .then((res) => {
      let lista = [];
      let lista2 = [];
      let lista3 = res.data.response;
      if(lista3.datos != null){
        lista3.datos.map((data) => {
          lista.push(
            { 
              item: data.item,
              nidmovimiento: data.nid,
              numero: data.cnum_numero, 
              cheque: data.ctxt_cheque_carta,
              registro: data.cnum_siaf,
              orden: data.cnum_orden,
              documento: data.cnum_documento,
              nombre: data.ctxt_nombre,
              concepto: data.ctxt_concepto,
              liquido: data.cnum_liquido != null ? 'S/.    ' + data.cnum_liquido.toFixed(2) : 'S/. 0.00', 
              fecha: Calendar.calendario12(data.dfec_ingreso),
              fecha2: data.dfec_ingreso,
              activo: data.item == 1 ? '1' : '0',
              documentox: data.documento
          })
        })
        paramTesoreria.comprobantes[2].data = lista
      }else{
        paramTesoreria.comprobantes[2].data = []
      }
      if(lista3.libro != null){
        lista3.libro.map((data) => {
          lista2.push(
            { 
              item: data.item,
              nidlibro: data.nid,
              cp: data.cp,
              registro: data.siaf,
              orden: data.orden,
              nombre: data.nombre,
              concepto: data.concepto,
              cheque: data.cheque,
              metas: data.metas,
              partidas: data.partidas,
              presupuestal: data.ctxt_presupuestal != null ? 'S/.    ' + data.ctxt_presupuestal.toFixed(2) : 'S/. 0.00', 
              patrimonial: data.ctxt_patrimonial != null ? 'S/.    ' + data.ctxt_patrimonial.toFixed(2) : 'S/. 0.00', 
              cuarta: data.ctxt_reten_cuarta != null ? 'S/.    ' + data.ctxt_reten_cuarta.toFixed(2) : 'S/. 0.00', 
              retenciones: data.ctxt_reten_otros != null ? 'S/.    ' + data.ctxt_reten_otros.toFixed(2) : 'S/. 0.00', 
          })
        })
        paramTesoreria.libro[2].data = lista2
      }else{
        paramTesoreria.libro[2].data = []
      }
      localStorage.setItem('data', JSON.stringify(paramTesoreria))
      this.limpiar(nid);
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading1: false, isLoading2: false})
      }
    });
  }

  // async actualizarComprobante(nid) {
  //   axios.defaults.headers.common['Authorization'] = this.state.token
  //   axios.get(`${tesoreria}` + 'comprobantes/listMovimientos?tipo=0&fechaini=' + paramTesoreria.comprobantes[0].fechaini + '&fechafin=' + paramTesoreria.comprobantes[1].fechafin)
  //   .then((res) => {
  //     let lista = [];
  //     let lista2 = res.data.response;
  //     if(lista2 != null){
  //       lista2.map((data) => {
  //         lista.push(
  //           { 
  //             item: data.item,
  //             nidmovimiento: data.nid,
  //             numero: data.cnum_numero, 
  //             cheque: data.ctxt_cheque_carta,
  //             registro: data.cnum_siaf,
  //             orden: data.cnum_orden,
  //             documento: data.cnum_documento,
  //             nombre: data.ctxt_nombre,
  //             concepto: data.ctxt_concepto,
  //             liquido: data.cnum_liquido != null ? 'S/.    ' + data.cnum_liquido.toFixed(2) : 'S/. 0.00', 
  //             fecha: Calendar.calendario12(data.dfec_ingreso),
  //             fecha2: data.dfec_ingreso,
  //             activo: data.item == 1 ? '1' : '0',
  //             documentox: data.documento
  //         })
  //       })
  //       paramTesoreria.comprobantes[2].data = lista
  //     }else{
  //       paramTesoreria.comprobantes[2].data = []
  //     }
  //     localStorage.setItem('data', JSON.stringify(paramTesoreria))
  //     this.actualizarLibro(nid);
  //   })
  //   .catch((error) => { 
  //     if(auth0Client.isAuthenticated() === true){
  //       auth0Client.signOut()
  //       this.props.history.replace('/');
  //     }else{
  //       console.log(error);
  //       this.setState({isLoading: false})
  //     }
  //   });
  // }

  // actualizarLibro(nid){
  //   axios.defaults.headers.common['Authorization'] = this.state.token
  //   axios.get(`${tesoreria}` + 'comprobantes/listLibro?mes=' + paramTesoreria.libro[1].mes+ '&anio=' + paramTesoreria.libro[0].anio)
  //   .then((res) => {
  //     let lista = [];
  //     let lista2 = res.data.response;
  //     if(lista2 != null){
  //       lista2.map((data) => {
  //         lista.push(
  //           { 
  //             item: data.item,
  //             nidlibro: data.nid,
  //             cp: data.cp,
  //             registro: data.siaf,
  //             orden: data.orden,
  //             nombre: data.nombre,
  //             concepto: data.concepto,
  //             cheque: data.cheque,
  //             metas: data.metas,
  //             partidas: data.partidas,
  //             presupuestal: data.ctxt_presupuestal != null ? 'S/.    ' + data.ctxt_presupuestal.toFixed(2) : 'S/. 0.00', 
  //             patrimonial: data.ctxt_patrimonial != null ? 'S/.    ' + data.ctxt_patrimonial.toFixed(2) : 'S/. 0.00', 
  //             cuarta: data.ctxt_reten_cuarta != null ? 'S/.    ' + data.ctxt_reten_cuarta.toFixed(2) : 'S/. 0.00', 
  //             retenciones: data.ctxt_reten_otros != null ? 'S/.    ' + data.ctxt_reten_otros.toFixed(2) : 'S/. 0.00', 
  //         })
  //       })
  //       paramTesoreria.libro[2].data = lista
  //     }else{
  //       paramTesoreria.libro[2].data = []
  //     }
  //     localStorage.setItem('data', JSON.stringify(paramTesoreria))
  //     this.limpiar(nid);
  //   })
  //   .catch((error) => { 
  //     if(auth0Client.isAuthenticated() === true){
  //       auth0Client.signOut()
  //       this.props.history.replace('/');
  //     }else{
  //       console.log(error);
  //       this.setState({isLoading: false})
  //     }
  //   });
  // }

  async guardarBeneficiario(){
    if(this.state.beneficiario != ""){
      // this.setState({isLoading1: true})
      let fromData = new FormData();

      // Escapar el apóstrofe antes de enviarlo al backend
      let descripcionEscapada = this.state.beneficiario.replace(/'/g, "&#39;");
      ///
      this.setState({ beneficiario: descripcionEscapada });

      fromData.append('numero', this.state.documento);
      //fromData.append('descripcion', encodeURIComponent(this.state.beneficiario));
      fromData.append('descripcion', descripcionEscapada);
      fromData.append('tipo', 14);
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'beneficiarios/insertarBeneficiario', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.listarBeneficiarios();
            this.props.handleClick("tl", "info", "El beneficiario ha sido registrado!", "pe-7s-smile")
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading1: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading1: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async guardarConcepto(){
    if(this.state.concepto != ""){
      // this.setState({isLoading1: true})
      let fromData = new FormData();
      fromData.append('descripcion', this.state.concepto);
      fromData.append('tipo', 14);
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'beneficiarios/insertarConcepto', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.listarConceptos();
            this.props.handleClick("tl", "info", "El concepto ha sido registrado!", "pe-7s-smile")
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading1: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading1: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async generarSelect(tipo, param){
    if(tipo == 1){
      this.state.beneficiarios.map((data) => {
        if(data.label == param){
          this.setState({
            selectedOption6: {
              value: data.value, 
              label: data.label, 
              tipo: data.tipo, 
              documento: data.documento,
              clase: data.clase
            }
          })
        }
      })
    }else{
      this.state.conceptos.map((data) => {
        if(data.label == param){
          this.setState({
            selectedOption7: {
              value: data.value, 
              label: data.label
            }
          })
        }
      })
    }
  }

  async generarChange(tipo, param){
    if(tipo == 1){
      if(param == ''){
        this.setState({selectedOption6: ''})
      }else{
        this.setState({beneficiario: param})
      }
    }else{
      if(param == ''){
        this.setState({selectedOption7: ''})
      }else{
        this.setState({concepto: param})
       }
    }
  }

  async volver(){
    this.props.history.push('/comprobantes');
  }

  render() {
    const { numero, selectedOption, selectedOption2 } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Nuevo Comprobante de Pago: <a style={{color: 'black'}}>N° {numero} - Referencial</a></h4>
              <div className="row" style={{marginBottom: 10}}>
                  <div className="col-md-6">
                  <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Datos del Beneficiario y Concepto</h4>
                  {this.state.isLoading1 ? (
                    <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading1}
                    />
                  ) : (
                      <div>
                        {this.state.marcador1 == false ? (
                          <div>
                          <div className="row">
                              <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>SELECCIONE EL BENEFICIARIO</ControlLabel>
                                {/* <Select value={selectedOption6} onChange={this.handleChange6} options={this.state.beneficiarios}/> */}
                                <Autocomplete 
                                  inputRef={this.inputRef}
                                  searchPattern={'containsString'}
                                  selectOnBlur = {false}
                                  placeholder = "Buscar beneficiario"
                                  maxOptionsLimit = {10} 
                                  searchEnabled = {true}
                                  getItemValue={(item)=>{ return item.label }} 
                                  onChange = {
                                    changedValue=>{ 
                                    this.generarChange(1, changedValue)
                                  }}
                                  onSelect = {
                                    selectedValue => { 
                                    this.generarSelect(1, selectedValue)
                                  }}
                                  itemsData = {this.state.beneficiarios}
                                  globalStyle = ".autocomplete {position: relative; display: inline-block; width: 100%} input {font-size: 14px} body {font-size: 14px; font-family: 'Roboto','Helvetica Neue',Arial,sans-serif; line-height: 1.42857143}"
                                />
                              </div>
                          </div>
                          <Button style={{width: 200, marginTop: 2, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.agregar() }}>Nuevo</Button>
                          </div>
                        ) : (
                          <div>
                            <div className="row">
                              <div className="col-md-12" style={{marginBottom: 18}}>
                                  <ControlLabel>BENEFICIARIO</ControlLabel>
                                  <FormControl type="text" value={this.state.beneficiario} onChange= {this.handleBeneficiario}/>
                              </div>
                              {/* <div className="col-md-3" style={{marginBottom: 18}}>
                                  <ControlLabel>NÚMERO DE DOCUMENTO (DNI O RUC)</ControlLabel>
                                  <FormControl type="text" value={this.state.documento} onChange= {this.handleDocumento}/>
                              </div> */}
                            </div>
                            <Button style={{width: 200, marginTop: 4, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.agregar() }}>Cancelar</Button>
                            <Button style={{width: 200, marginBottom: 20}} bsStyle="info" pullLeft fill type="submit" onClick={() => { this.guardarBeneficiario() }}>Guardar Beneficiario</Button>
                          </div>
                        )}
                        <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>INGRESE EL CONCEPTO</ControlLabel>
                              <FormControl componentClass="textarea" style={{  height: 170, maxHeight: 170, minHeight: 170, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.concepto} 
                                onChange= {this.handleConcepto}/>
                            </div>
                        </div>
                        {/* {this.state.marcador2 == false ? (
                          <div>
                          <div className="row">
                              <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>SELECCIONE EL CONCEPTO</ControlLabel>
                                <Select value={selectedOption7} onChange={this.handleChange7} options={this.state.conceptos}/>
                                <Autocomplete 
                                  inputRef={this.inputRef2}
                                  searchPattern={'containsString'}
                                  selectOnBlur = {false}
                                  placeholder = "Buscar concepto"
                                  maxOptionsLimit = {10} 
                                  searchEnabled = {true}
                                  getItemValue={(item)=>{ return item.label }} 
                                  onChange = {
                                    changedValue=>{ 
                                    this.generarChange(2, changedValue)
                                  }}
                                  onSelect = {
                                    selectedValue => { 
                                    this.generarSelect(2, selectedValue)
                                  }}
                                  itemsData = {this.state.conceptos}
                                  globalStyle = ".autocomplete {position: relative; display: inline-block; width: 100%} input {font-size: 14px} body {font-size: 14px; font-family: 'Roboto','Helvetica Neue',Arial,sans-serif; line-height: 1.42857143}"
                                />
                              </div>
                          </div>
                          <Button style={{width: 150, marginTop: 2, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.agregar2() }}>Nuevo</Button>
                          </div>
                        ) : (
                          <div>
                            <div className="row">
                              <div className="col-md-12" style={{marginBottom: 18}}>
                                  <ControlLabel>DEFINICIÓN DEL CONCEPTO</ControlLabel>
                                  <FormControl componentClass="textarea" style={{  height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.concepto} 
                                  onChange= {this.handleConcepto}/>
                              </div>
                            </div> 
                            <Button style={{width: 150, marginTop: 4, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.agregar2() }}>Cancelar</Button>
                            <Button style={{width: 200, marginBottom: 20}} bsStyle="info" pullLeft fill type="submit" onClick={() => { this.guardarConcepto() }}>Guardar Concepto</Button>
                          </div>
                        )} */}
                      </div>
                    )}
                  </div>
                {/* </div>
                <hr></hr>
                <div className="row" style={{marginBottom: 10}}> */}
                  <div className="col-md-6">
                  <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Datos del Comprobante</h4>
                      {this.state.isLoading2 ? (
                        <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading2}
                        />
                      ) : (
                        <div>
                          <div className="row">
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>HECHO POR</ControlLabel>
                                <FormControl type="text" style={{textTransform: 'uppercase'}} value={this.state.usuario} onChange= {this.handleUsuario} disabled/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>N° CORRELATIVO</ControlLabel>
                                <FormControl type="number" style={{textTransform: 'uppercase'}} value={this.state.correlativo} onChange= {this.handleCorrelativo}/>
                            </div>
                            <div className="col-md-4" style={{marginTop: 5, marginBottom: 18}}>
                                <ControlLabel></ControlLabel><br></br>
                                <Button style={{width: 200}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.obtenerCorrelativo(1) }}>Generar Correlativo</Button>
                            </div>
                          </div>
                          <div className="row" style={{marginTop: 18}}>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE GIRO</ControlLabel>
                                <FormControl type="date" value={this.state.fecha} onChange= {this.handleFecha}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>REGISTRO SIAF</ControlLabel>
                                <FormControl type="number" value={this.state.registrosiaf} onChange= {this.handleRegistrosiaf}/>                            
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>TIPO DE GIRO</ControlLabel>
                                <Select value={selectedOption} onChange={this.handleChange} options={this.state.tiposgiro}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE GIRO</ControlLabel>
                                <FormControl type="text" value={this.state.numerocaja} onChange= {this.handleNumerocaja}/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>TIPO DE ORDEN</ControlLabel>
                                <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.tiposorden}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE ORDEN</ControlLabel>
                                <FormControl type="text" value={this.state.ordenservicio} onChange= {this.handleOrdenservicio}/>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.marcador1 == false && this.state.marcador2 == false ? (
                      <Button style={{width: 200, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.guardar2() }}>Continuar</Button>
                      ) : ( null )}
                  </div>
                </div>
              </div>
            </Col>
            <Button style={{width: 200, marginTop: 25, marginRight: 30}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.volver() }}>Ir a Comprobantes</Button>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default NuevoComprobante;