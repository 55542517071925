import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet, 
    Font
} from "@react-pdf/renderer";

import Calendar from "components/Calendar/Calendar.jsx";
import { NumeroALetras } from "views/efectivo/conversor2";

Font.register({ family: 'Open Sans', src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf' });

const styles = StyleSheet.create({
    body: {
      paddingHorizontal: 35,
      flexGrow: 1,
    },
    title: {
      marginTop: 8,
      marginBottom: 5,
      fontSize: 15,
      textAlign: 'center',
    },
    texto1: {
      fontSize: 8,
      marginTop: 8
    },
    texto2: {
      fontSize: 8,
      marginBottom: 5,
      marginLeft: 5
    },
    texto6: {
      fontSize: 8,
      marginTop: 5,
      marginBottom: 5,
      marginLeft: 5
    },
    texto3: {
      fontSize: 10,
      marginTop: 20,
      marginLeft: 15
    },
    texto4: {
      fontSize: 8,
      marginBottom: 2,
      marginLeft: 5
    },
    texto5: {
      fontSize: 8,
      marginTop: 3,
      marginBottom: 3,
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    table: { 
      display: "table", 
      width: "auto", 
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderBottomWidth: 0,
      marginTop: 5, 
    }, 
    table2: { 
      display: "table", 
      width: "auto", 
      marginTop: 2, 
    }, 
    table3: { 
      display: "table", 
      width: "auto", 
    }, 
    tableCol: { 
      width: "40%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    }, 
    tableColFA: { 
      width: "20%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    }, 
    tableCol2: { 
      width: "70%",
    }, 
    tableCol3: { 
      width: "30%",
    },
    tableCol4: { 
      width: "100%",
      borderWidth: 1, 
      borderLeftWidth: 0,  
      borderTopWidth: 0,
      paddingRight: 5
    },
    tableCol4B: { 
      width: "100%",
      borderWidth: 1, 
      borderLeftWidth: 0,  
      borderRightWidth: 0,
      borderTopWidth: 0,
    },
    tableCol5: { 
      width: "50%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 ,
      borderBottomWidth: 0
    },
    tableCol5B: { 
      width: "50%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 ,
    },
    tableCol5C: { 
      width: "50%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderBottomWidth: 0
    },
    tableCol5CH: { 
      width: "50%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderBottomWidth: 0,
      paddingRight: 5,
      textAlign: 'right'
    },
    tableCol5D: { 
      width: "50%",
      borderWidth: 1, 
      borderLeftWidth: 0,
      borderTopWidth: 0, 
      borderBottomWidth: 0
    },
    tableCol5E: { 
      width: "50%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderBottomWidth: 0
    },
    tableCol5EH: { 
      width: "50%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderBottomWidth: 0,
      paddingRight: 5,
      textAlign: 'right'
    },
    tableCol6: { 
      width: "10%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
      borderBottomWidth: 0
    },
    tableCol6C: { 
      width: "12%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
      borderBottomWidth: 0
    },
    tableCol6B: { 
      width: "12%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderRightWidth: 0, 
      borderTopWidth: 0,
      borderBottomWidth: 0 
    },
    tableCol7: { 
      width: "18%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
      borderBottomWidth: 0 
    },
    tableCol8: { 
      width: "30%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
      borderBottomWidth: 0,
      paddingRight: 5,
      textAlign: 'right'
    },
    tableCol8B: { 
      width: "30%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
      borderBottomWidth: 0
    },
    tableCol8C: { 
      width: "30%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0, 
      paddingRight: 5,
      textAlign: 'right'
    },
    tableCol8D: { 
      width: "30%",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0, 
      borderTopWidth: 0,
      borderBottomWidth: 0 
    },
    tableCol8E: { 
      width: "30%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderRightWidth: 0,
      borderBottomWidth: 0, 
      paddingRight: 5,
      textAlign: 'right'
    },
    tableCol8F: { 
      width: "35%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
      borderBottomWidth: 0,
      paddingRight: 5,
      textAlign: 'right'
    },
    tableCol9: { 
      width: "40%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
      borderBottomWidth: 0 
    },
    tableCol9B: { 
      width: "40%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
      borderRightWidth: 0, 
      borderBottomWidth: 0 
    },
    tableCol9C: { 
      width: "35%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
      borderBottomWidth: 0 
    },
    tableCol10: { 
      width: "60%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderRightWidth: 0, 
      borderTopWidth: 0, 
      borderBottomWidth: 0 
    },
    tableCol12: { 
      width: "70%",
      borderWidth: 1, 
      borderLeftWidth: 0,
      borderBottomWidth: 0
    },
    tableCol12B: { 
      width: "70%",
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
      borderBottomWidth: 0 
    },
    tableCol12C: { 
      width: "70%",
      borderWidth: 1, 
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderBottomWidth: 0 
    },
    tableCol11: { 
      width: "100%",
    },
    tableRow: { 
      margin: "auto", 
      flexDirection: "row" 
    }, 
    tableColHeader: { 
      width: "40%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
    },
    tableColHeaderFA: { 
      width: "20%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
    },
    tableColHeader2: { 
      width: "100%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
    },
    tableColHeader2B: { 
      width: "100%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderRightWidth: 0, 
    },
    tableColHeader2C: { 
      width: "100%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderRightWidth: 0, 
      borderTopWidth: 0,
    },
    tableColHeader3: { 
      width: "50%", 
      borderWidth: 1, 
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColHeader3B: { 
      width: "50%", 
      borderWidth: 1, 
      borderLeftWidth: 0,
      borderRightWidth: 0, 
      borderTopWidth: 0,
    },
    tableColHeader3C: { 
      width: "50%", 
      borderWidth: 1, 
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderBottomWidth: 0 
    },
    tableColHeader3D: { 
      width: "50%", 
      borderWidth: 1, 
      borderLeftWidth: 0,
      borderRightWidth: 0, 
      borderTopWidth: 0,
      borderBottomWidth: 0 
    },
    tableColHeader3E: { 
      width: "50%", 
      borderWidth: 1, 
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableColHeader3F: { 
      width: "50%", 
      borderWidth: 1, 
      borderLeftWidth: 0,
      borderRightWidth: 0, 
      borderTopWidth: 0
    },
    tableColHeader4: { 
      width: "10%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
    },
    tableColHeader4C: { 
      width: "12%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
    },
    tableColHeader4B: { 
      width: "12%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderRightWidth: 0, 
      borderTopWidth: 0,
    },
    tableColHeader5: { 
      width: "18%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
    },
    tableColHeader6: { 
      width: "30%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
    },
    tableColHeader6E: { 
      width: "35%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
    },
    tableColHeader6B: { 
      width: "30%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderRightWidth: 0, 
      borderTopWidth: 0,
    },
    tableColHeader6C: { 
      width: "30%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderRightWidth: 0, 
    },
    tableColHeader6D: { 
      width: "30%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderRightWidth: 0
    },
    tableColHeader7: { 
      width: "40%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderBottomWidth: 0,
    },
    tableColHeader7B: { 
      width: "40%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderRightWidth: 0, 
      borderTopWidth: 0,
    },
    tableColHeader7C: { 
      width: "35%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },
    tableColHeader8: { 
      width: "60%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableColHeader8B: { 
      width: "60%", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
      borderBottomWidth: 0,
    },
    tableColHeader9: { 
      width: "70%", 
      borderWidth: 1, 
      borderLeftWidth: 0
    },
    tableCellHeader: {
      margin: 5, 
      marginTop: 3, 
      marginBottom: 3, 
      fontSize: 8,
      fontWeight: 500,
      textAlign: 'center'
    },  
    tableCellHeaderE: {
      margin: 10, 
      fontSize: 8,
      fontWeight: 500,
      textAlign: 'center'
    },
    tableCellHeaderF: {
      margin: 15, 
      fontSize: 8,
      fontWeight: 500,
      textAlign: 'center'
    },   
    tableCellHeaderG: {
      fontSize: 8,
      fontWeight: 500,
      textAlign: 'left'
    }, 
    tableCell: { 
      marginLeft: 5,
      marginBottom: 3,
      marginTop: 3,
      fontSize: 8
    },
    tableCell20: { 
      marginLeft: 5,
      marginBottom: 3,
      marginTop: 3,
      fontSize: 9
    },
    tableCellFG: { 
      marginBottom: 3,
      marginTop: 4,
      fontSize: 8,
      textAlign: 'center'
    },
    tableCellFF: { 
      marginBottom: 3,
      marginTop: 4,
      fontSize: 8,
      textAlign: 'center'
    },
    tableCell2: { 
      marginLeft: 5,
      marginBottom: 2,
      fontSize: 8
    },
    tableCell3: { 
      margin: 7,
      fontSize: 8
    },
    row: {
      flexDirection: 'row',
      marginBottom: 30,
    },
});

export function reporteComprobantes(movimientos) {
    let money = "";
    if(NumeroALetras(movimientos.comprobante[0].liquido.replace('S/.    ', '')) != "undefined SOLES "){
      money = NumeroALetras(movimientos.comprobante[0].liquido.replace('S/.    ', ''))
    }else{
      money = "CERO SOLES"
    }
    let cuentadebe2 = movimientos.presupuestal[0] != undefined ? movimientos.presupuestal[0].cuentadebe2 : "0"
    let importedebe2 = movimientos.presupuestal[0] != undefined ? movimientos.presupuestal[0].importedebe2 : "0"
    let cuentahaber2 = movimientos.presupuestal[0] != undefined ? movimientos.presupuestal[0].cuentahaber2 : "0"
    let importehaber2 = movimientos.presupuestal[0] != undefined ? movimientos.presupuestal[0].importehaber2 : "0"
    return (
        <Document>
        <Page size="A4" orientation="portrait" style={styles.body}>
        <Text style={styles.texto3}>TRIBUNAL CONSTITUCIONAL</Text>  
        <Text style={styles.texto5}>DIRECCIÓN GENERAL DE ADMINISTRACIÓN</Text>  
        <Text style={styles.texto4}>OFICINA DE CONTABILIDAD Y TESORERÍA</Text>
        <Text style={styles.title}>COMPROBANTE DE PAGO</Text>
        <View style={styles.table2}> 
            <View style={styles.tableRow}> 
              <View style={styles.tableCol2}> 
                <Text style={styles.texto1}>REGISTRO SIAF: {movimientos.comprobante[0].siaf}</Text>  
                <Text style={styles.texto1}>NOMBRE: {movimientos.identificador[0].nombre}</Text>  
                <Text style={styles.texto1}>SON: {money}</Text>
              </View> 
              <View style={styles.tableCol3}> 
                <View style={styles.table}> 
                  <View style={styles.tableRow}> 
                    <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCellHeader}>NÚMERO</Text> 
                    </View> 
                    <View style={styles.tableColHeaderFA}> 
                        <Text style={styles.tableCellHeader}>DÍA</Text> 
                    </View> 
                    <View style={styles.tableColHeaderFA}> 
                        <Text style={styles.tableCellHeader}>MES</Text> 
                    </View> 
                    <View style={styles.tableColHeaderFA}> 
                        <Text style={styles.tableCellHeader}>AÑO</Text> 
                    </View>
                  </View>
                  <View style={styles.tableRow}> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCellFF}>{movimientos.comprobante[0].numero}</Text> 
                    </View> 
                    <View style={styles.tableColFA}> 
                        <Text style={styles.tableCellFG}>{movimientos.comprobante[0].fecha.substr(8,2)}</Text> 
                    </View> 
                    <View style={styles.tableColFA}> 
                        <Text style={styles.tableCellFG}>{movimientos.comprobante[0].fecha.substr(5,2)}</Text> 
                    </View> 
                    <View style={styles.tableColFA}> 
                        <Text style={styles.tableCellFG}>{movimientos.comprobante[0].fecha.substr(0,4)}</Text> 
                    </View> 
                  </View> 
                </View>
              </View> 
            </View> 
        </View>
        <View style={styles.table}> 
          <View style={styles.tableRow}> 
            <View style={styles.tableColHeader2}> 
                <Text style={styles.tableCellHeader}>CONCEPTO</Text> 
            </View> 
          </View>
          <View style={styles.tableRow}> 
            <View style={styles.tableCol4}> 
              <Text style={styles.tableCell20}>{movimientos.identificador[0].concepto}</Text> 
              <Text style={styles.tableCell}></Text> 
              {/* <Text style={styles.tableCell}>DETALLE DE CONCEPTOS: </Text>  */}
              {movimientos.patrimonial.map((data, i) => {
                  return (
                    data.patrimonial != 0 ? (
                      <Text style={styles.tableCell2}>{data.concepto + '  ' + data.importedebe}</Text> 
                    ) : (null)
                  );
              })}
              {/* {movimientos.comprobante[0].siaf != null ? (
                <Text style={styles.tableCell2}>{'Registro SIAF N°: ' + movimientos.comprobante[0].siaf}</Text> 
              ) : (
                <Text style={styles.tableCell2}>{'Registro SIAF N°: '}</Text> 
              )} */}
              {movimientos.comprobante[0].orden != null ? (
                <Text style={styles.tableCell2}>{'N° DE ORDEN: ' + movimientos.comprobante[0].orden}</Text> 
              ) : (
                <Text style={styles.tableCell2}>{'N° DE ORDEN: '}</Text> 
              )}
            </View> 
          </View> 
        </View>
        <View style={styles.table}> 
          <View style={styles.tableRow}> 
            <View style={styles.tableColHeader3}> 
                <Text style={styles.tableCellHeader}>CODIFICACIÓN PROGRAMÁTICA</Text> 
            </View> 
            <View style={styles.tableColHeader3}> 
                <Text style={styles.tableCellHeader}>ESTADÍSTICA DEL OBJETO DEL GASTO</Text> 
            </View> 
          </View>
          <View style={styles.tableRow}> 
            <View style={styles.tableCol5B}> 
                <View style={styles.table3}> 
                  <View style={styles.tableRow}> 
                    <View style={styles.tableColHeader4}> 
                        <Text style={styles.tableCellHeader}>FUN</Text> 
                    </View> 
                    <View style={styles.tableColHeader4}> 
                        <Text style={styles.tableCellHeader}>P/F</Text> 
                    </View> 
                    <View style={styles.tableColHeader4}> 
                        <Text style={styles.tableCellHeader}>SPG</Text> 
                    </View> 
                    <View style={styles.tableColHeader4}> 
                        <Text style={styles.tableCellHeader}>PRG</Text> 
                    </View>
                    <View style={styles.tableColHeader5}> 
                        <Text style={styles.tableCellHeader}>ACT</Text> 
                    </View>
                    <View style={styles.tableColHeader5}> 
                        <Text style={styles.tableCellHeader}>CMP</Text> 
                    </View>
                    <View style={styles.tableColHeader4C}> 
                        <Text style={styles.tableCellHeader}>META</Text> 
                    </View>
                    <View style={styles.tableColHeader4B}> 
                        <Text style={styles.tableCellHeader}>FIN</Text> 
                    </View>
                  </View>
                  {movimientos.codificaciones.map((data, i) => {
                    if(data.fn != '.'){
                      return (
                      <View style={styles.tableRow}> 
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCell}>{data.fn}</Text> 
                        </View> 
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCell}>{data.pf}</Text> 
                        </View> 
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCell}>{data.spf}</Text> 
                        </View> 
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCell}>{data.prg}</Text> 
                        </View> 
                        <View style={styles.tableCol7}> 
                            <Text style={styles.tableCell}>{data.actproy}</Text> 
                        </View> 
                        <View style={styles.tableCol7}> 
                            <Text style={styles.tableCell}>{data.comp}</Text> 
                        </View> 
                        <View style={styles.tableCol6C}> 
                            <Text style={styles.tableCell}>{data.meta}</Text> 
                        </View> 
                        <View style={styles.tableCol6B}> 
                            <Text style={styles.tableCell}>{data.fin}</Text> 
                        </View> 
                      </View> 
                      );
                    }else{
                      return (
                      <View style={styles.tableRow}> 
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCell3}></Text> 
                        </View> 
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCell3}></Text> 
                        </View> 
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCell3}></Text> 
                        </View> 
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCell3}></Text> 
                        </View> 
                        <View style={styles.tableCol7}> 
                            <Text style={styles.tableCell3}></Text> 
                        </View> 
                        <View style={styles.tableCol7}> 
                            <Text style={styles.tableCell3}></Text> 
                        </View> 
                        <View style={styles.tableCol6C}> 
                            <Text style={styles.tableCell3}></Text> 
                        </View> 
                        <View style={styles.tableCol6B}> 
                            <Text style={styles.tableCell3}></Text> 
                        </View> 
                      </View> 
                      );
                    }
                 })}
                </View>
                <View style={styles.table3}> 
                  <View style={styles.tableRow}> 
                    <View style={styles.tableColHeader2B}> 
                        <Text style={styles.tableCellHeader}>CONTABILIDAD PRESUPUESTAL</Text> 
                    </View> 
                  </View>
                  <View style={styles.tableRow}> 
                    <View style={styles.tableCol4B}> 
                      <View style={styles.table3}> 
                        <View style={styles.tableRow}> 
                          <View style={styles.tableColHeader3}> 
                              <Text style={styles.tableCellHeader}>DEBE</Text> 
                          </View> 
                          <View style={styles.tableColHeader3B}> 
                              <Text style={styles.tableCellHeader}>HABER</Text> 
                          </View> 
                        </View>
                        <View style={styles.tableRow}> 
                          <View style={styles.tableCol5}> 
                            <View style={styles.table3}> 
                              <View style={styles.tableRow}> 
                                <View style={styles.tableColHeader3}> 
                                    <Text style={styles.tableCellHeader}>CUENTA</Text> 
                                </View> 
                                <View style={styles.tableColHeader3B}> 
                                    <Text style={styles.tableCellHeader}>IMPORTE</Text> 
                                </View> 
                              </View>
                              <View style={styles.tableRow}> 
                                <View style={styles.tableCol5}> 
                                    <Text style={styles.tableCell}>{cuentadebe2}</Text> 
                                </View> 
                                <View style={styles.tableCol5CH}> 
                                    <Text style={styles.tableCell}>{importedebe2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('S/.', '')}</Text> 
                                </View> 
                              </View>
                            </View>
                          </View> 
                          <View style={styles.tableCol5C}> 
                          <View style={styles.table3}> 
                              <View style={styles.tableRow}> 
                                <View style={styles.tableColHeader3}> 
                                    <Text style={styles.tableCellHeader}>CUENTA</Text> 
                                </View> 
                                <View style={styles.tableColHeader3B}> 
                                    <Text style={styles.tableCellHeader}>IMPORTE</Text> 
                                </View> 
                              </View>
                              <View style={styles.tableRow}> 
                                <View style={styles.tableCol5}> 
                                    <Text style={styles.tableCell}>{cuentahaber2}</Text> 
                                </View> 
                                <View style={styles.tableCol5CH}> 
                                    <Text style={styles.tableCell}>{importehaber2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('S/.', '')}</Text> 
                                </View> 
                              </View>
                            </View>
                          </View> 
                        </View> 
                      </View>
                    </View> 
                  </View> 
                </View>
                <View style={styles.table3}> 
                  <View style={styles.tableRow}> 
                    <View style={styles.tableColHeader2C}> 
                        <Text style={styles.tableCellHeader}>CONTABILIDAD PATRIMONIAL</Text> 
                    </View> 
                  </View>
                  <View style={styles.tableRow}> 
                    <View style={styles.tableCol4B}> 
                      <View style={styles.table3}> 
                        <View style={styles.tableRow}> 
                          <View style={styles.tableColHeader3}> 
                              <Text style={styles.tableCellHeader}>DEBE</Text> 
                          </View> 
                          <View style={styles.tableColHeader3B}> 
                              <Text style={styles.tableCellHeader}>HABER</Text> 
                          </View> 
                        </View>
                        <View style={styles.tableRow}> 
                          <View style={styles.tableCol5}> 
                            <View style={styles.table3}> 
                              <View style={styles.tableRow}> 
                                <View style={styles.tableColHeader3E}> 
                                    <Text style={styles.tableCellHeader}>CUENTA</Text> 
                                </View> 
                                <View style={styles.tableColHeader3F}> 
                                    <Text style={styles.tableCellHeader}>IMPORTE</Text> 
                                </View> 
                              </View>
                              {movimientos.patrimonial.map((data, i) => {
                                if(data.cuentadebe != '.'){
                                  return (
                                    <View style={styles.tableRow}> 
                                      <View style={styles.tableCol5D}> 
                                          <Text style={styles.tableCell}>{data.cuentadebe}</Text> 
                                      </View> 
                                      <View style={styles.tableCol5EH}> 
                                          <Text style={styles.tableCell}>{data.importedebe.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('S/.', '')}</Text> 
                                      </View> 
                                    </View> 
                                  );
                                }else{
                                  return (
                                    <View style={styles.tableRow}> 
                                      <View style={styles.tableCol5D}> 
                                          <Text style={styles.tableCell3}></Text> 
                                      </View> 
                                      <View style={styles.tableCol5E}> 
                                          <Text style={styles.tableCell3}></Text> 
                                      </View> 
                                    </View> 
                                  );
                                }
                                })}
                            </View>
                          </View> 
                          <View style={styles.tableCol5C}> 
                          <View style={styles.table3}> 
                              <View style={styles.tableRow}> 
                                <View style={styles.tableColHeader3E}> 
                                    <Text style={styles.tableCellHeader}>CUENTA</Text> 
                                </View> 
                                <View style={styles.tableColHeader3F}> 
                                    <Text style={styles.tableCellHeader}>IMPORTE</Text> 
                                </View> 
                              </View>
                              {movimientos.patrimonial.map((data, i) => {
                                if(data.cuentahaber != '.'){
                                  return (
                                    <View style={styles.tableRow}> 
                                      <View style={styles.tableCol5D}> 
                                          <Text style={styles.tableCell}>{data.cuentahaber}</Text> 
                                      </View> 
                                      <View style={styles.tableCol5EH}> 
                                          <Text style={styles.tableCell}>{data.importehaber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('S/.', '')}</Text> 
                                      </View> 
                                    </View> 
                                  );
                                }else{
                                  return (
                                    <View style={styles.tableRow}> 
                                      <View style={styles.tableCol5D}> 
                                          <Text style={styles.tableCell3}></Text> 
                                      </View> 
                                      <View style={styles.tableCol5E}> 
                                          <Text style={styles.tableCell3}></Text> 
                                      </View> 
                                    </View> 
                                  );
                                }
                              })}
                            </View>
                          </View> 
                        </View> 
                      </View>
                    </View> 
                  </View> 
                </View>
                <View style={styles.table3}> 
                  <View style={styles.tableRow}> 
                    <View style={styles.tableColHeader2C}> 
                        <Text style={styles.tableCellHeader}>PARA USO DEL TESORERO O CAJERO</Text> 
                    </View> 
                  </View>
                  <View style={styles.tableRow}> 
                    <View style={styles.tableCol4B}> 
                      <View style={styles.table3}> 
                        <View style={styles.tableRow}> 
                          <View style={styles.tableColHeader6}> 
                              <Text style={styles.tableCellHeader}>FECHA</Text> 
                          </View> 
                          <View style={styles.tableColHeader6}> 
                              <Text style={styles.tableCellHeader}>HECHO POR</Text> 
                          </View> 
                          <View style={styles.tableColHeader7B}> 
                              <Text style={styles.tableCellHeader}>CONFORME</Text> 
                          </View> 
                        </View>
                        <View style={styles.tableRow}> 
                          <View style={styles.tableCol8B}> 
                              <Text style={styles.tableCellHeader}>{Calendar.calendario12B(movimientos.comprobante[0].fecha)}</Text> 
                          </View> 
                          <View style={styles.tableCol8B}> 
                              <Text style={styles.tableCellHeader}>{movimientos.comprobante[0].usuariox.toUpperCase()}</Text> 
                          </View> 
                          <View style={styles.tableCol9B}> 
                              <Text style={styles.tableCellHeader}></Text> 
                          </View> 
                        </View> 
                      </View>
                    </View> 
                  </View> 
                </View>
                <View style={styles.table3}> 
                  <View style={styles.tableRow}> 
                    <View style={styles.tableColHeader2C}> 
                        <Text style={styles.tableCellHeader}>VISACIÓN</Text> 
                    </View> 
                  </View>
                  <View style={styles.tableRow}> 
                    <View style={styles.tableCol4B}> 
                      <View style={styles.table3}> 
                        <View style={styles.tableRow}> 
                          <View style={styles.tableCol9}> 
                              <Text style={styles.tableCellHeaderE}></Text> 
                          </View> 
                          <View style={styles.tableCol10}> 
                              <Text style={styles.tableCellHeaderE}></Text> 
                          </View> 
                        </View> 
                        <View style={styles.tableRow}> 
                          <View style={styles.tableColHeader7}> 
                              <Text style={styles.tableCellHeader}>CONTROL INTERNO</Text> 
                          </View> 
                          <View style={styles.tableColHeader8}> 
                              <Text style={styles.tableCellHeader}>JEFE DE FINANZAS</Text> 
                          </View> 
                        </View>
                      </View>
                    </View> 
                  </View> 
                </View>
                <View style={styles.table3}> 
                  <View style={styles.tableRow}> 
                    <View style={styles.tableColHeader2C}> 
                        <Text style={styles.tableCellHeader}>RECIBÍ CONFORME</Text> 
                    </View> 
                  </View>
                  <View style={styles.tableRow}> 
                    <View style={styles.tableCol4B}> 
                      <View style={styles.table3}> 
                        <View style={styles.tableRow}> 
                          <View style={styles.tableCol9}> 
                              <Text style={styles.tableCellHeaderF}></Text> 
                          </View> 
                          <View style={styles.tableCol10}> 
                              <Text style={styles.tableCellHeaderF}></Text> 
                          </View> 
                        </View> 
                        <View style={styles.tableRow}> 
                          <View style={styles.tableColHeader7}> 
                              <Text style={styles.tableCellHeader}>FECHA</Text> 
                          </View> 
                          <View style={styles.tableColHeader8}> 
                              <Text style={styles.tableCellHeader}>FIRMA</Text> 
                          </View> 
                        </View>
                      </View>
                    </View> 
                  </View> 
                </View>
                <View style={styles.table2}> 
                  <View style={styles.tableRow}> 
                    <View style={styles.tableCol11}> 
                      <Text style={styles.texto6}>NOMBRE Y APELLIDOS:</Text>  
                      <Text style={styles.texto2}>DNI: N°</Text>  
                    </View> 
                  </View> 
                </View>
            </View> 
            <View style={styles.tableCol5B}> 
                <View style={styles.table3}> 
                  <View style={styles.tableRow}> 
                    <View style={styles.tableColHeader7C}> 
                        <Text style={styles.tableCellHeader}>CLASIFICADOR DE GASTO</Text> 
                    </View> 
                    <View style={styles.tableColHeader6E}> 
                        <Text style={styles.tableCellHeader}>IMPORTE PARCIAL</Text> 
                    </View> 
                    <View style={styles.tableColHeader6B}> 
                        <Text style={styles.tableCellHeader}>IMPORTE TOTAL</Text> 
                    </View> 
                  </View>
                  {movimientos.estadistica.map((data, i) => {
                    if(data.partida != '.'){
                      return (
                        <View style={styles.tableRow}> 
                          <View style={styles.tableCol9C}> 
                              <Text style={styles.tableCell}>{data.partida}</Text> 
                          </View> 
                          <View style={styles.tableCol8F}> 
                              <Text style={styles.tableCell}>{data.parcial.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('S/.', '')}</Text> 
                          </View> 
                          <View style={styles.tableCol8C}> 
                              <Text style={styles.tableCell}></Text> 
                          </View>  
                        </View> 
                      );
                    }else{
                      return (
                        <View style={styles.tableRow}> 
                          <View style={styles.tableCol9C}> 
                              <Text style={styles.tableCell3}></Text> 
                          </View> 
                          <View style={styles.tableCol8F}> 
                              <Text style={styles.tableCell3}></Text> 
                          </View> 
                          <View style={styles.tableCol8C}> 
                              <Text style={styles.tableCell3}></Text> 
                          </View>  
                        </View> 
                      );
                    }
                  })} 
                </View>
                <View style={styles.table3}> 
                  <View style={styles.tableRow}> 
                    <View style={styles.tableCol12}> 
                        <Text style={styles.tableCell}>TOTAL</Text> 
                    </View> 
                    <View style={styles.tableCol8E}> 
                        <Text style={styles.tableCell}>{movimientos.comprobante[0].totalgastos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('S/.', '')}</Text> 
                    </View> 
                  </View>
                  <View style={styles.tableRow}> 
                    <View style={styles.tableCol12}> 
                        <Text style={styles.tableCell}>DEDUCCIONES</Text> 
                    </View> 
                    <View style={styles.tableCol8E}> 
                        <Text style={styles.tableCell}>{movimientos.comprobante[0].totalretenciones.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('S/.', '')}</Text> 
                    </View> 
                  </View>
                  <View style={styles.tableRow}> 
                    <View style={styles.tableCol12}> 
                        <Text style={styles.tableCell}>LIQUIDO A PAGAR</Text> 
                    </View> 
                    <View style={styles.tableCol8E}> 
                        <Text style={styles.tableCell}>{movimientos.comprobante[0].liquido.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('S/.', '')}</Text> 
                    </View> 
                  </View>
                </View>
                <View style={styles.table3}> 
                  <View style={styles.tableRow}> 
                    <View style={styles.tableColHeader9}> 
                        <Text style={styles.tableCellHeader}>RETENCIONES Y/O DEDUCCIONES</Text> 
                    </View> 
                    <View style={styles.tableColHeader6C}> 
                        <Text style={styles.tableCellHeader}>IMPORTE</Text> 
                    </View> 
                  </View>
                  {movimientos.deducciones.map((data, i) => {
                    if(data.deduccion != '.'){
                      return (
                        <View style={styles.tableRow}> 
                          <View style={styles.tableCol12C}> 
                              <Text style={styles.tableCell}>{data.deduccion}</Text> 
                          </View> 
                          <View style={styles.tableCol8C}> 
                              <Text style={styles.tableCell}>{data.parcial.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('S/.', '')}</Text> 
                          </View>  
                        </View> 
                      );
                    }else{
                      return (
                        <View style={styles.tableRow}> 
                          <View style={styles.tableCol12C}> 
                              <Text style={styles.tableCell3}></Text> 
                          </View> 
                          <View style={styles.tableCol8C}> 
                              <Text style={styles.tableCell3}></Text> 
                          </View>  
                        </View> 
                      );
                    }
                  })}  
                </View>
                <View style={styles.table3}> 
                  <View style={styles.tableRow}> 
                    <View style={styles.tableColHeader9}> 
                        <Text style={styles.tableCellHeader}>FORMA DE PAGO</Text> 
                    </View> 
                    <View style={styles.tableColHeader6D}> 
                        <Text style={styles.tableCellHeader}>AUTORIZACIÓN</Text> 
                    </View> 
                  </View>
                  <View style={styles.tableRow}> 
                    <View style={styles.tableCol12B}> 
                        {/* <Text style={styles.tableCell}>{'CHEQUE N° ' + movimientos.comprobante[0].numerocaja}</Text>  */}
                        <Text style={styles.tableCell}>{movimientos.comprobante[0].numerocaja}</Text> 

                    </View> 
                    <View style={styles.tableCol8D}> 
                        <Text style={styles.tableCell}></Text> 
                    </View> 
                  </View> 
                </View>
            </View> 
          </View> 
        </View>
        {/* <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed /> */}
        </Page>
      </Document>
    );
}