import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { Card } from "components/Card/Card.jsx";

import 'react-confirm-alert/src/react-confirm-alert.css';
import { NavLink} from 'react-router-dom';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;
class Areas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarAreas',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      vistas: []
    }
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.setState({isLoading: false})
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  render() {
    return (
      <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              content={
                <main role="main">
                  <div className="container">
                    <h2 className="text-center">ADMINISTRACIÓN DE ÁREAS Y PERMISOS</h2>
                 </div>
                  <div className="album py-5 bg-light">
                    <div style={{marginRight: 50, marginLeft: 50}}>
                      <div className="row" style={{marginTop: 30}}>
                      {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.isLoading}
                      />
                      ) : (
                      <div className="row text-center">
                          <div className="col-md-3" style={{marginTop : 10}}>
                            <NavLink to="/adminAreas"> 
                            <span className="fa-stack fa-5x">
                              <i className="fa fa-square fa-stack-2x" style={{color:"#E96D53"}}></i>
                              <i className="fa fa-sitemap fa-stack-1x fa-inverse" ></i>
                            </span>
                            <p style={{fontSize:20,color:"black", fontWeight: "bold"}}>ÁREAS</p> 
                            </NavLink>
                          </div>
                          <div className="col-md-3" style={{marginTop : 10}}>
                            <NavLink to="/administrarCargos">
                              <span className="fa-stack fa-5x">
                                <i className="fa fa-square fa-stack-2x" style={{color:"#A6D689"}}></i>
                                <i className="fa fa-users fa-stack-1x fa-inverse" ></i>
                              </span>
                              <p style={{fontSize:20,color:"black", fontWeight: "bold"}}>CARGOS</p> 
                            </NavLink>
                          </div>
                          <div className="col-md-3" style={{marginTop : 10}}>
                            <NavLink to="/administrarRegimen"> 
                            <span className="fa-stack fa-5x">
                              <i className="fa fa-square fa-stack-2x" style={{color:"#80D7DB"}}></i>
                              <i className="fa fa-clipboard fa-stack-1x fa-inverse" ></i>
                            </span>
                            <p style={{fontSize:20,color:"black", fontWeight: "bold"}}>RÉGIMEN</p> 
                            </NavLink>
                          </div>           
                          <div className="col-md-3" style={{marginTop : 10}}>
                            <NavLink to="/administrarConceptosPago"> 
                            <span className="fa-stack fa-5x">
                              <i className="fa fa-square fa-stack-2x" style={{color:"#EC405F"}}></i>
                              <i className="fa fa-credit-card fa-stack-1x fa-inverse"></i>
                            </span>
                            <p style={{fontSize:20,color:"black", fontWeight: "bold"}}>CONCEPTOS DE PAGO</p> 
                            </NavLink>
                          </div>
                          <div className="col-md-3" style={{marginTop : 10}}>
                            <NavLink to="/administrarUtilitarios">
                              <span className="fa-stack fa-5x">
                                <i className="fa fa-square fa-stack-2x" style={{color:"#6283C1"}}></i>
                                <i className="fa fa-wpforms fa-stack-1x fa-inverse" ></i>
                              </span>
                              <p style={{fontSize:20,color:"black", fontWeight: "bold"}}>UTILITARIOS</p> 
                            </NavLink>
                          </div>
                          <div className="col-md-3" style={{marginTop : 10}}>
                            <NavLink to="/administrarTipoPapeleta"> 
                            <span className="fa-stack fa-5x">
                              <i className="fa fa-square fa-stack-2x" style={{color:"#0F1072"}}></i>
                              <i className="fa fa-address-card fa-stack-1x fa-inverse"></i>
                            </span>
                            <p style={{fontSize:20,color:"black", fontWeight: "bold"}}>TIPOS DE PAPELETA</p> 
                            </NavLink>
                          </div>
                          <div className="col-md-3" style={{marginTop : 10}}>
                            <NavLink to="/administrarTipoDescansoMedico"> 
                            <span className="fa-stack fa-5x">
                              <i className="fa fa-square fa-stack-2x" style={{color:"#115552"}}></i>
                              <i className="fa fa-handshake-o fa-stack-1x fa-inverse"></i>
                            </span>
                            <p style={{fontSize:20,color:"black", fontWeight: "bold"}}>TIPOS DE DESCANSO</p> 
                            </NavLink>
                          </div>
                          <div className="col-md-3" style={{marginTop : 10}}>
                            <NavLink to="/diasDescansoMedico">
                              <span className="fa-stack fa-5x">
                                <i className="fa fa-square fa-stack-2x"style={{color:"ORANGE"}} ></i>
                                <i className="fa fa-ambulance fa-stack-1x fa-inverse" ></i>
                              </span>
                              <p style={{fontSize:20,color:"black", fontWeight: "bold"}}>DÍAS DE DESCANSO</p> 
                            </NavLink>
                          </div>
                          <div className="col-md-3" style={{marginTop : 10}}>
                            <NavLink to="/tipoCese">
                              <span className="fa-stack fa-5x">
                                <i className="fa fa-square fa-stack-2x"style={{color:"#C997C6"}} ></i>
                                <i className="fa fa-caret-square-o-down fa-stack-1x fa-inverse" ></i>
                              </span>
                              <p style={{fontSize:20,color:"black", fontWeight: "bold"}}>TIPOS DE CESE</p> 
                            </NavLink>
                          </div>
                          <div className="col-md-3" style={{marginTop : 10}}>
                            <NavLink to="/seccionesLegajo">
                              <span className="fa-stack fa-5x">
                                <i className="fa fa-square fa-stack-2x"style={{color:"#B9770E"}} ></i>
                                <i className="fa fa-book fa-stack-1x fa-inverse" ></i>
                              </span>
                              <p style={{fontSize:20,color:"black", fontWeight: "bold"}}>SECCIONES DE LEGAJO</p> 
                            </NavLink>
                          </div>
                          <div className="col-md-3" style={{marginTop : 10}}>
                            <NavLink to="/opcionJefatura">
                              <span className="fa-stack fa-5x">
                                <i className="fa fa-square fa-stack-2x"style={{color:"#B9865E"}} ></i>
                                <i className="fa fa-user-circle fa-stack-1x fa-inverse" ></i>
                              </span>
                              <p style={{fontSize:20,color:"black", fontWeight: "bold"}}>OPCIÓN JEFATURA</p> 
                            </NavLink>
                          </div>
                      </div>
                     )
                     
                      }
                      </div>
                    </div>
                  </div>
                </main>
                  }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Areas;