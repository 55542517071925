import React, { Component } from "react";
import { ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import { FormControl } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { sisperplus } from "store/Apis";


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class TipoCese extends Component {

    constructor(props) {
        super(props);
        this.state = {
            path: 'administrarAreas',
            usuario: localStorage.getItem('nickname'),
            token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
            id: JSON.parse(localStorage.getItem('datos'))[0].Person,
            movimientos: [],
            ctxt_descripcion: '',
            item: '',
            nid: '',
            isLoading: true,
            data: []

        }
        this.GetActionFormat = this.GetActionFormat.bind(this);
        this.handleDescripcion = this.handleDescripcion.bind(this);
    }

    handleDescripcion(event) { this.setState({ ctxt_descripcion: event.target.value }); }

    async componentDidMount() {
        if (localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null) {
            axios.get(validator + this.state.path + '/' + this.state.usuario, {
                headers: {
                    Authorization: this.state.token
                }
            }).then((response) => {
                let permisos = response.data;
                if (permisos.codError != 1) {
                    this.props.history.goBack()
                } else {
                    this.listarTipoCese();
                }
            }, (error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    this.props.history.goBack()
                }
            });
        } else {
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }

    async listarTipoCese() {
        this.setState({ isLoading: true })
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'utilitarios/listCeses')
            .then((res) => {
                let lista = [];
                let lista2 = res.data.response;
                if (lista2 != null) {
                    lista2.map((data) => {
                        lista.push({
                            item: data.item,
                            ctxt_descripcion: data.ctxt_descripcion,
                            nid: data.nid                     
                        })
                        this.cancelar()
                    })
                    this.setState({ movimientos: lista, isLoading: false })
                } else {
                    this.setState({ movimientos: [], isLoading: false })
                    this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })
                }
            });
    }

    async guardar() {
        this.setState({ isLoading: true })
        let fromData = new FormData();
        fromData.append('nombreCese', this.state.ctxt_descripcion);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'utilitarios/insertCeses', fromData)
            .then((response) => {
                var datos = response.data
                if (datos.response != null) {
                    this.limpiar();
                    this.props.handleClick("tl", "info", "El tipo de Cese ha sido registrada!", "pe-7s-smile")
                } else {
                    this.props.handleClick("tl", "error", "Hubo un problema con el registro!", "pe-7s-close")
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })
                }
            });
    }

    async obtener(data) {
        this.setState({
            nid: data.nid,
            ctxt_descripcion: data.ctxt_descripcion
        })

    }

    async save() {
        if (this.state.ctxt_descripcion != '') {
            if (this.state.nid == '') {
                this.guardar()
            } else {
                this.update()
            }
        } else {
            this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
        }
    }

    async limpiar() {
        this.cancelar();
        this.listarTipoCese();
    }

    async cancelar() {
        this.setState({ ctxt_descripcion: "", nid: ""})
    }

    async regresar() {
        this.props.history.push('/administrarAreas');
    }

    async update() {
        this.setState({ isLoading: true })
        let fromData = new FormData();
        fromData.append('nid_d', this.state.nid);
        fromData.append('descripcion', this.state.ctxt_descripcion);       
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'utilitarios/updateCeses', fromData)
            .then((response) => {
                var datos = response.data
                if (datos.response != null) {
                    this.limpiar();
                    this.props.handleClick("tl", "info", "El registro ha sido actualizado!", "pe-7s-smile")
                } else {
                    this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })
                }
            });
    }
    
    confirmarDelete = (data) => {
        confirmAlert({
            title: '¿Seguro que desea anular el Registro?',
            message: 'Se anulará el registro seleccionado.',
            buttons: [
                {
                    label: 'Aceptar',
                    onClick: () => this.delete(data)
                },
                {
                    label: 'Cancelar',
                    onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
                }
            ]
        });
    };

    async delete(data) {
        this.setState({ isLoading: true })
        let fromData = new FormData();
        fromData.append('nid_d', data.nid);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'utilitarios/anularCeses', fromData)
            .then((response) => {
                var datos = response.data
                if (datos.response != null) {
                    if (datos.response.estado == 1) {
                        this.listarTipoCese();
                        this.props.handleClick("tl", "info", "El tipo de cese ha sido anulado!", "pe-7s-smile")
                    } else {
                        this.props.handleClick("tl", "error", "No se puede anular el tipo de cese!", "pe-7s-close")
                        this.setState({ isLoading: false })
                    }
                } else {
                    this.props.handleClick("tl", "error", "Hubo un problema con manejar!", "pe-7s-close")
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    console.log(error);
                    this.setState({ isLoading: false })
                }
            });
    }

    GetActionFormat(cell, row) {
        if (row != undefined) {
            return (
                <div>
                    <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{ cursor: 'pointer', fontSize: 20 }} onClick={() => { this.obtener(row) }}>
                        <ReactTooltip />
                        <i className="fa fa-circle fa-stack-2x text-primary"></i>
                        <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{ fontSize: 15 }}></i>
                    </span>
                    <span data-tip="Anular Registro" className="fa-stack fa-2x" style={{ cursor: 'pointer', fontSize: 20 }} onClick={() => { this.confirmarDelete(row) }}>
                        <ReactTooltip />
                        <i className="fa fa-circle fa-stack-2x"></i>
                        <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{ fontSize: 15 }}></i>
                    </span>

                </div>
            );
        } else { }
    }

    render() {
        const columns = [
            { dataField: "item", text: "ITEM", headerStyle: { backgroundColor: '#337AB7', color: "white", width: 80 }, headerAlign: (column, colIndex) => 'center', align: 'center' },
            { dataField: "ctxt_descripcion", text: "TIPO DE CESE", filter: textFilter(), headerStyle: { backgroundColor: '#337AB7', color: "white", width: 450 }, headerAlign: (column, colIndex) => 'center', align: 'center' },
            { dataField: "detail", text: "ACCIONES", headerStyle: { backgroundColor: '#337AB7', color: "white", width: 150 }, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat }
        ]

        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'Primera',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Última',
            showTotal: true,
            totalSize: this.state.movimientos.length
        };
        const contentTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <div>
                    <div>
                        <BootstrapTable
                            striped
                            hover
                            keyField="item"
                            data={this.state.movimientos}
                            columns={columns}
                            filter={filterFactory()}
                            noDataIndication="No existen registros para mostrar"
                            {...paginationTableProps}
                        />
                    </div>
                    <div style={{ float: "right" }}><PaginationListStandalone {...paginationProps} /></div>
                </div>
            </div>
        );
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <div style={{ borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5 }}>
                                <h4 className="title" style={{ fontWeight: 200, marginTop: 18, marginBottom: 25 }}>TIPO DE CESE</h4>
                                <div className="row">


                                    <div className="row-md-12">
                                        <div className="col-md-6" style={{ marginBottom: 18 }}>
                                            <ControlLabel>DESCRIPCIÓN</ControlLabel>
                                            <FormControl type="text" value={this.state.ctxt_descripcion} onChange={this.handleDescripcion} />
                                        </div>

                                        <div className="col-md-2" style={{ marginBottom: 18 }}>
                                            <ControlLabel></ControlLabel><br></br>
                                            <Button style={{ width: 250, marginTop: 5, marginBottom: 10 }} bsStyle="success" fill type="submit" onClick={() => { this.save() }}>Guardar</Button>

                                        </div>
                                        <div className="col-md-2" style={{ marginBottom: 18 }}>
                                            <ControlLabel></ControlLabel><br></br>
                                            <Button style={{ width: 250, marginTop: 5, marginBottom: 10 }} bsStyle="danger" fill type="submit" onClick={() => { this.cancelar() }}>Limpiar</Button>
                                        </div>
                                    </div>
                                </div>
                                {this.state.isLoading ? (
                                    <ClipLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={50}
                                        color={'#123abc'}
                                        loading={this.state.loading}
                                    />
                                ) : (
                                    <PaginationProvider
                                        pagination={
                                            paginationFactory(options)
                                        }
                                    >
                                        {contentTable}
                                    </PaginationProvider>
                                )}
                            </div>
                            <Button style={{ width: 250, marginTop: 10 }} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default TipoCese;