import React, {Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {cec, tipos} from 'store/Apis'
import 'react-tabs/style/react-tabs.css';
import InputFiles from 'react-input-files';
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-accessible-accordion/dist/fancy-example.css';
import identificador  from 'assets/img/identificador.png'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class CrearCarousel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'utilitarioscec',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      identificador:'',
      archivonoticia:'',
      archivo2:'',
      file2:'',
      estado: 0,
      isLoading: false,
      imagen :false,
      show: true,
      archivo: "",
      file: [],
      flg_tipo:  {value: "N", label: "SIN ARCHIVO"},
      tipos: [  
        {value: "N", label: "SIN ARCHIVO"} , 
        {value: "A", label: "CON ARCHIVO"},
        {value: "L", label: "LINK DE NOTICIA"},
        {value: "F", label: "LINK DE FORMULARIO"}
      ] ,
      formulario:""
     
    }
    this.handleIdentificador = this.handleIdentificador.bind(this)
    this.handleFormulario = this.handleFormulario.bind(this)
  
    }
    handleIdentificador(event) {this.setState({identificador: event.target.value});}
    handleFormulario(event) {this.setState({formulario: event.target.value});}
    handleTipo = flg_tipo => {
        this.setState(
            {flg_tipo},
        );
    };

async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
              this.props.history.goBack()
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async regresar(){
    this.props.history.push('/consultarcarousel');
  }

  async validar(){
    if(this.state.archivo != "" && this.state.archivo != "--" && this.state.archivo != null && this.state.archivo != undefined){
      if(this.state.flg_tipo.value == "A"){
        if(this.state.archivo2 != ""){
         this.agregar();
       }else{
        this.props.handleClick("tl", "error", "Debe seleccionar un archivo a subir!", "pe-7s-close")
       }
     }else if(this.state.flg_tipo.value == "F"){
      if(this.state.formulario != ""){
        this.agregar();
      }else{
        this.props.handleClick("tl", "error", "Debe ingresar el link del formulario!", "pe-7s-close")
      }
    }else if(this.state.flg_tipo.value == "L"){
         if(this.state.identificador != ""){
           this.agregar();
         }else{
           this.props.handleClick("tl", "error", "Debe ingresar el identificador de la noticia!", "pe-7s-close")
         }
     }else{
       this.agregar();
     }
    }else{
     this.props.handleClick("tl", "error", "Debe seleccionar una imagen a subir!", "pe-7s-close")
    }
    
   }


  async agregar(){
     this.setState({isLoading: true})
      let fromData = new FormData();
      fromData.append("file", this.state.file);
      fromData.append("file2", this.state.file2 == undefined ? "" : this.state.file2); 
      fromData.append("tipo",this.state.flg_tipo.value);
      fromData.append("imagen",this.state.archivo);
      fromData.append("archivo",this.state.archivo2 == "" && this.state.identificador != "" ? this.state.identificador : 
                                this.state.archivo2 == "" && this.state.identificador == "" ? this.state.formulario :
                                this.state.archivo2 == "" && this.state.formulario=="" ?  "--"  :  this.state.archivo2 
                                 ); 
      fromData.append("usuario", this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${cec}` + 'carousel/insertarCarousel', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          this.props.handleClick("tl", "success", "Carousel registrado correctamente!", "pe-7s-close")
          this.regresar();
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
     
  }

 // documento pdf
  subir2 = (files2) => {
     if(files2.length !== 0){
          this.setState({file2: files2[0], archivo2: files2[0].name});
  }}

 async limpiar2(){
    this.setState({archivo2: "", file2: ""})
  }

//Imagen carousel
  subir = (files) => {
    if(files.length !== 0){
          this.setState({file: files[0], archivo: files[0].name});
    }}

  async limpiar(){
    this.setState({archivo: "", file: ""})
  }

async mostrarimagen(){
  if(this.state.imagen == true){
    this.setState({imagen: false})
  }else{
    this.setState({imagen: true})
  }
}

render() {
  return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>NUEVO CAROUSEL</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                <div >
                    <div className="row">
                        <div className="col-md-6">
                            <ControlLabel>Imagen del Carousel</ControlLabel>
                            <Alert bsStyle="info">
                                <span style={{letterSpacing: 1}}><b> Imagen Seleccionada : </b> {this.state.archivo}</span>
                                </Alert>
                            <Button style={{width: '100%', marginTop: -10}} bsStyle="info" pullRight fill type="submit">
                                <InputFiles  onChange={files => this.subir(files)}>
                                <i className="fa fa-file-image-o"  style={{fontSize: 15}}></i>Seleccionar Imagen
                                </InputFiles>
                            </Button> 
                        </div>
                        {this.state.archivo != "" ? (
                            <div  className="col-md-2">
                                <Button style={{width: '100%', marginTop:25}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar</Button>
                            </div>
                        ) : ( null )}  
                    </div>
                    <div className="row">
                        <div className="col-md-4" style={{marginTop:30 }}>
                            <ControlLabel>Eliga la opción que desea mostrar :</ControlLabel>
                            <Select value={this.state.flg_tipo} onChange={this.handleTipo} options={this.state.tipos}/>
                        </div>
                    </div>
                    <div className="row">
                       {
                       this.state.flg_tipo.value == "F"  ? (
                        <div>
                            <div className="col-md-4" style={{marginTop: 30}}>
                               <ControlLabel>Ingrese el link del formulario:</ControlLabel>
                               <FormControl type="text" value={this.state.formulario} onChange= {this.handleFormulario}/>                             
                            </div>
                        </div>        
                    ) : this.state.flg_tipo.value == "L"  ? (
                            <div>
                                <div className="col-md-4" style={{marginTop: 30}}>
                                   <ControlLabel>Ingrese el Identificador de la noticia :</ControlLabel>
                                   <FormControl type="text" value={this.state.identificador} onChange= {this.handleIdentificador}/>
                                    <div style={{marginTop: 10}}>
                                      <i className="fa fa-exclamation-circle"  style={{ marginRight:-10}}>  </i> 
                                        <Button style={{border:0}} onClick={() => { this.mostrarimagen() }}> ¿Dónde se encuentra el identificador de la noticia?</Button>
                                    </div> 
                                </div>
                                <div className="col-md-12">
                                    {this.state.imagen ? (
                                      <div className="row" style={{marginBottom: 20}}>
                                        <img src={identificador} style={{width:1000,height:300}}></img>
                                      </div>
                                    ) : (null)}
                                </div>
                            </div>        
                        ) : this.state.flg_tipo.value == "A" ? (
                            <div className="col-md-4"  style={{marginTop: 30}}>
                                <ControlLabel>Subir Documento:</ControlLabel>
                                <Alert bsStyle="info">
                                    <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo2}</span>
                                </Alert>
                                <Button style={{width: '100%', marginTop:-10}} bsStyle="info" pullRight fill type="submit">
                                    <InputFiles accept={'pdf/*'}  onChange={files2 => this.subir2(files2)}> Seleccionar Documento </InputFiles>
                                </Button> 
                                {this.state.archivo2 != "" ? (
                                 <Button style={{width: '100%', marginTop:15}} bsStyle="danger" type="submit" onClick={() => { this.limpiar2() }}>Limpiar</Button>
                                ) : ( null )}  
                            </div>
                        ) : ( null )
                        }  
                    </div>
                 <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Registro</Button>
                 <Button style={{marginRight: 10, marginTop: 20}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.validar() }}>Guardar Registro</Button>
                </div>
                )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CrearCarousel;