import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import ReactExport from 'react-export-excel';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ReporteGeneralV extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarVacaciones',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      anio:"",
      years : [],
      mes: "",//{value: "00", label: "TODOS"},
      meses: [ 
        {value: "01", label: "ENERO"},
        {value: "02", label: "FEBRERO"},
        {value: "03", label: "MARZO"},
        {value: "04", label: "ABRIL"},
        {value: "05", label: "MAYO"},
        {value: "06", label: "JUNIO"},
        {value: "07", label: "JULIO"},
        {value: "08", label: "AGOSTO"},
        {value: "09", label: "SETIEMBRE"},
        {value: "10", label: "OCTUBRE"},
        {value: "11", label: "NOVIEMBRE"},
        {value: "12", label: "DICIEMBRE"},
      ],
      anioSeleccionado: "2024",
      mesSeleccionado: "",
      areaSeleccionado: "",
      trabajadorSeleccionado: "",
      areas: [],
      trabajadores: [],
      area: "",
      trabajador: "",
      multiDataSet: [],
      editable: false,     
    }  
  }

  handleAnio = anio =>{
    this.setState({anio});
    console.log("opcion seleccionada: " + anio);        
    let valor="";
    this.setState({anioSeleccionado: ""})
    if(anio != null){
      this.setState({editable: false})
      anio.map((data,index) => {    
          if (index==0) {
              valor = data.label
          }else{
              valor = valor+","+data.label
          }        
        })
      this.setState({anioSeleccionado: valor})
    }else{
      this.setState({mes: ""})
      this.setState({mesSeleccionado: ""})
      this.setState({editable: true})
    }
  }

  handleMes = mes => {
    this.setState({ mes });
    let valor="";
    this.setState({mesSeleccionado: ""})
    if(mes != null){
      mes.map((data,index) => {    
          if (index==0) {
              valor = data.value
          }else{
              valor = valor+","+data.value
          }        
        })
      this.setState({mesSeleccionado: valor})
    }   
  };

  
  handleArea = area => {    
    this.setState({ area });
    let valor="";
    this.setState({areaSeleccionado: ""})
    if(area != null){
      area.map((data,index) => {    
          if (index==0) {
              valor = data.value
          }else{
              valor = valor+","+data.value
          }        
        })
      this.setState({areaSeleccionado: valor})
      this.listarTrabajadores(valor)
    }else{
      this.listarTrabajadores('')
    }   
  };

  handleTrabajador = trabajador => {
    this.setState({ trabajador });
    let valor="";
    this.setState({trabajadorSeleccionado: ""})
    if(trabajador != null){
      trabajador.map((data,index) => {    
          if (index==0) {
              valor = data.value
          }else{
              valor = valor+","+data.value
          }        
        })
      this.setState({trabajadorSeleccionado: valor})      
    }   
  };
  
 async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
             this.generarPeriodos();
             this.listVacaciones();
             this.listarAreas();
             this.listarTrabajadores('');
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  generarPeriodos(){
    let year =  2000;
    let cont = 26;
    let a = Array.from(new Array(cont),(val, index) => index + year);
    let array = a.reverse();
    let cont2 = 0;
    let lista = [];
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
          this.setState({anio: {value: cont2, label: data}})
        }
      })   
    this.setState({years: lista})    
 }

  async listVacaciones() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'vacaciones/reporteVacaciones?anio=' + this.state.anioSeleccionado + "&mes=" + this.state.mesSeleccionado + "&idarea=" + this.state.areaSeleccionado + "&idpersona=" + this.state.trabajadorSeleccionado)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              item:data.item,
              dni : data.DocumentNumber,    
              controlanio: data.anio,                        
              fechaIni : Calendar.calendarioA(data.Datebegin),
              fechaFin: Calendar.calendarioA(data.Dateend),  
              dias : data.Days,
              periodo: data.PRPeriod,
              apellidosnombres :data.nombres,
              tipoplanilla: data.tipoplanilla,
              idperson :data.Person,                             
              nomarea: data.area,
              alerta: data.alertaperiodo,              
            }) 
          }) 
          //this.setState({movimientos: lista, isLoading: false})     
          this.generarExcel(lista);   
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
    
  }

  
  async listarAreas(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'labores/listAreas')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.ReplicationUnit,
                label: data.name,
            })
          });
          //this.setState({areas: lista, area: {value: lista[0].value, label: lista[0].label}})
          this.setState({areas: lista})
          //this.listarTrabajadores()
      }else{
        this.setState({areas: []})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarTrabajadores(idarea){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'descanso/listPersonasxMultiarea?nid=' + idarea)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.idPerson,
                label: data.nombre,
                dni: data.dni
            })
          });
          //this.setState({trabajadores: lista, trabajador:{value: lista[0].value, label: lista[0].label, dni: lista[0].dni}})
          this.setState({trabajadores: lista})
      }else{
        this.setState({trabajadores: []})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  regresar(){
    this.props.history.push("/administrarVacaciones")
  }

  generarExcel(lista){
    let dataExcel = [];
    lista.map((data) => {
      dataExcel.push(
        [          
          {value: JSON.stringify(data.item), style: {font: {sz: "10.5", bold: true}, alignment: {horizontal: "center"}}},
          {value: data.controlanio == null ? '' : data.controlanio},
          {value: data.periodo == null ? '' : data.periodo},
          {value: data.dni == null ? '' : data.dni},
          {value: data.apellidosnombres == null ? '' : data.apellidosnombres},
          {value: data.nomarea == null ? '' : data.nomarea},
          {value: data.tipoplanilla == null ? '' : data.tipoplanilla},
          {value: data.fechaIni == null ? '' : data.fechaIni},
          {value: data.fechaFin == null ? '' : data.fechaFin},
          {value: data.dias == null ? '' : JSON.stringify(data.dias)},                
          {value: data.alerta == null ? '' : data.alerta},   
        ]
      )
    });
    const dataSet = [
      {
        xSteps: 5,
        columns: ["DETALLE DE VACACIONES"],
        data: []
      },
      {
        columns: ["TRIBUNAL CONSTITUCIONAL DEL PERÚ"],   
        data: [
            [{value: "RUC: 20217267618", style: {font: {sz: "10.5", bold: true}}}],            
            [{value: "FECHA: " + Calendar.calendario9(new Date()), style: {font: {sz: "10.5", bold: true}}}]
        ]       
      },          
      {
        columns: ["ITEM", "AÑO", "PERIODO", "DNI", "APELLIDOS Y NOMBRES", "AREA", "TIPO PLANILLA","F.INICIO", "F.FIN", "N° DÍAS", "OBSERVACIÓN"],
        data: dataExcel
      },     
    ];
    this.setState({
      movimientos: lista, 
      multiDataSet: dataSet,      
      isLoading: false
    })
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white",width:50}, headerAlign: (column, colIndex) => 'center', align: 'center'},      
      {dataField: "controlanio", text: "AÑO",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "periodo", text: "PERIODO",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "dni", text: "DNI",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "apellidosnombres", text: "APELLIDOS Y NOMBRES",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:250}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nomarea", text: "AREA",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:250}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipoplanilla", text: "TIPO PLANILLA",  headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechaIni", text: "FEC. INICIO", headerStyle: {backgroundColor: '#337AB7', color: "white", width: 150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechaFin", text: "FEC. TERMINO", headerStyle: {backgroundColor: '#337AB7', color: "white", width: 150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "dias", text: "N° DÍAS", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},          
      {dataField: "alerta", text: "OBSERVACIÓN", headerStyle: {backgroundColor: '#337AB7', color: "white",width:250}, headerAlign: (column, colIndex) => 'center', align: 'center'},    
    ]
    
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const { area, trabajador } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>REPORTE VACACIONES</h4>
              <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>AÑO</ControlLabel>
                    <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years} isMulti/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                  <ControlLabel>MES</ControlLabel>
                  <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses} isDisabled={this.state.editable} isMulti/>
                </div>                
                <div className="col-md-4" style={{marginBottom: 18}}>
                  <ControlLabel>ÁREAS</ControlLabel>
                  <Select value={area} onChange={this.handleArea} options={this.state.areas} isMulti/>
                </div>
                <div className="col-md-6" style={{marginBottom: 18}}>
                  <ControlLabel>TRABAJADORES</ControlLabel>
                  <Select value={trabajador} onChange= {this.handleTrabajador} options={this.state.trabajadores} isMulti />
                </div>
                <div className="col-md-4" style={{marginBottom: 18}}></div>
                <div className="col-md-2">                  
                  <Button style={{width: 200, marginBottom: 10, marginTop:-55}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.listVacaciones() }}>Buscar </Button> 
                  <ExcelFile filename="ReporteVacaciones" element={<Button style={{width: 200, marginLeft: 10}} bsStyle="success" pullRight fill type="submit">Exportar a Excel</Button>} >
                    <ExcelSheet dataSet={this.state.multiDataSet} name="Reporte">                      
                    </ExcelSheet>
                  </ExcelFile>
                </div>                
              </div>              
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
              <Button style={{width: 220, marginTop: 5, marginLeft: 10, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>

            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ReporteGeneralV;