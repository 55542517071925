import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import moment from 'moment';
import 'moment/locale/es';

const styles = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 45,
      paddingHorizontal: 35,
      flexGrow: 1,
    },
    title: {
      fontSize: 15,
      textAlign: 'center',
      marginBottom: 15,
    },
    author2: {
      fontSize: 16,
      textAlign: 'center',
      marginTop: 10
    },
    subtitle3: {
      fontSize: 10,
      marginBottom: 8,
      textAlign: 'right',
    },
    subtitle4: {
      fontSize: 10,
      marginBottom: 8,
      textAlign: 'right',
      marginTop: 40,
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    texto2: {
        fontSize: 10,
        marginTop: 10,
    },
    texto3: {
        fontSize: 12,
        fontWeight: 600,
        /*marginTop: 20,
        marginLeft: 15*/
    },
    texto4: {
        fontSize: 10,
        textAlign: "left",
        marginTop: 20,
        marginBottom: 20
    },
    image: {
      marginVertical: 11,
      marginHorizontal: 230,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderRightWidth: 0, 
        marginTop: 15, 
        marginBottom: 20,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderBottomWidth: 0
      }, 
      table2: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderRightWidth: 0, 
        marginTop: 15, 
        marginBottom: 10,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderBottomWidth: 0
      }, 
      tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
      }, 
      tableColHeader: { 
        width: "65em", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      }, 
      tableColHeader2: { 
        width: "25%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 1, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      }, 
      tableColHeader3: { 
        width: "30%",
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },    
      tableColHeader4: { 
        width: "35%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 1, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },         
      tableCol: { 
        width: "10%", 
        borderStyle: "solid", 
        textAlign: 'center',
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,  
        borderTopWidth: 0,
        borderBottomWidth: 0  
      },  
      tableCol2: { 
        width: "25%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 1, 
        borderTopWidth: 0,
        borderBottomWidth: 1 
      }, 
      tableCol3: { 
        width: "65em", 
        borderStyle: "solid", 
        textAlign: 'center',
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderTopWidth: 0,
        borderBottomWidth: 1  
      },  
      tableCol4: { 
        width: "65em", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderRightWidth: 0, 
        borderBottomWidth: 0,
      },  
      tableCol5: { 
        width: "25%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderRightWidth: 0, 
        borderBottomWidth: 0, 
      }, 
      tableCol6: { 
        width: "70%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderRightWidth: 1, 
        borderBottomWidth: 1, 
      }, 
      tableCol7: { 
        width: "35%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 1, 
        borderTopWidth: 0,
        borderBottomWidth: 1 
      }, 
      tableCol8: { 
        width: "35%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderRightWidth: 0, 
        borderBottomWidth: 0, 
      }, 
      tableCellHeader: {
        margin: "auto", 
        margin: 5, 
        fontSize: 8,
        fontWeight: 500,
        textAlign: 'center'
      },  
      tableCell: { 
        margin: "auto", 
        margin: 5, 
        fontSize: 8
      },
      row: {
        flexDirection: 'row',
        marginBottom: 30,
      },
});

export function caratulaLegajo(legajo) {

    return(

        <Document>
            <Page size="A4" orientation="portrait" style={styles.body}>
                <Image style={styles.image} src="./icon.png"/>
                <Text style={styles.title}>Tribunal Constitucional del Perú</Text>
                <Text style={styles.texto3}>I. INFORMACIÓN PERSONAL Y FAMILIAR</Text>  
                <Text style={styles.texto2}>DATOS PERSONALES</Text>  
                <View style={styles.table2}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader3}> 
                            <Text style={styles.tableCellHeader}>APELLIDOS Y NOMBRES</Text> 
                        </View>
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCellHeader}>{legajo.datospersonales[0].nombrecompleto}</Text> 
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader3}> 
                            <Text style={styles.tableCellHeader}>TIPO Y NRO. DE DOCUMENTO</Text> 
                        </View>
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCellHeader}>{legajo.datospersonales[0].destipodocumento + ' - ' + legajo.datospersonales[0].numdocumento}</Text> 
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader3}> 
                            <Text style={styles.tableCellHeader}>FECHA DE NACIMIENTO</Text> 
                        </View>
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCellHeader}>{legajo.datospersonales[0].fechanacimiento}</Text> 
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader3}> 
                            <Text style={styles.tableCellHeader}>LUGAR DE NACIMIENTO</Text> 
                        </View>
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCellHeader}>{legajo.datospersonales[0].nomdept_nac + ' / ' + legajo.datospersonales[0].nomprov_nac + ' / ' + legajo.datospersonales[0].nomdist_nac}</Text> 
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader3}> 
                            <Text style={styles.tableCellHeader}>DIRECCIÓN DE DOMICILIO</Text> 
                        </View>
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCellHeader}>{legajo.datospersonales[0].direccion + ' - ' + legajo.datospersonales[0].calle + ' - ' + legajo.datospersonales[0].numdomicilio + ' - ' + legajo.datospersonales[0].urbanizacion}</Text> 
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader3}> 
                            <Text style={styles.tableCellHeader}>LUGAR DE DOMICILIO</Text> 
                        </View>
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCellHeader}>{legajo.datospersonales[0].nomdept_dom + ' / ' + legajo.datospersonales[0].nomprov_dom + ' / ' + legajo.datospersonales[0].nomdist_dom}</Text> 
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader3}> 
                            <Text style={styles.tableCellHeader}>ESTADO CIVIL</Text> 
                        </View>
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCellHeader}>{legajo.datospersonales[0].desestadocivil}</Text> 
                        </View>                        
                    </View>
                    <View style={styles.tableRow}>                        
                        <View style={styles.tableColHeader3}> 
                            <Text style={styles.tableCellHeader}>CORREO</Text> 
                        </View>
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCellHeader}>{legajo.datospersonales[0].correo}</Text> 
                        </View>
                    </View>
                    <View style={styles.tableRow}>                        
                        <View style={styles.tableColHeader3}> 
                            <Text style={styles.tableCellHeader}>TELÉFONO</Text> 
                        </View>
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCellHeader}>{legajo.datospersonales[0].telefono}</Text> 
                        </View>
                    </View>
                    <View style={styles.tableRow}>                        
                        <View style={styles.tableColHeader3}> 
                            <Text style={styles.tableCellHeader}>CELULAR</Text> 
                        </View>
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCellHeader}>{legajo.datospersonales[0].celular}</Text> 
                        </View>
                    </View>                    
                </View>
                <Text style={styles.texto2}>DATOS FAMILIARES</Text>  
                <View style={styles.table}> 
                    <View style={styles.tableRow}>
                        
                        <View style={styles.tableColHeader2}> 
                            <Text style={styles.tableCellHeader}>APELLIDOS Y NOMBRES</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>DNI</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>PARENTESCO</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>GENERO</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>FECHA DE NACIMIENTO</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>SITUACIÓN</Text> 
                        </View>
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>DISCAPCIDAD</Text> 
                        </View> 
                    </View>
                    {legajo.datosfamiliares.map((data,i) => {
                        return(
                            <View style={styles.tableRow}> 
                                
                                <View style={styles.tableCol2}> 
                                    <Text style={styles.tableCell}>{data.nombrecompleto}</Text> 
                                </View> 
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.numerodocumento}</Text> 
                                </View> 
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.desparentesco}</Text> 
                                </View> 
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.genero}</Text> 
                                </View> 
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.fechanacimiento}</Text> 
                                </View> 
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.situacion}</Text> 
                                </View>
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.esdiscapacitado}</Text> 
                                </View>
                            </View> 
                        );
                    })}
                    <View style={styles.tableRow}>                        
                        <View style={styles.tableCol5}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View>
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View>
                    </View> 
                </View>
                <Text style={styles.texto3}>II. INCORPORACIÓN</Text> 
                <Text style={styles.texto4}>No registra información.</Text> 
                <Text style={styles.texto3}>III. FORMACIÓN ACADÉMICA Y CAPACITACIÓN</Text>
                <View style={styles.table}> 
                    <View style={styles.tableRow}>                        
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>TIPO DE ESTUDIO</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>NIVEL DE ESTUDIO</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>INSTITUCIÓN</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>ESPECIALIDAD</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>FECHA DE INICO</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>FECHA FIN</Text> 
                        </View>
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>AÑOS DE ESTUDIO</Text> 
                        </View>
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>NRO. COLEGIATURA</Text> 
                        </View>                       
                    </View>
                    {legajo.formacionacademica.map((data,i) => {
                        return(
                            <View style={styles.tableRow}> 
                                
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.destipoestudio}</Text> 
                                </View> 
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.desnivelestudio}</Text> 
                                </View> 
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.nominstitucion}</Text> 
                                </View> 
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.especialidad}</Text> 
                                </View> 
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.fechainicio}</Text> 
                                </View>
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.fechafin}</Text> 
                                </View>
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.aniosestudio}</Text> 
                                </View>
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.numcolegiatura}      
                                    </Text> 
                                </View>                               
                            </View>  
                        );
                    })}
                    <View style={styles.tableRow}> 
                        
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View>
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View>
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View>
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View>                        
                    </View>  
                </View>                
                <Text style={styles.texto3}>IV. EXPERIENCIA LABORAL</Text>       
                <View style={styles.table}> 
                    <View style={styles.tableRow}>
                        
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>SECTOR</Text> 
                        </View> 
                        <View style={styles.tableColHeader2}> 
                            <Text style={styles.tableCellHeader}>ENTIDAD</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>FECHA DE INICIO</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>FECHA FIN</Text> 
                        </View> 
                        <View style={styles.tableColHeader4}> 
                            <Text style={styles.tableCellHeader}>FUNCIONES DESEMPEÑADAS</Text> 
                        </View>                         
                    </View>
                    {legajo.experiencialaboral.map((data,i) => {
                        return(
                            <View style={styles.tableRow}> 
                                
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.sector}</Text> 
                                </View> 
                                <View style={styles.tableCol2}> 
                                    <Text style={styles.tableCell}>{data.entidad}</Text> 
                                </View> 
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.fechainicio}</Text> 
                                </View> 
                                <View style={styles.tableCol3}> 
                                    <Text style={styles.tableCell}>{data.fechafin}</Text> 
                                </View> 
                                <View style={styles.tableCol7}> 
                                    <Text style={styles.tableCell}>{data.funciones}</Text> 
                                </View>                             
                            </View>                         
                        );                    
                    })}
                    <View style={styles.tableRow}> 
                        
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol5}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol8}> 
                            <Text style={styles.tableCell}></Text> 
                        </View>                             
                    </View>  
                </View>
                <Text style={styles.texto3}>V. MOVIMIENTOS DEL PERSONAL</Text>
                <Text style={styles.texto4}>No registra información.</Text> 
                <Text style={styles.texto3}>VI. COMPENSACIONES</Text>  
                <Text style={styles.texto4}>No registra información.</Text> 
                <Text style={styles.texto3}>VII. EVALUACIÓN DE DESEMPEÑO, PROGRESIÓN EN LA CARRERA Y DESPLAZAMIENTO</Text>  
                <Text style={styles.texto4}>No registra información.</Text> 
                <Text style={styles.texto3}>VIII. RECONOCIMIENTOS Y SANCIONES DISCIPLINARIAS</Text>  
                <Text style={styles.texto4}>No registra información.</Text> 
                <Text style={styles.texto3}>IX. RELACIONES LABORALES INDIVIDUALES Y COLECTIVAS</Text>  
                <Text style={styles.texto4}>No registra información.</Text> 
                <Text style={styles.texto3}>X. SEGURIDAD Y SALUD EN EL TRABAJO (SST) Y BIENESTAR SOCIAL</Text>  
                <Text style={styles.texto4}>No registra información.</Text> 
                <Text style={styles.texto3}>XI. DESVINCULACIÓN</Text>  
                <Text style={styles.texto4}>No registra información.</Text> 
                <Text style={styles.texto3}>XII. OTROS QUE CONSIDERE LA ENTIDAD</Text>  
                <Text style={styles.texto4}>No registra información.</Text> 
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>            
        </Document>

    );

}
