import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {sisperplus} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Categorias extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'resoluciones',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      nombre:"",
      descripcion:"",    
      nid:""
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.handleNombre = this.handleNombre.bind(this);
    this.handleDescripcion  =this.handleDescripcion.bind(this);
  }
    handleNombre(event) {this.setState({nombre: event.target.value});}
    handleDescripcion(event) {this.setState({descripcion: event.target.value});}
 

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.listCategorias();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listCategorias() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'resoluciones/listCategorias')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              nombre: data.nombre,
              descripcion: data.descripcion,
              item: data.item,
              nid: data.nid,
            })
          })
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        
        this.setState({isLoading: false})
      }
    });
  }

  async save(){
    if(this.state.nombre != ''){
      if(this.state.nid == ''){
        this.guardar()
      }else{
        this.update()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

    guardar(){
        this.setState({isLoading: true})
          let fromData = new FormData();
          fromData.append('nombre', this.state.nombre);
          fromData.append('descripcion', this.state.descripcion);
          fromData.append('usuario', this.state.usuario);
          axios.defaults.headers.common['Authorization'] = this.state.token
          axios.post(`${sisperplus}` + 'resoluciones/insertCategorias', fromData)
          .then((response) => {
            var datos = response.data
            if(datos.response != null){
                this.limpiar();
                this.props.handleClick("tl", "info", "La categoría ha sido registrada!", "pe-7s-smile")
            }else{
                this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
                this.setState({isLoading: false})
            }
            })
            .catch((error) =>{ 
              if(auth0Client.isAuthenticated() === true){
                  auth0Client.signOut()
                  this.props.history.replace('/');
              }else{
                  console.log(error);
                  this.setState({isLoading: false})
              }
            });
    }

    update(){
      this.setState({isLoading: true})
        let fromData = new FormData();
        fromData.append('nidCat', this.state.nid);
        fromData.append('nombre', this.state.nombre);
        fromData.append('descripcion', this.state.descripcion);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'resoluciones/updateCategorias', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
              this.limpiar();
              this.props.handleClick("tl", "info", "La categoría ha sido actualizada!", "pe-7s-smile")
          }else{
              this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
              this.setState({isLoading: false})
          }
          })
          .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
            }else{
                console.log(error);
                this.setState({isLoading: false})
            }
          });
      }
      
    async limpiar(){
      this.listCategorias();
      this.cancelar();
    }

    async cancelar(){
      this.setState({ nombre: "", descripcion: "",nid:""})
    }
    
    confirmarDelete = (data) => {
      confirmAlert({
        title: '¿Seguro que desea eliminar la categoría?',
        message: 'Se anulará el registro seleccionado.',
        buttons: [
          {
            label: 'Aceptar',
            onClick: () => this.delete(data)
          },
          {
            label: 'Cancelar',
            onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
          }
        ]
      });
    };

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidCat', data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'resoluciones/anularCategorias', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listCategorias()
        this.props.handleClick("tl", "info", "La categoría ha sido eliminada!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
  
  async regresar(){
    this.props.history.push("/resoluciones")
  }
 
  async obtener(data){
    this.setState({
       nombre: data.nombre,
       descripcion: data.descripcion == null ? "" : data.descripcion,
       nid: data.nid,
      })
  }


  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Anular Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-black"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  render() {
    const columns = [
    {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "nombre", text: "CATEGORÍA", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "descripcion", text: "DESCRIPCIÓN",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align: 'center'},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
      <Grid fluid>
      <Row>
          <Col md={12}>
            <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>LISTA DE CATEGORÍAS</h4>
                <div className="row" style={{marginBottom: 10}}>
                    <div className="col-md-6" style={{marginBottom: 18}}>
                        <ControlLabel>CATEGORÍA </ControlLabel>
                        <FormControl type="text" value={this.state.nombre} onChange={this.handleNombre}/>
                    </div>
                    <div className="col-md-4" style={{marginBottom: 18}}>
                        <ControlLabel>DESCRIPCIÓN</ControlLabel>
                        <FormControl type="text" value={this.state.descripcion} onChange={this.handleDescripcion}/>
                    </div>
                 
                 
                        <Button style={{width: 200, marginBottom: 10, marginRight: 15}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.save() }}>GUARDAR</Button>
                        <Button style={{width: 200, marginBottom: 10, marginRight: 15}} bsStyle="danger" pullRight type="submit" onClick={() => { this.cancelar() }}>CANCELAR</Button>
                  
                </div>
              
                {this.state.isLoading ? (
                        <ClipLoader
                          css={override}
                          sizeUnit={"px"}
                          size={50}
                          color={'#123abc'}
                          loading={this.state.loading}
                        />
                      ) : (
                        <PaginationProvider
                          pagination={
                            paginationFactory(options)
                          }
                        >
                          { contentTable }
                        </PaginationProvider>
                  )}
            </div>
         
            <Button style={{width: 250, marginTop: 10, marginLeft:15}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
            
          </Col>
        </Row>
      </Grid>
    </div>
    );
  }
}

export default Categorias;