import React, { Component } from "react";
import { ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';

import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import InputFiles from 'react-input-files';

import axios from 'axios';
import { paramTesoreria, tesoreria } from 'store/Apis'
import Calendar from "components/Calendar/Calendar.jsx";

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class EditarComprobante extends Component {

  inputRef = React.createRef();
  inputRef2 = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      path: 'comprobantes',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      isLoading: true,
      documentos: [],
      file: [],
      archivo: "",
      numero: "",
    }
    this.GetActionFormat = this.GetActionFormat.bind(this);

  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(localStorage.getItem("movimientocomp") !== null){
        this.setState({movimiento: JSON.parse(localStorage.getItem('movimientocomp')).nidmovimiento,
                       numero: JSON.parse(localStorage.getItem('movimientocomp')).numero});
        this.listarDocumentos();
    }else{
      this.props.history.push('/comprobantes');
    }
  }

  async listarDocumentos() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'detallecomprobante/listDocumento?nidcomprobante=' + this.state.movimiento)
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push({ 
            ctxt_archivo: data.ctxt_archivo,
            ctxt_ruta: data.ctxt_ruta,
            item: data.item,
            nid: data.nid      
          })
        })
        this.setState({documentos: lista, isLoading: false})
      }else{
        this.setState({documentos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async guardar(){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('file', this.state.file);
    fromData.append('archivo',  this.state.archivo);
    fromData.append('comprobante', this.state.movimiento);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'detallecomprobante/insertarDocumento', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.actualizarComprobante();
        this.props.handleClick("tl", "info", "El documento ha sido registrado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('niddocumento', data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'detallecomprobante/anularDocumento', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.actualizarComprobante();
        this.props.handleClick("tl", "info", "El documento ha sido eliminado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async actualizarComprobante() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'comprobantes/listMovimientos?tipo=0&fechaini=' + paramTesoreria.comprobantes[0].fechaini + '&fechafin=' + paramTesoreria.comprobantes[1].fechafin)
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { 
              item: data.item,
              nidmovimiento: data.nid,
              numero: data.cnum_numero, 
              cheque: data.ctxt_cheque_carta,
              registro: data.cnum_siaf,
              orden: data.cnum_orden,
              documento: data.cnum_documento,
              nombre: data.ctxt_nombre,
              concepto: data.ctxt_concepto,
              liquido: data.cnum_liquido != null ? 'S/.    ' + data.cnum_liquido.toFixed(2) : 'S/. 0.00', 
              fecha: Calendar.calendario12(data.dfec_ingreso),
              fecha2: data.dfec_ingreso,
              activo: data.item == 1 ? '1' : '0',
              documentox: data.documento
          })
        })
        console.log("entreee")
        paramTesoreria.comprobantes[2].data = lista
      }else{
        paramTesoreria.comprobantes[2].data = []
      }
      localStorage.setItem('data', JSON.stringify(paramTesoreria))
      this.listarDocumentos();
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading1: false, isLoading2: false})
      }
    });
  }

  async save(){
    if(this.state.archivo != ""){
      this.guardar()
    }else{
      this.props.handleClick("tl", "warning", "Debe seleccionar un archivo para guardar!", "pe-7s-info")
    }
  }

  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], archivo: files[0].name});
    }
  }

  async volver(){
    this.props.history.push('/comprobantes');
  }

  confirmarDelete = (datos) => {
    confirmAlert({
      title: '¿Seguro de eliminar?',
      message: 'Se eliminará la codificación seleccionada.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(datos)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async detalle(data){
    window.open(data.ctxt_ruta, "_blank")
  }

  async cancelar(){
    this.setState({archivo: "", file: []})
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Ver documento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                <i className="fa fa-file-pdf-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Remover Documento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  render() {
    const columnsDocumentos = [
        {dataField: "ctxt_archivo", text: "DOCUMENTO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const {numero} = this.state
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Detalle del Comprobante de Pago: N° {numero}</h4>
                <div className="row" style={{marginBottom: 10}}>
                    <div className="col-md-12">
                        <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Documentos del Comprobante</h4>
                        <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>SUBIR NUEVO DOCUMENTO</ControlLabel>
                                <Alert bsStyle="warning">
                                    <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo}</span>
                                </Alert>
                                <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                                    <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                                </Button>
                            </div>
                        </div>
                        <div style={{marginTop: 10, marginBottom: 10}}>
                            <Button style={{width: 200, marginLeft: 10, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.save() }}>Guardar</Button>
                            <Button style={{width: 200, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Limpiar</Button>
                        </div>
                        {this.state.isLoading ? (
                            <ClipLoader
                                css={override}
                                sizeUnit={"px"}
                                size={50}
                                color={'#123abc'}
                                loading={this.state.loading}
                            />
                        ) : (
                            <BootstrapTable striped hover keyField="item" data={ this.state.documentos } columns={ columnsDocumentos }/>
                        )}
                    </div>
                </div>
              </div>
            </Col>
            <Button style={{width: 200, marginTop: 15, marginRight: 30}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.volver() }}>Volver</Button>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarComprobante;