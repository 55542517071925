import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, fechasBusqueda,paramRRHH} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { confirmAlert } from 'react-confirm-alert';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;


class VerPapeletaTrabajador extends Component {

    constructor(props) {
        super(props);
        this.state = {
          path: 'administrarPapeletas',
          usuario: localStorage.getItem('nickname'),
          token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
          id: JSON.parse(localStorage.getItem('datos'))[0].Person,
          movimientos: [],
          person:"",
          nombre:"",
          isLoading: true
        }
        this.verdocumento= this.verdocumento.bind(this);
        this.GetActionFormat= this.GetActionFormat.bind(this);
     }
    
    
      async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{
                    //this.listPapeletas()
                    this.validarLocal()
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
      }
      async validarLocal(){ 
        if(JSON.parse(localStorage.getItem("recursoshumanos")).trabajadoresAdm[0].data !== null){
              this.setState({
                      person: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.person ,
                      dni2: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.documentNumber,
                      nombre: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.nombreCompleto,
                      ruta: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.ruta,
                      enlace: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[1].enlace,
                      
              });
              this.listPapeletas();
           
        }}
   
    
      async listPapeletas() {
        this.setState({isLoading: true})
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'trabajadores/listPapeletaTrabajadores?person=' + this.state.person)
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({ 
                  fechaIni : Calendar.calendario9(data.FechaInicio),
                  fechaFin: Calendar.calendario9(data.fechaFin),
                  archivo: data.adjunto,
                  anio: data.anio,
                  tipopapeleta: data.descripcion,
                  idtipopapeleta: data.tipopapeleta,
                  documentcomments: data.documentcomments== null ? "":data.documentcomments, 
                  estado: data.estado,
                  flagjustifica: data.flagjustifica,
                  idpapeleta: data.idpapeleta,
                  mes: data.mes,
                  observacion: data.observacion,
                  nombres : data.personname,
                  dni: data.DocumentNumber,
                  fecharegistro : Calendar.calendario9(data.registerdate),
                  item: data.item,
                  idperson: data.person,
                  ruta:"T"
                }) 
              }) 
             this.setState({movimientos: lista, isLoading: false})
            
            }else{
              this.setState({movimientos: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
        
      }
    
      confirmarDelete = (data) => {
        confirmAlert({
          title: '¿Seguro que desea eliminar la papeleta?',
          message: 'Se anulará el registro seleccionado.',
          buttons: [
            {
              label: 'Aceptar',
              onClick: () => this.delete(data)
            },
            {
              label: 'Cancelar',
              onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
            }
          ]
        });
      };
    
      async delete(data){
        this.setState({isLoading: true})
        let fromData = new FormData();
        fromData.append('pape', data.idpapeleta);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'papeletaAdm/deletePapeletaAdm', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response == null){
            this.listPapeletas()
            this.props.handleClick("tl", "info", "Tu papeleta ha sido eliminada!", "pe-7s-smile")
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
            this.setState({isLoading: false})
          }
        })
        .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
      }
    
      async mostrar(data){
        let archivo = data.archivo;
        let anio = Calendar.calendario11(data.fecharegistro).substr(0,4);
        let mes = Calendar.calendario11(data.fecharegistro).substr(5,2);
        window.open("https://gestordocumentaltc.sedetc.gob.pe/index.php/s/ag7Dq3JcdRKr5Zg/download?path=%2F&files="  + anio + "/" + mes + "/" + archivo)
      }
    
      GetActionFormat(cell, row) {
        if(row != undefined){
        return (
            <div>
                <span data-tip="Ver Papeleta" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20,float:"left"}} onClick={() => { this.ver(row) }}>
                  <ReactTooltip />
                  <i className="fa fa-circle fa-stack-2x text-primary"></i>
                  <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                </span>
                {row.estado == 'PENDIENTE' ? (
                <span  data-tip="Eliminar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                  <ReactTooltip />
                  <i className="fa fa-circle fa-stack-2x"></i>
                  <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                </span>) : (null)}
            </div>
        );
      }else{}
    }
    
   
    verdocumento(cell, row) {
      return (
            <div>
                {row.archivo == null ? 
                ( 
                   <p style={{fontSize: 14,borderRadius: 2}}>{row.estado}</p>
                ) : (
                  <Button data-tip="Ver Autorización" bsStyle="success" type="submit" onClick={() => { this.mostrar(row) }}>{row.estado}<ReactTooltip /></Button>
                )}
            </div>
        );
    }
     async ver(data){
      paramRRHH.trabajadoresAdm[1].enlace = "T"
      paramRRHH.papeletaAdm[1].enlace = "T"
      paramRRHH.papeletaAdm[0].data = data
      localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH))
      this.props.history.push('/verPapeletaAdm');
    }
    
    async detalle(){
      paramRRHH.trabajadoresAdm[1].enlace = "T"
      paramRRHH.papeletaAdm[1].enlace = "T"
      localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH))
      this.props.history.push('/crearPapeletaAdm');
    }
    async regresar(){
        this.props.history.push('/administrarTrabajador');
    }
    
      render() {
        const columns = [
          {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white",width:50}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        //  {dataField: "dni", text: "DNI", filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white",width:120}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        //  {dataField: "nombres", text: "NOMBRES Y APELLIDOS",  filter: textFilter(),sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:250}, headerAlign: (column, colIndex) => 'center', align: 'justify'},
          {dataField: "tipopapeleta", text: "TIPO DE PAPELETA", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
          {dataField: "fechaIni", text: "FEC. INICIO",  filter : textFilter(),sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
          {dataField: "fechaFin", text: "FEC. TERMINO", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
          {dataField: "observacion", text: "MOTIVO", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:300}, headerAlign: (column, colIndex) => 'center', align: 'justify'},
          {dataField: "fecharegistro", text: "FEC. REGISTRO", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
          {dataField: "estado", text: "AUTORIZACIÓN", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.verdocumento},
          {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white", width:120}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
        ]
        const options = {
          custom: true,
          paginationSize: 4,
          pageStartIndex: 1,
          firstPageText: 'Primera',
          prePageText: 'Anterior',
          nextPageText: 'Siguiente',
          lastPageText: 'Última',
          showTotal: true,
          totalSize: this.state.movimientos.length
        };
        const contentTable = ({ paginationProps, paginationTableProps }) => (
          <div>
            <div>
              <div>
                <BootstrapTable
                  striped
                  hover
                  keyField="item"
                  data={ this.state.movimientos }
                  columns={ columns }
                  filter={ filterFactory() }
                  noDataIndication="No existen registros para mostrar"
                  { ...paginationTableProps }
                />
              </div>
              <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
            </div>
          </div>
        );
        return (
          <div className="content">
            <Grid fluid>
            <Row>
                <Col md={12}>
                  <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                  <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Papeletas de <a style={{fontWeight: "bold", color: "black",fontSize: 20}}>{this.state.nombre}</a></h4>
                  <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Ver Trabajadores</Button>
                 
                <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.detalle() }}>Nueva Papeleta</Button>
                   
              {this.state.isLoading ? (
                          <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading}
                          />
                        ) : (
                          <PaginationProvider
                            pagination={
                              paginationFactory(options)
                            }
                          >
                            { contentTable }
                          </PaginationProvider>
                     )}
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        );
      }
    }
    
export default VerPapeletaTrabajador;