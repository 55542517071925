import React, { Component } from "react";
import { ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, fechasBusqueda,paramRRHH} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import Swal from 'sweetalert2'



const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Vacaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarVacaciones',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],    
      anio: '',     
      years: [],
      isLoading: true, 
    }

    this.GetActionFormat = this.GetActionFormat.bind(this);

  }
    handleAnio = anio => { this.setState({ anio },   ); };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null &&
     localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.generarPeriodos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  generarPeriodos(){
    console.log(new Date().getFullYear())
    let year =  1990;
    let cont = 36;
    let a = Array.from(new Array(cont),(val, index) => index + year);
    let array = a.reverse()
    let cont2 = 0;
    let lista = [];
    if(fechasBusqueda.vacaciones[0].nidperiodo != "" && fechasBusqueda.vacaciones[1].periodo != ""){
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
      })
      this.setState({
        anio: { value: fechasBusqueda.vacaciones[0].nidperiodo,  label: fechasBusqueda.vacaciones[1].periodo}
      })
    }else{
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
          this.setState({
            anio: {value: cont2, label: data}}
           )
        }
      })
    }
    this.setState({years: lista})
    this.listVacaciones()
 }

 async listVacaciones() {
    this.setState({isLoading:true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'vacaciones/listVacaciones?anio=' + this.state.anio.label)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                diasConsumidos : data.ConsumedDays,
                dateBP : data.DateBeginProvision,
                dateBR : data.DateBeginRights,
                dateEN : data.DateEndNormal,
                dateER : data.DateEndRights,
                diasTotal : data.Days,
                dni :data.DocumentNumber,
                nombre : data.Name,
                diasPagados : data.PayedDays,
                provisionedDays : data.ProvisionedDays,
                fechaCreacion : data.XLastDate,
                person: data.Person,
                line : data.line,
                anio:data.ControlYear,
                item: data.item,
                diasRes :data.restante
             
            })        
          })
          fechasBusqueda.vacaciones[0].nidperiodo = this.state.anio.value
          fechasBusqueda.vacaciones[1].periodo = this.state.anio.label
          localStorage.setItem('fechasBusqueda', JSON.stringify(fechasBusqueda))
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        
        this.setState({isLoading: false})
      }
    });
  }
 
  GetActionFormat(cell, row) {
    if(row != undefined){
        return (
            <div>
                <span data-tip="Ver Detalle" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.verDetalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x " style={{color:"#1E436D"}}></i>
                <i className="fa fa-window-restore fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                </span>
            </div>
        );
    }else{}
  }

  confirmarEnvio = () => {
    confirmAlert({
      title: '¿Seguro que desea generar los registros del año '+ this.state.anio.label+' ?',
      message: 'Se generarán los registros.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.confirmar2()
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  confirmar2 = () => {
    Swal.fire({
        title: "Se estan generando los registros del año " + this.state.anio.label + " ?",
        text: "Este proceso dura unos minutos. Espere por favor...",
        showCancelButton: false,
        showCloseButton: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        },
        allowOutsideClick:false
      })
      this.guardarMasivo()
  };

  async guardarMasivo(){  
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('anio',this.state.anio.label); 
    fromData.append('usuario',this.state.usuario);    
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'vacaciones/insertMasivo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar();         
          this.props.handleClick("tl", "info", "Se ha registrado correctamente!", "pe-7s-smile") 
          Swal.close(); 
     }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
          Swal.close();
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          Swal.close();
          this.setState({isLoading: false})
      }
    });
   
  }

  async limpiar(){
    this.listVacaciones(); 
  }

  async verDetalle(data){
    paramRRHH.vacaciones[1].enlace = "V"
    paramRRHH.trabajadoresAdm[1].enlace = "V"
    paramRRHH.vacaciones[0].data = data
    localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH))
    this.props.history.push('/vacacionesDetalle');
  }


  async verReporte(){
   this.props.history.push("/reporteVacaciones")
  }

  async reporte(){
    this.props.history.push("/reporteGeneralVacaciones")
   }
  
  render() {
    const columns = [
    {dataField: "item", text: "ITEM",  headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "dni", text: "DNI", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "nombre", text: "NOMBRES Y APELLIDOS", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white",width:400}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "diasTotal", text: "DÍAS TOTAL DE VACACIONES", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "diasConsumidos", text: "DÍAS CONSUMIDOS", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "diasRes", text: "DÍAS RESTANTES", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable   

              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                <div className="row">
                <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25, marginLeft: 20}}> Vacaciones por Periodo </h4>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>PERIODO</ControlLabel>
                        <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                    </div>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.listVacaciones() }}>Buscar Vacaciones</Button>
                    <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.confirmarEnvio() }}>Generar</Button>
                    <Button style={{width: 250,marginTop : 5, marginRight:10, marginBottom:10}} bsStyle="warning" fill type="submit" onClick={()=>{this.verReporte()}}>Detalle de Vacaciones</Button>
                    <Button style={{width: 250,marginTop : 5, marginRight:10, marginBottom:10}} bsStyle="primary" fill type="submit" onClick={()=>{this.reporte()}}>Reporte</Button>
                </div>
                {this.state.isLoading ? (
                   <ClipLoader
                              css={override}
                              sizeUnit={"px"}
                              size={50}
                              color={'#123abc'}
                              loading={this.state.loading}
                          />                                  
                ) : (
                    <div>
                        <PaginationProvider
                            pagination={
                            paginationFactory(options)
                            }>
                            { contentTable }
                        </PaginationProvider>   
                    </div>
                )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Vacaciones;