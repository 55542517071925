
import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Route,Switch, Redirect} from 'react-router-dom'
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { validator } from 'store/Apis'
import ReactTooltip from 'react-tooltip'
import swal from 'sweetalert';
import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";
import store from "store/Store.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { concursos } from 'store/Apis'
import "./postulantes.css";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;


class Postulantes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'concursos',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      postulantes: [],
      listaDet: [],
      concurso: '',
      tipo: '',
      isLoading: true,
      isLoading2: false,
      nidpostulante:"",
      resContrato:"",
      resConcurso:"",
      nid:"",
      concursos:[],
      ganadores:[],
      concursoPublicoInterno: ''
    }
    this.GetActionFormat = this.GetActionFormat.bind(this);
    this.GetActionFormat2 = this.GetActionFormat2.bind(this);
    this.GetActionFormat3 = this.GetActionFormat3.bind(this);
    this.GetActionFormat4 = this.GetActionFormat4.bind(this);
  
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if( localStorage.getItem("concurso") !== null){
        this.setState({concurso: JSON.parse(localStorage.getItem('concurso')).concurso, 
                       tipo: JSON.parse(localStorage.getItem('concurso')).tipo,
                       nid : JSON.parse(localStorage.getItem('concurso')).nidconcurso,
                       concursoPublicoInterno: JSON.parse(localStorage.getItem('concurso')).extOrInt
                     });
        let nid = JSON.parse(localStorage.getItem('concurso')).nidconcurso;
     
        this.listarPostulantes(nid);
    }else{
      this.props.history.push('/concursos');
    }
  }

  async listarPostulantes(nid) {
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${concursos}` + 'private/listpostulantesxconcurso?nid=' + nid)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { item: data.item,
                nidpostulante: data.nid_postulante,
                dni: data.cnum_dni,
                nombres: data.nombres,
                cont_nuevo: data.cont_nuevo,
                cont_enproceso: data.cont_enproceso,
                cont_desierto: data.cont_desierto,
                cont_finalizado: data.cont_finalizado,
                cont_suspendido: data.cont_suspendido,
                ganador: data.ganador,
                resconcurso: data.resconcurso,
                rescontrato: data.rescontrato
            })
          }); 
          this.setState({postulantes: lista})
          this.listResoluciones()
        }else{
          this.setState({postulantes: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async listResoluciones(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${concursos}` + 'private/listResoluciones?nidconcurso='+this.state.nid+'&tipo=1')
    .then((res) => {                                                                                    
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({
              ctxt_descripcion: data.ctxt_descripcion,
              item: data.item,
              ctxt_ruta: data.ctxt_ruta             
            })
        })
          this.setState({concursos: lista})    
          this.listResoluciones2()   
        }else{
          this.setState({concursos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
    .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
    });
  } 

  async listResoluciones2(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${concursos}` + 'private/listResoluciones?nidconcurso='+this.state.nid+'&tipo=2')
    .then((res) => {                                                                                    
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({
              ctxt_descripcion: data.ctxt_descripcion,
              item: data.item,
              ctxt_ruta: data.ctxt_ruta             
            })
        })
          this.setState({ganadores: lista, isLoading: false})       
        }else{
          this.setState({ganadores: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
    .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
    });
  } 


  async listarConcursos(nid) {
    this.setState({listaDet: [], isLoading2: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${concursos}` + 'private/listconcursosxpostulante?nid=' + nid)
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { item: data.item,
              ctxt_estado: data.ctxt_estado,
              ctxt_descripcion: data.ctxt_descripcion,
              dfec_postulacion: Calendar.calendario9(data.dfec_postulacion)
          })
        });
        this.setState({listaDet: lista, isLoading2: false})
      }else{
        this.setState({listaDet: [], isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async ver(row){
    row = {...row, concursoPublicoInterno: this.state.concursoPublicoInterno ? this.state.concursoPublicoInterno : null}
    localStorage.setItem('postulante', JSON.stringify(row))
     if (this.state.concursoPublicoInterno === 0) {
      this.props.history.push('/detallePostulanteInterno');
     }else{
        this.props.history.push('/detallePostulante');
    }
  }

  async verFicha(row){
    localStorage.setItem('postulante', JSON.stringify(row))
    this.props.history.push('/fichaPostulante');
  }

  async cancelar(){
    this.props.history.push('/concursos');
  }

  GetActionFormat(cell, row) {
      return (
          <div>
            <p style={{fontSize: 12}}>Concursos Nuevos: <a>{row.cont_nuevo}</a></p>
            <p style={{fontSize: 12}}>Concursos en Proceso: <a>{row.cont_enproceso}</a></p>
            <p style={{fontSize: 12}}>Concursos Desiertos: <a>{row.cont_desierto}</a></p>
            <p style={{fontSize: 12}}>Concursos Finalizados: <a>{row.cont_finalizado}</a></p>
            <p style={{fontSize: 12}}>Concursos Suspendidos: <a>{row.cont_suspendido}</a></p>
          </div>
      );
  }

  GetActionFormat2(cell, row) {
    return (
        <div>
            <span data-tip="Ver Hoja de Vida" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver(row) }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x text-primary"></i>
              <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>
        </div>
    );
  }

  GetActionFormat3(cell, row) {
    return (
        <div>
            <span data-tip="Ver Ficha de Postulación" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.verFicha(row) }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x text-info"></i>
              <i className="fa fa-file-pdf-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>
        </div>
    );
  }

  GetActionFormat4(cell, row) {
    return (
        <div>
          {row.ganador=="SI"? 
          <div>
            <span data-tip="Anular condición de ganador" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarAnulacion(row) }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x text-success"></i>
              <i className="fa fa-trophy fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>
          </div>
            : 
            <span data-tip="Declarar Ganador(a)" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.declararGanador(row) }}>
            <ReactTooltip />
            <i className="fa fa-circle fa-stack-2x" style={{color: '#CFCDCD'}}></i>
            <i className="fa fa-trophy fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
          </span>}
        </div>
    );
  }
  
  confirmarAnulacion = (data) => {
    confirmAlert({
      title: <h2>¿Está seguro de anular la condición de ganador(a)?</h2>,
      message: 'Se anulará la condición de ganador(a) a '+  data.nombres,
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.anularCondición(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };
  async anularCondición(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidconcurso', this.state.nid);
    fromData.append('nidpostulante', data.nidpostulante);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${concursos}` + 'private/anularganador', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarPostulantes(this.state.nid)
          this.props.handleClick("tl", "success", "Se anuló la condición de ganador correctamente!", "pe-7s-close")
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async declararGanador(row) {
    const listaResoluciones = this.state.concursos.map((concurso, index) => {
      return `<li style="list-style: none;"><span id="resolucion${index + 1}" style="cursor: pointer; text-decoration: none; color: #282B9A;">${index + 1} ${concurso.ctxt_descripcion}</span></li>`;
  }).join('');

  const listaResolucionesGanadores = this.state.ganadores.map((ganador, index) => {
    return `<li style="list-style: none;"><span id="resoluciones${index + 1}" style="cursor: pointer; text-decoration: none; color: #282B9A;">${index + 1} ${ganador.ctxt_descripcion}</span></li>`;
}).join('');
    swal({
        title: "Declarar Ganador",
        content: {
            element: "div",
            attributes: {
                innerHTML: `
                    <p>Nombres y Apellidos</p><input id="nombre" class="swal2-input custom-input" value="${row.nombres}" disabled>
                    <p>Resoluciones del Concurso:</p>
                    <ul style="list-style: none; padding: 0; margin: 0 15 0 0;">${listaResoluciones}</ul>
                    <p style="margin-top:15">Resoluciones del Ganador:</p>
                    <ul style="list-style: none; padding: 0; margin: 0;">${listaResolucionesGanadores}</ul>
                `,
            },
        },
        buttons: {
            cancel: {
                text: "Cancelar",
                value: null,
                visible: true,
                className: "red-button",
            },
            confirm: {
                text: "Guardar Ganador",
                closeModal: false,
            },
        },
        closeOnClickOutside: false,
    }).then((result) => {
        if (result === null) {
            swal.close()
        } else {
            // El botón de guardar ganador fue presionado
            this.setState({
                nidpostulante: row.nidpostulante,
            });
            this.guardarGanador();
        }
    });

    // Adjuntar eventos onclick dinámicamente después de abrir la modal
    this.state.concursos.forEach((concurso, index) => {
        const spanElement = document.getElementById(`resolucion${index + 1}`);
        spanElement.onclick = () => this.abrirResolucion(concurso.ctxt_ruta);
    });

    this.state.ganadores.forEach((ganador, index) => {
      const spanElement = document.getElementById(`resoluciones${index + 1}`);
      spanElement.onclick = () => this.abrirResolucion(ganador.ctxt_ruta);
  });
}

abrirResolucion = (ctxtRuta) => {
    window.open(ctxtRuta, '_blank');
};

 /* async declararGanador(row) {
    console.log(this.state.concursos)
    const listaResoluciones = this.state.concursos.map((concurso, index) => {
      return `<li><span style="cursor: pointer; text-decoration: underline; color: blue;" onclick="abrirResolucion('${concurso.ctxt_ruta}')">${index + 1}. ${concurso.ctxt_descripcion}</span></li>`;
  }).join('');

     swal({
      title: "Declarar Ganador",
      content: {
        element: "div",
        attributes: {
          innerHTML: `
            <p>Nombres y Apellidos</p><input id="nombre" class="swal2-input custom-input" value="${row.nombres}" disabled>
            <p>Resoluciones:</p>
            <ul>${listaResoluciones}</ul>
            <p>Resolución del Contrato</p><input id="resolucionContrato" class="swal2-input custom-input" placeholder="">
          `,
        },
      },
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "red-button",
        },
        confirm: {
          text: "Guardar Ganador",
          closeModal: false, 
        },
      },
      closeOnClickOutside: false,
    })
    .then((result) => {
      if (result === null) {
        swal.close()
      } else {
        // El botón de guardar ganador fue presionado
     
        const resolucionContratoInput = document.getElementById('resolucionContrato');
          this.setState({
       
            resContrato: resolucionContratoInput.value,
            nidpostulante: row.nidpostulante,
          });
          this.guardarGanador();
     
      }
    });
  }*/




  async guardarGanador(){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidconcurso', this.state.nid);
    fromData.append('nidpostulante', this.state.nidpostulante);
    fromData.append('resoConcurso', "");
    fromData.append('resoContrato', "");
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${concursos}` + 'private/insertarganador', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.listarPostulantes(this.state.nid);
          this.props.handleClick("tl", "success", "Se registró correctamente al ganador!", "pe-7s-close")
          swal.close();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
          swal.close();
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }
  getRowStyle = (row, rowIndex) => {
    return {
      background: row.ganador === 'SI' ? '#EFFBE5' : '', 
      color: row.ganador === 'SI' ? 'black' : '', 
    };
  };

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nombres", text: "NOMBRE DEL POSTULANTE", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "dni", text: "N° DE DNI", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "hoja", text: "HOJA DE VIDA (Registros y Documentos Actuales del Postulante)", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},
      {dataField: "ficha", text: "FICHA DE POSTULACIÓN (Documentos Adjuntos de Postulación)", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat3},
      {dataField: "ganador", text: "GANADOR", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat4},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const columns2 = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#28B463', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ctxt_estado", text: "ESTADO", sort: true, headerStyle: {backgroundColor: '#28B463', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ctxt_descripcion", text: "CONCURSO", sort: true, headerStyle: {backgroundColor: '#28B463', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "dfec_postulacion", text: "FECHA DE POSTULACIÓN", sort: true, headerStyle: {backgroundColor: '#28B463', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.postulantes.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.postulantes }
              columns={ columns }
              expandRow={ expandRow }
              filter={ filterFactory() }
              rowStyle={this.getRowStyle}
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const expandRow = {
      onlyOneExpanding: true,
      renderer: row => (
        <div style={{textAlign: 'center'}}> 
          <Button style={{width: 150, marginLeft: 10, marginBottom: 20}} bsStyle="primary" fill type="submit" onClick={() => {this.listarConcursos(row.nidpostulante)}}>Ver Postulaciones</Button> 
          <div className="row">
            <div className="col-md-12">
              {this.state.isLoading2 ? (
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading2}
                  />
                ) : (
                  <div className="row">
                    <div className="col-md-12">
                      <BootstrapTable keyField='item' data={ this.state.listaDet } columns={ columns2 } />
                    </div>
                  </div>
              )}
            </div>
          </div>
        </div>
      ),
      onExpand: (row, isExpand, rowIndex, e) => {
        if(this.state.listaDet.length != 0){
         setTimeout(
             function() {
               this.setState({listaDet: []})
             }
             .bind(this),
             100
         );
        }
      }
    };
    const {concurso, tipo} = this.state
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Lista de Postulantes para el Concurso: {concurso + ' (' + tipo + ')'}</h4>
              <h4 className="category" style={{fontSize: 15, marginTop: 10}}>En la siguiente tabla se lista a las pesonas que hayan postulado al concurso seleccionado.</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
            <Button style={{width: 150, marginRight: 25}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Volver</Button>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Postulantes;