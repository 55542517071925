import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { cec} from 'store/Apis'
import 'react-tabs/style/react-tabs.css';
import InputFiles from 'react-input-files';
import { confirmAlert } from 'react-confirm-alert';
import Calendar from "components/Calendar/Calendar";
import Select from 'react-select';
import 'react-accessible-accordion/dist/fancy-example.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class EditarBiblioteca extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'biblioteca',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      codigo: "",
      nid: "",
      titulo : "", 
      descripcion:"",
      pagina: "",
      estante: "",  
      fecha: "",
      cod_libro:"",
      estado: 0,
      isLoading: true,
      show: true,
      archivonew: "",
      archivo: "",
      ruta: "",
      file: [],
      autores: [],
      autor: "",
      editoriales: [],
      editorial:"",
      especialidades: [],
      especialidad: "",
      codDeWey:"",
      balda:"",
      sede:"",
      departamento:"",
      provincia:"",
      distrito:"",
      valor:""
   
    }
    this.handleCodigo = this.handleCodigo.bind(this);
    this.handleTitulo = this.handleTitulo.bind(this);
    this.handleDescripcion = this.handleDescripcion.bind(this);
    this.handleAutor = this.handleAutor.bind(this);
    this.handleEditorial = this.handleEditorial.bind(this);
    this.handleEspecialidad = this.handleEspecialidad.bind(this);
    this.handlePagina = this.handlePagina.bind(this);
    this.handleEstante = this.handleEstante.bind(this); 
    this.handleFecha = this.handleFecha.bind(this);
    this.handleCodDeWey = this.handleCodDeWey.bind(this);
    this.handleBalda = this.handleBalda.bind(this);
    this.handleSede = this.handleSede.bind(this);
    this.handleDepartamento = this.handleDepartamento.bind(this);
    this.handleProvincia = this.handleProvincia.bind(this);
    this.handleDistrito = this.handleDistrito.bind(this);
    this.handleValor = this.handleValor.bind(this);
    }
    handleDescripcion(event) {this.setState({descripcion: event.target.value});}
    handleTitulo(event) {this.setState({titulo: event.target.value});}
    handleFecha(event) {this.setState({fecha: event.target.value});}
    handleCodigo(event){this.setState({cod_libro: event.target.value});}
    handleAutor(event){this.setState({autor: event.target.value});}
    handleEditorial(event){this.setState({editorial: event.target.value});}
    handleEspecialidad(event){this.setState({especialidad: event.target.value});}
    handlePagina(event){this.setState({pagina: event.target.value});}
    handleEstante(event){this.setState({estante: event.target.value});}
    handleCodDeWey(event){this.setState({codDeWey: event.target.value});}
    handleBalda(event){this.setState({balda: event.target.value});}
    handleSede(event){this.setState({sede: event.target.value});}
    handleDepartamento(event){this.setState({departamento: event.target.value});}
    handleProvincia(event){this.setState({provincia: event.target.value});}
    handleDistrito(event){this.setState({distrito: event.target.value});}
    handleValor(event){this.setState({valor: event.target.value});}
   
    changeAutor = autor => {
      this.setState(
      { autor },
      );
    };
    changeEditorial = editorial =>{
      this.setState(
        { editorial },
      );
    };
    changeEspecialidad = especialidad =>{
      this.setState(
        {especialidad},
      );
    };
    
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
              this.props.history.goBack()
            }else{
                this.validarLocal();
              //this.autores()
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(JSON.parse(localStorage.getItem("cec")).biblioteca[0].data !== null){
     this.setState({
          estante:  JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.cnum_estante,
          cod_libro: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.cod_libro,
          archivo: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.ctxt_archivo,
          ruta: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.ctxt_ruta,
       // descripcion: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.ctxt_descripcion,
          descripcion: "--",
          pagina: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.ctxt_paginas,
          titulo: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.ctxt_titulo,
          fecha: Calendar.calendario11(JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.dfec_compra),
          codDeWey: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.cod_dewey,
          balda: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.cnum_balda,
          sede: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.ctxt_sede,
          departamento: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.ctxt_departamento,
          provincia: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.ctxt_provincia,
          distrito: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.ctxt_distrito,
          valor: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.ctxt_valor,
          flg_estado: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.flg_estado,
          nid: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.nid,
          autor: {value: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.nid_autor, label: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.autor},
          editorial: {value: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.nid_editorial, label: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.editorial},
          especialidad: {value: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.nid_especialidad, label: JSON.parse(localStorage.getItem('cec')).biblioteca[0].data.especialidad}
        });
        this.autores();
     
        }else{
      this.props.history.push('/biblioteca');
    }
  }
  
  async autores(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${cec}` + 'tipos/comboTipos?nidcodigo=1')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_nombre,       
            })
        })
        this.setState({autores: lista})
        this.editoriales()
      }else{
        this.setState({autores: []})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
  async editoriales(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${cec}` + 'tipos/comboTipos?nidcodigo=2')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_nombre,       
            })
        })
        this.setState({editoriales: lista})
        this.especialidades()
      }else{
        this.setState({editoriales: []})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        
      }
    });
  }
  async especialidades(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${cec}` + 'tipos/comboTipos?nidcodigo=3')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_nombre,       
            })
        })
        this.setState({especialidades: lista, isLoading: false})
      }else{
        this.setState({especialidades: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
  
async validar(){
   var archivox = '--'
   if(this.state.cod_libro != '' && this.state.codDeWey != '' && this.state.titulo != '' && this.state.pagina != '' && this.state.estante != '' && this.state.balda != '' && 
   this.state.valor != ''  && this.state.departamento != '' && this.state.provincia != '' && this.state.distrito != '' && this.state.sede != '' ){
      if(this.state.archivonew  != '' && this.state.archivonew != null){
        archivox = this.state.archivonew
      }
      this.actualizar(archivox);
    }else{
      this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close") 
    }
  }

  async actualizar(archivox){ 
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('file', this.state.file);
    fromData.append('codigo', this.state.cod_libro);
    fromData.append('titulo', this.state.titulo);
    fromData.append('descripcion', this.state.descripcion)
    fromData.append('autor', this.state.autor.value);
    fromData.append('editorial', this.state.editorial.value);
    fromData.append('especialidad', this.state.especialidad.value);
    fromData.append('oldarchivoencry', this.state.archivo == "" || this.state.archivo == null ? "--" : this.state.archivo)
    fromData.append('oldruta', this.state.ruta == "" || this.state.ruta == null ? "--" : this.state.ruta)
    fromData.append('archivo', archivox);
    fromData.append('paginas', this.state.pagina);
    fromData.append('estante', this.state.estante);
    fromData.append('fecha', Calendar.calendario12(this.state.fecha));
    fromData.append('codDeWey', this.state.codDeWey);
    fromData.append('balda', this.state.balda);
    fromData.append('sede', this.state.sede);
    fromData.append('departamento', this.state.departamento);
    fromData.append('provincia', this.state.provincia);
    fromData.append('distrito', this.state.distrito);
    fromData.append('valor', this.state.valor);
    fromData.append('estado', this.state.archivonew == "" || this.state.archivonew == null ?  "0" : "1" )
    fromData.append('usuario',this.state.usuario);
    fromData.append('nidlibro',this.state.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'biblioteca/editarLibro', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Libro actualizado correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }
  async regresar(){
    this.props.history.push('/biblioteca');
  }
  subir = (files) => {
      if(files.length !== 0){
          this.setState({file: files[0], archivonew:files[0].name});
      }
  }
  async limpiar(){
    this.setState({archivonew: "", file: ""})
  }


  confirmarDeleteDoc = () => {
    confirmAlert({
      title: '¿Seguro que desea anular el documento?',
      message: 'Se anulará el documento seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete()
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async delete(){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('niddocumento', this.state.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'biblioteca/anularDocumento', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          if(datos.response.estado == 1){
             this.regresar();
              this.props.handleClick("tl", "info", "El documento ha sido anulado!", "pe-7s-smile")
          }else{
              this.props.handleClick("tl", "error", "No se puede anular el documento!", "pe-7s-close")
              this.setState({isLoading: false})
          }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  render() {
    const {autor, editorial, especialidad} = this.state
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Editar Libro: <a style={{fontWeight: "bold", color: "black"}}>{this.state.titulo}</a></h4>

              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <div>
                      <div className="row">
                         <div className="col-md-4" style={{marginBottom: 18}}>
                               <ControlLabel>CÓDIGO DE LIBRO</ControlLabel>
                               <FormControl type="text" value={this.state.cod_libro} onChange= {this.handleCodigo}/>
                         </div>
                         <div className="col-md-4" style={{marginBottom: 18}}>
                               <ControlLabel>CÓDIGO DEWEY </ControlLabel>
                               <FormControl type="text" value={this.state.codDeWey} onChange= {this.handleCodDeWey}/>
                         </div>

                         <div className="col-md-4" style={{marginBottom: 18}}>
                               <ControlLabel>FECHA DE COMPRA</ControlLabel>
                               <FormControl type="date" value={this.state.fecha} onChange= {this.handleFecha}/>
                         </div>
                         <div className="col-md-12" style={{marginBottom: 18}}>
                               <ControlLabel>TÍTULO</ControlLabel>
                               <FormControl type="text" value={this.state.titulo} onChange= {this.handleTitulo}/>
                         </div>
                         
                      </div>
                      <div className="row">
                         <div className="col-md-6" style={{marginBottom: 18}}>
                             <ControlLabel>AUTOR</ControlLabel>
                             <Select value={autor} onChange={this.changeAutor} options={this.state.autores}/>
                         </div>
                         <div className="col-md-6" style={{marginBottom: 18}}>
                             <ControlLabel>EDITORIAL</ControlLabel>
                             <Select value={editorial} onChange={this.changeEditorial} options={this.state.editoriales}/>
                         </div>
                      </div>
                      <div className="row">
                          <div className="col-md-4" style={{marginBottom: 18}}>
                             <ControlLabel>ESPECIALIDAD</ControlLabel>
                             <Select value={especialidad} onChange={this.changeEspecialidad} options={this.state.especialidades}/>
                          </div>
                          <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>NÚMERO DE PÁGINA</ControlLabel>
                               <FormControl type="text" value={this.state.pagina} onChange= {this.handlePagina}/>
                          </div>
                          <div className="col-md-2" style={{marginBottom: 18}}>
                               <ControlLabel>ESTANTE</ControlLabel>
                               <FormControl type="text" value={this.state.estante} onChange= {this.handleEstante}/>
                          </div>
                          <div className="col-md-2" style={{marginBottom: 18}}>
                               <ControlLabel>BALDA</ControlLabel>
                               <FormControl type="text" value={this.state.balda} onChange= {this.handleBalda}/>
                          </div>
                          <div className="col-md-2" style={{marginBottom: 18}}>
                               <ControlLabel>VALOR</ControlLabel>
                               <FormControl type="text" value={this.state.valor} onChange= {this.handleValor}/>
                          </div>
                      </div>
                      <div className="row">
                      <div className="col-md-3" style={{marginBottom: 18}}>
                             <ControlLabel>SEDE</ControlLabel>
                             <FormControl type="text" value={this.state.sede} onChange= {this.handleSede}/>
                          </div>
                          <div className="col-md-3" style={{marginBottom: 18}}>
                             <ControlLabel>DEPARTAMENTO</ControlLabel>
                             <FormControl type="text" value={this.state.departamento} onChange= {this.handleDepartamento}/>
                          </div>
                          <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>PROVINCIA</ControlLabel>
                               <FormControl type="text" value={this.state.provincia} onChange= {this.handleProvincia}/>
                          </div>
                          <div className="col-md-3" style={{marginBottom: 18}}>
                               <ControlLabel>DISTRITO</ControlLabel>
                               <FormControl type="text" value={this.state.distrito} onChange= {this.handleDistrito}/>
                          </div>
                          
                      </div>
                         {/* <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>DESCRIPCIÓN</ControlLabel>
                                <FormControl componentClass="textarea" style={{  height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.descripcion} onChange= {this.handleDescripcion} />
                            </div>
                         </div> */}
                         
                         <div className="row">
                            <div className="col-md-6">
                              <ControlLabel>ARCHIVO ACTUAL</ControlLabel><br></br>
                              {this.state.archivo != "" && this.state.archivo != null && this.state.archivo != "--" && this.state.archivo != 0 ? (
                          <div className="col-md-2">
                            <div className="col-md-1">
                                <span className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 10}} onClick={() => { this.confirmarDeleteDoc() }}>
                                  <i className="fa fa-times-circle " style={{fontSize: 25, color:"black"}}></i>
                                </span>
                            </div><br></br>
                            <a href={this.state.ruta} target="blank"><i style={{color: "red"}} class="fa fa-file-pdf-o fa-5x" aria-hidden="true"></i></a>
                          </div>
                             

                             ) : (
                                <div>
                                <p>Sin Documento</p>
                              
                              </div>
                              )}
                            </div>
                            <div className="col-md-6">
                                <ControlLabel>ARCHIVO ADJUNTO</ControlLabel>
                                <Alert bsStyle="warning">
                                    <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivonew}</span>
                                </Alert>
                                <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                                    <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                                </Button>
                                {this.state.archivonew != "" ? (
                                  <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar Archivo</Button>
                                ) : ( 
                                  null
                                )}
                            </div>
                        </div>
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Actualización</Button>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Actualizar Registro</Button>
                        </div>
                    </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarBiblioteca;
