import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, paramLegajo} from 'store/Apis'
import { Card } from "components/Card/Card.jsx";
import { NavLink, Link } from 'react-router-dom';
import "views/inicio/inicio.css";
import Button from "components/CustomButton/CustomButton.jsx";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class FichaPersonal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            path: 'miLegajo',
            usuario: localStorage.getItem('nickname'),
            token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,       
            vista: [],
            isLoading: true,            
            id: JSON.parse(localStorage.getItem('legajo')).legajo[0].idpersona,
            nombre: JSON.parse(localStorage.getItem('legajo')).legajo[1].nombre,
            codcategoria: JSON.parse(localStorage.getItem('legajo')).legajo[2].idcategoria,
            descategoria: JSON.parse(localStorage.getItem('legajo')).legajo[3].descategoria,
            enlace: JSON.parse(localStorage.getItem('legajo')).legajo[4].enlace,   
            idlegajo: JSON.parse(localStorage.getItem('legajo')).legajo[5].idlegajo,     
        }     
    }

    async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{
                  this.verificarLegajo();
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }

    async verificarLegajo(){
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'legajo/verificarLegajoPersona?idpersona=' + this.state.id)
        .then((res) => {         
            let lista2 = res.data.response;  
            if(lista2.length != 0){
                lista2.map((data) => {
                    this.setState(
                    {
                        idlegajo: data.id_legajo,
                        estadolegajo: data.estado
                    })  
                })
            }else{
                this.setState(
                {
                    idlegajo: 0,
                    estadolegajo: 0
                }) 
            }                   
        })
        .catch((error) => { 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              this.setState({isLoading: false})
            }
        });
    }
    
    async detalle(){     
        paramLegajo.legajo[0].idpersona = this.state.id
        paramLegajo.legajo[1].nombre = this.state.nombre
        paramLegajo.legajo[2].idcategoria = this.state.codcategoria
        paramLegajo.legajo[3].descategoria = this.state.descategoria
        paramLegajo.legajo[4].enlace = this.state.enlace
        paramLegajo.legajo[5].idlegajo = this.state.idlegajo
        localStorage.setItem('legajo', JSON.stringify(paramLegajo));
    }

    async regresar(){        
        if(this.state.enlace == "P"){
            this.props.history.push('/miLegajo');
        }else{
            this.props.history.push('/legajoTrabajador');
        }
    }

    render(){
        return(
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <main role="main">
                                    <div className="container">                                                                                
                                        <h3 className="text-center">{this.state.descategoria}</h3>                                        
                                    </div>
                                    <div className="row">
                                        <Button style={{width: 250, marginTop: 5,marginBottom: 10, marginLeft:20}} bsStyle="danger" fill  type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                                    </div>
                                    <div className="album py-5 bg-light">
                                        <div style={{marginRight: 50, marginLeft: 50}}>
                                        <div className="row" style={{marginTop: 30}}>
                                            <NavLink to="/datosGenerales" onClick = {() => {this.detalle()}}>
                                                <div className="col-md-6" >
                                                    <div className="wrap">
                                                        <div className="ico-wrap" style={{width:65}}>
                                                            <i className="pe-7s-check" style={{color:"black",fontSize: 40, width:20}}></i>
                                                        </div>
                                                        <div className="text-wrap vcenter">
                                                            <h2 className="mbr-fonts-style" style={{fontSize:18}}>DATOS GENERALES</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/listadoFamiliar" onClick = {() => {this.detalle()}} className={this.state.idlegajo == 0 ? "isDisabled" : ""}>
                                                <div className="col-md-6" >
                                                    <div className="wrap">
                                                        <div className="ico-wrap" style={{width:65}}>
                                                            <i className="pe-7s-check" style={{color:"black",fontSize: 40, width:20}}></i>
                                                        </div>
                                                        <div className="text-wrap vcenter">
                                                            <h2 className="mbr-fonts-style" style={{fontSize:18}}>DATOS FAMILIARES</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                            <NavLink to="/declaracionJurada" className={this.state.idlegajo == 0 ? "isDisabled" : ""}>
                                                <div className="col-md-6" >
                                                    <div className="wrap">
                                                        <div className="ico-wrap" style={{width:65}}>
                                                            <i className="pe-7s-check" style={{color:"black",fontSize: 40, width:20}}></i>
                                                        </div>
                                                        <div className="text-wrap vcenter">
                                                            <h2 className="mbr-fonts-style" style={{fontSize:18}}>DECLARACIÓN JURADA</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                        </div>
                                    </div>
                                    </main>
                                }
                            />

                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

}

export default FichaPersonal;