const validator =
  "https://tomcat.sedetc.gob.pe/autentica-services-0.0.1/operaciones//app2/SISPER/";

const sisper = "https://tomcat.sedetc.gob.pe/sisper-0.0.1/";
//const sisper = 'http://localhost:8081/sisper/';

const sisperplus = "https://tomcat.sedetc.gob.pe/sisperplus-0.0.2/";
//const sisperplus = "http://localhost:8081/sisperplus/";

const rrhh = "http://localhost:8083/rrhh/";

const gestor = "https://tomcat.sedetc.gob.pe/gestordoc-services-auth-0.0.1/";
//const gestor = 'http://localhost:8095/'

const marcaciones = "https://tomcat.sedetc.gob.pe/marcador-0.0.1/";
const concursos = "https://tomcat.sedetc.gob.pe/concursos-0.0.1/";
//const concursos =  'http://localhost:8081/concursos/';

const canaltv = "https://tomcat.sedetc.gob.pe/canaltc-0.0.1/";
//const canaltv =  'http://localhost:8084/canaltc/';

const tesoreria = "https://tomcat.sedetc.gob.pe/tesoreria-0.0.1/";
// const tesoreria =  'http://localhost:8081/tesoreria/';

const cec = "https://tomcat.sedetc.gob.pe/cec-0.0.2/";
//const cec =  'http://localhost:8083/cec/';

const jurisdiccional = "https://tomcat.sedetc.gob.pe/jurisdiccional-0.0.1/";
//const jurisdiccional =  'http://localhost:8081/jurisdiccional/';

const pensionistas = "https://tomcat.sedetc.gob.pe/pensionistas-0.0.1/";
//const pensionistas =  'http://localhost:8081/pensionistas/';

const paramTesoreria = {
  libro: [{ anio: "" }, { mes: "" }, { data: [] }],
  cheques: [{ fechaini: "" }, { fechafin: "" }, { data: [] }],
  comprobantes: [{ fechaini: "" }, { fechafin: "" }, { data: [] }],
  cajachica: [{ fechaini: "" }, { fechafin: "" }, { data: [] }],
  areas: [{ data: [] }, { data2: [] }],
  trabajadores: [{ data: [] }, { data2: [] }],
  metas: [{ data: [] }],
  tiposcaja: [{ data: [] }],
  beneficiarios: [{ data: [] }],
  conceptos: [{ data: [] }],
  clasificadores: [{ data: [] }],
  codificaciones: [{ data: [] }],
  retenciones: [{ data: [] }, { data2: [] }],
};

const paramCec = {
  noticia: [{ data: [] }],
  podcast: [{ data: [] }],
  biblioteca: [{ data: [] }],
  carousel: [{ data: [] }],
  libro: [{ data: [] }],
  revista: [{ data: [] }],
  cuaderno: [{ data: [] }],
  foroAbierto: [{ data: [] }],
  foroConstitucional: [{ data: [] }],
  foroConstiIncluyente: [{ data: [] }],
  conferencia: [{ data: [] }],
  conversatorio: [{ data: [] }],
  debate: [{ data: [] }],
  agendaConstitucional: [{ data: [] }],
  agendaCEC: [{ data: [] }],
  rimaykunaCEC: [{ data: [] }],
  cursos: [{ data: [] }],
  videos: [{ data: [] }],
  internacional: [{ data: [] }],
  secciones: [{ data: [] }],
  actividades: [{ data: [] }],
  subactividades: [{ data: [] }],
  normatividad: [{ data: [] }],
  fondoEditorial: [{ data: [] }],
  editoriales: [{ data: [] }],
};

const paramAulaVirtual = {
  inscripciones: [{ data: [] }],
  coloquios: [{ data: [] }],
  replica: [{ data: [] }],
};

const paramJurisdiccional = {
  agenda: [{ data: [] }],
  cecnoticia: [{ data: [] }],
  salanoticia: [{ data: [] }],
  causa: [{ data: [] }],
  seguimiento: [{ data: [] }],
  audiencia: [{ data: [] }],
  evento: [{ data: [] }],
  opcion: [{ data: [] }],
  penalidad: [{ data: [] }],
  carruselPortal: [{ data: [] }],
  boletin: [{ data: [] }],
};

const paramRRHH = {
  papeletaAdm: [{ data: [] }, { enlace: "" }],
  trabajadoresAdm: [{ data: [] }, { enlace: "" }],
  areaAdm: [{ data: [] }],
  descansoM: [{ data: [] }, { enlace: "" }],
  vacaciones: [{ data: [] }, { enlace: "" }],
  vacacionesxTrabajador: [{ data: [] }, { enlace: "" }],
  resoluciones: [{ data: [] }],
};

const fechasBusqueda = {
  noticias: [{ nidperiodo: "" }, { periodo: "" }, { nidmes: "" }, { mes: "" }],
  podcast: [{ nidperiodo: "" }, { periodo: "" }, { nidmes: "" }, { mes: "" }],
  foroAbierto: [
    { nidperiodo: "" },
    { periodo: "" },
    { nidmes: "" },
    { mes: "" },
  ],
  foroConstitucional: [
    { nidperiodo: "" },
    { periodo: "" },
    { nidmes: "" },
    { mes: "" },
  ],
  foroConstiIncluyente: [
    { nidperiodo: "" },
    { periodo: "" },
    { nidmes: "" },
    { mes: "" },
  ],
  conferencia: [
    { nidperiodo: "" },
    { periodo: "" },
    { nidmes: "" },
    { mes: "" },
  ],
  conversatorio: [
    { nidperiodo: "" },
    { periodo: "" },
    { nidmes: "" },
    { mes: "" },
  ],
  debate: [{ nidperiodo: "" }, { periodo: "" }, { nidmes: "" }, { mes: "" }],
  agendaConstitucional: [
    { nidperiodo: "" },
    { periodo: "" },
    { nidmes: "" },
    { mes: "" },
  ],
  agendaCEC: [{ nidperiodo: "" }, { periodo: "" }, { nidmes: "" }, { mes: "" }],
  rimaykunaCEC: [
    { nidperiodo: "" },
    { periodo: "" },
    { nidmes: "" },
    { mes: "" },
  ],
  cursos: [{ nidperiodo: "" }, { periodo: "" }, { nidmes: "" }, { mes: "" }],
  boletas: [{ nidperiodo: "" }, { periodo: "" }, { nidmes: "" }, { mes: "" }],
  papeletas: [{ nidperiodo: "" }, { periodo: "" }, { nidmes: "" }, { mes: "" }],
  boletin: [{ nidperiodo: "" }, { periodo: "" }, { nidmes: "" }, { mes: "" }],
  certificados: [
    { nidperiodo: "" },
    { periodo: "" },
    { nidmes: "" },
    { mes: "" },
  ],
  contador: [{ nidperiodo: "" }, { periodo: "" }],
  pensionistas: [
    { nidperiodo: "" },
    { periodo: "" },
    { nidmes: "" },
    { mes: "" },
  ],
  secArea: [{ nidperiodo: "" }, { periodo: "" }],
  certificadoDir: [
    { nidperiodo: "" },
    { periodo: "" },
    { nidmes: "" },
    { mes: "" },
  ],
  descansoMedico: [
    { nidperiodo: "" },
    { periodo: "" },
    { nidmes: "" },
    { mes: "" },
  ],
  vacaciones: [{ nidperiodo: "" }, { periodo: "" }],
  certiFirma: [
    { nidperiodo: "" },
    { periodo: "" },
    { nidmes: "" },
    { mes: "" },
  ],
  coloquios: [{ nidperiodo: "" }, { periodo: "" }, { nidmes: "" }, { mes: "" }],
  replica: [{ nidperiodo: "" }, { periodo: "" }, { nidmes: "" }, { mes: "" }],
  resoluciones: [
    { nidperiodo: "" },
    { periodo: "" },
    { nidmes: "" },
    { mes: "" },
  ],
  produccion: [{ nidperiodo: "" }, { periodo: "" }],
};

const paramPersonal = {
  manuales: [{ data: [] }],
};

const paramLegajo = {
  legajo: [
    { idpersona: "" },
    { nombre: "" },
    { idcategoria: "" },
    { descategoria: "" },
    { enlace: "" },
    { idlegajo: "" },
  ],
  //"legajo" : [{"data" : []}, {"enlace" : ""}],
  datosPersonales: [{ data: [] }, { enlace: "" }],
  datosFamiliares: [{ data: [] }, { enlace: "" }],
  formacionAcademica: [{ data: [] }, { enlace: "" }],
  experienciaLaboral: [{ data: [] }, { enlace: "" }],
};

export {
  validator,
  sisper,
  sisperplus,
  gestor,
  marcaciones,
  concursos,
  tesoreria,
  paramTesoreria,
  canaltv,
  rrhh,
  cec,
  jurisdiccional,
  fechasBusqueda,
  paramCec,
  paramJurisdiccional,
  paramRRHH,
  paramPersonal,
  pensionistas,
  paramAulaVirtual,
  paramLegajo,
};
