import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus,paramRRHH} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { confirmAlert } from 'react-confirm-alert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ConsultarTrabajadores extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarTrabajador',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.registrarPapeleta= this.registrarPapeleta.bind(this);
    this.registrarDM= this.registrarDM.bind(this);
    this.registrarVacaciones=this.registrarVacaciones.bind(this);    
    this.colorCese=this.colorCese.bind(this);
 }
 
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.listarTrabajadores();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
  async listarTrabajadores(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'trabajadores/listTrabajadores')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        console.log(lista2)
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({
              birthDate:Calendar.calendarioA(data.BirthDate), 
              documentNumber: data.DocumentNumber,
              eMail: data.EMail,
              lastName1: data.LastName1,
              lastName2: data.LastName2, 
              name1: data.Name1,
              name2: data.Name2,
              nombreCompleto: data.LastName1+" "+data.LastName2+" "+data.Name1+" "+(data.Name2==null?"":data.Name2),
              person: data.Person,
              secTelephone: data.SecTelephone,
              telefono :data.Telephone == null ? "" : data.Telephone,
              area: data.area,
              cargo: data.cargo,
              idarea: data.idarea,
              idcargo: data.idcargo,
              idtipoperson: data.idtipoperson,
              item: data.item,
              sex: data.sex,
              tipoperson: data.tipoperson,
              address:data.Address,
              fechaingreso: data.EntryDate,
              fechasalida: data.ReEntryDate,
              fechapension : data.PensionInscriptionDate,
              numcuenta: data.CTSAccount,
              tgenero:data.tgenero,
              ruta: "T",
              line: data.line,
              lineAnio:data.lineAnio,
              fechaCese2 : data.CeaseDate,
              nombreCese : data.LiquidationReason,
              vCese:data.vCese,
              nidCese: data.CeaseReason,
              idmodalidad: data.nidModalidad,
              modalidad: data.nameModalidad,
            })
        })
         this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
    .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
    });
  }
  
  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea eliminar al trabajador?',
      message: 'Se eliminará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('person', data.person);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'trabajadores/anularTrabajador', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response == null){
        this.listarTrabajadores()
        this.props.handleClick("tl", "info", "El trabajador ha sido eliminado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async enlaceRegistro(data, param){
    paramRRHH.trabajadoresAdm[0].data = data
    paramRRHH.trabajadoresAdm[1].enlace = "T"
    localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH))
    if(param == "P"){
        this.props.history.push('/verPapeletaTrabajador');
      }else if(param == "C"){
          this.props.history.push('/crearPapeletaAdm'); 
        }else if(param == "CM"){
            this.props.history.push('/crearDescansoMedico');
        }else if(param == "DM"){
            this.props.history.push('/verDescansoTrabajador'); 
          }else if(param == "CV"){
            this.props.history.push('/vacacionesDetalle');
            }else if(param == "DV"){
                this.props.history.push('/verVacacionesTrabajador'); 
              }else {
                this.props.history.push('/editarTrabajadoresAdm');
              }
  }

  registrarPapeleta(cell, row) {
    if(row != undefined){
      return (
          <div>
            {row.cargo=="PENSIONISTA" ?  null :
             <div>  
              <span data-tip="Nueva Papeleta" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.enlaceRegistro(row,"C")}}>
                  <ReactTooltip />
                  <i className="fa fa-circle fa-stack-2x " style={{color:"#449C99"}}></i>
                  <i className="fa fa-address-book fa-stack-1x fa-inverse" style={{fontSize: 18}}></i>         
                </span>           
                <span data-tip="Ver Papeletas" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.enlaceRegistro(row,"P")}}>
                  <ReactTooltip />
                  <i className="fa fa-circle fa-stack-2x " style={{color:"#751D26"}}></i>
                  <i className="fa fa-file-text fa-stack-1x fa-inverse" style={{fontSize: 18}}></i>         
                </span>
              </div>
            }
          </div>
      );
    }else{}
  }

  registrarDM(cell, row) {
    if(row != undefined){
      return (
          <div>  
             {row.cargo=="PENSIONISTA" ?  null :
             <div>        
              <span data-tip="Nuevo Descanso Médico" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.enlaceRegistro(row,"CM")}}>
                  <ReactTooltip />
                  <i className="fa fa-circle fa-stack-2x"style={{ color:"#478551"}}></i>
                  <i className="fa fa-hospital-o fa-stack-1x fa-inverse" style={{fontSize: 18}} ></i>
              </span>
              <span data-tip="Ver Descansos Médicos" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.enlaceRegistro(row,"DM")}}>
                  <ReactTooltip />
                  <i className="fa fa-circle fa-stack-2x" style={{color:"#323966"}}></i>
                  <i className="fa fa-ambulance fa-stack-1x fa-inverse" style={{fontSize: 18}}></i>
              </span>    
             </div>
             }              
          </div>
      );
    }else{}
  }

  colorCese(cell, row){
    if(row != undefined){
      return (
           <div>
           {row.vCese==1 ?(
                 <span data-tip="Cesado" className="fa-stack fa-1x">
                 <i className="fa fa-arrow-circle-o-down fa-stack-2x" style={{color:"#821C25"}}></i>
                </span>
                ):(
                  <span data-tip="No Cesado" className="fa-stack fa-1x">
                 <i className="fa fa-arrow-circle-o-up fa-stack-2x" style={{color:"#337AB7"}}></i>
                </span>
                )
              }
          </div>
       )
    }
  };

  GetActionFormat(cell, row) {
    if(row != undefined){
    return (
        <div>      
            <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.enlaceRegistro(row, "E") }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x text-primary"></i>
              <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span> 
            <span  data-tip="Eliminar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x"></i>
              <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>     
        </div>     
    );
  }else{}
  }

  registrarVacaciones(cell,row){
    if(row!=undefined){
      return(
        <div>
           {row.cargo=="PENSIONISTA" ?  null :
          <div>
            <span data-tip="Nueva Vacación" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.enlaceRegistro(row,"CV")}}>
                  <ReactTooltip />
                  <i className="fa fa-circle fa-stack-2x"style={{ color:"#391232"}}></i>
                  <i className="fa fa-plane fa-stack-1x fa-inverse" style={{fontSize: 18}} ></i>
            </span>  
            <span data-tip="Ver Vacaciones" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.enlaceRegistro(row,"DV")}}>
                  <ReactTooltip />
                  <i className="fa fa-circle fa-stack-2x" style={{color:"#7C380B"}}></i>
                  <i className="fa fa-suitcase fa-stack-1x fa-inverse" style={{fontSize: 18}}></i>
            </span>
          </div>  
           }
        </div>
      )
    }
  }

  async detalle(){
    this.props.history.push('/crearTrabajador');
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white",width:40}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "documentNumber",text: "DNI", filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white",width:100}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nombreCompleto", text: "NOMBRES Y APELLIDOS", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
     // {dataField: "birthDate", text: "FECHA DE NACIMIENTO", headerStyle: {backgroundColor: '#337AB7', color: "white", width:110}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    //  {dataField: "secTelephone", text: "N° CELULAR", headerStyle: {backgroundColor: '#337AB7', color: "white",width:100}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "area", text: "ÁREA", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "cargo", text: "CARGO", filter : textFilter(),sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipoperson", text: "RÉGIMEN", filter : textFilter(),sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "modalidad", text: "MODALIDAD", filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "vacaciones", text: "VACACIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:120}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.registrarVacaciones},
      {dataField: "descansos", text: "DESCANSO MÉDICO", headerStyle: {backgroundColor: '#337AB7', color: "white",width:120}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.registrarDM},
      {dataField: "papeletas", text: "PAPELETA", headerStyle: {backgroundColor: '#337AB7', color: "white",width:120}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.registrarPapeleta},
      {dataField: "vCese", text: "CESE", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:50}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.colorCese},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white", width:120}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Trabajadores</h4>
              <div className="row">

                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel></ControlLabel><br></br>
                     <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.detalle() }}>Nuevo Trabajador</Button>
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
export default ConsultarTrabajadores;