import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Route,Switch, Redirect} from 'react-router-dom'
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator ,paramRRHH} from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import InputFiles from 'react-input-files';

import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";
import store from "store/Store.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { concursos } from 'store/Apis'

import moment, { max } from 'moment'
import swal from 'sweetalert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class AudiPostulaciones extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'audiPostulaciones',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      selectedOption: "",
      concursos: [],
      tiposconcurso: [],
      concurso: "",
      periodo: new Date().getFullYear(),
      mes: "",
      nidcarga: "",
      estado: 0,
      isLoading: true,
      show: true,
      fechaini: moment(new Date()).format('YYYY-MM-DD'),
      fechafin: moment(new Date()).format('YYYY-MM-DD'),
      selectedOption: "",
      selectedOption2: {value: "6", label: "TODOS"},
      verFecha:"",
      estadosconcurso: [{value: "0", label: "NUEVA"},{value: "1", label: "EN PROCESO"},{value: "2", label: "DESIERTA"},{value: "3", label: "FINALIZADA"},{value: "4", label: "SUSPENDIDA"},{value: "5", label: "CANCELADA"},{value: "6", label : "TODOS"}],
      seleccionado: 1,
      anio: '',
      mes: '',
      years: [],
      meses: [],
      descripcion: "",
      tipoFecha:3,
      tipoBoton:"Con Fecha"
      
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);
    this.GetActionFormat3= this.GetActionFormat3.bind(this);
    this.handleConcurso = this.handleConcurso.bind(this);
    this.handlePeriodo = this.handlePeriodo.bind(this);
    this.handleMes = this.handleMes.bind(this);
    this.handleDescripcion = this.handleDescripcion.bind(this);
    this.handleFechaIni = this.handleFechaIni.bind(this);
    this.handleFechaFin = this.handleFechaFin.bind(this);

  }

  handleAnio = anio => {
    this.setState(
    { anio },
    );
  };

  handleMes = mes => {
    this.setState(
    { mes },
    );
  };
    handleConcurso(event) {
        this.setState({concurso: event.target.value});
    }

    handlePeriodo(event) {
        this.setState({periodo: event.target.value});
    }

    handleMes(event) {
        this.setState({mes: event.target.value});
    }
    handleDescripcion(event) {
      this.setState({descripcion: event.target.value});
  }

    handleChange = selectedOption => {
        this.setState(
        { selectedOption },
        );
    };

    handleChange2 = selectedOption2 => {
      this.setState(
      { selectedOption2 },
      );
    };
  handleFechaIni(event) {
    this.setState({fechaini: event.target.value});
  }
  handleFechaFin(event) {
    this.setState({fechafin: event.target.value});
  }

  async buscar(){
    swal({
      title: "Búsqueda de Concursos",
      text: 'Ingresa el año de búsqueda',
      content: "input",
      button: {
        text: "Buscar",
        closeModal: false,
      },
    })
    .then((value) => {
      if(value == null){
        swal.close();
      }else{
        this.setState({periodo: value})
        this.listarConcursos()
        swal.close();
      }
    });
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.mostrar(1);
             // this.calcularMes();
              this.generarPeriodos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
  generarPeriodos(){
    let year =  2020;
    let cont = 6;
    let a = Array.from(new Array(cont),(val, index) => index + year);
    let array = a.reverse()
    let arrayMes = [
      {"nid": "01", "descripcion": "ENERO"},
      {"nid": "02", "descripcion": "FEBRERO"},
      {"nid": "03", "descripcion": "MARZO"},
      {"nid": "04", "descripcion": "ABRIL"},
      {"nid": "05", "descripcion": "MAYO"},
      {"nid": "06", "descripcion": "JUNIO"},
      {"nid": "07", "descripcion": "JULIO"},
      {"nid": "08", "descripcion": "AGOSTO"},
      {"nid": "09", "descripcion": "SETIEMBRE"},
      {"nid": "10", "descripcion": "OCTUBRE"},
      {"nid": "11", "descripcion": "NOVIEMBRE"},
      {"nid": "12", "descripcion": "DICIEMBRE"},
    ]
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
                  })
        if(data == (new Date()).getFullYear())
          {
            this.setState({ anio: {value: cont2, label: data}})
          }
        })
    arrayMes.map((data) => {
        cont3++
        lista2.push({
         item: data.cont3,
          value: data.nid,
          label: data.descripcion,
                   })
        if(cont3 == (new Date()).getMonth() + 1)
        {
          this.setState({
          mes: {value: data.nid, label: data.descripcion}})
        }
    })
    this.setState({years: lista, meses: lista2})
    this.cargaView()
  }

  // async calcularMes(){
  //   let mesx = new Date().getMonth() + 1;
  //   if(mesx == 13){
  //     this.setState({mes: '1'})
  //   }else{
  //     this.setState({mes: mesx})
  //   }
  //   this.cargaView();
  // }

  formatoMes(mes){
    if(mes <= 9){
      mes = '0' + mes
    }else if(mes >= 13){
      mes = '01'
    }
    return mes
  }

  async cargaView() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${concursos}` + 'private/listtipoconcursos')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.nid,
                label: data.ctxt_descripcion,
            })
          });
          const nuevoElemento = { value: 0, label: 'TODOS' };
          lista.push(nuevoElemento);
          this.setState({tiposconcurso: lista, selectedOption: {value: 0, label: 'TODOS'}})
          this.listauditoriaConcursos();
      }else{
        this.setState({tiposconcurso: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
   }

  // async listarConcursos() {
  //     this.setState({isLoading: true})
  //     axios.defaults.headers.common['Authorization'] = this.state.token
  //     axios.get(`${concursos}` + 'private/listconcursosx?anio=' + this.state.periodo)
  //     .then((res) => {
  //       let lista = [];
  //       let lista2 = res.data.response;
  //       if(lista2 != null){
  //         lista2.map((data) => {
  //           lista.push(
  //             { item: data.item,
  //               nidconcurso: data.nid,
  //               nidtipoconcurso: data.nid_tipo,
  //               concurso: data.ctxt_descripcion,
  //               tipo: data.ctxt_tipo,
  //               anio: data.cnum_anio,
  //               mes: data.cnum_mes,
  //               fecha: Calendar.calendario9(data.dfec_inicio),
  //               fecha2: Calendar.calendario9(data.dfec_final),
  //               fecha3: Calendar.calendario10(data.dfec_inicio),
  //               fecha4: Calendar.calendario10(data.dfec_final),
  //               publicado: data.flg_publicado
  //           })
  //         })
  //         this.setState({concursos: lista, isLoading: false})
  //       }else{
  //         this.setState({concursos: [], isLoading: false})
  //         this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
  //       }
  //     })
  //     .catch((error) => { 
  //       if(auth0Client.isAuthenticated() === true){
  //         auth0Client.signOut()
  //         this.props.history.replace('/');
  //       }else{
  //         console.log(error);
  //         this.setState({isLoading: false})
  //       }
  //     });
  // }


  async listauditoriaConcursos() {
    var tipoconcurso =this.state.selectedOption.value==0 ? "" : this.state.selectedOption.value
    var econcurso = this.state.selectedOption2.label=="TODOS" ? "" : this.state.selectedOption2.label
    var fechaIP =this.state.verFecha==3 ? this.state.anio.label : this.state.verFecha==4 ?this.state.fechaini : ""
    var fechaFM = this.state.verFecha==3 ?this.state.mes.value  : this.state.verFecha==4 ? this.state.fechafin : ""
    var fechaTipo = this.state.verFecha==3 || this.state.verFecha==4 ? this.state.tipoFecha : ""
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${concursos}` + 'private/listauditoriaConcursos?tconcurso=' + tipoconcurso + '&econcurso=' + econcurso + '&descripcion=' + this.state.descripcion + '&fechaIP=' + fechaIP + '&fechaFM=' + fechaFM+ '&tipoFecha=' + fechaTipo  )
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { item: data.item,
              nidconcurso: data.nid,
              nidtipoconcurso: data.nid_tipo,
              concurso: data.ctxt_descripcion,
              tipo: data.ctxt_tipo,
              anio: data.cnum_anio,
              mes: data.cnum_mes,
              fecha: Calendar.calendario9(data.dfec_inicio),
              fecha2: Calendar.calendario9(data.dfec_final),
              fecha3: Calendar.calendario10(data.dfec_inicio),
              fecha4: Calendar.calendario10(data.dfec_final),
              publicado: data.flg_publicado,
              ctxt_estado:data.ctxt_estado
          })
        })
        this.setState({concursos: lista, isLoading: false})
      }else{
        this.setState({concursos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }



  
  // async listauditoriaConcursos(){
  //       this.setState({isLoading3: true})
  //       let fromData = new FormData();
  //       fromData.append('tconcurso', this.state.selectedOption.value==0 ? "" : this.state.selectedOption.value );
  //       fromData.append('econcurso', this.state.selectedOption2.label=="TODOS" ? "" : this.state.selectedOption2.label);
  //       fromData.append('descripcion', this.state.descripcion);
  //       fromData.append('fechaIP',   this.state.verFecha==3 ? this.state.anio.label : this.state.verFecha==4 ?this.state.fechaini : "" );
  //       fromData.append('fechaFM', this.state.verFecha==3 ?this.state.mes.value  : this.state.verFecha==4 ? this.state.fechafin : "");
  //       fromData.append('tipoFecha', this.state.verFecha==3 || this.state.verFecha==4 ? this.state.tipoFecha : "");
  //       axios.defaults.headers.common['Authorization'] = this.state.token
  //       axios.post(`${concursos}` + 'private/listauditoriaConcursos', fromData)
  //       .then((response) => {
  //         var datos = response.data
  //         console.log(datos)
  //         if(datos.response != null){
  //           this.setState({concursos: datos.response, isLoading: false})   
  //         }else{
  //           this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
  //           this.setState({isLoading3: false})
  //         }
  //       })
  //       .catch((error) =>{ 
  //         if(auth0Client.isAuthenticated() === true){
  //           auth0Client.signOut()
  //           this.props.history.replace('/');
  //         }else{
  //           console.log(error);
  //           this.setState({isLoading: false})
  //         }
  //       });
  // }

 

 async limpiar(){
    this.listarConcursos();
    this.cancelar();
    this.props.handleClick("tl", "info", "El concurso ha sido registrado!", "pe-7s-smile")
  }

  async cancelar(){
    this.setState({selectedOption: {value: this.state.tiposconcurso[0].value, label: this.state.tiposconcurso[0].label}, concurso: "", nidcarga: ""})
  }
  
  async obtener(data){
    localStorage.setItem('concurso', JSON.stringify(data))
    this.props.history.push('/audiPostulantes');
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              {row.publicado == '1' ? (
              <span data-tip="Ver Postulantes" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-graduation-cap fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
          </div>
      );
    }else{}
  }

  GetActionFormat2(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Ver Resoluciones del Concurso" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.verResoluciones(row,1) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-info"></i>
                <i className="fa fa-archive fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  GetActionFormat3(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Ver Resoluciones del Ganador" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.verResoluciones(row,2) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-trophy fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  async verResoluciones(row,param){
    paramRRHH.resoluciones[0].data = [{row},{param}]
    localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH)) 
    this.props.history.push('/verResoluciones');
  }
  cambiar(){
    if(this.state.tipoBoton=='Con Fecha'){
      this.setState({ ver : "2", verFecha : "2",tipoBoton: 'Sin Fecha' });
    }else{
      this.setState({ver : "1",verFecha : "3" ,tipoBoton: 'Con Fecha', tipoFecha: 3 })
    }
    
  }

  mostrar(param){
    
    if(param==1){
        this.setState({ver : "1",verFecha : "3" })
    }else if(param==2){
      this.setState({ ver : "2", verFecha : "2" });


    }else if(param==3){ 
      this.setState({ tipoFecha: param , verFecha : "3" , ver :"3"});
    }else{
      this.setState({tipoFecha: param,verFecha : "4" , ver :"4" });
    } 
  }

  render() {
    const { selectedOption, selectedOption2 } = this.state;
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:90}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ctxt_estado", text: "ESTADO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "concurso", text: "CONCURSO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "anio", text: "PERIODO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "mes", text: "MES", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipo", text: "TIPO DE CONCURSO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha", text: "FECHA INICIAL", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha2", text: "FECHA FINAL", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "resolucion", text: "RESOLUCIÓN DEL CONCURSO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},
      {dataField: "resolucion", text: "RESOLUCIÓN DEL GANADOR", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.GetActionFormat3},
      {dataField: "detail", text: "POSTULANTES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.concursos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.concursos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Concursos Públicados</h4>

              <div className="row" >
                <div className="col-md-12"  onChange={this.onChangeValue}>
                    <ControlLabel><p>Búsqueda por :</p></ControlLabel><br></br>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>TIPOS DE CONCURSO</ControlLabel>
                            <Select value={selectedOption} onChange={this.handleChange} options={this.state.tiposconcurso}/>
                        </div>
                        <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>ESTADO DEL CONCURSO</ControlLabel>
                            <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.estadosconcurso}/>
                        </div>
                    <div className="col-md-6" style={{marginBottom: 18}}>
                            <ControlLabel>DESCRIPCIÓN / N° RESOLUCIÓN DEL CONCURSO</ControlLabel>
                            <FormControl type="text" value={this.state.descripcion} onChange={this.handleDescripcion}/>
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                          <ControlLabel></ControlLabel>
                          <Button style={{  width: 245,marginTop:5, color:'white', backgroundColor:  'rgb(51, 122, 183)', borderColor:  'rgb(51, 122, 183)' }}
                          filltype="submit" onClick={() => this.cambiar()}> {this.state.tipoBoton}
                          </Button>
                      </div>   
                  
                    {/* <div className="col-md-2">
                        <Button style={{  width: 180, marginBottom: 10,marginTop: 10,color:'white', backgroundColor: this.state.seleccionado === 1 ? 'rgb(51, 122, 183)' : '#D2D4D1', borderColor: this.state.seleccionado === 1 ? 'rgb(51, 122, 183)' : '#D2D4D1' }}
                        filltype="submit"onClick={() => this.mostrar(1)}> Con Fecha
                        </Button>
                    </div>             
                    <div className="col-md-2">
                      <Button style={{  width: 180, marginBottom: 10, marginTop:10,color:'white',backgroundColor: this.state.seleccionado === 2 ? 'rgb(51, 122, 183)' : '#D2D4D1',borderColor: this.state.seleccionado === 2 ? 'rgb(51, 122, 183)' : '#D2D4D1'}} filltype="submit" onClick={() => this.mostrar(2)}  > Sin Fecha 
                      </Button>
                    </div>               */}


                </div>  
               <div className="col-md-12"> 
                {this.state.ver !== "2" ? (
                      <div className="col-md-4">   
                       
                            <label htmlFor="dependiente3" style={{ marginRight: 10}}>
                                <input type="radio" value="3" name="dependiente" id="dependiente3" style={{ marginRight: 5 }} onChange={(e) => this.mostrar(e.target.value)} defaultChecked/>
                                Periodo/Mes
                            </label>
                       
                       
                            <label htmlFor="dependiente4" style={{ marginRight: 10, marginLeft:35 }}>
                                <input type="radio" value="4" name="dependiente" id="dependiente4" style={{ marginRight: 5 }} onChange={(e) => this.mostrar(e.target.value)} />
                                Fecha Inicio/Fin
                            </label>
                       
                      </div>
                  ) :  <div className="col-md-10">  </div>} 
                { this.state.verFecha == "3"  ? (
                    <div className="col-md-6">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>PERIODO</ControlLabel>
                                <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>MES</ControlLabel>
                                <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses}/>
                            </div>
                    </div>
                    ) : this.state.verFecha == "4"  ? (
                    <div className="col-md-6">
                      <div className="col-md-6" style={{marginBottom: 18}}>
                          <ControlLabel>FECHA DE INICIO</ControlLabel>
                          <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaIni}  />
                      </div>
                      <div className="col-md-6" style={{marginBottom: 18}}>
                          <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                          <FormControl type="date" value={this.state.fechafin}  onChange= {this.handleFechaFin} min={this.state.fechaini}/>
                      </div>
                    </div>) :null}
                <div className="col-md-2">
                      <Button style={{width: 245, marginBottom: 10, marginTop:25}} bsStyle="success"  fill type="submit" onClick={() => { this.listauditoriaConcursos() }}>Buscar</Button>   
                </div>
               </div>
                  

                   
               
              



             
       
             
            </div>


              {/* <Button style={{width: 150, marginTop: 10, marginBottom: 10}} bsStyle="success" pullLeft fill type="submit" onClick={() => { this.buscar() }}>Buscar</Button> */}
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AudiPostulaciones;