import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col , Alert } from "react-bootstrap";import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, cec,sisperplus,fechasBusqueda,paramCec} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Swal from 'sweetalert2'
import { confirmAlert } from 'react-confirm-alert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ListSubCategorias extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'publicaciones',
      usuario: localStorage.getItem('nickname'),
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer '+localStorage.getItem('AccessToken') : 0,
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      nidcat:'',
      nid: '',
      descripcion:"",
      nidPersona:'',
      item: "",
      flg_envio:"",
      nid:"",
      nombre: "",
      ctxt_correo : "",
      nidper: "",   
      isLoading: true,
     
      enlace:"",
      fecha:""
    }
    this.GetActionFormat= this.GetActionFormat.bind(this); 
   
    this.secciones= this.secciones.bind(this);
    this.handleTitulo=this.handleTitulo.bind(this);
    this.handleDescripcion=this.handleDescripcion.bind(this);
 }

 handleTitulo(event) {this.setState({titulo: event.target.value});}
 handleDescripcion(event) {this.setState({descripcion: event.target.value});}
 

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{           
               this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(JSON.parse(localStorage.getItem("cec")).actividades[0].data !== null){
        this.setState({
            nidcat: JSON.parse(localStorage.getItem('cec')).actividades[0].data.nid,
        });
        this.listActSubCategoriasActividades();
    }else{
      this.props.history.push('/consultarActividades');
    }
  }

  async listActSubCategoriasActividades(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${cec}` + '/public/listActSubCategoriasActividades?subcategoria='+this.state.nidcat+'&seccion=1')
    .then((res) => {                                                                                    
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({
             ctxt_titulo: data.ctxt_titulo,
              item: data.item,
              ctxt_descripcion: data.ctxt_descripcion,
              nid: data.nid, 
                       
            })
        })
          this.setState({movimientos: lista, isLoading: false})       
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
    .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
    });
  } 
  async save(){
    if(this.state.titulo != "" && this.state.descripcion != "" ){
      if(this.state.nid == ''){
        this.guardar()
      }else{
        this.update()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  } 

  async guardar(){ 
        this.setState({isLoading: true})
        let fromData = new FormData();
        fromData.append('subcategori', this.state.nidcat);
        fromData.append('titulo', this.state.titulo);
        fromData.append('descripcion', this.state.descripcion);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${cec}` + 'contenido/insertarSubseccionesAct', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            this.props.handleClick("tl", "success", "Ha sido registrado correctamente!", "pe-7s-close")
            this.borrar()
            this.listActSubCategoriasActividades();
          }else{
              this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
              this.setState({isLoading: false})
          }
          })
          .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
          }else{
              console.log(error);
              this.setState({isLoading: false})
          }
        }); 
  }

  async update(){ 
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', this.state.nid);
    fromData.append('titulo', this.state.titulo);
    fromData.append('descripcion', this.state.descripcion);
    fromData.append('estado', 0);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'contenido/editarSubseccionesAct', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Registro actualizado correctamente!", "pe-7s-close")
        this.borrar();
        this.listActSubCategoriasActividades()
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  
  async Cancelar(){
    this.props.history.push('/consultarActividades');
   }

 
   async detalle(data){
    this.setState({
    titulo: data.ctxt_titulo,
    descripcion: data.ctxt_descripcion,
    nid: data.nid,
    
   })
  }

  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea anular el registro?',
      message: 'Se anulará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async delete(data){ 
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', data.nid);
    fromData.append('titulo', this.state.titulo);
    fromData.append('descripcion', this.state.descripcion);
    fromData.append('estado', 1);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'contenido/editarSubseccionesAct', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Registro eliminado correctamente!", "pe-7s-close")
        this.borrar();
        this.listActSubCategoriasActividades()
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }


   GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
             
              <span data-tip="Anular Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }
  async borrar(){
    this.setState({titulo: "", descripcion: "", nid: "",isLoading:false})
  }


secciones(cell, row) {
  if(row != undefined){
    return (
        <div>
            <span data-tip="Ver Secciones" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.versubsecciones(row) }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x text-info"></i>
              <i className="fa fa-eye fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>
        </div>
    );
  }else{}
}


async versubsecciones(data){
  paramCec.subactividades[0].data = data
  localStorage.setItem('cec', JSON.stringify(paramCec))
  this.props.history.push('/listSubSecciones')
}



  render() {
    const columns = [
      {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white",width:40}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ctxt_titulo", text: "TÍTULO", sort: true, filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white",}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ctxt_descripcion", text: "DESCRIPCIÓN", filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},   
      {dataField: "flg_orden", text: "SUB-SECCIONES",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center',align: 'center',formatter :this.secciones},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Actividades de Capacitación</h4>
              <div className="row">
              
                <div className="col-md-12" style={{marginBottom: 18}}>
        
                <ControlLabel></ControlLabel><br></br>
                    <div className="row" style={{marginBottom: 10}}>
                        <div className="col-md-6" style={{marginBottom: 18}}>
                            <ControlLabel>TÍTULO</ControlLabel>
                            <FormControl type="text" value={this.state.titulo} onChange= {this.handleTitulo}/>
                        </div>
                        <div className="col-md-6" style={{marginBottom: 18}}>
                            <ControlLabel> DESCRIPCIÓN</ControlLabel>
                            <FormControl type="text" value={this.state.descripcion} onChange= {this.handleDescripcion}/>
                        </div>         
                        <div className="col-md-12">
                        <Button style={{width: 120,marginTop:20, marginBottom: 5 }} bsStyle="danger" pullRight type="submit" onClick={() => { this.borrar() }}>Cancelar</Button>
                        <Button style={{width: 120,marginTop:20, marginBottom: 5 , marginRight: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.save() }}>Guardar</Button>
                        </div>             
                    </div>
                    <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.Cancelar() }}>Volver</Button>
                </div>
                <div style={{marginBottom: 18}}>            
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ListSubCategorias;