import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, fechasBusqueda,paramRRHH,gestor} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { confirmAlert } from 'react-confirm-alert';



const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class DescansoMedico extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarDesMedicos',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      anio: '',
      mes: '',
      years: [],
      meses: [],
      isLoading: true,
      dni2:""
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.colorear = this.colorear.bind(this);
 }

    handleAnio = anio => {
      this.setState(
      { anio },
      );
    };

    handleMes = mes => {
      this.setState(
      { mes },
      );
    };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.generarPeriodos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
  generarPeriodos(){
    let year =  2000;
    let cont = 26;
    let a = Array.from(new Array(cont),(val, index) => index + year);
    let array = a.reverse()
    let arrayMes = [
      {"nid": "01", "descripcion": "ENERO"},
      {"nid": "02", "descripcion": "FEBRERO"},
      {"nid": "03", "descripcion": "MARZO"},
      {"nid": "04", "descripcion": "ABRIL"},
      {"nid": "05", "descripcion": "MAYO"},
      {"nid": "06", "descripcion": "JUNIO"},
      {"nid": "07", "descripcion": "JULIO"},
      {"nid": "08", "descripcion": "AGOSTO"},
      {"nid": "09", "descripcion": "SETIEMBRE"},
      {"nid": "10", "descripcion": "OCTUBRE"},
      {"nid": "11", "descripcion": "NOVIEMBRE"},
      {"nid": "12", "descripcion": "DICIEMBRE"},
    ]
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    if(fechasBusqueda.descansoMedico[0].nidperiodo != "" && fechasBusqueda.descansoMedico[1].periodo != ""){
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
      })
      this.setState({anio: {
        value: fechasBusqueda.descansoMedico[0].nidperiodo, 
        label: fechasBusqueda.descansoMedico[1].periodo
      }})
    }else{
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
          this.setState({anio: {value: cont2, label: data}})
        }
      })
    }
    if(fechasBusqueda.descansoMedico[2].nidmes != "" && fechasBusqueda.descansoMedico[3].mes != ""){
      arrayMes.map((data) => {
        cont3++
        lista2.push({
            item: data.cont3,
            value: data.nid,
            label: data.descripcion,
        })
      })
      this.setState({mes: {
        value: fechasBusqueda.descansoMedico[2].nidmes, 
        label: fechasBusqueda.descansoMedico[3].mes, 
      }})
    }else{
      arrayMes.map((data) => {
        cont3++
        lista2.push({
            item: data.cont3,
            value: data.nid,
            label: data.descripcion,
        })
        if(cont3 == (new Date()).getMonth() + 1){
          this.setState({mes: {value: data.nid, label: data.descripcion}})
        }
      })
    }
    this.setState({years: lista, meses: lista2})
    this.listDescansoMedico()
 }

  async listDescansoMedico() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'descanso/listDescansoMedico?anio=' + this.state.anio.label + '&mes=' + this.state.mes.value)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              fechaIni : Calendar.calendarioA(data.DateBegin),
              fechaFin: Calendar.calendarioA(data.DateEnd),
              dias : data.Days,
              nomTipoDescanso :data.Description,
              idTipoDescanso: data.MedicalRestType,
              idperson :data.Person,
              dni: data.DocumentNumber,
              nombres:data.Name,
              estado : data.Status=="A" ? "AUTORIZADO" : data.Status=="P" ? "PENDIENTE" : "DESAUTORIZADO",
              idestado: data.Status,
              fechaCreacion : Calendar.calendarioA(data.XLastDate),
              centro : data. centromedico,
              medico: data.medico,
              diagnostico: data.diagnostico,
              line:data.line,
              item:data.item,
              ruta:"P",
              FileName : data.FileName,
              FileRoot: data.FileRoot,
              secuencia: data.Secuence,
              observacion: data.observacion,
              idcontingencia: data.id_contingencia,
              descontingencia: data.des_contingencia,
            }) 
          }) 
          fechasBusqueda.descansoMedico[0].nidperiodo = this.state.anio.value
          fechasBusqueda.descansoMedico[1].periodo = this.state.anio.label
          fechasBusqueda.descansoMedico[2].nidmes = this.state.mes.value
          fechasBusqueda.descansoMedico[3].mes = this.state.mes.label
          localStorage.setItem('fechasBusqueda', JSON.stringify(fechasBusqueda))
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea eliminar el registro?',
      message: 'Se eliminará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('persona', data.idperson);
    fromData.append('line', data.line);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'descanso/deleteDescansoMedico', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
         if(data.FileName==null){
          this.listDescansoMedico()
          this.props.handleClick("tl", "info", "El registro ha sido eliminado!", "pe-7s-smile")
         }else{
           this.remove(data)
         }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  remove(data){
    console.log(data)
    this.setState({isLoading2: true})
    let fromData = new FormData();
    fromData.append('persona', data.idperson);
    fromData.append('line', data.line);
    fromData.append('secuencia', data.secuencia);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'descanso/eliminarArchivo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        
         this.removerArchivo(data);
      }else{
         this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
         this.setState({isLoading2: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading2: false})
      }
    });
   }

   removerArchivo(archivo){
    let anio = (archivo.fechaCreacion).substring(6,10)
    let mes = (archivo.fechaCreacion).substring(3,5)
    let fromData = new FormData();
    fromData.append('usuario', this.state.usuario);
    fromData.append('anio', anio);
    fromData.append('mes', mes);
    fromData.append('cnomArchivo', archivo.FileRoot);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${gestor}` + 'document/bajarArchivoDes', fromData)
    .then((response) => {
    if(response.data.result == null){
        this.listDescansoMedico()
        this.props.handleClick("tl", "info", "El registro ha sido eliminado!", "pe-7s-smile")
        this.setState({isLoading2: false})
    }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar archivo!", "pe-7s-close")
        this.setState({isLoading2: false})
    }
    })
    .catch((error) =>{ 
    if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
    }else{
        console.log(error);
        this.setState({isLoading: false})
    }
    });
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Ver Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20,float:"left"}} onClick={() => { this.ver(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              {row.estado == 'PENDIENTE' || row.estado == 'DESAUTORIZADO'  ? (
              <span  data-tip="Eliminar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
          </div>
      );
    }else{}
  }

  colorear(cell, row){
    if(row != undefined){
      return (
          <div>
             {row.estado=="AUTORIZADO" ? 
                <p style={{fontSize: 14, color: '#2E8F48'}}>{row.estado}</p>
                : row.estado =="PENDIENTE" ?
                <p style={{fontSize: 14, color: '#D8C220'}}>{row.estado}</p>
                  :
                <p style={{fontSize: 14, color: '#A42D13'}}>{row.estado}</p>
              }
          </div> 
      )
    }}

  async ver(data){
    paramRRHH.descansoM[1].enlace = "D"
    paramRRHH.trabajadoresAdm[1].enlace = "D"
    paramRRHH.descansoM[0].data = data
    localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH))
    this.props.history.push('/editarDescansoMedico');
  }

  async detalle(){
    paramRRHH.descansoM[1].enlace = "DT"
    paramRRHH.trabajadoresAdm[1].enlace = "DT"
    localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH))
    this.props.history.push('/crearDescansoMedico');
  }

  async gestionarDM(){
    this.props.history.push('/gestionarDM');
  }

  async reporteDM(){
    this.props.history.push('/reporteDias');
  }

  async reporteDM2(){
    this.props.history.push('/reporteDesMedico');
  }

  async reporteDM3(){
    this.props.history.push('/reporteControl');
  }

  async reporteDM4(){
    this.props.history.push('/reporteRegistro');
  }

  async reporteDM5(){
    this.props.history.push('/reporteAcumulado');
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white",width:50}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "dni", text: "DNI", filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nombres", text: "NOMBRES Y APELLIDOS",  filter: textFilter(),sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:300}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nomTipoDescanso", text: "DESCANSO MÉDICO",  filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white", width:400}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechaIni", text: "FEC. INICIO", filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechaFin", text: "FEC. TERMINO",filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    //  {dataField: "centro", text: "CENTRO MEDICO",  filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'justify'},
    //  {dataField: "medico", text: "MÉDICO", filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'justify'},
    //  {dataField: "diagnostico", text: "DIAGNOSTICO",  filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white",width:185}, headerAlign: (column, colIndex) => 'center', align: 'justify'},
      {dataField: "estado", text: "ESTADO", filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.colorear},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white", width:120}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
           
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              expandRow={ expandRow }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"            
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const expandRow = {
      onlyOneExpanding: true,
      renderer: row => (
        <div> 
          <div className="row">
            <div className="col-md-12">
              <p style={{fontWeight: 'bold', fontSize: 15}}>MOTIVO: <a style={{color: '#333'}}>{ row.centro }</a></p>
              <p style={{fontWeight: 'bold', fontSize: 15}}>MÉDICO: <a style={{color: '#333'}}>{ row.medico }</a></p>
              <p style={{fontWeight: 'bold', fontSize: 15}}>DIAGNÓSTICO: <a style={{color: '#333'}}>{ row.diagnostico }</a></p>
            </div>
          </div>
        </div>
      )
    };
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Descansos Médicos</h4>
                <div className="row">
                  <div className="col-md-2" style={{marginBottom: 18}}>
                      <ControlLabel>PERIODO</ControlLabel>
                      <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                  </div>
                  <div className="col-md-2" style={{marginBottom: 18}}>
                      <ControlLabel>MES</ControlLabel>
                      <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses}/>
                  </div>
                  <div className="col-md-8" style={{marginBottom: 18}}>
                      <ControlLabel></ControlLabel><br></br>
                      <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.listDescansoMedico() }}>Buscar Registros</Button>
                      <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.detalle() }}>Nuevo Registro</Button>
                      <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="warning" fill type="submit" onClick={() => { this.gestionarDM() }}>Gestionar</Button>
                      <Button style={{width: 240, marginTop: 5, marginBottom: 10}} bsStyle="primary" fill type="submit" onClick={() => { this.reporteDM() }}>Reporte</Button>
                      <Button style={{width: 240, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="primary" fill type="submit" onClick={() => { this.reporteDM3() }}>Reporte Control</Button>
                      <Button style={{width: 240, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="primary" fill type="submit" onClick={() => { this.reporteDM4() }}>Reporte DM</Button>
                      <Button style={{width: 240, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="primary" fill type="submit" onClick={() => { this.reporteDM2() }}>Reporte General</Button>                      
                      <Button style={{width: 240, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="primary" fill type="submit" onClick={() => { this.reporteDM5() }}>Reporte Acumulado</Button>                      
                  </div>
                </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default DescansoMedico;