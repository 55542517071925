import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator,jurisdiccional} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Penalidades extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'adminPenalidades',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true
    }
    this.GetActionFormat = this.GetActionFormat.bind(this);
    this.verResolucion = this.verResolucion.bind(this);
    this.verEstado = this.verEstado.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
             this.listarPenalidades();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listarPenalidades() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${jurisdiccional}` + 'penalidades/listPenalidades')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              nombre: data.nombre,
              dni: data.dni == null ? "" : data.dni,
              penalidad: data.penalidad,
              expediente: data.cnum_expediente,
              colegiado:data.ctxt_colegiado,
              condicion: data.ctxt_condicion,
              resolucion: data.resolucion,
              flg_cancelado: data.flg_cancelado,
              cancelado: data.cancelado,
              fecha: data.fecha==null ? "" : Calendar.calendario12(data.fecha),
              item: data.item,
              nid: data.nid
            })
          })
         this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  


  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
            <div>
                {row.flg_cancelado == "0" ? (
              <span data-tip="Cancelar Penalidad" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-credit-card fa-stack-1x fa-inverse" style={{fontSize: 20}}></i>
              </span>)
              : null
              }
            </div>
      );
    }else{}
  }

  confirmar = (data) => {
    confirmAlert({
      title: '¿Seguro que desea cancelar la penalidad?',
      message: 'Se cancelará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.detalle(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async detalle(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'penalidades/cancelarPenalidades', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          if(datos.response.estado == 1){
              this.listarPenalidades();
              this.props.handleClick("tl", "info", "La penalidad ha sido cancelada!", "pe-7s-smile")
          }else{
              this.props.handleClick("tl", "error", "No se puede cancelar la penalidad!", "pe-7s-close")
              this.setState({isLoading: false})
          }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al cancelar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });

  }

  verResolucion(cell,row){
    if(row != undefined){
      return (
        <div>
              {row.resolucion != null && row.resolucion != ''? (
               <span data-tip="Ver Resolución" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(row.resolucion) }}>
               <ReactTooltip />
               <i className="fa fa-file-pdf-o fa-3x" style={{fontSize: 35, color:"red"}}></i>
             </span>
              ) : (null)}
          </div>
      );
    }else {}
  }

  verEstado(cell,row){
    if(row != undefined){
      return (
        <div>
            <p style={{fontSize: 14}}>{row.nombre}</p>  
            {row.flg_cancelado == 1 ? (
              <p style={{fontSize: 14, color: 'blue'}}>{row.cancelado}</p>
              ) : (
              <p style={{fontSize: 14, color: 'red'}}>{row.cancelado}</p>
              )
            }
        </div>
      );
    }else {}
  }

  abrirImagen(ruta){
    window.open(ruta, '_blank')
  }

  render() {
    const columns = [
    {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "expediente", text: "NÚMERO DE EXPEDIENTE", filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "colegiado", text: "COLEGIADO", filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "nombre", text: "NOMBRES Y APELLIDOS", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:300}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.verEstado},
    {dataField: "dni", text: "DNI o RUC", filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align: 'center'},
    {dataField: "condicion", text: "CONDICIÓN", filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align: 'center'},
    {dataField: "penalidad", text: "PENALIDAD", filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "fecha", text: "FECHA", filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "resolucion", text: "RESOLUCIÓN", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.verResolucion}, 
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Penalidades</h4>            
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Penalidades;