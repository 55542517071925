import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col} from "react-bootstrap";
import {FormControl} from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";

import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { cec,paramCec,paramAulaVirtual} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { validator, sisperplus,fechasBusqueda } from 'store/Apis';
import moment from 'moment'
import Calendar from "components/Calendar/Calendar.jsx";


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Produccion extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
    path: 'produccion',
    usuario: localStorage.getItem('nickname'),
    token: 'Bearer ' + localStorage.getItem('ConcurToken'),
    token2: 'Bearer ' + localStorage.getItem('AccessToken'),
    id: JSON.parse(localStorage.getItem('datos'))[0].Person,
    movimientos: [],
    isLoading:true,  
    comisiones :[],
    tproyectos :[],
    asesores : [],
    asignaciones: [],
    proyectos: [],
    activeTab: 1,

    fechaini: moment(moment().startOf('week').toDate()).format('YYYY-MM-DD'),
    fechafin: moment(moment().endOf('week').toDate()).format('YYYY-MM-DD'),
    

    fechaini2: moment(moment().startOf('week').toDate()).format('YYYY-MM-DD'),
    fechafin2: moment(moment().endOf('week').toDate()).format('YYYY-MM-DD'),
   
    
    }
    this.handleFechaIni = this.handleFechaIni.bind(this);
    this.handleFechaFin = this.handleFechaFin.bind(this);

    this.handleFechaIni2 = this.handleFechaIni.bind(this);
    this.handleFechaFin2 = this.handleFechaFin.bind(this);

  
  }
  handleFechaIni(event) {this.setState({fechaini: event.target.value});}
  handleFechaFin(event) {this.setState({fechafin: event.target.value});}

  handleFechaIni2(event) {this.setState({fechaini2: event.target.value});}
  handleFechaFin2(event) {this.setState({fechafin2: event.target.value});}
 


  handleTabChange = (index) => {
    this.setState({ activeTab: index });
    if(index=='1'){
      this.listComisiones()
    }else if(index=='2'){
      this.listTipoProyecto()
    }else if(index=='3'){
      this.listAsesores()
    }else if(index=='4'){
      this.listAsignacion()
    }else if(index=='5'){
      this.listProyecto()
    }
console.log('Pestaña activa:', index);
  };
  
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
             this.listComisiones();
             
            }
           
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listComisiones() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token2
    axios.get(`${sisperplus}` + 'produccion/listComisiones')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { item : data.item,
              comision : data.comision
          })
        });
        this.setState({comisiones: lista, isLoading: false})
      }else{
        this.setState({comisiones: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
}


async listTipoProyecto() {
  this.setState({isLoading: true})
  axios.defaults.headers.common['Authorization'] = this.state.token2
  axios.get(`${sisperplus}` + 'produccion/listTipoProyecto')
  .then((res) => {
    let lista = [];
    let lista2 = res.data.response;
    if(lista2 != null){
      lista2.map((data) => {
        lista.push(
          { item : data.item,
            tipo_proyecto : data.tipo_proyecto
        })
      });
      this.setState({tproyectos: lista, isLoading: false})
    }else{
      this.setState({tproyectos: [], isLoading: false})
      this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
    }
  })
  .catch((error) => { 
    if(auth0Client.isAuthenticated() === true){
      auth0Client.signOut()
      this.props.history.replace('/');
    }else{
      console.log(error);
      this.setState({isLoading: false})
    }
  });
}

async listAsesores() {
  this.setState({isLoading: true})
  axios.defaults.headers.common['Authorization'] = this.state.token2
  axios.get(`${sisperplus}` + 'produccion/listAsesores')
  .then((res) => {
    let lista = [];
    let lista2 = res.data.response;
    if(lista2 != null){
      lista2.map((data) => {
        lista.push(
          { item : data.item,
            asesor : data.asesor
        })
      });
      this.setState({asesores: lista, isLoading: false})
    }else{
      this.setState({asesores: [], isLoading: false})
      this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
    }
  })
  .catch((error) => { 
    if(auth0Client.isAuthenticated() === true){
      auth0Client.signOut()
      this.props.history.replace('/');
    }else{
      console.log(error);
      this.setState({isLoading: false})
    }
  });
}
async listAsignacion() {
  this.setState({isLoading: true})
  axios.defaults.headers.common['Authorization'] = this.state.token2
  axios.get(`${sisperplus}` + 'produccion/listAsignacion?fechaini=' + this.state.fechaini + '&fechafin=' + this.state.fechafin)
  .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { 
              item : data.item,
              asesor : data.asesor,
              asesor_coordinador : data.asesor_coordinador,
              tipo_proyecto : data.tipo_proyecto,
              expediente : data.expediente,
              comision : data.comision

          })
        });
        this.setState({asignaciones: lista, isLoading: false})
      }else{
        this.setState({asignaciones: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
  })
  .catch((error) => { 
    if(auth0Client.isAuthenticated() === true){
      auth0Client.signOut()
      this.props.history.replace('/');
    }else{
      console.log(error);
      this.setState({isLoading: false})
    }
  });
}

async listProyecto() {
  this.setState({isLoading: true})
  axios.defaults.headers.common['Authorization'] = this.state.token2
  axios.get(`${sisperplus}` + 'produccion/listProyecto?fechaini=' + this.state.fechaini+'&fechafin=' + this.state.fechafin)
  .then((res) => {
    let lista = [];
    let lista2 = res.data.response;
    if(lista2 != null){
      lista2.map((data) => {
        lista.push(
          { item : data.item,
            asesor: data.asesor,
            tipo_proyecto : data.tipo_proyecto,
            expediente: data.expediente,
            comision: data.comision,
            fecha_creacion: data.fecha_creacion


        })
      });
      this.setState({proyectos: lista, isLoading: false})
    }else{
      this.setState({proyectos: [], isLoading: false})
      this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
    }
  })
  .catch((error) => { 
    if(auth0Client.isAuthenticated() === true){
      auth0Client.signOut()
      this.props.history.replace('/');
    }else{
      console.log(error);
      this.setState({isLoading: false})
    }
  });
}

redirigirReporte(){
  this.props.history.push("/verReporte")
}



  
  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:250}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "comision", text: "COMISIÓN",filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align : "center"},
    ]
    const columns2 = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:250}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipo_proyecto", text: "TIPO PROYECTOS",filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align : "center"},
    ]
    const columns3 = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:250}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "asesor", text: "ASESORES",filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align : "center"},
    ]
    const columns4 = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:250}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "asesor", text: "ASESOR",filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align : "center"},
      {dataField: "asesor_coordinador", text: "COORDINADOR",filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align : "center"},
      {dataField: "tipo_proyecto", text: "TIPO PROYECTO", filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white", width:250}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "expediente", text: "EXPEDIENTE",filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align : "center"},
      {dataField: "comision", text: "COMISION",filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align : "center"},

    ]
    const columns5 = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:250}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "asesor", text: "ASESOR",filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align : "center"},
      {dataField: "tipo_proyecto", text: "TIPO PROYECTOS",filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align : "center"},
      {dataField: "expediente", text: "EXPEDIENTE", filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white", width:250}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "comision", text: "COMISION",filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align : "center"},
      {dataField: "fecha_creacion", text: "FECHA DE CREACION",filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align : "center"},
    ]

    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.comisiones.length
    };
    const options2 = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.tproyectos.length
    };
    const options3 = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.asesores.length
    };
    const options4 = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.asignaciones.length
    };
    const options5 = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.proyectos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.comisiones }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );

    const contentTable2 = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.tproyectos }
              columns={ columns2 }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const contentTable3 = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.asesores }
              columns={ columns3 }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const contentTable4 = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.asignaciones }
              columns={ columns4 }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const contentTable5 = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.proyectos  }
              columns={ columns5 }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Administrador de Producción</h4>
              <Button style={{width: 250 ,marginTop:-15, marginBottom: 20}} bsStyle="success" fill pullRight type="submit" onClick={() => { this.redirigirReporte() }}>VER REPORTE</Button>
             
              <Tabs>
                <TabList>
                  <Tab ><p style={{fontSize:14, fontWeight:"bold"}} onClick={() => this.handleTabChange(1)}> COMISIONES</p></Tab>  
                  <Tab ><p style={{fontSize:14, fontWeight:"bold"}} onClick={() => this.handleTabChange(2)}> TIPO DE PROYECTOS</p></Tab>             
                  <Tab ><p style={{fontSize:14, fontWeight:"bold"}} onClick={() => this.handleTabChange(3)}> ASESORES</p></Tab>
                  <Tab ><p style={{fontSize:14, fontWeight:"bold"}} onClick={() => this.handleTabChange(4)}> ASIGNACIONES</p></Tab>
                  <Tab ><p style={{fontSize:14, fontWeight:"bold"}} onClick={() => this.handleTabChange(5)}> PROYECTOS</p></Tab>     
                </TabList>
                <TabPanel >
                <p style={{fontWeight: 50,fontSize:20, marginTop: 18, marginBottom: 25}}>Lista de Comisiones</p>
                {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
                </TabPanel>
                <TabPanel>
                <p style={{fontWeight: 50,fontSize:20, marginTop: 18, marginBottom: 25}}>Lista de Tipos de Proyectos</p>
                {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options2)
                        }
                      >
                        { contentTable2 }
                      </PaginationProvider>
                 )}
                </TabPanel>
                <TabPanel>
                <p style={{fontWeight: 50,fontSize:20, marginTop: 18, marginBottom: 25}}>Lista de Asesores</p>
                {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options3)
                        }
                      >
                        { contentTable3 }
                      </PaginationProvider>
                 )}
                </TabPanel>
                <TabPanel>
                <p style={{fontWeight: 50,fontSize:20, marginTop: 18, marginBottom: 25}}>Lista de Asignaciones</p>
                {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <div>
                        <div className="row">
                        <div>
                          <div className="col-md-4" style={{marginBottom: 18}}>
                              <ControlLabel>FECHA DE INICIO</ControlLabel>
                              <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaIni}  />
                          </div>
                          <div className="col-md-4" style={{marginBottom: 18}}>
                              <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                              <FormControl type="date" value={this.state.fechafin}  onChange= {this.handleFechaFin} min={this.state.fechaini}/>
                          </div>
                        </div> 
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel></ControlLabel><br></br>
                              <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.listAsignacion() }}>Buscar Asignaciones</Button>
                          </div>
                          </div>
                      <PaginationProvider
                        pagination={
                          paginationFactory(options4)
                        }
                      >
                        { contentTable4 }
                      </PaginationProvider>
                      
                      </div>
                 )}
                </TabPanel>

                <TabPanel>
                <p style={{fontWeight: 50,fontSize:20, marginTop: 18, marginBottom: 25}}>Lista de Proyectos</p>
                {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <div>
                        <div className="row">
                        <div>
                          <div className="col-md-4" style={{marginBottom: 18}}>
                              <ControlLabel>FECHA DE INICIO</ControlLabel>
                              <FormControl type="date" value={this.state.fechaini2} onChange= {this.handleFechaIni2}  />
                          </div>
                          <div className="col-md-4" style={{marginBottom: 18}}>
                              <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                              <FormControl type="date" value={this.state.fechafin2}  onChange= {this.handleFechaFin2} min={this.state.fechaini}/>
                          </div>
                        </div> 
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel></ControlLabel><br></br>
                              <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.listProyecto() }}>Buscar Proyectos</Button>
                          </div>
                          </div>
                      <PaginationProvider
                        pagination={
                          paginationFactory(options5)
                        }
                      >
                        { contentTable5 }
                      </PaginationProvider>
                      
                      </div>
                      
                 )}
                </TabPanel>

              </Tabs>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Produccion;