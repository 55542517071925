import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Badge  } from "react-bootstrap";
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';

import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import Select from 'react-select';
import Autocomplete from "react-autocomplete-select";
import moment from 'moment'

import axios from 'axios';
import { tesoreria } from 'store/Apis'

import Calendar from "components/Calendar/Calendar.jsx";

import { pdf } from "@react-pdf/renderer";
import { reporteComprobantes } from "./ReporteComprobantes";
import { reporteContable } from "./ReporteContable";
import { reporteLibro } from "./ReporteLibro";
import { reporteFondo } from "./ReporteFondo";
import { reporteGeneral } from "./ReporteGeneral";
import { reporteCuadro } from "./ReporteCuadro";
import { reporteProyeccion } from "./ReporteProyeccion";
import { reporteLiquidacion } from "./ReporteLiquidacion";

import { Element, Events, animateScroll as scroll, scroller } from 'react-scroll'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Reportes extends Component {

  inputRef = React.createRef();
  inputRef2 = React.createRef();
  inputRef3 = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      path: 'cajachica',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      selectedOption: "",
      selectedOption2: "",
      selectedOption3: "",
      selectedOption4: "",
      selectedOption5: "",
      selectedOption6: "",
      selectedOption7: "",
      correlativo1: "",
      correlativo2: "",
      correlativo3: "",
      correlativo4: "",
      correlativo5: 0,
      correlativo6: 0,
      periodo: "",
      periodo2: "",
      periodo3: "",
      periodo4: "",
      periodo5: "",
      periodo6: "",
      periodo7: "",
      encargado: "",
      encargadosub: "",
      jefe: "",
      jefesub: "",
      tesorero: "",
      tesorerosub: "",
      director: "",
      directorsub: "",
      mes: "",
      mes2: "",
      mes3: "",
      mes4: "",
      mes5: "",
      mes6: "",
      years: [],
      meses: [],
      numero1: "",
      numero2: "12",
      numero3: "",
      campos1: [],
      campos2: [],
      documentos: [],
      nombres: [],
      conceptos: [],
      clasificadores: [],
      metas: [],
      base: "",
      marcador: 0,
      isLoading: true,
      isLoading2: true,
      nidorden: '',
      orden: '',
      numeroinicial: '',
      numerofinal: '',
      fechainicial: moment(moment().startOf('week').toDate()).format('YYYY-MM-DD'),
      fechafinal: moment(moment().endOf('week').toDate()).format('YYYY-MM-DD'),
      fechainicial2: moment(moment().startOf('week').toDate()).format('YYYY-MM-DD'),
      fechafinal2: moment(moment().endOf('week').toDate()).format('YYYY-MM-DD'),
      documentochange: "",
      nombrechange: "",
      conceptochange: "",
      arrayMes: [
        {"nid": "01", "descripcion": "ENERO"},
        {"nid": "02", "descripcion": "FEBRERO"},
        {"nid": "03", "descripcion": "MARZO"},
        {"nid": "04", "descripcion": "ABRIL"},
        {"nid": "05", "descripcion": "MAYO"},
        {"nid": "06", "descripcion": "JUNIO"},
        {"nid": "07", "descripcion": "JULIO"},
        {"nid": "08", "descripcion": "AGOSTO"},
        {"nid": "09", "descripcion": "SETIEMBRE"},
        {"nid": "10", "descripcion": "OCTUBRE"},
        {"nid": "11", "descripcion": "NOVIEMBRE"},
        {"nid": "12", "descripcion": "DICIEMBRE"},
      ]
    }

    this.scrollToTop = this.scrollToTop.bind(this);

    this.handlePeriodo = this.handlePeriodo.bind(this);
    this.handlePeriodo2 = this.handlePeriodo2.bind(this);
    this.handlePeriodo3 = this.handlePeriodo3.bind(this);
    this.handlePeriodo4 = this.handlePeriodo4.bind(this);
    this.handlePeriodo5 = this.handlePeriodo5.bind(this);
    this.handlePeriodo6 = this.handlePeriodo6.bind(this);
    this.handlePeriodo7 = this.handlePeriodo7.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleMes = this.handleMes.bind(this);
    this.handleMes2 = this.handleMes2.bind(this);
    this.handleMes3 = this.handleMes3.bind(this);
    this.handleMes4 = this.handleMes4.bind(this);
    this.handleMes5 = this.handleMes5.bind(this);
    this.handleMes6 = this.handleMes6.bind(this);
    this.handleEncargado = this.handleEncargado.bind(this);
    this.handleJefe = this.handleJefe.bind(this);
    this.handleTesorero = this.handleTesorero.bind(this);
    this.handleDirector = this.handleDirector.bind(this);
    this.handleNumero1 = this.handleNumero1.bind(this);
    this.handleNumero2 = this.handleNumero2.bind(this);
    this.handleNumero3 = this.handleNumero3.bind(this);
    this.handleNumeroinicial = this.handleNumeroinicial.bind(this);
    this.handleNumerofinal = this.handleNumerofinal.bind(this);
    this.handleFechainicial = this.handleFechainicial.bind(this);
    this.handleFechafinal = this.handleFechafinal.bind(this);
    this.handleFechainicial2 = this.handleFechainicial2.bind(this);
    this.handleFechafinal2 = this.handleFechafinal2.bind(this);
    this.handleCorrelativo1 = this.handleCorrelativo1.bind(this);
    this.handleCorrelativo2 = this.handleCorrelativo2.bind(this);
    this.handleCorrelativo3 = this.handleCorrelativo3.bind(this);
    this.handleCorrelativo4 = this.handleCorrelativo4.bind(this);
    this.handleCorrelativo5 = this.handleCorrelativo5.bind(this);
    this.handleCorrelativo6 = this.handleCorrelativo6.bind(this);
  }

  handleNumeroinicial(event) {
    this.setState({numeroinicial: event.target.value});
  }

  handleNumerofinal(event) {
    this.setState({numerofinal: event.target.value});
  }

  handleFechainicial(event) {
    this.setState({fechainicial: event.target.value});
  }

  handleFechafinal(event) {
    this.setState({fechafinal: event.target.value});
  }

  handleFechainicial2(event) {
    this.setState({fechainicial2: event.target.value});
  }

  handleFechafinal2(event) {
    this.setState({fechafinal2: event.target.value});
  }

  handleNumero1(event) {
    this.setState({numero1: event.target.value});
  }

  handleNumero2(event) {
    this.setState({numero2: event.target.value});
  }

  handleNumero3(event) {
    this.setState({numero3: event.target.value});
  }

  handleEncargado(event) {
    this.setState({encargado: event.target.value});
  }

  handleJefe(event) {
    this.setState({jefe: event.target.value});
  }

  handleTesorero(event) {
    this.setState({tesorero: event.target.value});
  }

  handleDirector(event) {
    this.setState({director: event.target.value});
  }

  handleCorrelativo1(event) {
    this.setState({correlativo1: event.target.value});
  }

  handleCorrelativo2(event) {
    this.setState({correlativo2: event.target.value});
  }

  handleCorrelativo3(event) {
    this.setState({correlativo3: event.target.value});
  }

  handleCorrelativo4(event) {
    this.setState({correlativo4: event.target.value});
  }

  handleCorrelativo5(event) {
    this.setState({correlativo5: event.target.value});
  }

  handleCorrelativo6(event) {
    this.setState({correlativo6: event.target.value});
  }

  handlePeriodo = periodo => {
    this.setState(
    { periodo },
    );
  };

  handlePeriodo2 = periodo2 => {
    this.setState(
    { periodo2 },
    );
  };

  handlePeriodo3 = periodo3 => {
    this.setState(
    { periodo3 },
    );
  };

  handlePeriodo4 = periodo4 => {
    this.setState(
    { periodo4 },
    );
  };

  handlePeriodo5 = periodo5 => {
    this.setState(
    { periodo5 },
    );
  };

  handlePeriodo6 = periodo6 => {
    this.setState(
    { periodo6 },
    );
  };

  handlePeriodo7 = periodo7 => {
    this.setState(
    { periodo7 },
    );
  };

  handleMes = mes => {
    this.setState(
    { mes },
    );
  };

  handleMes2 = mes2 => {
    this.setState(
    { mes2 },
    );
  };

  handleMes3 = mes3 => {
    this.setState(
    { mes3 },
    );
  };

  handleMes4 = mes4 => {
    this.setState(
    { mes4 },
    );
  };

  handleMes5 = mes5 => {
    this.setState(
    { mes5 },
    );
  };

  handleMes6 = mes6 => {
    this.setState(
    { mes6 },
    );
  };

  handleChange = selectedOption => {
    this.setState(
    { selectedOption },
    );
  };

  handleChange2 = selectedOption2 => {
    this.setState(
    { selectedOption2 },
    // () => this.verificador(selectedOption2)
    );
  };

  handleChange3 = selectedOption3 => {
    this.setState(
    { selectedOption3 },
    );
  };

  handleChange4 = selectedOption4 => {
    this.setState(
    { selectedOption4 },
    );
  };

  handleChange5 = selectedOption5 => {
    this.setState(
    { selectedOption5 },
    ); 
  };

  handleChange6 = selectedOption6 => {
    this.setState(
    { selectedOption6 },
    );
  };

  handleChange7 = selectedOption7 => {
    this.setState(
    { selectedOption7 },
    );
  };

  // async verificador(param){
  //   this.setState({nidorden: param.value, orden: param.label})
  // }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              Events.scrollEvent.register('begin', function () {
                console.log("begin", arguments);
              });
          
              Events.scrollEvent.register('end', function () {
                console.log("end", arguments);
              });
              this.generarPeriodos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  scrollTo() {
    scroller.scrollTo('scroll-to-element', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  scrollToWithContainer() {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register('end', () => {
        resolve();
        Events.scrollEvent.remove('end');
      });
      scroller.scrollTo('scroll-container', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });

    });
    goToContainer.then(() =>
      scroller.scrollTo('scroll-container-second-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container'
      }));
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  generarPeriodos(){
    let year =  2000;
    let cont = 26;
    let array = Array.from(new Array(cont),(val, index) => index + year);
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
            this.setState({
              periodo: {value: cont2, label: data}, 
              periodo2: {value: cont2, label: data}, 
              periodo3: {value: cont2, label: data}, 
              periodo4: {value: cont2, label: data},
              periodo5: {value: cont2, label: data},
              periodo6: {value: cont2, label: data},
              periodo7: {value: cont2, label: data}
            })
        }
    })
    this.state.arrayMes.map((data) => {
      cont3++
      lista2.push({
          item: data.cont3,
          value: data.nid,
          label: data.descripcion,
      })
      if(cont3 == (new Date()).getMonth() + 1){
          this.setState({
            mes: {value: data.nid, label: data.descripcion}, 
            mes2: {value: data.nid, label: data.descripcion},
            mes3: {value: data.nid, label: data.descripcion},
            mes4: {value: data.nid, label: data.descripcion},
            mes5: {value: data.nid, label: data.descripcion},
            mes6: {value: data.nid, label: data.descripcion}
          })
      }
    })
    this.setState({years: lista, meses: lista2})
    this.listarPersonas();
  }

  async listarPersonas() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listCodigos?tipo=firmantes')
    .then((res) => {
      let lista = res.data.response;
      if(lista != null){
          lista.map((data) => {
          if(data.nid == 41){
            this.setState({
              encargado: data.nombre,
              encargadosub: data.codigo
            })
          }else if(data.nid == 42){
            this.setState({
              jefe: data.nombre, 
              jefesub: data.codigo
            })
          }else if(data.nid == 43){
            this.setState({
              tesorero: data.nombre,
              tesorerosub: data.codigo
            })
          }else{
            this.setState({
              director: data.nombre,
              directorsub: data.codigo
            })
          }
        })
        this.listarCampos();
      }else{
        this.setState({isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  } 

  async listarCampos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listCampos')
    .then((res) => {
      let campo1 = [];
      let campo2 = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          if(data.flg_tipo == 1){
            campo1.push({ 
                value: data.nid,
                label: data.ctxt_descripcion,
            })
          }else{
            campo2.push({ 
              value: data.nid,
              label: data.ctxt_descripcion,
            })
          }
        })
        this.setState({
          campos1: campo1, 
          campos2: campo2, 
          selectedOption: {value: campo1[0].value, label: campo1[0].label},
          selectedOption2: {value: campo2[1].value, label: campo2[1].label}
        })
        this.listarBeneficiarios();
      }else{
        this.setState({campos1: [], campos2: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  } 

  async listarBeneficiarios() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'beneficiarios/listBeneficiarios')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.cnum_documento + ' (' + data.ctxt_descripcion + ')', 
              tipo: data.nid_tipo,
              documento: data.cnum_documento,  
              clase: data.ctxt_descripcion   
            })
        })
        this.setState({nombres: lista})
        this.listarConceptos()
      }else{
        this.setState({nombres: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarConceptos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'beneficiarios/listConceptos')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2.length != 0){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_descripcion,       
            })
        })
        this.setState({conceptos: lista})
        this.listarDocumentos()
      }else{
        this.setState({conceptos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarDocumentos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'beneficiarios/listDocumentos')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2.length != 0){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.cnum_documento,       
            })
        })
        this.setState({documentos: lista})
        this.listarClasificadores()
      }else{
        this.setState({documentos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
  
  async listarClasificadores() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listClasificadores?admin=0')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista.push(
          { value: '0',
            label: 'NO SELECCIONADO',       
          })
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_descripcion,       
            })
        })
        this.setState({clasificadores: lista, selectedOption6: {value: lista[0].value, label: lista[0].label}})
        this.listarMetas()
      }else{
        this.setState({clasificadores: []})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarMetas() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listCodigos?tipo=meta')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista.push(
          { value: '0',
            label: 'NO SELECCIONADO',       
          })
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.nombre,       
            })
        })
        this.setState({metas: lista, selectedOption7: {value: lista[0].value, label: lista[0].label}, isLoading: false})
        this.listarComprobantes()
      }else{
        this.setState({metas: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarComprobantes() {
    this.setState({isLoading2: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'comprobantes/listMovimientos?tipo=2&fechaini=' + this.state.fechainicial2 + '&fechafin=' + this.state.fechafinal2)
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      var total = 0;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { 
              item: data.item,
              nidmovimiento: data.nid,
              numero: data.cnum_numero, 
              cheque: data.ctxt_cheque_carta,
              registro: data.cnum_siaf,
              orden: data.cnum_orden,
              documento: data.cnum_documento,
              nombre: data.ctxt_nombre,
              concepto: data.ctxt_concepto,
              liquido: data.cnum_liquido != null ? 'S/.    ' + data.cnum_liquido.toFixed(2) : 'S/. 0.00', 
              fecha: Calendar.calendario12(data.dfec_ingreso),
              fecha2: data.dfec_ingreso,
              activo: data.item == 1 ? '1' : '0', 
              documentox: data.documento
          })
          // total = total + data.cnum_liquido
          total += data.cnum_liquido
        })
        this.generarReporte8(lista, total != 0 ? 'S/.    ' + total.toFixed(2) : 'S/.    0.00');
      }else{
        this.setState({isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading2: false})
      }
    });
  }

  async listarMovimientos() {
      this.setState({isLoading2: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${tesoreria}` + 'reportes/generarReporteContable?anio=' + this.state.periodo.label)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
            if(lista2.reporte.length != 0){
                lista2.reporte.map((data) => {
                    lista.push(
                      { 
                        item: data.item,
                        numero: data.numero,
                        nombre: data.nombre, 
                        concepto: data.concepto,
                        importe: data.importe != null ? 'S/.    ' + data.importe.toFixed(2) : 'S/.    0.00',
                    })
                  })
                  this.generarReporte(lista, lista2.sumatoria[0].total != null ? 'S/.    ' + lista2.sumatoria[0].total.toFixed(2) : 'S/.    0.00');
            }else{
              this.generarReporte(lista, "S/.    0.00");
            }
        }else{
          this.setState({isLoading2: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading2: false})
        }
      });
  }

  async listarMovimientos2() {
    this.setState({isLoading2: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'reportes/generarReportePagos?mes=' + this.state.mes.value + '&anio=' + this.state.periodo2.label)
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
          if(lista2.reporte.length != 0){
              lista2.reporte.map((data) => {
                  lista.push(
                    { 
                      item: data.item,
                      numero: data.numero,
                      nombre: data.nombre, 
                      concepto: data.concepto,
                      importe: data.monto != null ? 'S/.    ' + data.monto.toFixed(2) : 'S/.    0.00',
                      clasificador: data.clasificador,
                      documento: data.documento,
                      fecha: data.fecha,
                      meta: data.meta
                  })
                })
                let saldo = lista2.sumatoria[0].saldo != null ? 'S/.    ' + lista2.sumatoria[0].saldo.toFixed(2) : 'S/.    0.00'
                let saldo2 = lista2.sumatoria[0].saldo2 != null ? 'S/.    ' + lista2.sumatoria[0].saldo2.toFixed(2) : 'S/.    0.00'
                let gastado = lista2.sumatoria[0].gastado != null ? 'S/.    ' + lista2.sumatoria[0].gastado.toFixed(2) : 'S/.    0.00'
                let gastado2 = lista2.sumatoria[0].gastado2 != null ? 'S/.    ' + lista2.sumatoria[0].gastado2.toFixed(2) : 'S/.    0.00'
                this.generarReporte2(lista, saldo, saldo2, gastado, gastado2);
          }else{
            this.generarReporte2(lista, "S/.    0.00", "S/.    0.00", "S/.    0.00", "S/.    0.00");
          }
      }else{
        this.setState({isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading2: false})
      }
    });
  }

  async listarMovimientos3() {
    let numero1 = 0;
    let numero2 = 0;
    if(this.state.correlativo5 != 0 && this.state.correlativo6 != 0){
       numero1 = this.state.correlativo5;
       numero2 = this.state.correlativo6;
    }
    this.setState({isLoading2: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'reportes/generarReportePagosxClasificador?mes=' + this.state.mes2.value + 
    '&anio=' + this.state.periodo3.label + 
    '&numini=' + numero1 + 
    '&numfin=' + numero2)
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
          if(lista2.cabeceras.length != 0){
                let meta1 = lista2.sumatoria[0].meta1 != null ? 'S/.    ' + lista2.sumatoria[0].meta1.toFixed(2) : 'S/.    0.00'
                let meta2 = lista2.sumatoria[0].meta2 != null ? 'S/.    ' + lista2.sumatoria[0].meta2.toFixed(2) : 'S/.    0.00'
                let meta3 = lista2.sumatoria[0].meta3 != null ? 'S/.    ' + lista2.sumatoria[0].meta3.toFixed(2) : 'S/.    0.00'
                let total = lista2.sumatoria[0].total != null ? 'S/.    ' + lista2.sumatoria[0].total.toFixed(2) : 'S/.    0.00'
                this.generarReporte3(lista2.cabeceras, numero1, numero2, meta1, meta2, meta3, total);
          }else{
            this.generarReporte3(lista, numero1, numero2, "S/.    0.00", "S/.    0.00", "S/.    0.00", "S/.    0.00");
          }
      }else{
        this.setState({isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading2: false})
      }
    });
  }

  async listarMovimientos4() {
    let documento = ""
    let nombre = ""
    let concepto = ""
    if(this.state.selectedOption3.label == undefined){
      if(this.state.documentochange.label == undefined){
        documento = 0
      }else{
        documento = this.state.documentochange.label
      }
    }else{
      documento = this.state.selectedOption3.label
    }
    if(this.state.selectedOption4.clase == undefined){
      if(this.state.nombrechange.clase == undefined){
        nombre = 0
      }else{
        nombre = this.state.nombrechange.clase
      }
    }else{
      nombre = this.state.selectedOption4.clase
    }
    if(this.state.selectedOption5.label == undefined){
      if(this.state.conceptochange.label == undefined){
        concepto = 0
      }else{
        concepto = this.state.conceptochange.label
      }
    }else{
      concepto = this.state.selectedOption5.label
    }
    if(this.state.fechainicial != "" && this.state.fechafinal != ""){
      this.setState({isLoading2: true})
      let fromData = new FormData();
      fromData.append('numeroinicial', this.state.numeroinicial == '' || this.state.numeroinicial == 0 ? 0 : this.state.numeroinicial);
      fromData.append('numerofinal', this.state.numerofinal == '' || this.state.numerofinal == 0 ? 0 : this.state.numerofinal);
      fromData.append('fechainicial', this.state.fechainicial);
      fromData.append('fechafinal', this.state.fechafinal);
      fromData.append('documento',  documento);
      fromData.append('nombre', nombre);
      fromData.append('concepto', concepto);
      fromData.append('gasto', this.state.selectedOption6.value);
      fromData.append('meta', this.state.selectedOption7.value);
      fromData.append('orden', this.state.selectedOption2.value);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'reportes/generarReportexCampos', fromData)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
            if(lista2.reporte.length != 0){
                lista2.reporte.map((data) => {
                    lista.push(
                      { 
                        item: data.item,
                        numero: data.numero,
                        nombre: data.nombre, 
                        concepto: data.concepto,
                        importe: data.monto != null ? 'S/.    ' + data.monto.toFixed(2) : 'S/.    0.00',
                        clasificador: data.clasificador,
                        documento: data.documento,
                        fecha: data.fecha,
                        meta: data.meta
                    })
                  })
                  this.generarReporte4(lista, lista2.sumatoria[0].total != null ? 'S/.    ' + lista2.sumatoria[0].total.toFixed(2) : 'S/.    0.00');
            }else{
              this.generarReporte4(lista, "S/.    0.00");
            }
        }else{
          this.setState({isLoading2: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading2: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar los campos de fechas!", "pe-7s-info")
    }
  }

  segmentador(data){
    let options = []
    let anios = Object.keys(data)
    anios.map((item) => {
      let meses = Object.keys(data[item][0])
      this.state.arrayMes.map((item3) => {
        meses.map((item2) => {
          if(item2.toUpperCase() == item3.descripcion){
            options.push(
              { 
                anio: item,
                mes: item2,
                monto: data[item][0][item2]
            })
          }
        })
      })
    })
    return options
  }

  async listarMovimientos5() {
    this.setState({isLoading2: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'reportes/generarReporteSumaCuadroGastos?anioini=' + this.state.periodo4.label + '&aniofin=' + this.state.periodo5.label)
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista.push(
          { 
            item: lista2.atenciones.data.item,
            nombre: lista2.atenciones.data.nombre,
            reporte: this.segmentador(lista2.atenciones.reporte)
        })
        lista.push(
          { 
            item: lista2.cafeteria.data.item,
            nombre: lista2.cafeteria.data.nombre,
            reporte: this.segmentador(lista2.cafeteria.reporte)
        })
        lista.push(
          { 
            item: lista2.movilidad.data.item,
            nombre: lista2.movilidad.data.nombre,
            reporte: this.segmentador(lista2.movilidad.reporte)
        })
        this.generarReporte5(lista);
      }else{
        this.setState({isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading2: false})
      }
    });
  }

  async listarMovimientos6() {
    if(this.state.numero1 != "" && this.state.numero2 != "" && this.state.correlativo1 != "" && this.state.correlativo2 != ""){
      this.setState({isLoading2: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${tesoreria}` + 'reportes/generarReporteFondoPagos?anio=' + this.state.periodo6.label + 
      '&mesini=' + this.state.mes3.value + 
      '&mesfin=' + this.state.mes5.value + 
      '&numero=' + this.state.numero2 + 
      '&inicio=' + this.state.correlativo1 + 
      '&finale=' + this.state.correlativo2)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        let lista3 = lista2.sumatoria;
        if(lista2 != null){
          lista2.gastos.map((data) => {
              lista.push(
                { 
                  item: data.item,
                  codigo: data.reporte[0].codigo,
                  descripcion: data.reporte[0].descripcion,
                  meta1: data.reporte[0].meta1,
                  meta1x: data.reporte[0].meta1x,
                  meta2: data.reporte[0].meta2,
                  meta2x: data.reporte[0].meta2x,
                  total: data.reporte[0].total,
                  totalx: data.reporte[0].totalx
              })
            })
            this.generarReporte6(lista, lista3);
        }else{
          this.setState({isLoading2: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading2: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar los campos de tipo número!", "pe-7s-info")
    }
  }

  async listarMovimientos7() {
    if(this.state.correlativo3 != "" && this.state.correlativo4 != "" && this.state.encargado != "" && this.state.jefe != "" && this.state.tesorero != "" && this.state.director != "" && this.state.numero3 != ""){
      this.setState({isLoading2: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${tesoreria}` + 'reportes/generarReporteLiquidacion?anio=' + this.state.periodo7.label + 
      '&mesini=' + this.state.mes4.value + 
      '&mesfin=' + this.state.mes6.value + 
      '&inicio=' + this.state.correlativo3 + 
      '&finale=' + this.state.correlativo4)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        let lista3 = lista2.sumatoria;
        if(lista2 != null){
          lista2.gastos.map((data) => {
              lista.push(
                { 
                  item: data.item,
                  codigo: data.reporte[0].codigo,
                  descripcion: data.reporte[0].descripcion,
                  meta1: data.reporte[0].meta1,
                  meta2: data.reporte[0].meta2,
                  meta3: data.reporte[0].meta3,
                  total: data.reporte[0].total,
              })
            })
            this.generarReporte7(lista, lista3);
        }else{
          this.setState({isLoading2: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading2: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar los campos solicitados!", "pe-7s-info")
    }
  }

  async generarReporte(movimientos, total){
    var row = [];
    row.push({  
        total: total,
        anio: this.state.periodo.label
    })
    const blob = pdf(reporteContable(movimientos, row)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data, marcador: 0, isLoading2: false});
          }
        }
        return result;
    }); 
  }
  
  async generarReporte2(movimientos, saldo, saldo2, gastado, gastado2){
    var row = [];
    row.push({  
        saldo: saldo,
        saldo2: saldo2,
        gastado: gastado,
        gastado2: gastado2,
        anio: this.state.periodo2.label,
        mes: this.state.mes.label
    })
    const blob = pdf(reporteLibro(movimientos, row)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data, marcador: 1, isLoading2: false});
          }
        }
        return result;
    }); 
  }

  async generarReporte3(cabeceras, numero1, numero2, meta1, meta2, meta3, total){
    var row = [];
    row.push({ 
        num1: numero1,
        num2: numero2,
        meta1: meta1,
        meta2: meta2,
        meta3: meta3,
        meta3: meta3,
        total: total,
        anio: this.state.periodo2.label,
        mes: this.state.mes.label
    })
    const blob = pdf(reporteFondo(cabeceras, row)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data, marcador: 1, isLoading2: false});
          }
        }
        return result;
    }); 
  }

  async generarReporte4(movimientos, total){
    var row = [];
    row.push({  
      total: total
    })
    const blob = pdf(reporteGeneral(movimientos, row)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data, marcador: 1, isLoading2: false});
          }
        }
        return result;
    }); 
  }

  async generarReporte5(movimientos){
    var row = [];
    row.push({  
      anioini: this.state.periodo4.label,
      aniofin: this.state.periodo5.label 
    })
    const blob = pdf(reporteCuadro(movimientos, row)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data, marcador: 1, isLoading2: false});
          }
        }
        return result;
    }); 
  }

  async generarReporte6(movimientos, sumatoria){
    var row = [];
    row.push({  
      anio: this.state.periodo6.label,
      mesini: Calendar.calendario13(this.state.mes3.value),
      mesfin: Calendar.calendario13(this.state.mes5.value),
      numero1: this.state.numero1,
      numero2: this.state.numero2
    })
    const blob = pdf(reporteProyeccion(movimientos, sumatoria, row)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data, marcador: 1, isLoading2: false});
          }
        }
        return result;
    }); 
  }

  async generarReporte7(movimientos, sumatoria){
    var row = [];
    row.push({  
      encargado: this.state.encargado,
      encargadosub: this.state.encargadosub,
      jefe: this.state.jefe,
      jefesub: this.state.jefesub,
      tesorero: this.state.tesorero,
      tesorerosub: this.state.tesorerosub,
      director: this.state.director,
      directorsub: this.state.directorsub,
      numero: this.state.numero3,
      anio: this.state.periodo7.label,
      mesini: Calendar.calendario13(this.state.mes4.value),
      mesfin: Calendar.calendario13(this.state.mes6.value),
    })
    const blob = pdf(reporteLiquidacion(movimientos, sumatoria, row)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data, marcador: 1, isLoading2: false});
          }
        }
        return result;
    }); 
  }

  async generarReporte8(movimientos, total){
    var row = [];
    console.log(total)
    row.push({  
        total: total,
        fechainicial: Calendar.calendario12(this.state.fechainicial2),
        fechafinal: Calendar.calendario12(this.state.fechafinal2)
    })
    const blob = pdf(reporteComprobantes(movimientos, row)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data, marcador: 0, isLoading2: false});
          }
        }
        return result;
    }); 
  }

  async generarSelect(tipo, param){
    if(tipo == 1){
      this.state.documentos.map((data) => {
        if(data.label == param){
          this.setState({
            selectedOption3: {
              value: data.value, 
              label: data.label
            }
          })
        }
      })
    }else if(tipo == 2){
      this.state.nombres.map((data) => {
        if(data.label == param){
          this.setState({
            selectedOption4: {
              value: data.value, 
              label: data.label, 
              tipo: data.tipo, 
              documento: data.documento,
              clase: data.clase
            }
          })
        }
      })
    }else{
      this.state.conceptos.map((data) => {
        if(data.label == param){
          this.setState({
            selectedOption5: {
              value: data.value, 
              label: data.label
            }
          })
        }
      })
    }
  }

  async generarChange(tipo, param){
    if(tipo == 1){
      if(param == ''){
        this.setState({selectedOption3: '', documentochange: ''})
      }else{
        this.setState({documentochange: {value: '0', label: param}})
      }
    }else if(tipo == 2){
      if(param == ''){
        this.setState({selectedOption4: '', nombrechange: ''})
      }else{
        this.setState({nombrechange: {value: '0', label: param, tipo: '', documento: '', clase: param}})
      }
    }else{
      if(param == ''){
        this.setState({selectedOption5: '', conceptochange: ''})
      }else{
        this.setState({conceptochange: {value: '0', label: param}})
      }
    }
  }

  render() {
    const {selectedOption2, selectedOption6, selectedOption7} = this.state
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={4}>
               {this.state.isLoading ? (
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading}
                  />
                  ) : (
                  <Card
                    title="Lista de Reportes"
                    content={
                    <div>
                    <Element 
                      name="scroll" 
                      className="element" 
                      id="containerElement" style={{
                      position: 'relative',
                      height: '1195px',
                      overflow: 'scroll',
                      paddingRight: '15px',
                      paddingLeft: '15px',
                      marginRight: '-15px',
                      marginLeft: '-15px'
                    }}>
                    <div style={{borderRadius: 5, backgroundColor: "white", paddingTop: 1, paddingBottom: 5}}>
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Comprobantes de Pago</h4>
                      <div className="row">
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>FECHA INICIAL</ControlLabel>
                              <FormControl type="date" value={this.state.fechainicial2} onChange= {this.handleFechainicial2}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>FECHA FINAL</ControlLabel>
                              <FormControl type="date" value={this.state.fechafinal2} onChange= {this.handleFechafinal2}/>
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <Button style={{width: 250, marginTop: 5, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.listarComprobantes() }}>Generar Reporte</Button>
                          </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div style={{borderRadius: 5, backgroundColor: "white", paddingTop: 1, paddingBottom: 5}}>
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Registro Contable Caja Chica</h4>
                      <div className="row">
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>PERIODO</ControlLabel>
                              <Select value={this.state.periodo} onChange={this.handlePeriodo} options={this.state.years}/>
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <Button style={{width: 250, marginTop: 5, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.listarMovimientos() }}>Generar Registro</Button>
                          </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div style={{borderRadius: 5, backgroundColor: "white", paddingTop: 1, paddingBottom: 5}}>
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Libro Auxiliar para Pagos en Efectivo</h4>
                      <div className="row">
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>PERIODO</ControlLabel>
                              <Select value={this.state.periodo2} onChange={this.handlePeriodo2} options={this.state.years}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>MES</ControlLabel>
                              <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses}/>
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <Button style={{width: 250, marginTop: 5, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.listarMovimientos2() }}>Generar Libro</Button>
                          </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div style={{borderRadius: 5, backgroundColor: "white", paddingTop: 1, paddingBottom: 5}}>
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Fondos para Pagos en Efectivo</h4>
                      <div className="row">
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>PERIODO</ControlLabel>
                              <Select value={this.state.periodo3} onChange={this.handlePeriodo3} options={this.state.years}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>MES</ControlLabel>
                              <Select value={this.state.mes2} onChange={this.handleMes2} options={this.state.meses}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>CORRELATIVO INICIAL</ControlLabel>
                              <FormControl type="number" value={this.state.correlativo5} onChange= {this.handleCorrelativo5}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>CORRELATIVO FINAL</ControlLabel>
                              <FormControl type="number" value={this.state.correlativo6} onChange= {this.handleCorrelativo6}/>
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <Button style={{width: 250, marginTop: 5, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.listarMovimientos3() }}>Generar Fondo</Button>
                          </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div style={{borderRadius: 5, backgroundColor: "white", paddingTop: 1, paddingBottom: 5}}>
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Cuadro de Gastos Mensuales</h4>
                      <div className="row">
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>PERIODO INICIO</ControlLabel>
                              <Select value={this.state.periodo4} onChange={this.handlePeriodo4} options={this.state.years}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>PERIODO FINAL</ControlLabel>
                              <Select value={this.state.periodo5} onChange={this.handlePeriodo5} options={this.state.years}/>
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <Button style={{width: 250, marginTop: 5, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.listarMovimientos5() }}>Generar Cuadro</Button>
                          </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div style={{borderRadius: 5, backgroundColor: "white", paddingTop: 1, paddingBottom: 5}}>
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Proyección para Pagos en Efectivo</h4>
                      <div className="row">
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>PERIODO</ControlLabel>
                              <Select value={this.state.periodo6} onChange={this.handlePeriodo6} options={this.state.years}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>NÚMERO DE REPORTE</ControlLabel>
                              <FormControl type="number" value={this.state.numero1} onChange= {this.handleNumero1}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>MES INICIAL</ControlLabel>
                              <Select value={this.state.mes3} onChange={this.handleMes3} options={this.state.meses}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>MES FINAL</ControlLabel>
                              <Select value={this.state.mes5} onChange={this.handleMes5} options={this.state.meses}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>CORRELATIVO INICIAL</ControlLabel>
                              <FormControl type="number" value={this.state.correlativo1} onChange= {this.handleCorrelativo1}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>CORRELATIVO FINAL</ControlLabel>
                              <FormControl type="number" value={this.state.correlativo2} onChange= {this.handleCorrelativo2}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>NÚMERO DE PROYECCIÓN</ControlLabel>
                              <FormControl type="number" value={this.state.numero2} onChange= {this.handleNumero2}/>
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <Button style={{width: 250, marginTop: 5, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.listarMovimientos6() }}>Generar Proyección</Button>
                          </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div style={{borderRadius: 5, backgroundColor: "white", paddingTop: 1, paddingBottom: 5}}>
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Liquidación para Pagos en Efectivo</h4>
                      <div className="row">
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>PERIODO</ControlLabel>
                              <Select value={this.state.periodo7} onChange={this.handlePeriodo7} options={this.state.years}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>NÚMERO DE REPORTE</ControlLabel>
                              <FormControl type="number" value={this.state.numero3} onChange= {this.handleNumero3}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>MES INICIAL</ControlLabel>
                              <Select value={this.state.mes4} onChange={this.handleMes4} options={this.state.meses}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>MES FINAL</ControlLabel>
                              <Select value={this.state.mes6} onChange={this.handleMes6} options={this.state.meses}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>CORRELATIVO INICIAL</ControlLabel>
                              <FormControl type="number" value={this.state.correlativo3} onChange= {this.handleCorrelativo3}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>CORRELATIVO FINAL</ControlLabel>
                              <FormControl type="number" value={this.state.correlativo4} onChange= {this.handleCorrelativo4}/>
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>ENCARGADO</ControlLabel>
                              <FormControl type="text" value={this.state.encargado} onChange= {this.handleEncargado}/>
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>JEFE DE ÁREA</ControlLabel>
                              <FormControl type="text" value={this.state.jefe} onChange= {this.handleJefe}/>
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>TESORERO</ControlLabel>
                              <FormControl type="text" value={this.state.tesorero} onChange= {this.handleTesorero}/>
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>DIRECTOR/A GENERAL</ControlLabel>
                              <FormControl type="text" value={this.state.director} onChange= {this.handleDirector}/>
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <Button style={{width: 250, marginTop: 5, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.listarMovimientos7() }}>Generar Liquidación</Button>
                          </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div style={{borderRadius: 5, backgroundColor: "white", paddingTop: 1, paddingBottom: 5}}>
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Reporte General por Campos</h4>
                      <div className="row">
                          {/* <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>SELECCIONE EL CAMPO</ControlLabel>
                              <Select value={selectedOption} onChange={this.handleChange} options={this.state.campos1}/>
                          </div> */}
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>NÚMERO INICIAL</ControlLabel>
                              <FormControl type="number" value={this.state.numeroinicial} onChange= {this.handleNumeroinicial}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>NÚMERO FINAL</ControlLabel>
                              <FormControl type="number" value={this.state.numerofinal} onChange= {this.handleNumerofinal}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>FECHA INICIAL</ControlLabel>
                              <FormControl type="date" value={this.state.fechainicial} onChange= {this.handleFechainicial}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>FECHA FINAL</ControlLabel>
                              <FormControl type="date" value={this.state.fechafinal} onChange= {this.handleFechafinal}/>
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>DOCUMENTO DNI / RUC</ControlLabel>
                              <Autocomplete 
                                inputRef={this.inputRef3}
                                searchPattern={'containsString'}
                                selectOnBlur = {false}
                                placeholder = "Buscar documento"
                                maxOptionsLimit = {10} 
                                searchEnabled = {true}
                                getItemValue={(item)=>{ return item.label }} 
                                onChange = {
                                    changedValue=>{ 
                                    this.generarChange(1, changedValue)
                                }}
                                onSelect = {
                                    selectedValue => { 
                                    this.generarSelect(1, selectedValue)
                                }}
                                itemsData = {this.state.documentos}
                                globalStyle = ".autocomplete {position: relative; display: inline-block; width: 100%} input {font-size: 14px} body {font-size: 14px; font-family: 'Roboto','Helvetica Neue',Arial,sans-serif; line-height: 1.42857143}"
                              />
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>NOMBRE / PROVEEDOR</ControlLabel>
                              <Autocomplete 
                                inputRef={this.inputRef}
                                searchPattern={'containsString'}
                                selectOnBlur = {false}
                                placeholder = "Buscar nombre / proveedor"
                                maxOptionsLimit = {10} 
                                searchEnabled = {true}
                                getItemValue={(item)=>{ return item.label }} 
                                onChange = {
                                    changedValue=>{ 
                                    this.generarChange(2, changedValue)
                                }}
                                onSelect = {
                                    selectedValue => { 
                                    this.generarSelect(2, selectedValue)
                                }}
                                itemsData = {this.state.nombres}
                                globalStyle = ".autocomplete {position: relative; display: inline-block; width: 100%} input {font-size: 14px} body {font-size: 14px; font-family: 'Roboto','Helvetica Neue',Arial,sans-serif; line-height: 1.42857143}"
                              />
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>CONCEPTO</ControlLabel>
                              <Autocomplete 
                                inputRef={this.inputRef2}
                                searchPattern={'containsString'}
                                selectOnBlur = {false}
                                placeholder = "Buscar concepto"
                                maxOptionsLimit = {10} 
                                searchEnabled = {true}
                                getItemValue={(item)=>{ return item.label }} 
                                onChange = {
                                    changedValue=>{ 
                                    this.generarChange(3, changedValue)
                                }}
                                onSelect = {
                                    selectedValue => { 
                                    this.generarSelect(3, selectedValue)
                                }}
                                itemsData = {this.state.conceptos}
                                globalStyle = ".autocomplete {position: relative; display: inline-block; width: 100%} input {font-size: 14px} body {font-size: 14px; font-family: 'Roboto','Helvetica Neue',Arial,sans-serif; line-height: 1.42857143}"
                              />
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>CLASIFICADOR DE GASTO</ControlLabel>
                              <Select value={selectedOption6} onChange={this.handleChange6} options={this.state.clasificadores}/>
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>META</ControlLabel>
                              <Select value={selectedOption7} onChange={this.handleChange7} options={this.state.metas}/>
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>CAMPO DE ORDEN</ControlLabel>
                              <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.campos2}/>
                              {/* <Badge style={{marginTop: 18}} pill bg="primary">{this.state.orden}</Badge> */}
                          </div>
                      </div>
                      <div className="row">
                          <div className="col-md-12" style={{marginBottom: 18}}>
                              <Button style={{width: 250, marginTop: 5, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.listarMovimientos4() }}>Generar Reporte</Button>
                          </div>
                      </div>
                    </div>
                    </Element>
                    </div>
                    }
                    />
                 )}
            </Col>
            <Col md={8}>
              {this.state.isLoading2 ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading2}
                      />
                    ) : (
                      <Card
                        title="Visor de Reportes"
                        content={
                        <div>
                         {this.state.marcador == 0 ? (
                            <iframe src={this.state.base} style={{height: 1195, width: "100%"}}></iframe>
                          ) : (
                            <iframe src={this.state.base} style={{height: 1195, width: "100%"}}></iframe>
                          )}
                        </div>
                        }
                      />
                 )}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Reportes;