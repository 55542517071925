import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Autocomplete from "react-autocomplete-select";
import Calendar from "components/Calendar/Calendar.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { paramTesoreria, tesoreria } from 'store/Apis'

import { pdf } from "@react-pdf/renderer";
import { reporteCheques } from "./ReporteCheques";
import { NumeroALetras } from "views/efectivo/conversor2";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { saveAs } from 'file-saver';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class GenerarCheque extends Component {

  inputRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      path: 'comprobantes',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      isLoading: true,
      isLoading2: false,
      numero: '',
      nombre: '',
      fecha: '',
      monto: '',
      cheque: '',
      base: '',
      marcador: 0,
      movimiento: '',
      selectedOption: "",
      beneficiarios: [],
    }
    this.movimientos = []

    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);

    this.handleFecha = this.handleFecha.bind(this);
    this.handleMonto = this.handleMonto.bind(this);
    this.handleCheque = this.handleCheque.bind(this);

  }

  handleChange = selectedOption => {
    this.setState(
    { selectedOption },
    );
  };

  handleFecha(event) {
    this.setState({fecha: event.target.value});
  }

  handleMonto(event) {
    this.setState({monto: event.target.value});
  }

  handleCheque(event) {
    this.setState({cheque: event.target.value});
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(localStorage.getItem("movimientocomp") !== null){
        this.setState({movimiento: JSON.parse(localStorage.getItem('movimientocomp')).nidmovimiento,
                       fecha: JSON.parse(localStorage.getItem('movimientocomp')).fecha,
                       monto: JSON.parse(localStorage.getItem('movimientocomp')).liquido,
                       numero: JSON.parse(localStorage.getItem('movimientocomp')).numero,
                       nombre: JSON.parse(localStorage.getItem('movimientocomp')).nombre,
                       cheque: JSON.parse(localStorage.getItem('movimientocomp')).cheque
                     });
        // this.listarBeneficiarios();
        this.obtenerDatos();
    }else{
      this.props.history.push('/comprobantes');
    }
  }

  async obtenerDatos(){
    if(paramTesoreria.beneficiarios[0].data.length != 0){
      this.setState({
        beneficiarios: paramTesoreria.beneficiarios[0].data, 
        conceptos: paramTesoreria.conceptos[0].data, 
        apunta3: true, 
        apunta2: true,
        apunta: true, 
        marcador1: false, 
        marcador2: false, 
        beneficiario: "", 
        documento: "--", 
        concepto: ""
      })
      this.listarCheques()
    }else{
      this.listarBeneficiarios()
    }
  }

  async listarBeneficiarios() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'beneficiarios/listBeneficiarios')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.cnum_documento + ' (' + data.ctxt_descripcion + ')', 
              tipo: data.nid_tipo,
              documento: data.cnum_documento,  
              clase: data.ctxt_descripcion   
            })
        })
        this.setState({
          beneficiarios: lista, 
          selectedOption: {
            value: lista[0].value, 
            label: lista[0].label, 
            tipo: lista[0].tipo, 
            documento: lista[0].documento,
            clase: lista[0].clase
          }
        })
        this.listarCheques()
      }else{
        this.setState({beneficiarios: [], isLoading: false, isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false, isLoading2: false})
      }
    });
  }

  async listarCheques() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'cheques/listChequexNid?nidcheque=' + this.state.movimiento)
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { 
              item: data.item,
              nidcheque: data.nid,
              numero: data.cnum_numero, 
              nombre: data.ctxt_nombre,
              concepto: data.ctxt_concepto,
              liquido: data.ctxt_monto != null ? 'S/.    ' + data.ctxt_monto.toFixed(2) : 'S/. 0.00', 
              fecha: Calendar.calendario12(data.dfec_ingreso)
          })
        })
        this.movimientos = lista;
        this.setState({isLoading: false})
      }else{
        this.movimientos = [];
        this.setState({isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
}

  async detalle(){
    if(this.node.selectionContext.selected.length > 0){
      this.setState({isLoading2: true})
      let lista = this.node.selectionContext.selected;
      let lista2 = [];
      setTimeout(
        function() {
        lista.map((data) => {
            for(var i = 0; i < this.movimientos.length; i++){
              if(this.movimientos[i].item == data){
                let monto = "";
                if(this.movimientos[i].liquido == 'S/. 0.00'){
                  monto = this.movimientos[i].liquido.replace('S/. ', '');
                }else{
                  monto = this.movimientos[i].liquido.replace('S/.    ', '');
                }
                let fecha2 = this.movimientos[i].fecha.replace('-', ' ');
                fecha2 = fecha2.replace('-', ' ');
                lista2.push(
                  { 
                    numero: this.movimientos[i].numero, 
                    nombre: this.movimientos[i].nombre,
                    concepto: this.movimientos[i].concepto,
                    liquido: monto,
                    liquido2: NumeroALetras(monto),
                    fecha: fecha2,
                    fecha2: 'LIMA, ' + fecha2
                })
              }
            }
        });
        this.generarReporte(lista2);
        }
        .bind(this),
        2000
      );
    }else{
      this.props.handleClick("tl", "warning", "Debe seleccionar un cheque a emitir!", "pe-7s-info")
    }
  }

  async generarReporte(movimientos){
    const blob = pdf(reporteCheques(movimientos)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data, marcador: 0, isLoading2: false});
          }
        }
        return result;
    }); 
  }

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidcheque', data.nidcheque);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'cheques/anularCheque', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarCheques();
        this.props.handleClick("tl", "info", "El cheque ha sido eliminada!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async guardar(){
    if(this.state.fecha != '' && this.state.monto != ''){
        this.setState({isLoading: true})
        let fromData = new FormData();
        fromData.append('numero',  this.state.numero);
        fromData.append('fecha', this.state.fecha);
        fromData.append('monto', this.state.monto);
        fromData.append('comprobante', this.state.movimiento);
        fromData.append('beneficiario',  this.state.selectedOption.value);
        fromData.append('tipo', this.state.selectedOption.tipo);
        fromData.append('cheque', this.state.cheque);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${tesoreria}` + 'cheques/insertarCheque', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            this.listarCheques();
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
          }
          })
          .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
          }else{
              console.log(error);
              this.setState({isLoading: false})
          }
        });
    }else{
        this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  confirmarDelete = (datos) => {
    confirmAlert({
      title: '¿Seguro de eliminar?',
      message: 'Se eliminará el cheque seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(datos)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
            <p style={{fontSize: 13}}>N° {row.numero}</p>
            <p style={{fontSize: 13}}>{row.nombre}</p>
            <p style={{fontSize: 13, fontWeight: 200}}>CONCEPTO: {row.concepto}</p>
            <p style={{fontSize: 13, fontWeight: 200}}>TOTAL: {row.liquido}</p>
            <p style={{fontSize: 13, fontWeight: 200}}>{row.fecha}</p>
          </div>
      );
    }else{}
  }

  GetActionFormat2(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Remover Cheque" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  async volver(){
    this.props.history.push('/editarcomprobante');
  }

  async generarSelect(param){
    this.state.beneficiarios.map((data) => {
        if(data.label == param){
            this.setState({
            selectedOption: {
                value: data.value, 
                label: data.label, 
                tipo: data.tipo, 
                documento: data.documento,
                clase: data.clase
            }
            })
        }
    })
  }

  async generarChange(param){
    if(param == ''){
       this.setState({selectedOption: ''})
    }
  }

  zfill(liquido, width) {
    var zero = " ";
    var cont = 12 - width;
    return zero.repeat(cont) + liquido;
  }

  render() {
    const columns = [
      {dataField: "numero", text: "COMPROBANTES", filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', formatter: this.GetActionFormat},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white", width: 100}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPage: 4,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.movimientos.length
    };
    // const selectRow = {
    //     mode: 'checkbox',
    //     clickToSelect: true
    // };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              ref={ n => this.node = n }
              data={ this.movimientos }
              columns={ columns }
              // selectRow={ selectRow }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Generados de Cheques</h4>
                <div className="row">
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>COMPROBANTE</ControlLabel>
                        <FormControl type="text" value={this.state.numero} disabled/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>N° DE CHEQUE</ControlLabel>
                        <FormControl type="text" value={this.state.cheque} onChange= {this.handleCheque}/>
                    </div>
                  <div className="col-md-3" style={{marginBottom: 18}}>
                      <ControlLabel>FECHA</ControlLabel>
                      <FormControl type="date" value={this.state.fecha} onChange= {this.handleFecha}/>
                  </div>
                  <div className="col-md-3" style={{marginBottom: 18}}>
                      <ControlLabel>MONTO</ControlLabel>
                      <FormControl type="text" value={this.state.monto} onChange= {this.handleMonto}/>
                  </div>
                </div>
                <div className="row">
                    <div className="col-md-12" style={{marginBottom: 18}}>
                        <ControlLabel>BENEFICIARIO</ControlLabel>
                        <Autocomplete 
                            inputRef={this.inputRef}
                            searchPattern={'containsString'}
                            selectOnBlur = {false}
                            placeholder = "Buscar beneficiario"
                            maxOptionsLimit = {10} 
                            searchEnabled = {true}
                            getItemValue={(item)=>{ return item.label }} 
                            onChange = {
                            changedValue=>{ 
                            this.generarChange(changedValue)
                            }}
                            onSelect = {
                            selectedValue => { 
                            this.generarSelect(selectedValue)
                            }}
                            itemsData = {this.state.beneficiarios}
                            globalStyle = ".autocomplete {position: relative; display: inline-block; width: 100%} input {font-size: 14px} body {font-size: 14px; font-family: 'Roboto','Helvetica Neue',Arial,sans-serif; line-height: 1.42857143}"
                        />
                        <p style={{fontSize: 12, marginTop: 10}}>COMPROBANTE: {this.state.nombre}</p>
                    </div>
                </div>
                <div className="row" style={{marginBottom: 10}}>
                    <div className="col-md-12">
                       <Button style={{width: 200, marginLeft: 15, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.createFile() }}>Imprimir Cheques</Button>
                       <Button style={{width: 200}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.guardar() }}>Guardar Cheque</Button>
                    </div>
                </div>
                {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                )}
              </div>
            </Col>
            {/* <Col md={8}>
              {this.state.isLoading2 ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading2}
                      />
                    ) : (
                      <Card
                        title="Visor de Cheques Generados"
                        content={
                        <div>
                         {this.state.marcador == 0 ? (
                            <iframe src={this.state.base} style={{height: 1195, width: "100%"}}></iframe>
                          ) : (
                            <iframe src={this.state.base} style={{height: 1195, width: "100%"}}></iframe>
                          )}
                        </div>
                        }
                      />
                 )}
            </Col> */}
            <Button style={{width: 200, marginRight: 30}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.volver() }}>Volver a Anterior</Button>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default GenerarCheque;