import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import auth0Client from 'login/Auth';
import axios from 'axios';
import { sisperplus } from 'store/Apis'

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { validator } from 'store/Apis';

import { pdf, PDFViewer } from "@react-pdf/renderer";
import { PdfDocument } from "./mipdf";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Emitir extends Component {

constructor(props) {
super(props);
  this.state = {
    path: 'bandeja',
    dni: localStorage.getItem('dni'),
    usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
    id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
    nombre: JSON.parse(localStorage.getItem('datos'))[0].nombre,
    token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
    area: 'Dirección General de Administración',
    encargado: 'Fondo Para Pagos en Efectivo',
    monto: '',
    documento: '',
    concepto: '',
    isGoing: false,
    isLoading: false,
    base: ''
  }

  this.handleMonto = this.handleMonto.bind(this);
  this.handleDocumento = this.handleDocumento.bind(this);
  this.handleConcepto = this.handleConcepto.bind(this);

}

handleMonto(event) {
    this.setState({monto: event.target.value});
}

handleDocumento(event) {
    this.setState({documento: event.target.value});
}

handleConcepto(event) {
    this.setState({concepto: event.target.value});
}

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
              this.verificar();
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  async cancelar(){
    this.props.history.push('/bandeja');
  }

  async verificar(){
    this.setState({base: ''});
    var row = [];
    row.push(
       { recibo: "",
         datos: this.state.nombre,
         monto: this.state.monto,
         concepto: this.state.concepto
     })
    const blob = pdf(PdfDocument(this.state.dni, row)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data});
          }
        }
        return result;
    }); 
  }

  async crearDatos(datos){
    var row = [];
    row.push(
      { recibo: datos.codigo,
        datos: this.state.nombre,
        monto: this.state.monto,
        concepto: this.state.concepto
    })
    this.generarPdf(datos, row)
 }

  async generarPdf(datos, row){
    const blob = pdf(PdfDocument(this.state.dni, row)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.conversorFile(datos, base64data);
          }
        }
        return result;
    });
  }

  async urltoFile(url, filename, mimeType){ 
    mimeType = mimeType || (url.match(/^data:([^;]+);/)||'')[1]; 
    return (fetch(url) 
     .then(function(res){return res.arrayBuffer();}) 
     .then(function(buf){return new File([buf], filename, {type:mimeType});}) 
    ); 
  }

  async conversorFile(datos, base64){
    /*
    this.urltoFile(base64, row.archivo2, 'application/pdf') 
    .then((file) => {
      console.log(file)
    })
    */
    fetch(base64)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], datos.descripcion);
      this.subir(datos, file)
    })
  }

  async subir(datos, file){
        this.setState({isLoading: true})
        let fromData = new FormData();
        fromData.append('archivo', file);
        fromData.append('nomArchivo', datos.descripcion);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'retiros/subirArchivo', fromData)
        .then((response) => {
        var datos = response.data
        if(datos.response == null){
            this.props.handleClick("tl", "info", "Tu solicitud ha sido registrada!", "pe-7s-smile")
            this.props.history.push('/bandeja');
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
        });
  }

  async guardar(){
    if(this.state.monto != '' && this.state.concepto != '' && this.state.documento != ''){
        this.setState({isLoading: true})
        let fromData = new FormData();
        fromData.append('monto', this.state.monto);
        fromData.append('concepto', this.state.concepto);
        fromData.append('nombres', this.state.nombre);
        fromData.append('nomArchivo', this.state.documento);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'retiros/insertarRetiro', fromData)
        .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.crearDatos(datos.response);
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
        });
    }else{
        this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={5}>
            <Card
                title="Crear Solicitud"
                content={
                  this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                  <div>
                    <div className="row">
                        <div className="col-md-12" style={{marginBottom: 18}}>
                            <ControlLabel>Área Funcional</ControlLabel>
                            <FormControl type="text" value={this.state.area} disabled/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12" style={{marginBottom: 18}}>
                            <ControlLabel>Nombres del Solicitante</ControlLabel>
                            <FormControl type="text" value={this.state.nombre} disabled/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12" style={{marginBottom: 18}}>
                            <ControlLabel>Encargado del Pago</ControlLabel>
                            <FormControl type="text" value={this.state.encargado} disabled/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12" style={{marginBottom: 18}}>
                            <ControlLabel>Monto a Pagar</ControlLabel>
                            <FormControl type="number" value={this.state.monto} onChange= {this.handleMonto}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12" style={{marginBottom: 18}}>
                            <ControlLabel>Nombre del Documento a Crear</ControlLabel>
                            <FormControl type="text" value={this.state.documento} onChange= {this.handleDocumento}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12" style={{marginBottom: 18}}>
                            <ControlLabel>Concepto del Pago</ControlLabel>
                            <FormControl componentClass="textarea" style={{  height: 150, maxHeight: 150, minHeight: 150, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.concepto} onChange= {this.handleConcepto}/>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: 10, marginBottom: 10}}>
                    </div>
                    <Button style={{width: 150, marginLeft: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Cancelar Solicitud</Button>
                    <Button style={{width: 150, marginLeft: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.verificar() }}>Ver Redacción</Button>
                  </div>
                 )}
              />
            </Col>
            <Col md={7}>
            <Card
                title="Ver Solicitud"
                content={
                  <div>
                    <iframe src={this.state.base} style={{height: 1200, width: "100%"}}></iframe>
                    <Button style={{width: 150, marginTop: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.guardar() }}>Guardar Solicitud</Button>
                  </div>
                 }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(Emitir);