
import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';

import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { concursos } from 'store/Apis'
import moment from 'moment'
import swal from 'sweetalert';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { confirmAlert } from 'react-confirm-alert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Concursos extends Component {
  

  constructor(props) {
    super(props);
    this.state = {
      path: 'concursos',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      selectedOption: "",
      selectedOption2: {value: "0", label: "NUEVA"},
      selectPublicInt: [{value: "1", label: "Publico"},{value: "0", label: "Interno"}],
      selectPublicInt2: {value: "1", label: "Publico"},
      concursos: [],
      concursos2 : [],
      tiposconcurso: [],
      estadosconcurso: [{value: "0", label: "NUEVA"},{value: "1", label: "EN PROCESO"},{value: "2", label: "DESIERTA"},{value: "3", label: "FINALIZADA"},{value: "4", label: "SUSPENDIDA"},{value: "5", label: "CANCELADA"}],
      concurso: "",
      periodo: new Date().getFullYear(),
      mes: "",
      nidcarga: "",
      estado: 0,
      isLoading: true,
      show: true,
      fechaini: moment(new Date()).format('YYYY-MM-DD'),
      fechafin: moment(new Date()).format('YYYY-MM-DD'),
      dni:"",
      correo:"",
      concursantes:[], 
      editable:true,
      guardable:false,
      isMobile: false,
      ventana: 3,
      
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);
    this.handleConcurso = this.handleConcurso.bind(this);
    this.handlePeriodo = this.handlePeriodo.bind(this);
    this.handleMes = this.handleMes.bind(this);

    this.handleFechaIni = this.handleFechaIni.bind(this);
    this.handleFechaFin = this.handleFechaFin.bind(this);
    this.handleDni = this.handleDni.bind(this);
    this.handleCorreo = this.handleCorreo.bind(this);

  }
    handleConcurso(event) {
        this.setState({concurso: event.target.value});
    }
    handlePeriodo(event) {
        this.setState({periodo: event.target.value});
    }
    handleMes(event) {
        this.setState({mes: event.target.value});
    }
    handleChange = selectedOption => {
        this.setState(
        { selectedOption },
        );
    };
    handleChange2 = selectedOption2 => {
      this.setState(
      { selectedOption2 },
      );
    };
    handleChange3 = selectPublicInt2 => {
      this.setState(
      { selectPublicInt2 },
      );
    };
      handleFechaIni(event) {
        this.setState({fechaini: event.target.value});
      }
      handleFechaFin(event) {
        this.setState({fechafin: event.target.value});
      }
      handleDni(event) {
        this.setState({dni: event.target.value});
      }
      handleCorreo(event) {
        this.setState({correo: event.target.value});
      }
      handleTabChange = (index) => {
        console.log(index);
        this.setState({ventana: index})
        if (index === 3) {
          this.listarConcursos(1)
        }else if(index === 4){
          this.listarConcursos(0)
        }
        this.setState({ activeTab: index });
      }
      handleResize = () => {
        this.setState({ isMobile: window.innerWidth <= 920 });
      }
      
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  async componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.calcularMes();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async calcularMes(){
    let mesx = new Date().getMonth() + 1;
    if(mesx == 13){
      this.setState({mes: '1'})
    }else{
      this.setState({mes: mesx})
    }
    this.cargaView();
  }

  formatoMes(mes){
    if(mes <= 9){
      mes = '0' + mes
    }else if(mes >= 13){
      mes = '01'
    }
    return mes
  }

  async cargaView() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${concursos}` + 'private/listtipoconcursos')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.nid,
                label: data.ctxt_descripcion,
            })
          });
          this.setState({tiposconcurso: lista, selectedOption: {value: lista[0].value, label: lista[0].label}})
          this.listarConcursos(1);
      }else{
        this.setState({tiposconcurso: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        this.setState({isLoading: false})
      }
    });
   }

  async listarConcursos(tipo) {
      if (tipo === undefined){
        if (this.state.ventana === 3) {
          tipo = 1
        }else{
          tipo = 0
        }
      }
     this.concursos2 = [];
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${concursos}` + 'private/listconcursosx?anio=' + this.state.periodo +'&tipo='+ tipo)
      .then((res) => {
        let lista = [];
        let lista3 = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            if (data.nom_concurso === 1){
              
              lista.push(
                { 
                  item: data.item,
                  nidconcurso: data.nid,
                  nidtipoconcurso: data.nid_tipo,
                  concurso: data.ctxt_descripcion,
                  estado: data.ctxt_estado,
                  tipo: data.ctxt_tipo,
                  anio: data.cnum_anio,
                  mes: data.cnum_mes,
                  fecha: Calendar.calendario9(data.dfec_inicio),
                  fecha2: Calendar.calendario9(data.dfec_final),
                  fecha3: Calendar.calendario10(data.dfec_inicio),
                  fecha4: Calendar.calendario10(data.dfec_final),
                  publicado: data.flg_publicado,
                  extOrInt: data.extOrInt
  
              })
            }else{
              lista3.push(
                { 
                  item: data.item,
                  nidconcurso: data.nid,
                  nidtipoconcurso: data.nid_tipo,
                  concurso: data.ctxt_descripcion,
                  estado: data.ctxt_estado,
                  tipo: data.ctxt_tipo,
                  anio: data.cnum_anio,
                  mes: data.cnum_mes,
                  fecha: Calendar.calendario9(data.dfec_inicio),
                  fecha2: Calendar.calendario9(data.dfec_final),
                  fecha3: Calendar.calendario10(data.dfec_inicio),
                  fecha4: Calendar.calendario10(data.dfec_final),
                  publicado: data.flg_publicado,
                  extOrInt: data.extOrInt
              })
            }
          })
          this.setState({
            concursos: lista,
            concursos2: lista3,
            isLoading: false
          });
          
          this.cancelar();
        }else{
          this.setState({concursos: [],concursos2: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          this.setState({isLoading: false})
        }
      });
  }

  async delete(data){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${concursos}` + 'private/removerconcurso?nid=' + data.nidconcurso + '&anio=' + data.anio + '&mes=' + data.mes)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarConcursos();
        this.props.handleClick("tl", "info", "El concurso ha sido eliminado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        this.setState({isLoading: false})
      }
    });
  }

  async publicar(data){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${concursos}` + 'private/publicarconcurso?nid=' + data.nidconcurso)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarConcursos();
        this.props.handleClick("tl", "info", "El concurso ha sido publicado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al publicar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        this.setState({isLoading: false})
      }
    });
  }

 async limpiar(){
    this.listarConcursos();
    this.cancelar();
    this.props.handleClick("tl", "info", "El concurso ha sido registrado!", "pe-7s-smile")
  }

  async cancelar(){
    this.setState({selectedOption: {value: this.state.tiposconcurso[0].value, label: this.state.tiposconcurso[0].label}, concurso: "", nidcarga: ""})
  }

  async update(){
    let fromData = new FormData();
    fromData.append('fechaini', this.state.fechaini);
    fromData.append('fechafin', this.state.fechafin + ' 16:45:00');
    fromData.append('descripcion', this.state.concurso);
    fromData.append('estado', this.state.selectedOption2.label);
    fromData.append('anio', this.state.periodo);
    fromData.append('mes', this.formatoMes(this.state.mes));
    fromData.append('nidtipo', this.state.selectedOption.value);
    fromData.append('nids', this.state.nidcarga);
    fromData.append('intOrPub', this.state.selectPublicInt2.value);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${concursos}` + 'private/editarconcurso', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.limpiar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          this.setState({isLoading: false})
      }
    });
  }

  async guardar(){
      let fromData = new FormData();
      fromData.append('fechaini', this.state.fechaini);
      fromData.append('fechafin', this.state.fechafin + ' 16:45:00');
      fromData.append('descripcion', this.state.concurso);
      fromData.append('estado', this.state.selectedOption2.label);
      fromData.append('anio', this.state.periodo);
      fromData.append('mes', this.formatoMes(this.state.mes));
      fromData.append('nidtipo', this.state.selectedOption.value);
      fromData.append('intOrPub', this.state.selectPublicInt2.value);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${concursos}` + 'private/insertarconcurso', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.limpiar();
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            this.setState({isLoading: false})
        }
      });
  }

  async editar(datos){  
    var mes = datos.mes;
    if(mes.substr(0,1) == '0'){
      mes = mes.substr(1,1)
    }
    this.setState({
      selectedOption: {value: datos.nidtipoconcurso, label: datos.tipo},
      selectedOption2: {value: '', label: datos.estado},
      concurso: datos.concurso,
      periodo: datos.anio,
      mes: mes, 
      nidcarga: datos.nidconcurso,
      fechaini: datos.fecha3,
      fechafin: datos.fecha4,
      selectPublicInt2: {value: datos.extOrInt, label: datos.extOrInt == '0' ? 'Interno' : 'Publico'}
    })
  }

  async buscar(){
    swal({
      title: "Búsqueda de Concursos",
      text: 'Ingresa el año de búsqueda',
      content: "input",
      button: {
        text: "Buscar",
        closeModal: false,
      },
    })
    .then((value) => {
      if(value === null || value === ""){
        swal.close();
      }else{
        this.setState({periodo: value})
        this.listarConcursos()
        swal.close();
      }
    });
  }

  async obtener(data){
    localStorage.setItem('concurso', JSON.stringify(data))
    this.props.history.push('/postulantes');
  }

  async entrevistas(data){
    localStorage.setItem('concurso', JSON.stringify(data))
    this.props.history.push('/entrevistas');
  }

  async detalle(data){
    localStorage.setItem('concurso', JSON.stringify(data))
    this.props.history.push('/detConcurso');
  }

  async save(){
    if(this.state.concurso != "" && this.state.periodo != "" && this.state.mes != ""){
      if(this.state.nidcarga == ''){
        this.guardar()
      }else{
        this.update()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              {row.publicado == '1' ? (
              <span data-tip="Ver Detalle" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-info"></i>
                <i className="fa fa-folder-open-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
              {row.publicado == '1' ? (
              <span data-tip="Ver Postulantes" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-graduation-cap fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
              {row.publicado == '1' ? (
              <span data-tip="Ver Entrevistas" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.entrevistas(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-warning"></i>
                <i className="fa fa-users fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
              {row.publicado == '0' ? (
              <span data-tip="Publicar Concurso" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.publicar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                <i className="fa fa-paper-plane fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
              <span data-tip="Editar Concurso" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              {row.publicado == '0' ? (
              <span data-tip="Remover Concurso" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
          </div>
      );
    }else{}
  }

  async listarConcursantes() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${concursos}` + 'private/listconcursantesxdni?dni=' + this.state.dni)
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { item: data.item, 
              correo: data.ctxt_correo,
              telefono: data.cnum_telefono,
              persona: data.persona        
          })
        })
        this.setState({concursantes: lista, isLoading: false})
      }else{
        this.setState({concursantes: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        this.setState({isLoading: false})
      }
    });
}
async editar2(datos){
  this.setState({
    correo: datos.correo,
    editable: false,
    guardable: true,
  })
}

  confirmarEnvio = () => {
    confirmAlert({
      title: '¿Seguro que desea reenviar el PIN?',
      message: 'Se enviará el PIN de acceso.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.reenviarpin()
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };
  
  async reenviarpin() {
    this.setState({isLoading: true})
    axios.get(`${concursos}` + 'public/reenviarcodigo?dni=' + this.state.dni)
    .then((res) => {
      let lists = res.data.response;
          if(lists.estado === "0"){
            this.cancelar3();
            this.props.handleClick("tl", "success", "Se acaba de reenviar su PIN de acceso al correo!", "pe-7s-close")
          }else{
              this.props.handleClick("tl", "warning", "Inconvenientes al consultar. Intentelo mas tarde.!", "pe-7s-close")
          }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        this.setState({isLoading: false})
      }
    });
}

  GetActionFormat2(cell, row) {
    if(row != undefined){
      return (
          <div>    
              <span data-tip="Editar Correo" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar2(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Enviar PIN" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarEnvio(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-warning"></i>
                <i className="fa fa-envelope-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>     
          </div>
      );
    }else{}
  }

  async actualizarcorreo(){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('dni', this.state.dni);
    fromData.append('correo', this.state.correo);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${concursos}` + 'private/actualizarcorreo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarConcursantes(this.state.dni)
          this.cancelar3();
          this.props.handleClick("tl", "success", "El registro se actualizó correctamente!", "pe-7s-close")
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          this.setState({isLoading: false})
      }
    });
  }




  async cancelar3(){
    this.setState({ 
      correo: "",
      editable: true,
      guardable:false,
      isLoading:false
  })
  }


  render() {
    const { selectedOption, selectedOption2, selectPublicInt2 } = this.state;
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "estado", text: "ESTADO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "concurso", text: "CONCURSO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "anio", text: "PERIODO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "mes", text: "MES", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipo", text: "TIPO DE CONCURSO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha", text: "FECHA INICIAL", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha2", text: "FECHA FINAL", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const columns2 = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "persona", text: "NOMBRE", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "correo", text: "CORREO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.concursos.length
    };
    const options2 = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.concursantes.length
    };
    const options3 = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.concursos2.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.concursos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const contentTable2 = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.concursantes }
              columns={ columns2 }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );

    const contentTable3 = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.concursos2 }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Concursos Laborales</h4>
              <Tabs>
                <TabList>
                  <Tab ><p style={{fontSize:14, fontWeight:"bold"}} onClick={() => this.handleTabChange(1)}> Lista de Concursos</p></Tab>  
                  <Tab ><p style={{fontSize:14, fontWeight:"bold"}} onClick={() => this.handleTabChange(2)}> Postulantes</p></Tab> 
                </TabList>
                <TabPanel>
                  <div className="row" style={{marginBottom: 10}}>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6" style={{marginBottom: 18}}>
                            <ControlLabel>CONCURSO</ControlLabel>
                            <FormControl type="text" value={this.state.concurso} onChange= {this.handleConcurso}/>
                        </div>
                        <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>TIPOS DE CONCURSO</ControlLabel>
                            <Select value={selectedOption} onChange={this.handleChange} options={this.state.tiposconcurso}/>
                        </div>
                        <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>ESTADO DEL CONCURSO</ControlLabel>
                            <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.estadosconcurso}/>
                        </div>
                        <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>Concurso Publico/Interno</ControlLabel>
                            <Select value={selectPublicInt2} onChange={this.handleChange3} options={this.state.selectPublicInt}/>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>PERIODO</ControlLabel>
                            <FormControl type="number" value={this.state.periodo} onChange= {this.handlePeriodo} disabled/>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>MES</ControlLabel>
                            <FormControl type="number" value={this.state.mes} onChange= {this.handleMes} disabled/>
                        </div>
                        <div style={{marginLeft: 14, marginRight: 14}}>
                        <FormInputs
                          ncols={["col-md-2", "col-md-2"]}
                          properties={[
                            {label: "FECHA DE INICIO", type: "date", bsClass: "form-control", value: this.state.fechaini, onChange: this.handleFechaIni},
                            {label: "FECHA DE TÉRMINO", type: "date", bsClass: "form-control", value: this.state.fechafin, onChange: this.handleFechaFin}
                          ]}
                        />
                        </div>
                      </div>
                    </div>
                    
                    
                  </div><hr />
                  <div className="row" style={{marginLeft: 10, display: 'flex', justifyContent: 'flex-end', flexDirection: this.state.isMobile ? 'column' : 'row', alignItems: this.state.isMobile ? 'center' : 'flex-start'}}>
                      <div className="col">
                            <Button style={{width: 250, marginRight: 10, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.buscar() }}>Buscar</Button>
                      </div>
                      <div className="col">
                        <Button style={{width: 250, marginRight: 10, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.save() }}>Guardar</Button>
                      </div>
                      <div className="col">
                        <Button style={{width: 250, marginRight: 10, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Limpiar</Button>
                      </div>
                    </div><hr />
                    <Tabs>
                      <TabList>
                        <Tab onClick={() => this.handleTabChange(3)}><p style={{fontSize:14, fontWeight:"bold"}} > Lista de Concursos Publicos</p></Tab>  
                        <Tab onClick={() => this.handleTabChange(4)}><p style={{fontSize:14, fontWeight:"bold"}} > Lista de Concursos Internos</p></Tab> 
                      </TabList>
                      <TabPanel>
                      {this.state.isLoading ? (
                          <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading}
                          />
                        ) : (
                          <PaginationProvider
                            pagination={
                              paginationFactory(options)
                            }
                          >
                            { contentTable }
                          </PaginationProvider>
                    )}
                      </TabPanel>
                      <TabPanel>
                      {this.state.isLoading ? (
                          <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading}
                          />
                        ) : (
                          <PaginationProvider
                            pagination={
                              paginationFactory(options3)
                            }
                          >
                            { contentTable3 }
                          </PaginationProvider>
                    )}
                      </TabPanel>
                    </Tabs>
                  
                </TabPanel>
                <TabPanel>
                  <p style={{fontWeight: 50,fontSize:20, marginTop: 18, marginBottom: 25}}>Lista de Postulantes</p>
                  {this.state.isLoading ? (
                        <ClipLoader
                          css={override}
                          sizeUnit={"px"}
                          size={50}
                          color={'#123abc'}
                          loading={this.state.loading}
                        />
                  ) : (
                  <div>
                    <div className="row" style={{marginBottom: 10}}>
                      <div className="col-md-10">
                        <div className="row">
                          <div className="col-md-3" style={{marginBottom: 18}}>
                              <ControlLabel>DNI</ControlLabel>
                              <FormControl type="text" value={this.state.dni} onChange= {this.handleDni}disabled={this.state.guardable}/>
                          </div>
                          <Button style={{width: 150, marginLeft:10 , marginTop: 25}} bsStyle="success"  fill type="submit" onClick={() => { this.listarConcursantes() }}disabled={this.state.guardable}>Buscar</Button>
                          {/* <Button style={{width: 150, marginLeft:10, marginTop: 25}} bsStyle="danger"  fill type="submit" onClick={() => { this.cancelar2() }}disabled={this.state.guardable}>Limpiar</Button> */}
                        </div>
                        <div className="row">
                          <div className="col-md-3" style={{marginBottom: 18}}>
                              <ControlLabel>CORREO</ControlLabel> 
                              <FormControl type="text" value={this.state.correo} onChange= {this.handleCorreo}disabled={this.state.editable} />
                          </div>
                          <Button style={{width: 150, marginLeft: 10, marginTop: 25}} bsStyle="info"  fill type="submit" onClick={() => { this.actualizarcorreo() }}disabled={this.state.editable}>Actualizar</Button>
                          <Button style={{width: 150, marginLeft:10, marginTop: 25}} bsStyle="danger" fill type="submit" onClick={() => { this.cancelar3() }}disabled={this.state.editable}>Cancelar</Button>
                        </div>
                      </div>
                    </div>
                    <PaginationProvider
                        pagination={
                          paginationFactory(options2)
                        }>
                        { contentTable2 }
                    </PaginationProvider>
                  </div>
                  )}
                </TabPanel>
              </Tabs>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}


export default Concursos;