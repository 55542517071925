import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { jurisdiccional , paramJurisdiccional} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ConsultarSeguimiento extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'supervision',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      causa:'',
      nidcausa:"",
      causas: [],
      isLoading: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.verenlace=this.verenlace.bind(this);
    this.veryoutube=this.veryoutube.bind(this);
   
    }
  
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(JSON.parse(localStorage.getItem("jurisdiccional")).causa[0].data !== null){
        this.setState({causa: JSON.parse(localStorage.getItem('jurisdiccional')).causa[0].data.causa,
                       nidcausa: JSON.parse(localStorage.getItem('jurisdiccional')).causa[0].data.nid});
        this.listarSeguimiento(0);
    }else{
      this.props.history.push('/supervision');
    }
  }

  async listarSeguimiento(param) {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${jurisdiccional}` + 'seguimiento/listSeguimiento?causa='+ this.state.nidcausa)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                dfec_creacion:Calendar.calendario12( data.dfec_creacion),
                item: data.item,
                nid: data.nid,
                causa: data.causa,
                tipo: data.tipo,
                link: data.link,
                titulo: data.titulo,
                video: data.video
            })
          })
         this.setState({movimientos: lista, isLoading: false})
         if(param == 1){
          this.props.handleClick("tl", "info", "El seguimiento ha sido anulado!", "pe-7s-smile")  
         }
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea anular el seguimiento?',
      message: 'Se anulará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
 };

 async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'seguimiento/anularSeguimiento', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 1){ 
           this.listarSeguimiento(1);
        }else{
            this.props.handleClick("tl", "error", "No se puede anular el seguimiento!", "pe-7s-close")
            this.setState({isLoading: false})  
        }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async detalle(data){
    paramJurisdiccional.seguimiento[0].data = data
    localStorage.setItem('jurisdiccional', JSON.stringify(paramJurisdiccional))
    this.props.history.push('/editarseguimiento');
  }

  async detalle2(){
    this.props.history.push('/crearseguimiento');
  }

  async vercausa(){
    this.props.history.push('/supervision');
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Seguimiento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Anular Seguimiento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }
  
  abrirenlace(ruta){
    window.open(ruta, '_blank');
  } 

  verenlace(cell, row) {
    if(row != undefined){
      return (
          <div>
              {row.link != null && row.link != ''? (
               <span data-tip="Ver Documento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirenlace(row.link) }}>
               <ReactTooltip />
               <i className="fa fa-file-pdf-o fa-3x" style={{fontSize: 35, color:"red"}}></i>
               </span>
              ) : (null)}
         </div>
      );
    }else{}
  }

  veryoutube(cell, row){
    if(row != undefined){
      return (
          <div>
              {row.video != null && row.video != ''? (
               <span data-tip="Ver Video" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirenlace(row.video) }}>
               <ReactTooltip />
               <i className="fa fa-circle fa-stack-2x " style={{fontSize: 35, color:"white"}}></i>
                  <i className="fa fa-youtube-play fa-stack-2x fa-inverse " style={{fontSize: 35, color:"red"}}></i>
               </span>
              ) : (null)}     
        </div>
      );
    }else{}
  }

  render() {
    const {causa} = this.state
    const columns = [
    {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:100}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "titulo", text: "TÍTULO", sort: true, filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "link", text: "DOCUMENTO", headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.verenlace},
    {dataField: "tipo", text: "TIPO", sort: true,filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "video", text: "VIDEO", headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.veryoutube},
    {dataField: "dfec_creacion", text: "FECHA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Seguimientos de : <a style={{fontWeight: "bold", color: "black"}}>{causa}</a></h4>
                  <div className="row">
                      <div className="col-md-6" >
                            <ControlLabel></ControlLabel>
                            <Button style={{width: 200, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.detalle2() }}>Nuevo Seguimiento</Button>
                      </div>
                  </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
              <Button style={{width: 200, marginTop: 15,marginLeft: 15, marginBottom: 10 }} bsStyle="danger" fill type="submit" onClick={() => { this.vercausa() }}>Regresar</Button>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ConsultarSeguimiento;