import React, { Component } from "react";
import {FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, fechasBusqueda} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactExport from 'react-export-excel'
import { confirmAlert } from 'react-confirm-alert';

import Swal from 'sweetalert2'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';



const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ContadorTramite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: 'utilitariosStd',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      anioE :"",
      anio: '',     
      years: [],
      isLoading: true,
      documento:"",
      documentos: [],
      area: "",
      areas :[],
      nummax: 0,
      editable:true,
      anioActual: (new Date()).getFullYear(),
      a: false,
      nid:"",

    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.handleNumMax =this.handleNumMax.bind(this);
    this.handlePeriodo =this.handlePeriodo.bind(this);
  
    }
    handlePeriodo(event) {this.setState({anioActual: event.target.value});}
    handleNumMax(event) {this.setState({nummax: event.target.value});}
   

    handleAnio = anio => {
      this.setState(
      { anio },
      );
    };


    changeDocumento = documento => {
      this.setState(
      { documento },
      );
    };
    
    changeArea = area => {
      this.setState(
      { area },
      );
    };
  
  
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.generarPeriodos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }




  generarPeriodos(){
    let year =  2013;
    let cont = 13;
    let array = Array.from(new Array(cont),(val, index) => index + year);
    let cont2 = 0;
    let lista = [];
   if(fechasBusqueda.secArea[0].nidperiodo != "" && fechasBusqueda.secArea[1].periodo != ""){
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
      })
      this.setState({
        anio: { value: fechasBusqueda.secArea[0].nidperiodo,  label: fechasBusqueda.secArea[1].periodo}
      
      })
    }else{
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
          this.setState({
            anio: {value: cont2, label: data},
            anioE: {value: cont2, label: data} })
        }
      })
    }
    this.setState({years: lista})
    this.documentos()
 }

 async documentos(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'documentario/listDocumentos')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid_tipodocumento,
              label: data.ctxt_descripcion,       
            })
        })
        this.setState({documentos: lista, documento: {value: "", label: "Seleccione.."}})
        this.area()
      }else{
        this.setState({editoriales: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async area() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'documentario/listAreaAutorizacion')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.nid_area,
                label: data.ctxt_descripcion,
            })
          });
          this.setState({areas: lista,area: {value: lista[0].value, label: lista[0].label}})
          this.listSecuenciaxArea()
        }else{
        this.setState({areas: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
   }


  async listSecuenciaxArea() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'documentario/listSecuenciaxArea?anio=' + this.state.anio.label)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              area : data.area,
              nidarea :data.nidarea,
              cflg_tiposec:data.cflg_tiposec,
              cnum_ano:data.cnum_ano,
              documento:data.documento,
              niddoc: data.niddoc,
              nid_secuenciaxarea:data.nid_secuenciaxarea,
              nnum_maximo: data.nnum_maximo,
              nombrearea:data.nombrearea + " ("+ data.area + ")",
              tipodocumento:data.tipodocumento + " ("+ data.documento + ")",
              item:data.item
            })        
          })
          fechasBusqueda.secArea[0].nidperiodo = this.state.anio.value
          fechasBusqueda.secArea[1].periodo = this.state.anio.label
          localStorage.setItem('fechasBusqueda', JSON.stringify(fechasBusqueda))
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        
        this.setState({isLoading: false})
      }
    });
  }


 


  GetActionFormat(cell, row) {
    if(row != undefined){
    return (
        <div>
            <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x text-primary"></i>
              <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>
        </div>
    );
  }else{}
}

async guardarIndi(){  
    if(this.state.documento.value!= ""){
      this.setState({isLoading: true})
      let fromData = new FormData();
      fromData.append('documento', this.state.documento.value);
      fromData.append('area', this.state.area.value);
      fromData.append('anio',this.state.anioActual);
      fromData.append('numeromax',this.state.nummax);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${sisperplus}` + 'documentario/insertAreaIndividual', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.limpiar();         
            this.props.handleClick("tl", "info", "Se ha registrado correctamente!", "pe-7s-smile") 
      }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
      });
  }else{
    this.props.handleClick("tl", "warning", "Seleccione el tipo de documento!", "pe-7s-close")

  }

   
  }

  confirmarEnvio = () => {
    confirmAlert({
      title: '¿Seguro que desea generar las secuencias?',
      message: 'Se generarán las secuencias de cada área.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.confirmar2()
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  confirmar2 = () => {
  Swal.fire({
      title: "Se estan generando las secuencias por área",
      text: "Este proceso dura aproximadamente 5 minutos. Espere por favor...",
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
      allowOutsideClick:false
    })
    this.guardarMasivo()
  };


  async guardarMasivo(){  
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('anio',this.state.anioActual);    
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'documentario/insertMasivo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar();         
          this.props.handleClick("tl", "info", "Se ha registrado correctamente!", "pe-7s-smile") 
          Swal.close(); 
     }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
          Swal.close();
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          Swal.close();
          this.setState({isLoading: false})
      }
    });
   
  }

  async update(){  
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidsecuencia',this.state.nid);
    fromData.append('documento',this.state.documento.value);
    fromData.append('area',this.state.area.value);
    fromData.append('anio',this.state.anioActual);
    fromData.append('numero',this.state.nummax); 
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'documentario/updateArea', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar();         
          this.props.handleClick("tl", "info", "Registro actualizado correctamente!", "pe-7s-smile") 
     }else{
          this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
   
  }

async obtener(data){
    this.setState({
      area: {value : data.nidarea, label : data.nombrearea},
      documento: {value: data. niddoc ,label : data.tipodocumento},
      nummax: data.nnum_maximo,
      nid: data.nid_secuenciaxarea,
      anioActual:data.cnum_ano,
      editable:false,
      a:true,
      })
  }

  async limpiar(){
    this.listSecuenciaxArea();
    this.cancelar();
  }

  async cancelar(){
    this.setState({
         nummax:0, nid:"",
         documento :{value : "", label :"Seleccione.."},
         area :{value : "2", label :"COMISION DE HABEAS CORPUS"},
         editable:true,
         a:false,

        })
  }
  
  async regresar(){
    this.props.history.push('/utilitariosStd');
  }

  async save(){
     if(this.state.nid == ''){
        this.guardarIndi()
      }else{
        this.update()
      } 
  } 

  render() {
    const columns = [
    {dataField: "item", text: "ITEM",  headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "nombrearea", text: "ÁREA", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white",width:400}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "tipodocumento", text: "DOCUMENTO", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "nnum_maximo", text: "NÚMERO MÁXIMO", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "cflg_tiposec", text: "SECUENCIA", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
             
                <Tabs style={{marginTop: 30}}>
                    <TabList>
                        <Tab>INDIVIDUAL</Tab>
                        {this.state.a == false ? 
                          <Tab >MASIVO</Tab> :
                        null }
                    </TabList>
                    <TabPanel style={{marginTop: 30}}>
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>ÁREA</ControlLabel>
                                    <Select value={this.state.area} onChange={this.changeArea} options={this.state.areas}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>DOCUMENTO</ControlLabel>
                                    <Select value={this.state.documento} onChange={this.changeDocumento} options={this.state.documentos}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>PERIODO</ControlLabel>
                                <FormControl type="number" value={this.state.anioActual} onChange={this.handlePeriodo} disabled />
                            </div>
                            <div className="col-md-3" style={{ marginBottom: 18 }}>
                                    <ControlLabel>NÚMERO MÁXIMO</ControlLabel>
                                    <FormControl type="number" value={this.state.nummax} onChange={this.handleNumMax} disabled={this.state.editable} />
                            </div>
                            <Button style={{ width: 200, marginTop: 25, marginBottom: 20, marginRight: 15 }} bsStyle="danger" fill type="submit" pullRight onClick={() => { this.cancelar() }}>Cancelar</Button>
                            <Button style={{ width: 200, marginTop: 25, marginBottom: 20, marginRight: 10 }} bsStyle="success" fill type="submit" pullRight onClick={() => { this.save() }}>Guardar</Button>
                        </div>         
                    </TabPanel>
                    {this.state.a == false ? 
                    <TabPanel style={{marginTop: 30}}>
                        <div className="row">
                            <div className="col-md-4" style={{marginBottom: 18}}>
                              <ControlLabel>PERIODO ACTUAL</ControlLabel>
                               <p style={{fontStyle: 'italic', marginTop: 5, fontSize :15}}>Se generará las secuencias por área del año mostrado a continuación:</p> 
                               <FormControl type="number" value={this.state.anioActual} onChange={this.handlePeriodo} disabled  />
                             
                            </div> 
                              <Button style={{ width: 200, marginTop: 25, marginBottom: 20, marginRight: 10 }} bsStyle="success" fill type="submit" pullRight onClick={() => { this.confirmarEnvio() }}>Generar</Button>
                        </div>

                    </TabPanel>
                    : null}
                </Tabs>
                <hr></hr>
                <div className="row">
                <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25, marginLeft: 20}}>Listado de Secuencias por Área </h4>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>PERIODO</ControlLabel>
                        <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                    </div>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.listSecuenciaxArea() }}>Buscar</Button>
                </div>
                {this.state.isLoading ? (
                   <ClipLoader
                              css={override}
                              sizeUnit={"px"}
                              size={50}
                              color={'#123abc'}
                              loading={this.state.loading}
                          />                    
                   
                ) : (
                    <div>
                        <PaginationProvider
                            pagination={
                            paginationFactory(options)
                            }>
                            { contentTable }
                        </PaginationProvider>
                        <Button style={{width: 250, marginTop: 18}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                    </div>
                )}
   
              </div>

            </Col>

          </Row>
        </Grid>
      </div>
    );
  }
}

export default ContadorTramite;