import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from 'react-select';

import { validator } from 'store/Apis';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import { pdf } from "@react-pdf/renderer";
import { PdfDocument } from "./mipdf";
import { PdfDocument2 } from "./mipdf2";

import moment from 'moment'
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { sisper, sisperplus } from 'store/Apis'
import ReactTooltip from 'react-tooltip';
import { Right } from "react-bootstrap/lib/Media";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Remoto extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'teletrabajo',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      labores: [],
      areas: [],
      trabajadores: [],
      area: "",
      trabajador: "",
      fechaini: moment(moment().startOf('week').toDate()).format('YYYY-MM-DD'),
      fechafin: moment(moment().endOf('week').toDate()).format('YYYY-MM-DD'),
      tipoHorario2: [{value: "0", label: "JORNADA LABORAL"},{value: "2", label: "HORARIO PRÁCTICAS PRE PROFESIONAL"},{value: "3", label: "HORARIO PRÁCTICAS PROFESIONAL"},{value: "1", label: "HORAS ADICIONALES"}],
      selectedOption2: {value: "0", label: "JORNADA LABORAL"},
      horas: 1,
      minutos: 0,
      nidcarga: "",
      estado: 0,
      isLoading: false,
      base: "",
      ver:false,
      fechaT: "",
      areaT:"",
      nidT:"",
      isLoadingG:false
    }
    this.handleFechaini = this.handleFechaini.bind(this);
    this.handleFechafin = this.handleFechafin.bind(this);
    this.GetActionFormat = this.GetActionFormat.bind(this);
    this.handleFechaT = this.handleFechaT.bind(this);
    this.handleProducto= this.handleProducto.bind(this);
    this.handleActividad = this.handleActividad.bind(this);
    this.color= this.color.bind(this);
  }
    handleArea = area => {
        this.setState(
        { area },
        () => this.listarTrabajadores()
        );
    };

    changeAreaT = areaT => {
      this.setState(
      { areaT }
      );
    };
  

    handleTrabajador = trabajador => {
        this.setState(
        { trabajador },
        );
    };
    
    handleFechaini(event) {
      this.setState({fechaini: event.target.value});
    }

    handleFechafin(event) {
      this.setState({fechafin: event.target.value});
    }

    handleFechaT(event) {
      this.setState({fechaT: event.target.value});
    }
    handleProducto(event) {
      this.setState({producto: event.target.value});
    }

    handleActividad(event) {
      this.setState({actividad: event.target.value});
    }



    handleChange2 = selectedOption2 => {
      this.setState(
      { selectedOption2 },
      );
    };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.listarAreas();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listarAreas(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'labores/listAreas')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.ReplicationUnit,
                label: data.name,
            })
          });
          this.setState({areas: lista, area: {value: lista[0].value, label: lista[0].label}})
          this.listarTrabajadores()
      }else{
        this.setState({areas: []})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarTrabajadores(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'labores/listPersonasxarea?nid=' + this.state.area.value)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.Person,
                label: data.nombre,
                dni: data.dni
            })
          });
          this.setState({trabajadores: lista, trabajador:{value: lista[0].value, label: lista[0].label, dni: lista[0].dni}})
      }else{
        this.setState({trabajadores: []})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarLabores() {
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${sisperplus}` + 'labores/listCargasxpersona?usuario=' + this.state.trabajador.value + '&fechaini=' + this.state.fechaini + '&fechafin=' + this.state.fechafin + '&tipo=' + this.state.selectedOption2.value)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response.listCargas;
        let lista3 = res.data.response.listTotal[0].total;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { item: data.item,
                idlabor: data.nid,
                idarea: data.nid_area,
                oficina: data.oficina,
                producto: data.actividad,
                horas: data.horas + " Hrs",
                horas2: data.horas,
                horainicio: data.horainicio,
                horafinal: data.horafinal,
                actividad: data.producto,
                comentario: data.comentario,
                tipo: data.tipo,
                estado: data.estado == '' ? 'PENDIENTE' : data.estado,
                aprobador: data.aprobador,
                creacion: Calendar.calendario9(data.dfecCreacion),
                fecha: Calendar.calendario12(data.dfec_registro),
                fecha2: data.dfec_modificacion !=null ? Calendar.calendarioA(data.dfec_modificacion) : null,
            })
          })
          this.setState({minutos: lista3, labores: lista, isLoading: false, isLoadingG:false})
        }else{
          this.setState({labores: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  conversorHoras(minutos){
    var hours = Math.floor( minutos / 60);          
    var minutes = (minutos % 60);
    return hours + ":" + minutes;
  }

  async verificar(){
    if(this.state.selectedOption2.value == "0"){
      this.generarPDF1();
    }else{
      this.verData();
    }
 }

 async generarPDF1(){
   var row = [];
   row.push(
    {  jefe: this.state.area.text,
       datos: this.state.trabajador.label,
       fechaini: Calendar.calendario6(this.state.fechaini),
       fechafin: Calendar.calendario6(this.state.fechafin),
       // tipo: this.state.selectedOption2.label,
       minutos: this.conversorHoras(this.state.minutos)
   })
   const blob = pdf(PdfDocument(this.state.labores, row)).toBlob();
   function initPromise(numero) {
     return new Promise(function(res,rej) {
       res(numero);
     })
   }
   let data = initPromise(blob).then((result) => {
       if(result != null){
         var reader = new window.FileReader();
         reader.readAsDataURL(result); 
         reader.onloadend = () => {
             var base64data = reader.result;
             this.setState({base: base64data});
         }
       }
       return result;
   }); 
 }

 async verData(){
  axios.get(sisperplus + 'perfil/detailperson?dni=' + this.state.trabajador.dni, {
      headers: {Authorization: this.state.token}
    }).then((response) => {
      let datos = response.data.response;
      this.generarPDF2(datos[0].area, datos[0].cargo);
    },(error) => {
      console.log(error)
    });
 }

 async generarPDF2(organo, cargo){
   var row = [];
    row.push(
     { organo: organo,
       area: organo,
       cargo: cargo,
       datos: this.state.trabajador.label,
       fechaini: Calendar.calendario6(this.state.fechaini),
       fechafin: Calendar.calendario6(this.state.fechafin),
       //tipo: this.state.selectedOption2.label,
       minutos: this.conversorHoras(this.state.minutos)
   })
   const blob = pdf(PdfDocument2(this.state.labores, row)).toBlob();
   function initPromise(numero) {
     return new Promise(function(res,rej) {
       res(numero);
     })
   }
   let data = initPromise(blob).then((result) => {
       if(result != null){
         var reader = new window.FileReader();
         reader.readAsDataURL(result); 
         reader.onloadend = () => {
             var base64data = reader.result;
             this.setState({base: base64data});
         }
       }
       return result;
   }); 
 }

  async regresar(){
    this.setState({base: ""})
  }

  GetActionFormat(cell, row) {
    if (row != undefined) {
        return (
            <div>
               {row.estado == "APROBADO" ?
                <span data-tip="Anular Aprobación" className="fa-stack fa-2x" style={{ cursor: 'pointer', fontSize: 20 }} onClick={() => { this.confirmarAnulacion(row) }}>
                    <ReactTooltip />
                    <i className="fa fa-circle fa-stack-2x " style={{color:'#071856'}}></i>
                    <i className="fa fa-ban fa-stack-1x fa-inverse" style={{ fontSize: 15 }}></i>
                </span>
                : null }

                <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{ cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
                    <ReactTooltip />
                    <i className="fa fa-circle fa-stack-2x text-primary"></i>
                    <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{ fontSize: 15 }}></i>
                </span>
                <span data-tip="Anular Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
            </div>
        );
    } else { }
  }

  obtener(row){
    var areax = {value: row.idarea.toString(), label:row.oficina}
    this.setState({
      ver : true,
      fechaT : Calendar.calendario11(row.fecha),
      areaT : areax,
      nidT : row.idlabor,
      producto : row.actividad,
      actividad: row.producto
    })
  }

  confirmarDelete = (row) => {
    confirmAlert({
      title: '¿Seguro que desea anular el registro?',
      message: 'Se anulará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(row)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async delete(data){
    this.setState({isLoading:true})
    let fromData = new FormData();
    fromData.append('nid', data.idlabor);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'labores/eliminarCarga', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarLabores()
        this.props.handleClick("tl", "info", "El registro ha sido anulado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  confirmarUpdate = () => {
    confirmAlert({
      title: '¿Seguro que desea actualizar el registro?',
      message: 'Se actualizará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.update()
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async update(){
    const { areaT, fechaT,nidT, producto, actividad } = this.state;
    let fromData = new FormData();
    fromData.append('nid', nidT);
    fromData.append('fecha', fechaT);
    fromData.append('area', areaT.value);
    fromData.append('producto', producto);
    fromData.append('actividad', actividad);
    fromData.append('tipo', this.state.selectedOption2.label);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'labores/updateFechaArea', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarLabores()
        this.setState({ver:false})
        this.props.handleClick("tl", "info", "El registro ha sido actualizado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  confirmarAnulacion = (row) => {
    confirmAlert({
      title: '¿Seguro que desea anular la aprobación?',
      message: 'Se anulará la aprobación del registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.Anulacion(row)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async Anulacion(data){
    this.setState({isLoading:true})
    let fromData = new FormData();
    fromData.append('nid', data.idlabor);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'labores/eliminarAprobacion', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarLabores()
        this.props.handleClick("tl", "info", "La aprobación ha sido anulada!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
  cancelarT(){
    this.setState({ver :false})
  }
  color(cell, row){
    if(row != undefined){
      return (
          <div>
              {row.estado == "APROBADO" ? (
              <Button style={{width:110}} bsStyle="success"  type="submit">{row.estado}</Button>
             ) : row.estado == "OBSERVADO" ?           
             <Button style={{width:110}} bsStyle="warning"  type="submit">{row.estado}</Button>
             : row.estado == "PENDIENTE" ?  
             <Button style={{width:110}} bsStyle="primary"  type="submit">{row.estado}</Button>
             :
             <Button style={{width:110}} bsStyle="info"  type="submit">{row.estado}</Button>
             }
          </div>
      );
    }else{}
  }


  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:70}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipo", text: "TIPO", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "oficina", text: "OFICINA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "actividad", text: "ACTIVIDAD", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "horas", text: "HORAS LABORADAS", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "producto", text: "PRODUCTO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
     // {dataField: "comentario", text: "COMENTARIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "estado", text: "AUTORIZACIÓN", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.color},
      {dataField: "creacion", text: "F. DE REGISTRO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha", text: "F. DE TELETRABAJO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "aprobador", text: "APROBADO POR", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha2", text: "F. DE APROBACIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "acciones", text: "ACCIONES", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:190}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.labores.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
        {/* <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div> */}
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.labores }
              columns={ columns }
              expandRow={ expandRow }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              // cellEdit={ cellEditFactory({ mode: 'click' }) }
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const expandRow = {
      onlyOneExpanding: true,
      renderer: row => (
        <div> 
          <div className="row">
            <div className="col-md-12">
              <p style={{fontWeight: 'bold', fontSize: 15}}>ACTIVIDAD: <a style={{color: '#333'}}>{ row.actividad }</a></p> 
              <p style={{fontWeight: 'bold', fontSize: 15}}>PRODUCTO: <a style={{color: '#333'}}>{ row.producto }</a></p>
              <p style={{fontWeight: 'bold', fontSize: 15}}>COMENTARIO: <a style={{color: '#333'}}>{ row.comentario }</a></p>
              <p style={{fontWeight: 'bold', fontSize: 15}}>HORA INICIO: <a style={{color: '#333'}}>{ row.horainicio }</a></p>
              <p style={{fontWeight: 'bold', fontSize: 15}}>HORA FIN: <a style={{color: '#333'}}>{ row.horafinal }</a></p>
            </div>
          </div>
        </div>
      )
    };
    const { areaT,area, trabajador, selectedOption2 } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
            <Card
                title="Carga de Actividades"
                content={
                  <div style={{marginBottom: -20}}>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>LISTA DE ÁREAS</ControlLabel>
                            <Select value={area} onChange={this.handleArea} options={this.state.areas}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>LISTA DE TRABAJADORES</ControlLabel>
                            <Select value={trabajador} onChange= {this.handleTrabajador} options={this.state.trabajadores} />
                        </div>
                     </div>
                  </div>}
              />
            </Col>
          </Row>
          {this.state.base == "" ? (
          <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}> 
                {this.state.ver == false ? 
                <div className="row">
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25, marginLeft:15}}>Listado de Actividades</h4>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>TIPO DE HORARIO</ControlLabel>
                        <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.tipoHorario2}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE INICIO</ControlLabel>
                        <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaini}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                        <FormControl type="date" value={this.state.fechafin} onChange= {this.handleFechafin}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel></ControlLabel><br></br>
                        <Button style={{width: 150, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.listarLabores() }}>Buscar Actividades</Button>
                        <Button style={{width: 150, marginTop: 5, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.verificar() }}>Generar PDF</Button>
                    </div>
                </div>
              :
               <div className="row">
                <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25, marginLeft:15}}>Editar registro</h4>
                <div className="col-md-4" style={{marginBottom: 18}}>
                        <ControlLabel>TIPO DE HORARIO</ControlLabel>
                        <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.tipoHorario2}/>
                    </div>
                <div className="col-md-4" style={{marginBottom: 18}}>
                    <ControlLabel>ÁREA</ControlLabel>
                    <Select value={areaT} onChange={this.changeAreaT} options={this.state.areas}/>
                </div>
                <div className="col-md-4" style={{marginBottom: 18}}>
                    <ControlLabel>FECHA DE TELETRABAJO</ControlLabel>
                    <FormControl type="date" value={this.state.fechaT} onChange= {this.handleFechaT}/>
                </div>
                <div className="col-md-12" style={{marginBottom: 18}}>
                   <ControlLabel>ACTIVIDAD </ControlLabel>
                    <FormControl componentClass="textarea" style={{  height: 150, maxHeight: 150, minHeight: 150, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.producto} onChange= {this.handleProducto}/>
                </div>
                <div className="col-md-12"  style={{marginBottom: 18}}>
                    <ControlLabel>PRODUCTO</ControlLabel>
                    <FormControl componentClass="textarea" style={{  height: 150, maxHeight: 150, minHeight: 150, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.actividad} onChange= {this.handleActividad}/>
                </div>
                <div className="col-md-5" style={{marginBottom: 18}}>
                  <Button style={{width: 150, marginTop: 23, marginRight: 10}} bsStyle="success" fill type="submit" onClick={() => { this.confirmarUpdate() }}>Actualizar</Button>
                  <Button style={{width: 150, marginTop: 23}} bsStyle="danger" fill type="submit" onClick={() => { this.cancelarT() }}>Cancelar</Button>
                </div> 
              </div>  
           }
            {this.state.isLoading==true && this.state.isLoadingG==false ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
          ) : (
          <Row>
          <Col md={12}>
            <Card
                title="Ver Documento"
                content={
                  <div>
                    <iframe src={this.state.base} style={{height: 1200, width: "100%"}}></iframe>
                    <Button style={{width: 150, marginTop: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                  </div>
                 }
              />
            </Col>
        </Row>
        )}
        </Grid>
      </div>
    );
  }
}

export default Remoto;