import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {cec,paramCec } from 'store/Apis'
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import 'react-confirm-alert/src/react-confirm-alert.css';
import { NavLink, Link } from 'react-router-dom';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;


class Memorias extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'publicaciones',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      vistas: []
    }
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.listPublicaciones();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listPublicaciones(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${cec}` + 'medios/listPublicaciones?tipo=5')
    .then((res) => {
       let lista = res.data.response
       this.setState({vistas: lista, isLoading: false})
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
  async regresar(){
    this.props.history.push('/publicaciones');
  }

  async obtener (data){
    paramCec.cuaderno[0].data = data
    localStorage.setItem('cec', JSON.stringify(paramCec))
  }


  render() {
    return (
      <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              content={
                <main role="main">
                  <div className="container">
                    <h2 className="text-center">MEMORIAS</h2>             
                 </div>
                 <div className="album py-5 bg-light">
                  <div>
                       <Button style={{width: 250, marginTop: 5,marginBottom: 10}} bsStyle="danger" fill  type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                  </div>
                    <div style={{marginRight: 50, marginLeft: 50}}>
                      <div className="row" style={{marginTop: 30}}>
                      {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                      ) : (
                      this.state.vistas.map((data, i) => {
                            return (
                        <NavLink key={i} to={data.path}>
                          <div className="col-md-4" href={data.path} to={data.path} onClick={()=>{this.obtener(data)}}>
                             <div className="card"  style={{borderRadius: 0}}>
                              <img className="card-img-top" style={{height:230, width:'100%',objectFit: "scale-down"}} src={data.imagen}/>                            
                              <div className="card-body">
                                <p className="text-center" style={{color:"grey", letterSpacing: 1,fontWeight: "bold"}}>{data.descripcion}</p>
                              </div>
                             </div>
                          </div>
                        </NavLink>
                            );
                        }))}
                      </div>
                    </div>
                  </div>
                </main>
                  }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Memorias;