import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Route,Switch, Redirect} from 'react-router-dom'
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis'
import ReactTooltip from 'react-tooltip'

import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";
import store from "store/Store.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { sisper, sisperplus } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Papeletas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'papeletas',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      papeletas: [],
      isLoading: true,
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.descargar= this.descargar.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              let product = store.getState().detalle
              store.dispatch({
                type: "REMOVE_DETALLE",
                product
              });
              this.listarPapeletas();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listarPapeletas() {
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${sisperplus}` + 'papeletas/lista?id=' + this.state.id)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { FechaInicio: Calendar.calendario9(data.FechaInicio),
                adjunto: data.adjunto,
                descripcion: data.descripcion,
                documentcomments: data.documentcomments,
                estado: data.estado,
                estadoreloj: data.estadoreloj == '' ? 'PENDIENTE' : data.estadoreloj,
                fechaFin: Calendar.calendario9(data.fechaFin),
                flagjustifica: data.flagjustifica,
                idpapeleta: data.idpapeleta,
                item: data.item,
                observacion: data.observacion,
                registerdate: Calendar.calendario9(data.registerdate),
                tipopapeleta: data.tipopapeleta,
            })
          });
          this.setState({papeletas: lista, isLoading: false})
        }else{
          this.setState({papeletas: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async nuevo(){
    this.props.history.push('/nuevapapeleta');
  }

  async ver(row){
    localStorage.setItem('detalle', JSON.stringify(row))
    this.props.history.push('/detalle');
  }

  async delete(idpape){
    let fromData = new FormData();
    fromData.append('pape', idpape);
    fromData.append('nid', this.state.id);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'papeletas/eliminarPapeleta', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response == null){
        this.listarPapeletas();
        this.props.handleClick("tl", "info", "Tu papeleta ha sido eliminada!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  
  }

  GetActionFormat(cell, row) {
      return (
          <div>
              <span data-tip="Ver Papeleta" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              {row.estado == 'PENDIENTE' ? (
              <span data-tip="Remover Papeleta" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete(row.idpapeleta) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
          </div>
      );
  }

  async mostrar(data){

   let data2={
              file : data.adjunto,
              anio : Calendar.calendario11(data.registerdate).substr(0,4),
              mes : Calendar.calendario11(data.registerdate).substr(5,2),
              "tipo" : "/adjuntos/",
              "estado" : "2"
            }  
            console.log(data2)
   window.open('http://181.177.234.7/papeletas/adjuntos/' + data2.anio + "/" + data2.mes + "/" + data2.file)
    // localStorage.setItem('visor', JSON.stringify(data2))
   //  this.props.history.push('/visorautorizacion');
  }

  GetActionFormat2(cell, row) {
    return (
          <div>
              {row.adjunto == null ? (
              <p style={{fontSize: 14}}>{row.estado}</p>) : (
              <Button data-tip="Ver Autorización" bsStyle="success" type="submit" onClick={() => { this.mostrar(row) }}>{row.estado}<ReactTooltip /></Button>
              )}
          </div>
      );
  }

  descargar(cell, row){
    return (
      <div>
          {row.adjunto == null ? (
          <p style={{fontSize: 14}}>{row.adjunto}</p>
          ) : (
            <span data-tip="Descargar Autorización" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.download(row) }}>
            <ReactTooltip />
            <i className="fa fa-download fa-stack-1x fa-inverse" style={{fontSize: 25, color:"#1B4F72"}}></i>
          </span>
         
          )}
      </div>
  );
  }

  download(data){
    console.log(data)
    let anio = Calendar.calendario11(data.registerdate).substr(0,4)
    let mes =  Calendar.calendario11(data.registerdate).substr(5,2) 
    let archivo = data.adjunto
    window.open("https://gestordocumentaltc.sedetc.gob.pe/index.php/s/ag7Dq3JcdRKr5Zg/download?path=%2F&files="+ anio + "/"+ mes +"/"+ archivo, "_blank")
  }


  render() {
    const columns = [
      {dataField: "item", text: "ITEM",  headerStyle: {backgroundColor: '#337AB7', color: "white",width:70}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "descripcion", text: "TIPO DE PAPELETA", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "FechaInicio", text: "FEC. INICIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechaFin", text: "FEC. TERMINO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    //  {dataField: "observacion", text: "MOTIVO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "registerdate", text: "FEC. REGISTRO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "estado", text: "AUTORIZACIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},
     {dataField: "descargar", text: "DESCARGAR", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.descargar},
      {dataField: "estadoreloj", text: "SINC. RELOJ", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.papeletas.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
        {/* <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div> */}
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.papeletas }
              columns={ columns }
              expandRow={ expandRow }
              filter={ filterFactory() }
              // cellEdit={ cellEditFactory({ mode: 'click' }) }
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const expandRow = {
      onlyOneExpanding: true,
      renderer: row => (
        <div> 
          <div className="row">
            <div className="col-md-12">
              <p style={{fontWeight: 'bold', fontSize: 15}}>MOTIVO: <a style={{color: '#333'}}>{ row.observacion }</a></p>
            </div>
          </div>
        </div>
      )
    };
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Papeletas de Salida</h4>
              <Button style={{width: 150, marginTop: 10, marginBottom: 10}} bsStyle="success" pullLeft fill type="submit" onClick={() => { this.nuevo() }}>Nueva Papeleta</Button>
              <h4 className="category" style={{fontSize: 15, marginTop: 10}}>Los documentos de autorizaciones de papeletas se reflejaran en el sistema despues de 15 minutos de haberse aprobado debido a la sincronización con la web.</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Papeletas;