import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus,fechasBusqueda,paramJurisdiccional,concursos,paramRRHH} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Swal from 'sweetalert2'
import { confirmAlert } from 'react-confirm-alert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class VerConcursos extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'concursos',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,

      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      nidconcurso:"",
      tipo:"", 
      nombre :"",
      nombreConcurso:""
    }
    this.pdf= this.pdf.bind(this);
 
 }

 async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal()
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
  async validarLocal(){
    if(JSON.parse(localStorage.getItem("recursoshumanos")).resoluciones[0].data !== null){
        console.log(JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data[0].row.nidconcurso)
        this.setState({
          nidconcurso: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data[0].row.nidconcurso,
          nombreConcurso: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data[0].row.concurso,
          tipo: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data[1].param,
          nombre: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data[1].param==1 ? "Concurso" : "Ganador",
        })
        this.listResoluciones();
    }else{
      this.props.history.push('/audiPostulaciones');
    }
  }

  async listResoluciones(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${concursos}` + 'private/listResoluciones?nidconcurso='+this.state.nidconcurso+'&tipo='+this.state.tipo)
    .then((res) => {                                                                                    
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({
              ctxt_descripcion: data.ctxt_descripcion,
              item: data.item,
              ctxt_ruta: data.ctxt_ruta             
            })
        })
          this.setState({movimientos: lista, isLoading: false})       
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
    .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
    });
  } 

  async Cancelar(){
    this.props.history.push('/audiPostulaciones');
   }

   pdf(cell, row) {
    return (
        <div>
            <span data-tip="Ver Resolución" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver(row) }}>
              <ReactTooltip />  
              <i className="fa fa-circle fa-stack-2x text-danger"></i>
              <i className="fa fa-file-pdf-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span> 
        </div>
    );
  }

  async ver(row){
      window.open(row.ctxt_ruta, '_blank'); 
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white",width:40}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ctxt_descripcion", text: "DESCRIPCIÓN", sort: true, filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white",width:250}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ctxt_ruta", text: "ARCHIVO", filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white",width:100}, headerAlign: (column, colIndex) => 'center', align: 'center' ,formatter: this.pdf},   
  ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Concurso: {this.state.nombreConcurso} </h4>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Resoluciones del <strong>{this.state.nombre}</strong> </h4>
          
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <div>
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                     
                         
                          <Button style={{width: 250, marginBottom: 10}} bsStyle="danger" fill pullRight type="submit" onClick={() => { this.Cancelar() }}>Volver</Button>
                   
                      </div>
                 )}
              </div>           
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default VerConcursos;