import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus,paramRRHH} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { confirmAlert } from 'react-confirm-alert';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class AreasAdm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarAreas',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
 }


  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
             this.listAreasAdm()
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
  
    
 

  async listAreasAdm() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'area/listArea')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                code:data.code,
                name:data.name,
                referencia: data.reference,
                descripcion: data.Description,
                estado:data.Status =="A" ? "ACTIVO": "INACTIVO",
                fecha:Calendar.calendarioA(data.XLastDate),
                firstperson :data.person1 == null ? "" : data.person1 ,
                secondperson :data.person2 == null ? "" : data.person2,
                namefirstperson :data.personname1 == null ? "" : data.personname1,
                namesecondperson :data.personname2 == null ? "" : data.personname2,
                nid: data.ReplicationUnit,
                item: data.item
             }) 
          }) 
         
          this.setState({movimientos: lista, isLoading: false})
        
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
    
  }
  confirmarDelete = (data) => {
    var titulo = "";
    var mensaje = "";
    if(data.estado == 'ACTIVO'){
        titulo = "¿Seguro que desea anular el área?";
        mensaje = "Se anulará el registro seleccionado.";
    }else{
        titulo = "¿Seguro que desea habilitar el área?";
        mensaje = "Se activará el registro seleccionado";
    }
    confirmAlert({
      title: titulo,
      message: mensaje,
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };
  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'area/manejarArea', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 1){
            this.listAreasAdm();
            if(data.estado == "ACTIVO"){
                  this.props.handleClick("tl", "info", "El área ha sido anulado!", "pe-7s-smile")
            }else{
                  this.props.handleClick("tl", "info", "El área ha sido habilitado!", "pe-7s-smile")
            }  
        }else{
            this.props.handleClick("tl", "error", "No se puede manejar el estado!", "pe-7s-close")
            this.setState({isLoading: false})
        }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al manejar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }


  GetActionFormat(cell, row) {
    if(row != undefined){
    return (
        <div>
           <span data-tip="Ver Trabajadores" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 18}} onClick={() => { this.detalle(row,"T") }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x" style={{color: "#900C3F"}}></i>
              <i className="fa fa-users fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>

            <span data-tip="Ver Permisos" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize:18}} onClick={() => { this.detalle(row,"P") }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x" style={{color: "#A95810"}}></i>
              <i className="fa fa-bars fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>

            <span data-tip="Ver Subáreas" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 18}} onClick={() => { this.detalle(row,"S") }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x " style={{color: "#121452"}}></i>
              <i className="fa fa-sitemap fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>

            <span data-tip="Editar Área" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 18}} onClick={() => { this.detalle(row,"E") }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x "  style={{color: "#DFDC29"}}></i>
              <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>

             {row.estado=="ACTIVO" ?
            <span  data-tip="Anular Área" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 18}} onClick={() => { this.confirmarDelete(row) }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x"></i>
              <i className="fa fa-power-off fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span> :
            <span  data-tip="Activar Área" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 18}} onClick={() => { this.confirmarDelete(row) }}>
            <ReactTooltip />
            <i className="fa fa-circle fa-stack-2x" style={{ color:"#285511"}}></i>
            <i className="fa fa-power-off fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
          </span>
            }
        </div>
    );
  }else{}
}

async detalle(data,param){
  paramRRHH.areaAdm[0].data = data
  localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH))
  if(param =="P")
  this.props.history.push('/verPermisos');
  else if(param == "E") {
    this.props.history.push('/editarArea');
   }else if(param == "S") {
    this.props.history.push('/verSubareas');
   }else {
    this.props.history.push('/verTrabajadores');

  }
}

async regresar(){
  this.props.history.push("/administrarAreas")
}

async crear(){
  this.props.history.push("/crearArea")
}


  render() {
    const columns = [
      {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white",width:50}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "descripcion", text: "ÁREA", filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "name", text: "DESCRIPCIÓN", filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "referencia", text: "REFERENCIA", filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'justify'},
      {dataField: "namefirstperson", text: "JEFE PRINCIPAL",  filter: textFilter(),sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'CENTER'},
      {dataField: "namesecondperson", text: "JEFE SECUNDARIO", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "estado", text: "ESTADO", sort: true,filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              expandRow={ expandRow }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const expandRow = {
      onlyOneExpanding: true,
      renderer: row => (
        <div> 
          <div className="row">
            <div className="col-md-12">
              <p style={{fontWeight: 'bold', fontSize: 15}}>DESCRIPCIÓN: <a style={{color: '#333'}}>{ row.name }</a></p>
              <p style={{fontWeight: 'bold', fontSize: 15}}>REFERENCIA: <a style={{color: '#333'}}>{ row.referencia }</a></p>
            </div>
          </div>
        </div>
      )
    };
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Lista de Áreas del TC</h4>
              <div className="row">
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 250, marginRight: 10,  marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                    <Button style={{width: 250, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.crear() }}>Nueva Área</Button>
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AreasAdm;