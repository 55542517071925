import { createStore } from 'redux';

const reducer =  (state, action) => {
    if(action.type === "SAVE_DETALLE"){
        return {
           ...state,
           detalle: state.detalle.concat(action.product)
        };
    }else if (action.type === "REMOVE_DETALLE"){
        return {
            ...state,
            detalle: state.detalle.filter(product => product.item === action.product.item)
        };
    }
    return state;
}

export default createStore(reducer, { detalle: [] });