import React, { Component } from "react";
import {FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import ReactExport from 'react-export-excel';
import moment from 'moment'
import { sisperplus } from "store/Apis";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;




class Desplazamiento extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarDesplazamientos',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,

      multiDataSet: [],
      movimientos: [],
      sala:  {value: "01", label: "SALA 1"},
      salas: [  
        {value: "01", label: "SALA 1"} , 
        {value: "02", label: "SALA 2"}
      ],

      fechaini: moment().startOf('month').format('YYYY-MM-DD'),
      fechafin: moment().format('YYYY-MM-DD'),

      isLoading: true
    }

    this.handleFechaini = this.handleFechaini.bind(this);
    this.handleFechafin = this.handleFechafin.bind(this);


    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.verenlace=this.verenlace.bind(this);
    this.verenlace2=this.verenlace2.bind(this);


    this.handleSala = this.handleSala.bind(this);

    
  
  }

  handleFechaini(event) {this.setState({fechaini: event.target.value})}

  handleFechafin = (event) => {
    const selectedDate = event.target.value;
    const currentDate = moment().format('YYYY-MM-DD');
  
    if (selectedDate <= currentDate) {
      this.setState({ fechafin: selectedDate });
    } else {
      this.props.handleClick("tl", "warning", "La fecha de término no puede ser mayor a la fecha actual", "pe-7s-info")
    }
  };
  
  
  handleSala = sala => {
    this.setState(
      { sala },
      ); 
    };
 
    async componentDidMount() {
      if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
          axios.get(validator + this.state.path + '/'+ this.state.usuario, {
          headers: {
              Authorization: this.state.token
          }
          }).then((response) => {
              let permisos = response.data;
              if(permisos.codError != 1){
                  this.props.history.goBack()
              }else{
                  this.listarDesplazamientos();
              }
          },(error) => {
              if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
              }else{
              this.props.history.goBack()
              }
          });
      }else{
          auth0Client.signOut();
          this.props.history.replace('/');
      }
    }
  async listarXfechas(){
    this.setState({isLoading: true})
    let fecha1 = moment(this.state.fechaini).format('YYYY-MM-DD')
    let fecha2 = moment(this.state.fechafin).format('YYYY-MM-DD')
    if(fecha1 <= fecha2){
      this.listarDesplazamientos()
    }else{
      this.props.handleClick("tl", "warning", "La fecha de inicio no puede ser mayor a la fecha de término", "pe-7s-info")
      this.setState({isLoading: false})
    }
  }

  async listarDesplazamientos() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'desplazamientos/listDesplazamientos?fec_inicio=' + this.state.fechaini + '&fec_fin=' + this.state.fechafin)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              item: data.item,
              dni: data.dni,
              nombresApellidos: data.nombres,
              tipoDesplazamiento: data.nom_tipoDesp,
              docSustento: data.docSustenta,
              docAutorizacion: data.docAutoriza,
              fechaInicio: data.dfec_inicio,
              fechaTermino: data.dfec_fin,
              nid: data.nid,

              Email: data.Email,
              ctxt_comunicarAreaOrigen : data.ctxt_comunicarAreaOrigen,
              ctxt_regLabOrigen : data.ctxt_regLabOrigen,
              nid_puestoDestino : data.nid_puestoDestino,
              nid_tipoDesp : data.nid_tipoDesp,
              ctxt_revisionPlazaPresup : data.ctxt_revisionPlazaPresup,
              ctxt_aceptaExpresa : data.ctxt_aceptaExpresa,
              rdoc_actoRes : data.rdoc_actoRes,
              fec_inicioContrato: data.fec_inicioContrato,
              dfec_nContrato:data.dfec_nContrato,
              ctxt_comunicarAreaDestino:data.ctxt_comunicarAreaDestino,
              ctxt_expRemLegajo:data.ctxt_expRemLegajo,
              nid_person:data.nid_person,
              ctxt_remDestino:data.ctxt_remDestino,
              dfec_actoRes:data.dfec_actoRes,
              nom_puestoDestino:data.nom_puestoDestino,
              direccion:data.direccion,
              nid_puestoOrigen:data.nid_puestoOrigen,
              ctxt_comunicarServidor:data.ctxt_comunicarServidor,
              ctxt_enCargoArea:data.ctxt_enCargoArea,
              fec_finContrato:data.fec_finContrato,
              ctxt_perfil:data.ctxt_perfil,
              ctxt_regLabDestino:data.ctxt_regLabDestino,
              nom_unidadOrganica: data.nom_unidadOrganica,
              ctxt_remOrigen:data.ctxt_remOrigen,
              nid_unidadOrganica:data.nid_unidadOrganica,
              nom_puestoOrigen:data.nom_puestoOrigen,
              ctxt_nProcesoSel:data.ctxt_nProcesoSel,
              nom_tipoDesp:data.nom_tipoDesp,
              rdoc_nContrato:data.rdoc_nContrato,
              nom_RegimenLaboralDestino:data.nom_RegimenLaboralDestino,
              nombre_regimen : data.nombre_regimen,
              nid_OrganicaDestino: data.nid_OrganicaDestino,
              nom_OrganicaDestino: data.nom_OrganicaDestino
            })
          })
         this.setState({movimientos: lista, isLoading: false})
         this.generarExcel(lista)
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        this.setState({isLoading: false})
      }
    });
  }
    
  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea anular el desplazamiento?',
      message: 'Se anulará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'desplazamientos/deleteDesplazamiento', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 1){
            this.listarDesplazamientos()
            this.props.handleClick("tl", "info", "Desplazamiento ha sido anulada!", "pe-7s-smile")
        }else{
            this.props.handleClick("tl", "error", "Desplazamiento No se puede anular!", "pe-7s-close")
            this.setState({isLoading: false})
        }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        this.setState({isLoading: false})
      }
    });
  }

  // async detalle(data){
  //   paramJurisdiccional.salanoticia[0].data = data
  //   localStorage.setItem('jurisdiccional', JSON.stringify(paramJurisdiccional))
  //   this.props.history.push('/editarsalasnoticias');
  // }
  async detalle(){
    this.props.history.push('/NuevoDesplazamiento');
  }

  async detalle2(data){
    localStorage.setItem('desplazamiento', JSON.stringify(data))
    this.props.history.push('/EditarDesplazamiento');

  }

  async detalle3(){
    this.props.history.push('/detalleDesplazamiento');
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Ver Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle2(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-info"></i>
                <i className="fa fa-eye fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Anular" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  async abrirImagen(link){
    window.open(link, '_blank');
  } 
  
  verenlace(cell, row) {
    if(row != undefined){
        return (
          <div>
              {row.docSustento != null  && row.docSustento != '' ? (
              <span data-tip="Ver Documento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(row.docSustento) }}>
                  <ReactTooltip /> 
                  <i className="fa fa-file-pdf-o fa-3x" style={{fontSize: 35, color:"red"}}></i>
              </span>
              ) : (null)}
          </div>
        );
    }else{}
  }
  verenlace2(cell, row) {
    if(row != undefined){
        return (
          <div>
              {row.docAutorizacion != null  && row.docAutorizacion != '' ? (
              <span data-tip="Ver Documento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(row.docAutorizacion) }}>
                  <ReactTooltip /> 
                  <i className="fa fa-file-pdf-o fa-3x" style={{fontSize: 35, color:"red"}}></i>
              </span>
              ) : (null)}
          </div>
        );
    }else{}
  }
  convertirFecha(fecha){
    let date = new Date(fecha);
    let newDate = new Date(date.getTime() + Math.abs(date.getTimezoneOffset()*60000));
    return newDate.toISOString().split('T')[0];
  }
  generarExcel(lista){
    let dataExcel = [];
    lista.map((data) => {
      dataExcel.push(
        [
          {value: JSON.stringify(data.item), alignment: {horizontal: "center"}},
          {value: data.nombresApellidos === null || undefined? '--' : data.nombresApellidos},
          {value: data.dni === null || undefined? '--' : data.dni},
          {value: data.direccion === null || undefined? '--' : data.direccion},
          {value: data.Email === null || undefined? '--' : data.Email},
          {value: data.nombre_regimen === null|| undefined ? '--' : data.nombre_regimen},
          {value: data.nom_unidadOrganica === null || undefined? '--' : data.nom_unidadOrganica},
          {value: data.fec_inicioContrato === null|| undefined ? '--' : this.convertirFecha(data.fec_inicioContrato)},
          {value: data.fec_finContrato === null|| undefined ? '--' : this.convertirFecha(data.fec_finContrato)},
          {value: data.nom_puestoOrigen === null || undefined? '--' : data.nom_puestoOrigen},
          {value: data.ctxt_remOrigen === null || undefined? '--' : data.ctxt_remOrigen},
          {value: data.ctxt_nProcesoSel === null || undefined? '--' : data.ctxt_nProcesoSel},
          {value: data.rdoc_actoRes === null||  data.rdoc_actoRes === undefined  || data.rdoc_actoRes === ''? '--' : data.rdoc_actoRes},
          {value: data.dfec_actoRes === null|| undefined ? '--' : data.rdoc_actoRes === null || data.rdoc_actoRes === '' || data.rdoc_actoRes === undefined ? '--' : data.dfec_actoRes},
          {value: data.rdoc_nContrato === null ||  data.rdoc_nContrato === undefined || data.rdoc_nContrato === '' ? '--' : data.rdoc_nContrato},
          {value: data.dfec_nContrato === null || undefined? '--' : data.rdoc_nContrato === null || data.rdoc_nContrato === undefined|| data.rdoc_nContrato === '' ? '--' : data.dfec_nContrato},
          {value: data.nom_tipoDesp === null || undefined? '--' : data.nom_tipoDesp},
          {value: data.nom_OrganicaDestino === null || undefined? '--' : data.nom_OrganicaDestino},
          {value: data.nom_puestoDestino === null || undefined? '--' : data.nom_puestoDestino},
          {value: data.ctxt_remDestino === null || undefined? '--' : data.ctxt_remDestino},
          {value: data.nom_RegimenLaboralDestino === null || undefined? '--' : data.nom_RegimenLaboralDestino},
          {value: data.ctxt_aceptaExpresa === null|| undefined ? '--' : parseInt(data.ctxt_aceptaExpresa) === 1 ? 'SI' : 'NO'},
          {value: data.ctxt_revisionPlazaPresup === null || undefined? '--' : parseInt(data.ctxt_revisionPlazaPresup) === 1 ? 'SI' : data.ctxt_revisionPlazaPresup === 2 ? 'NO' : 'NO APLICA'},
          {value: data.ctxt_perfil === null || undefined? '--' : parseInt(data.ctxt_perfil) === 1 ? 'SI' : 'NO'},
          {value: data.fechaInicio === null || undefined  ? '--' : data.fechaInicio},
          {value: data.fechaTermino === null || undefined ?  '--' : data.fechaTermino},
          {value: data.docSustento === null || undefined? '--' : data.docSustento },
          {value: data.ctxt_enCargoArea === null || undefined? '--' : parseInt(data.ctxt_enCargoArea) === 1 ? 'SI' : 'NO'},
          {value: data.docAutorizacion === null|| undefined ? '--' : data.docAutorizacion},
          {value: data.ctxt_expRemLegajo === null || undefined? '--' : parseInt(data.ctxt_expRemLegajo) === 1 ? 'SI' : 'NO'},
          {value: data.ctxt_comunicarServidor === null|| undefined ? '--' : parseInt(data.ctxt_comunicarServidor) === 1 ? 'SI' : 'NO'},
          {value: data.ctxt_comunicarAreaOrigen === null|| undefined ? '--' : parseInt(data.ctxt_comunicarAreaOrigen) === 1 ? 'SI' : 'NO'},
          {value: data.ctxt_comunicarAreaDestino  === null || undefined? '--' : parseInt(data.ctxt_comunicarAreaDestino) === 1 ? 'SI' : 'NO'},
      ]   
      )
    });
    const dataSet = [
      {
        columns: [
          'N°', //1
          'Apellidos y nombres',//2
          'DNI', 
          'Dirección',
          'Correo personal',
          'Régimen Laboral origen',
          'Órgano y/o unidad orgánica',
          'Fecha de inicio del contrato',
          'Fecha de término del contrato', 
          'Puesto origen', 
          'Remuneración origen', 
          'N° de Proceso de Selección', 
          'Acto resolutivo', 
          'Fecha - Acto resolutivo', 
          'N° Contrato', 
          'Fecha - Contrato', 
          'Tipo de desplazamiento',
          'Órgano y/o unidad orgánica - destino',
          'Puesto destino', 
          'Remuneración destino',
          'Régimen Laboral destino', 
          'Documento de aceptación expresa',
          'Revisión de plaza presupuestada/PAP',
          'Cumple perfil', 
           'Fecha de inicio del desplazamiento', 
          'Fecha de término del desplazamiento', 
          'Documento sustento de la necesidad de desplazamiento', 
          'Entrega de cargo en el área/entidad de origen', 
          'Documento que autoriza el desplazamiento', 
          'Expediente remitido a legajo',
          'Comunicar servidor', 
          'Comunicar  área origen',
          'Comunicar  área destino'],
        data: dataExcel
      },
    ];
    this.setState({multiDataSet: dataSet})
  }
 

  render() {
    const columns = [
    {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width: 50}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "dni", text: "DNI", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", }, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "nombresApellidos", text: "Nombres y Apellidos ", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", }, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "tipoDesplazamiento", text: "TIPO DESPLAZAMIENTO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", }, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "docSustento", text: "DOC. SUSTENTO",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",}, headerAlign: (column, colIndex) => 'center',align: 'center',formatter: this.verenlace},
    {dataField: "docAutorizacion", text: "DOC. AUTORIZACION",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",}, headerAlign: (column, colIndex) => 'center',align: 'center',formatter: this.verenlace2},
    {dataField: "fechaInicio", text: "FECHA  DE INICIO DE DESPLAZAMIENTO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", }, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "fechaTermino", text: "FECHA DE TÉRMINO DE DESPLAZAMIENTO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", }, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Desplazamientos</h4>
              <div className="row">
                    <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE INICIO</ControlLabel>
                        <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaini} />
                    </div>
                 <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                        <FormControl type="date" value={this.state.fechafin} onChange= {this.handleFechafin}/>
                    </div>
                <div className="col-md-8" style={{marginBottom: 18}}>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.listarXfechas() }}>Buscar Desplazamientos</Button>
                    <Button style={{width: 250, marginTop: 5, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.detalle() }}>Nuevo Desplazamiento</Button>
                    <ExcelFile element={<Button style={{width: 150, marginLeft: 10}} bsStyle="success" pullRight fill type="submit">Descargar Excel</Button>}>
                              <ExcelSheet dataSet={this.state.multiDataSet} name="desplazamientos"/>
                    </ExcelFile>
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Desplazamiento;
