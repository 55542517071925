import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Route,Switch, Redirect} from 'react-router-dom'
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis'
import ReactTooltip from 'react-tooltip'

import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";
import store from "store/Store.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { concursos } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class PostulantesPas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'programas',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      postulantes: [],
      listaDet: [],
      concurso: '',
      isLoading: true,
      isLoading2: false
    }
    this.GetActionFormat = this.GetActionFormat.bind(this);
    this.GetActionFormat3 = this.GetActionFormat3.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if( localStorage.getItem("concurso") !== null){
        this.setState({concurso: JSON.parse(localStorage.getItem('concurso')).concurso});
        let nid = JSON.parse(localStorage.getItem('concurso')).nidconcurso;
        this.listarPostulantes(nid);
    }else{
      this.props.history.push('/programas');
    }
  }

  async listarPostulantes(nid) {
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${concursos}` + 'private/listpostulantesxprograma?nid=' + nid)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { item: data.item,
                nidpostulante: data.nid_postulante,
                dni: data.cnum_dni,
                nombres: data.nombres,
                cont_nuevo: data.cont_nuevo,
                cont_enproceso: data.cont_enproceso,
                cont_desierto: data.cont_desierto,
                cont_finalizado: data.cont_finalizado,
                cont_suspendido: data.cont_suspendido
            })
          });
          this.setState({postulantes: lista, isLoading: false})
        }else{
          this.setState({postulantes: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async listarProgramas(nid) {
    this.setState({listaDet: [], isLoading2: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${concursos}` + 'private/listprogramasxpostulante?nid=' + nid)
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { item: data.item,
              ctxt_estado: data.ctxt_estado,
              ctxt_descripcion: data.ctxt_descripcion,
              dfec_postulacion: Calendar.calendario9(data.dfec_postulacion)
          })
        });
        this.setState({listaDet: lista, isLoading2: false})
      }else{
        this.setState({listaDet: [], isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async verFicha(row){
    localStorage.setItem('postulante', JSON.stringify(row))
    this.props.history.push('/fichaPostulantePas');
  }

  async cancelar(){
    this.props.history.push('/programas');
  }

  GetActionFormat(cell, row) {
      return (
          <div>
            <p style={{fontSize: 12}}>Concursos Nuevos: <a>{row.cont_nuevo}</a></p>
            <p style={{fontSize: 12}}>Concursos en Proceso: <a>{row.cont_enproceso}</a></p>
            <p style={{fontSize: 12}}>Concursos Desiertos: <a>{row.cont_desierto}</a></p>
            <p style={{fontSize: 12}}>Concursos Finalizados: <a>{row.cont_finalizado}</a></p>
            <p style={{fontSize: 12}}>Concursos Suspendidos: <a>{row.cont_suspendido}</a></p>
          </div>
      );
  }

  GetActionFormat3(cell, row) {
    return (
        <div>
            <span data-tip="Ver Ficha de Inscripción" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.verFicha(row) }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x text-info"></i>
              <i className="fa fa-file-pdf-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>
        </div>
    );
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nombres", text: "NOMBRE DEL POSTULANTE", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "dni", text: "N° DE DNI", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ficha", text: "FICHA DE INSCRIPCIÓN (Documentos Adjuntos de Inscripción)", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat3},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const columns2 = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#28B463', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ctxt_estado", text: "ESTADO", sort: true, headerStyle: {backgroundColor: '#28B463', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ctxt_descripcion", text: "PROGRAMA", sort: true, headerStyle: {backgroundColor: '#28B463', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "dfec_postulacion", text: "FECHA DE INSCRIPCIÓN", sort: true, headerStyle: {backgroundColor: '#28B463', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.postulantes.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.postulantes }
              columns={ columns }
              expandRow={ expandRow }
              filter={ filterFactory() }
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const expandRow = {
      onlyOneExpanding: true,
      renderer: row => (
        <div style={{textAlign: 'center'}}> 
          <Button style={{width: 150, marginLeft: 10, marginBottom: 20}} bsStyle="primary" fill type="submit" onClick={() => {this.listarProgramas(row.nidpostulante)}}>Ver Inscripciones</Button> 
          <div className="row">
            <div className="col-md-12">
              {this.state.isLoading2 ? (
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading2}
                  />
                ) : (
                  <div className="row">
                    <div className="col-md-12">
                      <BootstrapTable keyField='item' data={ this.state.listaDet } columns={ columns2 } />
                    </div>
                  </div>
              )}
            </div>
          </div>
        </div>
      ),
      onExpand: (row, isExpand, rowIndex, e) => {
        if(this.state.listaDet.length != 0){
         setTimeout(
             function() {
               this.setState({listaDet: []})
             }
             .bind(this),
             100
         );
        }
      }
    };
    const {concurso} = this.state
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Lista de Postulantes para el Programa: {concurso}</h4>
              <h4 className="category" style={{fontSize: 15, marginTop: 10}}>En la siguiente tabla se lista a las pesonas que se hayan inscrito al programa seleccionado.</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
            <Button style={{width: 150, marginRight: 25}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Volver</Button>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default PostulantesPas;