import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, cec, fechasBusqueda, paramCec } from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { paramAulaVirtual } from "store/Apis";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ConsultarColoquios extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'publicaciones',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      anio: '',
      mes: '',
      years: [],
      meses: [],
      
      isLoading: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.veryoutube = this.veryoutube.bind(this);
 }
  handleAnio = anio => {
    this.setState(
    { anio },
    );
  };
  handleMes = mes => {
    this.setState(
    { mes },
    );
  };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
             this.generarPeriodos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  generarPeriodos(){
    let year =  2000;
    let cont = 26;
    let array = Array.from(new Array(cont),(val, index) => index + year);
    let arrayMes = [
      {"nid": "01", "descripcion": "ENERO"},
      {"nid": "02", "descripcion": "FEBRERO"},
      {"nid": "03", "descripcion": "MARZO"},
      {"nid": "04", "descripcion": "ABRIL"},
      {"nid": "05", "descripcion": "MAYO"},
      {"nid": "06", "descripcion": "JUNIO"},
      {"nid": "07", "descripcion": "JULIO"},
      {"nid": "08", "descripcion": "AGOSTO"},
      {"nid": "09", "descripcion": "SETIEMBRE"},
      {"nid": "10", "descripcion": "OCTUBRE"},
      {"nid": "11", "descripcion": "NOVIEMBRE"},
      {"nid": "12", "descripcion": "DICIEMBRE"},
    ]
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    if(fechasBusqueda.coloquios[0].nidperiodo != "" && fechasBusqueda.coloquios[1].periodo != ""){
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
      })
      this.setState({anio: {
        value: fechasBusqueda.coloquios[0].nidperiodo, 
        label: fechasBusqueda.coloquios[1].periodo
      }})
    }else{
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
          this.setState({anio: {value: cont2, label: data}})
        }
      })
    }
    if(fechasBusqueda.coloquios[2].nidmes != "" && fechasBusqueda.coloquios[3].mes != ""){
      arrayMes.map((data) => {
        cont3++
        lista2.push({
            item: data.cont3,
            value: data.nid,
            label: data.descripcion,
        })
      })
      this.setState({mes: {
        value: fechasBusqueda.coloquios[2].nidmes, 
        label: fechasBusqueda.coloquios[3].mes, 
      }})
    }else{
      arrayMes.map((data) => {
        cont3++
        lista2.push({
            item: data.cont3,
            value: data.nid,
            label: data.descripcion,
        })
        if(cont3 == (new Date()).getMonth() + 1){
          this.setState({mes: {value: data.nid, label: data.descripcion}})
        }
      })
    }
    this.setState({years: lista, meses: lista2})

    this.listarColoquios()
 }

  async listarColoquios() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${cec}` + 'contenido/listPublicaciones?anio=' + this.state.anio.label + '&mes=' + this.state.mes.value + '&nidcanal=36')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              ctxt_titulo: data.ctxt_titulo,
              ctxt_descripcion: data.ctxt_descripcion,
              ctxt_clave: data.ctxt_clave,
              ctxt_ruta: data.ctxt_ruta,
              cnum_anio: data.cnum_anio,
              cnum_mes: data.cnum_mes,
              nid_tipo: data.nid_tipo,
              tipo: data.tipo,
              flg_principal: data.flg_principal,
              flg_estado: data.flg_estado,
              nid_canal: data.nid_canal,
              dfec_publicacion: data.dfec_publicacion,
              dfec_creacion: data.dfec_creacion,
              item: data.item,
              nid: data.nid
            })
          })
          fechasBusqueda.coloquios[0].nidperiodo = this.state.anio.value
          fechasBusqueda.coloquios[1].periodo = this.state.anio.label
          fechasBusqueda.coloquios[2].nidmes = this.state.mes.value
          fechasBusqueda.coloquios[3].mes = this.state.mes.label
          localStorage.setItem('fechasBusqueda', JSON.stringify(fechasBusqueda))
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea anular la publicación?',
      message: 'Se anulará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidvideo', data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'contenido/anularPublicaciones', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 1){
          this.listarColoquios();
          this.props.handleClick("tl", "info", "La publicación ha sido anulada!", "pe-7s-smile")
      }else{
          this.props.handleClick("tl", "error", "No se puede anular la publicación!", "pe-7s-close")
          this.setState({isLoading: false})
      }
    }else{
      this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
      this.setState({isLoading: false})
    }
  })
  .catch((error) =>{ 
    if(auth0Client.isAuthenticated() === true){
      auth0Client.signOut()
      this.props.history.replace('/');
    }else{
      console.log(error);
      this.setState({isLoading: false})
    }
  });
}



  async detalle(data){
    paramAulaVirtual.coloquios[0].data = data
    localStorage.setItem('aulaVirtual', JSON.stringify(paramAulaVirtual))
    this.props.history.push('/editarColoquios');
  }


  async detalle2(){
    this.props.history.push('/crearColoquios');
   }


  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Publicación" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row, "E") }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
             
              <span data-tip="Anular Publicación" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  abrirYoutube(ruta){
    var rutax = "https://www.youtube.com/watch?v=" + ruta
    window.open(rutax, '_blank');
  }
  async regresar(){
    this.props.history.push('/cecProgramas');
  }



  veryoutube(cell, row){
    if(row != undefined){
      return (
          <div>
              {row.ctxt_ruta != null && row.ctxt_ruta != ''? (
               <span data-tip="Ver Video" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirYoutube(row.ctxt_ruta) }}>
               <ReactTooltip />
               <i className="fa fa-circle fa-stack-2x " style={{fontSize: 35, color:"white"}}></i>
                  <i className="fa fa-youtube-play fa-stack-2x fa-inverse " style={{fontSize: 35, color:"red"}}></i>
               </span>
              ) : (null)}     
        </div>
      );
    }else{}
   }

 

  render() {
    const columns = [
   {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "ctxt_titulo", text: "TÍTULO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "ctxt_descripcion", text: "DESCRIPCIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "ctxt_clave", text: "PALABRAS CLAVE",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:200}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "ctxt_ruta", text: "VIDEO",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center',align: 'center',formatter :this.veryoutube},
    // {dataField: "tipo", text: "TIPO DE VIDEO",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center',align: 'center', align: 'center'},
    {dataField: "dfec_publicacion", text: "FECHA DE PUBLICACIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:200}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Publicaciones de Coloquios Constitucionales</h4>
              
              <div className="row">


                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>PERIODO</ControlLabel>
                    <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>MES</ControlLabel>
                    <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses}/>
                </div>

                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.listarColoquios() }}>Buscar </Button>
                    <Button style={{width: 250, marginTop: 5, marginBottom: 10,marginRight: 10,}} bsStyle="info" fill type="submit" onClick={() => { this.detalle2() }}>Nueva Publicación</Button>
                    <Button style={{width: 250, marginTop: 5,marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>

                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ConsultarColoquios;