import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import auth0Client from 'login/Auth';
import axios from 'axios';
import { gestor, sisper, sisperplus } from 'store/Apis'

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Calendar from "components/Calendar/Calendar.jsx";
import InputFiles from 'react-input-files';
import Select from 'react-select';

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import CheckboxGroup from 'react-checkbox-group'
import { validator } from 'store/Apis'
import './lista.css';

import store from "store/Store.jsx";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class verPapeleta extends Component {

constructor(props) {
super(props);
  this.state = {
    path: 'papeletas',
    dni: localStorage.getItem('dni'),
    usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
    id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
    token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
    detalle: JSON.parse(localStorage.getItem('detalle')),
    detalle2: [],
    nidPapeleta: '',
    fechaini: '',
    fechafin: '',
    selectedOption: '',
    papeletas: [],
    archivos: [],
    file: [],
    fileName: '',
    type: '',
    motivo: '',
    sustento: '',
    isGoing: false,
    isLoading: false,
    isLoading2: false,
    editable: true
  }

  this.handleFechaIni = this.handleFechaIni.bind(this);
  this.handleFechaFin = this.handleFechaFin.bind(this);
  this.handleMotivo = this.handleMotivo.bind(this);
  this.handleSustento = this.handleSustento.bind(this);
  this.handleCheck = this.handleCheck.bind(this);
}

handleFechaIni(event) {
    this.setState({fechaini: event.target.value});
}

handleFechaFin(event) {
    this.setState({fechafin: event.target.value});
}

handleMotivo(event) {
    this.setState({motivo: event.target.value});
}

handleSustento(event) {
    this.setState({sustento: event.target.value});
}

handleCheck(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
}

handleChange = selectedOption => {
    this.setState(
    { selectedOption },
    // () => console.log(`Option selected:`, this.state.selectedOption)
    );
};

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
              this.validarLocal();
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(store.getState().detalle.length === 1 || this.state.detalle !== null){
      if(this.state.detalle == null){
        store.subscribe(() => {
          this.setState({
            detalle2: store.getState().detalle
          })
        })
        let data = store.getState().detalle;
        data.map((det) => {
          this.setState({
            nidPapeleta: det.idpapeleta,
            selectedOption: {value: det.tipopapeleta, label: det.descripcion},
            fechaini: Calendar.calendario11(det.FechaInicio),
            fechafin: Calendar.calendario11(det.fechaFin),
            motivo: det.observacion,
            sustento: det.documentcomments,
            isGoing: det.flagjustifica == 'N' ? false : true,
            registerdate: det.registerdate,
            estado: det.estado,
            estadoreloj: det.estadoreloj
          });
        });
        this.tiposPapeletas();
      }else{
         this.setState({
          nidPapeleta: this.state.detalle.idpapeleta,
          selectedOption: {value: this.state.detalle.tipopapeleta, label: this.state.detalle.descripcion},
          fechaini: Calendar.calendario11(this.state.detalle.FechaInicio),
          fechafin: Calendar.calendario11(this.state.detalle.fechaFin),
          motivo: this.state.detalle.observacion,
          sustento: this.state.detalle.documentcomments,
          isGoing: this.state.detalle.flagjustifica == 'N' ? false : true,
          registerdate: this.state.detalle.registerdate,
          estado: this.state.detalle.estado,
          estadoreloj: this.state.detalle.estadoreloj
         });
         let product = this.state.detalle
         store.dispatch({
            type: "SAVE_DETALLE",
            product
         });
        localStorage.removeItem('detalle')
        this.tiposPapeletas();
      }
    }else{
      this.props.history.push('/papeletas');
    }
  }

  async tiposPapeletas(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'papeletas/tiposPapeletas?param=1')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.tipopapeleta,
                label: data.descripcion,
            })
          });
          this.setState({papeletas: lista})
          this.cargaView();
      }else{
        this.setState({papeletas: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async cargaView() {
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'papeletas/listaPapeletas?id=' + this.state.nidPapeleta)
        .then((res) => {
            let lista = res.data.response;
            if(lista != null){
            this.setState({archivos: lista, isLoading: false})
        }else{
            this.setState({archivos: [], isLoading: false})
            this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
        }
        })
        .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
        });
    }

  cerrar(){
    this.setState({visFile: false});
  } 

  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], fileName: files[0].name, type: files[0].type, visFile: true});
    }
  }

  async cancelar(){
    this.props.history.push('/papeletas');
  }

  ver(data){
    let datosvisor = [{filename: data.filename,
                       filesource: data.filesource,
                       mes: Calendar.calendario10(data.xlastdate).substring(5,7),
                       anio: Calendar.calendario10(data.xlastdate).substring(0,4),
                       estado : "1",
                      tipo :"/adjuntos/" 
                      }]
    window.open('http://181.177.234.7/papeletas/adjuntos/' + datosvisor[0].anio + "/" + datosvisor[0].mes + "/" + datosvisor[0].filesource)
    //localStorage.setItem('visor', JSON.stringify(datosvisor))
   //  this.props.history.push('/visordetalle');
  }







  subirArchivo(){
    let fromData = new FormData();
    fromData.append('pape', this.state.nidPapeleta);
    fromData.append('filename', this.state.fileName);
    fromData.append('nid', this.state.id);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'papeletas/insertarArchivo', fromData)
    .then((response) => {
        var datos = response.data.response
        if(datos.archivo != ''){
          this.uploadArchivo(datos.archivo)
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al subir archivo!", "pe-7s-close")
          this.setState({isLoading: false})
        }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  uploadArchivo(archivo){
        let fecha = this.state.registerdate;
        let mes = fecha.substring(3,5)
        let anio = fecha.substring(6,10)
        let fromData = new FormData();
        fromData.append('usuario', this.state.usuario);
        fromData.append('archivo_file', this.state.file);
        fromData.append('anio', anio);
        fromData.append('mes', mes);
        fromData.append('cnomArchivo', archivo);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${gestor}` + 'document/subirArchivoPap', fromData)
        .then((response) => {
        if(response.data.result == null){
            this.props.handleClick("tl", "info", "Tu papeleta ha sido actualizada!", "pe-7s-smile")
            this.props.history.push('/papeletas');
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al guardar archivo!", "pe-7s-close")
            this.setState({isLoading2: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
        });
  }

  removerArchivo(archivo){
    let anio = Calendar.calendario10(archivo.xlastdate).substring(0,4)
    let mes = Calendar.calendario10(archivo.xlastdate).substring(5,7)
    let fromData = new FormData();
    fromData.append('usuario', this.state.usuario);
    fromData.append('anio', anio);
    fromData.append('mes', mes);
    fromData.append('cnomArchivo', archivo.filesource);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${gestor}` + 'document/bajarArchivoPap', fromData)
    .then((response) => {
    if(response.data.result == null){
        this.cargaView()
        this.props.handleClick("tl", "info", "Tu archivo ha sido eliminado!", "pe-7s-smile")
        this.setState({isLoading2: false})
    }else{
        this.props.handleClick("tl", "error", "Hubo un problema al guardar archivo!", "pe-7s-close")
        this.setState({isLoading2: false})
    }
    })
    .catch((error) =>{ 
    if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
    }else{
        console.log(error);
        this.setState({isLoading: false})
    }
    });
  }

  cambioFormat(fecha){
    var dia = fecha.substring(8,10);
    var mes = fecha.substring(5,7);
    var anio = fecha.substring(0,4);
    var newfecha = dia + '-' + mes + '-' + anio
    return newfecha
  }

  cambioCheck(check){
    if(check == true){
     return 'Y';
    }else{
     return 'N';
    }
  }

  editar(enabled){
    if(enabled == true){
        this.setState({editable: false})
    }else{
        this.guardar()
    }
  }

  guardar(){
    if(this.state.type == '' || this.state.type == 'application/pdf' || this.state.type == 'image/jpeg' || this.state.type == 'image/png'){
      if(this.state.fechaini != '' && this.state.fechafin != '' && this.state.motivo != ''){
          this.setState({isLoading: true})
          let fromData = new FormData();
          fromData.append('pape', this.state.nidPapeleta);
          fromData.append('tipopapeleta', this.state.selectedOption.value);
          fromData.append('observaciones', this.state.motivo);
          fromData.append('fechaini', this.cambioFormat(this.state.fechaini));
          fromData.append('fechafin', this.cambioFormat(this.state.fechafin));
          fromData.append('userid', this.state.id);
          fromData.append('documentcomments', this.state.sustento);
          fromData.append('flagjustifica', this.cambioCheck(this.state.isGoing));
          axios.defaults.headers.common['Authorization'] = this.state.token
          axios.post(`${sisperplus}` + 'papeletas/editarPapeleta', fromData)
          .then((response) => {
            var datos = response.data
            if(datos.response == null){
              if(this.state.file != ''){
                  this.subirArchivo()
              }else{
                  this.props.handleClick("tl", "info", "Tu papeleta ha sido actualizada!", "pe-7s-smile")
                  this.props.history.push('/papeletas');
              }
            }else{
              this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
              this.setState({isLoading: false})
            }
          })
          .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              this.setState({isLoading: false})
            }
          });
      }else{
        this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
      }
    }else{
      this.props.handleClick("tl", "warning", "El archivo a subir debe ser un pdf o una imagen!", "pe-7s-info")
    }
  }

  remove(data){
   this.setState({isLoading2: true})
   let fromData = new FormData();
   fromData.append('pape', data.idpapeleta);
   fromData.append('archivo', data.filename);
   axios.defaults.headers.common['Authorization'] = this.state.token
   axios.post(`${sisperplus}` + 'papeletas/eliminarArchivo', fromData)
   .then((response) => {
     var datos = response.data
     if(datos.response == null){
        this.removerArchivo(data);
     }else{
        this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
        this.setState({isLoading2: false})
     }
   })
   .catch((error) =>{ 
     if(auth0Client.isAuthenticated() === true){
       auth0Client.signOut()
       this.props.history.replace('/');
     }else{
       console.log(error);
       this.setState({isLoading2: false})
     }
   });
  }

  async download(data){
    let anio = Calendar.calendario10(data.xlastdate).substr(0,4)
    let mes =  Calendar.calendario10(data.xlastdate).substr(5,2) 
    let archivo = data.filesource
    window.open("https://gestordocumentaltc.sedetc.gob.pe/index.php/s/ag7Dq3JcdRKr5Zg/download?path=%2F&files="+ anio + "/"+ mes +"/"+ archivo, "_blank")
  }

  render() {
    const { selectedOption } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
            <Card
                title="Ver Detalle"
                content={
                  this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                  <div>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>TIPO DE PAPELETA</ControlLabel>
                            <Select value={selectedOption} onChange={this.handleChange} options={this.state.papeletas} isDisabled={this.state.editable}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DE INICIO</ControlLabel>
                            <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaIni} disabled={this.state.editable}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DE TERMINO</ControlLabel>
                            <FormControl type="date" value={this.state.fechafin} onChange= {this.handleFechaFin} disabled={this.state.editable}/>
                        </div>
                    </div>
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        {label: "FECHA DE REGISTRO", type: "text", bsClass: "form-control", defaultValue: this.state.registerdate, disabled: true},
                        {label: "AUTORIZACIÓN", type: "text", bsClass: "form-control", defaultValue: this.state.estado, disabled: true},
                        {label: "SINCRONIZACIÓN EN RELOJ", type: "text", bsClass: "form-control", defaultValue: this.state.estadoreloj, disabled: true}
                      ]}
                    />
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>Motivo</ControlLabel>
                            <FormControl componentClass="textarea" style={{  height: 150, maxHeight: 150, minHeight: 150, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.motivo} onChange= {this.handleMotivo} disabled={this.state.editable}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>DOCUMENTO SUSTENTO (Opcional)</ControlLabel>
                            <FormControl componentClass="textarea" style={{ height: 150, maxHeight: 150, minHeight: 150, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.sustento} onChange= {this.handleSustento} disabled={this.state.editable}/>
                        </div>
                        <div className="col-md-4" style={{letterSpacing: 1}}>
                        <ControlLabel>JUSTIFICA INGRESO FUERA DE HORARIO</ControlLabel><br></br>
                        <div>
                            <input onChange={this.handleCheck} name="isGoing" type="checkbox" checked={this.state.isGoing} style={{marginRight: 5}} disabled={this.state.editable}
                            /><a>Marque solamente cuando esta papeleta justificará un ingreso fuera de horario.</a>
                        </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: 18, marginBottom: 20}}>
                        <div className="col-md-3"></div>
                        <div className="col-md-6" style={{marginBottom: 18}}>
                            <ControlLabel>ARCHIVOS SUBIDOS</ControlLabel>
                            {this.state.isLoading2 ? (
                            <ClipLoader
                                css={override}
                                sizeUnit={"px"}
                                size={50}
                                color={'#123abc'}
                                loading={this.state.loading2}
                            />
                            ) : (
                            this.state.archivos.length != 0 ? (
                            <ul className="todo-list">
                            {this.state.archivos.map((data,index) =>
                                <li key={index}>
                                    {/* <div className="icon">
                                        <img src={require(`../images/${this.props.data.image}`)}  alt="hambuerger" />
                                    </div> */}
                                   <div className="price">
                                        <h3>{data.item}</h3>
                                    </div>
                                    <div className="name">
                                        <span className="item-name">{data.filename}</span>
                                        <span className="item-price">{data.idpapeleta}</span>
                                    </div>
                                    {/* <div className="price">
                                        <h3>{data.item}</h3>
                                    </div> */}
                                     <span className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20, marginRight: 5}} onClick={() => { this.download(data) }}>
                                        <i className="fa fa-circle fa-stack-2x" style={{color:"#1B4F72"}}></i>
                                        <i className="fa fa-download fa-stack-1x fa-inverse" style={{fontSize: 18, color:"white"}}></i>
                                    </span>
                                    <span  className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20, marginRight: 5}} onClick={() => { this.ver(data) }}>
                                        <i className="fa fa-circle fa-stack-2x text-danger"></i>
                                        <i className="fa fa-file-pdf-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                    </span>
                                    <span  className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.remove(data) }}>
                                        <i className="fa fa-circle fa-stack-2x"></i>
                                        <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                    </span>
                                </li>
                                )
                            }
                            </ul>): (null)
                            )}
                            <Alert bsStyle="warning">
                                {/* <button onClick={() => { this.cerrar() }} type="button" aria-hidden="true" className="close">&#x2715;</button> */}
                                <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.fileName}</span>
                            </Alert>
                            {this.state.editable == false ? (
                            <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit" >
                                <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                            </Button>
                            ): (null)}
                            <h4 className="category" style={{fontSize: 15, marginTop: 5}}>Los documentos adjuntos de tu papeleta se reflejaran en el sistema despues de 15 minutos de haberse subido debido a la sincronización con la web.</h4>
                        </div>
                    </div>
                        <Button style={{width: 150, marginLeft: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Cancelar Detalle</Button>
                        {this.state.estado != 'AUTORIZADO' ? (
                        this.state.editable == true ? (
                         <Button style={{width: 150, marginLeft: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.editar(this.state.editable) }}>Editar Papeleta</Button>): (
                         <Button style={{width: 150, marginLeft: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar(this.state.editable) }}>Guardar Papeleta</Button>)
                    ): (null)}
                  </div>
                 )}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(verPapeleta);