import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col,FormControl } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import axios from 'axios';
import { jurisdiccional} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class EditarSeguimiento extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'supervision',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      titulo:"",
      link :"",
      video : "",
      dfec_creacion:"",
      tipo:"",
      nid:"",
      isLoading: true,
      tipos:[
        {value: "audiencia", label: "AUDIENCIA"},
        {value: "informe", label: "INFORME"},
        {value: "sentencia", label: "SENTENCIA"},
        {value: "auto", label: "AUTO"}

      ],
    }
    this.handleTitulo= this.handleTitulo.bind(this);
    this.handleLink= this.handleLink.bind(this);
    this.handleVideo= this.handleVideo.bind(this);
    this.handleTipo= this.handleTipo.bind(this);
    this.handleFecha= this.handleFecha.bind(this);
  }
  handleTitulo(event){this.setState({titulo: event.target.value});}
  handleLink(event){this.setState({link: event.target.value});}
  handleVideo(event){this.setState({video: event.target.value});}
  handleTipo(event){this.setState({tipo: event.target.value});}
  handleFecha(event){this.setState({dfec_creacion: event.target.value});}
  handleTipo = tipo => {
    this.setState(
      { tipo },
      ); 
    };
 
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(JSON.parse(localStorage.getItem("jurisdiccional")).seguimiento[0].data !== null){
        this.setState({
                dfec_creacion: JSON.parse(localStorage.getItem('jurisdiccional')).seguimiento[0].data.dfec_creacion,
                nid: JSON.parse(localStorage.getItem('jurisdiccional')).seguimiento[0].data.nid,
                causa: JSON.parse(localStorage.getItem('jurisdiccional')).seguimiento[0].data.causa,
                tipo: {value: JSON.parse(localStorage.getItem('jurisdiccional')).seguimiento[0].data.tipo, label: JSON.parse(localStorage.getItem('jurisdiccional')).seguimiento[0].data.tipo.toUpperCase()},
                link: JSON.parse(localStorage.getItem('jurisdiccional')).seguimiento[0].data.link,
                titulo: JSON.parse(localStorage.getItem('jurisdiccional')).seguimiento[0].data.titulo,
                video: JSON.parse(localStorage.getItem('jurisdiccional')).seguimiento[0].data.video,
                isLoading: false
        });
       
        }else{
      this.props.history.push('/consultarseguimiento');
    }
  }

  
  async validar(){
    if( this.state.titulo != '' && this.state.link != '' && this.state.video != ''){
      this.actualizar();
     }else{
       this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close") 
     }
   }

  async actualizar(){  
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', this.state.nid);
    fromData.append('titulo', this.state.titulo);
    fromData.append('link', this.state.link);
    fromData.append('video', this.state.video);
    fromData.append('tipo', this.state.tipo.value);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'seguimiento/editarSeguimiento', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Seguimiento actualizado correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
    })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async regresar(){
    this.props.history.push('/consultarseguimiento');
  }

  abrir(ruta){
    window.open(ruta, '_blank');
  } 
  mostrar = () => {
    confirmAlert({
      title: <i class="fa fa-user" style={{ color :"black", marginLeft: 130,fontSize: 100}}></i>,
      message:<div><p>USUARIO : Usuario_01</p><p>CONTRASEÑA : Tribunal2022$</p> </div> ,
      buttons: [
        {
          label: 'OK',
        },        
      ],
      closeOnClickOutside: false,
    });}


  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Editar : <a style={{fontWeight: "bold", color: "black"}}>{this.state.titulo}</a></h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                       <div className="row">
                            <div className="col-md-8" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-align-justify"></i> TÍTULO</ControlLabel>
                                <FormControl type="text" value={this.state.titulo} onChange= {this.handleTitulo}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-th-large"></i> TIPO</ControlLabel>
                                <Select value={this.state.tipo} onChange={this.handleTipo} options={this.state.tipos}/>
                            </div>
                       
                          <div className="col-md-8" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-link"></i> LINK DEL DOCUMENTO</ControlLabel>
                                <FormControl type="text" value={this.state.link} onChange= {this.handleLink}/>
                          </div>
                          <div className="col-md-4" style={{marginBottom: 18}}>
                             <Button style={{width: 160,marginTop:25}} bsStyle="primary" pullRight  type="submit" onClick={() => { this.mostrar() }}>Ver Credenciales</Button>
                             <Button style={{width: 330,marginTop:25,marginRight:5}} bsStyle="primary" pullRight type="submit" onClick={() => { this.abrir("https://www.tc.gob.pe/wp-login.php") }}>Haga click aquí para subir el documento</Button>
                          </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-link"></i> LINK DEL VIDEO</ControlLabel>
                                <FormControl type="text" value={this.state.video} onChange= {this.handleVideo}/>
                          </div>
                         
                        </div>
                        <Tabs style={{marginTop: 15}}>
                          <TabList>
                             <Tab>VISUALIZAR</Tab>
                          </TabList>
                          <TabPanel>
                              <div className="row">
                                      <div className="col-md-2" style={{marginBottom: 18}}>
                                          <ControlLabel><i className="fa fa-file-pdf-o"></i> DOCUMENTO :</ControlLabel><br></br>
                                          {this.state.link != "" && this.state.link != null ? (
                                          <span  style={{cursor: 'pointer', fontSize: 18}} >
                                            <a href={this.state.link} target="blank"><i style={{color: "red"}} className="fa fa-file-pdf-o fa-5x" aria-hidden="true"></i></a>
                                          </span>
                                          ) : (
                                            <p>Sin Documento</p>
                                          )}
                                      </div>
                                      <div className="col-md-2" style={{marginBottom: 18}}>
                                          <ControlLabel><i className="fa fa-youtube-play"></i> VIDEO :</ControlLabel><br></br>
                                          {this.state.video != "" && this.state.video != null ? (
                                          <span  style={{cursor: 'pointer', fontSize: 18}} >
                                            <a href={this.state.video} target="blank"><i style={{color: "red", fontSize: 80}} className="fa fa-youtube-play fa-stack-4x" aria-hidden="true"></i></a>
                                          </span>
                                          ) : (
                                            <p>Sin Video</p>
                                          )}
                                      </div>
                                </div>
                          </TabPanel>
                        </Tabs>
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Actualización</Button>
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Actualizar Seguimiento</Button>

                    </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarSeguimiento;