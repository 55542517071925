import React, { Component } from "react";
import {Button, FormControl, ControlLabel, Grid, Row, Col} from "react-bootstrap";
import auth0Client from 'login/Auth';
import axios from 'axios';
import { sisperplus } from 'store/Apis'
import { validator } from 'store/Apis'
import ClipLoader from 'react-spinners/ClipLoader';

import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class DiasDescansoMedico extends Component {

  constructor(props) {
      super(props);
      this.state = {
      path: 'salidas',
      dni: localStorage.getItem('dni'),
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      selectedOption: {value: new Date().getFullYear(), label: new Date().getFullYear()},
      anios: [],
      salidas: [],
      isLoading2: true,
      inicioT:"",
      finT:"",
      inicioS:"",
      finS:"",
      editable:true, 
      ocultar:false,
      nid:""
    }
    this.handleFinT = this.handleFinT.bind(this);
    this.handleInicioS = this.handleInicioS.bind(this);   
  }
    handleFinT(event) {this.setState({finT: event.target.value});}
    handleInicioS(event) {this.setState({inicioS: event.target.value});}
 
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.listar();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listar(){
    this.setState({isLoading2: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'utilitarios/listDiasDescanso')
    .then((res) => {
      let lista = res.data.response;
      if(lista != null){
        this.setState({finT :lista[0].dfec_fin, inicioT: lista[0].dfec_inicio,nidT: lista[0].nid ,
            finS :lista[1].dfec_fin, inicioS: lista[1].dfec_inicio,nidS: lista[1].nid,isLoading2: false})
      }else{
        this.setState({ isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  save(){
      if(this.state.finT != '' ){
        this.setState({isLoading2 :true})
          let fromData = new FormData();
          fromData.append('nid', this.state.nid);
          fromData.append('inicio', this.state.finT);
          axios.defaults.headers.common['Authorization'] = this.state.token
          axios.post(`${sisperplus}` + 'utilitarios/updateDiasDescanso', fromData)
          .then((response) => {
            var datos = response.data
            if(datos.response != null){ 
                  this.props.handleClick("tl", "info", "El dia ha sido actualizado!", "pe-7s-smile")
                  this.cancelar()
                }else{
              this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
              this.setState({isLoading: false})
            }
          })
          .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              this.setState({isLoading: false})
            }
          });
      }else{
        this.props.handleClick("tl", "warning", "Debe completar el campo requerido!", "pe-7s-info")
      }
  }
 
  editar(visible){
      if(visible == true){// esta en disable?
          this.setState({
              nid: 1,           
              editable: false,
              ocultar:true})}
      else{
          this.setState({editable: true})
      }
  }

  cancelar(){
    this.setState({
          ocultar:false,
          editable: true,isLoading2:false
        })
        this.listar()
  }

  async regresar(){
    this.props.history.push('/administrarAreas')
  }

  render() {
     return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12} style={{ paddingBottom: 25}}>
            {this.state.isLoading2==false ? 
            <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                <div>
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>DESCANSO MÉDICO CUBIERTO POR LA ENTIDAD</h4>
                    <div className="row">
                        <div className="col-md-3" style={{marginTop: 10, marginBottom: 18}}>
                            <ControlLabel >DÍA DE INICIO</ControlLabel>
                            <FormControl type="text" value={this.state.inicioT} disabled/>
                        </div>
                        <div className="col-md-3" style={{marginTop: 10, marginBottom: 18}}>
                            <ControlLabel >DÍA FINAL</ControlLabel>
                            <FormControl type="number" value={this.state.finT} onChange={this.handleFinT} disabled={this.state.editable}/>
                        </div>
                        <div className="col-md-6" style={{marginTop: 20, marginBottom: 18}}>       
                          {this.state.ocultar==false ? 
                            <Button style={{width: 200, marginBottom: 10, marginTop:15}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar(this.state.editable) }}>Editar</Button> 
                          :
                            <div>
                              <Button style={{width: 200, marginBottom: 10, marginTop:15}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.save() }}>Guardar</Button>
                              <Button style={{width: 200, marginBottom: 10, marginTop:15, marginLeft:10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Cancelar</Button> 
                            </div>
                          }
                        </div>
                    </div>
                </div>
                <div>
                  <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>DESCANSO MÉDICO CUBIERTO POR EL SEGURO</h4>
                  <div className="row">
                    <div className="col-md-3" style={{marginTop: 10, marginBottom: 18}}>
                          <ControlLabel >DÍA DE INICIO</ControlLabel>
                          <FormControl type="text" value={this.state.inicioS}  disabled/>
                    </div>
                    <div className="col-md-3" style={{marginTop: 10, marginBottom: 18}}>
                          <ControlLabel >DÍA FINAL</ControlLabel>
                          <FormControl type="text" value={this.state.finS}  disabled/>
                    </div>
                  </div>
              </div>
            </div>
            : 
            <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={50}
            color={'#123abc'}
            loading={this.state.loading}
          />            
            }
            </Col>
               
          </Row>
         < Button style={{width: 200, marginTop: 18,marginLeft:15}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
        </Grid>  

      </div>

    );
  }
}

export default DiasDescansoMedico;