import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus,fechasBusqueda,paramJurisdiccional} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { confirmAlert } from 'react-confirm-alert';
import Swal from 'sweetalert2'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Boletines extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'boletines',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      anio: '',
      mes: '',
      nid:'', 
      flg_enviado: '',
      ctxt_descripcion: '',
      ctxt_enlace: '',
      years: [],
      meses: [],    
      isLoading: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);
    this.estado=this.estado.bind(this);
 }
  handleAnio = anio => {
    this.setState(
    { anio },
    );
  };

  handleMes = mes => {
    this.setState(
    { mes },
    );
  };
 
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              
               this.generarPeriodos();
               console.log(this.state.nid)
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  generarPeriodos(){
    let year =  2000;
    let cont = 26;
    let array = Array.from(new Array(cont),(val, index) => index + year);
    let arrayMes = [
      {"nid": "01", "descripcion": "ENERO"},
      {"nid": "02", "descripcion": "FEBRERO"},
      {"nid": "03", "descripcion": "MARZO"},
      {"nid": "04", "descripcion": "ABRIL"},
      {"nid": "05", "descripcion": "MAYO"},
      {"nid": "06", "descripcion": "JUNIO"},
      {"nid": "07", "descripcion": "JULIO"},
      {"nid": "08", "descripcion": "AGOSTO"},
      {"nid": "09", "descripcion": "SETIEMBRE"},
      {"nid": "10", "descripcion": "OCTUBRE"},
      {"nid": "11", "descripcion": "NOVIEMBRE"},
      {"nid": "12", "descripcion": "DICIEMBRE"},
    ]
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    if(fechasBusqueda.boletin[0].nidperiodo != "" && fechasBusqueda.boletin[1].periodo != ""){
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
      })
      this.setState({anio: {
        value: fechasBusqueda.boletin[0].nidperiodo, 
        label: fechasBusqueda.boletin[1].periodo

      }})
    }else{
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
          this.setState({anio: {value: cont2, label: data}})
        }
      })
    }
    if(fechasBusqueda.boletin[2].nidmes != "" && fechasBusqueda.boletin[3].mes != ""){
      arrayMes.map((data) => {
        cont3++
        lista2.push({
            item: data.cont3,
            value: data.nid,
            label: data.descripcion,
        })
      })
      this.setState({mes: {
        value: fechasBusqueda.boletin[2].nidmes, 
        label: fechasBusqueda.boletin[3].mes, 

      }})
    }else{
      arrayMes.map((data) => {
        cont3++
        lista2.push({
            item: data.cont3,
            value: data.nid,
            label: data.descripcion,
        })
        if(cont3 == (new Date()).getMonth() + 1){
          this.setState({mes: {value: data.nid, label: data.descripcion}})
        }
      })
    }
    this.setState({years: lista, meses: lista2})
    this.listBoletin()
 }
 
  async listBoletin(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'boletin/listBoletines?anio='+this.state.anio.label+'&mes='+this.state.mes.value)
    .then((res) => {                                                                                    
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({
              cnom_archivo: data.cnom_archivo,
              ctxt_descripcion: data.ctxt_descripcion,
              ctxt_enlace: data.ctxt_enlace,
              ctxt_fecha: Calendar.calendario12(data.ctxt_fecha), 
              flg_enviado: data.flg_enviado==0?"NO ENVIADO":"ENVIADO",
              flg_referencia : data.flg_referencia ==0 ? "NO"  : "SI",
              referencia : data.flg_referencia,
              item: data.item,
              nid: data.nid             
            })
        })
          fechasBusqueda.boletin[0].nidperiodo = this.state.anio.value
          fechasBusqueda.boletin[1].periodo = this.state.anio.label
          fechasBusqueda.boletin[2].nidmes = this.state.mes.value
          fechasBusqueda.boletin[3].mes = this.state.mes.label
          localStorage.setItem('fechasBusqueda', JSON.stringify(fechasBusqueda))
          this.setState({movimientos: lista, isLoading: false})       
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
    .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
    });
  } 

  confirmarCancelar = (data) => {
    confirmAlert({
      title: '¿Seguro que desea cancelar el registro?',
      message: 'Se cancelará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.cancelarRegistro(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  confirmarEnvio = (data) => {
    confirmAlert({
      title: '¿Seguro que desea enviar el boletín?',
      message: 'Se enviará el boletín seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.confirmar2(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  confirmar2 = (data) => {
  Swal.fire({
      title: "Los correos se están enviando",
      text: "Este proceso dura aproximadamente 5 minutos. Espere por favor...",
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
      allowOutsideClick:false
    })
    this.sendMessage(data)
  
  };


  async sendMessage(data){  
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidboletin', data.nid);
    fromData.append('fecha', data.ctxt_fecha);
    fromData.append('descripcion', data.ctxt_descripcion);
    fromData.append('enlace', data.ctxt_enlace)
    fromData.append('referencia', data.referencia)
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'boletin/insertEnviosMasivo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Boletin enviado correctamente!", "pe-7s-close")
        this.listBoletin();
        Swal.close();  
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema con el envío!", "pe-7s-close")
          this.setState({isLoading: false})
          Swal.close();  
      }
        })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
          Swal.close();  
      }else{
          console.log(error);
          this.setState({isLoading: false})
          Swal.close();  
      }
    });
  }

  async cancelarRegistro(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidboletin', data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'boletin/anularBoletin', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          if(datos.response.estado == 1){
              this.listBoletin();
              this.props.handleClick("tl", "info", "La noticia ha sido anulada!", "pe-7s-smile")
          }else{
              this.props.handleClick("tl", "error", "No se puede anular la noticia!", "pe-7s-close")
              this.setState({isLoading: false})
          }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
  
  async newsletter(){
    this.props.history.push('/crearBoletin');
   }

  async detalle(data,param){
    paramJurisdiccional.boletin[0].data = data
    localStorage.setItem('jurisdiccional', JSON.stringify(paramJurisdiccional))
    if(param=="E"){
    this.props.history.push('/editarBoletin')}
    else{this.props.history.push('/listarEnviado')};   
  }

  async ver(a){
    window.open(a, '_blank');
  }
 
  GetActionFormat2(cell, row) {
    return (
      <div>
          <span data-tip="Ver Boletin" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver(row.ctxt_enlace) }}>
            <ReactTooltip />
            <i className="fa fa-circle fa-stack-2x"style={{color: "red"}}></i>
            <i className="fa fa-file-pdf-o fa-stack-1x fa-inverse" style={{fontSize: 18}}></i>
          </span>
      </div>
    );
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
    return (
        <div>
            {row.flg_enviado == 'NO ENVIADO' ? (
            <span data-tip="Editar Boletín" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row,"E") }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span> 
              ) : (null)}
              {row.flg_enviado =='NO ENVIADO'?(      
              <span data-tip="Anular Boletín" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarCancelar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              ) : (null)}
              {row.flg_enviado == 'NO ENVIADO'?(
              <span data-tip="Enviar Boletín" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarEnvio(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-warning"></i>
                <i className="fa fa-envelope-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span> 
              ):(null)} 
              {row.flg_enviado == 'ENVIADO' ? (
            <span  data-tip="Ver enviados" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row,"D") }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x text-info"></i>
              <i className="fa fa-folder-open-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>) : (null)}
            {row.flg_enviado =='ENVIADO'?(      
              <span data-tip="Anular Boletín" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarCancelar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              ) : (null)}             
        </div> 
    );
  }else{}
}

estado(cell, row){
  return(
    <div>
       {row.flg_enviado== 'ENVIADO' ?
        <Button style={{width: 110}} bsStyle="success" >ENVIADO</Button>    
      :
        <Button style={{width: 110}} bsStyle="warning" >SIN ENVIAR</Button>
      }
    </div> )
}

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white",}, headerAlign: (column, colIndex) => 'center', align: 'center'},
  //    {dataField: "ctxt_descripcion", text: "DESCRIPCION", sort: true, filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail2", text: "ENLACE", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},   
      {dataField: "ctxt_fecha", text: "FECHA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},  
      {dataField: "flg_referencia", text: "CONTENIDO TC", headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "flg_enviado", text: "ESTADO DE ENVIO",headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.estado},   
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}    
  
  
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de boletines</h4>
              <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>PERIODO</ControlLabel>
                    <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>MES</ControlLabel>
                    <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses}/>
                </div>
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.listBoletin() }}>Buscar Boletín</Button>
                    <Button style={{width: 250, marginTop: 5, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.newsletter() }}>Nuevo Boletín</Button>
                </div>
                <div style={{marginBottom: 18}}>            
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Boletines;