import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBView,
  MDBContainer,
  MDBAnimation,
  MDBFooter 
} from "mdbreact";

import "./login.css";
// import personal from './personal.png'

import auth0Client from './Auth';
import ReactLoading from 'react-loading';
import { Section, Title, Article, Prop, list } from "./Generic";

class Login extends React.Component {
  state = {
    isLoading: false
  }

  async login(){
    if(await auth0Client.handleAuthentication() == 'acceso'){
      this.setState({
        isLoading: true
      })
    }
    await auth0Client.handleAuthentication();
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      window.location.href = '/inicio';  
    }else{
      this.login();
    }
  }

  loginWithRedirect = () => {
    if (auth0Client.isAuthenticated()) {
        auth0Client.signIn();
    }
  }

  signOut = () => {
      auth0Client.signOut();
      this.props.history.replace('/');
  }

  render() {
    return (
      <div id="apppage">
        <MDBView>
        <Router>
          <div>
            <MDBNavbar color="primary-color" dark expand="md" fixed="top" scrolling transparent>
              <MDBContainer>
                <MDBNavbarBrand style={{marginTop: 15, color: 'white'}}>
                  <strong style={{letterSpacing: 1}} className="white-text">SIAJ</strong>
                </MDBNavbarBrand>
              </MDBContainer>
            </MDBNavbar>
          </div>
        </Router>
          <MDBMask className="d-flex justify-content-center align-items-center">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="white-text" style={{marginTop: 50, color: "white"}}>
                  <MDBAnimation type="fadeInRight" delay=".3s">
                    {/* <img src={personal} alt="" classNameName="img-fluid" style={{marginTop: 40}}/> */}
                    <span className="fa-stack" style={{fontSize: 150}}>
                      <i className="fa fa-users"></i>
                    </span>
                  </MDBAnimation>
                  <MDBAnimation type="fadeInLeft" delay=".3s" style={{marginTop: -50}}>
                    <h1 className="h1-responsive font-weight-bold minTit">SISTEMA INTEGRADO ADMINISTRATIVO Y JURISDICCIONAL</h1>
                    <hr className="hr-light" />
                    <h6 style={{letterSpacing: 2}} className="mb-4 minDes">
                      Accede a nuestro nuevo Sistema Integrado Administrativo y Jurisdiccional del Tribunal Constitucional en 
                      donde podrás realizar consultas sobre tu perfil de trabajador y gestionar procesos administrativos y Jurisdiccional de acuerdo a tus roles otorgados.
                    </h6>
                    { this.state.isLoading ? (
                      <Section style={{marginTop: 40}}>
                        {list.map(l => (
                          <Article key={l.prop}>
                            <ReactLoading type={l.prop} color="#fff" />
                          </Article>
                        ))}
                      </Section>
                    ) : (
                      <MDBBtn 
                        onClick={() => { this.loginWithRedirect() }} style={{marginTop: 20, letterSpacing: 1, color: "white", borderColor: "white", width: 300}} color="white">
                        ACCEDER
                      </MDBBtn>
                    )}
                  </MDBAnimation>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
          <MDBFooter color="blue" className="font-small pt-4 mt-4">
            <div className="footer-copyright text-center py-3">
              <MDBContainer fluid style={{color: "white", letterSpacing: 1, bottom:20, right:20, position: "absolute"}}>
                &copy; {new Date().getFullYear()} - Desarrollado por la Oficina de TI - <a> Tribunal Constitucional del Perú </a>
              </MDBContainer>
            </div>
          </MDBFooter>
        </MDBView>
      </div>
    );
  }
}

export default Login;