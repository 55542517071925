import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { jurisdiccional,paramJurisdiccional} from 'store/Apis'

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`; 

class ConsultarCausaSeguimiento extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'supervision',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      nid:"",
      causa:"",
      isLoading: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.verseguimientos=this.verseguimientos.bind(this)
    this.handleCausa=this.handleCausa.bind(this);
   
  }
  handleCausa(event) {this.setState({causa: event.target.value});}
  
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
             this.listarCausaSeguimiento();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
  async listarCausaSeguimiento() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${jurisdiccional}` + 'causaseguimiento/listCausasseguimiento')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                dfec_creacion:Calendar.calendario12( data.dfec_creacion),
                item: data.item,
                nid: data.nid,
                causa: data.causa
             })
          })
         this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarSeguimiento() {
  this.setState({isLoading: true})
  axios.defaults.headers.common['Authorization'] = this.state.token
  axios.get(`${jurisdiccional}` + 'seguimiento/listSeguimiento')
  .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push({ 
              dfec_creacion:Calendar.calendario12( data.dfec_creacion),
              item: data.item,
              nid: data.nid,
              causa: data.causa
          })
        })
       this.setState({movimientos: lista, isLoading: false})
      }else{
        this.setState({movimientos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
  })
  .catch((error) => { 
    if(auth0Client.isAuthenticated() === true){
      auth0Client.signOut()
      this.props.history.replace('/');
    }else{
      console.log(error);
      this.setState({isLoading: false})
    }
  });
}

  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea anular la causa?',
      message: 'Se anulará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'causaseguimiento/anularCausasseguimiento', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 1){
            this.listarCausaSeguimiento();
            this.props.handleClick("tl", "info", "La causa ha sido anulada!", "pe-7s-smile")
        }else{
            this.props.handleClick("tl", "error", "No se puede anular la causa!", "pe-7s-close")
            this.setState({isLoading: false})
        }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async obtener(data, param){
    let data2 = {
      "nid": data.nid,
      "causa": data.causa
    }
    paramJurisdiccional.causa[0].data = data2
    localStorage.setItem('jurisdiccional', JSON.stringify(paramJurisdiccional))
    if(param == "E"){
      this.setState({
       causa: data.causa,
       nid: data.nid,
      })
    }else {
       this.props.history.push('/consultarseguimiento');
    }
 }

  verseguimientos(cell, row){
    if(row != undefined){
      return (
        <div>
           {row.causa != '' ? (
            <span data-tip="Ver Seguimientos" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row, "D") }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x text-warning"></i>
              <i className="fa fa-th-list fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>) : (null)}
      </div>
      );
    }else{}
  }

  async save(){
    if(this.state.causa != "" ){
      if(this.state.nid == ''){
        this.guardar()
      }else{
        this.update()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  } 

  async guardar(){  
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('causa', this.state.causa);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'causaseguimiento/insertaCausasseguimiento', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar();
          this.props.handleClick("tl", "info", "La causa ha sido registrada!", "pe-7s-smile")
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async update(){  
    let fromData = new FormData();
    fromData.append('causa', this.state.causa);
    fromData.append('nid', this.state.nid)
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'causaseguimiento/editarCausasseguimiento', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Causa actualizada correctamente!", "pe-7s-close")
        this.limpiar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async limpiar(){
    this.listarCausaSeguimiento();
    this.cancelar();
  }

  async cancelar(){
    this.setState({ causa: "", nid: ""})
  }


  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Causa" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row, "E") }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Anular Causa" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }
  
  async regresar(){
    this.props.history.push('/supervision');
  }

  render() {
    const columns = [
    {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "causa", text: "CAUSA", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "dfec_creacion", text: "FECHA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "seguimiento", text: "SEGUIMIENTO",  headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter:this.verseguimientos},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Causas</h4>
              <div className="row" style={{marginBottom: 10}}>
                  <div className="col-md-10" style={{marginBottom: 18}}>
                      <ControlLabel><i className="fa fa-th-large"></i> CAUSA</ControlLabel>
                      <FormControl type="text" value={this.state.causa} onChange= {this.handleCausa}/>
                  </div>
                  <div className="col-md-2">
                      <Button style={{width: 150, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.save() }}>Guardar</Button>
                      <Button style={{width: 150, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Cancelar</Button>
                  </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ConsultarCausaSeguimiento;