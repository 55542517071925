import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import ClipLoader from 'react-spinners/ClipLoader';
import InputFiles from 'react-input-files';
import axios from 'axios';
import { cec} from 'store/Apis'
import { Card } from "components/Card/Card.jsx";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Right } from "react-bootstrap/lib/Media";
import { data } from "jquery";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class DetalleFeria extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'publicaciones',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
     isLoading2: true,
      isLoading3: true,
      nid:'',
      nidnoticia:'',
      nidtipo:'2',
      titulo:'',
      imagenes:[],
      parrafos:[],
      ctxt_descripcion:"",
      archivo: "",
      file: [],
 }  
   this.handleDescripcion = this.handleDescripcion.bind(this);
   this.Verimagen=this.Verimagen.bind(this);
}
    handleDescripcion(event) {this.setState({ctxt_descripcion: event.target.value});}
  
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
              this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  
  async validarLocal(){
    if(JSON.parse(localStorage.getItem("cec")).noticia[0].data !== null){
        this.setState({
         titulo: JSON.parse(localStorage.getItem('cec')).noticia[0].data.ctxt_titulo,
         nidnoticia: JSON.parse(localStorage.getItem('cec')).noticia[0].data.nid
        });
        this.listarImagenes();
    }else{
      this.props.history.push('/noticias');
    }
  }
  async listarImagenes() {
   axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${cec}` + 'medios/listImagenes?nidnoticia=' + this.state.nidnoticia)
    .then((res) => {
      let lista = res.data.response
      this.setState({imagenes: lista})
      this.listarParrafos()
   })
   .catch((error) => { 
     if(auth0Client.isAuthenticated() === true){
       auth0Client.signOut()
       this.props.history.replace('/');
     }else{
       console.log(error);
      
     }
   });
 }

 async listarParrafos() {
  axios.defaults.headers.common['Authorization'] = this.state.token
  axios.get(`${cec}` + 'medios/listParrafos?nidtipo=' + this.state.nidtipo + '&nidparent=' + this.state.nidnoticia)
  .then((res) => {
    let lista = res.data.response
    this.setState({
      parrafos: lista,
      isLoading2: false,
      isLoading3:false
    })
 })
 .catch((error) => { 
   if(auth0Client.isAuthenticated() === true){
     auth0Client.signOut()
     this.props.history.replace('/');
   }else{
     console.log(error);
 
   }
 });
}

//insertar imagen
async agregar(){
  this.setState({isLoading2: true})
  if(this.state.archivo != '' && this.state.archivo != null){
  let fromData = new FormData();
  fromData.append('file', this.state.file);
  fromData.append('imagen', this.state.archivo);
  fromData.append('noticia', this.state.nidnoticia);
  fromData.append('usuario', this.state.usuario)
  axios.defaults.headers.common['Authorization'] = this.state.token
  axios.post(`${cec}` + 'medios/insertarImagen', fromData)
  .then((response) => {
    var datos = response.data
    if(datos.response != null){
      this.limpiar()
    }else{
        this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
        this.setState({isLoading2: false})
    }})
    .catch((error) =>{ 
    if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
    }else{
        console.log(error);
        this.setState({isLoading2: false})
    }
});
}else{
  this.props.handleClick("tl", "error", "Debe completar el campo solicitado!", "pe-7s-close") 
  this.setState({isLoading2: false})
}}

//insertar parrafo
async agregar2(){
  this.setState({isLoading3: true})
  if(this.state.ctxt_descripcion != ''){
  let fromData = new FormData();
  fromData.append('descripcion', this.state.ctxt_descripcion);
  fromData.append('tipo', this.state.nidtipo);
  fromData.append('parent', this.state.nidnoticia);
  fromData.append('usuario', this.state.usuario)
  axios.defaults.headers.common['Authorization'] = this.state.token
  axios.post(`${cec}` + 'medios/insertarParrafo', fromData)
  .then((response) => {
    var datos = response.data
    if(datos.response != null){
       this.limpiar2();
       this.props.handleClick("tl", "success", "Párrafo registrado correctamente!", "pe-7s-close")
   }else{
        this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
        this.setState({isLoading3: false})
    }})
    .catch((error) =>{ 
    if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
    }else{
        console.log(error);
        this.setState({isLoading3: false})
  }});
  }else{
    this.props.handleClick("tl", "error", "Debe completar el campo solicitado!", "pe-7s-close") }
  }

  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], archivo: files[0].name});
  }}

  async limpiar(){
    this.listarImagenes();
    this.setState({archivo: "", file: ""})
    this.props.handleClick("tl", "success", "Imagen registrada correctamente!", "pe-7s-close")  
  }
  async cancelar(){
    this.setState({archivo: "", file: ""})
  }


  async limpiar2(){
   this.listarParrafos()
    this.cancelar2(); 
  }

  async cancelar2(){
    this.setState({ctxt_descripcion: ""})
  }

  //editar parrafo
  async update(){  
    this.setState({isLoading3: true})
    let fromData = new FormData();
    fromData.append('descripcion', this.state.ctxt_descripcion);
    fromData.append('usuario', this.state.usuario);
    fromData.append('nidparrafo', this.state.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'medios/editarParrafo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.limpiar2();
       this.props.handleClick("tl", "success", "Parrafo actualizado correctamente!", "pe-7s-close")
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
          this.setState({isLoading3: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading3: false})
      }
    });
  }
//anular parrafo
  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea anular el párrafo?',
      message: 'Se anulará el párrafo seleccionado.',
      buttons: [
        {label: 'Aceptar',
          onClick: () => this.delete(data)},
        {label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }]
    });
  };
//anular parrafo
  async delete(data){
    this.setState({isLoading3: true})
    let fromData = new FormData();
    fromData.append('nidparrafo',  data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'medios/anularParrafo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 1){
            this.listarParrafos();
            this.props.handleClick("tl", "info", "El párrafo ha sido anulado!", "pe-7s-smile")
        }else{
            this.props.handleClick("tl", "error", "No se puede anular el párrafo!", "pe-7s-close")
            this.setState({isLoading3: false})
        }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading3: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading3: false})
      }
    });
  }

  //anular imagen
  confirmarDelete2 = (data) => {
    confirmAlert({
      title: '¿Seguro que desea anular la imagen?',
      message: 'Se anulará la imagen seleccionada.',
      buttons: [
        {label: 'Aceptar',
          onClick: () => this.delete2(data)},
        {label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }]
    });
  };
//anular imagen
  async delete2(data){
    this.setState({isLoading2: true})
    let fromData = new FormData();
    fromData.append('nidimagen',  data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'medios/anularImagen', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 1){
            this.listarImagenes();
            this.props.handleClick("tl", "info", "La imagen ha sido anulada!", "pe-7s-smile")
        }else{
            this.props.handleClick("tl", "error", "No se puede anular la imagen!", "pe-7s-close")
            this.setState({isLoading2: false})
        }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading2: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading2: false})
      }
    });
  }

 Verimagen(cell, row) {
    if(row != undefined){
      return (
        <div>
          <span style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(row.ruta) }}>
           <img src={row.ctxt_ruta} alt="" style={{width:"500px"}}></img>
          </span>
        </div>
      );}}

  abrirImagen(ruta){
      window.open(ruta, '_blank');
    } 

   async regresar(){
      this.props.history.push('/ferias');
    } 

  async obtener(data){
     localStorage.setItem('parrafo', JSON.stringify(data))
      this.setState({
         ctxt_descripcion: data.ctxt_descripcion,
         nid: data.nid,
        })
      }

  async save(){
      if(this.state.ctxt_descripcion != "" ){
          if(this.state.nid == ""){
            this.agregar2()
          }else{
            this.update()
          }
        }else{
          this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
        }
      }


 async manejarparrafo(tipo, data){
  this.setState({isLoading3: true})
  let fromData = new FormData();
    fromData.append('tipo', tipo);
    fromData.append('orden', data.nid_orden == null ? "0" : data.nid_orden);
    fromData.append('nidparrafo', data.nid);
    fromData.append('nidparent', this.state.nidnoticia);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'noticias/manejarParrafo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarParrafos();
        this.props.handleClick("tl", "info", "El párrafo ha sido movido correctamente!", "pe-7s-smile")
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al mover!", "pe-7s-close")
          this.setState({isLoading3: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading3: false})
      }
    });
 }

render() {
  return (
    <div className="content">
    <Grid fluid>
     <Row>
      <Col md={12}>
        <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>DETALLE : <a style={{fontWeight: "bold", color: "black"}}>{this.state.titulo}</a></h4>
      <Card
         content={
            <main role="main">
            <div className="row">
              <div className="col-md-12">
              <h4 className="title" style={{fontWeight: 200, marginTop: -10,marginBottom:40}}>IMÁGENES : </h4>
                <div className="row">
                  <div className="col-md-5">
                      <ControlLabel>Nueva imagen</ControlLabel>
                        <Alert bsStyle="info">
                          <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo}</span>
                        </Alert>
                      <Button style={{width: '100%', marginTop: -10}} bsStyle="info" pullRight fill type="submit">
                        <InputFiles  onChange={files => this.subir(files)}>
                          <i className="fa fa-file-image-o"  style={{fontSize: 15}}></i>Seleccionar Imagen
                        </InputFiles>
                      </Button> 
                  </div>
                  {this.state.archivo != "" ? (
                    <div  className="col-md-2">
                        <Button style={{width: '100%', marginTop: 25}} bsStyle="success"  type="submit" onClick={() => { this.agregar() }}>GUARDAR</Button>
                        <Button style={{width: '100%', marginTop:5}} bsStyle="danger" type="submit" onClick={() => { this.cancelar() }}>CANCELAR</Button>
                    </div>
                  ) : ( null )}  
                  
                </div>
              </div>
            </div>
            <h5 className="title" style={{fontWeight: 200, marginTop:25,marginBottom:20,fontWeight: "bold"}}>LISTA DE IMÁGENES : </h5>
              <div className="row" style={{marginTop: 15}}>
                {this.state.isLoading2 ? (
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading2}
                  />
                ) : (
                this.state.imagenes.map((data, i) => {
                    return (
                      <div key={i}>
                        <div className="col-md-4">
                          <div className="col-md-1">
                            <span data-tip="Anular imagen" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 15}} onClick={() => { this.confirmarDelete2(data) }}>
                              <i class="fa fa-circle fa-stack-2x" style={{ color:"silver"}}></i>
                              <i className="fa fa-times fa-stack-1x fa-inverse" style={{fontSize: 15, color:"white"}}></i>
                           </span>
                          </div>
                          <span data-tip="Ver imagen" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(data.ctxt_ruta) }}>
                              <img src={data.ctxt_ruta} style={{width:"400px",marginTop: 15 }}></img>  
                          </span>
                        </div>
                    </div>
                    );
                  }))}
              </div>
              </main>
            }
            />
          <Card
          content={
            <main role="main">
              <div className="row">
                <div className="col-md-12">
                <h4 className="title" style={{fontWeight: 200, marginTop: -10,marginBottom:40}}>PÁRRAFOS : </h4>
                    <ControlLabel>Nuevo Párrafo</ControlLabel><br></br>
                    <FormControl componentClass="textarea" 
                        style={{ height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%" }}
                        value={this.state.ctxt_descripcion} onChange= {this.handleDescripcion} 
                    /> 
                    <Button style={{marginTop: 20 ,marginBottom:5}} bsStyle="success" pullRight type="submit" onClick={() => { this.save() }}>GUARDAR</Button>
                    <Button style={{marginRight: 10, marginTop: 20,marginBottom:5 }} bsStyle="danger" pullRight type="submit" onClick={() => { this.cancelar2() }}>CANCELAR</Button>
                </div>
              </div>
                  <h5 className="title" style={{fontWeight: 200, marginTop: 20,fontWeight: "bold"}}>LISTA DE PÁRRAFOS : </h5>
                  <div className="row" style={{marginTop: 15}}>
                    {this.state.isLoading3 ? (
                    <ClipLoader
                      css={override}
                      sizeUnit={"px"}
                      size={50}
                      color={'#123abc'}
                      loading={this.state.loading3}
                    />
                    ) : (
                    this.state.parrafos.map((data, i) => {     
                     return (
                        <div className="row" key={i} style={{marginLeft:5}}>
                            <div className="col-md-10">
                              <FormControl  componentClass="textarea" 
                              style={{ marginTop:10, marginBottom:15, height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%" }} 
                              value={data.ctxt_descripcion} onChange= {this.handleDescripcion} disabled
                            />
                            </div>
                            <div className="col-md-2" style={{marginTop:40}}>
                              <span data-tip="Editar Párrafo" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(data) }}>
                                <ReactTooltip />
                                <i className="fa fa-circle fa-stack-2x text-success"></i>
                                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                              </span>
                              <span data-tip="Anular Párrafo" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(data) }}>
                                <ReactTooltip />
                                <i className="fa fa-circle fa-stack-2x"></i>
                                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                              </span>
                              
                          {data.item == 1  ? (
                                      <span data-tip="Bajar Párrafo" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.manejarparrafo(0, data) }}>
                                        <ReactTooltip />
                                        <i className="fa fa-circle fa-stack-2x"></i>
                                        <i className="fa fa-arrow-down fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                      </span>
                                
                              ) : data.item == this.state.parrafos[this.state.parrafos.length - 1].item  ?       (
                                 
                                      <span data-tip="Subir Párrafo" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.manejarparrafo(1, data) }}>
                                        <ReactTooltip />
                                        <i className="fa fa-circle fa-stack-2x"></i>
                                        <i className="fa fa-arrow-up fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                      </span>
                              ) : 
                              <span>
                                      <span data-tip="Bajar Párrafo" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.manejarparrafo(0, data) }}>
                                        <ReactTooltip />
                                        <i className="fa fa-circle fa-stack-2x"></i>
                                        <i className="fa fa-arrow-down fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                      </span>
                                      <span data-tip="Subir Párrafo" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.manejarparrafo(1, data) }}>
                                        <ReactTooltip />
                                        <i className="fa fa-circle fa-stack-2x"></i>
                                        <i className="fa fa-arrow-up fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                      </span>
                              </span>
                          }  
                          </div>
                        </div>
                       );  
                    }))}
                    </div>
              </main>
                }
            />
            <div>
              <Button bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Actualización</Button>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );   
}}
export default DetalleFeria;