import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import auth0Client from 'login/Auth';

import { validator } from 'store/Apis'
import ReactTooltip from 'react-tooltip'

import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";
import moment from 'moment'

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { sisperplus, marcaciones } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Usuarios extends Component {

constructor(props) {
    super(props);
    this.state = {
        path: 'administrador',
        usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
        token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
        id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
        usuarios: [],
        isLoading: true,
        user: 'https://sisper.sedetc.gob.pe/recursos/imagenes/usuario.jpg',
        personal: 'https://sisper.sedetc.gob.pe/recursos/imagenes/fotos/'
    }

    this.GetActionFoto= this.GetActionFoto.bind(this);
}


async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
              this.cargaView();
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  async cargaView() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${marcaciones}` + 'private/listarDisponibles')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
            lista.push(
              { item: data.item,
                nom_foto: data.nom_foto == null ? this.state.user : this.state.personal + data.nom_foto,
                cnum_dni: data.cnum_dni,
                nombre: data.nombre,
                ctxt_correo: data.ctxt_correo
            })
          });
        this.setState({usuarios: lista, isLoading: false})
      }else{
        this.setState({usuarios: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async ver(row){
    axios.get(sisperplus + 'perfil/detailperson?dni=' + row.cnum_dni, {
        headers: {Authorization: this.state.token}
      }).then((response) => {
        let datos = response.data.response;
        let lista = [];
        datos.map((data) => {
          lista.push(
            { Person: data.Person,
              nombre: data.nombre,
              fechaini: Calendar.calendario10(new Date().setDate(new Date().getDate() -7)),
              fechafin: moment(new Date()).format('YYYY-MM-DD')
          })
        });
        this.props.history.push('/asistencias', {response: lista});
      },(error) => {
        console.log(error)
      });
  }

  GetActionFoto(cell, row) {
    return (
        <div className="content" style={{cursor: 'pointer'}} data-tip="Ver Asistencia">
          <div className="author" onClick={() => { this.ver(row) }}>
            <a><img style={{width: 130, height: 170}} className="avatar border-gray" src={row.nom_foto} alt="..."/><ReactTooltip /></a>
          </div>
        </div>
    );
  }

  render() {
    const columns = [
        // {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "nom_foto", text: "USUARIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFoto},
        {dataField: "cnum_dni", text: "N° DE DNI", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "nombre", text: "NOMBRES Y APELLIDOS", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "ctxt_correo", text: "CORREO ELECTRÓNICO", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'}      ]
      const options = {
        custom: true,
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'Primera',
        prePageText: 'Anterior',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        showTotal: true,
        totalSize: this.state.usuarios.length
      };
      const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div>
          <div>
          {/* <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div> */}
            <div>
              <BootstrapTable
                striped
                hover
                keyField="item"
                data={ this.state.usuarios }
                columns={ columns }
                filter={ filterFactory() }
                // cellEdit={ cellEditFactory({ mode: 'click' }) }
                { ...paginationTableProps }
              />
            </div>
            <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
          </div>
        </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Lista de Usuarios</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(Usuarios);