import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col} from "react-bootstrap";
import {FormControl} from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { cec,paramCec,paramAulaVirtual} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import "./inscripcion.css";
import * as XLSX from 'xlsx';



const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Inscripciones extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
    path: 'inscripciones',
    usuario: localStorage.getItem('nickname'),
    token: 'Bearer ' + localStorage.getItem('ConcurToken'),
    token2: 'Bearer ' + localStorage.getItem('AccessToken'),
    id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      correo: "",
      nombres:"",
      numero: "",
      nota: "",
      flg_tipo:  {value: "asistente", label: "ASISTENTE"},
      tipos: [  
        {value: "asistente", label: "ASISTENTE"} ,
        {value: "docente", label: "DOCENTE"}, 
        {value: "moderador(a)", label: "MODERADOR(A)"},
        {value: "panelista", label: "PANELISTA"},
        {value: "ponente", label: "PONENTE"},
        {value: "organizador(a)", label: "ORGANIZADOR(A)"}
      ] ,
      curso:"",
      cursos:[],
      curso2:"",     
      isLoading: true,
      alumnos:[],
      nid:"" ,
      c: 0,
      cargar : false,
      a: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.generarCertificados= this.generarCertificados.bind(this);
    this.handleCorreo=this.handleCorreo.bind(this);
    this.handleNombres=this.handleNombres.bind(this);
    this.handleNumero=this.handleNumero.bind(this); 
    this.handleNota=this.handleNota.bind(this);  
  }
    handleCorreo(event) {this.setState({correo:event.target.value});}
    handleNombres(event) {this.setState({nombres:event.target.value});}
    handleNumero(event) {this.setState({numero:event.target.value});}
    handleNota(event) {this.setState({nota:event.target.value});}

    handleTipo = flg_tipo => {
        this.setState(
            {flg_tipo},
        );
    };

    handleCurso = curso => {
      this.setState(
          {curso},
      );
    };

    handleCurso2 = curso2 => {
      this.setState(
          {curso2},
      );
    };
 
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.listarCursos()
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listarCursos(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${cec}` + 'aula/listCursos')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.id,
              label: data.name,       
            })
        })
        this.setState({cursos: lista, curso: {value: lista[0].value, label: lista[0].label},curso2: {value: lista[0].value, label: lista[0].label}})
        this.listarInscripciones()
      }else{
        this.setState({cursos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarInscripciones(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${cec}` + 'aula/listInscripciones?nidcurso=' + this.state.curso2.value)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                nombres: data.nombres,
                correo: data.correo,
                numero: data.numero,
                nota: data.nota == null || data.nota == "--" ?  "" : data.nota , 
                calidad: data.calidad, 
                nid_curso: data.nid_curso,
                item: data.item,
                nid: data.id,
                flg_validador: data.flg_validador
            })
          })
         this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
    .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
    });
  }

  handleInputChange = (e) => {
      let file = e.target.files[0];
      let reader = new FileReader();
        reader.onload = async function (e) {
        let data = new Uint8Array(e.target.result);
        let workbook = XLSX.read(data, { type: "array" });
        let worksheet = workbook.Sheets[workbook.SheetNames[0]];
        this.state.alumnos = XLSX.utils.sheet_to_json(worksheet);        
    }.bind(this);
      reader.readAsArrayBuffer(file);
  };

  async save(){    
   if(this.state.alumnos.length > 0){
      this.cargar1 ()
      this.state.alumnos.map((data) => {
        this.guardar(1,data.DNI,data.NOMBRES,data.CORREO,data.CALIDAD,
            data.NOTA==undefined ? "" : data.NOTA)})
    }else{
      if(this.state.nombres != ''  && this.state.correo != '' && this.state.numero != ''){
          if(this.state.nid == ''){
            this.guardar(2)
          }else{
            this.update()
          }
      }else{
            this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
      }
    }
  }
  
  async guardar(param,dni,nombre,correo,tipo,nota){  
    this.setState({isLoading: true})
      let fromData = new FormData();
        fromData.append('dni', param == 2 ? this.state.numero : dni);
        fromData.append('nombre', param == 2 ? this.state.nombres : nombre);
        fromData.append('email', param == 2 ? this.state.correo : correo);
        fromData.append('tipo', param == 2 ?this.state.flg_tipo.value : tipo);
        fromData.append('nota', param == 2 ? this.state.nota : nota);
        fromData.append('nidcurso', this.state.curso.value); 
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${cec}` + 'aula/insertInscripciones', fromData) 
      .then((response) => {
         this.state.c ++
         this.setState({c : this.state.c ++})
        var datos = response.data
        if(datos.response != null){
          if(param == 2){
            this.limpiar();
            this.props.handleClick("tl", "info", "Ha sido registrado con éxito!", "pe-7s-smile")
            this.setState({isLoading: false})
          }else if(param==1){
           if(this.state.c == this.state.alumnos.length){
               this.limpiar2();}  
          }    
         }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")      
        }
        })
        .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
          }else{
              console.log(error);
              this.setState({isLoading: false})
          }
        });
      }

  update(){
    this.setState({isLoading: true})
      let fromData = new FormData();
      fromData.append('nid',this.state.nid);
      fromData.append('dni', this.state.numero);
      fromData.append('nombre', this.state.nombres.toUpperCase());
      fromData.append('email', this.state.correo);
      fromData.append('tipo', this.state.flg_tipo.value);
      fromData.append('nota', this.state.nota);
      fromData.append('nidcurso', this.state.curso.value); 
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${cec}` + 'aula/updateInscripciones', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.limpiar();
            this.props.handleClick("tl", "info", "El registro ha sido actualizado!", "pe-7s-smile")
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
          }else{
              console.log(error);
              this.setState({isLoading: false})
          }
        });
    }

    confirmarDelete = (data) => {
      confirmAlert({
        title: '¿Seguro que desea anular el registro?',
        message: 'Se anulará el registro seleccionado.',
        buttons: [
          {
            label: 'Aceptar',
            onClick: () => this.delete(data)
          },
          {
            label: 'Cancelar',
            onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
          }
        ]
      });
    };
  
    async delete(data){
      this.setState({isLoading: true})
      let fromData = new FormData();
      fromData.append('nid', data.nid);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${cec}` + 'aula/anularInscripciones', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          if(datos.response.estado == 1){
              this.listarInscripciones();
              this.props.handleClick("tl", "info", "El registro ha sido anulado!", "pe-7s-smile")
          }else{
              this.props.handleClick("tl", "error", "No se puede anular el registro!", "pe-7s-close")
              this.setState({isLoading: false})
          }
        }else{
        this.props.handleClick("tl", "error", "Hubo un problema con manejar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
    }



    confirmarDeleteMasivo = (data) => {
      confirmAlert({
        title: '¿Seguro que desea eliminar las incripciones?',
        message: 'Inscripciones de : ' +this.state.curso2.label.toLowerCase(),
        buttons: [
          {
            label: 'Aceptar',
            onClick: () => this.deleteMasivo()
          },
          {
            label: 'Cancelar',
            onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
          }
        ]
      });
    };
  
    async deleteMasivo(){
      this.setState({isLoading: true})
      let fromData = new FormData();
      fromData.append('nid', this.state.curso2.value);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${cec}` + 'aula/eliminarxCurso', fromData)
      .then((response) => {
        var datos = response.data
        console.log("acc")
        if(datos.response != null){
          if(datos.response.estado == 1){
              this.listarInscripciones();
              this.props.handleClick("tl", "info", "Los registros han sido eliminados!", "pe-7s-smile")
          }else{
              this.props.handleClick("tl", "error", "No se puede eliminar las inscripciones!", "pe-7s-close")
              this.setState({isLoading: false})
          }
        }else{
        this.props.handleClick("tl", "error", "Hubo un problema con manejar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
    }



  async limpiar(){
    this.listarInscripciones();
    this.cancelar();
  }

  async cancelar(){ 
    this.setState({ nid: "", correo: "", nombres: "", numero: "", nota: "", 
    flg_tipo : {value: "asistente", label: "ASISTENTE"} ,
    curso : {value: "47", label: "ANÁLISIS DE LA SENTENCIA RECAÍDA EN EL EXPEDIENTE N° 01272-2017-PA/TC"}, a:false})
  }

  async limpiar2(){
    this.listarInscripciones();
    this.cancelar2();
    this.props.handleClick("tl", "info", "Operacion exitosa!", "pe-7s-smile")
  }

  async cancelar2(){ 
    const file =document.querySelector('.file');
          file.value = '';
          this.setState({ curso : {value: "47", label: "ANÁLISIS DE LA SENTENCIA RECAÍDA EN EL EXPEDIENTE N° 01272-2017-PA/TC"},
          alumnos :[],
          c:0,
          isLoading:false,
          cargar: false});
  } 
  
  
  async obtener(data){
    this.setState({
      a:true,
      nid: data.nid,
      nombres: data.nombres,
      correo: data.correo,
      numero: data.numero,
      nota: data.nota,
      flg_tipo: {label: data.calidad.toUpperCase(), value: data.calidad},
      curso: {value: this.state.curso2.value , label : this.state.curso2.label}
   })
  }

  async ver2(){
    window.open("https://cec.sedetc.gob.pe/RECURSOS/INSCRIPCIONES/INSCRIPCION.xlsx" , "_blank")
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Anular Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  generarCertificados(cell, row) {
    if(row != undefined){
      return (
          <div>
             {row.flg_validador == "1" ? 
              <span data-tip="Generar Certificado" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle3(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x " style={{color:"#1C2464"}}></i>
                <i className="fa fa-id-card fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          : null}
          </div>
      );
    }else{}
  }

  async detalle3(data){
    paramAulaVirtual.inscripciones[0].data = data
    localStorage.setItem('aulaVirtual', JSON.stringify(paramAulaVirtual))
    this.props.history.push('/certificados');
  }
  
  async cargar1(){
    if(this.state.cargar == false){
      this.setState({cargar: true})
    }else{
      this.setState({cargar: false})
    }
  }

  render() {
    const columns = [
    {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "numero", text: "DNI",filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center',align : "center"},
    {dataField: "nombres", text: "NOMBRES", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:450}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "correo", text: "CORREO",filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white",width:250}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "calidad", text: "CALIDAD",filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align : "center"},
    {dataField: "nota", text: "NOTA",filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "certificados", text: "CERTIFICADOS",headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.generarCertificados},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>INSCRIPCIÓN</h4>
              <Tabs >
                <TabList >
                  <Tab >INDIVIDUAL</Tab>
                  {this.state.a == false ? 
                  <Tab >MASIVO</Tab> :
                  null }
                </TabList>
                <TabPanel >
                  <div className="row" style={{marginTop:25}}>
                      <div className="col-md-4" style={{marginBottom: 18}}>
                          <ControlLabel>NOMBRES Y APELLIDOS</ControlLabel>
                          <FormControl type="text" value={this.state.nombres} onChange={this.handleNombres}/>
                      </div>
                      <div className="col-md-8" style={{marginBottom: 18}}>
                          <ControlLabel>CURSOS</ControlLabel>
                          <Select value={this.state.curso} onChange={this.handleCurso} options={this.state.cursos}/>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>DNI</ControlLabel>
                          <FormControl type="number" value={this.state.numero} onChange={this.handleNumero}/>
                      </div>
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>CORREO</ControlLabel>
                          <FormControl type="text" value={this.state.correo} onChange={this.handleCorreo}/>
                      </div>
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>TIPO</ControlLabel>
                          <Select value={this.state.flg_tipo} onChange={this.handleTipo} options={this.state.tipos}/>
                      </div>
                      <div className="col-md-3" style={{marginBottom: 18}}>
                          <ControlLabel>NOTA <a> (OPCIONAL)</a></ControlLabel>
                          <FormControl type="number" value={this.state.nota} onChange={this.handleNota}/>
                      </div>         
                      <Button style={{width: 200, marginTop: 11, marginBottom: 20,marginRight:15}} bsStyle="danger" fill type="submit"pullRight onClick={() => { this.cancelar() }}>Cancelar</Button>
                      <Button style={{width: 200, marginTop: 10, marginBottom: 20,marginRight:10}} bsStyle="success" fill type="submit"pullRight onClick={() => { this.save() }}>Guardar</Button>
                    </div> 
                </TabPanel>
                {this.state.a == false ? 
                <TabPanel>
                    <div className="row">
                      <div className="col-md-4" style={{marginTop:20}}>
                        <ControlLabel>ARCHIVO EXCEL</ControlLabel>
                        <input accept=".xlsx" className="upload-box" style={{ marginBottom:20, cursor:"pointer"}} required  type="file" id="file" name="file" class="file"  onChange={this.handleInputChange} />
                      </div>
                      <div className="col-md-8" style={{marginBottom: 18,marginTop:20}}>
                        <ControlLabel>SELECCIONE EL CURSO</ControlLabel>
                        <Select value={this.state.curso} onChange={this.handleCurso} options={this.state.cursos}/>
                      </div>
                      <div className="col-md-12" style={{marginBottom: 18,marginTop:20}}>
                        <div>  
                        <Button style={{width: 300, marginTop: 35, marginBottom: 20, background:"green", border:"green"}} fill type="submit"  onClick={() => { this.ver2() }}>
                        <i className="fa fa-file-excel-o"></i>DESCARGAR PLANTILLA DE EXCEL</Button>
                        <Button style={{width: 200, marginTop: 35, marginBottom: 20}} bsStyle="danger" fill type="submit" pullRight onClick={() => { this.cancelar2() }}>Cancelar</Button>
                        <Button style={{width: 200, marginTop: 35, marginBottom: 20, marginRight:5}} bsStyle="success" fill type="submit" pullRight onClick={() => { this.save() }}>Guardar</Button>
                        </div>
                      </div>
                    </div>
                </TabPanel>
                : null }
              </Tabs>
              <hr style={{ background: "#337AB7",  height: "2px", border: "none", }}  />   
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>LISTA DE INSCRIPCIONES POR CURSO</h4>
              <div className="row">
                    {this.state.cargar ? (
                        <div className="col-md-12 text-center" >
                          <h2>Inscribiendo alumnos al curso : </h2>
                          <h5> {this.state.curso.label}  </h5> 
                          <h2> {this.state.c} / {this.state.alumnos.length}</h2>
                          <h5>Este proceso puede durar unos minutos. Espere por favor..</h5> 
                        </div>
                   ) : (
              <div>
                <div className="col-md-6" style={{marginBottom: 20}}>
                    <ControlLabel>CURSOS</ControlLabel>
                    <Select value={this.state.curso2} onChange={this.handleCurso2} options={this.state.cursos}/>
                </div> 
                <Button style={{width: 250, marginTop: 25, marginRight: 10, marginBottom: 20}} bsStyle="info" fill  type="submit" onClick={() => { this.listarInscripciones() }}>Buscar</Button>
                <Button style={{width: 250, marginTop: 25, marginRight: 10, marginBottom: 20}} bsStyle="danger" fill  type="submit" onClick={() => { this.confirmarDeleteMasivo() }}>Eliminar por Curso</Button>

              </div>
              )}
              </div>
            {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Inscripciones;