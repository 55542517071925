import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, fechasBusqueda} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactExport from 'react-export-excel'


const ExcelFile = ReactExport.ExcelFile; // indica el archivo excel que vamos a crear
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;//nombre de hoja de excel
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;//nombre de las columnas de excel

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ContadorTramite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: 'utilitariosStd',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      anio: '',     
      years: [],
      isLoading: true
    }
 
 }
  handleAnio = anio => {
    this.setState(
    { anio },
    );
  };
  
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.generarPeriodos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
  generarPeriodos(){
    let year =  2000;
    let cont = 26;
    let array = Array.from(new Array(cont),(val, index) => index + year);
    let cont2 = 0;
    let lista = [];
   if(fechasBusqueda.contador[0].nidperiodo != "" && fechasBusqueda.contador[1].periodo != ""){
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
      })
      this.setState({anio: {
        value: fechasBusqueda.contador[0].nidperiodo, 
        label: fechasBusqueda.contador[1].periodo
      }})
    }else{
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
          this.setState({anio: {value: cont2, label: data}})
        }
      })
    }
    this.setState({years: lista})
    this.listContadorTramites()
 }

  async listContadorTramites() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'documentario/listContadorTramites?anio=' + this.state.anio.label)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              ctxt_descripcion: data.ctxt_descripcion,
              derivados: data.derivados,
              finalizados: data.finalizados,
              generados: data.generados,
              ingresados:data.ingresados,
              pendientes:data.pendientes,
              idarea:data.idarea,
              item:data.item
            })
          })
          fechasBusqueda.contador[0].nidperiodo = this.state.anio.value
          fechasBusqueda.contador[1].periodo = this.state.anio.label
          localStorage.setItem('fechasBusqueda', JSON.stringify(fechasBusqueda))
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        
        this.setState({isLoading: false})
      }
    });
  }

  async regresar(){
    this.props.history.push('/utilitariosStd');
  }

  render() {
    const columns = [
    {dataField: "item", text: "ITEM",  headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "ctxt_descripcion", text: "ÁREA", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white",width:400}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "ingresados", text: "INGRESADOS", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "generados", text: "GENERADOS", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "pendientes", text: "PENDIENTES", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "derivados", text: "DERIVADOS", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "finalizados", text: "FINALIZADO", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>LISTADO DE TRÁMITES</h4>
                <div className="row">
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>PERIODO</ControlLabel>
                        <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                    </div>
                   
                        <ControlLabel></ControlLabel><br></br>
                        <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.listContadorTramites() }}>Buscar Trámites</Button>
                        
                    
                </div>
                {this.state.isLoading ? (
                    <div>
                        <div className="row text-center">
                        <h4>CARGANDO BANDEJA</h4>
                          <ClipLoader
                              css={override}
                              sizeUnit={"px"}
                              size={50}
                              color={'#123abc'}
                              loading={this.state.loading}
                          />
                            <p>Este proceso puede durar unos minutos</p>
                             <h5>Espere por favor...</h5>
                        </div>
                    </div>
                ) : (
                    <div>
                    <ExcelFile element={<Button style={{backgroundColor:"green" ,border:0,width: 250,height:40,marginBottom:5}}pullRight  fill type="submit">Exportar a Excel</Button>} filename= {"REPORTE DE TRÁMITES DEL AÑO "  + this.state.anio.label  }>
                      <ExcelSheet data={this.state.movimientos}  name ={"REPORTE - " + this.state.anio.label}>
                          <ExcelColumn label="ITEM" value="item"></ExcelColumn>
                          <ExcelColumn label="ÁREA" value="ctxt_descripcion" ></ExcelColumn>
                          <ExcelColumn label="INGRESADOS" value="ingresados" ></ExcelColumn>
                          <ExcelColumn label="GENERADOS" value="generados" ></ExcelColumn>
                          <ExcelColumn label="PENDIENTES" value="pendientes" ></ExcelColumn>
                          <ExcelColumn label="DERIVADOS" value="derivados" ></ExcelColumn>
                          <ExcelColumn label="FINALIZADO" value="finalizados" ></ExcelColumn>
                        </ExcelSheet>
                    </ExcelFile>
                        <PaginationProvider
                            pagination={
                            paginationFactory(options)
                            }>
                            { contentTable }
                        </PaginationProvider>
                        <Button style={{width: 250, marginTop: 18}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                    </div>
                )}
   
              </div>

            </Col>

          </Row>
        </Grid>
      </div>
    );
  }
}

export default ContadorTramite;