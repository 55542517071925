import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import ReactExport from 'react-export-excel';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ReporteAcumulado extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarDesMedicos',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      anio:"",
      years : [],
      mes: "",//{value: "00", label: "TODOS"},
      meses: [ 
        {value: "01", label: "ENERO"},
        {value: "02", label: "FEBRERO"},
        {value: "03", label: "MARZO"},
        {value: "04", label: "ABRIL"},
        {value: "05", label: "MAYO"},
        {value: "06", label: "JUNIO"},
        {value: "07", label: "JULIO"},
        {value: "08", label: "AGOSTO"},
        {value: "09", label: "SETIEMBRE"},
        {value: "10", label: "OCTUBRE"},
        {value: "11", label: "NOVIEMBRE"},
        {value: "12", label: "DICIEMBRE"},
      ],
      anioSeleccionado: "2024",
      mesSeleccionado: "",
      areaSeleccionado: "",
      trabajadorSeleccionado: "",
      areas: [],
      trabajadores: [],
      area: "",
      trabajador: "",
      multiDataSet: [],
      editable: false,
      descansos:[],
      descanso :"",
      descansoSeleccionado:""
    }  
  }

  handleAnio = anio =>{
    this.setState({anio});   
  }

    
 async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
             this.generarPeriodos();             
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  generarPeriodos(){
    let year =  2000;
    let cont = 26;
    let a = Array.from(new Array(cont),(val, index) => index + year);
    let array = a.reverse();
    let cont2 = 0;
    let lista = [];
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
          this.setState({anio: {value: cont2, label: data}})
        }
      })   
    this.setState({years: lista})
    this.listDescansoMedico()
 }

  async listDescansoMedico() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'descanso/reporteAcumuladoDM?anio=' + this.state.anio.label )
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              item:data.item,
              idperson :data.Person,              
              nombres:data.Name,
              dni : data.DocumentNumber,                            
              primero_20_dias: data.primero_20_dias,
              subsidio_incap_temporal: data.subsidio_incap_temporal,
              licencia_enf_sin_goce: data.licencia_enf_sin_goce,
              subsidio_maternidad: data.subsidio_maternidad,
              licencia_mater_sin_goce: data.licencia_mater_sin_goce           
            }) 
          }) 
          //this.setState({movimientos: lista, isLoading: false})     
          this.generarExcel(lista);   
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
    
  }

  
  regresar(){
    this.props.history.push("/administrarDesMedicos")
  }

  generarExcel(lista){
    let dataExcel = [];
    lista.map((data) => {
      dataExcel.push(
        [          
          {value: JSON.stringify(data.item), style: {font: {sz: "10.5", bold: true}, alignment: {horizontal: "center"}}},
          {value: data.dni == null ? '' : data.dni},
          {value: data.nombres == null ? '' : data.nombres},          
          {value: data.primero_20_dias == null ? '' : JSON.stringify(data.primero_20_dias)},
          {value: data.subsidio_incap_temporal == null ? '' : JSON.stringify(data.subsidio_incap_temporal)},          
          {value: data.licencia_enf_sin_goce == null ? '' : JSON.stringify(data.licencia_enf_sin_goce)},          
          {value: data.subsidio_maternidad == null ? '' : JSON.stringify(data.subsidio_maternidad)},
          {value: data.licencia_mater_sin_goce == null ? '' : JSON.stringify(data.licencia_mater_sin_goce)},
        ]
      )
    });
    const dataSet = [
      {
        xSteps: 5,
        columns: ["REPORTE ACUMULADO DE DESCANSOS MÉDICOS"],
        data: []
      },
      {
        columns: ["TRIBUNAL CONSTITUCIONAL DEL PERÚ"],   
        data: []       
      },     
      {
        columns: ["ITEM", "DNI", "APELLIDOS Y NOMBRES", "20 PRIMERO DÍAS DE INCAPACIDAD TEMPORAL", "SUBSIDIO POR INCAPACIDAD TEMPORAL", "LICENCIA POR ENFERMEDAD SIN GOCE DE HABER", "SUBSIDIO POR MATERNIDAD", "LICENCIA POR MATERNIDAD SIN GOCE DE HABER"],
        data: dataExcel
      },     
    ];
    this.setState({
      movimientos: lista, 
      multiDataSet: dataSet,      
      isLoading: false
    })
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white",width:50}, headerAlign: (column, colIndex) => 'center', align: 'center'},      
      {dataField: "dni", text: "DNI", filter : textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nombres", text: "APELLIDOS Y NOMBRES", filter : textFilter(),  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:450}, headerAlign: (column, colIndex) => 'center', align: 'center'},      
      {dataField: "primero_20_dias", text: "20 PRIMERO DÍAS DE INCAPACIDAD TEMPORAL", filter : textFilter(),  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},      
      {dataField: "subsidio_incap_temporal", text: "SUBSIDIO POR INCAPACIDAD TEMPORAL", filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "licencia_enf_sin_goce", text: "LICENCIA POR ENFERMEDAD SIN GOCE DE HABER", filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "subsidio_maternidad", text: "SUBSIDIO POR MATERNIDAD", filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},    
      {dataField: "licencia_mater_sin_goce", text: "LICENCIA POR MATERNIDAD SIN GOCE DE HABER", filter : textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},            
    ]
    
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div style={{textAlign: "center", fontWeight: "bold"}}>Se ha encontrado un total de {this.state.movimientos.length} registros</div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const { area, trabajador } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>DESCANSOS MÉDICOS</h4>
              <div className="row">
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>AÑO</ControlLabel>
                    <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years} />
                </div>
               
                <ControlLabel></ControlLabel><br></br>                
                  <Button style={{width: 200, marginRight: 10, marginBottom: 10, marginTop: 5}} bsStyle="info"  fill type="submit" onClick={() => { this.listDescansoMedico() }}>Buscar </Button> 
                  <ExcelFile filename="ReporteAcumuladoDM" element={<Button style={{width: 200, marginLeft: 10, marginBottom: 10, marginTop: 5}} bsStyle="success"  fill type="submit">Exportar a Excel</Button>} >
                    <ExcelSheet dataSet={this.state.multiDataSet} name="Reporte">                      
                    </ExcelSheet>
                  </ExcelFile>
                              
              </div>              
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
              <Button style={{width: 220, marginTop: 5, marginLeft: 10, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>

            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ReporteAcumulado;