import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col} from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import Calendar from "components/Calendar/Calendar.jsx";

import axios from 'axios';
import {cec} from 'store/Apis'
import 'react-tabs/style/react-tabs.css';
import moment from 'moment'
import 'react-accessible-accordion/dist/fancy-example.css';
import codYoutube  from 'assets/img/Youtube.png'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class EditarReplica extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'publicaciones',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      nidvideo:"",
      titulo : "", 
      descripcion:"",
      clave:"",
      fecha:  "",
      ruta: "",
      nidcanal: "",
      nidtipo: "",
      estado: 0,
      isLoading: true,
      show: true,
      imagen:false
    }
    this.handleDescripcion = this.handleDescripcion.bind(this);
    this.handleTitulo = this.handleTitulo.bind(this);
    this.handleFecha = this.handleFecha.bind(this);
    this.handleRuta = this.handleRuta.bind(this);
    this.handleClave = this.handleClave.bind(this);
  }
    handleDescripcion(event) {this.setState({descripcion: event.target.value});}
    handleTitulo(event) {this.setState({titulo: event.target.value});}
    handleFecha(event) {this.setState({fecha: event.target.value});}
    handleRuta(event) {this.setState({ruta: event.target.value});}
    handleClave(event) {this.setState({clave: event.target.value});}
   
 async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(JSON.parse(localStorage.getItem("aulaVirtual")).replica[0].data !== null){
     this.setState({
            titulo: JSON.parse(localStorage.getItem('aulaVirtual')).replica[0].data.ctxt_titulo,
            descripcion: JSON.parse(localStorage.getItem('aulaVirtual')).replica[0].data.ctxt_descripcion,
            ruta: JSON.parse(localStorage.getItem('aulaVirtual')).replica[0].data.ctxt_ruta,
            clave: JSON.parse(localStorage.getItem('aulaVirtual')).replica[0].data.ctxt_clave,
            nidtipo: "1",
            nidcanal: "37",
            fecha: Calendar.calendario11(JSON.parse(localStorage.getItem('aulaVirtual')).replica[0].data.dfec_publicacion),
            nidvideo: JSON.parse(localStorage.getItem('aulaVirtual')).replica[0].data.nid,
            isLoading:false
        });
     
    }else{
      this.props.history.push('/replica');
    }
  }

  async validar(){
   if(this.state.descripcion != '' && this.state.titulo != ''  && this.state.fecha != '' && this.state.ruta != '' && this.state.clave != ""){
       this.update();
    }else{
      this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close") 
    }
  }

  async update(){   
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('titulo', this.state.titulo);
    fromData.append('descripcion', this.state.descripcion);
    fromData.append('clave', this.state.clave)
    fromData.append('ruta', this.state.ruta)
    fromData.append('nidtipo', this.state.nidtipo);
    fromData.append('nidcanal', this.state.nidcanal);
    fromData.append('fechapublicacion',  this.state.fecha);
    fromData.append('usuario',this.state.usuario);
    fromData.append('nidvideo',this.state.nidvideo);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'contenido/editarPublicaciones', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Publicación actualizada correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }
  async regresar(){
    this.props.history.push('/replica');
  }

  async mostrarimagen(){
    if(this.state.imagen == true){
      this.setState({imagen: false})
    }else{
      this.setState({imagen: true})
    }
  }
  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>EDITAR PUBLICACIÓN</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                       <div className="row">
                            <div className="col-md-8" style={{marginBottom: 18}}>
                                <ControlLabel>TÍTULO</ControlLabel>
                                <FormControl type="text" value={this.state.titulo} onChange= {this.handleTitulo}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE PUBLICACIÓN</ControlLabel>
                                <FormControl type="date" value={this.state.fecha} onChange= {this.handleFecha}/>
                            </div>
                       </div>
                        <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>DESCRIPCIÓN</ControlLabel>
                                <FormControl componentClass="textarea" style={{  height:50, maxHeight: 50, minHeight: 50, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.descripcion} onChange= {this.handleDescripcion} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>RUTA (Ingresar solo el código de video)</ControlLabel>
                                <FormControl type="text" value={this.state.ruta} onChange= {this.handleRuta}/>
                                <i className="fa fa-exclamation-circle"  style={{ marginRight:-10}}>  </i> 
                                <Button style={{border:0}} onClick={() => { this.mostrarimagen() }}> ¿Dónde se encuentra el código del video?</Button>
                            </div>
                            <div className="col-md-9" style={{marginBottom: 18}}>
                                <ControlLabel>PALABRAS CLAVE</ControlLabel>
                                <FormControl type="text" value={this.state.clave} onChange= {this.handleClave}/>
                            </div>
                        </div>
                        <div className="row">
                            {this.state.imagen ? (
                              <div className="col-md-12" >
                                <img src={codYoutube} style={{width:1000,height:300}}></img>
                              </div>
                            ) : (null)}
                        </div>
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Actualización</Button>
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Actualizar Registro</Button>
                    </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarReplica;