import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import axios from 'axios';
import { gestor,  sisperplus } from 'store/Apis'
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import InputFiles from 'react-input-files';
import Select from 'react-select';
import moment from 'moment';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { validator } from 'store/Apis'
import "react-confirm-alert/src/react-confirm-alert.css";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class NuevaResolucion extends Component {

constructor(props) {
super(props);
  this.state = {
    path: 'resoluciones',
    dni: localStorage.getItem('dni'),
    usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
    id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
    token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
    fecha:  moment(new Date()).format('YYYY-MM-DD'),
    numRes:"",
    nomRes: "",
    type:"",
    file: [],
    fileName: '',
    isLoading: true,
    categoria:"",
    categorias:[],
    ver :"",
    enlace:""
  }
  this.handleNomRes= this.handleNomRes.bind(this);
  this.handleNumRes= this.handleNumRes.bind(this);
  this.handleFecha = this.handleFecha.bind(this);
  this.handleEnlace = this.handleEnlace.bind(this);
 }

  handleEnlace (event) {this.setState({enlace: event.target.value});}
  handleNomRes (event) {this.setState({nomRes: event.target.value});}
  handleNumRes (event) {this.setState({numRes: event.target.value});}
  handleFecha(event) {this.setState({fecha: event.target.value});}


  handleCategoria = categoria =>{
    this.setState(
    {categoria},
    );
};


  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
              this.listCategorias();  
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

 
  async listCategorias() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'resoluciones/listCategorias')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              label: data.nombre,   
              value: data.nid,
            })
          })
          this.setState({categorias: lista, categoria:{value: lista[0].value, label:lista[0].label}, isLoading: false })
          this.mostrar(1);
        }else{
          this.setState({categorias: []})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        
        this.setState({isLoading: false})
      }
    });
  }


  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], fileName: files[0].name, type: files[0].type});
    }
  }

  async cancelar(){
      this.props.history.push('/resoluciones'); 
  }

  guardar(){
    if(this.state.ver=="1" && this.state.fileName!="" || this.state.ver=="2" && this.state.enlace!= "" ){
      if(this.state.nomRes != '' && this.state.numRes != '' && this.state.fecha !=''){
          this.setState({isLoading: true})
          let fromData = new FormData();
          fromData.append('numRes', this.state.numRes);
          fromData.append('nomRes', this.state.nomRes);
          fromData.append('fecha', this.state.fecha);
          fromData.append('nomArchivo', this.state.ver =="1" ? this.state.fileName : this.state.enlace);
          fromData.append('categoria',this.state.categoria.value);
          fromData.append('usuario', this.state.usuario);  
          fromData.append('estado', this.state.ver);  
          axios.defaults.headers.common['Authorization'] = this.state.token
          axios.post(`${sisperplus}` + 'resoluciones/insertResoluciones', fromData)
          .then((response) => {
            if(this.state.ver=="1"){
                var datos = response.data.response 
                if(datos.archivo != null){
                  this.uploadArchivo(datos.archivo)     
                }else{
                  this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
                  this.setState({isLoading: false})
                }
            }else{
              this.props.handleClick("tl", "info", "Ha sido registrado con éxito!", "pe-7s-smile")
              this.cancelar(); 
            }})
          .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              this.setState({isLoading: false})
            }
          });
      }else{
        this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
      }
    }else{
      this.props.handleClick("tl", "warning", "Agregue el documento según la opción seleccionada!", "pe-7s-info")
    }
  }



  uploadArchivo(archivo){
      let anio = this.state.fecha.substr(0,4)
      let mes = this.state.fecha.substr(5,2)
      let fromData = new FormData();
      fromData.append('usuario', this.state.usuario);
      fromData.append('archivo_file', this.state.file);
      fromData.append('anio', anio);
      fromData.append('mes', mes);
      fromData.append('cnomArchivo', archivo);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${gestor}` + 'document/subirArchivoRes', fromData)
      .then((response) => {
        if(response.data.result == null){
          this.props.handleClick("tl", "info", "Ha sido registrado con éxito!", "pe-7s-smile")
          this.cancelar();             
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al guardar archivo!", "pe-7s-close")
          this.setState({isLoading2: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

 
  mostrar(param){
    if(param==1){
        this.setState({ver : "1"})
    }else {
        this.setState({ver : "2"})
    }
  }


 
  async limpiar(){
    this.setState({fileName: "", file: ""})
  }
  
  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
            <Card
                title="Registrar Resolución"
                content={
                  this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                  <div>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>N° RESOLUCIÓN</ControlLabel>
                            <FormControl type="text" value={this.state.numRes} onChange={this.handleNumRes} />
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>CATEGORÍA</ControlLabel>
                            <Select value={this.state.categoria} onChange={this.handleCategoria} options={this.state.categorias}/>
                        </div> 
                         <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA</ControlLabel>
                            <FormControl type="date" value={this.state.fecha}   onChange={this.handleFecha} />
                        </div>
                          <div className="col-md-12" style={{marginBottom: 18}}>
                            <ControlLabel>RESOLUCIÓN</ControlLabel>
                            <FormControl componentClass="textarea" style={{  height: 100, maxHeight: 100, minHeight: 100, width: "100%",
                             maxWidth:"100%", minWidth:"100%" }} value={this.state.nomRes} onChange={this.handleNomRes}  />
                        </div>
                    </div>
                                  
                  <div className="row" style={{marginTop: 18, marginBottom: 20}}>
                  <div className="col-md-3" style={{marginBottom: 18}} onChange={this.onChangeValue}>
                    <ControlLabel>SUBIR DOCUMENTO POR</ControlLabel><br></br>
                       <input type="radio" value="1" name="dependiente" style={{marginRight: 5}}  onChange= {(e) => this.mostrar(e.target.value)} defaultChecked/><label style={{marginRight: 10}}>ARCHIVO</label>
                       <input type="radio" value="2" name="dependiente" style={{marginRight: 5}} onChange= {(e) => this.mostrar(e.target.value)}/><label style={{marginRight: 10}}>ENLACE</label>
                  </div>
                  {this.state.ver=="1" ? 
                      <div className="col-md-6" style={{marginBottom: 18}}>
                            <ControlLabel>DOCUMENTO </ControlLabel>
                            <Alert bsStyle="warning">
                              <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.fileName}</span>
                            </Alert>
                            <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                                <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                            </Button>
                            {this.state.fileName != "" ? (
                                  <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar Archivo</Button>
                            ) : (  null )}
                      </div>
                      : 
                      <div className="col-md-9" style={{marginBottom: 18}}>
                        <ControlLabel>Ingrese el enlace del documento </ControlLabel>
                        <FormControl type="text" value={this.state.enlace} onChange={this.handleEnlace} />
                      </div>
                      }

                  </div>
                    <Button style={{width: 200, marginLeft: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Regresar</Button>
                    <Button style={{width: 200, marginLeft: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.guardar() }}>Guardar Registro</Button>

                  </div>
                 )}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default NuevaResolucion;