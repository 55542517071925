import React, { Component } from "react";
import Toggle from "react-toggle";

import auth0Client from 'login/Auth';
import axios from 'axios';
import { sisper, sisperplus } from 'store/Apis'

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class FixedPlugin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      classes: "dropdown show-dropdown open",
      bg_checked: null,
      bgImage: '',
      colores: [],
      isLoading: true,
      imagenes: [],
      isLoading2: true,
      nidColor: '',
      nidImagen: ''
    };
  }
  handleClick = () => {
    this.props.handleFixedClick();
  };
  onChangeClick = () => {
    this.setState({ bg_checked: !this.state.bg_checked });
    this.props.handleHasImage(!this.state.bg_checked);
    let visible
    if(!this.state.bg_checked){visible = 1 }else{visible = 0}
    let fromData = new FormData();
    fromData.append('color', this.state.nidColor);
    fromData.append('imagen', this.state.nidImagen);
    fromData.append('visible', visible);
    fromData.append('usuario', this.state.usuario);
    axios.post(`${sisperplus}` + 'personalizado/actualizar', fromData,                 
    {headers: {
      Authorization: this.state.token
    }}
    ).then((response) => {})
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      this.cargarColores();
    }
  }

  cargarColores() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'personalizado/listColores')
    .then((res) => {
      let lista = res.data.response
      this.setState({colores: lista})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${sisperplus}` + 'personalizado/listImagenes')
      .then((res) => {
        let lista2 = res.data.response
        this.setState({imagenes: lista2})
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'personalizado/listPersonalizado?usuario=' + this.state.usuario)
        .then((res) => {
            this.setState({
              bg_checked: res.data.response[0].flg_visible,
              nidColor: res.data.response[0].nid_color,
              nidImagen: res.data.response[0].nid_imagen,
              isLoading: false,
              isLoading2: false
            })
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({
              isLoading: false
            })
          }
        });
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({
            isLoading: false
          })
        }
      });
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({
          isLoading: false
        })
      }
    });
  }

  render() {
    return (
      <div className="fixed-plugin">
        <div id="fixedPluginClasses" className={this.props.fixedClasses}>
          <div onClick={this.handleClick}>
            <i className="fa fa-cog fa-2x" />
          </div>
          <ul className="dropdown-menu">
            <li className="header-title" style={{marginBottom: 15}}>PERSONALIZACIÓN</li>
            <li className="adjustments-line">
              <p className="pull-left">IMÁGENES DISPONIBLES</p>
              <div className="pull-right">
                <Toggle
                  // defaultChecked={this.state.bg_checked}
                  checked={this.state.bg_checked}
                  onChange={this.onChangeClick}
                />
              </div>
              <div className="clearfix" />
            </li>
            <li className="adjustments-line">
              <a className="switch-trigger">
                <p>COLORES</p>
                <div className="pull-right">
                {this.state.isLoading ? (
                    <ClipLoader
                      css={override}
                      sizeUnit={"px"}
                      size={50}
                      color={'#123abc'}
                      loading={this.state.loading}
                    />
                    ) : (
                    this.state.colores.map((data, i) => {
                      return (
                        data.color == 'black' ?(
                            <span
                              key={i}
                              className={
                                this.props.bgColor === data.color ? "badge filter active" : "badge filter"
                              }
                              data-color={data.color}
                              onClick={() => {
                                this.props.handleColorClick(data.color, data.nid_color, this.state.nidImagen, this.state.bg_checked);
                              }}
                            />
                            ):(
                            <span
                              key={i}
                              className={
                                this.props.bgColor === data.color ? "badge filter badge-" + data.color + " active" : "badge filter badge-" + data.color
                              }
                              data-color={data.color}
                              onClick={() => {
                                this.props.handleColorClick(data.color, data.nid_color, this.state.nidImagen, this.state.bg_checked);
                              }}
                            />
                        )
                    );
                }))}
                </div>
                <div className="clearfix" />
              </a>
            </li>
            <li className="header-title">Selecciona Imágenes</li>
            {this.state.isLoading2 ? (
                    <ClipLoader
                      css={override}
                      sizeUnit={"px"}
                      size={50}
                      color={'#123abc'}
                      loading={this.state.loading2}
                    />
                    ) : (
                    this.state.imagenes.map((data, i) => {
                      return (                 
                      <li  key={i} className={this.props.bgImage === require('assets/img/' + data.imagen) ? "active" : ""}>
                      {/* <li  key={i} className={this.state["bgImage"] === require('assets/img/' + data.imagen)  ? "active" : ""}>   */}
                        <a
                          className="img-holder switch-trigger"
                          onClick={() => {
                            this.setState({ bgImage: require('assets/img/' + data.imagen) });
                            this.props.handleImageClick(require('assets/img/' + data.imagen), this.state.nidColor, data.nid_imagen, this.state.bg_checked);
                          }}
                        >
                          <img src={require('assets/img/' + data.imagen)} alt="..." />
                        </a>
                      </li>
                    );
                }))}
          </ul>
        </div>
      </div>
    );
  }
}

export default FixedPlugin;
