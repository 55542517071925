import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";

class Mensajes extends Component {

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        // this.cargaView(); 
      }  
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <Card
                title="En Desarrollo"
                content={<div></div>}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Mensajes;