import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import auth0Client from 'login/Auth';
import axios from 'axios';
import { gestor, sisper, sisperplus, paramRRHH } from 'store/Apis'
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Calendar from "components/Calendar/Calendar.jsx";
import InputFiles from 'react-input-files';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { validator } from 'store/Apis'
import ReactTooltip from 'react-tooltip'
import { confirmAlert } from 'react-confirm-alert';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class EditarResolucion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'resoluciones',
      dni: localStorage.getItem('dni'),
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      numRes: "",
      nomRes: "",
      fecha: "",
      fileName: "",
      ruta_archivo: "",
      isLoading: true,
      isLoading2: true,
      isLoading3: false,
      editable: true,
      editable2: true,
      categoria: "",
      categorias: [],
      nid: "",
      archivos: [],
      v: "",
      enlace: "",
      cont:""
    }

    this.handleNomRes = this.handleNomRes.bind(this);
    this.handleNumRes = this.handleNumRes.bind(this);
    this.handleFecha = this.handleFecha.bind(this);
    this.handleEnlace = this.handleEnlace.bind(this);

  }

  handleNomRes(event) { this.setState({ nomRes: event.target.value }); }
  handleNumRes(event) { this.setState({ numRes: event.target.value }); }
  handleFecha(event) { this.setState({ fecha: event.target.value }); }
  handleEnlace(event) { this.setState({ enlace: event.target.value }); }

  handleCategoria = categoria => {
    this.setState({ categoria });
  };

  handleOptionChange = (event) => {
    this.setState({
      v: event.target.value // actualizar el valor seleccionado
    });
  }

  async componentDidMount() {
    if (localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null) {
      axios.get(validator + this.state.path + '/' + this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
      }).then((response) => {
        let permisos = response.data;
        if (permisos.codError != 1) {
          this.props.history.goBack()
        } else {
          this.validarLocal();
        }
      }, (error) => {
        if (auth0Client.isAuthenticated() === true) {
          auth0Client.signOut()
          this.props.history.replace('/');
        } else {
          this.props.history.goBack()
        }
      });
    } else {
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  async validarLocal() {
    if(JSON.parse(localStorage.getItem("recursoshumanos")).resoluciones[0].data !== null){
        this.setState({
            fecha : Calendar.calendario11(JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.fecha),
            numRes : JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.cnum_resolucion,
            nomRes : JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.cnom_resolucion,
            categoria: {value: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.categoria, label: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.nombreCat},
            fileName: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.cnom_archivo,
            enlace: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.ctxt_linkExterno,
            ruta_archivo: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.ruta_archivo,
            nid: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.nid,
            v: JSON.parse(localStorage.getItem('recursoshumanos')).resoluciones[0].data.cnom_archivo!=null ? "1" : "2",
            isLoading: false,
            isLoading2: false         
        })
        console.log(this.state.v)
        this.listCategorias();
    }else{
      this.props.history.push('/resoluciones');
    }
  }

  async listCategorias() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'resoluciones/listCategorias')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              label: data.nombre,   
              value: data.nid,
            })
          })
          this.setState({categorias: lista, categoria:{value: lista[0].value, label:lista[0].label} })
          this.cargaView()
        }else{
          this.setState({categorias: []})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        
        this.setState({isLoading: false})
      }
    });
   
  }

  async cargaView() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'resoluciones/listArchivos?nidRes=' + this.state.nid)
    .then((res) => {
        let lista = res.data.response;
        if(lista != null){
        this.setState({archivos: lista,cont:lista[0].cnom_archivo, isLoading: false})
        this.limpiar()
    }else{
        this.setState({archivos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
    }
    })
    .catch((error) => { 
    if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
    }else{
        console.log(error);
        this.setState({isLoading: false})
    }
    });
}

guardarR(){
    if(this.state.numRes != '' && this.state.nomRes != '' && this.state.fecha != ''){
        this.setState({isLoading3: true})
        let fromData = new FormData();
        fromData.append('nidRes', this.state.nid);
        fromData.append('numRes', this.state.numRes);
        fromData.append('nomRes', this.state.nomRes);
        fromData.append('fecha', this.state.fecha);
        fromData.append('categoria', this.state.categoria.value);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'resoluciones/updateResoluciones', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
             this.setState({isLoading3: false , editable: true })
             this.props.handleClick("tl", "success", "Actualizado correctamente!", "pe-7s-close")
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
            this.setState({isLoading3: false})
          }
        })
        .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

    guardarA(){
      if(this.state.v=="1" && this.state.fileName != ""  || this.state.v=="2" && this.state.enlace!=null ){
              this.setState({isLoading2: true})
              let fromData = new FormData();
              fromData.append('nidRes', this.state.nid);
              fromData.append('nomArchivo', this.state.v =="1" ? this.state.fileName : this.state.enlace);
              fromData.append('usuario', this.state.usuario);
              fromData.append('estado', this.state.v);
              axios.defaults.headers.common['Authorization'] = this.state.token
              axios.post(`${sisperplus}` + 'resoluciones/actualizarArchivo', fromData)
              .then((response) => {
                if(this.state.v=="1"){
                    var datos = response.data
                    if(datos.response != null){
                      this.uploadArchivo()
                    }else{
                      this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
                      this.setState({isLoading: false})
                    }
                }else{
                  this.props.handleClick("tl", "info", "Ha sido registrado con éxito!", "pe-7s-smile") 
                  this.setState({isLoading2: false, editable2: true})
                }
              })
              .catch((error) =>{ 
                if(auth0Client.isAuthenticated() === true){
                  auth0Client.signOut()
                  this.props.history.replace('/');
                }else{
                  console.log(error);
                  this.setState({isLoading: false})
                }
              });
         }else{
        this.props.handleClick("tl", "warning", "Agregue el documento según la opción seleccionada!", "pe-7s-info")
      }
    }

    

  uploadArchivo(){   
        let anio = this.state.fecha.substr(0,4)
        let mes = this.state.fecha.substr(8,2)
        let fromData = new FormData();
        fromData.append('usuario', this.state.usuario);
        fromData.append('archivo_file', this.state.file);
        fromData.append('anio', anio);
        fromData.append('mes', mes);
        fromData.append('cnomArchivo', this.state.fileName);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${gestor}` + 'document/subirArchivoRes', fromData)
        .then((response) => {
        if(response.data.result == null){
           this.props.handleClick("tl", "success", "El archivo se adjuntó correctamente!", "pe-7s-close")
           this.cargaView()
           this.setState({isLoading2: false , editable2: true })
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al guardar archivo!", "pe-7s-close")
            this.setState({isLoading2: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
        });
  }
 
  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea eliminar el archivo?',
      message: 'Se eliminará el archivo.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.remove(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  remove(data){
    this.setState({isLoading2: true})
    let fromData = new FormData();
    fromData.append('nidRes', this.state.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'resoluciones/anularArchivo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
         this.removerArchivo(data);
      }else{
         this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
         this.setState({isLoading2: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading2: false})
      }
    });
   }

   removerArchivo(archivo){   
    let fromData = new FormData();
    fromData.append('usuario', this.state.usuario);
    fromData.append('anio', archivo.anio);
    fromData.append('mes', archivo.mes);
    fromData.append('cnomArchivo', archivo.cnom_archivo);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${gestor}` + 'document/bajarArchivoRes', fromData)
    .then((response) => {
    if(response.data.result == null){
        this.cargaView()
        this.props.handleClick("tl", "info", "Tu archivo ha sido eliminado!", "pe-7s-smile")
        this.setState({isLoading2: false})
    }else{
        this.props.handleClick("tl", "error", "Hubo un problema al guardar archivo!", "pe-7s-close")
        this.setState({isLoading2: false})
    }
    })
    .catch((error) =>{ 
    if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
    }else{
        console.log(error);
        this.setState({isLoading: false})
    }
    });
  }

  async cancelar(){
   if(this.state.v==1 && this.state.value != null || this.state.v==2 && this.state.enlace != null || this.state.cont != null ){
         this.props.history.push('/resoluciones');  
     }else {
      this.props.handleClick("tl", "warning", "Tiene que adjuntar un documento!", "pe-7s-smile")
     }
         
  }

  subir = (files) => {
      if(files.length !== 0){
          this.setState({file: files[0], fileName: files[0].name, type: files[0].type});
      }
  }
 
  ver(data){
      window.open(data)
  }

  editar(enabled,param){
      if(enabled == true){
        if (param=="A"){
          this.setState({editable2: false})
        }else {
          this.setState({editable: false})
        }  
      }else{
         if (param=="R"){
            this.guardarR()
         }else {
            this.guardarA()
         }   
      }
  }

  async limpiar(){
    this.setState({fileName: "", file: ""})
  }

  async cancelar2(param){
    if(param =="1"){
       this.setState({editable: true})
    }else{
      this.setState({editable2: true})
    }
   
  }

render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                 content={ 
                  this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                 ) : (

                  <div>
                    <h4 className="title" style={{fontWeight: 200, marginBottom: 25}}>Datos de la Resolución</h4>

                    {this.state.isLoading3 ? (
                            <ClipLoader
                                css={override}
                                sizeUnit={"px"}
                                size={50}
                                color={'#123abc'}
                                loading={this.state.loading2}
                            />
                            ) : (   
                    <div className="row">
                      
                            <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>N° RESOLUCIÓN</ControlLabel>
                                <FormControl type="text" value={this.state.numRes} onChange={this.handleNumRes} disabled={this.state.editable}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>CATEGORÍA</ControlLabel>
                                <Select value={this.state.categoria} onChange={this.handleCategoria} options={this.state.categorias} isDisabled={this.state.editable}/>
                            </div> 
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA</ControlLabel>
                                <FormControl type="date" value={this.state.fecha}   onChange={this.handleFecha} disabled={this.state.editable} />
                            </div>
                            <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>RESOLUCIÓN</ControlLabel>
                                <FormControl componentClass="textarea" style={{  height: 100, maxHeight: 100, minHeight: 100, width: "100%",
                                maxWidth:"100%", minWidth:"100%" }} value={this.state.nomRes} onChange={this.handleNomRes} disabled={this.state.editable} />
                            </div>
                       
                       
                          {this.state.editable == true ? (
                            <Button style={{width: 200, marginLeft: 10, marginTop:25,marginRight:15}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.editar(this.state.editable) }}>Editar Resolución</Button>
                          ):(
                            <div>
                            <Button style={{width: 200, marginLeft: 10,marginRight:15,marginTop:25}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar(this.state.editable,"R") }}>Actualizar Registro</Button>
                            <Button style={{width: 200, marginLeft: 10,marginTop:25}} bsStyle="danger" pullRight  type="submit" onClick={() => { this.cancelar2("1") }}>Cancelar</Button>
                            </div>
                         )}
                       <hr></hr>
                    </div>)}
                    <hr  style={{ background: "#D6E6F1", height: "2px",border: "none" }} />
                    <h4 className="title" style={{fontWeight: 200, marginBottom: 25}}>Documento</h4>
                    <div className="row" style={{marginTop: 18, marginBottom: 20}}>        
                         <div className="col-md-2" style={{marginBottom: 18}} onChange={this.onChangeValue}>
                                <ControlLabel>SUBIR DOCUMENTO POR</ControlLabel><br></br>
                                <input type="radio" value="1"  checked={this.state.v === "1"} style={{marginRight: 5}} onChange={this.handleOptionChange}/><label style={{marginRight: 10}}>ARCHIVO</label>
                                <input type="radio" value="2"  checked={this.state.v === "2"} style={{marginRight: 5}} onChange={this.handleOptionChange}/><label style={{marginRight: 10}}>ENLACE</label>
                            </div> 
                        <div className="col-md-8" style={{marginBottom: 18}}> 
                            {this.state.isLoading2 ? (
                            <ClipLoader
                                css={override}
                                sizeUnit={"px"}
                                size={50}
                                color={'#123abc'}
                                loading={this.state.loading2}
                            />
                            ) : (      
                          <div>                          
                           { this.state.v =="1" &&  this.state.cont!= null? (                             
                            <ul className="todo-list">
                            {this.state.archivos.map((data,index) =>
                                <li key={index}>   
                                                           
                                   <div className="price">
                                        <h3>{data.item}</h3>
                                    </div>
                                    <div className="name">          
                                        <span className="item-name">{data.cnom_archivo}</span>                    
                                    </div>
                                       
                                    <span  className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20, marginRight: 5}} onClick={() => { this.ver(data.ruta_archivo) }}>
                                        <i className="fa fa-circle fa-stack-2x text-danger"></i>
                                        <i className="fa fa-file-pdf-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                    </span>
                                    <span  className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(data) }}>
                                        <i className="fa fa-circle fa-stack-2x"></i>
                                        <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                    </span>
                                </li>
                                )}</ul>): (null)}
                          </div> )}
                          {this.state.fileName!=null && this.state.v =="1" ?  
                          <div>
                            <ControlLabel>DOCUMENTO</ControlLabel>
                            <Alert bsStyle="warning">
                                <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.fileName}</span>
                            </Alert>
                            {this.state.editable2 == false ? (
                            <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit" >
                                <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                            </Button>
                            ): (null)}
                          </div>
                          
                          :(
                            <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>Ingrese el enlace del documento </ControlLabel>
                              <FormControl type="text" value={this.state.enlace} onChange={this.handleEnlace} disabled={this.state.editable2} />
                            </div>
                          )}
                        </div>
                        <div className="col-md-12">
                           {this.state.editable2 == true ? (
                               <Button style={{width: 200, marginLeft: 10,marginTop:25,}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.editar(this.state.editable2,"A") }}>Editar Documento</Button>
                            ):(
                              <div>
                              <Button style={{width: 200, marginLeft: 10,marginTop:25}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar(this.state.editable2,"A") }}>Guardar Documento</Button>
                              <Button style={{width: 200, marginLeft: 10,marginTop:25, marginRight:5}} bsStyle="danger" pullRight  type="submit" onClick={() => { this.cancelar2("2") }}>Cancelar</Button>
                          </div>
                          )}
                        </div>
                    </div>
                      <Button style={{width: 200, marginTop:20}} bsStyle="danger"  fill type="submit" onClick={() => { this.cancelar() }}>Regresar</Button>
                  </div>
                 )}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarResolucion;