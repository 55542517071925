import React, { Component } from "react";
import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';

import { Link } from 'react-router-dom';

import auth0Client from 'login/Auth';

class AdminNavbarLinks extends Component {

  state = {
    usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0
  }

  goSalir(){
    auth0Client.signOut();
  }

  verEnlace(enlace){
    window.open(enlace, '_blank');
  }

  render() {
    return (
      /*<div>
        <Nav pullRight style={{letterSpacing: 1}}>
          <NavItem eventKey={3} componentClass={Link} href="/perfil" to="/perfil" style={{textTransform: 'uppercase'}}>{this.state.usuario}</NavItem>
          <NavItem eventKey={3} href="/" onClick={this.goSalir}>SALIR</NavItem>

          <MenuItem componentClass={Link} href="/noticias" to="/noticias">NOTICIAS</MenuItem>
          <MenuItem componentClass={Link} href="/mensajes" to="/mensajes">MENSAJES</MenuItem>
          <MenuItem componentClass={Link} href="/calendario" to="/calendario">CALENDARIO</MenuItem>

        </Nav>
      </div>*/
      <div>
        <Nav pullRight style={{letterSpacing: 1}}>
          <NavDropdown
            eventKey={2}
            title="LIMPIADOR"
            id="basic-nav-dropdown-right"
            style={{textTransform: 'uppercase'}}>
            <MenuItem style={{marginTop: 10}} componentClass={Link} onClick={() => { this.verEnlace("https://acortar.link/mvH4gN") }}>PORTAL WEB</MenuItem>
            <MenuItem divider />
            <MenuItem style={{marginBottom: 10}}  componentClass={Link} onClick={() => { this.verEnlace("https://acortar.link/Gx0eIx") }}>APLICACIONES</MenuItem>
          </NavDropdown>
          <NavDropdown
            eventKey={2}
            title={this.state.usuario}
            id="basic-nav-dropdown-right"
            style={{textTransform: 'uppercase'}}>
            <MenuItem style={{marginTop: 10}} componentClass={Link} href="/perfil" to="/perfil">PERFIL</MenuItem>
            <MenuItem divider />
            <MenuItem style={{marginBottom: 10}}  componentClass={Link} href="/configuracion" to="/configuracion">CONFIGURACIÓN</MenuItem>
          </NavDropdown>
          <NavItem eventKey={3} href="/" onClick={this.goSalir}>SALIR</NavItem>
        </Nav>
      </div>
    );
  }
}

export default AdminNavbarLinks;
