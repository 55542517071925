import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { confirmAlert } from 'react-confirm-alert';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Subareas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarAreas',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      nidrep:"",
      cantidad:"",
      nivel:{value: "0", label: "Seleccione el nivel"},
      // niveles: [
      //   {value: "1", label: "NIVEL 1"},
      //   {value: "2", label: "NIVEL 2"},
      //   {value: "3", label: "NIVEL 3"}
      // ] ,
      area:"",
      areas:[],
      nombrearea:"",

      list: [],
      line:""
  }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.handleNivel=this.handleNivel.bind(this)
 
 }

    handleNivel(event) {this.setState({nivel: event.target.value});}
    handleNivel = nivel => {
        this.setState(
          { nivel }
          );
        };

    handleArea = area => {
        this.setState(
           { area }
           );
        };


  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
             this.validarLocal();                          
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(JSON.parse(localStorage.getItem("recursoshumanos")).areaAdm[0].data !== null){
     this.setState({
        nidrep: JSON.parse(localStorage.getItem('recursoshumanos')).areaAdm[0].data.nid, 
        nombrearea: JSON.parse(localStorage.getItem('recursoshumanos')).areaAdm[0].data.descripcion,          
        });
        this.listPermisos();
        }else{
      this.props.history.push('/adminAreas');
    }
  }

 
  async listPermisos() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'area/listRepUnitHierarchyAdm?nidreplication=' + this.state.nidrep +'&param=2')
    .then((res) => {
        let lista = [];
        this.state.list = res.data.response;
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                line:data.line,
                nidrepiunit:data.repunit,
                name: data.repunitname,
                nivel: data.nivel,
                namenivel: data.namenivel,
                fecha:Calendar.calendarioA(data.XLastDate),
                firstperson :data.person1,
                namefirstperson :data.personname1,
                secondperson :data.person2,
                namesecondperson :data.personname2,
                item: data.item
             }) 
          }) 
          this.setState({movimientos: lista,cantidad:lista.length, list : lista})
          this.listReplicationArea();
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }


  async listReplicationArea(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'area/listArea')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;   
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
            { value: data.ReplicationUnit,          
              label: data.Description,
            })  
          })     
          this.setState({areas: lista, area: {value: lista[0].value, label: lista[0].label}, isLoading: false})               
        }else{
          this.setState({areas: []})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")       
        }
      })
    .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
    });
 }

 async save(){
   if( this.state.line == '' || this.state.line == null || this.state.line ==  undefined ){
     this.guardar()    
    }else{
      this.update()
    } 
}

guardar(){
  this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidreplication', this.state.nidrep);
    fromData.append('nivel', "");
    fromData.append('idarea', this.state.area.value);
    fromData.append('area', this.state.area.label);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'area/insertRepUnitHierarchy', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar(1);
          this.props.handleClick("tl", "info", "Se ha registrado satisfactoriamente!", "pe-7s-smile")
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
      });
}

update(){
  this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidreplication', this.state.nidrep);
    fromData.append('nivel', "");
    fromData.append('idarea', this.state.area.value);
    fromData.append('area', this.state.area.label);
    fromData.append('usuario', this.state.usuario);
    fromData.append('line', this.state.line);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'area/updateRepUnitHierarchy', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar(2);
          this.props.handleClick("tl", "info", "El registro ha sido actualizado!", "pe-7s-smile")
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
      });
  }

  
async limpiar(param){
  if(param == 1 ){
    this.listPermisos();
    this.cancelar(); 
  }else if (param ==2) {
    this.listPermisos();
    this.cancelar(); 
  }else {
    this.setState({ 
    nivel:{value: "0", label: "Seleccione el nivel"}
    })
    this.listPermisos()
  }}


async cancelar(param){
  if(param == 1){
        this.setState({ 
          nivel:{value: "0", label: "Seleccione el nivel"},
          area :{value: this.state.areas[0].value, label: this.state.areas[0].label},
          line:""})
   }else{
      this.setState({ 
        nivel:{value: "0", label: "Seleccione el nivel"},  
        area :{value: this.state.areas[0].value, label: this.state.areas[0].label},
        line:""
 
    })
   }

}

  confirmarDelete = (data) => {
    confirmAlert({
      title: "¿Seguro que desea anular el registro?",
      message: "Se anulará el registro seleccionado.",
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async delete(data){
    console.log(data)
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidreplication', this.state.nidrep);
    fromData.append('nivel', data.line);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'area/deleteRepUnitHierarchy', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.limpiar(3)        
        this.props.handleClick("tl", "info", "El registro ha sido eliminado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
 
  async obtener(data){
  this.setState({
       area :{value: data.nidrepiunit, label: data.name},
       nivel: {value: data.nivel, label: data.namenivel},
       nid: data.nid,
       line: data.line
      })
    }


  GetActionFormat(cell, row) {
   if(row != undefined){
    return (
        <div>
          {/* <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x text-primary"></i>
              <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span> */}
            <span  data-tip="Eliminar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x"></i>
              <i className="fa fa-trash fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span> 
        </div>
    );
  }else{}
}


async regresar(){
  this.props.history.push("/adminAreas")
}

filter (){
  let optionsArray = ["1", "2", "3"]; 
  let disabledArray = this.state.list.map(function(array1) {
              return (array1.nivel).toString();
      }); 
  
  optionsArray = optionsArray.map(val => disabledArray.includes(val) 
  ? { value: val, label : "NIVEL " +val , isDisabled: true } 
  : { value: val, label : "NIVEL " +val , isDisabled: false })

      return optionsArray;
}


  render() {
    
    const columns = [
      {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white",width:50}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "namenivel", text: "NIVEL", filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white",width:250}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "name", text: "ÁREA", filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "namefirstperson", text: "JEFE PRINCIPAL",  filter: textFilter(),sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "namesecondperson", text: "JEFE SECUNDARIO", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white", width:200}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Subáreas : <a style={{fontWeight: "bold", color: "black",fontSize: 20}}>{this.state.nombrearea}</a></h4>
                <div className="row" style={{marginBottom: 10}}>
                    <div className="col-md-4" style={{marginBottom: 18}}>
                        <ControlLabel>ÁREA</ControlLabel>
                        <Select value={this.state.area} onChange= {this.handleArea} options={this.state.areas}/>
                    </div>
                    <Button style={{width: 200, marginBottom: 10, marginLeft: 10}} bsStyle="danger" pullRight type="submit" onClick={() => { this.cancelar(1) }}>CANCELAR </Button>
                    <Button style={{width: 200, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.save() }}>GUARDAR</Button>
               
                </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
                  <Button style={{width: 250, marginTop: 10, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Subareas;