import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
    body: {
      paddingHorizontal: 35,
      flexGrow: 1,
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    tableRow: { 
      margin: "auto", 
      flexDirection: "row",
      marginTop: 25, //Adecuar a Impresora
      marginBottom: 75 //Adecuar a Impresora
    },   
    tableCol: { 
      width: "15%", 
    },  
    tableCol2: { 
      width: "60%", 
    }, 
    tableCol3: { 
      width: "25%", 
    },  
    tableCell: { 
      fontSize: 10,
      textAlign: 'center',
      marginBottom: 15
    },
    tableCell2: { 
      fontSize: 10,
      textAlign: 'left'
    },
    tableCell3: { 
      fontSize: 10,
      textAlign: 'right'
    },
    tableCell4: { 
      marginTop: 20, 
      fontSize: 10,
      textAlign: 'center',
      marginBottom: 15
    },
    tableCell5: { 
      marginTop: 20, 
      fontSize: 10,
      textAlign: 'center',
      marginBottom: 15
    },
    tableCell6: { 
      fontSize: 10,
      textAlign: 'right',
      marginBottom: 15
    },
    tableCell7: { 
      marginTop: 5, 
      fontSize: 10,
      textAlign: 'center',
      marginBottom: 15
    },
    row: {
      flexDirection: 'row',
      marginBottom: 30,
    },
});

export function reporteCheques(movimientos) {
    return (
        <Document>
        <Page size="A4" orientation="portrait" style={styles.body}>
          <View style={styles.table}> 
                {movimientos.map((data, i) => {
                    return (
                        <View style={styles.tableRow}> 
                          <View style={styles.tableCol3}> 
                              <Text style={styles.tableCell}>{data.fecha.substring(0,2) + "      " + data.fecha.substring(3,6) + "      " + data.fecha.substring(6,10)}</Text> 
                              <Text style={styles.tableCell4}>{data.liquido.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                              <Text style={styles.tableCell7}>C/P   :   {data.numero}</Text> 
                          </View> 
                          <View style={styles.tableCol2}> 
                              <Text style={styles.tableCell3}>{data.fecha2.substring(0,5) + "       " + data.fecha2.substring(6,8) + "      " + data.fecha2.substring(9,11) + "      " + data.fecha2.substring(12,16)}</Text> 
                              <Text style={styles.tableCell5}>{data.nombre}</Text> 
                              <Text style={styles.tableCell2}>{data.liquido2}</Text> 
                          </View> 
                          <View style={styles.tableCol}> 
                              <Text style={styles.tableCell6}>{data.liquido.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                          </View> 
                        </View> 
                    );
                 })}
            </View>
          {/* <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed /> */}
        </Page>
      </Document>
    );
}