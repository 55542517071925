import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";

import moment from 'moment'
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import Select from 'react-select';
import { paramTesoreria, tesoreria } from 'store/Apis'

import { saveAs } from 'file-saver';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ConsultaComprobantes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'comprobantes',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      movimientos: [],
      fechaini: moment(moment().startOf('week').toDate()).format('YYYY-MM-DD'),
      fechafin: moment(moment().endOf('week').toDate()).format('YYYY-MM-DD'),
      periodo: "",
      numero: "",
      beneficiario: "",
      concepto: "",
      years: [],
      isLoading: true,
      busqueda: false
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);
    this.GetActionFormat3= this.GetActionFormat3.bind(this);

    this.handleFechaini = this.handleFechaini.bind(this);
    this.handleFechafin = this.handleFechafin.bind(this);
    this.handlePeriodo = this.handlePeriodo.bind(this);
    this.handleNumero = this.handleNumero.bind(this);
    this.handleBeneficiario = this.handleBeneficiario.bind(this);
    this.handleConcepto = this.handleConcepto.bind(this);

  }

  handleFechaini(event) {
    this.setState({fechaini: event.target.value});
  }

  handleFechafin(event) {
    this.setState({fechafin: event.target.value});
  }

  handlePeriodo = periodo => {
    this.setState(
    { periodo },
    );
  };

  handleNumero(event) {
    this.setState({numero: event.target.value});
  }

  handleBeneficiario(event) {
    this.setState({beneficiario: event.target.value});
  }

  handleConcepto(event) {
    this.setState({concepto: event.target.value});
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              if(localStorage.getItem("fechasmovicomp") !== null){
                  this.setState({
                    fechaini: JSON.parse(localStorage.getItem('fechasmovicomp')).fechaini,
                    fechafin: JSON.parse(localStorage.getItem('fechasmovicomp')).fechafin
                  });
              }
              this.generarPeriodos(0);
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  generarPeriodos(param){
    let year =  2000;
    let cont = 26;
    let array = Array.from(new Array(cont),(val, index) => index + year);
    let cont2 = 0;
    let lista = [];
    array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
            this.setState({
              periodo: {value: cont2, label: data}
            })
        }
    })
    this.setState({years: lista})
    if(paramTesoreria.comprobantes[0].fechaini == this.state.fechaini && paramTesoreria.comprobantes[1].fechafin == this.state.fechafin){
      let fechas = {
        "fechaini": this.state.fechaini,
        "fechafin": this.state.fechafin
      }
      localStorage.setItem('fechasmovicomp', JSON.stringify(fechas))
      this.setState({movimientos: paramTesoreria.comprobantes[2].data, isLoading: false})
    }else{
      this.listarMovimientos(param);
    }
  }

  async listarMovimientos(param) {
      if(param == 1){
        this.setState({isLoading: true})
      }
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${tesoreria}` + 'comprobantes/listMovimientos?tipo=0&fechaini=' + this.state.fechaini + '&fechafin=' + this.state.fechafin)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { 
                item: data.item,
                nidmovimiento: data.nid,
                numero: data.cnum_numero, 
                cheque: data.ctxt_cheque_carta,
                registro: data.cnum_siaf,
                orden: data.cnum_orden,
                documento: data.cnum_documento,
                nombre: data.ctxt_nombre,
                concepto: data.ctxt_concepto,
                liquido: data.cnum_liquido != null ? 'S/.    ' + data.cnum_liquido.toFixed(2) : 'S/. 0.00', 
                fecha: Calendar.calendario12(data.dfec_ingreso),
                fecha2: data.dfec_ingreso,
                activo: data.item == 1 ? '1' : '0',
                documentox: data.documento
            })
          })
          let fechas = {
            "fechaini": this.state.fechaini,
            "fechafin": this.state.fechafin
          }
          localStorage.setItem('fechasmovicomp', JSON.stringify(fechas))
          paramTesoreria.comprobantes[0].fechaini = fechas.fechaini
          paramTesoreria.comprobantes[1].fechafin = fechas.fechafin
          paramTesoreria.comprobantes[2].data = lista
          localStorage.setItem('data', JSON.stringify(paramTesoreria))
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  validaCadena(numero){
    if(numero.length == 1){
      return "0000" + numero
    }else if(numero.length == 2){
      return "000" + numero
    }else if(numero.length == 3){
      return "00" + numero
    }else if(numero.length == 4){
      return "0" + numero
    }else if(numero.length == 5){
      return numero
    }else{
      return "00000"
    }
  }

  async buscarMovimiento() {
    if(this.state.numero != "" && this.validaCadena(this.state.numero) != "00000" || this.state.beneficiario != "" || this.state.concepto != ""){
      this.setState({isLoading: true})
      let fromData = new FormData();
      fromData.append('anio', this.state.periodo.label);
      fromData.append('numero',  this.state.numero == "" ? "--" : this.validaCadena(this.state.numero));
      fromData.append('beneficiario', this.state.beneficiario == "" ? "--" : this.state.beneficiario);
      fromData.append('concepto', this.state.concepto == "" ? "--" : this.state.concepto);
      fromData.append('tipo', '0');
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'comprobantes/buscarMovimiento', fromData)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { 
                item: data.item,
                nidmovimiento: data.nid,
                numero: data.cnum_numero, 
                cheque: data.ctxt_cheque_carta,
                registro: data.cnum_siaf,
                orden: data.cnum_orden,
                documento: data.cnum_documento,
                nombre: data.ctxt_nombre,
                concepto: data.ctxt_concepto,
                liquido: data.cnum_liquido != null ? 'S/.    ' + data.cnum_liquido.toFixed(2) : 'S/. 0.00', 
                fecha: Calendar.calendario12(data.dfec_ingreso),
                fecha2: data.dfec_ingreso,
                activo: data.item == 1 ? '1' : '0',
                documentox: data.documento
            })
          })
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async detalle(data){
    let data2 = {
      "nidmovimiento": data.nidmovimiento
    }
    localStorage.setItem('movimientocomp', JSON.stringify(data2))
    this.props.history.push('/editarcomprobante');
  }

  async detalle2(){
    this.props.history.push('/nuevocomprobante');
  }

  async detalle3(data, param){
    let data2 = {
      "nidmovimiento": data.nidmovimiento,
      "tiporeporte": param,
      "origen": 'C'
    }
    localStorage.setItem('movimientocomp', JSON.stringify(data2))
    this.props.history.push('/generarreporte');
  }

  async detalle4(data){
    let numerocaja = data.cheque
    numerocaja = numerocaja.replace("CHEQUE N° ", "")
    numerocaja = numerocaja.replace("CO. N° ", "")
    numerocaja = numerocaja.replace("O-S/CH. N° ", "")
    numerocaja = numerocaja.replace("CE. N° ", "")
    numerocaja = numerocaja.replace("OPE. N° ", "")
    numerocaja = numerocaja.replace("CCI. N° ", "")
    numerocaja = numerocaja.replace("PE. AFP N° ", "")
    let data2 = {
      "nidmovimiento": data.nidmovimiento,
      "fecha": Calendar.calendario12D(data.fecha2),
      "liquido": data.liquido != 'S/. 0.00' ? data.liquido.replace("S/.    ", "") : data.liquido.replace("S/. ", ""),
      "numero": data.numero,
      "nombre": data.nombre,
      "cheque": numerocaja
    }
    let cheque = data2.cheque + '|' + data2.numero + '|' + data2.fecha + '|' + data2.nombre + '|' + data2.liquido;               
    const blob = new Blob([cheque], { type: 'text/plain; charset=utf-8'})
    saveAs(blob, 'Cheques.txt')
    setTimeout(
      function() {
        window.open("generador2://")
      }
      .bind(this),
      1000
    );
    // localStorage.setItem('movimientocomp', JSON.stringify(data2))
    // this.props.history.push('/generarcheque')
  }

  async detalle5(data){
    let data2 = {
      "nidmovimiento": data.nidmovimiento,
      "numero": data.numero
    }
    localStorage.setItem('movimientocomp', JSON.stringify(data2))
    this.props.history.push('/consultadocumentos');
  }

  async detalle6(){
    if(this.state.busqueda == true){
      this.setState({busqueda: false})
    }else{
      this.setState({busqueda: true})
    }
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Comprobante" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              {/* <span data-tip="Generar Cheque" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle3(row, 'CHEQUE DE PAGO') }}> */}
              <span data-tip="Generar Cheque" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle4(row)}}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-info"></i>
                <i className="fa fa-clipboard fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Generar Comprobante" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle3(row, 'COMPROBANTE DE PAGO') }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-warning"></i>
                <i className="fa fa-file fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Ver Documentación" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle5(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                <i className="fa fa-file-pdf-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  GetActionFormat2(cell, row) {
    if(row != undefined){
      return (
        <div>
            {row.documentox == null || row.documentox == ''? (
            <p style={{fontSize: 14}}>SIN DOCUMENTO</p>) : (
            <i style={{color: "red", marginLeft: -20, cursor: "pointer"}} class="fa fa-file-pdf-o fa-3x" aria-hidden="true" onClick={() => { this.mostrar(row.documentox) }}></i>
            )}
        </div>
      );
    }else{}
  }

  GetActionFormat3(cell, row) {
    if(row != undefined){
      return (
        <div>
             <p style={{fontSize: 14}}>N° REGISTRO SIAF: <a>{row.registro}</a></p> 
             <p style={{fontSize: 14}}>N° DE ORDEN DE SERVICIO: <a>{row.orden}</a></p> 
             <p style={{fontSize: 14}}>N° DE CHEQUE: <a>{row.cheque}</a></p> 
             <p style={{fontSize: 14}}>N° DE DOCUMENTO: <a>{row.documento}</a></p> 
        </div>
      );
    }else{}
  }

  mostrar(ruta){
    window.open(ruta, "_blank")
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width: 100}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "numero", text: "NÚMERO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "registro", text: "SIAF", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "orden", text: "ORDEN", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "cheque", text: "GIRO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "documento", text: "N° DE DOCUMENTO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nombre", text: "BENEFICIARIO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width: 200}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "concepto", text: "CONCEPTO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width: 400}, headerAlign: (column, colIndex) => 'center', align: 'justify'},
      {dataField: "liquido", text: "TOTAL", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha", text: "FECHA", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "detail3", text: "DETALLE", filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat3},
      {dataField: "detail2", text: "DOCUMENTO", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Comprobantes de Pago</h4>
              <h5 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Búsqueda por Rango de Fechas</h5>
              <div className="row">
                <div className="col-md-2" style={{marginBottom: 18}}>
                    <ControlLabel>FECHA DE INICIO</ControlLabel>
                    <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaini}/>
                </div>
                <div className="col-md-2" style={{marginBottom: 18}}>
                    <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                    <FormControl type="date" value={this.state.fechafin} onChange= {this.handleFechafin}/>
                </div>
                <div className="col-md-5" style={{marginBottom: 18}}>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 200, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.listarMovimientos(1) }}>Buscar Movimientos</Button>
                    <Button style={{width: 200, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="warning" fill type="submit" onClick={() => { this.detalle6() }}>Buscador Rápido</Button>
                    <Button style={{width: 200, marginTop: 5, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.detalle2() }}>Nuevo Comprobante</Button>
                </div>
              </div>
              {this.state.busqueda ? (
                <div>
                <h5 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Búsqueda por Número y/o Beneficiario</h5>
                <div className="row" style={{marginBottom: 20}}>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                      <ControlLabel>PERIODO</ControlLabel>
                      <Select value={this.state.periodo} onChange={this.handlePeriodo} options={this.state.years}/>
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                      <ControlLabel>NÚMERO</ControlLabel>
                      <FormControl type="number" value={this.state.numero} onChange= {this.handleNumero}/>
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                      <ControlLabel>BENEFICIARIO</ControlLabel>
                      <FormControl type="TEXT" value={this.state.beneficiario} onChange= {this.handleBeneficiario}/>
                    </div>
                    <div className="col-md-4" style={{marginBottom: 18}}>
                      <ControlLabel>CONCEPTO</ControlLabel>
                      <FormControl type="TEXT" value={this.state.concepto} onChange= {this.handleConcepto}/>
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                      <ControlLabel></ControlLabel><br></br>
                      <Button style={{width: 200, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.buscarMovimiento() }}>Buscar Comprobantes</Button>
                    </div>
                </div>
                </div>
              ) : (null)}
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ConsultaComprobantes;