import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormControl
} from "react-bootstrap";

import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import InputFiles from 'react-input-files';

import cover from "./cover.jpg";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactPaginate from 'react-paginate';

import axios from 'axios';
import { sisper, sisperplus } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Perfil extends React.Component {

  constructor(props) {
    super(props);
      this.state = {
        token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
        usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
        dni: localStorage.getItem('dni'),
        nombreX: JSON.parse(localStorage.getItem('datos'))[0].nombre,
        areaX: JSON.parse(localStorage.getItem('datos'))[0].area,
        cargoX: JSON.parse(localStorage.getItem('datos'))[0].cargo,
        cargo: '',
        nidUser: '',
        nombres: '',
        apepaterno: '',
        apematerno: '',
        correo: '',
        celular: '',
        nacimiento: '',
        foto: '',
        listaAprob: [],
        listaAprob2: [],
        listaColab: [],
        listaColab2: [],
        isLoading: true,
        isLoading2: true,
        isLoading3: true,
        isLoading4: true,
        enab: true,
        editar: true,
        cancelar: false,
        actualizar: false,
        path: 'https://sisper.sedetc.gob.pe/recursos/imagenes/fotos/',
        pathNull: 'https://sisper.sedetc.gob.pe/recursos/imagenes/usuario.jpg',
        param: 0,
        imageUri: '',
        offset: 0,
        elements: [],
        perPage: 6,
        currentPage: 0,
        pageCount: 0
      }
      this.handleNombres = this.handleNombres.bind(this);
      this.handlePaterno = this.handlePaterno.bind(this);
      this.handleMaterno = this.handleMaterno.bind(this);
      this.handleCorreo = this.handleCorreo.bind(this);
      this.handleCelular = this.handleCelular.bind(this);
      this.handleNacimiento = this.handleNacimiento.bind(this);
    }

  handleNombres(event) {
    this.setState({nombres: event.target.value});
  }
  handlePaterno(event) {
    this.setState({apepaterno: event.target.value});
  }
  handleMaterno(event) {
    this.setState({apematerno: event.target.value});
  }
  handleCorreo(event) {
    this.setState({correo: event.target.value});
  }
  handleCelular(event) {
    this.setState({celular: event.target.value});
  }
  handleNacimiento(event) {
    this.setState({nacimiento: event.target.value});
  }

  subir = (files) => {
      let foto = this.state.foto.replace(this.state.path, '');
      if(files != undefined){
        this.savePicture(foto, files[0])
      }
  }

  savePicture = (foto, file) => {
    if(file != undefined){
      this.setState({isLoading2: true})
      let fromData = new FormData();
      fromData.append('archivo', file);
      fromData.append('nid', this.state.nidUser);
      fromData.append('dni', this.state.dni);
      fromData.append('oldfoto', foto);
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${sisperplus}` + 'perfil/insertarFoto', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response.estado == 1){
          this.setState({param: 2})
          this.props.handleClick("tl", "info", "Tu foto ha sido actualizada!", "pe-7s-smile")
          this.listarDatos();
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
          this.setState({isLoading2: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
    }
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        this.setState({
          isLoading: true
        })
        this.listarDatos(); 
      }  
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  editar = () => {
    this.setState({
      editar: false,
      actualizar: true,
      cancelar: true,
      enab: false
    })
  }

  actualizar = () => {
    if(this.state.nombres != '' && this.state.apepaterno != '' && this.state.apematerno != '' && this.state.correo != '' && this.state.celular != '' && this.state.nacimiento != ''){
      this.setState({
        param: 1,
        actualizar: false,
        cancelar: false,
        editar: true,
        enab: true
      })
      this.editarDatos()
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }

  }

  cancelar = () => {
    this.setState({
      param: 1,
      actualizar: false,
      cancelar: false,
      editar: true,
      enab: true
    })
    this.listarDatos()
  }

  async editarDatos() {
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nombres', this.state.nombres);
    fromData.append('apepaterno', this.state.apepaterno);
    fromData.append('apematerno', this.state.apematerno);
    fromData.append('dni', this.state.dni);
    fromData.append('correo', this.state.correo);
    fromData.append('nacimiento', this.state.nacimiento);
    fromData.append('celular', this.state.celular);
    fromData.append('usuario', this.state.usuario);
    axios.post(`${sisperplus}` + 'perfil/editar', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response.estado == 1){
        this.props.handleClick("tl", "info", "Tus datos han sido actualizados!", "pe-7s-smile")
        this.listarDatos()
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });

  }

  async listarDatos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'perfil/listDatos?usuario=' + this.state.usuario)
    .then((res) => {
      this.setState({
        nidUser: res.data.response[0].nid_usuario,
        nombres: res.data.response[0].cnom_nombres,
        apepaterno: res.data.response[0].cape_paterno,
        apematerno: res.data.response[0].cape_materno,
        correo: res.data.response[0].ctxt_correo,
        celular: res.data.response[0].ctxt_numcelular2,
        nacimiento: res.data.response[0].dfec_nacimiento,
        foto: res.data.response[0].nom_foto,
      })
      if(this.state.foto != null){
        this.setState({
          foto: this.state.path + this.state.foto,
          // nacimiento: Calendar.calendario6(this.state.nacimiento)
          nacimiento: this.state.nacimiento
        })
      }else{
        this.setState({
          foto: this.state.pathNull,
        })
      }
      if(this.state.param == 0){
        this.setState({isLoading: false, isLoading2: false})
        this.naprob();
      }else if(this.state.param == 2){  
        this.setState({isLoading2: false})
      }else{
        this.setState({isLoading: false})
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({
          isLoading: false,
          isLoading2: false,
          isLoading3: false,
          isLoading4: false
        })
      }
    });
  }

  async naprob() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'perfil/naprob?dni=' + this.state.dni)
    .then((res) => {
      this.setState({
        listaAprob: res.data.response,
      })
      let lista = this.state.listaAprob
      lista.map((data) => {
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'perfil/listFotos?dni=' + data.aprobadorsecundario)
        .then((res) => {
           if(res.data.response[0].nom_foto != null){
            this.state.listaAprob2.push(
              { area: data.areaaprobacion, 
                aprobador: data.aprobadorprincipal, 
                nivel: data.nivel, 
                foto: this.state.path + res.data.response[0].nom_foto
            })
           }else{
            this.state.listaAprob2.push(
              { area: data.areaaprobacion, 
                aprobador: data.aprobadorprincipal, 
                nivel: data.nivel, 
                foto: this.state.pathNull
            })
           }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({
              isLoading3: false,
              isLoading4: false
            })
          }
        });
      });
      setTimeout(
          function() {
            this.setState({
              isLoading3: false
            })
          }
          .bind(this),
          2000
      );
      this.colaboradores();
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({
          isLoading3: false,
          isLoading4: false
        })
      }
    });
  }

  async colaboradores(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'perfil/colaboradores?dni=' + this.state.dni)
    .then((res) => {
      this.setState({listaColab: res.data.response})
      let lista = this.state.listaColab
      lista.forEach((data) => {
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'perfil/listFotos?dni=' + data.DocumentNumber)
        .then((res) => {
           if(res.data.response[0].nom_foto != null){
            this.state.listaColab2.push(
              { item: data.item,
                nombre: data.Name, 
                dni: data.DocumentNumber, 
                foto: this.state.path + res.data.response[0].nom_foto
            })
           }else{
            this.state.listaColab2.push(
              { item: data.item,
                nombre: data.Name, 
                dni: data.DocumentNumber, 
                foto: this.state.pathNull
            })
           }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({
              isLoading4: false
            })
          }
        });
      });
      setTimeout(
        function() {
          this.setState({isLoading4: false, pageCount: Math.ceil(this.state.listaColab2.length / this.state.perPage)})
        }
        .bind(this), 3000
    );
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading4: false})
      }
    });
  }

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState({ currentPage: selectedPage, offset: offset });
  }

  render() {
    let paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"Anterior"}
          nextLabel={"Siguiente"}
          breakLabel={<span className="gap">...</span>}
          pageCount={this.state.pageCount}
          onPageChange={this.handlePageClick}
          forcePage={this.state.currentPage}
          containerClassName={"pagination"}
          previousLinkClassName={"previous_page"}
          nextLinkClassName={"next_page"}
          disabledClassName={"disabled"}
          activeClassName={"active"}
        />
      );
    }
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={8}>
              <Card
                title="Situación Laboral"
                content={
                  this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                  <div style={{marginBottom: -20}}>
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        {label: "ENTIDAD", type: "text", bsClass: "form-control", defaultValue: "TRIBUNAL CONSTITUCIONAL", disabled: true},
                        {label: "USUARIO", type: "text", bsClass: "form-control", defaultValue: this.state.usuario, disabled: true},
                        {label: "N° DE DNI", type: "text", bsClass: "form-control", defaultValue: this.state.dni, disabled: true}
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        {label: "NOMBRES", type: "text", bsClass: "form-control", value: this.state.nombres, onChange: this.handleNombres, disabled: this.state.enab},
                        {label: "APELLIDO PATERNO", type: "text", bsClass: "form-control", value:  this.state.apepaterno, onChange: this.handlePaterno, disabled: this.state.enab},
                        {label: "APELLIDO MATERNO",type: "text", bsClass: "form-control", value: this.state.apematerno, onChange: this.handleMaterno, disabled: this.state.enab}
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        {label: "CORREO", type: "text", bsClass: "form-control", value: this.state.correo, onChange: this.handleCorreo, disabled: this.state.enab},
                        {label: "CELULAR", type: "text", bsClass: "form-control", value: this.state.celular, onChange: this.handleCelular, disabled: this.state.enab},
                        {label: "NACIMIENTO", type: "date", bsClass: "form-control", value: this.state.nacimiento, onChange: this.handleNacimiento, disabled: this.state.enab},
                      ]}
                    />
                    {this.state.editar ? ( 
                      <Button style={{width: 150, marginLeft: 10}} bsStyle="success" pullRight fill type="submit">
                         <InputFiles onChange={files => this.subir(files)}>Cambiar Foto</InputFiles>
                      </Button>
                    ) : ( null )}
                    {this.state.editar ? ( <Button style={{width: 150}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar() }}>Editar Perfil</Button> ) : ( null )}
                    {this.state.actualizar ? ( <Button style={{width: 150, marginLeft: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.actualizar() }}>Actualizar Perfil</Button> ) : ( null )}
                    {this.state.cancelar ? ( <Button style={{width: 150}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Cancelar Edición</Button>) : ( null )}
                  </div>
                 )}
              />
            </Col>
            <Col md={4}>
            {this.state.isLoading2 ? (
                <ClipLoader
                  css={override}
                  sizeUnit={"px"}
                  size={50}
                  color={'#123abc'}
                  loading={this.state.loading2}
                />
                ) : (
                <UserCard
                  bgImage={cover}
                  avatar={this.state.foto}
                  subimagen="La imagen debe ser de 240 X 320"
                  name={this.state.nombreX}
                  userName= {this.state.areaX}
                  description={
                    <span style={{fontSize: 16, color: 'black', textTransform: 'uppercase'}}>{this.state.cargoX}</span>
                  }
                  // description2={
                  //   <span style={{fontSize: 16, color: 'blue', textTransform: 'uppercase'}}>{this.state.nacimiento}</span>
                  // }
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title="Áreas de Aprobación"
                category="Se muestran a continuación las áreas que pueden aprobar su papeleta siempre que el responsable del nivel 1 no se encuentre disponible."
                content={
                  <Row>
                    {this.state.isLoading3 ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading3}
                      />
                    ) : (
                    this.state.listaAprob2
                    .sort((a, b) => a.nivel - b.nivel)
                    .map((data, i) => {
                      return (
                       <Col key={i} md={4} style={{marginBottom: -20}}>
                          <UserCard
                              bgImage={cover}
                              avatar={data.foto}
                              name={data.aprobador}
                              userName= {data.area}
                              description={
                                <span style={{fontSize: 16, color: 'red'}}>NIVEL: {data.nivel}</span>
                              }
                          />
                        </Col>
                      )
                    })
                    )} 
                  </Row>
                }
                category2="(*) En caso que haya algún error en está información favor de comunicarse con la Oficina de Gestión y Desarrollo Humano al anexo 144."
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title="Colaboradores de su Área"
                content={
                  <Row>
                    {this.state.isLoading4 ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading4}
                      />
                    ) : (
                      <div>
                        <div style={{float: "right", marginRight: 18, marginTop: -80}}>
                          {paginationElement}
                        </div>
                        <div style={{marginTop: 40}}>
                        {this.state.listaColab2
                          .sort((a, b) => a.item - b.item)
                          .slice(this.state.offset, this.state.offset + this.state.perPage)
                          .map((data, i) => {
                            return (
                            <Col key={i} md={4}>
                                  <UserCard
                                      bgImage={cover}
                                      avatar={data.foto}
                                      name={data.nombre}
                                      userName= {data.dni}
                                  />
                              </Col>
                            )
                          })}
                          </div>
                      </div>
                    )} 
                  </Row>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Perfil;