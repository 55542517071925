import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import { BrowserRouter as Router, Route,Switch, Redirect} from 'react-router-dom'
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import InputFiles from 'react-input-files';

import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";
import store from "store/Store.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { concursos } from 'store/Apis'

import moment from 'moment'

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class DetConcurso extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'concursos',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      selectedOption: {value: "0", label: "Sin Opción Principal"},
      option: [],
      detalle: [],
      detalle2: [],
      descripcion: "",
      oldarchivo: "",
      archivo: "",
      file: [],
      nidcarga: "",
      estado: 0,
      concurso: '',
      ruta: '',
      orden: '',
      tipo: '',
      anio: '',
      mes: '',
      isLoading: true,
      dependiente:''
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);
    this.handleDescripcion = this.handleDescripcion.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
  }

    handleDescripcion(event) {
        this.setState({descripcion: event.target.value});
    }

    handleChange = selectedOption => {
        this.setState(
        { selectedOption },
        );
    };
    
    onChangeValue(event) {
      console.log(event.target.value);
      this.setState({dependiente: event.target.value});
    }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if( localStorage.getItem("concurso") !== null){
      console.log(JSON.parse(localStorage.getItem('concurso')));
        this.setState({concurso: JSON.parse(localStorage.getItem('concurso')).nidconcurso, 
                       tipo: JSON.parse(localStorage.getItem('concurso')).tipo,
                       anio: JSON.parse(localStorage.getItem('concurso')).anio,
                       mes: JSON.parse(localStorage.getItem('concurso')).mes
                     });
        this.listdetallexconcurso(0);
    }else{
      this.props.history.push('/concursos');
    }
  }

  async listdetallexconcurso(cont) {
      if(cont == 0){this.setState({isLoading: true})}
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${concursos}` + 'private/listdetallexconcurso?nid=' + this.state.concurso)
      .then((res) => {
        console.log(res.data.response);
        let lista = res.data.response;
        let lista2 = [];
        let lista3 = [];
        this.setState({option: []})
        this.state.option = [{value: "0", label: "Sin Opción Principal"}];
        if(lista != null){
          lista.map((data) => {
              if(data.nid_menu == '0'){
                console.log('entro1');
                lista2.push(
                    { item: data.item,
                      nid: data.nid,
                      nid_menu: data.nid_menu,
                      nid_orden: data.nid_orden,
                      ctxt_descripcion: data.ctxt_descripcion,
                      ctxt_ruta: data.ctxt_ruta,
                      cnom_archivo: data.cnom_archivo,
                      ctxt_menu: data.ctxt_menu,
                      ctxt_tipo: data.ctxt_tipo,
                      ctxt_tipo2: data.ctxt_tipo==1 ? "Concurso" : data.ctxt_tipo==2 ? "Ganador" : ""
                  })
                  this.state.option.push(
                    { value: data.nid,
                      label: data.ctxt_descripcion
                  })
              }else{
                console.log('entro2');
                lista3.push(
                    { item: data.item,
                      nid: data.nid,
                      nid_menu: data.nid_menu,
                      nid_orden: data.nid_orden,
                      ctxt_descripcion: data.ctxt_descripcion,
                      ctxt_ruta: data.ctxt_ruta,
                      cnom_archivo: data.cnom_archivo,
                      ctxt_menu: data.ctxt_menu
                  })
              }
          })
          this.setState({detalle: lista2, detalle2: lista3, isLoading: false})
        }else{
          this.setState({detalle: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async update(){
    let fromData = new FormData();
    fromData.append('file', this.state.file);
    fromData.append('descripcion', this.state.descripcion);
    fromData.append('menu', this.state.selectedOption.value);
    fromData.append('oldarchivo', this.state.oldarchivo);
    fromData.append('archivo', this.state.archivo);
    fromData.append('anio', this.state.anio);
    fromData.append('mes', this.state.mes);
    fromData.append('nidconcurso', this.state.concurso);
    fromData.append('niddetalle', this.state.nidcarga);
    
    if(this.state.dependiente === null|| this.state.dependiente === ''){
      fromData.append('tipo', 0);
    } else {
      fromData.append('tipo', this.state.dependiente);
    }
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${concursos}` + 'private/editardetallexconcurso', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async editar(datos){
    if(datos.ctxt_menu == null || datos.ctxt_menu == '0'){
      this.setState({selectedOption: {value: ''+datos.nid_menu, label:"Sin Opción Principal"}})
    }else{
      this.setState({selectedOption: {value: ''+datos.nid_menu, label: datos.ctxt_menu}})
    }
    this.setState({
      archivo: datos.cnom_archivo,
      oldarchivo: datos.cnom_archivo,
      descripcion: datos.ctxt_descripcion,
      ruta: datos.ctxt_ruta,
      nidcarga: datos.nid,
      orden: datos.nid_orden,
      dependiente : datos.ctxt_tipo
    })
  }

  async save(){
    if(this.state.descripcion !== ""){
      if(this.state.nidcarga == ""){
        this.guardar()
      }else{
        this.update()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async limpiar(){
    this.cancelar();
    this.listdetallexconcurso(0);
    this.props.handleClick("tl", "info", "Cambios realizados correctamente!", "pe-7s-smile")
  }

  async cancelar(){
    this.setState({selectedOption: {value: "0", label: "Sin Opción Principal"}, descripcion: "", nidcarga: "", archivo: "", file: [], dependiente: ''})
  }

  async volver(){
    this.props.history.push('/concursos');
  }

  async guardar(){
      var archivo = "";
      if(this.state.archivo !== ""){
         archivo = this.state.archivo;
      }else{
         archivo = "--";
      }
      let fromData = new FormData();
      fromData.append('file', this.state.file);
      fromData.append('descripcion', this.state.descripcion);
      fromData.append('menu', this.state.selectedOption.value);
      fromData.append('archivo', archivo);
      fromData.append('anio', this.state.anio);
      fromData.append('mes', this.state.mes);
      fromData.append('nidconcurso', this.state.concurso);
      fromData.append('cnomusuario', this.state.usuario);
      fromData.append('tipo', this.state.dependiente);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${concursos}` + 'private/insertardetallexconcurso', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.limpiar();
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
      });
  }

  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], archivo: files[0].name});
    }
  }

  async ver(datos){
    window.open(datos.ctxt_ruta);
  }

  async mover(datos, tipo){
    let fromData = new FormData();
    fromData.append('tipo', tipo);
    fromData.append('menu', datos.nid_menu);
    fromData.append('orden', datos.nid_orden);
    fromData.append('niddetalle', datos.nid);
    fromData.append('nidconcurso', this.state.concurso);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${concursos}` + 'private/moverdetallexconcurso', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listdetallexconcurso(1);
        this.props.handleClick("tl", "info", "El registro ha sido movido correctamente!", "pe-7s-smile")
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  confirmarDelete = (datos) => {
    confirmAlert({
      title: '¿Seguro de eliminar?',
      message: 'Se eliminará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(datos)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async delete(datos){
    let fromData = new FormData();
    fromData.append('menu', datos.nid_menu);
    fromData.append('archivo', datos.cnom_archivo);
    fromData.append('anio', this.state.anio);
    fromData.append('mes', this.state.mes);
    fromData.append('nidconcurso', this.state.concurso);
    fromData.append('niddetalle', datos.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${concursos}` + 'private/removerdetallexconcurso', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listdetallexconcurso(1);
        this.props.handleClick("tl", "info", "El registro ha sido eliminado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  GetActionFormat(cell, row) {
    console.log(row);
    if(row != undefined){
      return (
          <div>
              <span data-tip="Subir Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.mover(row, 0) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-info"></i>
                <i className="fa fa-arrow-up fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Bajar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.mover(row, 1) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-arrow-down fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Remover Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  GetActionFormat2(cell, row) {
    if(row != undefined){
      return (
          <div>
          {row.cnom_archivo != '--' ? (
              <span data-tip="Ver Adjunto" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                <i className="fa fa-file fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
          </div>
      );
    }else{}
  }

  render() {
    const { selectedOption } = this.state;
    const columns = [
      {dataField: "nid_orden", text: "ORDEN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ctxt_descripcion", text: "DESCRIPCIÓN", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "cnom_archivo", text: "ARCHIVO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "archivo", text: "VER ADJUNTO", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},
      {dataField: "ctxt_tipo2", text: "TIPO DE RESOLUCIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.detalle.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.detalle }
              columns={ columns }
              expandRow={ expandRow }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const expandRow = {
        onlyOneExpanding: true,
        renderer: row => (
          <div>
            <h4 style={{marginTop: 5, fontSize: 18, textAlign: 'center'}} className="title">Formatos:</h4>
            <div className="row">
            <div className="col-md-12">
                <Table>
                    <Tbody>
                    {this.state.detalle2.map((item, key) => (row.nid == item.nid_menu ? (
                        <Tr key={key}>
                            <Td style={{textAlign: 'center', border: '1px solid #D5D8DC', paddingTop: 12, paddingBottom: 12}}>{item.nid_orden}</Td>
                            <Td style={{textAlign: 'center', border: '1px solid #D5D8DC', paddingTop: 12, paddingBottom: 12}}>{item.ctxt_descripcion}</Td>
                            <Td style={{textAlign: 'center', border: '1px solid #D5D8DC', paddingTop: 12, paddingBottom: 12}}>{item.cnom_archivo}</Td>
                            <Td style={{textAlign: 'center', border: '1px solid #D5D8DC', paddingTop: 12, paddingBottom: 12}}>
                                {item.cnom_archivo != '--' ? (
                                <span data-tip="Ver Adjunto" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver(item) }}>
                                    <ReactTooltip />
                                    <i className="fa fa-circle fa-stack-2x text-danger"></i>
                                    <i className="fa fa-file fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                </span>) : (null)}
                            </Td>
                            <Td style={{textAlign: 'center', border: '1px solid #D5D8DC', paddingTop: 12, paddingBottom: 12}}>
                                <span data-tip="Subir Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.subir(item) }}>
                                    <ReactTooltip />
                                    <i className="fa fa-circle fa-stack-2x text-info"></i>
                                    <i className="fa fa-arrow-up fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                </span>
                                <span data-tip="Bajar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.bajar(item) }}>
                                    <ReactTooltip />
                                    <i className="fa fa-circle fa-stack-2x text-primary"></i>
                                    <i className="fa fa-arrow-down fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                </span>
                                <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar(item) }}>
                                    <ReactTooltip />
                                    <i className="fa fa-circle fa-stack-2x text-success"></i>
                                    <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                </span>
                                <span data-tip="Remover Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete(item) }}>
                                    <ReactTooltip />
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                </span>
                            </Td>
                        </Tr>
                        ) : (null)
                    ))}
                    </Tbody>
                </Table>
            </div>
            </div>
          </div>
        )
      };
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Detalle del Concurso</h4>
              <div className="row">
                <div className="col-md-12" style={{marginBottom: 18}}>
                    <ControlLabel>DESCRIPCIÓN</ControlLabel>
                    <FormControl type="text" value={this.state.descripcion} onChange= {this.handleDescripcion}/>
                </div>
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel>OPCIONES PRINCIPALES</ControlLabel>
                    <Select value={selectedOption} onChange={this.handleChange} options={this.state.option}/>
                </div>             
                {/* <div className="col-md-6">
                  <ControlLabel>TIPO DE RESOLUCIÓN</ControlLabel><br></br>
                  <input type="radio" value="1"  checked={this.state.v === "1"} style={{marginRight: 5}} onChange={this.handleOptionChange}/><label style={{marginRight: 10}}>Concurso</label>
                  <input type="radio" value="2"  checked={this.state.v === "2"} style={{marginRight: 5}} onChange={this.handleOptionChange}/><label style={{marginRight: 10}}>Ganador</label>       
                  <p className="category" style={{fontStyle:"italic", fontSize: 14,  color:"grey"}}>NOTA: Seleccione el tipo solo cuando sea una Resolución Administrativa</p>
                </div> */}
                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel>TIPO DE RESOLUCIÓNE</ControlLabel><br></br>
                    <input type="radio" value="1" name="dependiente"  checked={this.state.dependiente ==="1"} onChange={this.onChangeValue} style={{marginRight: 5}}/><label style={{marginRight: 10}}>Concurso</label>
                    <input type="radio" value="2" name="dependiente"  checked={this.state.dependiente === "2"} onChange={this.onChangeValue} style={{marginRight: 5}}/><label style={{marginRight: 10}}>Ganador</label>
                    <p className="category" style={{fontStyle:"italic", fontSize: 14,  color:"grey"}}>NOTA: Seleccione el tipo solo cuando sea una Resolución Administrativa</p>
                 </div>
              </div>
              <div className="row">
                  <div className="col-md-12">
                      <ControlLabel>ARCHIVO ADJUNTO</ControlLabel>
                      <Alert bsStyle="warning">
                          <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo}</span>
                      </Alert>
                      <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                          <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                      </Button>
                  </div>
              </div>
              <div className="row">
                <div className="col-md-12" style={{marginTop: 18, marginBottom: 18}}>
                    <Button style={{width: 150}} bsStyle="info" fill type="submit" onClick={() => { this.save() }}>Guardar</Button>
                    <Button style={{width: 150, marginLeft: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.cancelar() }}>Limpiar</Button>
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
            <Button style={{width: 150, marginRight: 25}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.volver() }}>Volver</Button>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default DetConcurso;