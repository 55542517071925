import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { jurisdiccional} from 'store/Apis'
import 'react-tabs/style/react-tabs.css';
import Select from 'react-select';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { confirmAlert } from 'react-confirm-alert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class CrearSalasNoticias extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'salasnoticias',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      titulo : "", 
      link: "",
      estado: 0,
      isLoading: false,
      show: true,
      sala:{value: "01", label: "SALA 1"},
      salas: [
        {value: "01", label: "SALA 1"},
        {value: "02", label: "SALA 2"}
      ]
    }
    this.handleTitulo = this.handleTitulo.bind(this);
    this.handleLink = this.handleLink.bind(this);
  }
    handleSala = sala => {
    this.setState(
      { sala },
      ); 
    };
    handleTitulo(event) {this.setState({titulo: event.target.value});}
    handleLink(event) {this.setState({link: event.target.value});}
    
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async agregar(){  
    if(this.state.titulo != ''  &&  this.state.link != ''){
      let fromData = new FormData();
      fromData.append('titulo', this.state.titulo);
      fromData.append('link', this.state.link);
      fromData.append('sala', this.state.sala.label);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${jurisdiccional}` + 'noticiassalas/insertarNoticias', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          this.props.handleClick("tl", "success", "Noticia registrada correctamente!", "pe-7s-close")
            this.regresar();
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
      });
    }else{
       this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close") 
    }
  }

  async regresar(){
    this.props.history.push('/salasnoticias');
  }


  abrir(ruta){
    window.open(ruta, '_blank');
  } 
  mostrar = () => {
    confirmAlert({
      title: <i class="fa fa-user" style={{ color :"black", marginLeft: 130,fontSize: 100}}></i>,
      message:<div><p>USUARIO : Usuario_01</p><p>CONTRASEÑA : Tribunal2022$</p> </div> ,
      buttons: [
        {
          label: 'OK',
        },        
      ],
      closeOnClickOutside: false,
    });}



  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Nueva Noticia</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                        <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel><i className="fa fa-newspaper-o"></i> TÍTULO</ControlLabel>
                              <FormControl type="text" value={this.state.titulo} onChange= {this.handleTitulo}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                              <ControlLabel><i className="fa fa-newspaper-o"></i> SALA</ControlLabel>
                              <Select value={this.state.sala} onChange={this.handleSala} options={this.state.salas}/>
                            </div>
                            <div className="col-md-8" style={{marginBottom: 18}}>
                              <ControlLabel><i className="fa fa-link"></i> LINK DEL DOCUMENTO</ControlLabel>
                              <FormControl type="text" value={this.state.link} onChange= {this.handleLink}/>
                            </div>
                            <div className="col-md-12" style={{marginBottom: 18}}>
                             <Button style={{width: 150}} bsStyle="primary" pullRight  type="submit" onClick={() => { this.mostrar() }}>Ver Credenciales</Button>
                             <Button style={{width: 350,marginRight:5}} bsStyle="primary" pullRight type="submit" onClick={() => { this.abrir("https://www.tc.gob.pe/wp-login.php") }}>Haga click aquí para subir el documento</Button>
                            </div>

                        </div> 
                        <Tabs style={{marginTop: 30}}>
                        <TabList>
                        <Tab>VIZUALIZAR DOCUMENTO </Tab>
                        </TabList>
                        <TabPanel>
                            <div className="row">
                                  <div className="col-md-6" style={{marginBottom: 18}}>
                                    {this.state.link != "" && this.state.link != null ? (
                                      <span style={{cursor: 'pointer', fontSize: 18}} >
                                          <a href={this.state.link} target="blank"><i style={{color: "red"}} className="fa fa-file-pdf-o fa-5x" aria-hidden="true"></i></a>
                                      </span>
                                      ) : (
                                        <p>Sin Documento</p>
                                      )}
                                  </div>
                            </div>
                          </TabPanel>
                        </Tabs>
                        <Button style={{ marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Registro</Button>
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.agregar() }}>Guardar Registro</Button>
                    </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
export default CrearSalasNoticias;