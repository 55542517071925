import React, { Component } from "react";
import {FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, fechasBusqueda} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactExport from 'react-export-excel'
import { confirmAlert } from 'react-confirm-alert';

import Swal from 'sweetalert2'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ContadorTramite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: 'utilitariosStd',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      nummax: 0,
      editable:true,
      anioActual: (new Date()).getFullYear(),
      nid:"",
      procedencia: {value : "", label: "Seleccione..."},
      procedencias : [
        {value : "E", label: "EXTERNO"},
        {value : "P", label: "PERSONAL"},
        {value : "I", label: "INTERNO"},
        {value : "D", label: "NO DEFINIDO"}
      ]
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.handleNumMax =this.handleNumMax.bind(this);

    this.handlePeriodo =this.handlePeriodo.bind(this);
  
  }
  handlePeriodo(event) {this.setState({anioActual: event.target.value});}
  handleNumMax(event) {this.setState({nummax: event.target.value});}

    handleProcedencia = procedencia => {
      this.setState(
      { procedencia },
      );
    };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.listSecuenciaxArea();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
  async listSecuenciaxArea() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'documentario/listSecuenciaxPeriodo')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              nid : data.nid_secuenciaxano,
              procedencia : data.cflg_procedencia,
              anio : data.cnum_ano,
              nummax: data.nnum_maximo,
              nomProc : data.nomProc + " (" + data.cflg_procedencia + ")",
              item:data.item
            })
          })
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        
        this.setState({isLoading: false})
      }
    });
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
 }

async guardarIndi(){  
if(this.state.procedencia.value!= ""){ 
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('procedencia', this.state.procedencia.value);
    fromData.append('anio',this.state.anioActual);
    fromData.append('numeromax',this.state.nummax);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'documentario/insertPeriodo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar();         
          this.props.handleClick("tl", "info", "Se ha registrado correctamente!", "pe-7s-smile") 
     }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }else{
    this.props.handleClick("tl", "warning", "Seleccione la procedencia!", "pe-7s-close")
  }

  }
  
  async update(){  
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid',this.state.nid);
    fromData.append('procedencia',this.state.procedencia.value);
    fromData.append('anio',this.state.anioActual);
    fromData.append('numeromax',this.state.nummax); 
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'documentario/updatePeriodo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar();         
          this.props.handleClick("tl", "info", "Registro actualizado correctamente!", "pe-7s-smile") 
     }else{
          this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
   
  }

async obtener(data){
    this.setState({
      procedencia : {value :data.procedencia , label : data.nomProc },
      nummax: data.nummax,
      nid: data.nid,
      anioActual:data.anio,
      editable:false
    })
  }

  async limpiar(){
    this.listSecuenciaxArea();
    this.cancelar();
  }

  async cancelar(){
    this.setState({
         nummax:0, 
         nid:"",
         procedencia : {value : "", label: "Seleccione..."},
         editable:true
        })
  }

  async regresar(){
    this.props.history.push('/utilitariosStd');
  }

  async save(){
     if(this.state.nid == ''){
        this.guardarIndi()
      }else{
        this.update()
      } 
  } 

  render() {
    const columns = [
    {dataField: "item", text: "ITEM",  headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "nomProc", text: "PROCEDENCIA", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white",width:400}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "nummax", text: "NÚMERO MÁXIMO", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "anio", text: "AÑO", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 15, paddingBottom: 5}}>
                <div className="row">
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>PROCEDENCIA</ControlLabel>
                        <Select value={this.state.procedencia} onChange={this.handleProcedencia} options={this.state.procedencias}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>PERIODO</ControlLabel>
                        <FormControl type="number" value={this.state.anioActual} onChange={this.handlePeriodo} disabled />
                    </div>
                    <div className="col-md-3" style={{ marginBottom: 18 }}>
                        <ControlLabel>NÚMERO MÁXIMO</ControlLabel>
                        <FormControl type="number" value={this.state.nummax} onChange={this.handleNumMax} disabled={this.state.editable} />
                    </div>
                    <Button style={{ width: 180, marginTop: 25, marginBottom: 20, marginRight: 15}} bsStyle="danger" fill type="submit" pullRight onClick={() => { this.cancelar() }}>Cancelar</Button>
                    <Button style={{ width: 180, marginTop: 25, marginBottom: 20, marginRight: 5 }} bsStyle="success" fill type="submit" pullRight onClick={() => { this.save() }}>Guardar</Button>
                </div>         
                <hr></hr>
                <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Listado de Secuencias por Periodo </h4>
                {this.state.isLoading ? (
                   <ClipLoader
                              css={override}
                              sizeUnit={"px"}
                              size={50}
                              color={'#123abc'}
                              loading={this.state.loading}
                          />                    
                ) : (
                    <div>
                        <PaginationProvider
                            pagination={
                            paginationFactory(options)
                            }>
                            { contentTable }
                        </PaginationProvider>
                        <Button style={{width: 250, marginTop: 18}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                    </div>
                )}
   
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ContadorTramite;