import React, { Component } from "react";
import { ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import InputFiles from 'react-input-files';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, cec, fechasBusqueda, paramCec } from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Swal from "sweetalert2";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class CertiFirmados extends Component {

    constructor(props) {
        super(props);
        this.state = {
            path: 'certiFirmados',
            usuario: localStorage.getItem('nickname'),
            token: 'Bearer ' + localStorage.getItem('ConcurToken'),
            token2: 'Bearer ' + localStorage.getItem('AccessToken'),
            id: JSON.parse(localStorage.getItem('datos'))[0].Person,
            movimientos: [],
            years: [],
            meses: [],
            archivo: '',
            isLoading: true,
            file: [],
            ver: false,
            data: [],
        }
    this.GetActionFormat = this.GetActionFormat.bind(this);
    }

    handleAnio = anio => {
        this.setState(
            { anio },
        );
    };

    handleMes = mes => {
        this.setState(
            { mes },
        );
    };

    async componentDidMount() {
        if (localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null) {
            axios.get(validator + this.state.path + '/' + this.state.usuario, {
                headers: {
                    Authorization: this.state.token2
                }
            }).then((response) => {
                let permisos = response.data;
                if (permisos.codError != 1) {
                    this.props.history.goBack()
                } else {
                    this.generarPeriodos();
                }
            }, (error) => {
                if (auth0Client.isAuthenticated() === true) {
                    auth0Client.signOut()
                    this.props.history.replace('/');
                } else {
                    this.props.history.goBack()
                }
            });
        } else {
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }

    generarPeriodos() {
        let year = 2000;
        let cont = 26;
        let array = Array.from(new Array(cont), (val, index) => index + year);
        let arrayMes = [
            { "nid": "01", "descripcion": "ENERO" },
            { "nid": "02", "descripcion": "FEBRERO" },
            { "nid": "03", "descripcion": "MARZO" },
            { "nid": "04", "descripcion": "ABRIL" },
            { "nid": "05", "descripcion": "MAYO" },
            { "nid": "06", "descripcion": "JUNIO" },
            { "nid": "07", "descripcion": "JULIO" },
            { "nid": "08", "descripcion": "AGOSTO" },
            { "nid": "09", "descripcion": "SETIEMBRE" },
            { "nid": "10", "descripcion": "OCTUBRE" },
            { "nid": "11", "descripcion": "NOVIEMBRE" },
            { "nid": "12", "descripcion": "DICIEMBRE" },
        ]
        let cont2 = 0;
        let cont3 = 0;
        let lista = [];
        let lista2 = [];
        if (fechasBusqueda.certiFirma[0].nidperiodo != "" && fechasBusqueda.certiFirma[1].periodo != "") {
            array.map((data) => {
                cont2++
                lista.push({
                    value: cont2,
                    label: data,
                })
            })
            this.setState({
                anio: {
                    value: fechasBusqueda.certiFirma[0].nidperiodo,
                    label: fechasBusqueda.certiFirma[1].periodo
                }
            })
        } else {
            array.map((data) => {
                cont2++
                lista.push({
                    value: cont2,
                    label: data,
                })
                if (data == (new Date()).getFullYear()) {
                    this.setState({ anio: { value: cont2, label: data } })
                }
            })
        }
        if (fechasBusqueda.certiFirma[2].nidmes != "" && fechasBusqueda.certiFirma[3].mes != "") {
            arrayMes.map((data) => {
                cont3++
                lista2.push({
                    item: data.cont3,
                    value: data.nid,
                    label: data.descripcion,
                })
            })
            this.setState({
                mes: {
                    value: fechasBusqueda.certiFirma[2].nidmes,
                    label: fechasBusqueda.certiFirma[3].mes,
                }
            })
        } else {
            arrayMes.map((data) => {
                cont3++
                lista2.push({
                    item: data.cont3,
                    value: data.nid,
                    label: data.descripcion,
                })
                if (cont3 == (new Date()).getMonth() + 1) {
                    this.setState({ mes: { value: data.nid, label: data.descripcion } })
                }
            })
        }
        this.setState({ years: lista, meses: lista2 })
        this.listarCertiFirma()
    }

    async listarCertiFirma() {
        this.setState({isLoading: true})
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${cec}` + 'aula/listCertificadoFirmados?anio='+ this.state.anio.label + '&mes='+ this.state.mes.value)
        .then((res) => {
          if(res.data.response[0].data!= null){
             let m =[]; 
            (res.data.response[0].data).forEach((value, index) => {
               m.push(
              (res.data.response[0].data).slice(index,index+1)
              ) })
            let lista2 = m;   
            if(lista2 != null){
                  fechasBusqueda.certiFirma[0].nidperiodo = this.state.anio.value
                  fechasBusqueda.certiFirma[1].periodo = this.state.anio.label
                  fechasBusqueda.certiFirma[2].nidmes = this.state.mes.value
                  fechasBusqueda.certiFirma[3].mes = this.state.mes.label
                  localStorage.setItem('fechasBusqueda', JSON.stringify(fechasBusqueda))
              this.setState({movimientos: lista2, isLoading: false})
            }else{
              this.setState({movimientos: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            } }else{
            this.setState({movimientos: [], isLoading: false})
          } })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
      }

    async validar() {
        if (this.state.archivo != '') {
            this.cargar();
        } else {
            this.props.handleClick("tl", "error", "Debe cargar el archivo comprimido!", "pe-7s-close")
        }
    }

    cargar = () => {
        Swal.fire({
          title: "Los archivos se están descomprimiendo " ,
          text: " Espere por favor...",
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading()
            },
            allowOutsideClick:false
        })
        this.agregar()     
    };
      
    async agregar(){  
        this.setState({isLoading: true})
        let fromData = new FormData();
        fromData.append('file',this.state.file );
        fromData.append('anio',this.state.anio.label );
        fromData.append('mes',this.state.mes.value );
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${cec}` + 'aula/unRar', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response == null){
            this.props.handleClick("tl", "success", "Archivo Descomprimido correctamente!", "pe-7s-close")
              Swal.close(); 
              this.Regresar();
          }else{
              this.props.handleClick("tl", "error", "Hubo un problema al descomprimir!", "pe-7s-close")
              Swal.close(); 
              this.setState({isLoading: false})
          }
          })
          .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            
          }else{
              console.log(error);
              Swal.close(); 
              this.setState({isLoading: false})
              this.props.handleClick("tl", "error", "Hubo un problema interno!", "pe-7s-close")
          }
        });
      }

    Regresar() {
        this.listarCertiFirma()
        this.setState({ ver: false })
        this.limpiar();
    }

    async subirDocumento() {
        this.setState({ ver: true })
    }

    subir = (files) => {
        if (files.length !== 0) {
            this.setState({ file: files[0], archivo: files[0].name });
        }  
    }

    async limpiar() {
        this.setState({ archivo: "", file: "" , isLoading:false})
    }

    abrirDocumento(ruta) {
        window.open("https://aulabackend.sedetc.gob.pe/firmados/"+this.state.anio.label+"/"+this.state.mes.value+"/"+ ruta  , '_blank')//
    }

    GetActionFormat(cell, row) {
        if (row != undefined) {
            return (
                <div>
                    <span data-tip="Ver Documento" className="fa-stack fa-2x" style={{ cursor: 'pointer', fontSize: 20 }} onClick={() => { this.abrirDocumento(row) }}>
                       <i className="fa fa-file-pdf-o fa-3x" style={{ fontSize: 35, color: "red" }}></i>
                   </span>  
                </div>
            );
        }
    }

    mes(){
        let mesActual = new Intl.DateTimeFormat('es-ES', { month: 'long'}).format(new Date());
        return mesActual.toUpperCase();
    }

    render() {
        const columns = [
            {dataField: "0", text: "CERTIFICADO",filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "ver", text: "ARCHIVO", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.GetActionFormat},
        ]

        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'Primera',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Última',
            showTotal: true,
            totalSize: this.state.movimientos.length
        };

        const contentTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <div>
                    <div>
                        <BootstrapTable
                            striped
                            hover
                            keyField="item"
                            data={this.state.movimientos}
                            columns={columns}
                            filter={filterFactory()}
                            noDataIndication="No existen registros para mostrar"
                            {...paginationTableProps}
                        />
                    </div>
                    <div style={{ float: "right" }}><PaginationListStandalone {...paginationProps} /></div>
                </div>
            </div>
        );

        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <div style={{ borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5 }}>
                                <div>
                                    <h4 className="title" style={{ fontWeight: 200, marginTop: 18, marginBottom: 25 }}>CERTIFICADOS FIRMADOS</h4>
                                </div> 
                               
                                {this.state.ver != true ? (   
                                    <div className="row">
                                        <div className="row-md-12">
                                            <div className="col-md-2" style={{ marginBottom: 18 }}>
                                                <ControlLabel>PERIODO</ControlLabel>
                                                <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years} />
                                            </div>
                                            <div className="col-md-2" style={{ marginBottom: 18 }}>
                                                <ControlLabel>MES</ControlLabel>
                                                <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses} />
                                            </div>
                                            <div className="col-md-2" style={{ marginBottom: 18 }}>
                                                <ControlLabel></ControlLabel><br></br>
                                                <Button style={{ width: 250, marginTop: 5, marginBottom: 10 }} bsStyle="info" fill type="submit" onClick={() => { this.listarCertiFirma() }}>Buscar Certificados</Button>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-2" style={{ marginBottom: 18 }}>
                                                    <ControlLabel></ControlLabel><br></br>
                                                    <Button style={{ width: 235, marginTop: 5, marginRight: 20, marginBottom: 10 }} bsStyle="warning" fill type="submit" onClick={() => { this.subirDocumento() }}>Subir Archivo</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                 ) : (null)}
                               {this.state.ver == true ? (
                                    <div> 
                                        <div className="row">
                                         
                                                <h4 className="title" style={{ fontWeight: 200, marginTop: 18, marginBottom: 25 ,marginLeft:15}}>Seleccione el periodo y mes : </h4>
                                                <div className="col-md-2" style={{ marginBottom: 18 }}>
                                                    <ControlLabel>PERIODO</ControlLabel>
                                                    <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years} />
                                                </div>
                                                <div className="col-md-2" style={{ marginBottom: 18 }}>
                                                    <ControlLabel>MES</ControlLabel>
                                                    <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses} />
                                                </div>
                                                <div className="col-md-12" style={{ marginBottom: 18 }}>
                                                    <p className="category" style={{fontStyle:"italic", fontSize: 14,  color:"grey"}}>NOTA: El periodo y mes que seleccione corresponderá a la fecha de creación de los certificados</p>
                                                </div>
                                               <div className="col-md-12">
                                                <ControlLabel>SUBIR ARCHIVO ADJUNTO</ControlLabel>
                                                <Alert bsStyle="warning">
                                                    <span style={{ letterSpacing: 1 }}><b> Archivo: </b> {this.state.archivo}</span>
                                                </Alert>
                                                <Button style={{ width: '100%', marginTop: -10, marginBottom: 10 }} bsStyle="success" pullRight fill type="submit">
                                                    <InputFiles accept="application/zip, .rar"  onChange={files => this.subir(files)}>Dale click para seleccionar el archivo</InputFiles>
                                                </Button>
                                                {this.state.archivo != "" ? (
                                                <Button style={{ width: '100%', marginTop: 10, marginBottom: 10 }} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar Archivo</Button>
                                                ) : (null)}</div>

                                            <Button style={{width:250, marginRight: 15, marginTop: 20 }} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.Regresar() }}>CANCELAR</Button>
                                        <Button style={{width:250, marginRight: 10, marginTop: 20 }} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>GUARDAR</Button>
                                        </div>
                                      
                                    </div>) : (null)
                                }
                                {this.state.isLoading ? (
                                    <ClipLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={50}
                                        color={'#123abc'}
                                        loading={this.state.loading}
                                    />
                                ) : (
                                    <div>
                                        {this.state.ver == false ?
                                            <div>
                                                <PaginationProvider
                                                    pagination={
                                                        paginationFactory(options)
                                                    }
                                                >
                                                    {contentTable}
                                                </PaginationProvider>
                                            </div>
                                        : null }
                                    </div>
                                )
                                }
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default CertiFirmados;