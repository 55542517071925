import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {cec,paramCec } from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ConsultarBiblioteca extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'biblioteca',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.VerLibro= this.VerLibro.bind(this);
    this.estado= this.estado.bind(this);
  }


  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.listarBiblioteca();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listarBiblioteca() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${cec}` + 'biblioteca/listBibliotecaAdm')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                autor: data.autor,
                cnum_estante: data.cnum_estante,
                cod_libro: data.cod_libro,
                ctxt_archivo: data.ctxt_archivo,
                ctxt_descripcion: data.ctxt_descripcion,
                ctxt_paginas: data.ctxt_paginas,
                ctxt_ruta: data.ctxt_ruta,
                ctxt_titulo: data.ctxt_titulo,
                dfec_compra:data.dfec_compra,
                editorial: data.editorial,
                especialidad: data.especialidad,
                item: data.item,
                flg_estado: data.flg_estado == 1 ? 'ACTIVO' : 'INACTIVO',
                cod_dewey: data.cod_dewey,
                cnum_balda : data.cnum_balda,
                ctxt_sede :   data.ctxt_sede.toUpperCase(),
                ctxt_departamento: data.ctxt_departamento.toUpperCase(),
                ctxt_provincia:data.ctxt_provincia.toUpperCase(),
                ctxt_distrito:data.ctxt_distrito.toUpperCase(),
                ctxt_valor: "S/. " + data.ctxt_valor,
                nid: data.nid,
                nid_autor: data.nid_autor,
                nid_editorial: data.nid_editorial,
                nid_especialidad: data.nid_especialidad
            })
          })
        
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
  confirmarDelete = (data) => {
    var titulo = "";
    var mensaje = "";
    if(data.flg_estado == 'ACTIVO'){
        titulo = "¿Seguro que desea anular el libro?";
        mensaje = "Se anulará el registro seleccionado.";
    }else{
        titulo = "¿Seguro que desea activar el libro?";
        mensaje = "Se activará el registro seleccionado";
    }
    confirmAlert({
      title: titulo,
      message: mensaje,
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };
  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidlibro', data.nid);
    fromData.append('tipo', data.flg_estado == 'ACTIVO' ? 0 : 1);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'biblioteca/manejarLibro', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 1){
            this.listarBiblioteca();
            if(data.flg_estado == "ACTIVO"){
                  this.props.handleClick("tl", "info", "El libro ha sido anulado!", "pe-7s-smile")
            }else{
                  this.props.handleClick("tl", "info", "El libro ha sido activado!", "pe-7s-smile")
            }  
        }else{
            this.props.handleClick("tl", "error", "No se puede manejar el libro!", "pe-7s-close")
            this.setState({isLoading: false})
        }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al manejar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
  /////
  async detalle(data){
    paramCec.biblioteca[0].data = data
    localStorage.setItem('cec', JSON.stringify(paramCec))
    this.props.history.push('/editarbiblioteca');
  }
  async detalle2(){
    this.props.history.push('/crearbiblioteca');
  }
  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Libro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              {row.flg_estado == "INACTIVO" ? (
              <span data-tip="Habilitar Libro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-eye fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
               ) : (null)}
               {row.flg_estado == "ACTIVO" ? (
              <span data-tip="Anular Libro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              ) : (null)}
          </div>
      );
    }else{}
  }
  estado(cell, row){
    if (row != undefined){
      return (
        <div>
            {row.ctxt_ruta == null || row.ctxt_archivo == "0" || row.ctxt_archivo == "--" ? (
              <p><p>{row.flg_estado}</p> {" ("}<a style={{fontSize: 15}}>Libro Físico</a>{")"}</p>
            ) : (
              <p> <p>{row.flg_estado}</p> {" ("}<a style={{fontSize: 15}} href={row.ctxt_ruta} target= "_blank">Ver Libro Digital</a>{")"}</p>
            )}
        </div>
             )
    }else{}
  }
  VerLibro(cell, row) {
    if(row != undefined){
      return (
          <div>
              {row.autor != null && row.autor != '' ? (
                <span  style={{cursor: 'pointer'}}>
                  <p style={{marginTop:20}}>Autor:</p><p style={{fontSize: 13}}>{row.autor}</p>
                </span>
              ) : (null)}

              {row.editorial != null && row.editorial != ''? ( 
                <span  style={{cursor: 'pointer'}}>
                  <p style={{marginTop:20}}>Editorial:</p><p style={{fontSize: 13}}>{row.editorial}</p>
                </span>
              ) : (null)}

              {row.cnum_estante != null && row.cnum_estante != ''? (
                <span  style={{cursor: 'pointer'}}>
                 <p style={{marginTop:20}}>Estante: <a style={{fontSize: 13, color: "black"}}>{row.cnum_estante}</a></p>
                </span>
              ) : (null)}

              {row.cnum_balda != null && row.cnum_balda != ''? (
                <span  style={{cursor: 'pointer'}}>
                <p style={{marginTop:20}}>Balda: <a style={{fontSize: 13, color: "black"}}>{row.cnum_balda}</a></p>
                </span>
              ) : (null)}

              {row.ctxt_paginas != null && row.ctxt_paginas != ''? (
                <span  style={{cursor: 'pointer'}}>
                <p style={{marginTop:20}}>Número de Páginas: </p><p style={{fontSize: 13, color: "black"}}>{row.ctxt_paginas}</p>
                </span>
               ) : (null)}

             
          </div>
           );
    }else{}
  }
  codigo(cell, row) {
    if(row != undefined){
      return (
          <div>
              {row.cod_libro != null && row.cod_libro != '' ? (
                <span  style={{cursor: 'pointer'}}>
                  <p style={{marginTop:20}}>Código:</p><p style={{fontSize: 13}}>{row.cod_libro}</p>
                </span>
              ) : (null)}

              {row.cod_dewey != null && row.cod_dewey != ''? ( 
                <span  style={{cursor: 'pointer'}}>
                 <p style={{fontSize: 13}}>({row.cod_dewey})</p>
                </span>
              ) : (null)}
          </div>
           );
    }else{}
  }
  detcompra(cell, row) {
    if(row != undefined){
      return (
          <div>
              {row.dfec_compra != null && row.dfec_compra != '' ? (
                <span  style={{cursor: 'pointer'}}>
                <p style={{marginTop:20}}>Fecha: <a style={{fontSize: 13, color: "black"}}>{row.dfec_compra}</a></p>
                </span>
              ) : (null)}

              {row.ctxt_valor != null && row.ctxt_valor != ''? ( 
                <span  style={{cursor: 'pointer'}}>
                <p style={{marginTop:20}}>Valor: <a style={{fontSize: 13, color: "black"}}> {row.ctxt_valor}</a></p>
                </span>
              ) : (null)}
          </div>
           );
    }else{}
  }
  direccion(cell, row) {
    if(row != undefined){
      return (
          <div>
              {row.ctxt_sede != null && row.ctxt_sede != '' ? (
                <span  style={{cursor: 'pointer'}}>
                <p style={{marginTop:20}}>Sede: <a style={{fontSize: 13, color: "black"}}>{row.ctxt_sede}</a></p>
                </span>
              ) : (null)}

              {row.ctxt_departamento != null && row.ctxt_departamento != ''? ( 
                <span  style={{cursor: 'pointer'}}>
                <p style={{marginTop:20}}>Departamento: <a style={{fontSize: 13, color: "black"}}>{row.ctxt_departamento}</a></p>
                </span>
              ) : (null)}

              {row.ctxt_provincia != null && row.ctxt_provincia != ''? ( 
                <span  style={{cursor: 'pointer'}}>
                <p style={{marginTop:20}}>Provincia: <a style={{fontSize: 13, color: "black"}}>{row.ctxt_provincia}</a></p>
                </span>
              ) : (null)}

              {row.ctxt_distrito != null && row.ctxt_distrito != ''? ( 
                <span  style={{cursor: 'pointer'}}>
                <p style={{marginTop:20}}>Distrito: <a style={{fontSize: 13, color: "black"}}>{row.ctxt_distrito}</a></p>
                </span>
              ) : (null)}
          </div>
           );
    }else{}
  }


  render() {
    const columns = [
    {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white" ,width:50}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "codigo", text: "CÓDIGO", headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.codigo},
    {dataField: "ctxt_titulo", text: "TÍTULO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:220}, headerAlign: (column, colIndex) => 'center', align: 'center'},
   // {dataField: "ctxt_descripcion", text: "DESCRIPCION", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "especialidad", text: "ESPECIALIDAD", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "compra", text: "DETALLE DE COMPRA", headerStyle: {backgroundColor: '#337AB7', color: "white",width:140}, headerAlign: (column, colIndex) => 'center', align: 'justify',formatter: this.detcompra},
    {dataField: "direccion", text: "DIRECCIÓN GENERAL", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'justify', formatter: this.direccion},
    {dataField: "detalle", text: "DETALLE", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'justify',formatter: this.VerLibro},
    {dataField: "estado", text: "ESTADO", headerStyle: {backgroundColor: '#337AB7', color: "white",width:120}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.estado},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:120}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Libros del CEC</h4>
              <div className="row">
              <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 250, marginTop: 5, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.detalle2() }}>Nuevo Libro</Button>
              </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ConsultarBiblioteca;