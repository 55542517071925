import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar";
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {validator,sisperplus} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class TrabajadoresAreas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarAreas',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      isLoading2: true,
      nombrearea:"",
      nidrep:"",
      listTrabajadoresTC:[],
      listTrabajadoresxArea:[],
      nombre:""
    
     
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);
}
    
 
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.listJefatura()
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }



  async listJefatura() {
    this.setState({isLoading1: true,isLoading2: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'administrador/listaJefatura')
    .then((res) => {
        let lista = [];
      
        let lista2 = res.data.response
        
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              cnom_nombres: data.cnom_nombres + ' ' + data.cape_paterno +' '  + data.cape_materno,
              item: data.item,
              cape_paterno: data.cape_paterno,
              cnum_dni: data.cnum_dni,
              cape_materno: data.cape_materno,
            }) 
          })
          this.setState({listTrabajadoresTC: lista , isLoading: false,isLoading2: false})
          this.listaNoJefatura()
        }else{
          this.setState({listTrabajadoresTC: [], isLoading: false,isLoading2: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false ,isLoading2: false})
      }
    });
  }

  async listaNoJefatura() {
    this.setState({isLoading1: true,isLoading2: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'administrador/listaNoJefatura')
    .then((res) => {
        let lista = [];
      
        let lista2 = res.data.response
        
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              cnom_nombres: data.cnom_nombres + ' ' + data.cape_paterno +' '  + data.cape_materno,
              item: data.item,
              cnum_dni: data.cnum_dni,
              cape_paterno: data.cape_paterno,
              cape_materno: data.cape_materno,
            }) 
          })
          this.setState({listTrabajadoresxArea: lista , isLoading: false,isLoading2: false})
        }else{
          this.setState({listTrabajadoresxArea: [], isLoading: false,isLoading2: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false ,isLoading2: false})
      }
    });
  }
   
    
    confirmarUpdate = (data) => {
      confirmAlert({
        title: '¿Seguro que desea agregarlo?',
        message: 'El trabajador seleccionado se agregará al área: '+this.state.nombrearea,
        buttons: [
          {
            label: 'Aceptar',
            onClick: () => this.update(data)
          },
          {
            label: 'Cancelar',
            onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
          }
        ]
      });
    };

  async update(data){
    this.setState({isLoading: true,isLoading2: true})
    let fromData = new FormData();
    fromData.append('nidperson', data.nidperson);
    fromData.append('nidreplication', this.state.nidrep);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'area/updateTrabajadorArea', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listTrabajadores()
        this.props.handleClick("tl", "info", "El trabajador ha sido agregado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al agregar!", "pe-7s-close")
        this.setState({isLoading: false,isLoading2: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false,isLoading2: false})
      }
    });
  }
  
  async regresar(){
    this.props.history.push("/administrarAreas")
  }
   confirmarActualizarJefaturaHabilitar = (estado, dni) => {
  
  
      confirmAlert({
        title: estado === 1 ? '¿Seguro que desea habilitar jefatura?' : '¿Seguro que desea deshabilitar jefatura?',
        message: estado === 1 ? 'Se habilitará la opción opción jefatura'  : 'Se deshabilitará la opción jefatura' ,
        buttons: [
          {
            label: 'Aceptar',
            onClick: () => {
              this.actualizarJefatura(estado, dni);
            } 
          },
          {
            label: 'Cancelar',
            onClick: () => {
              this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info");
            }
          }
        ]
      });
 }

  

  async actualizarJefatura(estado, dni){
    let fromData = new FormData();
    fromData.append('dni', dni);
    fromData.append('usuario', this.state.usuario);
    fromData.append('estado', estado);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'area/actualizarjefe', fromData)
     .then((response) => {
          var datos = response.data
          if(datos.response != null){
            this.listJefatura();
            this.listaNoJefatura();
          }
          })
          .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
          }else{
              this.setState({isLoading: false})
          }
        }); 
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>      
              <span data-tip="Habilitar Opción" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarActualizarJefaturaHabilitar(1,row.cnum_dni) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-arrow-right fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>                 
          </div>
      );
    }else{}
  }

  GetActionFormat2(cell, row) {
    if(row != undefined){
      return (
          <div>              
              <span  data-tip="Deshabilitar Opción" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarActualizarJefaturaHabilitar(0, row.cnum_dni) }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x text-primary"></i>
              <i className="fa fa-arrow-left fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>
          </div>
      );
    }else{}
  }

  render() {
    const columns = [
        {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:100}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnum_dni", text: "DNI", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnom_nombres", text: "NOMBRE Y APELLIDOS", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'justify'},
        {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2}
    ]
    const columnsTrabajadores = [
        {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:100}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnum_dni", text: "DNI", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnom_nombres", text: "NOMBRE Y APELLIDOS", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'justify'},
        {dataField: "detail", text: "", headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}

       ]
       
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.listTrabajadoresTC.length
    };
    const options2 = {
        custom: true,
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'Primera',
        prePageText: 'Anterior',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        showTotal: true,
        totalSize: this.state.listTrabajadoresxArea.length
      };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div>
         <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.listTrabajadoresTC }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const contentTable2 = ({ paginationProps, paginationTableProps }) => (
        <div>
          <div>
          <div >
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.listTrabajadoresxArea }
              columns={ columnsTrabajadores }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
         </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={6}>
             <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}> 
                <h4 className="title text-center" style={{fontWeight: 200, marginTop: 18}}>TRABAJADORES SIN OPCIÓN DE JEFATURA</h4>  
                  {this.state.isLoading ? (
                          <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.isLoading}
                          />
                        ) : (
                      <div className="row" style={{marginTop: 30, marginBottom: 10}}>
                          <div className="col-md-12">
                                <PaginationProvider
                                  pagination={
                                    paginationFactory(options2)
                                  }>
                                  { contentTable2 }
                                </PaginationProvider>
                          </div>
                      </div>
                    )
                    }
              </div>
              <Button style={{width: 250, marginTop: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
              
            </Col>
            <Col md={6}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>  
                <h4 className="title text-center" style={{fontWeight: 200, marginTop: 18}}>TRABAJADORES CON OPCIÓN HABILITADA DE JEFATURA</h4> 
                {this.state.isLoading2 ? (
                        <ClipLoader
                          css={override}
                          sizeUnit={"px"}
                          size={50}
                          color={'#123abc'}
                          loading={this.state.isLoading2}
                        />
                      ) : (
                      <div className="row" style={{marginTop: 30, marginBottom: 10}}>
                       
                          <div className="col-md-12" >
                                <PaginationProvider
                                  pagination={
                                    paginationFactory(options)
                                  }>
                                  { contentTable }
                                </PaginationProvider>
                            </div>


                      </div>

                      
                    )
                  }
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default TrabajadoresAreas;