import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';

import ClipLoader from 'react-spinners/ClipLoader';
import InputFiles from 'react-input-files';
import axios from 'axios';
import { cec} from 'store/Apis'
import { Card } from "components/Card/Card.jsx";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Right } from "react-bootstrap/lib/Media";
import { data } from "jquery";
import { jurisdiccional } from "store/Apis";
import { Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {Pie,Bar,Doughnut} from 'react-chartjs-2';
import 'chart.piecelabel.js';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';

ChartJS.register(
  ArcElement, Tooltip, Legend
)


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Votantes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'votaciones',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      isLoading2: true,
      nidevento:"",
      evento:"",
      votantes:[],
      cantidad :[],
      opcion:[],
      respuesta:[],
      colores: [],
      lista2:[],
      data1:[],
      data2:{},
      opciones1:{},
      opciones2:{}
  }  
  
}
   
  
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
              this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  
  async validarLocal(){
    if(JSON.parse(localStorage.getItem("jurisdiccional")).evento[0].data !== null){
        this.setState({evento: JSON.parse(localStorage.getItem('jurisdiccional')).evento[0].data.evento,
                       nidevento: JSON.parse(localStorage.getItem('jurisdiccional')).evento[0].data.nid});
        this.listarVotantes();
    }else{
      this.props.history.push('/evento');
    }
  }


  async listarVotantes() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${jurisdiccional}` + 'votaciones/listVotantes?nidevento=' + this.state.nidevento)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              ctxt_nombres: data.ctxt_nombres,
              ctxt_opcion: data.ctxt_opcion,
              item: data.item,nid: data.nid
            })
          })
          this.setState({movimientos: lista, isLoading: false})
          this.listarCantVotantes();
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }


  async listarCantVotantes() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${jurisdiccional}` + 'votaciones/listCantVotaciones?nidevento=' + this.state.nidevento)
    .then((res) => {
        var cantidades=[],opcion=[];
        this.state.lista2 = res.data.response;
        if(this.state.lista2 != null){
          this.state.lista2.map((data) => {
            cantidades.push(data.cantidad);
            opcion.push(data.ctxt_opcion);
          })  
         this.setState({cantidad: cantidades, opcion:opcion, isLoading: false})
         this.generarColores();
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  generarColores(){
    var colores=[];
    for(var i=0; i<this.state.lista2.length; i++){
      colores.push(this.colorHEX());
    }
    this.setState({colores: colores});
  }

  colorHEX(){
    var color = "";
    for(var i=0;i<6;i++){
      color = color + this.generarCaracter() ;
    }
    return "#" + color;
  } 

  generarCaracter(){
    var caracter = ["a","b","c","d","e","f","0","1","2","3","4","5","6","7","8","9"];
    var numero = (Math.random()*15).toFixed(0);
    return caracter[numero];
  }
    
 async regresar(){
      this.props.history.push('/eventos');
    } 

  render() {

   const data1={
      labels : this.state.opcion ,
      datasets:[{
        data: this.state.cantidad,
        backgroundColor: this.state.colores
      }]
      
    };

    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ctxt_nombres", text: "NOMBRES Y", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:300}, headerAlign: (column, colIndex) => 'center', align: 'justify'},
      {dataField: "ctxt_opcion", text: "OPCIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:300}, headerAlign: (column, colIndex) => 'center', align: 'center'} 
       ]
      const options = {
        custom: true,
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'Primera',
        prePageText: 'Anterior',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        showTotal: true,
        totalSize: this.state.movimientos.length
      };
      const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div className="row">
          <div className="col-md-6">
            <div>
              <BootstrapTable
                striped
                hover
                keyField="item"
                data={ this.state.movimientos }
                columns={ columns }
                filter={ filterFactory() }
                noDataIndication="No existen registros para mostrar"
                { ...paginationTableProps }
              />
            </div>
            <div style={{float: "left"}}><PaginationListStandalone { ...paginationProps }/></div>
          </div>
        </div>
      );

    return (
      <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
        <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
           
              <h4 className="title" style={{fontWeight: 200,marginBottom:40}}>RESULTADOS DEL EVENTO:   <a style={{fontWeight: "bold", color: "black"}}>{this.state.evento}</a></h4>
           
             {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
              ) : (
                <div className="row">
                    <div className="col-md-6">
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)}
                      >
                       { contentTable }
                      </PaginationProvider>
                    </div>
                    <div className="col-md-6">
                    <Tabs>
                      <TabList>
                          <Tab>GRÁFICO CIRCULAR</Tab>
                         
                          <Tab>GRÁFICO DOUGHNUT</Tab> 
                      </TabList>
                      <TabPanel>
                      <div style={{width:400, height:400, marginLeft:170}}>
                           <Pie data={data1}  style={{width:'100%', height:'100%'}} /> 
                        </div>
                      </TabPanel>
                     
                      <TabPanel>
                        <div  style={{width:400, height:400, marginLeft:170}}>
                          <Doughnut data={data1} style={{width:'100%', height:'100%'}} />
                        </div>
                      </TabPanel> 
                    </Tabs>
                    </div>
                </div>
                 )} 
                
              </div>
                <Button bsStyle="danger"  style={{marginTop:10}} pullRight fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
  }
  
export default Votantes;