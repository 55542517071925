import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import { BrowserRouter as Router, Route,Switch, Redirect} from 'react-router-dom'
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis'
import ReactTooltip from 'react-tooltip'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";
import store from "store/Store.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { concursos } from 'store/Apis';

import moment from 'moment';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class AudiDetalle extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'audiPostulaciones',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      datos: [],
      academico: [],
      laboralgen: [],
      laboralespe: [],
      cursos: [],
      conocimientos: [],
      declaraciones: [],
      archivos: [],
      anexos: [],
      declaracion: [],
      nombres: '',
      dni: '',
      direccion: '',
      celular: '',
      telefono: '', 
      correo: '',
      nacimiento: '',
      civil: '',
      medio: '',
      departamento: '',
      provincia: '',
      distrito: '',
      totalhoras: '',
      totalanios: '',
      totalanios2: '',
      totalanios3: '',
      totalmeses: '',
      totalmeses2: '',
      totaldias: '',
      totaldias2: '',
      isLoading: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if( localStorage.getItem("postulante") !== null){
        this.setState({nombres: JSON.parse(localStorage.getItem('postulante')).nombres, 
                       dni: JSON.parse(localStorage.getItem('postulante')).dni
                     });
        let nid = JSON.parse(localStorage.getItem('postulante')).nidpostulante;
        this.listarPostulantes(nid);
    }else{
      this.props.history.push('/audiPostulantes');
    }
  }

  async listarPostulantes(nid) {
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${concursos}` + 'private/listdetalle?nidpostulante=' + nid)
      .then((res) => {
        let lista = res.data.response;
        if(lista != null){
          var horas = 0;
          var anios = 0;
          var anios2 = 0;
          var anios3 = 0;
          var meses = 0;
          var meses2 = 0;
          var dias = 0;
          var dias2 = 0;
          lista.listcursos.map((data) => {
            horas = horas + parseInt(data.cnum_horas);
          });
          lista.listacademico.map((data) => {
            anios = anios + parseInt(data.cnum_anios);
          });
          lista.listlaboralgeneral.map((data) => {
            anios2 = anios2 + parseInt(data.cnum_anios);
            meses = meses + parseInt(data.cnum_meses);
            dias = dias + parseInt(data.cnum_dias);
          });
          var mesesx = dias / 30;
          mesesx = mesesx.toString().split('.');
          mesesx = mesesx[0];
          dias = dias - (parseInt(mesesx) * 30);
          mesesx = meses + parseInt(mesesx);
          var anios2x = mesesx / 12;
          anios2x = anios2x.toString().split('.');
          anios2x = anios2x[0]
          anios2 = anios2 + (parseInt(anios2x))
          meses = mesesx - (parseInt(anios2x) * 12);
          lista.listlaboralespecifico.map((data) => {
            anios3 = anios3 + parseInt(data.cnum_anios);
            meses2 = meses2 + parseInt(data.cnum_meses);
            dias2 = dias2 + parseInt(data.cnum_dias);
          });
          var meses2x = dias2 / 30;
          meses2x = meses2x.toString().split('.');
          meses2x = meses2x[0];
          dias2 = dias2 - (parseInt(meses2x) * 30);
          meses2x = meses2 + parseInt(meses2x);
          var anios3x = meses2x / 12;
          anios3x = anios3x.toString().split('.');
          anios3x = anios3x[0]
          anios3 = anios3 + (parseInt(anios3x))
          meses2 = meses2x - (parseInt(anios3x) * 12);
          this.setState({direccion: lista.listdatos[0].cnom_direccion,
                         celular: lista.listdatos[0].cnum_celular,
                         telefono: lista.listdatos[0].cnum_telefono, 
                         correo: lista.listdatos[0].ctxt_correo,
                         nacimiento: lista.listdatos[0].dfec_nacimiento,
                         civil: lista.listdatos[0].cnom_civil,
                         medio: this.verificarMedio(lista.listdatos[0].ctxt_medio),
                         departamento: lista.listdatos[0].ctxt_departamento,
                         provincia: lista.listdatos[0].ctxt_provincia,
                         distrito: lista.listdatos[0].ctxt_distrito,
                         datos: lista.listdatos,
                         academico: lista.listacademico, 
                         laboralgen: lista.listlaboralgeneral, 
                         laboralespe: lista.listlaboralespecifico, 
                         cursos: lista.listcursos,
                         conocimientos: lista.listconocimientos,
                         declaraciones: lista.listdeclaraciones,
                         archivos: lista.listarchivos,
                         anexos: lista.listanexos,
                         declaracion: lista.listarchivodeclaracion,
                         totalhoras: ''+horas + ' horas',
                         totalanios: ''+anios + ' años',
                         totalanios2: ''+anios2 + ' años',
                         totalanios3: ''+anios3 + ' años',
                         totalmeses: ''+meses + ' meses',
                         totalmeses2: ''+meses2 + ' meses',
                         totaldias: ''+dias + ' dias',
                         totaldias2: ''+dias2 + ' dias',
                         isLoading: false
                       })
        }else{
          this.setState({postulantes: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  verificarMedio(nid){
    let medio = '';
    switch(nid) {
        case 0:
            medio = 'WEB SERVIR';
          break;
        case 1:
            medio = 'REDES SOCIALES';
          break;
        case 2:
            medio = 'DIARIO EL COMERCIO';
          break;
        case 3:
            medio = 'APTITUS';
          break;
        case 4:
            medio = 'BUMERAN';
          break;
        case 5:
            medio = 'BOLSAS DE UNIVERSIDADES';
          break;
        case 6:
            medio = 'OTROS';
          break;
        default:
          medio = '';
    }
    return medio
  }

  async ver(row){
      window.open(row.ctxt_ruta)
  }

  async cancelar(){
    this.props.history.push('/audiPostulantes');
  }

  GetActionFormat(cell, row) {
      return (
          <div>
              <span data-tip="Ver Documento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                <i className="fa fa-file fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
  }

  GetActionFormat2(cell, row) {
    return (
        <div>
            <span data-tip="Ver Funciones" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x text-primary"></i>
              <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>
        </div>
    );
}

  render() {
    const columns9 = [
      {dataField: "ctxt_colegio", text: "COLEGIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ctxt_habilitado", text: "HABILITADO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "dfec_colegiacion", text: "FECHA DE COLEGIACIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "cnum_folios", text: "N° DE FOLIOS", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const columns = [
      {dataField: "ctxt_formacion", text: "FORMACIÓN", footer: 'TOTAL DE AÑOS', footerAlign: 'center', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ctxt_especialidad", text: "ESPECIALIDAD", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ctxt_institucion", text: "INSTITUCIÓN", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "ctxt_nivel", text: "NIVEL", sort: true, footer: '', headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "dfec_inicio", text: "FECHA DE INICIO", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "dfec_final", text: "FECHA DE TERMINO", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "cnum_anios", text: "N° DE AÑOS", footer: this.state.totalanios, footerAlign: 'center', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "cnum_folios", text: "N° DE FOLIOS", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const columns2 = [
        {dataField: "ctxt_cargo", text: "CARGO", footer: 'TOTAL', footerAlign: 'center', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "ctxt_empresa", text: "EMPRESA / INSTITUCIÓN", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "ctxt_area", text: "ÁREA", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "ctxt_sector", text: "SECTOR", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "funciones", text: "FUNCIONES", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},
        {dataField: "dfec_inicio", text: "FECHA DE INICIO", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "dfec_final", text: "FECHA DE TERMINO", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnum_anios", text: "N° DE AÑOS", footer: this.state.totalanios2, footerAlign: 'center', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnum_meses", text: "N° DE MESES", footer: this.state.totalmeses, footerAlign: 'center', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnum_dias", text: "N° DE DIAS", footer: this.state.totaldias, footerAlign: 'center', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnum_folios", text: "N° DE FOLIOS", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const columns3 = [
        {dataField: "ctxt_cargo", text: "CARGO", footer: 'TOTAL', footerAlign: 'center', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "ctxt_empresa", text: "EMPRESA / INSTITUCIÓN", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "ctxt_area", text: "ÁREA", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "ctxt_sector", text: "SECTOR", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "funciones", text: "FUNCIONES", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},
        {dataField: "dfec_inicio", text: "FECHA DE INICIO", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "dfec_final", text: "FECHA DE TERMINO", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnum_anios", text: "N° DE AÑOS", footer: this.state.totalanios3, footerAlign: 'center', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnum_meses", text: "N° DE MESES", footer: this.state.totalmeses2, footerAlign: 'center', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnum_dias", text: "N° DE DIAS", footer: this.state.totaldias2, footerAlign: 'center', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnum_folios", text: "N° DE FOLIOS", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const columns4 = [
        {dataField: "ctxt_curso", text: "CURSO / ESPECIALIDAD", footer: 'TOTAL DE HORAS', footerAlign: 'center', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "ctxt_institucion", text: "INSTITUCIÓN", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnum_anio", text: "PERIODO", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnum_horas", text: "N° DE HORAS", footer: this.state.totalhoras, footerAlign: 'center', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnum_folios", text: "N° DE FOLIOS", footer: '', sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const columns5 = [
        {dataField: "titulo", text: "CONOCIMIENTO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "dominio", text: "NIVEL DE DOMINIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const columns6 = [
        {dataField: "pregunta", text: "DECLARACIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "ctxt_descripcion", text: "RESPUESTA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    ]
    const columns7 = [
        {dataField: "ctxt_tipo", text: "TIPO DE ARCHIVO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnom_archivoriginal", text: "NOMBRE DEL ARCHIVO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}

    ]
    const columns8 = [
        {dataField: "ctxt_descripcion", text: "TIPO DE ANEXO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cnom_archivoriginal", text: "NOMBRE DEL ANEXO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const columns10 = [
      {dataField: "cnom_archivoriginal", text: "NOMBRE DEL DOCUMENTO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const expandRow = {
      onlyOneExpanding: true,
      renderer: row => (
        <div>
          <h4 style={{marginTop: 5, fontSize: 18, textAlign: 'center'}} className="title">Funciones:</h4>
          <div className="row">
            <div className="col-md-12">
              <pre>{row.ctxt_funciones}</pre>
            </div>
          </div>
        </div>
      )
    };
    const expandRow2 = {
      onlyOneExpanding: true,
      renderer: row => (
        <div>
          <h4 style={{marginTop: 5, fontSize: 18, textAlign: 'center'}} className="title">Funciones:</h4>
          <div className="row">
            <div className="col-md-12">
              <pre>{row.ctxt_funciones}</pre>
            </div>
          </div>
        </div>
      )
    };
    const {nombres, dni, direccion, celular, telefono, correo, nacimiento, civil, medio, departamento, provincia, distrito} = this.state
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Detalle del postulante: {nombres + ' (' + dni + ')'}</h4>
              {this.state.isLoading ? (
                    <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>DIRECCIÓN</ControlLabel>
                            <FormControl type="text" value={direccion} onChange= {this.handleDireccion} disabled/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>CELULAR</ControlLabel>
                            <FormControl type="text" value={celular} onChange= {this.handleCelular} disabled/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>TELEFONO</ControlLabel>
                            <FormControl type="text" value={telefono} onChange= {this.handleTelefono} disabled/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>CORREO ELECTRÓNICO</ControlLabel>
                            <FormControl type="text" value={correo} onChange= {this.handleCorreo} disabled/>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DE NACIMIENTO</ControlLabel>
                            <FormControl type="text" value={nacimiento} onChange= {this.handleNacimiento} disabled/>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>ESTADO CIVIL</ControlLabel>
                            <FormControl type="text" value={civil} onChange= {this.handleCivil} disabled/>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>MEDIO DEL PROCESO</ControlLabel>
                            <FormControl type="text" value={medio} onChange= {this.handleMedio} disabled/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>DEPARTAMENTO</ControlLabel>
                            <FormControl type="text" value={departamento} onChange= {this.handleDepartamento} disabled/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>PROVINCIA</ControlLabel>
                            <FormControl type="text" value={provincia} onChange= {this.handleProvincia} disabled/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>DISTRITO</ControlLabel>
                            <FormControl type="text" value={distrito} onChange= {this.handleDistrito} disabled/>
                        </div>
                    </div>
                    <div>
                     <h4 className="category" style={{fontSize: 15, marginTop: 10}}>Detalle de colegiatura del postulante:</h4>
                     <BootstrapTable striped hover keyField='item' data={ this.state.datos } columns={ columns9 }/>
                   </div>
                   <div>
                    <h4 className="category" style={{fontSize: 15, marginTop: 10}}>Detalle académico del postulante:</h4>
                    <BootstrapTable striped hover keyField="item" data={ this.state.academico } columns={ columns }/>
                   </div>
                   <div>
                    <h4 className="category" style={{fontSize: 15, marginTop: 10}}>Detalle laboral general del postulante:</h4>
                    <BootstrapTable striped hover keyField="item" data={ this.state.laboralgen } columns={ columns2 } expandRow={ expandRow }/>
                  </div>
                  <div>
                    <h4 className="category" style={{fontSize: 15, marginTop: 10}}>Detalle laboral específico del postulante:</h4>
                    <BootstrapTable striped hover keyField="item" data={ this.state.laboralespe } columns={ columns3 } expandRow={ expandRow2 }/>
                  </div>
                  <div>
                    <h4 className="category" style={{fontSize: 15, marginTop: 10}}>Detalle de cursos del postulante:</h4>
                    <BootstrapTable striped hover keyField="item" data={ this.state.cursos } columns={ columns4 }/>
                  </div>
                  <div>
                    <h4 className="category" style={{fontSize: 15, marginTop: 10}}>Detalle de conocimientos del postulante:</h4>
                    <BootstrapTable striped hover keyField="item" data={ this.state.conocimientos } columns={ columns5 }/>
                  </div>
                  <div>
                    <h4 className="category" style={{fontSize: 15, marginTop: 10}}>Detalle de declaraciones del postulante:</h4>
                    <BootstrapTable striped hover keyField="item" data={ this.state.declaraciones } columns={ columns6 }/>
                  </div>
                  <div>
                    <h4 className="category" style={{fontSize: 15, marginTop: 10}}>Detalle de archivos del postulante:</h4>
                    <BootstrapTable striped hover keyField="item" data={ this.state.archivos } columns={ columns7 }/>
                  </div>
                  <div>
                    <h4 className="category" style={{fontSize: 15, marginTop: 10}}>Detalle de anexos del postulante:</h4>
                    <BootstrapTable striped hover keyField="item" data={ this.state.anexos } columns={ columns8 }/>
                  </div>
                  <div>
                    <h4 className="category" style={{fontSize: 15, marginTop: 10}}>Documento de declaración:</h4>
                    <BootstrapTable striped hover keyField="item" data={ this.state.declaracion } columns={ columns10 }/>
                  </div>
                </div>
                )}
              </div>
            </Col>
            <Button style={{marginTop: 20, width: 150, marginRight: 25}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Volver</Button>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AudiDetalle;