import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { jurisdiccional} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ModeloIntegridad extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'modeloIntegridad',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      nid:"",
      descripcion: "",
      archivo: "",
      enlace:"",
      isLoading: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.ver=this.ver.bind(this);
    this.handleDescripcion=this.handleDescripcion.bind(this);
    this.handleArchivo=this.handleArchivo.bind(this);
    this.handleEnlace=this.handleEnlace.bind(this);

  }
    handleDescripcion(event) {this.setState({descripcion: event.target.value});}
    handleArchivo(event) {this.setState({archivo: event.target.value});}
    handleEnlace(event) {this.setState({enlace: event.target.value});}
  
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
             this.listarModelo();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
  async listarModelo() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${jurisdiccional}` + 'modelo/listModelo')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                descripcion: data.ctxt_descripcion,
                archivo: data.ctxt_archivo,
                enlace: data.ctxt_enlace, 
                fecha: Calendar.calendarioA(data.dfec_creacion),
                item: data.item,
                nid: data.nid
             })
          })
         this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea anular el registro?',
      message: 'Se anulará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'modelo/anularModelo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 1){
            this.listarModelo();
            this.props.handleClick("tl", "info", "El registro ha sido anulado!", "pe-7s-smile")
        }else{
            this.props.handleClick("tl", "error", "No se puede anular el registro!", "pe-7s-close")
            this.setState({isLoading: false})
        }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async obtener(data){
    this.setState({
      enlace: data.enlace,
      archivo: data.archivo,
      descripcion: data.descripcion,
      nid: data.nid,
    })
  }

  
  async save(){
    if(this.state.descripcion != "" && this.state.enlace != "" && this.state.archivo != ""){
      if(this.state.nid == ''){
        this.guardar()
      }else{
        this.update()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  } 

  async guardar(){  
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('descripcion', this.state.descripcion.toUpperCase());
    fromData.append('archivo', this.state.archivo);
    fromData.append('enlace',this.state.enlace);
    fromData.append('usuario',this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'modelo/insertModelo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar();
          this.props.handleClick("tl", "info", "Registro satisfactorio!", "pe-7s-smile")
     }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async update(){  
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', this.state.nid); 
    fromData.append('descripcion', this.state.descripcion.toUpperCase());
    fromData.append('archivo', this.state.archivo);
    fromData.append('enlace',this.state.enlace);
    fromData.append('usuario',this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'modelo/updateModelo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Registro actualizado correctamente!", "pe-7s-close")
        this.limpiar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async limpiar(){
    this.listarModelo();
    this.cancelar();
  }

  async cancelar(){
    this.setState({descripcion: "", enlace: "", archivo: "", nid: ""})
  }


  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
             
              <span data-tip="Anular Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }
  
  ver(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Ver Documento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrir(row.enlace) }}>
                <ReactTooltip />
                <i className="fa fa-file-pdf-o fa-stack-1x fa-inverse" style={{fontSize: 25, color: "red"}}></i>
              </span>
               
          </div>
      );
    }else{}
  }


  abrir(ruta){
    window.open(ruta, '_blank');
  } 

  mostrar = () => {
    confirmAlert({
      title: <i class="fa fa-user" style={{ color :"black", marginLeft: 130,fontSize: 100}}></i>,
      message:<div><p>USUARIO : Usuario_01</p><p>CONTRASEÑA : Tribunal2022$</p> </div> ,
      buttons: [
        {
          label: 'OK',
        },
        
      ],
      closeOnClickOutside: false,
    });
  };
  

  render() {
    const columns = [
    {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "descripcion", text: "DESCRIPCIÓN", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:400}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "archivo", text: "NOMBRE DEL DOCUMENTO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:400}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "enlace", text: "DOCUMENTO",  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.ver},
    {dataField: "fecha", text: "FECHA DE CREACIÓN",  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
             <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>MODELOS DE INTEGRIDAD</h4>

               <div className="row">
                    <div className="col-md-8" style={{marginBottom: 18}}>
                        <ControlLabel><i class="fa fa-align-justify"></i>DESCRIPCIÓN</ControlLabel>
                        <FormControl type="text" value={this.state.descripcion} onChange= {this.handleDescripcion}/>
                    </div>
                    <div className="col-md-4" style={{marginBottom: 18}}>
                        <ControlLabel><i class="fa fa-file-pdf-o"></i> NOMBRE DEL ARCHIVO</ControlLabel>
                        <FormControl type="text" value={this.state.archivo} onChange= {this.handleArchivo}/>
                    </div>
                    <div className="col-md-8" style={{marginBottom: 18}}>
                        <ControlLabel><i class="fa fa-link"></i> ENLACE</ControlLabel>
                        <FormControl type="text" value={this.state.enlace} onChange= {this.handleEnlace}/>
                    </div>
                    <div className="col-md-4" style={{marginBottom: 18}}>
                      <Button style={{width: 150,marginTop:25,marginRight:5}} bsStyle="primary" pullRight  type="submit" onClick={() => { this.mostrar() }}>Ver Credenciales</Button>
                      <Button style={{width: 330,marginTop:25,marginRight:5}} bsStyle="primary" pullRight  type="submit" onClick={() => { this.abrir("https://www.tc.gob.pe/wp-login.php") }}>Haga click aquí para subir el documento</Button>

                    </div>
                      <Button style={{width: 150,marginTop:25,marginRight:20,marginBottom:10}} bsStyle="danger" pullRight  fill type="submit" onClick={() => { this.cancelar() }}>Cancelar</Button>
                      <Button style={{width: 150,marginTop:25,marginRight:5,marginBottom:10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.save() }}>Guardar</Button>

              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
             

            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ModeloIntegridad;