import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { sisperplus} from 'store/Apis'
import 'react-tabs/style/react-tabs.css';
import moment from 'moment'
import Select from 'react-select';
import 'react-accessible-accordion/dist/fancy-example.css';
import Swal from 'sweetalert2'
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class DetalleDesplazamiento extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'NuevoDesplazamiento',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      
      isLoading: false,
      disabled: true,
      nid: '',
      nid_person: '',

      dni: '',
      nombres: '',
      direccion: '',
      nidRegimen: '',
      Regimen: '',
      remuneracion: '',
      correo: '',
      fechaIn: '',
      fechaF: '',

      proceso: '',
      

      actoResolutivo1: '',
      contrato1: '',
      docSustento1: '',
      docAutorizaDesplazamiento1: '',


      puestoDestinoRemuneracion: '',
      puestoDestino: '',
      puestosDestinos: [],

      regimenDestino: '',
      regimenDestinos: [],
      tipoDesplazamiento: '',
      tipoDesplazamientos: [],
      cargod : '',
      cargods: [],
      unidadOrganica: '',
      unidadOrganicas: [],

      unidadOrganica2: '',
      unidadOrganicas2: [],

      fecha1:  moment(new Date()).format('YYYY-MM-DD'),
      fecha2:  moment(new Date()).format('YYYY-MM-DD'),
      fechaInicio:  moment(new Date()).format('YYYY-MM-DD'),
      fechaFin:  moment(new Date()).format('YYYY-MM-DD'),

      archivoResolutivo: null,  
      archivoContratos: null,
      archivoNecesidad: null,
      archivoDesplazamiento: null,

      
      perfiles: [    
        {value: "1", label: "SI"},
        {value: "2", label: "NO"}
      ],
      
      aceptaciones: [    
        {value: "1", label: "SI"},
        {value: "2", label: "NO"}
      ],
        
        plazas: [    
            {value: "1", label: "SI"},
            {value: "2", label: "NO"},
            {value: "3", label: "NO APLICA"}
        ],
        
        cargos: [    
            {value: "1", label: "SI"},
            {value: "2", label: "NO"}
        ],
        
        comunicars: [    
            {value: "1", label: "SI"},
            {value: "2", label: "NO"}
        ],

        servidores: [    
            {value: "1", label: "SI"},
            {value: "2", label: "NO"}
        ],

        origenes: [    
            {value: "1", label: "SI"},
            {value: "2", label: "NO"}
        ],

        destinos: [    
            {value: "1", label: "SI"},
            {value: "2", label: "NO"}
        ],

        expedientes: [    
            {value: "1", label: "SI"},
            {value: "2", label: "NO"}
        ],
    
    }

    this.handleDNI = this.handleDNI.bind(this);
    this.handleNombres = this.handleNombres.bind(this);
    this.handleDireccion = this.handleDireccion.bind(this);
    this.handleRegimen = this.handleRegimen.bind(this);
    this.handleRemuneracion = this.handleRemuneracion.bind(this);
    this.handleCorreo = this.handleCorreo.bind(this);
    this.handleFechaIn = this.handleFechaIn.bind(this);
    this.handleFechaF = this.handleFechaF.bind(this);
    this.handleRemuneracionDestino = this.handleRemuneracionDestino.bind(this);

    this.handleProceso = this.handleProceso.bind(this);

    this.handlePuestoDestino = this.handlePuestoDestino.bind(this);
    this.handleRegimenDestino = this.handleRegimenDestino.bind(this);
    this.handleTipoDesplazamiento = this.handleTipoDesplazamiento.bind(this);
    this.unidadOrganica = this.handleOrganica.bind(this);
    this.unidadOrganica2 = this.handleOrganica2.bind(this);
    this.handleCargod = this.handleCargod.bind(this);
    this.handleFecha1 = this.handleFecha1.bind(this);
    this.handleFecha2 = this.handleFecha2.bind(this);
    this.handleFechaInicio = this.handleFechaInicio.bind(this);
    this.handleFechaFin = this.handleFechaFin.bind(this);

  }

    

    handleDNI(event){this.setState({dni: event.target.value});}
    handleNombres(event){this.setState({nombres: event.target.value});}
    handleDireccion(event){this.setState({direccion: event.target.value});}
    handleRegimen(event){this.setState({Regimen: event.target.value});}
    handleRemuneracion(event){this.setState({remuneracion: event.target.value});}
    handleCorreo(event){this.setState({correo: event.target.value});}
    handleFechaIn(event){this.setState({fechaIn: event.target.value});}
    handleFechaF(event){this.setState({fechaF: event.target.value});}

    handleProceso(event){this.setState({proceso: event.target.value});}

    handlePuestoDestino(event){this.setState({puestoDestino: event.target.value});}
    handleRegimenDestino(event){this.setState({regimenDestino: event.target.value});}
    handleTipoDesplazamiento(event){this.setState({tipoDesplazamiento: event.target.value});}
    handleOrganica(event){this.setState({unidadOrganica: event.target.value});}
    handleOrganica2(event){this.setState({unidadOrganica2: event.target.value});}
    handleCargod(event){this.setState({cargod: event.target.value});}
    handleFecha1(event){this.setState({fecha1: event.target.value});}
    handleFecha2(event){this.setState({fecha2: event.target.value});}
    handleFechaInicio(event){this.setState({fechaInicio: event.target.value});}
    handleFechaFin(event){this.setState({fechaFin: event.target.value});}
    handleRemuneracionDestino(event){this.setState({remuneracionDestino: event.target.value});}

    
    handlePerfil = (perfil) => {
        this.setState(
            {perfil},
        );
    }
    handleAceptacion = (aceptacion) => {
        this.setState(
            {aceptacion},
        );
    }
    handlePlaza = (plaza) => {
        this.setState(
            {plaza},
        );
    }
    handleCargo = (cargo) => {
        this.setState(
            {cargo},
        );
    }
    handleComunicar = (comunicar) => {
        this.setState(
            {comunicar},
        );
    }
    handleServidor = (servidor) => {
        this.setState(
            {servidor},
        );
    }
    handleOrigen = (origen) => {
        this.setState(
            {origen},
        );
    }
    handleDestino = (destino) => {
        this.setState(
            {destino},
        );
    }
    handleExpediente = (expediente) => {
        this.setState(
            {expediente},
        );
    }
    handleArchivoResolutivo = (event) => {this.setState({archivoResolutivo: event.target.files[0]})}
    handleArchivoNecesidad = (event) => {this.setState({archivoNecesidad: event.target.files[0]})}
    handleArchivoDesplazamiento = (event) => {this.setState({archivoDesplazamiento: event.target.files[0]})}
    handleArchivoContrato = (event) => {this.setState({archivoContratos: event.target.files[0]})}

    //change
    changeCargod = cargod => {
      this.setState({ cargod });
    };
    changeOrganica = unidadOrganica => {
        this.setState({ unidadOrganica });
    };
    changeOrganica2 = unidadOrganica2 => {
      this.setState({ unidadOrganica2 });
  };

    changeTipoDesplazamiento = tipoDesplazamiento => {
        this.setState({ tipoDesplazamiento });
    };

    changeRegimenDestino = regimenDestino => {
        this.setState({ regimenDestino });
    };

    changePuestoDestino = puestoDestino => {
        this.setState({ puestoDestino });
    };

    // listar DNI
    async listarDniX(dni) {
            axios.defaults.headers.common['Authorization'] = this.state.token
            axios.get(`${sisperplus}` + 'trabajadores/listTrabajadoresxDni?dni=' + dni)
            .then((res) => {
              this.setState({isLoading: false})
            let data = res.data;
            if(data.response != null && data.response.length > 0){
                this.setState({
                    nombres: data.response[0].Name ,
                    direccion: data.response[0].direccion,
                    Regimen: data.response[0].regLabOrigen,
                    correo: data.response[0].Email,
                    fechaIn: this.convertTimestampToDate(data.response[0].fec_inicioContrato),
                    fechaF: this.convertTimestampToDate(data.response[0].fec_finContrato),
                    nid_person: data.response[0].nid_person,
                    nidRegimen: data.response[0].nid_regLabOrigen,
                })
            }else{
               this.setState({isLoading: false})
               this.props.handleClick("tl", "error", "Usuario No encontrado!", "pe-7s-info")
            }
            })
            .catch((error) => { 
              if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
              }else{
                this.setState({isLoading: false})
              }
            });
      }

  async regresar(){
    this.props.history.push('/administrarDesplazamientos');
  }

  convertTimestampToDate(data) {
    if (!data) {
      return null;
    }
  
    const timestamp = Number(data);
  
    if (!isNaN(timestamp)) {
      const date = new Date(timestamp);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }
  
    return data; 
  }
  
    componentDidMount(){
      this.listarPrincipal()
  //   if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
  //       axios.get(validator + this.state.path + '/'+ this.state.usuario, {
  //       headers: {
  //           Authorization: this.state.token
  //       }
  //       }).then((response) => {
  //           let permisos = response.data;
  //           console.log(permisos);
  //           if(permisos.codError != 1){
  //               this.props.history.goBack()
  //           }else{
  //             this.listarPrincipal()
  //           }
  //       },(error) => {
  //           if(auth0Client.isAuthenticated() === true){
  //           auth0Client.signOut()
  //           this.props.history.replace('/');
  //           }else{
  //           this.props.history.goBack()
  //           }
  //       });
  //   }else{
  //       auth0Client.signOut();
  //       this.props.history.replace('/');
  //   }
  }
  listarPrincipal(){
    let data = JSON.parse(localStorage.getItem('desplazamiento'));
      if (data !=null ){
        this.setState({isLoading: true})
        this.listarCargo(data.nid_puestoOrigen)
        this.listarDniX(data.dni)
        this.listarTipodesplazamiento(data.nid_tipoDesp)
        this.listarUnidadOrganica(data.nid_unidadOrganica, data.nid_OrganicaDestino)
        this.listarRegimenDestino(data.ctxt_regLabDestino)
        this.listPuestoDestino(data.nid_puestoDestino)

        this.setState({
          dni: data.dni,
          proceso: data.ctxt_nProcesoSel,

          actoResolutivo1: data.rdoc_actoRes,
          contrato1: data.rdoc_nContrato,
          docSustento1: data.docSustento,
          docAutorizaDesplazamiento1: data.docAutorizacion,
          fecha1: this.convertTimestampToDate(data.dfec_actoRes),
          fecha2: this.convertTimestampToDate(data.dfec_nContrato),
          plaza: {value: data.ctxt_revisionPlazaPresup, label: data.ctxt_revisionPlazaPresup == 1 ? 'SI' : data.ctxt_revisionPlazaPresup == 3 ? 'NO APLICA' : 'NO'},
          perfil: {value: data.ctxt_perfil, label: data.ctxt_perfil == 1 ? 'SI' : 'NO'},
          aceptacion: {value: data.ctxt_aceptaExpresa, label: data.ctxt_aceptaExpresa == 1 ? 'SI' : 'NO'},
          cargo: {value: data.ctxt_enCargoArea, label: data.ctxt_enCargoArea == 1 ? 'SI' : 'NO'},
          servidor: {value: data.ctxt_comunicarServidor, label: data.ctxt_comunicarServidor == 1 ? 'SI' : 'NO'},
          origen: {value: data.ctxt_comunicarAreaOrigen, label: data.ctxt_comunicarAreaOrigen == 1 ? 'SI' : 'NO'},
          destino: {value: data.ctxt_comunicarAreaDestino, label: data.ctxt_comunicarAreaDestino == 1 ? 'SI' : 'NO'},
          expediente: {value: data.ctxt_expRemLegajo, label: data.ctxt_expRemLegajo == 1 ? 'SI' : 'NO'},
          nid: data.nid,
          fechaInicio: this.convertTimestampToDate(data.fechaInicio),
          fechaFin: this.convertTimestampToDate(data.fechaTermino),
          remuneracion: data.ctxt_remOrigen,
          remuneracionDestino: data.ctxt_remDestino,
        })
        console.log(this.state.actoResolutivo1);
      } else {
        this.props.history.push('/administrarDesplazamientos');
      }
  }
  async listarCargo(nid){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'trabajadores/listCargo')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.Position,
              label: data.Description,       
            })
        })
        const cargoEncontrado = lista.find(cargo => cargo.value === nid);
        if (cargoEncontrado) {
          this.setState({
             cargods: lista,
             cargod: {
                 value: cargoEncontrado.value,
                 label: cargoEncontrado.label
             }
          })
         } else {
          this.setState({cargods: lista, cargod: {value: lista[0].value, label: lista[0].label}})
          }
      }else{
        this.setState({cargods: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        this.setState({isLoading: false})
      }
    });
  }
  async listarUnidadOrganica(nid, nid2){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'desplazamientos/listUnidadOrganica')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_nombre,       
            })
        })
        
         const unidadEncontrada = lista.find(unidad => unidad.value === nid);
         const unidadEncontrada2 = lista.find(unidad => unidad.value === parseInt(nid2));
         if (unidadEncontrada) {
            this.setState({
               unidadOrganicas: lista,
               unidadOrganica: {
                   value: unidadEncontrada.value,
                     label: unidadEncontrada.label
               }
            })
         } else {
            this.setState({unidadOrganicas: lista, unidadOrganica: {value: lista[0].value, label: lista[0].label}})
         }
         if (unidadEncontrada2) {
          this.setState({
             unidadOrganicas2: lista,
             unidadOrganica2: {
                 value: unidadEncontrada2.value,
                   label: unidadEncontrada2.label
             }
          })
         } else {
            this.setState({unidadOrganicas2: lista, unidadOrganica2: {value: lista[0].value, label: lista[0].label}})
         }
        
      }else{
        this.setState({unidadOrganicas: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => {
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        this.setState({isLoading: false})
      }
    });
  }

  async listarTipodesplazamiento(nid){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'desplazamientos/listTipoDesplazamiento')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_nombre,       
            })
        })
          const tipoEncontrado = lista.find(tipo => tipo.value === nid);
          if (tipoEncontrado) {
            this.setState({
               tipoDesplazamientos: lista,
               tipoDesplazamiento: {
                   value: tipoEncontrado.value,
                     label: tipoEncontrado.label
               }
            })} else {
                  this.setState({tipoDesplazamientos: lista, tipoDesplazamiento: {value: lista[0].value, label: lista[0].label}})
            }
      }else{
        this.setState({tipoDesplazamientos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => {
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        this.setState({isLoading: false})
      }
    });
  }

  async listarRegimenDestino(nid){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'regimen/listRegimen')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.PayRollType,
              label: data.ShortName,       
            })
        })
        const regimenEncontrado = lista.find(regimen => regimen.value === nid);
        if (regimenEncontrado) {
         this.setState({
           regimenDestinos: lista,
           regimenDestino: { 
             value: regimenEncontrado.value, 
             label: regimenEncontrado.label 
           }
         })
         } else {
         this.setState({regimenDestinos: lista, regimenDestino: {value: lista[0].value, label: lista[0].label}})
         }
      }else{
        this.setState({regimenDestinos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => {
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        this.setState({isLoading: false})
      }
    });
  }
  async listPuestoDestino(nid){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'cargos/listCargo')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.Position,
              label: data.Description,
              remuneracion: data.jornal == null ? '00' : data.jornal,     
            })
        })
        const puestoEncontrado = lista.find(puesto => puesto.value === nid);
         if (puestoEncontrado) {
            this.setState({
               puestosDestinos: lista,
               puestoDestino: {
                   value: puestoEncontrado.value,
                  label: puestoEncontrado.label,
                  remuneracion: puestoEncontrado.remuneracion
               }
            })
         } else {
            this.setState({puestosDestinos: lista, puestoDestino: {value: lista[0].value, label: lista[0].label}})
         }

      }else{
        this.setState({puestosDestinos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => {
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        this.setState({isLoading: false})
      }
    });
  }


  async editar(){
    this.setState({disabled: false})
  }

  async verDocumento(link){
      window.open(link, '_blank');
  }
  async validar(){
    Swal.fire({
      title: "Editando Desplazamiento",
      text: "Espere por favor...",
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
      allowOutsideClick:false
    })
    let fromData = new FormData();

    fromData.append('nid', this.state.nid)
    fromData.append('nid_person', this.state.nid_person);                       
    fromData.append('dni', this.state.dni);
    fromData.append('regLab_Origen', this.state.nidRegimen);
    fromData.append('regLab_Destino', this.state.regimenDestino.value);
    fromData.append('nid_puestoOrigen', this.state.cargod.value);
    fromData.append('nProcesoSeleccion', this.state.proceso);
    fromData.append('nid_unidadOrganica', this.state.unidadOrganica.value);
    fromData.append('nid_unidadOrganicaDestino', this.state.unidadOrganica2.value);
    if (this.state.archivoResolutivo !== null) {
      fromData.append('file_actoRes', this.state.archivoResolutivo);
      fromData.append('doc_actoRes', this.state.archivoResolutivo.name);
      fromData.append('nom_file_actoRes', this.state.actoResolutivo1);
    } else {
      fromData.append('file_actoRes', '');
      fromData.append('doc_actoRes', '');
      fromData.append('nom_file_actoRes', this.state.actoResolutivo1);
    }
    fromData.append('fec_actoRes', this.state.fecha1);
    if (this.state.archivoContratos !== null) {
      fromData.append('file_nContrato', this.state.archivoContratos);
      fromData.append('doc_nContrato', this.state.archivoContratos.name);
      fromData.append('nom_file_nContrato', this.state.contrato1);
    } else {
      fromData.append('file_nContrato', '');
      fromData.append('doc_nContrato', '');
      fromData.append('nom_file_nContrato', this.state.contrato1);
    }
    
    fromData.append('fec_nContrato', this.state.fecha2);

    fromData.append('nid_tipoDesp', this.state.tipoDesplazamiento.value);
    fromData.append('nid_puestoDestino', this.state.puestoDestino.value);
    fromData.append('remOrigen', this.state.remuneracion);
    fromData.append('remDestino', this.state.remuneracionDestino);
    fromData.append('perfil', this.state.perfil.value);
    fromData.append('aceptaExpresa', this.state.aceptacion.value);
    fromData.append('revisionPlazaPres', this.state.plaza.value);

    if (this.state.archivoNecesidad !== null) {
      fromData.append('file_sustentaDesp', this.state.archivoNecesidad);
      fromData.append('doc_sustentaDesp', this.state.archivoNecesidad.name);
      fromData.append('nom_file_sustentaDesp', this.state.docSustento1);
    } else {
      fromData.append('file_sustentaDesp', '');
      fromData.append('doc_sustentaDesp', '');
      fromData.append('nom_file_sustentaDesp', this.state.docSustento1);
    }

    fromData.append('enCargoArea', this.state.cargo.value);

    if (this.state.archivoDesplazamiento !== null) {
      fromData.append('file_autorizaDesp', this.state.archivoDesplazamiento);
      fromData.append('doc_autorizaDesp', this.state.archivoDesplazamiento.name);
      fromData.append('nom_file_autorizaDesp', this.state.docAutorizaDesplazamiento1);
    } else {
      fromData.append('file_autorizaDesp', '');
      fromData.append('doc_autorizaDesp', '');
      fromData.append('nom_file_autorizaDesp', this.state.docAutorizaDesplazamiento1);
    }

    fromData.append('fec_inicio', this.state.fechaInicio);
    fromData.append('fec_fin', this.state.fechaFin);
    fromData.append('comunicaServidor', this.state.servidor.value);
    fromData.append('comunicaAreaOrigen', this.state.origen.value);
    fromData.append('comunicaAreaDestino', this.state.destino.value);
    fromData.append('expRemLegajo', this.state.expediente.value);
    fromData.append('estado', '1');
    fromData.append('usuario', this.state.usuario);
    
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'desplazamientos/editarDesplazamiento', fromData)
    .then((res) => {
      this.props.handleClick("tl", "success", "Desplazamiento editado correctamente!", "pe-7s-info")
      this.props.history.push('/administrarDesplazamientos');
      Swal.close()
    }).catch((error) => {
      this.props.history.push('/administrarDesplazamientos');
      this.props.handleClick("tl", "error", "Error al editar desplazamiento!", "pe-7s-info")
      Swal.close()
    });


  }
  
  render() {
    const {cargod, unidadOrganica,tipoDesplazamiento, regimenDestino, puestoDestino, unidadOrganica2} = this.state

    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                      <h3>Datos Origen</h3>
                      <hr style={{borderTop: '2px solid #7de4f1'}}/>
                       <div className="row">
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>DNI<a style={{color:"red"}}> *</a></ControlLabel>
                                <FormControl type="text"  value={this.state.dni} onChange= {this.handleDNI} disabled />
                            </div>
                              <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>Nombres y Apellidos</ControlLabel>
                                <FormControl type="text"  value= {this.state.nombres} onChange={this.handleNombres} disabled/>
                             </div>
                             <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>Dirección</ControlLabel>
                                <FormControl type="text"  value= {this.state.direccion} onChange={this.handleDireccion} disabled/>
                             </div>
                             
                        </div>
                        <div className="row">
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>Correo Personal</ControlLabel>
                                <FormControl type="text"  value= {this.state.correo} onChange={this.handleCorreo} disabled/>
                             </div>
                             <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>Régimen Laboral</ControlLabel>
                                <FormControl type="text"  value= {this.state.Regimen} onChange={this.handleRegimen} disabled/>
                             </div>
                             
                             <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel> Órgano y/o unidad orgánica <a style={{color:"red"}}> *</a></ControlLabel>
                                <Select value={unidadOrganica} onChange={this.changeOrganica} options={this.state.unidadOrganicas} isDisabled={this.state.disabled}/>
                             </div>
                        </div>
                        <div className="row">
                        
                        <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>Fecha de inicio del contrato</ControlLabel>
                                <FormControl type="date"  value= {this.state.fechaIn} onChange={this.handleFechaIn} disabled/>
                             </div>
                             
                             <div className="col-md-2">
                                <ControlLabel style={{ paddingRight: '10px' }}>Fecha de término del contrato</ControlLabel>
                                <FormControl type="date"  value= {this.state.fechaF} onChange={this.handleFechaF} disabled/>
                            </div>
                              
                              <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel> Puesto<a style={{color:"red"}}> *</a></ControlLabel>
                                <Select value={cargod} onChange={this.changeCargod} options={this.state.cargods} isDisabled={this.state.disabled}/>
                             </div>
                             <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>Remuneración<a style={{color:"red"}}> *</a></ControlLabel>
                                <FormControl type="text"  value= {this.state.remuneracion} onChange={this.handleRemuneracion} disabled={this.state.disabled} />
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel> N° de Proceso de Selección<a style={{color:"red"}}> *</a></ControlLabel>
                                <FormControl type="text" value= {this.state.proceso} onChange={this.handleProceso} disabled={this.state.disabled}/>
                              </div>
                              
                            
                            
                        </div>
                        <div className="row">
                        {
                          !this.state.disabled ? this.state.actoResolutivo1 !== ""? (
                                 <div className="col-md-4" style={{marginBottom: 18, border: '1px solid #ccc', borderRadius: 15, textAlign: 'center'}}>
                                    <p>Documento Actual acto resolutivo</p>
                                      <span data-tip="Ver Documento" className="fa-stack" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => { this.verDocumento(this.state.actoResolutivo1) }}>
                                      <ReactTooltip />
                                      <i className="fa fa-file-pdf-o fa-3x" style={{ fontSize: 50, color: "red" }}></i>
                                    </span>
                                  </div>
                         ) : "": ''
                        }
                        <div className="col-md-2">

                        </div>
                        {
                          !this.state.disabled ? this.state.contrato1  !== ""? (
                                  <div className="col-md-4" style={{marginBottom: 18, border: '1px solid #ccc', borderRadius: 15, textAlign: 'center'}}>
                                  <p>N° Contrato</p>
                                  <span data-tip="Ver Documento" className="fa-stack" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => { this.verDocumento(this.state.contrato1) }}>
                                      <ReactTooltip />
                                      <i className="fa fa-file-pdf-o fa-3x" style={{ fontSize: 50, color: "red" }}></i>
                                  </span>
                              </div>
                         ) : "": ''
                        }
                        </div>
                        <div className="row"  >
                            {
                                this.state.disabled ? this.state.actoResolutivo1 !== ""? (
                                  
                                  <div className="col-md-4" style={{marginBottom: 18, border: '1px solid #ccc', borderRadius: 15, textAlign: 'center'}}>
                                    <p>Acto resolutivo</p>
                                      <span data-tip="Ver Documento" className="fa-stack" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => { this.verDocumento(this.state.actoResolutivo1) }}>
                                      <ReactTooltip />
                                      <i className="fa fa-file-pdf-o fa-3x" style={{ fontSize: 50, color: "red" }}></i>
                                    </span>
                                  </div>
                                ) : "" : (<div className="col-md-4" >
                                <ControlLabel>Acto resolutivo</ControlLabel>
                                <FormControl type="file"  onChange={this.handleArchivoResolutivo} style={{ cursor: 'pointer' }}/>
                                
                              </div>) 
                            }
                            {
                              this.state.disabled ? (<div className="col-md-2">

                                </div>): ""
                            }
                            {
                               !this.state.disabled ? (<div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>Fecha</ControlLabel>
                                 <FormControl type="date" value={this.state.fecha1} onChange= {this.handleFecha1} disabled={this.state.disabled}/>
                             </div>): ""
                            }
                              
                             {
                                this.state.disabled ? this.state.contrato1 !=="" ? (
                                  <div className="col-md-4" style={{marginBottom: 18, border: '1px solid #ccc', borderRadius: 15, textAlign: 'center'}}>
                                  <p>N° Contrato</p>
                                  <span data-tip="Ver Documento" className="fa-stack" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => { this.verDocumento(this.state.contrato1) }}>
                                      <ReactTooltip />
                                      <i className="fa fa-file-pdf-o fa-3x" style={{ fontSize: 50, color: "red" }}></i>
                                  </span>
                              </div>
                                ): "" : (
                                  <div className="col-md-4" style={{marginBottom: 18}}>
                                    <ControlLabel>N° contrato </ControlLabel>
                                    <FormControl type="file"  onChange={this.handleArchivoContrato} style={{ cursor: 'pointer' }}/>
                                  </div>
                                  )
                             }
                             {
                                !this.state.disabled ? (<div className="col-md-2" style={{marginBottom: 18}}>
                                  <ControlLabel>Fecha</ControlLabel>
                                  <FormControl type="date" value={this.state.fecha2} onChange= {this.handleFecha2} disabled={this.state.disabled}/>
                               </div>): ""
                             }
                             
                        </div>
                        <h3>Datos destino</h3>
                        <hr style={{borderTop: '2px solid #7de4f1'}}/>
                        <div className="row">
                        <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>Tipo de desplazamiento<a style={{color:"red"}}> *</a></ControlLabel>
                                <Select value={tipoDesplazamiento} onChange={this.changeTipoDesplazamiento} options={this.state.tipoDesplazamientos} isDisabled={this.state.disabled}/>
                             </div> 
                             <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel> Órgano y/o unidad orgánica<a style={{color:"red"}}> *</a></ControlLabel>
                                <Select value={unidadOrganica2} onChange={this.changeOrganica2} options={this.state.unidadOrganicas2} isDisabled={this.state.disabled}/>
                             </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>Puesto<a style={{color:"red"}}> *</a></ControlLabel>
                                <Select value={puestoDestino} onChange={this.changePuestoDestino} options={this.state.puestosDestinos} isDisabled={this.state.disabled}/>
                            </div>
                            <div className="col-md-3" >
                                <ControlLabel style={{ paddingRight: '10px' }}>Remuneración</ControlLabel>
                                <FormControl type="text"  value= {this.state.remuneracionDestino} onChange={this.handleRemuneracionDestino} disabled={this.state.disabled} />
                            </div>
                              
                        </div>
                        <div className="row">
                        <div className="col-md-3">
                                <ControlLabel style={{ paddingRight: '10px' }}>Régimen Laboral<a style={{color:"red"}}> *</a></ControlLabel>
                                <Select value={regimenDestino} onChange={this.changeRegimenDestino} options={this.state.regimenDestinos} isDisabled={this.state.disabled}/>
                             </div>
                             <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>Documento de aceptación expresa <a style={{color:"red"}}> *</a></ControlLabel>
                                <Select value={this.state.aceptacion} onChange={this.handleAceptacion} options={this.state.aceptaciones} isDisabled={this.state.disabled}/>
                             </div>
                             <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>Revisión de plaza presupuestada/PAP<a style={{color:"red"}}> *</a></ControlLabel>
                                <Select value={this.state.plaza} onChange={this.handlePlaza} options={this.state.plazas} isDisabled={this.state.disabled}/>
                             </div>
                             <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>Cumple perfil<a style={{color:"red"}}> *</a></ControlLabel>
                                <Select value={this.state.perfil} onChange={this.handlePerfil} options={this.state.perfiles} isDisabled={this.state.disabled}/>
                             </div>
                             
                        </div>
                        {
                          !this.state.disabled ? (
                            <div className="row">
                                 <div className="col-md-4" style={{marginBottom: 18, border: '1px solid #ccc', borderRadius: 15, textAlign: 'center'}}>
                                    <p>Documento sustento de la necesidad de desplazamiento - Actual</p>
                                      <span data-tip="Ver Documento" className="fa-stack" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => { this.verDocumento(this.state.docSustento1) }}>
                                      <ReactTooltip />
                                      <i className="fa fa-file-pdf-o fa-3x" style={{ fontSize: 50, color: "red" }}></i>
                                    </span>
                                  </div>
                                  <div className="col-md-2">

                                  </div>
                                  <div className="col-md-4" style={{marginBottom: 18, border: '1px solid #ccc', borderRadius: 15, textAlign: 'center'}}>
                                  <p>Documento que autoriza el desplazamiento - Actual</p>
                                  <span data-tip="Ver Documento" className="fa-stack" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => { this.verDocumento(this.state.docAutorizaDesplazamiento1) }}>
                                      <ReactTooltip />
                                      <i className="fa fa-file-pdf-o fa-3x" style={{ fontSize: 50, color: "red" }}></i>
                                  </span>
                              </div>
                            </div>) : ''
                        } <hr />
                        <div className="row">
                        <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>Fecha de inicio del desplazamiento<a style={{color:"red"}}> *</a></ControlLabel>
                                <FormControl type="date"  value={this.state.fechaInicio} onChange= {this.handleFechaInicio} disabled={this.state.disabled}/>
                             </div>
                             <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>Fecha de término del desplazamiento<a style={{color:"red"}}> *</a></ControlLabel>
                                <FormControl type="date"  value={this.state.fechaFin} onChange= {this.handleFechaFin} disabled={this.state.disabled}/>
                             </div>
                             {
                              this.state.disabled ? (
                                <div className="col-md-3" style={{marginBottom: 18, border: '1px solid #ccc', borderRadius: 20,  textAlign: 'center'}}>
                                  <p>Documento sustento de la necesidad de desplazamiento</p>
                                  <span data-tip="Ver Documento" className="fa-stack" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => { this.verDocumento(this.state.docSustento1) }}>
                                    <ReactTooltip />
                                    <i className="fa fa-file-pdf-o fa-3x" style={{ fontSize: 50, color: "red" }}></i>
                                </span>
                             </div>
                              ) : (
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                  <ControlLabel>Documento sustento de la necesidad de desplazamiento<a style={{color:"red"}}> *</a></ControlLabel>
                                  <FormControl type="file"  onChange={this.handleArchivoNecesidad} style={{ cursor: 'pointer' }}/>
                                </div>
                              )
                            }
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>Entrega de cargo en el área/entidad de origen <a style={{color:"red"}}> *</a></ControlLabel>
                                <Select value={this.state.cargo} onChange={this.handleCargo} options={this.state.cargos} isDisabled={this.state.disabled}/>
                            </div>
                        </div>
                        <div className="row">
                            

                            
                              {
                                this.state.disabled ? (
                                  <div className="col-md-3" style={{marginBottom: 18, border: '1px solid #ccc', borderRadius: 15, textAlign: 'center'}}>
                                <p>Documento que autoriza el desplazamiento</p>
                                <span data-tip="Ver Documento" className="fa-stack" style={{ cursor: 'pointer', fontSize: 30 }} onClick={() => { this.verDocumento(this.state.docAutorizaDesplazamiento1) }}>
                                    <ReactTooltip />
                                    <i className="fa fa-file-pdf-o fa-3x" style={{ fontSize: 50, color: "red" }}></i>
                                </span>
                             </div>
                                ) : (
                                  <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>Documento que autoriza el desplazamiento<a style={{color:"red"}}> *</a></ControlLabel>
                                <FormControl type="file" onChange={this.handleArchivoDesplazamiento} style={{ cursor: 'pointer' }}/>
                             </div>
                                )
                              }
                             <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>Expediente remitido a legajo<a style={{color:"red"}}> *</a></ControlLabel>
                                <Select value={this.state.expediente} onChange={this.handleExpediente} options={this.state.expedientes} isDisabled={this.state.disabled}/>
                             </div>
                             <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>Comunicar servidor<a style={{color:"red"}}> *</a></ControlLabel>
                                <Select value={this.state.servidor} onChange={this.handleServidor} options={this.state.servidores} isDisabled={this.state.disabled}/>
                             </div> 
                             <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>Comunicar área origen<a style={{color:"red"}}> *</a></ControlLabel>
                                <Select value={this.state.origen} onChange={this.handleOrigen} options={this.state.origenes} isDisabled={this.state.disabled}/>
                             </div>
                             <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel>Comunicar área destino<a style={{color:"red"}}> *</a></ControlLabel>
                                <Select value={this.state.destino} onChange={this.handleDestino} options={this.state.destinos} isDisabled={this.state.disabled}/>
                             </div>
                        </div>

                      <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                      {this.state.disabled ? (
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar() }}>Editar</Button> 
                      ) : (
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Guardar Registro</Button>
                        
                      )}
                    </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    
    );
   
  }
   
}

export default DetalleDesplazamiento;
