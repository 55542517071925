import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator,sisperplus} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Select from 'react-select';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class SeccionesLegajo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            path: 'administrarAreas',
            usuario: localStorage.getItem('nickname'),
            token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
            id: JSON.parse(localStorage.getItem('datos'))[0].Person,
            movimientos: [],            
            nid:"",
            descripcion: "",  
            idseccion: "",
            descategoria: "",
            isLoading: true,            
            estado: {value:"1", label:"ACTIVO"},
            estados: [
                {value:"1", label: "ACTIVO"},
                {value:"0", label: "INACTIVO"}
            ]
        }

        this.GetActionFormat= this.GetActionFormat.bind(this);
        this.handleDescripcion=this.handleDescripcion.bind(this);
    }
    
    handleDescripcion(event) {this.setState({descripcion: event.target.value});}
    handleEstado = estado => { this.setState( { estado }, );  };

    async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{                    
                    this.listarCategorias();
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }

    async listarCategorias() {
        this.setState({isLoading: true})
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'legajo/listSeccionesLegajo?filtro=0')
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({                   
                  idseccion: data.id_seccion,
                  descripcion: data.descripcion,    
                  idestado: data.id_estado,
                  estado: data.estado,              
                  item: data.item,
                })
              })
              this.setState({movimientos: lista, isLoading: false})
            }else{
              this.setState({movimientos: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);  
            this.setState({isLoading: false})
          }
        });
    }

    GetActionFormat(cell, row) {
        if(row != undefined){
            return (
              <div>
                  <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
                    <ReactTooltip />
                    <i className="fa fa-circle fa-stack-2x text-success"></i>
                    <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                  </span>                  
              </div>
            );
        }else{}
    }

    async regresar(){
        this.props.history.push("/administrarAreas")
    }

    async save(){
        if(this.state.descripcion != ''){
            if(this.state.idseccion == ''){
                this.guardar()
            }else{
                this.update()
            }
        }else{
            this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
        }
    }

    guardar(){
        this.setState({isLoading: true})
        let fromData = new FormData();        
        fromData.append('descripcion', this.state.descripcion.toUpperCase());        
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'legajo/insertSeccionLegajo', fromData)
        .then((response) => {
            var datos = response.data
            if(datos.response != null){
                this.limpiar();
                this.props.handleClick("tl", "info", "Datos registrados correctamente!", "pe-7s-smile")
            }else{
                this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
                this.setState({isLoading: false})
            }
        })
        .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
            }else{
                console.log(error);
                this.setState({isLoading: false})
            }
        });
    }

    async obtener(data){ 
        this.setState({
            idseccion: data.idseccion,            
            descripcion: data.descripcion,          
            estado: {value: data.idestado, label: data.estado},      
       })        
    }

    update(){
        this.setState({isLoading: true})
        let fromData = new FormData();        
        fromData.append('idseccion', this.state.idseccion);
        fromData.append('descripcion', this.state.descripcion.toUpperCase());        
        fromData.append('estado', this.state.estado.value);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'legajo/updateSeccionLegajo', fromData)
        .then((response) => {
        var datos = response.data
            if(datos.response != null){
                this.limpiar();
                this.props.handleClick("tl", "info", "El registro ha sido actualizado!", "pe-7s-smile")
            }else{
                this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
                this.setState({isLoading: false})
            }
        })
        .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
            }else{
                console.log(error);
                this.setState({isLoading: false})
            }
        });
    }

    async limpiar(){       
        this.listarCategorias();
        this.cancelar();
    }
  
    async cancelar(){
        this.setState({ descripcion: "", idtipodocumento: "", idseccion: "",
        estado : {value:"1", label: "ACTIVO"}})
    }

    render(){
        const columns = [
            {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "descripcion", text: "DESCRIPCIÓN", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:700}, headerAlign: (column, colIndex) => 'center', align: 'center'},            
            {dataField: "estado", text: "ESTADO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
        ]
        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'Primera',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Última',
            showTotal: true,
            totalSize: this.state.movimientos.length
        };
        const contentTable = ({ paginationProps, paginationTableProps }) => (
            <div>
              <div>
                <div>
                  <BootstrapTable
                    striped
                    hover
                    keyField="item"
                    data={ this.state.movimientos }
                    columns={ columns }
                    filter={ filterFactory() }
                    noDataIndication="No existen registros para mostrar"
                    { ...paginationTableProps }
                  />
                </div>
                <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
              </div>
            </div>
        );
        return(
            <div className="content">
                <Grid fluid>
                <Row>
                    <Col md={12}>
                        <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                        <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>LISTA DE SECCIONES</h4>
                        <div className="row" style={{marginBottom: 10}}>                            
                            <div className="col-md-9" style={{marginBottom: 18}}>
                                <ControlLabel>DESCRIPCIÓN</ControlLabel>
                                <FormControl type="text" value={this.state.descripcion} onChange={this.handleDescripcion}/>
                            </div>
                            <div className="col-md-3" style={{marginBottom: 18}}>
                                <ControlLabel>ESTADO</ControlLabel>
                                <Select value={this.state.estado} onChange={this.handleEstado} options={this.state.estados}/>
                            </div>                                
                                <Button style={{width: 200, marginBottom: 10, marginRight :10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>CANCELAR</Button>
                                <Button style={{width: 200, marginBottom: 10, marginRight :10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.save() }}>GUARDAR</Button>                                  
                            </div>   
                        {this.state.isLoading ? (
                                <ClipLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    size={50}
                                    color={'#123abc'}
                                    loading={this.state.loading}
                                />
                                ) : (
                                <PaginationProvider
                                    pagination={
                                    paginationFactory(options)
                                    }
                                >
                                    { contentTable }
                                </PaginationProvider>
                            )}
                        </div>
                        <Button style={{width: 250, marginTop: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                    </Col>
                </Row>
                </Grid>
            </div>
        );
    }

}

export default SeccionesLegajo;