import React, { Component } from "react";
import auth0Client from 'login/Auth';
import axios from 'axios';
import { NavLink, Link } from 'react-router-dom';
import { sisperplus } from 'store/Apis'

import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import { validator } from 'store/Apis'
import "./inicio.css";
import { Card } from "components/Card/Card.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Inicio extends Component {

  state = {
    path: 'inicio',
    usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
    token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
    vista: [],
    isLoading: true
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
             this.cargaView();
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  async cargaView(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'perfil/listPrincipal')
    .then((res) => {
       let lista = res.data.response
       this.setState({vistas: lista, isLoading: false})
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  render() {
    return (
      <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              content={
                <main role="main">
                <section className="page-section" id="services">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <h2 className="section-heading text-uppercase">SIAJ</h2>
                        <h3 className="section-subheading text-muted">Nuevo Sistema Integrado Administrativo y Jurisdiccional del Tribunal Constitucional</h3>
                      </div>
                    </div>
                    <div className="row text-center" style={{marginTop: 30}}>
                      <div className="col-md-4">
                        <span className="fa-stack fa-5x">
                          <i className="fa fa-circle fa-stack-2x"></i>
                          <i className="fa fa-laptop fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="service-heading">Multiplataforma</h4>
                      </div>
                      <div className="col-md-4">
                        <span className="fa-stack fa-5x">
                          <i className="fa fa-circle fa-stack-2x text-primary"></i>
                          <i className="fa fa-send fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="service-heading">Rápido y flexible</h4>
                      </div>
                      <div className="col-md-4">
                        <span className="fa-stack fa-5x">
                          <i className="fa fa-circle fa-stack-2x"></i>
                          <i className="fa fa-lock fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="service-heading">Seguro al usar</h4>
                      </div>
                    </div>
                  </div>
                </section>
                  <div className="album py-5 bg-light">
                    <div style={{marginRight: 50, marginLeft: 50}}>
                      <div className="row" style={{marginTop: 30}}>
                      {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                      ) : (
                      this.state.vistas.map((data, i) => {
                            return (
                              <NavLink key={i} to={data.path}>
                                <div className="col-md-4" href={data.path} to={data.path}>
                                  <div className="card mb-4 shadow-sm" style={{borderRadius: 0}}>
                                    <div className={data.style} style={{borderRadius: 0}}>
                                      <p className="textDes" style={{letterSpacing: 1, color: "white"}}>{data.title}</p>
                                    </div>
                                    <div className="card-body" style={{marginTop: -20, paddingBottom: 1}}>
                                      <p className="card-text textDes" style={{marginLeft: 10, marginRight: 10, color: "grey", letterSpacing: 1}}>{data.descripcion}</p>
                                    </div>
                                  </div>
                                </div>
                              </NavLink>
                            );
                        }))}
                      </div>
                    </div>
                  </div>
                </main>
                  }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Inicio;