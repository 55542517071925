import React, { Component } from "react";
import { ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, fechasBusqueda,paramRRHH} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import Swal from 'sweetalert2'
import Calendar from "components/Calendar/Calendar.jsx";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ReporteVacaciones extends Component {

    constructor(props){
        super(props);
        this.state = {
            path: 'administrarVacaciones',
            usuario: localStorage.getItem('nickname'),
            token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
            id: JSON.parse(localStorage.getItem('datos'))[0].Person,
            movimientos: [],    
            isLoading: true 
        }
        this.verDetalle= this.verDetalle.bind(this);
        this.colorear = this.colorear.bind(this);
     }
        


    async componentDidMount(){
        if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null &&
        localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
                headers: {
                    Authorization: this.state.token
                }
                }).then((response) => {
                    let permisos = response.data;
                    if(permisos.codError != 1){
                        this.props.history.goBack()
                    }else{
                      this.reporteVacaciones();
                    }
                },(error) => {
                    if(auth0Client.isAuthenticated() === true){
                    auth0Client.signOut()
                    this.props.history.replace('/');
                    }else{
                    this.props.history.goBack()
                    }
                });             
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }

    async reporteVacaciones(){
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'vacaciones/listReporteVacaciones')
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({ 
                    item: data.item,
                    diasConsumidos:data.diasConsumidos,
                    diasRestantes:data.diasRestantesVacaciones,//>30 ? <a style={{color:"red"}}>{data.diasRestantesVacaciones}</a> : <a style={{color:"blue"}}>{data.diasRestantesVacaciones}</a>
                    diasVacaCorrespon: data.diasVacacionesCorrespondientes,
                    dni:data.cnum_dni,
                    fechaIngreso :Calendar.calendario10(data.EntryDate),//A
                    nombre: data.Name,
                    person :data.Person,
                    anios: data.anios,
                    cargo: data.cargo,
                    meses:data.meses,
                    dias:data.dias,
                    diasLaborales: (data.anios + " años " + data.meses + " meses "+ data.dias + " dias")
                })        
              })
              this.setState({movimientos: lista, isLoading: false})
            }else{
              this.setState({movimientos: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              
              this.setState({isLoading: false})
            }
          });
    }


    async regresar(){
        this.props.history.push("/administrarVacaciones")
    }

    verDetalle(cell, row){
        if(row != undefined){
            return (
                <div>
                    <span data-tip="Ver Detalle" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.verDetalle2(row) }}>
                    <ReactTooltip />
                    <i className="fa fa-circle fa-stack-2x " style={{color:"#1E436D"}}></i>
                    <i className="fa fa-window-restore fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                    </span>
                </div>
            );
        }else{}

    }

    colorear(cell, row){
        if(row != undefined){
            return (
                <div>
                   {row.diasRestantes >30 ? 
                   <a style={{color:"red"}}>{row.diasRestantes}</a> 
                   
                   : <a style={{color:"blue"}}>{row.diasRestantes}</a>
                   
                
                   } 
                </div>
            );
        }else{}

    }

    async verDetalle2(data){
        paramRRHH.vacaciones[1].enlace = "R"
        paramRRHH.vacaciones[0].data = data
        localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH))
        this.props.history.push('/vacacionesTrabajador');
      }
    
  

    render () {
        const columns=[
            {dataField: "item", text: "ITEM",  headerStyle: {backgroundColor: '#337AB7', color: "white",width:50}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "dni", text: "DNI",  filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white",width:120}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "nombre", text: "NOMBRES Y APELLIDOS",  filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white",width:300}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "cargo", text: "CARGO",  filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white",width:300}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "fechaIngreso", text: "FECHA DE INGRESO",  filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "diasLaborales", text: "DIAS LABORALES",  filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white", width:190}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "diasVacaCorrespon", text: "VACACIONES CORRESPONDIENTE",  filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white", width:130}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "diasConsumidos", text: " VACACIONES CONSUMIDAS",  filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "diasRestantes", text: "VACACIONES PENDIENTES",  filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter:this.colorear},
             {dataField: "detalle", text: "DETALLE",   headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter : this.verDetalle},
        ]

        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'Primera',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Última',
            showTotal: true,
            totalSize: this.state.movimientos.length
        };

        const contentTable = ({ paginationProps, paginationTableProps }) => (
            <div>
              <div>
                <div>
                  <BootstrapTable
                    striped
                    hover
                    keyField="item"
                    data={ this.state.movimientos }
                    columns={ columns }
                    filter={ filterFactory() }
                    noDataIndication="No existen registros para mostrar"
                    { ...paginationTableProps }
                  />
                </div>
                <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
              </div>
            </div>
          );

        return(
            <div className="content">
                <Grid fluid>
                    <Row>
                      <Col md={12}>
                        <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                        <div className="row">
                            <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25, marginLeft: 20}}> Reporte de Vacaciones</h4>
                            <Button style={{width: 250,marginTop : 5, marginLeft:15, marginBottom:10}} bsStyle="danger" fill type="submit" onClick={()=>{this.regresar()}}>Regresar</Button>
                        </div>
                        {this.state.isLoading ? (
                            <ClipLoader
                                css={override}
                                sizeUnit={"px"}
                                size={50}
                                color={'#123abc'}
                                loading={this.state.loading}
                            />                                  
                        ) : (
                            <div>
                                <PaginationProvider
                                    pagination={
                                    paginationFactory(options)
                                    }>
                                    { contentTable }
                                </PaginationProvider>   
                            </div>
                            )}
                        </div>
                       </Col>
                    </Row>
                </Grid>
            </div>
        );
    }


}

export default ReporteVacaciones;