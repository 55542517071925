import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, paramLegajo} from 'store/Apis'
import { Card } from "components/Card/Card.jsx";
import { NavLink, Link } from 'react-router-dom';
import "views/inicio/inicio.css";
import "./milegajo.css";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class ConsultarMiLegajo extends Component {

    constructor(props) {
        super(props);
        this.state = {
          path: 'miLegajo',
          usuario: localStorage.getItem('nickname'),
          token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
          id: JSON.parse(localStorage.getItem('datos'))[0].Person,
          vista: [],
          isLoading: true,
          nombre: JSON.parse(localStorage.getItem('datos'))[0].nombre,               
        }     
    }

    async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{
                  this.listarSecciones();
                  this.verificarLegajo();
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }

    async listarSecciones(){
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'legajo/listSeccionesLegajo?filtro=1')
        .then((res) => {         
            let lista = res.data.response;           
            this.setState({vistas: lista, isLoading: false})            
          })
        .catch((error) => { 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              this.setState({isLoading: false})
            }
        });
    }

    async verificarLegajo(){
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'legajo/verificarLegajoPersona?idpersona=' + this.state.id)
        .then((res) => {         
            let lista2 = res.data.response;  
            if(lista2.length != 0){
                lista2.map((data) => {
                    this.setState(
                    {
                        idlegajo: data.id_legajo,
                        estadolegajo: data.estado
                    })  
                })
            }else{
                this.setState(
                {
                    idlegajo: 0,
                    estadolegajo: 0
                }) 
            }                   
        })
        .catch((error) => { 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              this.setState({isLoading: false})
            }
        });
    }

    async detalle(data){     
        paramLegajo.legajo[0].idpersona = this.state.id
        paramLegajo.legajo[1].nombre = this.state.nombre
        paramLegajo.legajo[2].idcategoria = data.id_seccion
        paramLegajo.legajo[3].descategoria = data.descripcion
        paramLegajo.legajo[4].enlace = "P"
        paramLegajo.legajo[5].idlegajo = this.state.idlegajo
        localStorage.setItem('legajo', JSON.stringify(paramLegajo));
      }

    render(){
        return(
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <main role="main">
                                    <div className="container">
                                        <div className="row">                                            
                                            <h3 className="text-center">{this.state.nombre}</h3>
                                        </div>                                       
                                    </div>
                                    <div className="album py-5 bg-light">
                                        <div style={{marginRight: 50, marginLeft: 50}}>
                                        <div className="row" style={{marginTop: 30}}>
                                        {this.state.isLoading ? (
                                        <ClipLoader
                                            css={override}
                                            sizeUnit={"px"}
                                            size={50}
                                            color={'#123abc'}
                                            loading={this.state.loading}
                                        />
                                        ) : (
                                        this.state.vistas.map((data, i) => {
                                                return (
                                                
                                                    <NavLink key={i} to={data.url} onClick = {() => {this.detalle(data)}} 
                                                        className={this.state.idlegajo == 0 && data.id_seccion != 1 ? "isDisabled" : data.url == '' ? "isDisabled" : ""}>
                                                        <div className="col-md-6" >
                                                            <div className="wrap">
                                                                <div className="ico-wrap" style={{width:65}}>
                                                                    <i className="pe-7s-check" style={{color:"black",fontSize: 40, width:20}}></i>
                                                                </div>
                                                                <div className="text-wrap vcenter">
                                                                    <h2 className="mbr-fonts-style" style={{fontSize:14}}>{data.descripcion}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                               
                                                );
                                            }))}
                                        </div>
                                        </div>
                                    </div>
                                    </main>
                                }
                            />

                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

}

export default ConsultarMiLegajo;