import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {sisperplus , paramPersonal} from 'store/Apis'
import { Card } from "components/Card/Card.jsx";
import "./directorios.css";

import 'react-confirm-alert/src/react-confirm-alert.css';
import { NavLink } from 'react-router-dom';





const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Directorios extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'manuales',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      directorios: [],
      directorios2:[]
    }
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.listDirectoriosSiaj()
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listDirectoriosSiaj(){
    axios.defaults.headers.common['Authorization'] = this.state.token2
    axios.get(`${sisperplus}` + 'utilitarios/directorios?cate=1')
    .then((res) => {
       let lista = res.data.response
       this.setState({directorios: lista})
    })
    this.listDirectoriosplicaciones()
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listDirectoriosplicaciones(){
    axios.defaults.headers.common['Authorization'] = this.state.token2
    axios.get(`${sisperplus}` + 'utilitarios/directorios?cate=2')
    .then((res) => {
       let lista = res.data.response
       this.setState({directorios2: lista, isLoading: false})
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async detalle(data){
    paramPersonal.manuales[0].data = data
    localStorage.setItem('personal', JSON.stringify(paramPersonal))
    this.props.history.push('/manualCEC');
  }

  render() {
    return (
      <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              content={
                <main role="main">
                  <div className="container">
                    <h3 className="text-center">SIAJ</h3>
                 </div>
                  <div className="album py-5 bg-light">
                    <div style={{marginRight: 50, marginLeft: 50}}>
                      <div className="row" style={{marginTop: 30}}>
                      {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.isLoading}
                      />
                      ) : (
                        this.state.directorios.map((data, i) => {
                          return (
                            <div>
                            <NavLink key={i} to={data.path} onClick = {() => {this.detalle(data)}}>
                               <div class="col-md-4" >
                                
                                    <div className="wrap">
                                        <div className="ico-wrap" style={{width:50}}>
                                            <i className={data.style} style={{color:"black",fontSize: 50, width:20}}></i>
                                        </div>
                                        <div className="text-wrap vcenter">
                                            <h2 class="mbr-fonts-style" style={{fontSize:18}}>{data.ctxt_titulo}</h2>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                            </div>
                      );}))
                      }
                      </div>
                    </div>
                  </div>
                </main>
                  }
              />
            </Col>
            <Col md={12}>
            <Card
              content={
                <main role="main">
                  <div className="container">
                    <h3 className="text-center">APLICACIONES</h3>
                 </div>
                  <div className="album py-5 bg-light">
                    <div style={{marginRight: 50, marginLeft: 50}}>
                      <div className="row" style={{marginTop: 30}}>
                      {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.isLoading}
                      />
                      ) : (
                         this.state.directorios2.map((data, i) => {
                          return (
                            <div>
                            <NavLink key={i} to={data.path} onClick = {() => {this.detalle(data)}}>
                               <div class="col-md-4" >
                                
                                    <div className="wrap">
                                        <div className="ico-wrap" style={{width:50}}>
                                            <i className={data.style} style={{color:"black",fontSize: 50, width:20}}></i>
                                        </div>
                                        <div className="text-wrap vcenter">
                                            <h2 class="mbr-fonts-style" style={{fontSize:18}}>{data.ctxt_titulo}</h2>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                          </div>
                      );}) )
                      }
                      </div>
                    </div>
                  </div>
                </main>
                  }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Directorios;