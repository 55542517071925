import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import Calendar from "components/Calendar/Calendar.jsx";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import InputFiles from 'react-input-files';
import axios from 'axios';
import { cec} from 'store/Apis'
import Select from 'react-select';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "reactstrap";



const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class EditarNoticia extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'noticias',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      descripcion:'',
      ctxt_imagen:'',
      inscripcion: '',
      transmision: '',
      informativo: '',
      ctxt_ruta: '',
      titulo:'',
      fecha: '',
      nid:'',
      movimientos: [],
      noticia: [],
      archivo:"",
      file: [],
      isLoading: true,
      archivo2: "",
      file2: [],
      documento:"",
      documentoruta:"",
      tipo : "",
      tipos : [
              {value : "n", label: "NOTA DE PRENSA"},
              {value : "c", label: "COMUNICADO"}
              ],
      seccion :""
    }
    this.handleDescripcion = this.handleDescripcion.bind(this);
    this.handleTitulo = this.handleTitulo.bind(this);
    this.handleFecha = this.handleFecha.bind(this);
    this.handleInscripcion = this.handleInscripcion.bind(this);
    this.handleTransmision = this.handleTransmision.bind(this);
    this.handleInformativo = this.handleInformativo.bind(this);
   
}
    handleTipo = tipo => {
      this.setState(
          {tipo},
      );
    };
    handleDescripcion(event) {this.setState({descripcion: event.target.value});}
    handleTitulo(event) {this.setState({titulo: event.target.value});}
    handleFecha(event) {this.setState({fecha: event.target.value});}
    handleInscripcion(event) {this.setState({inscripcion: event.target.value});}
    handleTransmision(event) {this.setState({transmision: event.target.value});}
    handleInformativo(event) {this.setState({informativo: event.target.value});}
  
 
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
              this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(JSON.parse(localStorage.getItem("cec")).noticia[0].data !== null){
        this.setState({
            descripcion: JSON.parse(localStorage.getItem('cec')).noticia[0].data.ctxt_descripcion,
            ctxt_imagen: JSON.parse(localStorage.getItem('cec')).noticia[0].data.ctxt_imagen,
            ctxt_ruta:JSON.parse(localStorage.getItem('cec')).noticia[0].data.ctxt_ruta,
            inscripcion: JSON.parse(localStorage.getItem('cec')).noticia[0].data.ctxt_link,
            transmision: JSON.parse(localStorage.getItem('cec')).noticia[0].data.ctxt_link2,
            informativo: JSON.parse(localStorage.getItem('cec')).noticia[0].data.ctxt_link3,
            titulo: JSON.parse(localStorage.getItem('cec')).noticia[0].data.ctxt_titulo,
            tipo:{value: JSON.parse(localStorage.getItem('cec')).noticia[0].data.tipov ,
            label: JSON.parse(localStorage.getItem('cec')).noticia[0].data.tipol},
            documento: JSON.parse(localStorage.getItem('cec')).noticia[0].data.adjunto,
            documentoruta:JSON.parse(localStorage.getItem('cec')).noticia[0].data.adjunto_ruta,
            fecha: Calendar.calendario11(JSON.parse(localStorage.getItem('cec')).noticia[0].data.dfec_fecha),
            nid: JSON.parse(localStorage.getItem('cec')).noticia[0].data.nid,
            seccion : JSON.parse(localStorage.getItem('cec')).noticia[0].data.ctxt_tipo==1 ? "ACTIVIDADES DEL CEC" : "ACTUALIDAD INTERNACIONAL",
            isLoading: false
        });
    }else{
      this.props.history.push('/noticias');
    }
  }

 async actualizar(){
    
    let vacio="--"; 
    let tipodoc="N"; 
    if (this.state.descripcion != '' && this.state.titulo != ''  && this.state.fecha != ''){ 

      if(this.state.tipo.value=="c" && (this.state.documento =="" && this.state.archivo2=="") ){ 
          this.props.handleClick("tl", "error", "Adjunte el archivo del comunicado!", "pe-7s-close")       
      }else{
        let estado = 0; 
    if(this.state.tipo.value=="n"){
        estado = 0; tipodoc="S";
      }
    if(this.state.archivo != ''){
        estado = 1; tipodoc="N";
      }
    if(this.state.archivo != '' && this.state.archivo2 != '' ){
        estado = 1; tipodoc = "D";
      }
    if(this.state.archivo2 != ''&& this.state.archivo==""){
        estado = 2;
      }
      this.setState({isLoading: true})
      let fromData = new FormData();
      fromData.append('file', this.state.file);
      fromData.append('titulo', this.state.titulo);
      fromData.append('descripcion', this.state.descripcion);
      fromData.append('imagen', estado == 0 || estado ==2 ? this.state.ctxt_imagen : this.state.archivo);
      fromData.append('oldruta', this.state.ctxt_ruta);
      fromData.append('fecha', this.state.fecha);	
      fromData.append('inscripcion', this.state.archivo2!="" ? vacio : this.state.inscripcion );	
      fromData.append('transmision', this.state.archivo2!="" ? vacio :this.state.transmision );
      fromData.append('informativo', this.state.archivo2!="" ? vacio :this.state.informativo);	
      fromData.append('estado', estado); 
      fromData.append('file2', this.state.file2); 
      fromData.append('tipodoc',  tipodoc);		
      fromData.append('archivo',  estado == 2 || tipodoc == "D" ?this.state.archivo2: this.state.documento  );
      fromData.append('oldarchivo', this.state.documentoruta);	
      fromData.append('usuario', this.state.usuario);	
      fromData.append('nidnoticia', this.state.nid);	
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${cec}` + 'noticias/editarNoticia', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          this.props.handleClick("tl", "success", "Noticia actualizada correctamente!", "pe-7s-close")
            this.regresar();
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });}
     }else{
      this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close") 
     }
    
  }

  async regresar(){
    this.props.history.push('/noticias');
  }
  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], archivo: files[0].name});
    }
  }

  async limpiar(){
    this.setState({archivo: "", file: ""})
  }

  subir2 = (files2) => {
    if(files2.length !== 0){
        this.setState({file2: files2[0], archivo2: files2[0].name});
    }
  }

  async limpiar2(){
    this.setState({archivo2: "", file2: ""})
  }
 
  abrirImagen(ruta){
    window.open(ruta, '_blank');
  } 
  render() {
 
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>EDITAR : <a style={{fontWeight: "bold", color: "black"}}>{this.state.titulo}</a></h4>
                  {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                       <div className="row">
                            <div className="col-md-8" style={{marginBottom: 18}}>
                                <ControlLabel>TÍTULO</ControlLabel>
                                <FormControl type="text" value={this.state.titulo} onChange= {this.handleTitulo}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA</ControlLabel>
                                <FormControl type="date" value={this.state.fecha} onChange= {this.handleFecha}/>
                            </div>
                       </div>
                        <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>DESCRIPCIÓN</ControlLabel>
                                <FormControl componentClass="textarea" style={{  height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.descripcion} onChange= {this.handleDescripcion} />
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:20}}>
                          <div className="col-md-4">
                            <ControlLabel>TIPO DE NOTICIA</ControlLabel>
                            <Select value={this.state.tipo} onChange={this.handleTipo} options={this.state.tipos}/>
                          </div>
                          <div className="col-md-4" style={{marginBottom:18}}>
                            <ControlLabel>SECCIÓN</ControlLabel>
                            <FormControl type="text" value={this.state.seccion}  disabled/>
                          </div>
                        </div>
                        {this.state.tipo.value == "n" ? 
                        <div className="row" >
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>LINK DE INSCRIPCIÓN</ControlLabel>
                                <FormControl type="text" value={this.state.inscripcion} onChange= {this.handleInscripcion}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>LINK DE TRANSMISIÓN</ControlLabel>
                                <FormControl type="text" value={this.state.transmision} onChange= {this.handleTransmision}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>LINK INFORMATIVO</ControlLabel>
                                <FormControl type="text" value={this.state.informativo} onChange= {this.handleInformativo}/>
                            </div>
                        </div>
                        : null}
                        <div className="row">                        
                            <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>PRESENTACIÓN</ControlLabel>
                              <span  style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(this.state.ctxt_ruta) }} >
                                <img src={this.state.ctxt_ruta} alt="" style={{width:"500px"}}  ></img></span>
                            </div>
                            {this.state.tipo.value == "c" && this.state.documento!=null && this.state.documento!="--"&& this.state.documento!=""? 
                            <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>DOCUMENTO</ControlLabel>
                              <span  style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(this.state.documentoruta) }} >
                                 <i class="fa fa-file-pdf-o" style={{color:"red", fontSize:50, paddingInlineStart:'35%', paddingBlock:'15%'}}></i>
                              </span>
                            </div>: null}
                        </div>
                        <div className="row">
                            <div className="col-md-6" style={{marginBottom:18}}>
                              <ControlLabel>IMAGEN DE NOTICIA</ControlLabel>
                              <Alert bsStyle="warning" style={{marginTop:10}}>
                                  <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo}</span>
                              </Alert>
                              <Button style={{width: '100%', marginTop: -10}} bsStyle="warning" pullRight fill type="submit">
                                  <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                              </Button>
                              {this.state.archivo != "" ? (
                                <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar Archivo</Button>
                              ) : ( 
                                null
                              )}
                              </div>
                              {this.state.tipo.value == "c" ? 
                           <div className="col-md-6">
                                <ControlLabel>ARCHIVO ADJUNTO</ControlLabel>
                                <Alert bsStyle="info" style={{marginTop:10}}>
                                    <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo2}</span>
                                </Alert>
                                <Button style={{width: '100%', marginTop: -10}} bsStyle="info" pullRight fill type="submit">
                                    <InputFiles accept={'pdf/*'} onChange={files2 => this.subir2(files2)}>Dale click aquí para seleccionar el archivo</InputFiles>
                                </Button>
                                {this.state.archivo2 != "" ? (
                                  <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar2() }}>Limpiar Archivo</Button>
                                ) : ( 
                                  null
                                )}
                            </div>
                            :null}
                           </div>
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Actualización</Button>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.actualizar() }}>Actualizar Registro</Button> 
                        </div>
                  </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarNoticia;