import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';

import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import Select from 'react-select';
import Autocomplete from "react-autocomplete-select";
import moment from 'moment'
import Calendar from "components/Calendar/Calendar.jsx";

import axios from 'axios';
import { paramTesoreria, tesoreria } from 'store/Apis'

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class NuevaCajaChica extends Component {

  inputRef = React.createRef();
  inputRef2 = React.createRef();
  
  constructor(props) {
    super(props);
    this.state = {
      path: 'cajachica',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      selectedOption: "",
      selectedOption2: "",
      selectedOption3: "",
      selectedOption5: "",
      selectedOption6: "",
      selectedOption7: "",
      beneficiarios: [],
      conceptos: [],
      oficinas: [],
      responsables: [],
      metas: [],
      cajas: [],
      movimiento: '',
      numero: '',
      fecha: moment(new Date()).format('YYYY-MM-DD'),
      correlativo: '',
      numerocaja: '',
      beneficiario: '',
      documento: '',
      concepto: '',
      marcador1: false,
      marcador2: false,
      isLoading1: true,
      isLoading2: true
    }

    this.handleFecha = this.handleFecha.bind(this);
    this.handleCorrelativo = this.handleCorrelativo.bind(this);
    this.handleNumerocaja = this.handleNumerocaja.bind(this);
    this.handleBeneficiario = this.handleBeneficiario.bind(this);
    this.handleDocumento = this.handleDocumento.bind(this);
    this.handleConcepto = this.handleConcepto.bind(this);
  }

  handleChange = selectedOption => {
    this.setState(
    { selectedOption },
    );
  };

  handleChange2 = selectedOption2 => {
    this.setState(
    { selectedOption2 },
    );
  };

  handleChange3 = selectedOption3 => {
    this.setState(
    { selectedOption3 },
    );
  };

  handleChange5 = selectedOption5 => {
    this.setState(
    { selectedOption5 },
    ); 
  };

  handleChange6 = selectedOption6 => {
    this.setState(
    { selectedOption6 },
    );
  };

  handleChange7 = selectedOption7 => {
    this.setState(
    { selectedOption7 },
    );
  };

  handleFecha(event) {
    this.setState({fecha: event.target.value});
  }

  handleCorrelativo(event) {
    this.setState({correlativo: event.target.value});
  }

  handleNumerocaja(event) {
    this.setState({numerocaja: event.target.value});
  }

  handleBeneficiario(event) {
    this.setState({beneficiario: event.target.value});
  }

  handleDocumento(event) {
    this.setState({documento: event.target.value});
  }

  handleConcepto(event) {
    this.setState({concepto: event.target.value});
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.obtenerCorrelativo(0);
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async obtenerCorrelativo(param){
    if(this.state.fecha != ''){
      let fromData = new FormData();
      fromData.append('tipo', 1);
      fromData.append('fecha',  this.state.fecha);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'comprobantes/obtenerCorrelativo', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          this.setState({numero: datos.response.codigo, correlativo: datos.response.codigo}) 
          if(param == 0){
            this.obtenerDatos();
          }
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading1: false, isLoading2: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading1: false, isLoading2: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe seleccionar una fecha!", "pe-7s-info")
    }
  }

  async obtenerDatos(){
    if(paramTesoreria.areas[1].data2.length != 0){
      this.setState({
        oficinas: paramTesoreria.areas[1].data2, 
        selectedOption: {
          value: paramTesoreria.areas[1].data2[0].value, 
          label: paramTesoreria.areas[1].data2[0].label
        },
        responsables: paramTesoreria.trabajadores[1].data2, 
        selectedOption2: {
          value: paramTesoreria.trabajadores[1].data2[0].value, 
          label: paramTesoreria.trabajadores[1].data2[0].label
        },
        metas: paramTesoreria.metas[0].data, 
        selectedOption3: {
          value: paramTesoreria.metas[0].data[0].value, 
          label: paramTesoreria.metas[0].data[0].label
        },
        cajas: paramTesoreria.tiposcaja[0].data, 
        selectedOption5: {
          value: paramTesoreria.tiposcaja[0].data[0].value, 
          label: paramTesoreria.tiposcaja[0].data[0].label
        },
        beneficiarios: paramTesoreria.beneficiarios[0].data, 
        conceptos: paramTesoreria.conceptos[0].data, 
        isLoading1: false, 
        isLoading2: false, 
        apunta3: true, 
        apunta2: true,
        apunta: true, 
        marcador1: false, 
        marcador2: false, 
        beneficiario: "", 
        documento: "--", 
        concepto: ""
      })
    }else{
      this.listarAreas()
    }
  }

  async listarAreas() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listAreasActuales')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid_area,
              label: data.ctxt_descripcion
          })
        })
        this.setState({oficinas: lista, selectedOption: {value: lista[0].value, label: lista[0].label}})
        this.listarTrabajadores()
      }else{
        this.setState({oficinas: [], isLoading1: false, isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading1: false, isLoading2: false})
      }
    });
  }

  async listarTrabajadores() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listTrabajadoresActuales')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_descripcion          
            })
        })
        this.setState({responsables: lista, selectedOption2: {value: lista[0].value, label: lista[0].label}})
        this.listarMetas()
      }else{
        this.setState({responsables: [], isLoading1: false, isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading1: false, isLoading2: false})
      }
    });
  }

  async listarMetas() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listCodigos?tipo=meta')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.codigo,       
            })
        })
        this.setState({metas: lista, selectedOption3: {value: lista[0].value, label: lista[0].label}})
        this.listarTiposCaja()
      }else{
        this.setState({metas: [], isLoading1: false, isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading1: false, isLoading2: false})
      }
    });
  }

  async listarTiposCaja() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listCodigos?tipo=tipo_caja')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.nombre,       
            })
        })
        this.setState({cajas: lista, selectedOption5: {value: lista[0].value, label: lista[0].label}})
        this.listarBeneficiarios()
      }else{
        this.setState({cajas: [], isLoading1: false, isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading1: false, isLoading2: false})
      }
    });
  }

  async listarBeneficiarios() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'beneficiarios/listBeneficiarios')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.cnum_documento + ' (' + data.ctxt_descripcion + ')', 
              tipo: data.nid_tipo,
              documento: data.cnum_documento,  
              clase: data.ctxt_descripcion   
            })
        })
        this.setState({
          beneficiarios: lista, 
          // selectedOption6: {
          //   value: lista[0].value, 
          //   label: lista[0].label, 
          //   tipo: lista[0].tipo, 
          //   documento: lista[0].documento,
          //   clase: lista[0].clase
          // }
        })
        paramTesoreria.beneficiarios[0].data = lista
        this.listarConceptos()
      }else{
        this.setState({beneficiarios: [], isLoading1: false, isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading1: false, isLoading2: false})
      }
    });
  }

  async listarConceptos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'beneficiarios/listConceptos')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2.length != 0){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_descripcion,       
            })
        })
        this.setState({
            conceptos: lista, 
            // selectedOption7: {value: lista[0].value, label: lista[0].label},
            isLoading1: false, 
            isLoading2: false, 
            apunta3: true, 
            apunta2: true,
            apunta: true, 
            marcador1: false, 
            marcador2: false, 
            beneficiario: "", 
            documento: "--", 
            concepto: ""
        })
        paramTesoreria.conceptos[0].data = lista
        localStorage.setItem('data', JSON.stringify(paramTesoreria))
      }else{
        this.setState({conceptos: [], isLoading1: false, isLoading2: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading1: false, isLoading2: false})
      }
    });
  }

  async limpiar(nid){
    let data = {
        "nidmovimiento": nid
    }
    localStorage.setItem('movimientocaja', JSON.stringify(data))
    this.props.history.push('/editarcajachica');
  }

  async agregar(){
    if(this.state.marcador1 == true){
      this.setState({marcador1: false})
    }else{
      this.setState({marcador1: true})
    }
  }

  async agregar2(){
    if(this.state.marcador2 == true){
      this.setState({marcador2: false})
    }else{
      this.setState({marcador2: true})
    }
  }

  async guardar2(){
    if(this.state.correlativo != '' && this.state.correlativo.length == 5){
      if(this.state.selectedOption6 != '' && this.state.selectedOption7 != '' && this.state.fecha != '' && this.state.numerocaja != ''){
        this.setState({isLoading1: true, isLoading2: true})
        let fromData = new FormData();
        fromData.append('correlativo', this.state.correlativo);
        fromData.append('fecha',  this.state.fecha);
        fromData.append('tipocaja', this.state.selectedOption5.value);
        fromData.append('numerocaja', this.state.numerocaja);
        fromData.append('oficina', this.state.selectedOption.value);
        fromData.append('responsable',  this.state.selectedOption2.value);
        fromData.append('meta', this.state.selectedOption3.value);
        fromData.append('beneficiario',  this.state.selectedOption6.value);
        fromData.append('tipo', this.state.selectedOption6.tipo);
        fromData.append('documento', this.state.selectedOption6.documento);
        fromData.append('nombre', this.state.selectedOption6.clase);
        fromData.append('nidconcepto',  this.state.selectedOption7.value);
        fromData.append('concepto', this.state.selectedOption7.label);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${tesoreria}` + 'comprobantes/insertarCajaChica2', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            if(datos.response.codigo == "0"){
              this.setState({isLoading1: false, isLoading2: false})
              this.props.handleClick("tl", "error", "El correlativo ya existe!", "pe-7s-close")
            }else{
              let nid = datos.response.codigo;
              this.actualizarCajaChica(nid);
            }
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading1: false, isLoading2: false})
          }
          })
          .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
          }else{
              console.log(error);
              this.setState({isLoading1: false, isLoading2: false})
          }
        });
      }else{
        this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe ingresar el correlativo correctamente!", "pe-7s-info")
    }
  }

  // async guardar2(){
  //   if(this.state.correlativo != '' && this.state.correlativo.length == 5){
  //     if(this.state.selectedOption6 != '' && this.state.selectedOption7 != '' && this.state.fecha != '' && this.state.numerocaja != ''){
  //       this.setState({isLoading1: true, isLoading2: true})
  //       let fromData = new FormData();
  //       fromData.append('correlativo', this.state.correlativo);
  //       fromData.append('fecha',  this.state.fecha);
  //       fromData.append('tipocaja', this.state.selectedOption5.value);
  //       fromData.append('numerocaja', this.state.numerocaja);
  //       fromData.append('oficina', this.state.selectedOption.value);
  //       fromData.append('responsable',  this.state.selectedOption2.value);
  //       fromData.append('meta', this.state.selectedOption3.value);
  //       fromData.append('identificador', '');
  //       fromData.append('contabilidad', '');
  //       fromData.append('estadistica', '');
  //       fromData.append('usuario', this.state.usuario);
  //       axios.defaults.headers.common['Authorization'] = this.state.token
  //       axios.post(`${tesoreria}` + 'comprobantes/insertarCajaChica', fromData)
  //       .then((response) => {
  //         var datos = response.data
  //         if(datos.response != null){
  //           if(datos.response.codigo == "0"){
  //             this.setState({isLoading1: false, isLoading2: false})
  //             this.props.handleClick("tl", "error", "El correlativo ya existe!", "pe-7s-close")
  //           }else{
  //             let nid = datos.response.codigo;
  //             this.guardar(nid);
  //           }
  //         }else{
  //           this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
  //           this.setState({isLoading1: false, isLoading2: false})
  //         }
  //         })
  //         .catch((error) =>{ 
  //         if(auth0Client.isAuthenticated() === true){
  //             auth0Client.signOut()
  //             this.props.history.replace('/');
  //         }else{
  //             console.log(error);
  //             this.setState({isLoading1: false, isLoading2: false})
  //         }
  //       });
  //     }else{
  //       this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
  //     }
  //   }else{
  //     this.props.handleClick("tl", "warning", "Debe ingresar el correlativo correctamente!", "pe-7s-info")
  //   }
  // }

  // async guardar(nid){
  //     let fromData = new FormData();
  //     fromData.append('beneficiario',  this.state.selectedOption6.value);
  //     fromData.append('tipo', this.state.selectedOption6.tipo);
  //     fromData.append('documento', this.state.selectedOption6.documento);
  //     fromData.append('nombre', this.state.selectedOption6.clase);
  //     fromData.append('nidconcepto',  this.state.selectedOption7.value);
  //     fromData.append('concepto', this.state.selectedOption7.label);
  //     fromData.append('comprobante', nid);
  //     fromData.append('usuario', this.state.usuario);
  //     axios.defaults.headers.common['Authorization'] = this.state.token
  //     axios.post(`${tesoreria}` + 'detallecomprobante/insertarIdentificador', fromData)
  //     .then((response) => {
  //       var datos = response.data
  //       if(datos.response != null){
  //         this.actualizarCajaChica(nid);
  //       }else{
  //         this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
  //         this.setState({isLoading1: false, isLoading2: false})
  //       }
  //       })
  //       .catch((error) =>{ 
  //       if(auth0Client.isAuthenticated() === true){
  //           auth0Client.signOut()
  //           this.props.history.replace('/');
  //       }else{
  //           console.log(error);
  //           this.setState({isLoading1: false, isLoading2: false})
  //       }
  //     });
  // }

  async actualizarCajaChica(nid){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'comprobantes/listMovimientos?tipo=1&fechaini=' + paramTesoreria.cajachica[0].fechaini + '&fechafin=' + paramTesoreria.cajachica[1].fechafin)
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { 
              item: data.item,
              nidmovimiento: data.nid,
              numero: data.cnum_numero, 
              tipocaja: data.tipocaja,
              numerocaja: data.cnum_numero_caja,
              documento: data.cnum_documento,
              nombre: data.ctxt_nombre,
              concepto: data.ctxt_concepto,
              liquido: data.cnum_liquido,
              totalgastos: data.cnum_total_gastos != null ? 'S/.    ' + data.cnum_total_gastos.toFixed(2) : 'S/. 0.00', 
              fecha: Calendar.calendario12(data.dfec_ingreso),
              meta: data.meta,
              area: data.area,
              responsable: data.responsable,
              activo: data.item == 1 ? '1' : '0'
          })
        })
        paramTesoreria.cajachica[2].data = lista
      }else{
        paramTesoreria.cajachica[2].data = []
      }
      localStorage.setItem('data', JSON.stringify(paramTesoreria))
      this.limpiar(nid);
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async guardarBeneficiario(){
    if(this.state.beneficiario != "" && this.state.documento != ""){
      // this.setState({isLoading1: true})
      let fromData = new FormData();
      fromData.append('numero', this.state.documento);
      fromData.append('descripcion', this.state.beneficiario);
      fromData.append('tipo', 13);
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'beneficiarios/insertarBeneficiario', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.listarBeneficiarios();
            this.props.handleClick("tl", "info", "El proveedor ha sido registrado!", "pe-7s-smile")
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading1: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading1: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async guardarConcepto(){
    if(this.state.concepto != ""){
      // this.setState({isLoading1: true})
      let fromData = new FormData();
      fromData.append('descripcion', this.state.concepto);
      fromData.append('tipo', 14);
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'beneficiarios/insertarConcepto', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.listarConceptos();
            this.props.handleClick("tl", "info", "El concepto ha sido registrado!", "pe-7s-smile")
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading1: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading1: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async generarSelect(tipo, param){
    if(tipo == 1){
      this.state.beneficiarios.map((data) => {
        if(data.label == param){
          this.setState({
            selectedOption6: {
              value: data.value, 
              label: data.label, 
              tipo: data.tipo, 
              documento: data.documento,
              clase: data.clase
            }
          })
        }
      })
    }else{
      this.state.conceptos.map((data) => {
        if(data.label == param){
          this.setState({
            selectedOption7: {
              value: data.value, 
              label: data.label
            }
          })
        }
      })
    }
  }

  async generarChange(tipo, param){
    if(tipo == 1){
      if(param == ''){
        this.setState({selectedOption6: ''})
       }else{
         this.setState({beneficiario: param})
       }
    }else{
      if(param == ''){
        this.setState({selectedOption7: ''})
       }else{
        this.setState({concepto: param})
       }
    }
  }

  async volver(){
    this.props.history.push('/cajachica');
  }

  render() {
    const {selectedOption, selectedOption2, selectedOption3, selectedOption5, numero} = this.state
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Nuevo Movimiento de Fondos para Pagos en Efectivo: <a style={{color: 'black'}}>N° {numero} - Referencial</a></h4>
              <div className="row" style={{marginBottom: 10}}>
                <div className="col-md-6">
                  <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Datos del Proveedor y Concepto</h4>
                  {this.state.isLoading1 ? (
                    <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading1}
                    />
                  ) : (
                      <div>
                        {this.state.marcador1 == false ? (
                          <div>
                          <div className="row">
                              <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>SELECCIONE EL PROVEEDOR</ControlLabel>
                                {/* <Select value={selectedOption6} onChange={this.handleChange6} options={this.state.beneficiarios}/> */}
                                <Autocomplete 
                                  inputRef={this.inputRef}
                                  searchPattern={'containsString'}
                                  selectOnBlur = {false}
                                  placeholder = "Buscar proveedor"
                                  maxOptionsLimit = {10} 
                                  searchEnabled = {true}
                                  getItemValue={(item)=>{ return item.label }} 
                                  onChange = {
                                    changedValue=>{ 
                                    this.generarChange(1, changedValue)
                                  }}
                                  onSelect = {
                                    selectedValue => { 
                                    this.generarSelect(1, selectedValue)
                                  }}
                                  itemsData = {this.state.beneficiarios}
                                  globalStyle = ".autocomplete {position: relative; display: inline-block; width: 100%} input {font-size: 14px} body {font-size: 14px; font-family: 'Roboto','Helvetica Neue',Arial,sans-serif; line-height: 1.42857143}"
                                />
                              </div>
                          </div>
                          <Button style={{width: 200, marginTop: 2, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.agregar() }}>Nuevo</Button>
                          </div>
                        ) : (
                          <div>
                            <div className="row">
                              <div className="col-md-9" style={{marginBottom: 18}}>
                                  <ControlLabel>PROVEEDOR</ControlLabel>
                                  <FormControl type="text" value={this.state.beneficiario} onChange= {this.handleBeneficiario}/>
                              </div>
                              <div className="col-md-3" style={{marginBottom: 18}}>
                                  <ControlLabel>N° DE DOCUMENTO</ControlLabel>
                                  <FormControl type="text" value={this.state.documento} onChange= {this.handleDocumento}/>
                              </div>
                            </div>
                            <Button style={{width: 200, marginTop: 4, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.agregar() }}>Cancelar</Button>
                            <Button style={{width: 200, marginBottom: 20}} bsStyle="info" pullLeft fill type="submit" onClick={() => { this.guardarBeneficiario() }}>Guardar Proveedor</Button>
                          </div>
                        )}
                        {this.state.marcador2 == false ? (
                          <div>
                          <div className="row">
                              <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>SELECCIONE EL CONCEPTO</ControlLabel>
                                {/* <Select value={selectedOption7} onChange={this.handleChange7} options={this.state.conceptos}/> */}
                                <Autocomplete 
                                  inputRef={this.inputRef2}
                                  searchPattern={'containsString'}
                                  selectOnBlur = {false}
                                  placeholder = "Buscar concepto"
                                  maxOptionsLimit = {10} 
                                  searchEnabled = {true}
                                  getItemValue={(item)=>{ return item.label }} 
                                  onChange = {
                                    changedValue=>{ 
                                    this.generarChange(2, changedValue)
                                  }}
                                  onSelect = {
                                    selectedValue => { 
                                    this.generarSelect(2, selectedValue)
                                  }}
                                  itemsData = {this.state.conceptos}
                                  globalStyle = ".autocomplete {position: relative; display: inline-block; width: 100%} input {font-size: 14px} body {font-size: 14px; font-family: 'Roboto','Helvetica Neue',Arial,sans-serif; line-height: 1.42857143}"
                                />
                              </div>
                            </div>
                            <Button style={{width: 200, marginTop: 6, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.agregar2() }}>Nuevo</Button>
                          </div>
                        ) : (
                          <div>
                            <div className="row">
                              <div className="col-md-12" style={{marginBottom: 18}}>
                                  <ControlLabel>DEFINICIÓN DEL CONCEPTO</ControlLabel>
                                  <FormControl componentClass="textarea" style={{  height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.concepto} 
                                  onChange= {this.handleConcepto}/>
                              </div>
                            </div> 
                            <Button style={{width: 200, marginTop: 4, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.agregar2() }}>Cancelar</Button>
                            <Button style={{width: 200, marginBottom: 20}} bsStyle="info" pullLeft fill type="submit" onClick={() => { this.guardarConcepto() }}>Guardar Concepto</Button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                {/* </div>
                <hr></hr>
                <div className="row" style={{marginBottom: 10}}> */}
                  <div className="col-md-6">
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Datos del Movimiento</h4>
                        {this.state.isLoading2 ? (
                          <ClipLoader
                              css={override}
                              sizeUnit={"px"}
                              size={50}
                              color={'#123abc'}
                              loading={this.state.loading2}
                          />
                        ) : (
                          <div>
                            <div className="row">
                              <div className="col-md-4" style={{marginBottom: 18}}>
                                  <ControlLabel>HECHO POR</ControlLabel>
                                  <FormControl type="text" style={{textTransform: 'uppercase'}} value={this.state.usuario} onChange= {this.handleUsuario} disabled/>
                              </div>
                              <div className="col-md-4" style={{marginBottom: 18}}>
                                  <ControlLabel>N° CORRELATIVO</ControlLabel>
                                  <FormControl type="number" style={{textTransform: 'uppercase'}} value={this.state.correlativo} onChange= {this.handleCorrelativo}/>
                              </div>
                              <div className="col-md-4" style={{marginTop: 5, marginBottom: 18}}>
                                  <ControlLabel></ControlLabel><br></br>
                                  <Button style={{width: 200}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.obtenerCorrelativo(1) }}>Generar Correlativo</Button>
                              </div>
                            </div>
                            <div className="row" style={{marginTop: 18}}>
                              <div className="col-md-6" style={{marginBottom: 18}}>
                                  <ControlLabel>TIPO DE CAJA</ControlLabel>
                                  <Select value={selectedOption5} onChange={this.handleChange5} options={this.state.cajas}/>
                              </div>
                              <div className="col-md-6" style={{marginBottom: 18}}>
                                  <ControlLabel>NÚMERO</ControlLabel>
                                  <FormControl type="text" value={this.state.numerocaja} onChange= {this.handleNumerocaja}/>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6" style={{marginBottom: 18}}>
                                  <ControlLabel>FECHA DE PAGO</ControlLabel>
                                  <FormControl type="date" value={this.state.fecha} onChange= {this.handleFecha}/>
                              </div>
                              <div className="col-md-6" style={{marginBottom: 18}}>
                                  <ControlLabel>META</ControlLabel>
                                  <Select value={selectedOption3} onChange={this.handleChange3} options={this.state.metas}/>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6" style={{marginBottom: 18}}>
                                  <ControlLabel>OFICINA</ControlLabel>
                                  <Select value={selectedOption} onChange={this.handleChange} options={this.state.oficinas}/>
                              </div>
                              <div className="col-md-6" style={{marginBottom: 18}}>
                                  <ControlLabel>RESPONSABLE</ControlLabel>
                                  <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.responsables}/>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.marcador1 == false && this.state.marcador2 == false ? (
                        <Button style={{width: 200, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.guardar2() }}>Continuar</Button>
                        ) : ( null )}
                    </div>
                  </div>
              </div>
            </Col>
            <Button style={{width: 200, marginTop: 25, marginRight: 30}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.volver() }}>Ir a Caja Chica</Button>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default NuevaCajaChica;