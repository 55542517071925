import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar";
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {validator,sisperplus} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ListManuales extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'manuales',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,

      nombre:"",
      nid:"",
      manuales:[],
     
    }
    this.verManual = this.verManual.bind(this)
}
    
 
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
               this.validarLocal()
           //  this.listManuales()
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(JSON.parse(localStorage.getItem("personal")).manuales[0].data !== null){
     this.setState({
        nid: JSON.parse(localStorage.getItem('personal')).manuales[0].data.ctxt_tipo, 
        nombre: JSON.parse(localStorage.getItem('personal')).manuales[0].data.ctxt_titulo,          
        });

        this.listManuales();
        }else{
      this.props.history.push('/manuales');
    }
  }

  async listManuales() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'utilitarios/manuales?tipo=' + this.state.nid )
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              descripcion: data.ctxt_descripcion,
              ruta: data.ctxt_ruta,
              nid: data.nid,
              item: data.item
            }) 
          })
         
          this.setState({manuales: lista ,isLoading: false})
        }else{
          this.setState({manuales: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        
        this.setState({isLoading: false ,isLoading2: false})
      }
    });
  }
   
    
  
  async regresar(){
    this.props.history.push("/manuales")
  }
 
 verManual(cell, row){
    if (row != null){
    return(
        <div>
            <span data-tip="Ver Manual" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver(row.ruta) }}>
            <ReactTooltip />
        
            <i className="fa fa-file-pdf-o fa-stack-1x fa-inverse" style={{fontSize:30, color: "red"}}></i>
            </span>
        </div>
    )
    }else{

    }
 }
 ver(ruta){
   window.open(ruta, '_blank'); 
 }





  render() {
    const columns = [
        {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:100}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "descripcion", text: "APARTADO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "ruta", text: "MANUAL",  headerStyle: {backgroundColor: '#337AB7', color: "white",width:300}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter : this.verManual},
    ]
   
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.manuales.length
    };
    
    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div>
         <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.manuales }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
  
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col>
             <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}> 
                <h4 className="title text-center" style={{fontWeight: 200, marginTop: 18}}>MANUALES :
                 <a style={{fontWeight: "bold", color: "black",fontSize: 21}}> {this.state.nombre}</a></h4>  
                  {this.state.isLoading ? (
                          <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.isLoading}
                          />
                        ) : (
                      <div className="row" style={{marginTop: 30, marginBottom: 10}}>
                          <div className="col-md-12" > 
                          <Button style={{width: 250, marginTop: 10, marginBottom: 15}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                                <PaginationProvider
                                  pagination={
                                    paginationFactory(options)
                                  }>
                                  { contentTable }
                                </PaginationProvider>
                            </div>
                      </div>
                    )
                    }
              </div>
             
              
           </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ListManuales;