import * as auth0 from "auth0-js";
import axios from "axios";

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      clientID: "220zQX3VslroUhMSZ4zmfgPi0hdyqnij",
      domain: "tcperu.auth0.com",
      responseType: "token id_token",
      //redirectUri: "http://localhost:3000",
      redirectUri: "https://test.sedetc.gob.pe",
      scope: "openid profile read:auth read:sisper",
      audience: "https://appweb.sedetc.gob.pe",
    });

    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.autenticacion = this.autenticacion.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  getProfile() {
    return this.profile;
  }

  getIdToken() {
    return this.idToken;
  }

  isLoading() {
    return false;
  }

  isAuthenticated() {
    return new Date().getTime() > localStorage.getItem("expires_at");
  }

  signIn() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.accessToken) {
          return reject(err);
        }
        resolve("acceso");
        this.autenticacion(authResult);
      });
    });
  }

  autenticacion(authResult) {
    this.idToken = authResult.accessToken;
    this.profile = authResult.idTokenPayload;
    this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    localStorage.setItem("expires_at", this.expiresAt);
    localStorage.setItem("AccessToken", this.idToken);
    let data = {
      client_id: "220zQX3VslroUhMSZ4zmfgPi0hdyqnij",
      client_secret:
        "q_RvIeyPcCoYYcQvu91pcvNTT6EELoA427agvI64NeqzO5lXD_dt6zsbYCCvSmx6",
      audience: "https://tcperu.auth0.com/api/v2/",
      grant_type: "client_credentials",
    };
    axios
      .post("https://tcperu.auth0.com/oauth/token", data, {
        header: "content-type: application/json",
        crossDomain: true,
      })
      .then(
        (response) => {
          let token = "Bearer " + response.data.access_token;
          let idPersona = this.profile.sub;
          // axios.defaults.headers.common['Authorization'] = token
          axios
            .get("https://tcperu.auth0.com/api/v2/users/" + idPersona, {
              headers: {
                Authorization: token,
              },
            })
            .then(
              (response) => {
                let datos = response.data;
                let usuario = datos.username;
                localStorage.setItem("nickname", usuario);
                axios
                  .get(
                    "https://tomcat.sedetc.gob.pe/autentica-services-0.0.1/colaboradores/poslogin/" +
                      usuario,
                    {
                      headers: {
                        Authorization:
                          "Bearer " + localStorage.getItem("AccessToken"),
                      },
                    }
                  )
                  .then(
                    (response) => {
                      let dni = JSON.stringify(response.data[0].dni);
                      dni = JSON.parse(dni);
                      axios
                        .get(
                          "https://tomcat.sedetc.gob.pe/autentica-services-0.0.1/operaciones/app2/SISPER/" +
                            usuario,
                          {
                            headers: {
                              Authorization:
                                "Bearer " + localStorage.getItem("AccessToken"),
                            },
                          }
                        )
                        .then(
                          (response) => {
                            let operaciones = response.data;
                            if (operaciones != "") {
                              localStorage.setItem("dni", dni);
                              localStorage.setItem("idPersona", idPersona);
                              localStorage.setItem(
                                "operaciones",
                                JSON.stringify(operaciones)
                              );
                              let login = {
                                username: "concursos",
                                password: "vlvyndqlvtvC4831",
                              };
                              axios
                                .post(
                                  "https://tomcat.sedetc.gob.pe/autenticasedetc-0.0.1/authenticate",
                                  login,
                                  {
                                    header: "content-type: application/json",
                                    crossDomain: true,
                                  }
                                )
                                .then(
                                  (response) => {
                                    localStorage.setItem(
                                      "ConcurToken",
                                      response.data.token
                                    );
                                    window.location.href = "/inicio";
                                  },
                                  (error) => {
                                    console.log(error);
                                  }
                                );
                            } else {
                              this.signOut();
                              window.location.href = "/";
                            }
                          },
                          (error) => {
                            console.log(error);
                          }
                        );
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
              },
              (error) => {
                console.log(error);
              }
            );
        },
        (error) => {
          console.log(error);
        }
      );
  }

  signOut() {
    this.idToken = null;
    this.profile = null;
    this.expiresAt = null;
    localStorage.removeItem("AccessToken");
    localStorage.removeItem("ConcurToken");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("profile");
    localStorage.removeItem("operaciones");
    localStorage.removeItem("datos");
    localStorage.removeItem("dni");
    localStorage.removeItem("idPersona");
    localStorage.removeItem("nickname");
    localStorage.removeItem("solicitud");
    localStorage.removeItem("concurso");
    localStorage.removeItem("postulante");
    localStorage.removeItem("beneficiario");
    localStorage.removeItem("movimientocomp");
    localStorage.removeItem("movimientocaja");
    localStorage.removeItem("fechasmovicomp");
    localStorage.removeItem("fechasmovicaja");
    localStorage.removeItem("fechasmoviche");
    localStorage.removeItem("libro");
    localStorage.removeItem("periolibro");
    localStorage.removeItem("data");
    localStorage.removeItem("fechasBusqueda");
    localStorage.removeItem("cec");
    localStorage.removeItem("jurisdiccional");
    localStorage.removeItem("recursoshumanos");
    localStorage.removeItem("personal");
    localStorage.removeItem("aulaVirtual");
    localStorage.removeItem("desplazamiento");
  }
}

const auth0Client = new Auth();

export default auth0Client;
