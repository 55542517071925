import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from "login/Auth";
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from "react-tooltip";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Calendar from "components/Calendar/Calendar.jsx";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { validator, sisperplus } from "store/Apis";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-bottom: 60px;
`;

class ConceptosPago extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "administrarAreas",
      usuario: localStorage.getItem("nickname"),
      token:
        localStorage.getItem("AccessToken") != null
          ? "Bearer " + localStorage.getItem("AccessToken")
          : 0,
      id: JSON.parse(localStorage.getItem("datos"))[0].Person,
      movimientos: [],
      nid: "",
      descripcionLga: "",
      descripcionCta: "",
      isLoading: true,
    };
    this.GetActionFormat = this.GetActionFormat.bind(this);
    this.handleDescripcionLga = this.handleDescripcionLga.bind(this);
    this.handleDescripcionCta = this.handleDescripcionCta.bind(this);
  }
  handleDescripcionLga(event) {
    this.setState({ descripcionLga: event.target.value });
  }
  handleDescripcionCta(event) {
    this.setState({ descripcionCta: event.target.value });
  }

  async componentDidMount() {
    if (
      localStorage.getItem("AccessToken") != null &&
      localStorage.getItem("expires_at") != null &&
      localStorage.getItem("operaciones") != null &&
      localStorage.getItem("nickname") != null
    ) {
      axios
        .get(validator + this.state.path + "/" + this.state.usuario, {
          headers: {
            Authorization: this.state.token,
          },
        })
        .then(
          (response) => {
            let permisos = response.data;
            if (permisos.codError != 1) {
              this.props.history.goBack();
            } else {
              this.listConceptoPago();
            }
          },
          (error) => {
            if (auth0Client.isAuthenticated() === true) {
              auth0Client.signOut();
              this.props.history.replace("/");
            } else {
              this.props.history.goBack();
            }
          }
        );
    } else {
      auth0Client.signOut();
      this.props.history.replace("/");
    }
  }

  async listConceptoPago() {
    this.setState({ isLoading: true });
    axios.defaults.headers.common["Authorization"] = this.state.token;
    axios
      .get(`${sisperplus}` + "utilitarios/listConceptos")
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if (lista2 != null) {
          lista2.map((data) => {
            lista.push({
              item: data.item,
              nid: data.nid,
              flg_estado: data.flg_estado,
              fechaCreacion: Calendar.calendarioA(data.dfec_creacion),
              descripcionLga: data.ctxt_descripcion_lga,
              descripcionCta: data.ctxt_descripcion_cta,
              codigo: data.ctxt_codigo,
            });
          });
          this.setState({ movimientos: lista, isLoading: false });
        } else {
          this.setState({ movimientos: [], isLoading: false });
          this.props.handleClick(
            "tl",
            "error",
            "Búsqueda incorrecta!",
            "pe-7s-info"
          );
        }
      })
      .catch((error) => {
        if (auth0Client.isAuthenticated() === true) {
          auth0Client.signOut();
          this.props.history.replace("/");
        } else {
          console.log(error);
          this.setState({ isLoading: false });
        }
      });
  }

  async save() {
    if (this.state.descripcionCta != "" && this.state.descripcionLga != "") {
      if (this.state.nid == "") {
        this.guardar();
      } else {
        this.update();
      }
    } else {
      this.props.handleClick(
        "tl",
        "warning",
        "Debe completar todos los campos!",
        "pe-7s-info"
      );
    }
  }

  guardar() {
    this.setState({ isLoading: true });
    let fromData = new FormData();
    fromData.append("descripcion_cta", this.state.descripcionCta.toUpperCase());
    fromData.append("descripcion_lga", this.state.descripcionLga.toUpperCase());
    fromData.append("usuario", this.state.usuario);
    axios.defaults.headers.common["Authorization"] = this.state.token;
    axios
      .post(`${sisperplus}` + "utilitarios/insertConceptos", fromData)
      .then((response) => {
        var datos = response.data;
        if (datos.response != null) {
          this.limpiar();
          this.props.handleClick(
            "tl",
            "info",
            "El concepto de pago ha sido registrado!",
            "pe-7s-smile"
          );
        } else {
          this.props.handleClick(
            "tl",
            "error",
            "Hubo un problema al registrar!",
            "pe-7s-close"
          );
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        if (auth0Client.isAuthenticated() === true) {
          auth0Client.signOut();
          this.props.history.replace("/");
        } else {
          console.log(error);
          this.setState({ isLoading: false });
        }
      });
  }

  update() {
    this.setState({ isLoading: true });
    let fromData = new FormData();
    fromData.append("nidconcepto", this.state.nid);
    fromData.append("descripcion_cta", this.state.descripcionCta.toUpperCase());
    fromData.append("descripcion_lga", this.state.descripcionLga.toUpperCase());
    fromData.append("usuario", this.state.usuario);
    axios.defaults.headers.common["Authorization"] = this.state.token;
    axios
      .post(`${sisperplus}` + "utilitarios/updateConceptos", fromData)
      .then((response) => {
        var datos = response.data;
        if (datos.response != null) {
          this.limpiar();
          this.props.handleClick(
            "tl",
            "info",
            "El registro ha sido actualizado!",
            "pe-7s-smile"
          );
        } else {
          this.props.handleClick(
            "tl",
            "error",
            "Hubo un problema al actualizar!",
            "pe-7s-close"
          );
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        if (auth0Client.isAuthenticated() === true) {
          auth0Client.signOut();
          this.props.history.replace("/");
        } else {
          console.log(error);
          this.setState({ isLoading: false });
        }
      });
  }

  async limpiar() {
    this.listConceptoPago();
    this.cancelar();
  }

  async cancelar() {
    this.setState({ descripcionLga: "", descripcionCta: "", nid: "" });
  }

  confirmarDelete = (data) => {
    var titulo = "";
    var mensaje = "";
    if (data.flg_estado == "ACTIVO") {
      titulo = "¿Seguro que desea anular el registro?";
      mensaje = "Se anulará el registro seleccionado.";
    } else {
      titulo = "¿Seguro que desea habilitar el registro?";
      mensaje = "Se activará el registro seleccionado";
    }
    confirmAlert({
      title: titulo,
      message: mensaje,
      buttons: [
        {
          label: "Aceptar",
          onClick: () => this.delete(data),
        },
        {
          label: "Cancelar",
          onClick: () =>
            this.props.handleClick(
              "tl",
              "warning",
              "Se canceló la operación!",
              "pe-7s-info"
            ),
        },
      ],
    });
  };

  async delete(data) {
    this.setState({ isLoading: true });
    let fromData = new FormData();
    fromData.append("nidconcepto", data.nid);
    axios.defaults.headers.common["Authorization"] = this.state.token;
    axios
      .post(
        `${sisperplus}` + "utilitarios/anular_activarlistConceptos",
        fromData
      )
      .then((response) => {
        var datos = response.data;
        if (datos.response != null) {
          this.listConceptoPago();
          if (data.flg_estado == "ACTIVO") {
            this.props.handleClick(
              "tl",
              "info",
              "El registro ha sido anulado!",
              "pe-7s-smile"
            );
          } else {
            this.props.handleClick(
              "tl",
              "info",
              "El registro ha sido habilitado!",
              "pe-7s-smile"
            );
          }
        } else {
          this.props.handleClick(
            "tl",
            "error",
            "Hubo un problema con manejar!",
            "pe-7s-close"
          );
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        if (auth0Client.isAuthenticated() === true) {
          auth0Client.signOut();
          this.props.history.replace("/");
        } else {
          console.log(error);
          this.setState({ isLoading: false });
        }
      });
  }

  GetActionFormat(cell, row) {
    if (row != undefined) {
      return (
        <div>
          <span
            data-tip="Editar Registro"
            className="fa-stack fa-2x"
            style={{ cursor: "pointer", fontSize: 20 }}
            onClick={() => {
              this.obtener(row);
            }}
          >
            <ReactTooltip />
            <i className="fa fa-circle fa-stack-2x text-success"></i>
            <i
              className="fa fa-pencil-square-o fa-stack-1x fa-inverse"
              style={{ fontSize: 15 }}
            ></i>
          </span>

          {row.flg_estado == "ACTIVO" ? (
            <span
              data-tip="Anular Concepto"
              className="fa-stack fa-2x"
              style={{ cursor: "pointer", fontSize: 20 }}
              onClick={() => {
                this.confirmarDelete(row);
              }}
            >
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x"></i>
              <i
                className="fa fa-times fa-stack-1x fa-inverse"
                style={{ fontSize: 15 }}
              ></i>
            </span>
          ) : (
            <span
              data-tip="Activar Concepto"
              className="fa-stack fa-2x"
              style={{ cursor: "pointer", fontSize: 20 }}
              onClick={() => {
                this.confirmarDelete(row);
              }}
            >
              <ReactTooltip />
              <i
                className="fa fa-circle fa-stack-2x"
                style={{ color: "#285511" }}
              ></i>
              <i
                className="fa fa-check fa-stack-1x fa-inverse"
                style={{ fontSize: 15 }}
              ></i>
            </span>
          )}
        </div>
      );
    } else {
    }
  }

  async obtener(data) {
    this.setState({
      descripcionLga: data.descripcionLga,
      descripcionCta: data.descripcionCta,
      nid: data.nid,
    });
  }

  async regresar() {
    this.props.history.push("/administrarAreas");
  }

  render() {
    const columns = [
      {
        dataField: "item",
        text: "ITEM",
        sort: true,
        headerStyle: { backgroundColor: "#337AB7", color: "white", width: 150 },
        headerAlign: (column, colIndex) => "center",
        align: "center",
      },
      {
        dataField: "codigo",
        text: "CÓDIGO",
        filter: textFilter(),
        sort: true,
        headerStyle: { backgroundColor: "#337AB7", color: "white" },
        headerAlign: (column, colIndex) => "center",
        align: "center",
      },
      {
        dataField: "descripcionLga",
        text: "DESCRIPCIÓN LARGA",
        filter: textFilter(),
        sort: true,
        headerStyle: { backgroundColor: "#337AB7", color: "white", width: 400 },
        headerAlign: (column, colIndex) => "center",
        align: "center",
      },
      {
        dataField: "descripcionCta",
        text: "DESCRIPCIÓN CORTA",
        filter: textFilter(),
        sort: true,
        headerStyle: { backgroundColor: "#337AB7", color: "white", width: 400 },
        headerAlign: (column, colIndex) => "center",
        align: "center",
      },
      {
        dataField: "fechaCreacion",
        text: "FECHA",
        sort: true,
        headerStyle: { backgroundColor: "#337AB7", color: "white" },
        headerAlign: (column, colIndex) => "center",
        align: "center",
      },
      {
        dataField: "flg_estado",
        text: "ESTADO",
        sort: true,
        headerStyle: { backgroundColor: "#337AB7", color: "white", width: 150 },
        headerAlign: (column, colIndex) => "center",
        align: "center",
      },
      {
        dataField: "detail",
        text: "ACCIONES",
        headerStyle: { backgroundColor: "#337AB7", color: "white", width: 200 },
        headerAlign: (column, colIndex) => "center",
        align: "center",
        formatter: this.GetActionFormat,
      },
    ];
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: "Primera",
      prePageText: "Anterior",
      nextPageText: "Siguiente",
      lastPageText: "Última",
      showTotal: true,
      totalSize: this.state.movimientos.length,
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={this.state.movimientos}
              columns={columns}
              filter={filterFactory()}
              noDataIndication="No existen registros para mostrar"
              {...paginationTableProps}
            />
          </div>
          <div style={{ float: "right" }}>
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div
                style={{
                  borderRadius: 5,
                  backgroundColor: "white",
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 1,
                  paddingBottom: 5,
                }}
              >
                <h4
                  className="title"
                  style={{ fontWeight: 200, marginTop: 18, marginBottom: 25 }}
                >
                  Concepto de Pagos
                </h4>
                <div className="row" style={{ marginBottom: 10 }}>
                  <div className="col-md-5" style={{ marginBottom: 18 }}>
                    <ControlLabel>DESCRIPCIÓN LARGA</ControlLabel>
                    <FormControl
                      type="text"
                      value={this.state.descripcionLga}
                      onChange={this.handleDescripcionLga}
                    />
                  </div>
                  <div className="col-md-5" style={{ marginBottom: 18 }}>
                    <ControlLabel>DESCRIPCIÓN CORTA</ControlLabel>
                    <FormControl
                      type="text"
                      value={this.state.descripcionCta}
                      onChange={this.handleDescripcionCta}
                    />
                  </div>

                  <div className="col-md-2">
                    <Button
                      style={{ width: 200, marginBottom: 10, marginTop: 26 }}
                      bsStyle="success"
                      pullRight
                      fill
                      type="submit"
                      onClick={() => {
                        this.save();
                      }}
                    >
                      GUARDAR
                    </Button>
                    <Button
                      style={{ width: 200, marginBottom: 10 }}
                      bsStyle="danger"
                      pullRight
                      type="submit"
                      onClick={() => {
                        this.cancelar();
                      }}
                    >
                      CANCELAR
                    </Button>
                  </div>
                </div>
                {this.state.isLoading ? (
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={50}
                    color={"#123abc"}
                    loading={this.state.loading}
                  />
                ) : (
                  <PaginationProvider pagination={paginationFactory(options)}>
                    {contentTable}
                  </PaginationProvider>
                )}
              </div>
              <Button
                style={{ width: 250, marginTop: 10 }}
                bsStyle="danger"
                fill
                type="submit"
                onClick={() => {
                  this.regresar();
                }}
              >
                Regresar
              </Button>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ConceptosPago;
