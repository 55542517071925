import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { jurisdiccional} from 'store/Apis'
import 'react-tabs/style/react-tabs.css';
import moment from 'moment'
import Select from 'react-select';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { confirmAlert } from 'react-confirm-alert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class CrearExpedientesVotados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: 'votados',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      ctxt_descripcion : "", 
      dfec_fecha:  moment(new Date()).format('YYYY-MM-DD'),
      cnom_archivo: "",
      ctxt_ruta: "",
      estado: 0,
      isLoading: false,
      show: true,
      flg_tipo: {value: "agenda", label: "AGENDA"},
      tipos: [    
        {value: "agenda", label: "AGENDA"},
        {value: "acuerdo", label: "ACUERDO"}
      ]   
    }
    this.handleDescripcion = this.handleDescripcion.bind(this);
    this.handleFecha = this.handleFecha.bind(this);
    this.handleArchivo = this.handleArchivo.bind(this);
    this.handleRuta = this.handleRuta.bind(this);
  }
  handleTipo = flg_tipo => {
    this.setState(
        {flg_tipo},
    );
   };
    handleDescripcion(event) {this.setState({ctxt_descripcion: event.target.value});}
    handleFecha(event) {this.setState({dfec_fecha: event.target.value});}
    handleArchivo(event) {this.setState({cnom_archivo: event.target.value});}
    handleRuta(event) {this.setState({ctxt_ruta: event.target.value});}
    
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
  async validar(){
    if(this.state.ctxt_descripcion != ''  &&  this.state.dfec_fecha != ''
    && this.state.cnom_archivo != '' && this.state.ctxt_ruta){
      this.agregar();
     }else{
       this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close") 
     }
   }

  async agregar(){  
    let fromData = new FormData();
    fromData.append('descripcion', this.state.ctxt_descripcion);
    fromData.append('archivo',this.state.cnom_archivo);
    fromData.append('ruta', this.state.ctxt_ruta)
    fromData.append('fecha', this.state.dfec_fecha);
    fromData.append('tipo', this.state.flg_tipo.value);
    fromData.append('usuario',this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'acuerdospleno/insertarAcuerdos', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Registrado correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }
  async regresar(){
    this.props.history.push('/votados');
  }

  abrir(ruta){
    window.open(ruta, '_blank');
  } 
  mostrar = () => {
    confirmAlert({
      title: <i class="fa fa-user" style={{ color :"black", marginLeft: 130,fontSize: 100}}></i>,
      message:<div><p>USUARIO : Usuario_01</p><p>CONTRASEÑA : Tribunal2022$</p> </div> ,
      buttons: [
        {
          label: 'OK',
        },        
      ],
      closeOnClickOutside: false,
    });}

  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Nuevo Registro</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                       <div className="row">
                            <div className="col-md-8" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-bars"></i> DESCRIPCIÓN</ControlLabel>
                                <FormControl type="text" value={this.state.ctxt_descripcion} onChange= {this.handleDescripcion}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-calendar"></i> FECHA</ControlLabel>
                                <FormControl type="DATE" value={this.state.dfec_fecha} onChange= {this.handleFecha}/>
                            </div>
                            <div className="col-md-2" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-bars"></i> TIPO</ControlLabel>
                                <Select value={this.state.flg_tipo} onChange={this.handleTipo} options={this.state.tipos}/>
                            </div>
                            <div className="col-md-5" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-file-pdf-o"></i> NOMBRE DEL DOCUMENTO</ControlLabel>
                                <FormControl type="text" value={this.state.cnom_archivo} onChange= {this.handleArchivo}/>
                            </div>
                            <div className="col-md-5" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-link"></i> LINK DEL DOCUMENTO</ControlLabel>
                                <FormControl type="text" value={this.state.ctxt_ruta} onChange= {this.handleRuta}/>
                            </div>
                            <div className="col-md-12" style={{marginBottom: 18}}>
                             <Button style={{width: 150}} bsStyle="primary" pullRight  type="submit" onClick={() => { this.mostrar() }}>Ver Credenciales</Button>
                             <Button style={{width: 350,marginRight:5}} bsStyle="primary" pullRight type="submit" onClick={() => { this.abrir("https://www.tc.gob.pe/wp-login.php") }}>Haga click aquí para subir el documento</Button>
                            </div>
                            

                        </div>
                        <Tabs style={{marginTop: 30}}>
                          <TabList>
                            <Tab>VIZUALIZAR DOCUMENTO </Tab>
                          </TabList>
                          <TabPanel>
                           {this.state.ctxt_ruta != "" && this.state.ctxt_ruta != null ? (
                              <a href={this.state.ctxt_ruta} target="blank"><i style={{color: "red"}}
                              className="fa fa-file-pdf-o fa-5x" aria-hidden="true"></i></a>
                              ) : (
                              <p>Sin Documento</p>
                              )}
                          </TabPanel>
                        </Tabs>
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Registro</Button>
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Guardar Registro</Button>
                    </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CrearExpedientesVotados;