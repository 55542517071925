import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {cec,paramCec } from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Memorias from "../Memorias";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class MemoriaAdmin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'publicaciones',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      nid_seccion :  JSON.parse(localStorage.getItem('cec')).cuaderno[0].data.nid_seccion,
      titulo : JSON.parse(localStorage.getItem('cec')).cuaderno[0].data.descripcion,
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
 

    this.imagenlibro=this.imagenlibro.bind(this);
    this.verenlace = this.verenlace.bind(this);
  }


  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
           this.listarMemorias() 
           console.log(JSON.parse(localStorage.getItem('cec')).cuaderno[0].data)
        }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listarMemorias() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${cec}` + 'contenido/listContenido?nidseccion=' + this.state.nid_seccion)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                ctxt_archivo: data.ctxt_archivo,
                ctxt_descripcion :data.ctxt_descripcion,
                ctxt_imagen: data.ctxt_imagen,
                ctxt_rutaarchivo: data.ctxt_rutaarchivo,
                ctxt_rutaimagen: data.ctxt_rutaimagen,
                ctxt_tamano: data.ctxt_tamano,
                ctxt_titulo: data.ctxt_titulo,
                item: data.item,
                nid: data.nid,
                nid_seccion: data.nid_seccion,
                descripcion : data.nid_seccion==45 ? "Memorias TC y CEC: Periodos 2005 al 2007" : data.nid_seccion==4 ? "Memorias CEC: Periodos 2008 al 2012": "Memorias TC y CEC: Periodos 2013 en adelante" 
            })
          })
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }


  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea anular el registro ?',
      message: 'Se anulará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidcontenido', data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'contenido/anularContenido', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 1){
            this.listarMemorias()
            this.props.handleClick("tl", "info", "El regitro ha sido anulado!", "pe-7s-smile")
        }else{
            this.props.handleClick("tl", "error", "No se puede anular el registro!", "pe-7s-close")
            this.setState({isLoading: false})
        }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
  
  async regresar(){
    this.props.history.push('/memorias');}

  async detalle(datos){
    paramCec.cuaderno[0].data = datos
    localStorage.setItem('cec', JSON.stringify(paramCec))
    this.props.history.push('/editarMemoria');
  }
  async detalle2(){
    paramCec.cuaderno[0].data = {nid_seccion: this.state.nid_seccion,descripcion:this.state.titulo}
    localStorage.setItem('cec', JSON.stringify(paramCec))
    this.props.history.push('/crearMemoria');
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Anular Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }
  
  imagenlibro(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span  data-tip="Ver portada" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(row.ctxt_rutaimagen) }}>
                 <img src={row.ctxt_rutaimagen} alt="" style={{width:"150px"}}></img>
              </span>
          </div>
            );
    }else{}
  }
  
  abrirImagen(ruta){
    window.open(ruta, '_blank');
  } 
  
  verenlace(cell, row) {
    if(row != undefined){
      return (
          <div>
              {row.ctxt_rutaarchivo != null && row.ctxt_rutaarchivo != ''? (
              <span data-tip="Ver Documento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(row.ctxt_rutaarchivo) }}>
                  <ReactTooltip /> 
                  <i className="fa fa-book fa-2x" style={{fontSize:70,marginLeft:-45,marginTop:-15}}></i>
              </span>
              ) : (null)}
          </div>
      );
    }else{}
  }

  render() {
    const columns = [
    {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white" ,width:100}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "ctxt_titulo", text: "TÍTULO",filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white",width:350}, headerAlign: (column, colIndex) => 'center', align: 'center'},
  //{dataField: "categoria", text: "CATEGORIA", filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white",width:300}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "ctxt_descripcion", text: "DESCRIPCION", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "libro", text: "PORTADA", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.imagenlibro},
    {dataField: "documento", text: "DOCUMENTO", headerStyle: {backgroundColor: '#337AB7', color: "white" ,width:200}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.verenlace},
  //{dataField: "detalle", text: "DETALLE", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'justify',formatter: this.VerLibro},
    {dataField: "ctxt_tamano", text: "TAMAÑO", headerStyle: {backgroundColor: '#337AB7', color: "white",width:100}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:120}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>{this.state.titulo==undefined ? 'Memorias' : this.state.titulo}</h4>
                  <div className="row">
                    <div className="col-md-6" style={{marginBottom: 18}}>
                        <ControlLabel></ControlLabel><br></br>
                        <Button style={{width: 250, marginTop: 5, marginRight: 10,marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                        <Button style={{width: 250, marginTop: 5, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.detalle2() }}>Nuevo </Button>
                    </div>
                  </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default MemoriaAdmin;