import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import auth0Client from 'login/Auth';
import axios from 'axios';
import { gestor, sisperplus } from 'store/Apis'
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import InputFiles from 'react-input-files';
import Select from 'react-select';
import moment from 'moment';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { validator } from 'store/Apis'
import "react-confirm-alert/src/react-confirm-alert.css";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class CrearPapeletasAdm extends Component {

constructor(props) {
super(props);
  this.state = {
    path: 'administrarPapeletas',
    dni: localStorage.getItem('dni'),
    usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
    id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
    token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
    fechaini: moment(new Date()).format('YYYY-MM-DD'),
    fechafin: moment(new Date()).format('YYYY-MM-DD'),
    selectedOption: "",
    papeletas: [],
    file: [],
    fileName: '',
    type: '',
    motivo: '',
    sustento: '',
    isGoing: false,
    isLoading: false,
    dni2: "",
    nombre:"",
    idpersona:"",
    enlace: ""
  }
  this.handleDNI = this.handleDNI.bind(this);
  this.handleFechaIni = this.handleFechaIni.bind(this);
  this.handleFechaFin = this.handleFechaFin.bind(this);
  this.handleMotivo = this.handleMotivo.bind(this);
  this.handleSustento = this.handleSustento.bind(this);
  this.handleCheck = this.handleCheck.bind(this);

}

handleDNI(event) {this.setState({dni2: event.target.value});}

handleFechaIni(event) {this.setState({fechaini: event.target.value});}

handleFechaFin(event) {this.setState({fechafin: event.target.value});}

handleMotivo(event) {this.setState({motivo: event.target.value});}

handleSustento(event) {this.setState({sustento: event.target.value});}

handleCheck(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
}

handleChange = selectedOption => {
    this.setState(
    { selectedOption },
    );
};

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
              this.validarLocal();
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  async validarLocal(){ 
    if(JSON.parse(localStorage.getItem("recursoshumanos")).trabajadoresAdm[0].data !== null){
          this.setState({
                  idpersona: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.person,
                  dni2: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.documentNumber,
                  nombre: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.nombreCompleto,
                  ruta: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.ruta,
                  enlace: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[1].enlace,
          });
          this.cargaView();
       
    }}


  async cargaView() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'papeletas/tiposPapeletas?param=0')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.tipopapeleta,
                label: data.descripcion,
            })
          });
          this.setState({papeletas: lista, selectedOption : {value : lista[0].value, label : lista[0].label },isLoading: false})

      }else{
        this.setState({papeletas: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
   }

  
   onKeyUpValue() {
    if(this.state.dni2.length == 8){
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'papeletaAdm/listPerson?dni=' + this.state.dni2)
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { nombres: data.Name,
                idpersona : data.person
              })
          });
          if(lista[0] == null ){       
            this.props.handleClick("tl", "error", "El DNI ingresado no se encuentra registrado!", "pe-7s-info")
            this.setState({nombre: "", idpersona: "", isLoading: false})
            }else{
              this.setState({nombre: lista[0].nombres, idpersona: lista[0].idpersona, isLoading: false})
            }
        }else{
           this.props.handleClick("tl", "error", "Error de conexión!", "pe-7s-info")
        }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }else if(this.state.dni2.length > 8){
        this.props.handleClick("tl", "error", "El DNI ingresado tiene más de 8 dígitos!", "pe-7s-info")
    }else {
      this.setState({nombre: ""})
    }
  }

  cerrar(){
    this.setState({visFile: false});
  } 

  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], fileName: files[0].name, type: files[0].type, visFile: true});
    }
  }

  async cancelar(){
    if(this.state.enlace == "T"){
      this.props.history.push('/verPapeletaTrabajador');
    }else{
   
      this.props.history.push('/administrarPapeletas');
    }
   
  }

  guardar(){
    if(this.state.type == '' || this.state.type == 'application/pdf' || this.state.type == 'image/jpeg' || this.state.type == 'image/png'){
      if(this.state.fechaini != '' && this.state.fechafin != '' && this.state.motivo != '' && this.state.dni2 !=  '' && this.state.nombre != ''){
          this.setState({isLoading: true})
          let fromData = new FormData();
          fromData.append('idperson', this.state.idpersona);
          fromData.append('nombres', this.state.nombre);
          fromData.append('tipopapeleta', this.state.selectedOption.value);
          fromData.append('observaciones', this.state.motivo);
          fromData.append('fechaini', this.cambioFormat(this.state.fechaini));
          fromData.append('fechafin', this.cambioFormat(this.state.fechafin));
          fromData.append('documentcomments', this.state.sustento == "" ? "" : this.state.sustento);
          fromData.append('flagjustifica', this.cambioCheck(this.state.isGoing));
          axios.defaults.headers.common['Authorization'] = this.state.token
          axios.post(`${sisperplus}` + 'papeletaAdm/insertarPapeletaAdm', fromData)
          .then((response) => {
            var datos = response.data
            if(datos.response == null){
              axios.defaults.headers.common['Authorization'] = this.state.token
              axios.get(`${sisperplus}` + 'papeletas/listaTop?id=' + this.state.idpersona)
              .then((res) => {
                let lista = res.data.response;
                  if(this.state.file != ''){
                    this.subirArchivo(lista[0].idpapeleta)
                  }else{
                    this.props.handleClick("tl", "info", "Tu papeleta ha sido registrada!", "pe-7s-smile")
                    this.cancelar()
                  }
              })
              .catch((error) => { 
                if(auth0Client.isAuthenticated() === true){
                  auth0Client.signOut()
                  this.props.history.replace('/');
                }else{
                  console.log(error);
                  this.setState({isLoading: false})
                }
              });
            }else{
              this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
              this.setState({isLoading: false})
            }
          })
          .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              this.setState({isLoading: false})
            }
          });
        // }
      }else{
        this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
      }
    }else{
      this.props.handleClick("tl", "warning", "El archivo a subir debe ser un pdf o una imagen!", "pe-7s-info")
    }
  }

  subirArchivo(idpapeleta){
    let fromData = new FormData();
    fromData.append('pape', idpapeleta);
    fromData.append('filename', this.state.fileName);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'papeletaAdm/insertarArchivoAdm', fromData)
    .then((response) => {
      var datos = response.data.response
      if(datos.archivo != null){
        this.uploadArchivo(datos.archivo)
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al subir archivo!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
  
  uploadArchivo(archivo){
        let anio = new Date().getFullYear()
        let mes = parseInt(new Date().getMonth()) + 1
        if(mes < 10){mes = '0'+ mes}
        let fromData = new FormData();
        fromData.append('usuario', this.state.usuario);
        fromData.append('archivo_file', this.state.file);
        fromData.append('anio', anio);
        fromData.append('mes', mes);
        fromData.append('cnomArchivo', archivo);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${gestor}` + 'document/subirArchivoPap', fromData)
        .then((response) => {
          if(response.data.result == null){
            this.props.handleClick("tl", "info", "Tu papeleta ha sido registrada!", "pe-7s-smile")
            this.cancelar();
           
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al guardar archivo!", "pe-7s-close")
            this.setState({isLoading2: false})
          }
        })
        .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
  }

  cambioFormat(fecha){
    var dia = fecha.substring(8,10);
    var mes = fecha.substring(5,7);
    var anio = fecha.substring(0,4);
    var newfecha = dia + '-' + mes + '-' + anio
    return newfecha
  }

  cambioCheck(check){
    if(check == true){
     return 'Y';
    }else{
     return 'N';
    }
  }
  async limpiar(){
    this.setState({fileName: "", file: ""})
  }

  render() {
    const { selectedOption } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
            <Card
                title="REGISTRAR PAPELETA"
                content={
                  this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                  <div>
                    
                    <div className="row">
                 
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>INGRESE EL DNI</ControlLabel>
                            <FormControl  type="number" value={this.state.dni2} onChange={this.handleDNI} onKeyUp={this.onKeyUpValue.bind(this)} disabled={this.state.enlace == "T"}/>
                        </div>
                      
                        <div className="col-md-8" style={{marginBottom: 18}}>
                            <ControlLabel>NOMBRE Y APELLIDOS</ControlLabel>
                            <FormControl type="text" value={this.state.nombre} disabled/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>TIPO DE PAPELETA<a style={{color:"red"}}> *</a></ControlLabel>
                            <Select value={selectedOption} onChange={this.handleChange} options={this.state.papeletas}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DE INICIO<a style={{color:"red"}}> *</a></ControlLabel>
                            <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaIni}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DE TÉRMINO<a style={{color:"red"}}> *</a></ControlLabel>
                            <FormControl type="date" value={this.state.fechafin} onChange= {this.handleFechaFin}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>MOTIVO<a style={{color:"red"}}> *</a></ControlLabel>
                            <FormControl componentClass="textarea" style={{  height: 150, maxHeight: 150, minHeight: 150, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.motivo} onChange= {this.handleMotivo}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>DOCUMENTO SUSTENTO <a>(OPCIONAL)</a></ControlLabel>
                            <FormControl componentClass="textarea" style={{ height: 150, maxHeight: 150, minHeight: 150, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.sustento} onChange= {this.handleSustento}/>
                        </div>
                        <div className="col-md-4" style={{letterSpacing: 1}}>
                        <ControlLabel>JUSTIFICA INGRESO FUERA DE HORARIO</ControlLabel><br></br>
                        <div>
                            <input onChange={this.handleCheck} name="isGoing" type="checkbox" checked={this.state.isGoing} style={{marginRight: 5}}
                            /><a>Marque solamente cuando esta papeleta justificará un ingreso fuera de horario.</a>
                        </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: 18, marginBottom: 20}}>
                    <div className="col-md-3"></div>
                        {/* {this.state.visFile != false ? ( */}
                            <div className="col-md-6" style={{marginBottom: 18}}>
                            <ControlLabel>ARCHIVOS SUBIDOS <a>(OPCIONAL)</a></ControlLabel>
                            <Alert bsStyle="warning">
                                {/* <button onClick={() => { this.cerrar() }} type="button" aria-hidden="true" className="close">&#x2715;</button> */}
                                <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.fileName}</span>
                            </Alert>
                            <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                                <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                            </Button>
                            {this.state.fileName != "" ? (
                                  <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar Archivo</Button>
                                ) : (  null )}
                        </div>
                        {/* ) : (null)} */}
                    </div>
                    <Button style={{width: 150, marginLeft: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Ver Papeletas</Button>
                    <Button style={{width: 150, marginLeft: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.guardar() }}>Guardar Papeleta</Button>
                  </div>
                 )}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CrearPapeletasAdm;