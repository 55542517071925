import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { tesoreria, paramTesoreria } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ConsultaBeneficiarios extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'beneficiarios',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      selectedOption: "",
      beneficiarios: [],
      tiposbeneficiario: [],
      beneficiario: "",
      numero: "",
      nidcarga: "",
      isLoading: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);

    this.handleBeneficiario = this.handleBeneficiario.bind(this);
    this.handleNumero = this.handleNumero.bind(this);

  }

    handleBeneficiario(event) {
        this.setState({beneficiario: event.target.value});
    }

    handleNumero(event) {
        this.setState({numero: event.target.value});
    }

    handleChange = selectedOption => {
        this.setState(
        { selectedOption },
        );
    };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.cargaView();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async cargaView() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listCodigos?tipo=tipo_beneficiario')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.nid,
                label: data.nombre,
            })
          });
          this.setState({tiposbeneficiario: lista, selectedOption: {value: lista[0].value, label: lista[0].label}})
          this.listarBeneficiarios();
      }else{
        this.setState({tiposbeneficiario: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
   }

  obtenerTipo(tipo){
    if(tipo == 12){
      return 'TRABAJADOR';
    }else if(tipo == 13){
      return 'PROVEEDOR';
    }else{
      return 'CONCEPTOS'
    }
  }

  async listarBeneficiarios() {
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${tesoreria}` + 'beneficiarios/listBeneficiarios')
      .then((res) => {
        let lista = [];
        let lista3 = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                item: data.item,
                nidbeneficiario: data.nid,
                nidtipobeneficiario: data.nid_tipo,
                beneficiario: data.ctxt_descripcion,
                numero: data.cnum_documento,
                tipobeneficiario: this.obtenerTipo(data.nid_tipo)
            })
            lista3.push({
                value: data.nid,
                label: data.cnum_documento + ' (' + data.ctxt_descripcion + ')', 
                tipo: data.nid_tipo,
                documento: data.cnum_documento,  
                clase: data.ctxt_descripcion
            })
          })
          paramTesoreria.beneficiarios[0].data = lista3
          localStorage.setItem('data', JSON.stringify(paramTesoreria))
          this.setState({beneficiarios: lista, isLoading: false})
          this.cancelar();
        }else{
          this.setState({beneficiarios: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidbeneficiario', data.nidbeneficiario);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'beneficiarios/anularBeneficiario', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarBeneficiarios();
        this.props.handleClick("tl", "info", "El beneficiario ha sido eliminado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async limpiar(){
    this.listarBeneficiarios();
    this.cancelar();
    this.props.handleClick("tl", "info", "El beneficiario ha sido registrado!", "pe-7s-smile")
  }

  async cancelar(){
    this.setState({selectedOption: {value: this.state.tiposbeneficiario[0].value, label: this.state.tiposbeneficiario[0].label}, beneficiario: "", numero: "", nidcarga: ""})
  }

  async update(){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('numero', this.state.numero);
    fromData.append('descripcion', this.state.beneficiario);
    fromData.append('tipo', this.state.selectedOption.value);
    fromData.append('usuario', this.state.usuario);
    fromData.append('nidbeneficiario', this.state.nidcarga);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'beneficiarios/editarBeneficiario', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async guardar(){
      this.setState({isLoading: true})
      let fromData = new FormData();
      fromData.append('numero', this.state.numero);
      fromData.append('descripcion', this.state.beneficiario);
      fromData.append('tipo', this.state.selectedOption.value);
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'beneficiarios/insertarBeneficiario', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.limpiar();
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
      });
  }

  async editar(datos){
    this.setState({
      selectedOption: {value: datos.nidtipobeneficiario, label: this.obtenerTipo(datos.nidtipobeneficiario)},
      beneficiario: datos.beneficiario,
      numero: datos.numero,
      nidcarga: datos.nidbeneficiario,
    })
  }

  async verConceptos(){
    this.props.history.push('/conceptos');
  }

  async save(){
    if(this.state.beneficiario != "" && this.state.numero != ""){
      if(this.state.nidcarga == ''){
        this.guardar()
      }else{
        this.update()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              {row.nidtipobeneficiario != '12' ? (
              <span data-tip="Editar Beneficiario" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
              {row.nidtipobeneficiario != '12' ? (
              <span data-tip="Remover Beneficiario" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
          </div>
      );
    }else{}
  }

  render() {
    const { selectedOption } = this.state;
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "beneficiario", text: "BENEFICIARIO / CONCEPTO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "numero", text: "N° DE DOCUMENTO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipobeneficiario", text: "TIPO DE BENEFICIARIO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.beneficiarios.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.beneficiarios }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Beneficiarios</h4>
              <div className="row" style={{marginBottom: 10}}>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6" style={{marginBottom: 18}}>
                        <ControlLabel>BENEFICIARIO</ControlLabel>
                        <FormControl type="text" value={this.state.beneficiario} onChange= {this.handleBeneficiario}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>NÚMERO DE DOCUMENTO (DNI O RUC)</ControlLabel>
                        <FormControl type="text" value={this.state.numero} onChange= {this.handleNumero}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>TIPO DE BENEFICIARIO</ControlLabel>
                        <Select value={selectedOption} onChange={this.handleChange} options={this.state.tiposbeneficiario}/>
                    </div>
                  </div>
                  <div>
                      <Button style={{width: 200, marginBottom: 10}} bsStyle="success" pullLeft fill type="submit" onClick={() => { this.verConceptos() }}>Ver Conceptos</Button>
                      <Button style={{width: 200, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.save() }}>Guardar</Button>
                      <Button style={{width: 200, marginRight: 10, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Limpiar</Button>
                  </div>
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ConsultaBeneficiarios;