import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col,Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {sisperplus,gestor} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from 'moment';
import InputFiles from 'react-input-files';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class DetalleVacaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarVacaciones',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      persona: "",
      line:"",
      dni:"",
      nombre:"",
      secuencia:"",
      fechainicio:"",
      fechafin:"",
      dias:"0",
      fileName:"",
      fileRoot:"",
      file:[],
      type: '',
      fechaC:"",
      isLoadingFile: false,
      cont :[],
      contF :"0",
      enlace:"",
      diasA:"",
      detalle:""
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.descargar= this.descargar.bind(this);
    this.icon =this.icon.bind(this);
    this.handleFechaInicial = this.handleFechaInicial.bind(this);
    this.handleFechaFin = this.handleFechaFin.bind(this);
    this.handleDiasT = this.handleDiasT.bind(this);
  }
    handleFechaInicial(event) {this.setState({fechainicio: event.target.value});}
    handleFechaFin(event) {this.setState({fechafin: event.target.value});}
    handleDiasT(event) {this.setState({dias: event.target.value});}
  
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{                
               this.validarLocal()
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){ 
 
    if(JSON.parse(localStorage.getItem("recursoshumanos")).trabajadoresAdm[1].enlace == "V"){
      
          this.setState({
                  persona: JSON.parse(localStorage.getItem('recursoshumanos')).vacaciones[0].data.person,
                  line: JSON.parse(localStorage.getItem('recursoshumanos')).vacaciones[0].data.line,
                  dni: JSON.parse(localStorage.getItem('recursoshumanos')).vacaciones[0].data.dni,
                  nombre: JSON.parse(localStorage.getItem('recursoshumanos')).vacaciones[0].data.nombre,
                  anio:JSON.parse(localStorage.getItem('recursoshumanos')).vacaciones[0].data.anio,
                  enlace: JSON.parse(localStorage.getItem('recursoshumanos')).vacaciones[1].enlace,
          });

       
        this.listDetalleVacaciones();  
        }else if(JSON.parse(localStorage.getItem("recursoshumanos")).trabajadoresAdm[1].enlace == "T"){    
          this.setState({
                  persona: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.person,
                  dni: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.documentNumber,
                  nombre: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.nombreCompleto,
                  line:  JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.line,
                  anio:  JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.lineAnio,
                  enlace: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[1].enlace  
          });
          this.listDetalleVacaciones();
        }else{
            this.setState({
                persona: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.person,
                dni: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.documentNumber,
                nombre: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.nombreCompleto,
                line:  JSON.parse(localStorage.getItem('recursoshumanos')).vacacionesxTrabajador[0].data.line,
                anio:  JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.lineAnio,
                enlace: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[1].enlace,
                fechainicio:  Calendar.calendario11(JSON.parse(localStorage.getItem('recursoshumanos')).vacacionesxTrabajador[0].data.fechainicio),
                fechafin:  Calendar.calendario11(JSON.parse(localStorage.getItem('recursoshumanos')).vacacionesxTrabajador[0].data.fechafin),
                fileName:  JSON.parse(localStorage.getItem('recursoshumanos')).vacacionesxTrabajador[0].data.fileName,
                fileRoot:  JSON.parse(localStorage.getItem('recursoshumanos')).vacacionesxTrabajador[0].data.fileRoot,
                dias:  JSON.parse(localStorage.getItem('recursoshumanos')).vacacionesxTrabajador[0].data.dias, 
                secuencia:  JSON.parse(localStorage.getItem('recursoshumanos')).vacacionesxTrabajador[0].data.secuencia,
                fechaC:  JSON.parse(localStorage.getItem('recursoshumanos')).vacacionesxTrabajador[0].data.fechaC
              });
              this.listDetalleVacaciones(); 
      }
   }

  async listDetalleVacaciones() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'vacaciones/listDetalleVacaciones?persona='+ this.state.persona + '&line=' + this.state.line)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        this.state.cont = lista2
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              item: data.item,
              person: data.Person,
              fechainicio: Calendar.calendarioA(data.Datebegin),
              fechafin : Calendar.calendarioA(data.Dateend),
              dias: data.Days,
              fechaC: Calendar.calendarioB(data.XLastDate),
              secuencia: data.Secuence,
              fileName:data.FileName,
              fileRoot:data.FileRoot
             })
          })
          this.setState({movimientos: lista, isLoading: false})
          this.contadorDias()
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);      
        this.setState({isLoading: false})
      }
    });
  }

  contadorDias(){
    let diasConsu =""
    diasConsu=this.state.cont.map(item => item.Days).reduce((prev, curr) => prev + curr, 0);
    this.setState({contF:diasConsu})
  }

  async obtener(data){ 
    this.setState({ 
        secuencia: data.secuencia,
        fechainicio:Calendar.calendario11(data.fechainicio),
        fechafin: Calendar.calendario11(data.fechafin),
        dias:data.dias,
        diasA:data.dias, 
        fileName:data.fileName==null? "":data.fileName ,
        fileRoot:data.fileRoot==null? "":data.fileRoot,
        fechaC:data.fechaC
      })
  }

  async save(){
    if(this.state.fechainicio != '' && this.state.fechafin != '' && this.state.dias != '0' ){
       if(this.state.secuencia == ''){
          if(this.state.contF+this.state.dias <= 30){
              this.guardar()
          }else{
              this.props.handleClick("tl", "warning", "Los dias ingresados exceden los dias restantes de vacaciones!", "pe-7s-info")
          }
        }else{       
          if(this.state.contF- this.state.diasA + this.state.dias <= 30){
              this.update()
          }else{
              this.props.handleClick("tl", "warning", "Los dias ingresados exceden los dias restantes de vacaciones!", "pe-7s-info")
          }
        }    
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }


  guardar(){
      if(this.state.type == '' || this.state.type == 'application/pdf' || this.state.type == 'image/jpeg' || this.state.type == 'image/png'){
              this.setState({isLoading: true})
              let fromData = new FormData();
              fromData.append('persona', this.state.persona);
              fromData.append('line', this.state.line);
              fromData.append('fechainicio', this.state.fechainicio);
              fromData.append('fechafin', this.state.fechafin);
              fromData.append('dia', this.state.dias);
              fromData.append('usuario', this.state.usuario);
              fromData.append('controlanio', this.state.anio);
              axios.defaults.headers.common['Authorization'] = this.state.token
              axios.post(`${sisperplus}` + 'vacaciones/insertDetalleVacaciones', fromData)
              .then((response) => {
                  var datos = response.data
                  if(datos.response != null){         
                    axios.defaults.headers.common['Authorization'] = this.state.token
                    axios.get(`${sisperplus}` + 'vacaciones/listTopVacaciones?persona=' + this.state.persona)
                    .then((res) => {
                      let lista = res.data.response;
                        if(this.state.file != ''){
                          this.subirArchivo(lista[0].Person,lista[0].line,lista[0].Secuence)
                        }else{
                          this.props.handleClick("tl", "info", "Ha sido registrado con éxito!", "pe-7s-smile")
                          this.limpiar();
                        }
                    })
                    .catch((error) => { 
                      if(auth0Client.isAuthenticated() === true){
                        auth0Client.signOut()
                        this.props.history.replace('/');
                      }else{
                        console.log(error);
                        this.setState({isLoading: false})
                      }
                    });
                  }else{
                      this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
                      this.setState({isLoading: false})
                  }
              })
                .catch((error) =>{ 
                if(auth0Client.isAuthenticated() === true){
                    auth0Client.signOut()
                     this.props.history.replace('/');
                }else{
                    console.log(error);
                    this.setState({isLoading: false})
                }
              });
        }else{
          this.props.handleClick("tl", "warning", "El archivo a subir debe ser un pdf o una imagen!", "pe-7s-info")
        }
    }

  subirArchivo(person,line,secuencia){
      let fromData = new FormData();
      fromData.append('persona', person);
      fromData.append('line', line);
      fromData.append('secuencia', secuencia);
      fromData.append('nombreFile', this.state.fileName);
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${sisperplus}` + 'vacaciones/insertArchivoVacaciones', fromData)
      .then((response) => {
        var datos = response.data.response 
        if(datos.archivo != null){
           this.uploadArchivo(datos.archivo)     
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al subir archivo!", "pe-7s-close")
          this.setState({isLoading: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
    }

  uploadArchivo(archivo){
      let anio = new Date().getFullYear()
      let mes = parseInt(new Date().getMonth()) + 1
      if(mes < 10){mes = '0'+ mes}
      let fromData = new FormData();
      fromData.append('usuario', this.state.usuario);
      fromData.append('archivo_file', this.state.file);
      fromData.append('anio', anio);
      fromData.append('mes', mes);
      fromData.append('cnomArchivo', archivo);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${gestor}` + 'document/subirArchivoVac', fromData)
      .then((response) => {
        if(response.data.result == null){
          this.props.handleClick("tl", "info", "Ha sido registrado con éxito!", "pe-7s-smile")
          this.limpiar();               
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al guardar archivo!", "pe-7s-close")
          this.setState({isLoading2: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

    update(){
      this.setState({isLoading: true})
        let fromData = new FormData();
        fromData.append('persona', this.state.persona);
        fromData.append('line', this.state.line);
        fromData.append('secuencia', this.state.secuencia);
        fromData.append('fechainicio', this.state.fechainicio);
        fromData.append('fechafin', this.state.fechafin);
        fromData.append('dia', this.state.dias);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'vacaciones/updateDetalleVacaciones', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){         
            axios.defaults.headers.common['Authorization'] = this.state.token
            axios.get(`${sisperplus}` + 'vacaciones/listTopVacaciones?persona=' + this.state.persona)
            .then((res) => {
              let lista = res.data.response;
                if(this.state.file != ''){
                  this.subirArchivo(lista[0].Person,lista[0].line,lista[0].Secuence)
                }else{
                  this.props.handleClick("tl", "info", "Ha sido actualizado con éxito!", "pe-7s-smile")
                  this.limpiar()
                }
            })
            .catch((error) => { 
              if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
              }else{
                console.log(error);
                this.setState({isLoading: false})
              }
            });
          }else{
              this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
              this.setState({isLoading: false})
          }
        })
          .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
            }else{
                console.log(error);
                this.setState({isLoading: false})
            }
          });
      }
      
  async limpiar(){
    this.listDetalleVacaciones();
    this.cancelar();
      if(this.state.enlace =="T"|| this.state.enlace=="VT"){
        this.regresar()
      } 
  }

  async cancelar(){
    this.setState({ fechainicio: "", fechafin: "",dias:"0",fileName: "", file: "",secuencia:"",fileRoot: ""})
  }

//Eliminar registro
  confirmarDelete = (data) => {
      confirmAlert({
        title: '¿Seguro que desea eliminar el registro?',
        message: 'Se eliminará el registro seleccionado.',
        buttons: [
          {
            label: 'Aceptar',
            onClick: () => this.delete(data)
          },
          {
            label: 'Cancelar',
            onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
          }
        ]
      });
  };

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('persona', this.state.persona);
    fromData.append('line', this.state.line);
    fromData.append('secuencia', data.secuencia);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'vacaciones/deleteDetalleVacaciones', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
         if(data.fileName==null){
              this.listDetalleVacaciones()
              this.props.handleClick("tl", "info", "El registro ha sido eliminado!", "pe-7s-smile")
         }else{
              this.removerArchivo(data,1)
         }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
  
  removerArchivo(archivo,param){   
      let anio = param ==1 ? (archivo.fechaC).substring(6,10) : (this.state.fechaC).substring(6,10)
      let mes = param ==1 ? (archivo.fechaC).substring(3,5) : (this.state.fechaC).substring(3,5)
      let fromData = new FormData();
      fromData.append('usuario', this.state.usuario);
      fromData.append('anio', anio);
      fromData.append('mes', mes);
      fromData.append('cnomArchivo', param ==1 ? archivo.fileRoot : this.state.fileRoot);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${gestor}` + 'document/bajarArchivoVac', fromData)
      .then((response) => {
      if(response.data.result == null){
        if(param==1){
          this.props.handleClick("tl", "info", "El registro ha sido eliminado con éxito!", "pe-7s-smile")
          this.listDetalleVacaciones()
        }else{
          this.props.handleClick("tl", "info", "El archivo ha sido eliminado con éxito!", "pe-7s-smile")
          this.setState({fileName: "", file: "",fileRoot: "",isLoadingFile:false})
        }      
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
      });
  }

  //Eliminar solo el archivo 
  confirmarDeleteFile = () => {
      confirmAlert({
        title: '¿Seguro que desea eliminar el archivo?',
        message: 'Se eliminará el archivo por completo.',
        buttons: [
          {
            label: 'Aceptar',
            onClick: () => this.deleteFile()
          },
          {
            label: 'Cancelar',
            onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
          }
        ]
      });
  };

  async deleteFile(){
    this.setState({isLoadingFile: true})
    let fromData = new FormData();
    fromData.append('persona', this.state.persona);
    fromData.append('line', this.state.line);
    fromData.append('secuencia', this.state.secuencia);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'vacaciones/deleteArchivoDetalleVacaciones', fromData)
    .then((response) => {
      var datos = response.data
      console.log(datos.response)
      if(datos.response != null){     
          this.removerArchivo("",2)
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async regresar(){

    if(this.state.enlace =="V"){
    this.props.history.push("/administrarVacaciones")
    }else if (this.state.enlace == "T"){
      this.props.history.push("/verVacacionesTrabajador")
    }else{
      this.props.history.push("/vacacionesTrabajador")
    }
  }
 
  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Anular Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-black"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  verificarI(param){
    if(this.state.fechafin<param){
        var fecha1 = moment(param);
        var fecha2 = moment(param);
        var dia = (fecha2.diff(fecha1, 'days'))+1;
        this.setState({dias: dia, fechainicio:param,fechafin:param})
    }else{
        var fecha1 = moment(param);
        var fecha2 = moment(this.state.fechafin);
        var dia = (fecha2.diff(fecha1, 'days'))+1;
        this.setState({dias: dia, fechainicio:param})
    }
  }

  verificarF(param){
    var fecha1 = moment(this.state.fechainicio);
    var fecha2 = moment(param);
    var dia = (fecha2.diff(fecha1, 'days'))+1;
    this.setState({dias: dia, fechafin:param})
  }

  async limpiarArchivo(){
    if(this.state.secuencia != "" && this.state.fileRoot != ""){
       this.confirmarDeleteFile()
    }else{
      this.setState({fileName: "", file: ""})
    }
  }

  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], fileName: files[0].name, type: files[0].type, visFile: true});
      }
  }

  download(data){
     let datosvisor = [{filename: data.fileName,
                          filesource: data.fileRoot,
                          mes: (data.fechaC).substring(3,5),
                          anio: (data.fechaC).substring(6,10),
                          }]
        window.open("https://gestordocumentaltc.sedetc.gob.pe/index.php/s/N7aJpcXjDyBAXNb/download?path=%2F&files="  + datosvisor[0].anio + "/" + datosvisor[0].mes + "/" + datosvisor[0].filesource)
    }


    icon(cell,row){
      if(row != undefined){
        return (

          <span data-tip="Ver documento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver(row) }}>
            <ReactTooltip />
            <i className="fa fa-circle fa-stack-2x text-danger"></i>
            <i className="fa fa-file-pdf-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
          </span>
       ) 
      }}

      ver(data){
          let datosvisor = [{filename: data.fileName,
          filesource: data.fileRoot,
          mes: (data.fechaC).substring(3,5),
          anio: (data.fechaC).substring(6,10),
          estado :"3",
          tipo :"/vacaciones/"}]
          // localStorage.setItem('visor', JSON.stringify(datosvisor))
          // this.props.history.push('/visordetalle');
          window.open('http://181.177.234.7/papeletas/vacaciones/' + datosvisor[0].anio + "/" + datosvisor[0].mes + "/" + datosvisor[0].filesource)
        }

    descargar(cell,row){
      if(row != undefined){
        return (
          <span data-tip="Descargar documento"  className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20, marginRight: 5}} onClick ={()=>{this.download(row)}} >
              <i className="fa fa-download fa-stack-1x fa-inverse" style={{fontSize: 25, color:"#1B4F72"}}></i>
          </span>
        )
    }}


  render() {
    const columns = [
    {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "fechainicio", text: "FECHA INICIO", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "fechafin", text: "FECHA FIN", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "dias", text: "DÍAS", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "fileName", text: "ARCHIVO ADJUNTO",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter:this.icon},
    {dataField: "otro", text: "DESCARGAR",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter:this.descargar},
    {dataField: "fechaC", text: "FECHA DE CREACIÓN", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]

    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
      <Grid fluid>
      <Row>
          <Col md={12}>
            <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>VACACIONES DEL AÑO : {this.state.anio}</h4>               
                <div className="row" style={{marginBottom: 10}}>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel>DNI</ControlLabel>
                        <FormControl type="text" value={this.state.dni} disabled/>
                    </div>
                    <div className="col-md-4" style={{marginBottom: 18}}>
                        <ControlLabel>NOMBRES Y APELLIDOS</ControlLabel>
                        <FormControl type="text" value={this.state.nombre} disabled/>
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE INICIO <a style={{color:"red"}}> *</a></ControlLabel>
                        <FormControl type="date" value={this.state.fechainicio} onChange= {(e) => this.verificarI(e.target.value)}  />
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE TÉRMINO <a style={{color:"red"}}> *</a></ControlLabel>
                        <FormControl type="date" value={this.state.fechafin} onChange= {(e) => this.verificarF(e.target.value)}  min={this.state.fechainicio}/>
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel>DÍAS </ControlLabel>
                        <FormControl type="text" value={this.state.dias} onChange={this.handleDiasT} disabled/>
                    </div> 
                </div>  
                {this.state.isLoadingFile==false ? (
                <div className="row"> 
                    <div className="col-md-12" style={{marginBottom: 5}}>
                      <ControlLabel>ADJUNTAR ARCHIVOS <a>(OPCIONAL)</a></ControlLabel>
                      <Alert bsStyle="warning" style={{borderRadius:15}}>
                          <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.fileName}</span>
                      </Alert><ControlLabel></ControlLabel>
                      <Button style={{width: '100%', marginTop: -5, backgroundColor :"#E79E2E", borderColor:"#E79E2E",borderRadius:15}}  pullRight fill type="submit">
                          <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                      </Button>
                    </div>   
                    {this.state.fileName != "" && this.state.fileName != null ? (

                    
                       <Button style={{width: 200, marginBottom: 10, marginLeft:15,marginTop: 18}} bsStyle="warning" fill   type="submit" onClick={() => { this.limpiarArchivo() }}>Limpiar</Button>
                    
                    ) : (  null )}   
                </div>  
                ):<ClipLoader css={override} sizeUnit={"px"} size={50} color={'#123abc'} loading={this.state.loading}/>}                  
                <div className="row">
                  <Button style={{width: 200, marginBottom: 10, marginRight:15,marginTop: 18}} bsStyle="danger" pullRight  type="submit" onClick={() => { this.cancelar() }}>CANCELAR</Button>
                  <Button style={{width: 200, marginBottom: 10, marginRight:15,marginTop: 18}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.save() }}>GUARDAR</Button>
                </div>
                <hr style={{ background: "#337AB7",  height: "2px", border: "none", }}  />  
                <div style={{marginTop: 20}}>
                    {this.state.contF == 30 ?
                      <div>
                        <p style={{fontWeight: 'bold'}}>Días Consumidos : {this.state.contF} </p>
                        <p style={{fontStyle: 'italic'}}>Nota : Usted ha  empleado todos los dias de vacaciones de este año, solo puede actualizar los registros existentes.</p>
                      </div>
                    :
                    <div>
                        <p style={{fontStyle: 'italic'}}>Días Consumidos : {this.state.contF} </p>
                        <p style={{fontStyle: 'italic'}}>Días Restantes : {30-this.state.contF}</p>                      
                    </div>
                    }
                </div>  
             {this.state.isLoading ? (
                        <ClipLoader
                          css={override}
                          sizeUnit={"px"}
                          size={50}
                          color={'#123abc'}
                          loading={this.state.loading}
                        />
                      ) : (
                        <PaginationProvider
                          pagination={
                            paginationFactory(options)
                          }
                        >
                          { contentTable }
                        </PaginationProvider>
                  )}
            </div>
            <Button style={{width: 250, marginTop: 10,marginLeft:15}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
          </Col>
        </Row>
      </Grid>
    </div>
    );
  }
}

export default  DetalleVacaciones;