import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { jurisdiccional} from 'store/Apis'
import 'react-tabs/style/react-tabs.css';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment'
import Select from 'react-select';

import 'react-accessible-accordion/dist/fancy-example.css';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class EditarCarruselPortal extends Component {

  constructor(props) {
    super(props);
    this.state = {
        path: 'utilitariosPortal',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      imagen : "", 
      nid: "",
      isLoading: false,
      show: true,
      identificador:'',
      identificadores: [
        {value: "1", label: "VERSIÓN NORMAL"},
        {value: "2", label: "VERSIÓN QUECHUA"},
        {value: "3", label: "AMBAS VERSIONES"}
     ]
    }
    this.handleImagen = this.handleImagen.bind(this);
  }

    handleImagen(event) {this.setState({imagen: event.target.value});}
    handleIdentificador = identificador => { this.setState( { identificador }, );  };
    

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal()
              }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
  async validarLocal(){
    if(JSON.parse(localStorage.getItem("jurisdiccional")).carruselPortal[0].data !== null){
        this.setState({
                imagen: JSON.parse(localStorage.getItem('jurisdiccional')).carruselPortal[0].data.ctxt_imagen,
                nid: JSON.parse(localStorage.getItem('jurisdiccional')).carruselPortal[0].data.nid,
                identificador: {value: JSON.parse(localStorage.getItem('jurisdiccional')).carruselPortal[0].data.combo.value, label: JSON.parse(localStorage.getItem('jurisdiccional')).carruselPortal[0].data.combo.label},
                isLoading: false
        });
    }else{
      this.props.history.push('/consultarCarouselPortal');
    }
  }



  async actualizar(){ 
    if(this.state.imagen != ''){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('imagen', this.state.imagen);
    fromData.append('identificador', this.state.identificador.value);
    fromData.append('usuario', this.state.usuario);
    fromData.append('nidcarousel', this.state.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${jurisdiccional}` + 'carousel/editarCarouselPortal', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Carousel actualizado correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
   }
   else{
    this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close") 
  }

  }
  async regresar(){
    this.props.history.push('/consultarCarouselPortal');
  }

  abrir(ruta){
    window.open(ruta, '_blank');
  } 

  mostrar = () => {
    confirmAlert({
      title: <i class="fa fa-user" style={{ color :"black", marginLeft: 130,fontSize: 100}}></i>,
      message:<div><p>USUARIO : Usuario_01</p><p>CONTRASEÑA : Tribunal2022$</p> </div> ,
      buttons: [
        {
          label: 'OK',
        },
        
      ],
      closeOnClickOutside: false,
  });}

  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Editar Imagen del Carousel</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                            <div className="row">
                                <div className="col-md-8" style={{marginBottom: 18}}>
                                    <ControlLabel><i class="fa fa-picture-o" ></i> Link de la Imagen</ControlLabel>
                                    <FormControl type="text" value={this.state.imagen} onChange= {this.handleImagen}/>
                                </div>
                           
                                <div className="col-md-4" style={{marginBottom: 18}}>
                                    <ControlLabel><i class="fa fa-window-restore"></i> Tipo</ControlLabel>
                                    <Select value={this.state.identificador} onChange={this.handleIdentificador} options={this.state.identificadores}/>
                                </div>
                                <div className="col-md-12" style={{marginBottom: 18}}>
                                  <Button style={{width: 350,marginRight:5}} bsStyle="primary" pullLeft type="submit" onClick={() => { this.abrir("https://www.tc.gob.pe/wp-login.php") }}>Haga click aquí para subir la imagen</Button>
                                  <Button style={{width: 150,}} bsStyle="primary" pullLeft  type="submit" onClick={() => { this.mostrar() }}>Ver Credenciales</Button>
                                </div>

                            </div>
                            
                            <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Actualización</Button>
                            <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.actualizar() }}>Actualizar Registro</Button>
                       </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarCarruselPortal;