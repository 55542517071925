import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import Select from 'react-select';
import Autocomplete from "react-autocomplete-select";

import axios from 'axios';
import { paramTesoreria, tesoreria } from 'store/Apis'
import Calendar from "components/Calendar/Calendar.jsx";

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class EditarCajaChica extends Component {

  inputRef = React.createRef();
  inputRef2 = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      path: 'cajachica',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      selectedOption: "",
      selectedOption2: "",
      selectedOption3: "",
      selectedOption4: "",
      selectedOption5: "",
      selectedOption6: "",
      selectedOption7: "",
      beneficiarios: [],
      conceptos: [],
      oficinas: [],
      responsables: [],
      metas: [],
      clasificadores: [],
      cajas: [],
      estadistica: [],
      patrimonial: [],
      nidcarga: "",
      movimiento: '',
      numero: '',
      fecha: '',
      usuariox: '',
      monto: '',
      cuentadebe: '',
      cuentahaber: '',
      importedebe: '',
      importehaber: '',
      cuentadebe2: '',
      cuentahaber2: '',
      importedebe2: '',
      importehaber2: '',
      totalgastos: '',
      liquido: '',
      numerocaja: '',
      nombre: '',
      documento: '',
      concepto: '',
      nididentificador: '',
      nidpresupuestal: '',
      apunta: true,
      apunta2: true,
      apunta3: true,
      isLoading1: true,
      isLoading2: true,
      isLoading3: true,
      isLoading4: true,
      isLoading5: true,
      nidanterior: 0,
      nidsuperanterior: 0,
      nidsiguiente: 0,
      nidsupersiguiente: 0
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);

    this.handleFecha = this.handleFecha.bind(this);
    this.handleUsuario = this.handleUsuario.bind(this);
    this.handleMonto = this.handleMonto.bind(this);
    this.handleCuentadebe = this.handleCuentadebe.bind(this);
    this.handleCuentahaber = this.handleCuentahaber.bind(this);
    this.handleImportedebe = this.handleImportedebe.bind(this);
    this.handleImportehaber = this.handleImportehaber.bind(this);
    this.handleCuentadebe2 = this.handleCuentadebe2.bind(this);
    this.handleCuentahaber2 = this.handleCuentahaber2.bind(this);
    this.handleImportedebe2 = this.handleImportedebe2.bind(this);
    this.handleImportehaber2 = this.handleImportehaber2.bind(this);
    this.handleTotalgastos = this.handleTotalgastos.bind(this);
    this.handleNumerocaja = this.handleNumerocaja.bind(this);
    this.handleNombre = this.handleNombre.bind(this);
    this.handleDocumento = this.handleDocumento.bind(this);
    this.handleConcepto = this.handleConcepto.bind(this);
    this.handleNumero = this.handleNumero.bind(this);
  }

  handleChange = selectedOption => {
    this.setState(
    { selectedOption },
    );
  };

  handleChange2 = selectedOption2 => {
    this.setState(
    { selectedOption2 },
    );
  };

  handleChange3 = selectedOption3 => {
    this.setState(
    { selectedOption3 },
    );
  };

  handleChange4 = selectedOption4 => {
    this.setState(
    { selectedOption4 },
    );
  };

  handleChange5 = selectedOption5 => {
    this.setState(
    { selectedOption5 },
    ); 
  };

  handleChange6 = selectedOption6 => {
    this.setState(
    { selectedOption6 },
    );
  };

  handleChange7 = selectedOption7 => {
    this.setState(
    { selectedOption7 },
    );
  };

  handleFecha(event) {
    this.setState({fecha: event.target.value});
  }

  handleUsuario(event) {
    this.setState({usuariox: event.target.value});
  }

  handleMonto(event) {
    this.setState({monto: event.target.value});
  }

  handleCuentadebe(event) {
    this.setState({cuentadebe: event.target.value});
  }

  handleCuentahaber(event) {
    this.setState({cuentahaber: event.target.value});
  }

  handleImportedebe(event) {
    this.setState({importedebe: event.target.value});
  }

  handleImportehaber(event) {
    this.setState({importehaber: event.target.value});
  }

  handleCuentadebe2(event) {
    this.setState({cuentadebe2: event.target.value});
  }

  handleCuentahaber2(event) {
    this.setState({cuentahaber2: event.target.value});
  }

  handleImportedebe2(event) {
    this.setState({importedebe2: event.target.value});
  }

  handleImportehaber2(event) {
    this.setState({importehaber2: event.target.value});
  }

  handleTotalgastos(event) {
    this.setState({totalgastos: event.target.value});
  }

  handleNumerocaja(event) {
    this.setState({numerocaja: event.target.value});
  }

  handleNombre(event) {
    this.setState({nombre: event.target.value});
  }

  handleDocumento(event) {
    this.setState({documento: event.target.value});
  }

  handleConcepto(event) {
    this.setState({concepto: event.target.value});
  }

  handleNumero(event) {
    this.setState({numero: event.target.value});
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(localStorage.getItem("movimientocaja") !== null){
        this.setState({movimiento: JSON.parse(localStorage.getItem('movimientocaja')).nidmovimiento});
        this.obtenerDatos();
    }else{
      this.ultimoMov()
      // this.props.history.push('/cajachica');
    }
  }

  async ultimoMov() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listUltimoMovimiento?tipo=1')
    .then((res) => {
      let lista = res.data.response;
      this.setState({movimiento: lista[0].nid});
      this.obtenerDatos();
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async obtenerDatos(){
    if(paramTesoreria.areas[1].data2.length != 0){
      this.setState({
        oficinas: paramTesoreria.areas[1].data2, 
        responsables: paramTesoreria.trabajadores[1].data2, 
        metas: paramTesoreria.metas[0].data, 
        clasificadores: paramTesoreria.clasificadores[0].data,
        selectedOption4: {
          value: paramTesoreria.clasificadores[0].data[0].value,
          label: paramTesoreria.clasificadores[0].data[0].label
        },
        cajas: paramTesoreria.tiposcaja[0].data, 
        selectedOption5: {
          value: paramTesoreria.tiposcaja[0].data[0].value, 
          label: paramTesoreria.tiposcaja[0].data[0].label
        },
        beneficiarios: paramTesoreria.beneficiarios[0].data, 
        conceptos: paramTesoreria.conceptos[0].data, 
      })
      this.listarMovimiento(0, "")
    }else{
      this.listarAreas()
    }
  }

  async listarAreas() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listAreas')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid_area,
              label: data.ctxt_descripcion
          })
        })
        this.setState({oficinas: lista})
        this.listarTrabajadores()
      }else{
        this.setState({oficinas: []})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarTrabajadores() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listTrabajadores')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_descripcion          
            })
        })
        this.setState({responsables: lista})
        this.listarMetas()
      }else{
        this.setState({responsables: []})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarMetas() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listCodigos?tipo=meta')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.codigo,       
            })
        })
        this.setState({metas: lista})
        this.listarClasificadores()
      }else{
        this.setState({metas: []})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarClasificadores() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listClasificadores?admin=0')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_descripcion,       
            })
        })
        this.setState({clasificadores: lista, selectedOption4: {value: lista[0].value, label: lista[0].label}})
        this.listarTiposCaja()
      }else{
        this.setState({clasificadores: []})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarTiposCaja() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listCodigos?tipo=tipo_caja')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.nombre,       
            })
        })
        this.setState({cajas: lista, selectedOption5: {value: lista[0].value, label: lista[0].label}})
        this.listarBeneficiarios()
      }else{
        this.setState({cajas: []})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarBeneficiarios() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'beneficiarios/listBeneficiarios')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.cnum_documento + ' (' + data.ctxt_descripcion + ')', 
              tipo: data.nid_tipo,
              documento: data.cnum_documento,  
              clase: data.ctxt_descripcion    
            })
        })
        this.setState({beneficiarios: lista})
        this.listarConceptos()
      }else{
        this.setState({beneficiarios: []})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarConceptos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'beneficiarios/listConceptos')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2.length != 0){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_descripcion,       
            })
        })
        this.setState({conceptos: lista})
        this.listarMovimiento(0, "")
      }else{
        this.setState({conceptos: []})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarMovimiento(param1, param2) {
      let mov = ""
      if(param1 == 0){
        mov = this.state.movimiento;
      }else{
        mov = param2;
      }
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${tesoreria}` + 'comprobantes/listMovimientoxNid?nidmovimiento=' + mov + '&tipo=1')
      .then((res) => {
        let lista = res.data.response;
        let listestadistica = [];
        let listpatrimonial = [];
        if(lista != null){
              this.setState({
                nidsupersiguiente: lista.listMovimientoxNid[0].supersiguiente,
                nidsiguiente: lista.listMovimientoxNid[0].nidsiguiente,
                nidanterior: lista.listMovimientoxNid[0].nidanterior, 
                nidsuperanterior: lista.listMovimientoxNid[0].superanterior, 
                nidmovimiento: lista.listMovimientoxNid[0].nid,
                numero: lista.listMovimientoxNid[0].cnum_numero, 
                // tipocaja: lista.listMovimientoxNid[0].tipocaja,
                numerocaja: lista.listMovimientoxNid[0].cnum_numero_caja,
                // documento: lista.listMovimientoxNid[0].cnum_documento,
                // nombre: lista.listMovimientoxNid[0].ctxt_nombre,
                // concepto: lista.listMovimientoxNid[0].ctxt_concepto,
                liquido: lista.listMovimientoxNid[0].cnum_liquido != null ? 'S/.    ' + lista.listMovimientoxNid[0].cnum_liquido.toFixed(2) : 'S/. 0.00', 
                totalgastos: lista.listMovimientoxNid[0].cnum_total_gastos != null ? 'S/.    ' + lista.listMovimientoxNid[0].cnum_total_gastos.toFixed(2) : 'S/. 0.00', 
                fecha: lista.listMovimientoxNid[0].dfec_ingreso,
                nidarea: lista.listMovimientoxNid[0].nid_area,
                area: lista.listMovimientoxNid[0].area,
                nidresponsable: lista.listMovimientoxNid[0].nid_responsable,
                responsable: lista.listMovimientoxNid[0].responsable,
                usuariox: lista.listMovimientoxNid[0].usuario_registro,
                selectedOption: {value: lista.listMovimientoxNid[0].nid_area, label: lista.listMovimientoxNid[0].area},
                selectedOption2: {value: lista.listMovimientoxNid[0].nid_responsable, label: lista.listMovimientoxNid[0].responsable},
                selectedOption3: {value: lista.listMovimientoxNid[0].nid_meta, label: lista.listMovimientoxNid[0].meta},
                selectedOption5: {value: lista.listMovimientoxNid[0].nid_tipo_caja, label: lista.listMovimientoxNid[0].tipocaja},
                nidpresupuestal: lista.listPresupuestal[0].nid,
                cuentadebe2: lista.listPresupuestal[0].ctxt_cuenta_debe != '0' ? lista.listPresupuestal[0].ctxt_cuenta_debe : '8401.01', 
                cuentahaber2: lista.listPresupuestal[0].ctxt_cuenta_haber != '0' ? lista.listPresupuestal[0].ctxt_cuenta_haber : '8601.01', 
                importedebe2: lista.listPresupuestal[0].ctxt_importe_debe == 0 ? "" : 'S/.    ' + lista.listPresupuestal[0].ctxt_importe_debe.toFixed(2),
                importehaber2: lista.listPresupuestal[0].ctxt_importe_debe == 0 ? "" : 'S/.    ' + lista.listPresupuestal[0].ctxt_importe_haber.toFixed(2),
                nididentificador: lista.listIdentificadores[0].nid,
                nombre: lista.listIdentificadores[0].ctxt_nombre,
                concepto: lista.listIdentificadores[0].ctxt_concepto,
                documento: lista.listIdentificadores[0].cnum_documento,
                // selectedOption6: {
                //   value: lista.listIdentificadores[0].nid_beneficiario, 
                //   label: lista.listIdentificadores[0].cnum_documento + ' (' + lista.listIdentificadores[0].ctxt_nombre + ')', 
                //   tipo: lista.listIdentificadores[0].nid_tipo_beneficiario, 
                //   documento: lista.listIdentificadores[0].cnum_documento,
                //   clase: lista.listIdentificadores[0].ctxt_nombre,
                // },
                // selectedOption7: {
                //   value: lista.listIdentificadores[0].nid_concepto, 
                //   label: lista.listIdentificadores[0].ctxt_concepto
                // }
              })
              if(lista.listEstadistica != null){
                lista.listEstadistica.map((data) => {
                    listestadistica.push(
                      { nidestadistica: data.nid,
                        nidclasificador: data.nid_clasificador,
                        partida: data.ctxt_partida,
                        parcial: 'S/.    ' + data.ctxt_parcial.toFixed(2),
                      })
                })
                this.setState({estadistica: listestadistica})
              }else{
                this.setState({estadistica: []})
              }
              if(lista.listPatrimonial != null){
                lista.listPatrimonial.map((data) => {
                    listpatrimonial.push(
                      { nidpatrimonial: data.nid,
                        cuentadebe: data.ctxt_cuenta_debe,
                        importedebe: 'S/.    ' + data.ctxt_importe_debe.toFixed(2),
                        cuentahaber: data.ctxt_cuenta_haber,
                        importehaber: 'S/.    ' + data.ctxt_importe_haber.toFixed(2),
                      })
                })
                this.setState({
                  patrimonial: listpatrimonial, 
                  isLoading5: false, 
                  isLoading1: false, 
                  isLoading2: false, 
                  isLoading3: false, 
                  isLoading4: false, 
                  apunta3: true, 
                  apunta2: true,
                  apunta: true
                })
              }else{
                this.setState({
                  patrimonial: [], 
                  isLoading5: false, 
                  isLoading1: false, 
                  isLoading2: false, 
                  isLoading3: false, 
                  isLoading4: false, 
                  apunta3: true, 
                  apunta2: true,
                  apunta: true
                })
              }
        }else{
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
        }
      });
  }

  async limpiar(){
    this.listarMovimiento(0, "");
    this.setState({monto: '', cuentadebe: '', importedebe: '', cuentahaber: '', importehaber: ''})
    this.props.handleClick("tl", "info", "Los datos han sido actualizados!", "pe-7s-smile")
  }

  async guardar6(){
    this.setState({isLoading5: true})
    let fromData = new FormData();
    fromData.append('concepto',  0);
    fromData.append('cuentadebe',  this.state.cuentadebe);
    fromData.append('importedebe', this.state.importedebe);
    fromData.append('cuentahaber', this.state.cuentahaber);
    fromData.append('importehaber',  this.state.importehaber);
    fromData.append('comprobante', this.state.nidmovimiento);
    fromData.append('tipo', 1);
    fromData.append('usuario', this.state.usuario);
    fromData.append('nidcontabilidad', this.state.nidcarga);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'detallecomprobante/editarContabilidad', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.limpiar();
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
        this.setState({isLoading5: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading5: false})
      }
    });
  }

  async guardar5(){
    this.setState({isLoading5: true})
    let fromData = new FormData();
    fromData.append('concepto',  0);
    fromData.append('cuentadebe',  this.state.cuentadebe);
    fromData.append('importedebe', this.state.importedebe);
    fromData.append('cuentahaber', this.state.cuentahaber);
    fromData.append('importehaber',  this.state.importehaber);
    fromData.append('comprobante', this.state.nidmovimiento);
    fromData.append('tipox', 15);
    fromData.append('tipo', 1);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'detallecomprobante/insertarContabilidad', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.limpiar();
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
        this.setState({isLoading5: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading5: false})
      }
    });
  }

  async guardar4(){
    if(this.state.cuentadebe2 != '' && this.state.importedebe2 != '' && this.state.cuentahaber2 != '' && this.state.importehaber2 != ''){
      this.setState({isLoading4: true})
      let fromData = new FormData();
      fromData.append('concepto',  0);
      fromData.append('cuentadebe',  this.state.cuentadebe2);
      fromData.append('importedebe', this.state.importedebe2);
      fromData.append('cuentahaber', this.state.cuentahaber2);
      fromData.append('importehaber',  this.state.importehaber2);
      fromData.append('comprobante', this.state.nidmovimiento);
      fromData.append('tipo', 1);
      fromData.append('usuario', this.state.usuario);
      fromData.append('nidcontabilidad', this.state.nidpresupuestal);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'detallecomprobante/editarContabilidad', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          this.limpiar();
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading4: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading4: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async guardar3(){
    if(this.state.monto != ''){
      this.setState({isLoading3: true})
      let fromData = new FormData();
      fromData.append('cantidad',  this.state.monto);
      fromData.append('clasificador', this.state.selectedOption4.value);
      fromData.append('comprobante', this.state.nidmovimiento);
      fromData.append('tipo', 1);
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'detallecomprobante/insertarEstadistica', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          this.actualizarCajaChica(0);
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading3: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading3: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async guardar2(){
    if(this.state.fecha != '' && this.state.numerocaja != '' && this.state.numero != ''){
      this.setState({isLoading2: true})
      let fromData = new FormData();
      fromData.append('numero',  this.state.numero);
      fromData.append('fecha',  this.state.fecha);
      fromData.append('tipocaja', this.state.selectedOption5.value);
      fromData.append('numerocaja', this.state.numerocaja);
      fromData.append('oficina', this.state.selectedOption.value);
      fromData.append('responsable',  this.state.selectedOption2.value);
      fromData.append('meta', this.state.selectedOption3.value);
      fromData.append('usuario', this.state.usuario);
      fromData.append('nidcomprobante', this.state.nidmovimiento);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'comprobantes/editarCajaChica', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          this.actualizarCajaChica(0);
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading2: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading2: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async guardar(){
      let temp = 0;
      let fromData = new FormData();
      if(this.state.selectedOption6.value != undefined){
        if(this.state.selectedOption7.value != undefined){
          fromData.append('beneficiario',  this.state.selectedOption6.value);
          fromData.append('tipo', this.state.selectedOption6.tipo);
          fromData.append('documento', this.state.selectedOption6.documento);
          fromData.append('nombre', this.state.selectedOption6.clase);
          fromData.append('nidconcepto',  this.state.selectedOption7.value);
          fromData.append('concepto', this.state.selectedOption7.label);
          fromData.append('usuario', this.state.usuario);
          fromData.append('nididentificador', this.state.nididentificador);
          temp = 1;
        }else{
          fromData.append('beneficiario',  this.state.selectedOption6.value);
          fromData.append('tipo', this.state.selectedOption6.tipo);
          fromData.append('documento', this.state.selectedOption6.documento);
          fromData.append('nombre', this.state.selectedOption6.clase);
          fromData.append('nidconcepto',  0);
          fromData.append('concepto', '--');
          fromData.append('usuario', this.state.usuario);
          fromData.append('nididentificador', this.state.nididentificador);
          temp = 1;
        }
      }else{
        if(this.state.selectedOption7.value != undefined){
          fromData.append('beneficiario',  0);
          fromData.append('tipo', 0);
          fromData.append('documento', '--');
          fromData.append('nombre', '--');
          fromData.append('nidconcepto',  this.state.selectedOption7.value);
          fromData.append('concepto', this.state.selectedOption7.label);
          fromData.append('usuario', this.state.usuario);
          fromData.append('nididentificador', this.state.nididentificador);
          temp = 1;
        }else{
          this.props.handleClick("tl", "warning", "Debe seleccionar algún campo!", "pe-7s-info")
        }
      }
      if(temp == 1){
        this.setState({isLoading1: true})
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${tesoreria}` + 'detallecomprobante/editarIdentificador', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            this.actualizarCajaChica(0);
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading1: false})
          }
          })
          .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
          }else{
              console.log(error);
              this.setState({isLoading1: false})
          }
        });
      }
  }

  async actualizarCajaChica(param){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'comprobantes/listMovimientos?tipo=1&fechaini=' + paramTesoreria.cajachica[0].fechaini + '&fechafin=' + paramTesoreria.cajachica[1].fechafin)
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { 
              item: data.item,
              nidmovimiento: data.nid,
              numero: data.cnum_numero, 
              tipocaja: data.tipocaja,
              numerocaja: data.cnum_numero_caja,
              documento: data.cnum_documento,
              nombre: data.ctxt_nombre,
              concepto: data.ctxt_concepto,
              liquido: data.cnum_liquido,
              totalgastos: data.cnum_total_gastos != null ? 'S/.    ' + data.cnum_total_gastos.toFixed(2) : 'S/. 0.00', 
              fecha: Calendar.calendario12(data.dfec_ingreso),
              meta: data.meta,
              area: data.area,
              responsable: data.responsable,
              activo: data.item == 1 ? '1' : '0'
          })
        })
        paramTesoreria.cajachica[2].data = lista
      }else{
        paramTesoreria.cajachica[2].data = []
      }
      localStorage.setItem('data', JSON.stringify(paramTesoreria))
      if(param == 0){
        this.limpiar();
      }else{
        this.listarMovimiento(0, "");
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async delete(data){
    this.setState({isLoading3: true})
    let fromData = new FormData();
    fromData.append('nidcomprobante', this.state.movimiento);
    fromData.append('tipo', 1);
    fromData.append('nidestadistica', data.nidestadistica);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'detallecomprobante/anularEstadistica', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.actualizarCajaChica(1);
        this.props.handleClick("tl", "info", "La partida ha sido eliminada!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading3: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading3: false})
      }
    });
  }

  async delete2(data){
    this.setState({isLoading5: true})
    let fromData = new FormData();
    fromData.append('nidcomprobante', this.state.movimiento);
    fromData.append('tipo', 1);
    fromData.append('nidcontabilidad', data.nidpatrimonial);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'detallecomprobante/anularContabilidad', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarMovimiento(0, "");
        this.props.handleClick("tl", "info", "El importe ha sido eliminado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading5: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading5: false})
      }
    });
  }

  confirmarDelete = (datos) => {
    confirmAlert({
      title: '¿Seguro de eliminar?',
      message: 'Se eliminará la partida seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(datos)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  confirmarDelete2 = (datos) => {
    confirmAlert({
      title: '¿Seguro de eliminar?',
      message: 'Se eliminará el importe seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete2(datos)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async save(){
    if(this.state.cuentadebe != "" && this.state.cuentahaber != "" && this.state.importedebe != "" && this.state.importehaber != ""){
      if(this.state.nidcarga == ''){
        this.guardar5()
      }else{
        this.guardar6()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async editar(datos){
    this.setState({
      cuentadebe: datos.cuentadebe,
      cuentahaber: datos.cuentahaber,
      importedebe: datos.importedebe.replace('S/.    ', ''),
      importehaber: datos.importehaber.replace('S/.    ', ''),
      nidcarga: datos.nidpatrimonial,
    })
  }

  async editar2(){
    if(this.state.apunta == true){
      this.setState({
        apunta: false,
        importedebe2: this.state.importedebe2.replace('S/.    ', ''),
        importehaber2: this.state.importehaber2.replace('S/.    ', '')
      })
    }else{
      let impdebe2 = "";
      let imphaber2 = "";
      if(this.state.importedebe2 != 0){
        impdebe2 = 'S/.    ' + this.state.importedebe2
      }else{
        impdebe2 = this.state.importedebe2
      }
      if(this.state.importehaber2 != 0){
        imphaber2 = 'S/.    ' + this.state.importehaber2
      }else{
        imphaber2 = this.state.importehaber2
      }
      this.setState({
        apunta: true,
        importedebe2: impdebe2,
        importehaber2: imphaber2
      })
    }
  }

  async editar3(){
    if(this.state.apunta2 == true){
      this.setState({apunta2: false})
    }else{
      this.setState({apunta2: true})
    }
  }

  async editar4(){
    if(this.state.apunta3 == true){
      this.setState({apunta3: false})
    }else{
      this.setState({apunta3: true})
    }
  }

  async cancelar(){
    this.setState({monto: "", selectedOption4: {value: this.state.clasificadores[0].value, label: this.state.clasificadores[0].label}})
  }

  async cancelar2(){
    this.setState({cuentadebe: "", cuentahaber: "", importedebe: "", importehaber: "", nidcarga: ""})
  }

  async volver(){
    this.props.history.push('/cajachica');
  }

  async superadelante(){
    if(this.state.nidsupersiguiente != null){
      let data = {
        "nidmovimiento": this.state.nidsupersiguiente
      }
      localStorage.setItem('movimientocaja', JSON.stringify(data))
      this.setState({movimiento: this.state.nidsupersiguiente})
      this.listarMovimiento(1, this.state.nidsupersiguiente)
    }
  }

  async adelante(){
    if(this.state.nidsiguiente != null){
      let data = {
        "nidmovimiento": this.state.nidsiguiente
      }
      localStorage.setItem('movimientocaja', JSON.stringify(data))
      this.setState({movimiento: this.state.nidsiguiente})
      this.listarMovimiento(1, this.state.nidsiguiente)
    }
  }

  async atras(){
    if(this.state.nidanterior != null){
      let data = {
        "nidmovimiento": this.state.nidanterior
      }
      localStorage.setItem('movimientocaja', JSON.stringify(data))
      this.setState({movimiento: this.state.nidanterior})
      this.listarMovimiento(1, this.state.nidanterior)
    }
  }

  async superatras(){
    if(this.state.nidsuperanterior != null){
      let data = {
        "nidmovimiento": this.state.nidsuperanterior
      }
      localStorage.setItem('movimientocaja', JSON.stringify(data))
      this.setState({movimiento: this.state.nidsuperanterior})
      this.listarMovimiento(1, this.state.nidsuperanterior)
    }
  }

  async generarSelect(tipo, param){
    if(tipo == 1){
      this.state.beneficiarios.map((data) => {
        if(data.label == param){
          this.setState({
            selectedOption6: {
              value: data.value, 
              label: data.label, 
              tipo: data.tipo, 
              documento: data.documento,
              clase: data.clase
            }
          })
        }
      })
    }else{
      this.state.conceptos.map((data) => {
        if(data.label == param){
          this.setState({
            selectedOption7: {
              value: data.value, 
              label: data.label
            }
          })
        }
      })
    }
  }

  async generarChange(tipo, param){
    if(tipo == 1){
      if(param == ''){
        this.setState({selectedOption6: ''})
       }
    }else{
      if(param == ''){
        this.setState({selectedOption7: ''})
       }
    }
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Remover Partida" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  GetActionFormat2(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Importe" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Remover Importe" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete2(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  render() {
    const columnsEstadistica = [
      {dataField: "partida", text: "PARTIDA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "parcial", text: "IMPORTE", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const columnsPatrimonial = [
        {dataField: "cuentadebe", text: "CUENTA DEBE", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "importedebe", text: "IMPORTE DEBE", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cuentahaber", text: "CUENTA HABER", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "importehaber", text: "IMPORTE HABER", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2}
    ]
    const {numero, selectedOption, selectedOption2, selectedOption3, selectedOption4, selectedOption5, apunta, apunta2, apunta3} = this.state
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <div className="row">
                <div className="col-md-8">
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Detalle del Movimiento de Fondos para Pagos en Efectivo: N° {numero}</h4>
                </div>
                <div className="col-md-4" style={{marginTop: 10}}>
                    <Button style={{width: 50, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.superadelante() }}>
                       <i className="fa fa-fast-forward" style={{fontSize: 18, paddingTop: 5}}></i>
                    </Button>
                    <Button style={{width: 50, marginRight: 10, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.adelante() }}>
                       <i className="fa fa-forward" style={{fontSize: 18, paddingTop: 5}}></i>
                    </Button>
                    <Button style={{width: 50, marginRight: 10, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.atras() }}>
                       <i className="fa fa-backward" style={{fontSize: 18, paddingRight: 10, paddingTop: 5}}></i>
                    </Button>   
                    <Button style={{width: 50, marginRight: 10, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.superatras() }}>
                       <i className="fa fa-fast-backward" style={{fontSize: 18, paddingRight: 10, paddingTop: 5}}></i>
                    </Button>              
                </div>
              </div>
                <div className="row" style={{marginBottom: 10}}>
                  <div className="col-md-6">
                  <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Datos del Beneficiario y Concepto</h4>
                  {this.state.isLoading1 ? (
                    <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading1}
                    />
                  ) : (
                    apunta3 == false ? (
                      <div>
                        <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>SELECCIONE EL BENEFICIARIO</ControlLabel>
                              {/* <Select value={selectedOption6} onChange={this.handleChange6} options={this.state.beneficiarios}/> */}
                              <Autocomplete 
                                  inputRef={this.inputRef}
                                  searchPattern={'containsString'}
                                  selectOnBlur = {false}
                                  placeholder = "Buscar beneficiario"
                                  maxOptionsLimit = {10} 
                                  searchEnabled = {true}
                                  getItemValue={(item)=>{ return item.label }} 
                                  onChange = {
                                    changedValue=>{ 
                                    this.generarChange(1, changedValue)
                                  }}
                                  onSelect = {
                                    selectedValue => { 
                                    this.generarSelect(1, selectedValue)
                                  }}
                                  itemsData = {this.state.beneficiarios}
                                  globalStyle = ".autocomplete {position: relative; display: inline-block; width: 100%} input {font-size: 14px} body {font-size: 14px; font-family: 'Roboto','Helvetica Neue',Arial,sans-serif; line-height: 1.42857143}"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>SELECCIONE EL CONCEPTO</ControlLabel>
                              {/* <Select value={selectedOption7} onChange={this.handleChange7} options={this.state.conceptos}/> */}
                              <Autocomplete 
                                  inputRef={this.inputRef2}
                                  searchPattern={'containsString'}
                                  selectOnBlur = {false}
                                  placeholder = "Buscar concepto"
                                  maxOptionsLimit = {10} 
                                  searchEnabled = {true}
                                  getItemValue={(item)=>{ return item.label }} 
                                  onChange = {
                                    changedValue=>{ 
                                    this.generarChange(2, changedValue)
                                  }}
                                  onSelect = {
                                    selectedValue => { 
                                    this.generarSelect(2, selectedValue)
                                  }}
                                  itemsData = {this.state.conceptos}
                                  globalStyle = ".autocomplete {position: relative; display: inline-block; width: 100%} input {font-size: 14px} body {font-size: 14px; font-family: 'Roboto','Helvetica Neue',Arial,sans-serif; line-height: 1.42857143}"
                                />
                            </div>
                        </div> 
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-md-8" style={{marginBottom: 18}}>
                            <ControlLabel>NOMBRE O RAZON SOCIAL</ControlLabel>
                            <FormControl type="text" value={this.state.nombre} onChange= {this.handleNombre} disabled/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>DOCUMENTO</ControlLabel>
                            <FormControl type="text" value={this.state.documento} onChange= {this.handleDocumento} disabled/>
                        </div>
                        <div className="col-md-12" style={{marginBottom: 18}}>
                            <ControlLabel>CONCEPTO</ControlLabel>
                            <FormControl componentClass="textarea" style={{  height: 205, maxHeight: 205, minHeight: 205, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.concepto} onChange= {this.handleConcepto} disabled/>
                        </div>
                      </div> 
                    )
                    )}
                    {apunta3 == true ? (
                      <Button style={{width: 200, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.editar4() }}>Editar</Button>
                      ) : (
                      <div>
                        <Button style={{width: 200, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.guardar() }}>Actualizar</Button>
                        <Button style={{width: 200, marginRight: 10, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.editar4() }}>Cancelar</Button>
                      </div>
                    )}
                  </div>
                {/* </div>
                <hr></hr>
                <div className="row" style={{marginBottom: 10}}> */}
                  <div className="col-md-6">
                  <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Datos del Movimiento</h4>
                      {this.state.isLoading2 ? (
                        <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading2}
                        />
                      ) : (
                        <div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>HECHO POR</ControlLabel>
                                <FormControl type="text" style={{textTransform: 'uppercase'}} value={this.state.usuariox} onChange= {this.handleUsuario} disabled/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>N° CORRELATIVO</ControlLabel>
                                <FormControl type="text" style={{textTransform: 'uppercase'}} value={numero} onChange= {this.handleNumero} disabled={apunta2}/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>TIPO DE CAJA</ControlLabel>
                                <Select value={selectedOption5} onChange={this.handleChange5} options={this.state.cajas}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>NÚMERO</ControlLabel>
                                <FormControl type="text" value={this.state.numerocaja} onChange= {this.handleNumerocaja} disabled={apunta2}/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE PAGO</ControlLabel>
                                <FormControl type="date" value={this.state.fecha} onChange= {this.handleFecha} disabled={apunta2}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>META</ControlLabel>
                                <Select value={selectedOption3} onChange={this.handleChange3} options={this.state.metas}/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>OFICINA</ControlLabel>
                                <Select value={selectedOption} onChange={this.handleChange} options={this.state.oficinas}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>RESPONSABLE</ControlLabel>
                                <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.responsables}/>
                            </div>
                          </div>
                        </div>
                      )}
                      {apunta2 == true ? (
                        <Button style={{width: 200, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.editar3() }}>Editar</Button>
                        ) : (
                        <div>
                          <Button style={{width: 200, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.guardar2() }}>Actualizar</Button>
                          <Button style={{width: 200, marginRight: 10, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.editar3() }}>Cancelar</Button>
                        </div>
                      )}
                  </div>
                </div>
                <hr></hr>
                <div className="row" style={{marginBottom: 10}}>
                    <div className="col-md-6">
                      <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Clasificadores de Gasto</h4>
                      <div className="row">
                          <div className="col-md-8" style={{marginBottom: 18}}>
                              <ControlLabel>PARTIDA</ControlLabel>
                              <Select value={selectedOption4} onChange={this.handleChange4} options={this.state.clasificadores}/>
                          </div>
                          <div className="col-md-4" style={{marginBottom: 18}}>
                              <ControlLabel>IMPORTE</ControlLabel>
                              <div class="input-group">
                                  <FormControl type="number" value={this.state.monto} onChange= {this.handleMonto}/>
                                  <span class="input-group-btn">
                                    <Button style={{height: 40, width: 50, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.guardar3() }}>
                                      <i className="fa fa-plus" style={{fontSize: 18, paddingTop: 2, paddingRight: 15}}></i>
                                    </Button> 
                                  </span>
                              </div>
                          </div>
                      </div>
                      {this.state.isLoading3 ? (
                          <ClipLoader
                              css={override}
                              sizeUnit={"px"}
                              size={50}
                              color={'#123abc'}
                              loading={this.state.loading3}
                          />
                      ) : (
                        <div>
                          <BootstrapTable striped hover keyField="item" data={ this.state.estadistica } columns={ columnsEstadistica }/>
                          <div className="row" style={{marginBottom: 10}}>
                              <div className="col-md-9"></div>
                              <div className="col-md-3">
                                  <ControlLabel>TOTAL GASTOS</ControlLabel>
                                  <FormControl type="text" value={this.state.totalgastos} onChange= {this.handleTotalgastos} disabled/>
                              </div>
                          </div>
                        </div>
                      )}
                    </div>
                  {/* </div>
                  <hr></hr>
                  <div className="row" style={{marginBottom: 10}}> */}
                    <div className="col-md-6">
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Contabilidad Presupuestal</h4>
                      {this.state.isLoading4 ? (
                          <ClipLoader
                              css={override}
                              sizeUnit={"px"}
                              size={50}
                              color={'#123abc'}
                              loading={this.state.loading4}
                          />
                      ) : (
                      <div className="row">
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>CTA DEBE</ControlLabel>
                              <FormControl type="text" value={this.state.cuentadebe2} onChange= {this.handleCuentadebe2} disabled={apunta}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>IMPORTE DEBE</ControlLabel>
                              <FormControl type="text" value={this.state.importedebe2} onChange= {this.handleImportedebe2} disabled={apunta}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>CTA HABER</ControlLabel>
                              <FormControl type="text" value={this.state.cuentahaber2} onChange= {this.handleCuentahaber2} disabled={apunta}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>IMPORTE HABER</ControlLabel>
                              <FormControl type="text" value={this.state.importehaber2} onChange= {this.handleImportehaber2} disabled={apunta}/>
                          </div>
                      </div>
                      )}
                      <div>
                      {apunta == true ? (
                        <Button style={{width: 200, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.editar2() }}>Editar</Button>
                        ) : (
                        <div>
                          <Button style={{width: 200, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.guardar4() }}>Actualizar</Button>
                          <Button style={{width: 200, marginRight: 10, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.editar2() }}>Cancelar</Button>
                        </div>
                      )}
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row" style={{marginBottom: 10}}>
                    <div className="col-md-12">
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Contabilidad Patrimonial</h4>
                      <div className="row">
                          <div className="col-md-3" style={{marginBottom: 18}}>
                              <ControlLabel>CTA DEBE</ControlLabel>
                              <FormControl type="text" value={this.state.cuentadebe} onChange= {this.handleCuentadebe}/>
                          </div>
                          <div className="col-md-3" style={{marginBottom: 18}}>
                              <ControlLabel>IMPORTE DEBE</ControlLabel>
                              <FormControl type="number" value={this.state.importedebe} onChange= {this.handleImportedebe}/>
                          </div>
                          <div className="col-md-3" style={{marginBottom: 18}}>
                              <ControlLabel>CTA HABER</ControlLabel>
                              <FormControl type="text" value={this.state.cuentahaber} onChange= {this.handleCuentahaber}/>
                          </div>
                          <div className="col-md-3" style={{marginBottom: 18}}>
                              <ControlLabel>IMPORTE HABER</ControlLabel>
                              <div class="input-group">
                                  <FormControl type="number" value={this.state.importehaber} onChange= {this.handleImportehaber}/>
                                  <span class="input-group-btn">
                                    <Button style={{height: 40, width: 50, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.save() }}>
                                      <i className="fa fa-plus" style={{fontSize: 18, paddingTop: 2, paddingRight: 15}}></i>
                                    </Button> 
                                  </span>
                                  <span class="input-group-btn">
                                    <Button style={{height: 40, width: 50, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.cancelar2() }}>
                                      <i className="fa fa-trash" style={{fontSize: 18, paddingTop: 2, paddingRight: 15}}></i>
                                    </Button> 
                                  </span>
                              </div>
                          </div>
                      </div>
                      {this.state.isLoading5 ? (
                          <ClipLoader
                              css={override}
                              sizeUnit={"px"}
                              size={50}
                              color={'#123abc'}
                              loading={this.state.loading5}
                          />
                          ) : (
                          <BootstrapTable striped hover keyField="item" data={ this.state.patrimonial } columns={ columnsPatrimonial }/>
                      )}
                    </div>
                  </div>
              </div>
            </Col>
            <Button style={{width: 200, marginTop: 25, marginRight: 15}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.volver() }}>Ir a Caja Chica</Button>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarCajaChica;