import React, { Component } from "react";
import {FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import moment from 'moment'
import Select from 'react-select';
import { Card } from "components/Card/Card.jsx";
import { validator, sisperplus} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import InputFiles from 'react-input-files';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class EditarFamiliar extends Component {

    constructor(props){
        super(props);
        this.state = {
            path: 'miLegajo',
            usuario: localStorage.getItem('nickname'),
            token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,          
            movimientos: [],
            isLoading: true,   
            id: JSON.parse(localStorage.getItem('legajo')).legajo[0].idpersona,
            nombre: JSON.parse(localStorage.getItem('legajo')).legajo[1].nombre,
            codcategoria: JSON.parse(localStorage.getItem('legajo')).legajo[2].idcategoria,
            descategoria: JSON.parse(localStorage.getItem('legajo')).legajo[3].descategoria,
            enlace: JSON.parse(localStorage.getItem('legajo')).legajo[4].enlace,
            idlegajo: JSON.parse(localStorage.getItem('legajo')).legajo[5].idlegajo,
            tipodocumentos: [], numdocumento: "",
            nombres: "", apepaterno: "", apematerno: "",
            situacion: [{value:"1",label:"VIVO"},{value:"2",label:"FALLECIDO"}], 
            genero: [{value:"1",label:"MASCULINO"},{value:"2",label:"FEMENINO"}], 
            parentesco: [],
            fechanacimiento: "",            
            selectedTipoDocumento: "", 
            selectedSituacion: {value:"1",label:"VIVO"}, selectedGenero: {value:"1",label:"MASCULINO"},
            selectedParentesco: "",
            file: [],
            archivo: "",
            filename: "",
            esDiscapacitado: false,
            selectedEstadoSolicitud: "",
            estadossolicitud: [    
                {value: "0", label: "SIN VALIDAR"},     
                {value: "1", label: "APROBADO"},           
                {value: "2", label: "ANULADO"}
            ],
            desmotivo: "",
            datos: JSON.parse(localStorage.getItem("legajo")).datosFamiliares[0].data,
        }

        this.handleNumDocumento = this.handleNumDocumento.bind(this);
        this.handleNombres = this.handleNombres.bind(this);
        this.handleApePaterno = this.handleApePaterno.bind(this);
        this.handleApeMaterno = this.handleApeMaterno.bind(this);
        this.handleFecNacimiento = this.handleFecNacimiento.bind(this);   
        this.handleCheck = this.handleCheck.bind(this);     
        this.handleDesMotivo = this.handleDesMotivo.bind(this);   
    }

    changeTipoDocumento = selectedTipoDocumento =>{ this.setState({selectedTipoDocumento});};    
    changeGenero        = selectedGenero =>{ this.setState({selectedGenero});};   
    changeSituacion     = selectedSituacion =>{ this.setState({selectedSituacion});};
    changeParentesco    = selectedParentesco =>{ this.setState({selectedParentesco});};
    changeEstadoSolicitud = selectedEstadoSolicitud =>{ this.setState({selectedEstadoSolicitud});};  

    handleNumDocumento(event) { 
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
          this.setState({numdocumento: event.target.value})
        }
        //this.setState({numdocumento: event.target.value});
      };    
    handleNombres(event) { this.setState({nombres: event.target.value});};
    handleApePaterno(event) { this.setState({apepaterno: event.target.value});};
    handleApeMaterno(event) { this.setState({apematerno: event.target.value});};
    handleFecNacimiento(event) { this.setState({fechanacimiento: event.target.value});};   
    handleDesMotivo(event) { this.setState({desmotivo: event.target.value});}; 

    handleCheck(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
    }

    async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{
                    //console.log("codigo: " + this.state.codcategoria);                    
                    this.listarTipoDocumentos();
                    this.listarParentesco();
                    this.obtenerDatos();
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }

    async obtenerDatos(){
        if(this.state.datos !== null){
            this.setState({
                iddatofamiliar: this.state.datos.iddatofamiliar,
                selectedTipoDocumento: {value: this.state.datos.idtipodocumento, label: this.state.datos.destipodocumento},
                numdocumento: this.state.datos.numerodocumento,
                apepaterno: this.state.datos.apellidopaterno, 
                apematerno: this.state.datos.apellidomaterno,
                nombres: this.state.datos.nombre, 
                fechanacimiento: Calendar.calendario11(this.state.datos.fechanacimiento),
                selectedGenero: {value: this.state.datos.idgenero, label:this.state.datos.genero},
                selectedParentesco: {value: this.state.datos.idparentesco, label: this.state.datos.desparentesco},
                selectedSituacion: {value: this.state.datos.idsituacion, label: this.state.datos.situacion},
                esDiscapacitado: this.state.datos.esdiscapacitado == "0" ? false : true,
                //idreg: this.state.datos.idreg,
                selectedEstadoSolicitud: {value: this.state.datos.idestado, label: this.state.datos.nomestado},
                desmotivo: this.state.datos.motivoanula,
                nid: this.state.datos.niddocumento,
                archivo: this.state.datos.archivo,
                ruta: this.state.datos.rutaarchivo
            })
        }
    }

    async listarTipoDocumentos() {        
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'legajo/listTipoDocumentos?filtro=1')        
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({ 
                    value: data.id_tipodocumento,
                    label: data.descripcion,     
                })
              })
    
              this.setState({tipodocumentos: lista})
            }else{
              this.setState({tipodocumentos: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }

    async listarParentesco() {        
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'legajo/listParentesco')        
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({ 
                    value: data.id_parentesco,
                    label: data.descripcion,     
                })
              })
    
              this.setState({parentesco: lista})
            }else{
              this.setState({parentesco: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }

    subir = (files) => {
        if(files.length !== 0){
            this.setState({file: files[0], filename: files[0].name, type: files[0].type, visFile: true});
        }
    }

    async download(){
        let anio = Calendar.calendario10(this.state.fechaini).substr(0,4)
        let mes =  Calendar.calendario10(this.state.fechaini).substr(5,2) 
        let archivo = this.state.ruta        
        window.open(archivo, "_blank")
    }

    async validar(){
        if(this.state.numdocumento != '' && this.state.apepaterno != '' && this.state.apematerno != '' && 
          this.state.nombres != '' && this.state.fechanacimiento != '') {
            
            if(this.state.numdocumento.length == 8){

                if(this.state.enlace = 'T'){                
                    if(this.state.selectedEstadoSolicitud.value == '2' && this.state.desmotivo == ''){
                       this.props.handleClick("tl", "warning", "Debe ingresar motivo de anulación!", "pe-7s-info")
                       return;
                    }
                }   

                if(this.state.filename != ''){
                    if(this.state.type == '' || this.state.type == 'application/pdf'){
                        this.guardar();  
                        this.actualizarDocumento();
                    }else{
                        this.props.handleClick("tl", "warning", "El archivo a subir debe ser un PDF!", "pe-7s-info")
                    }
                }else{
                    this.guardar();  
                    this.regresar();
                }
                          
            }else{
              this.props.handleClick("tl", "warning", "El DNI ingresado no es válido!", "pe-7s-close");
            }            
         
        }else{
          this.props.handleClick("tl", "warning", "Debe completar los campos solicitados!", "pe-7s-info");      
        }
    
      }

    async regresar(){
        this.props.history.push('/listadoFamiliar');
    }

    async guardar(){
        this.setState({isLoading: true})
        let formdata = new FormData();
        formdata.append("nid", this.state.iddatofamiliar);        
        formdata.append("idlegajo", this.state.idlegajo);        
        formdata.append("idtipodocumento", this.state.selectedTipoDocumento.value);
        formdata.append("numerodocumento", this.state.numdocumento);
        formdata.append("apepaterno", this.state.apepaterno.toUpperCase());
        formdata.append("apematerno", this.state.apematerno.toUpperCase());
        formdata.append("nombres", this.state.nombres.toUpperCase());        
        formdata.append("fechanacimiento", this.state.fechanacimiento);
        formdata.append("idgenero", this.state.selectedGenero.value);
        formdata.append("idparentesco", this.state.selectedParentesco.value);
        formdata.append("idsituacion", this.state.selectedSituacion.value);
        formdata.append("esdiscapacitado", this.state.esDiscapacitado == true ? "1" : "0");        
        formdata.append("estado", this.state.selectedEstadoSolicitud.value);
        formdata.append("motivo", this.state.desmotivo);
        formdata.append("usuario", this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'legajo/updateDatosFamiliaresLegajo', formdata)
        .then((response) => {
            var datos = response.data
            if(datos.response != null){
                this.props.handleClick("tl", "success", "Datos actualizados correctamente!", "pe-7s-close")                
            }else{
                this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
                this.setState({isLoading: false})
            }
        })
        .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
            }else{
                console.log(error);
                this.setState({isLoading: false})
            }
        });
    }

    async actualizarDocumento(){
        this.setState({isLoading: true})
        let fromData = new FormData();                      
        //fromData.append('id', this.state.idreg);
        fromData.append('nid', this.state.nid);
        fromData.append('idlegajo', this.state.idlegajo);
        fromData.append('idseccion', this.state.codcategoria);
        fromData.append('idtipodocumento', "2");        
        fromData.append('file', this.state.file);
        fromData.append('archivo', this.state.filename)                      
        fromData.append('usuario', this.state.usuario);              
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'legajo/updateDocumentoLegajo', fromData)
        .then((response) => {                
            if(response.data.result == null){
                this.props.handleClick("tl", "info", "Se actualizó documento!", "pe-7s-smile")
                this.regresar();
            }else{
            this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
            this.setState({isLoading: false})
            }
        })
        .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
            }else{
                console.log(error);
                this.setState({isLoading: false})
            }
        });  
    }

    render(){
        const {selectedTipoDocumento,selectedParentesco,selectedGenero,selectedSituacion,selectedEstadoSolicitud} = this.state
        return(
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                        <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 5, paddingBottom: 5}}>
                            <h4 className="title" style={{fontWeight: 600, marginTop: 18, marginBottom: 25}}>EDITAR FAMILIAR</h4>                            
                            <div className="row"> 
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>TIPO DE DOCUMENTO<a style={{color:"red"}}> *</a></ControlLabel>
                                    <Select value={selectedTipoDocumento} onChange={this.changeTipoDocumento} options={this.state.tipodocumentos} />
                                </div>
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>NÚMERO DE DOCUMENTO<a style={{color:"red"}}> *</a></ControlLabel>
                                    <FormControl type="text" value={this.state.numdocumento} onChange= {this.handleNumDocumento} maxLength={8} />
                                </div>   
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>APELLIDO PATERNO<a style={{color:"red"}}> *</a></ControlLabel>
                                    <FormControl type="text" value={this.state.apepaterno} onChange= {this.handleApePaterno} />
                                </div>
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>APELLIDO MATERNO<a style={{color:"red"}}> *</a></ControlLabel>
                                    <FormControl type="text" value={this.state.apematerno} onChange= {this.handleApeMaterno} />
                                </div>                                 
                            </div>                   
                            <div className="row">                                 
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>NOMBRES<a style={{color:"red"}}> *</a></ControlLabel>
                                    <FormControl type="text" value={this.state.nombres} onChange= {this.handleNombres} />
                                </div>
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>FECHA DE NACIMIENTO<a style={{color:"red"}}> *</a></ControlLabel>
                                    <FormControl type="date" value={this.state.fechanacimiento} onChange= {this.handleFecNacimiento} />
                                </div>
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>GENERO<a style={{color:"red"}}> *</a></ControlLabel>
                                    <Select value={selectedGenero} onChange={this.changeGenero} options={this.state.genero} />
                                </div>
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>PARENTESCO<a style={{color:"red"}}> *</a></ControlLabel>
                                    <Select value={selectedParentesco} onChange={this.changeParentesco} options={this.state.parentesco} />
                                </div>                                  
                            </div>  
                            <div className="row">
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>SITUACIÓN<a style={{color:"red"}}> *</a></ControlLabel>
                                    <Select value={selectedSituacion} onChange={this.changeSituacion} options={this.state.situacion} />
                                </div>
                                <div className="col-md-3" style={{letterSpacing: 1}}>                                    
                                    <br /><br />
                                    <div>
                                        <input onChange={this.handleCheck} name="esDiscapacitado" type="checkbox" checked={this.state.esDiscapacitado} style={{marginRight: 5}}
                                        /><a>¿Es discapacitado?</a>
                                    </div>
                                </div>
                            </div>   
                            {this.state.enlace == 'T' ? (
                                <div>
                                    <p style={{borderBottom: "solid", backgroundColor: "#1E75C6", borderColor: "#1E75C6", borderWidth: 3, fontSize: 16, height:20, maxHeight: 20, minHeight: 20, textAlign: "center", color: "white", paddingBottom: 25}}><span>ESTADO DE SOLICITUD</span></p>                
                                    <div className="row">
                                        <div className="col-md-3" style={{marginBottom: 18}}>
                                            <ControlLabel>ESTADO<a style={{color:"red"}}> *</a></ControlLabel>
                                            <Select value={selectedEstadoSolicitud} onChange={this.changeEstadoSolicitud} options={this.state.estadossolicitud} />
                                        </div>
                                        {selectedEstadoSolicitud.value == '2' ? (
                                            <div className="col-md-9" style={{marginBottom: 18}}>                                    
                                                <ControlLabel>MOTIVO<a style={{color:"red"}}> *</a></ControlLabel>
                                                <FormControl componentClass="textarea" style={{  height: 60, maxHeight: 60, minHeight: 60, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.desmotivo} onChange= {this.handleDesMotivo} maxLength={200}/>                                    
                                            </div>    
                                        ) : (
                                            null
                                        )}                                        
                                    </div>
                                    <p style={{borderBottom: "solid", borderColor: "#1E75C6", borderWidth: 3, fontSize: 18}}><span></span></p>
                                </div>
                            ) : (
                                null
                            )}                          
                            <div className="row" style={{marginTop: 18, marginBottom: 20}}>
                                <div className="col-md-3"></div>                            
                                <div className="col-md-6" style={{marginBottom: 18}}>
                                    <ControlLabel>ARCHIVOS SUBIDOS</ControlLabel>
                                    <ul className="todo-list">
                                    <li>                                   
                                        <div className="price">
                                            <h3>1</h3>
                                        </div>
                                        <div className="name">
                                            <span className="item-name">{this.state.archivo}</span>                                        
                                        </div>                                    
                                        <span className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20, marginRight: 5}} onClick={() => { this.download() }}>
                                            <i className="fa fa-circle fa-stack-2x" style={{color:"#1B4F72"}}></i>
                                            <i className="fa fa-download fa-stack-1x fa-inverse" style={{fontSize: 18, color:"white"}}></i>
                                        </span>                                                                     
                                        </li>
                                    </ul>
                                    <Alert bsStyle="warning">                                    
                                        <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.filename}</span>
                                    </Alert>
                                    <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                                        <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                                    </Button>                              
                                </div>                            
                            </div>
                            <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Registro</Button>
                            <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Editar Registro</Button>
                        </div>                        
                        </Col>
                    </Row>
                </Grid>

            </div>
        )
    }
}

export default EditarFamiliar;