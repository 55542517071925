import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col , Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, cec, fechasBusqueda, paramCec } from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { paramAulaVirtual } from "store/Apis";
import InputFiles from 'react-input-files';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Equipo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'publicaciones',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      archivo:"",
      file:[],
      nombres:"",
      cargo:"",
      nivel: {value: 1 , label :"PRINCIPAL"},
      niveles:[{value: 1 , label :"PRINCIPAL"},{value: 0 , label :"SECUNDARIA"}],
      orden : "",      
      isLoading: true,
      nid:""
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.foto= this.foto.bind(this);
    this.handlePersona=this.handlePersona.bind(this);
    this.handleCargo=this.handleCargo.bind(this);
    this.handleOrden=this.handleOrden.bind(this);

  }
    handlePersona(event) {this.setState({nombres: event.target.value});}
    handleCargo(event) {this.setState({cargo: event.target.value});}
    handleOrden(event) {this.setState({orden: event.target.value});}
    handleNiveles = nivel => {
      this.setState(
          {nivel},
      );
     };

    
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
             this.listEquipoAdm();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  
  async listEquipoAdm() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${cec}` + 'medios/listEquipoAdm')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                ctxt_nombres: data. ctxt_nombres,
                item: data.item,
                ctxt_periodo: data.ctxt_periodo,
                flg_nivel: data.flg_nivel,
                nid: data.nid,
                flg_estado:  data.flg_estado,
                ctxt_cargo: data.ctxt_cargo,
                cnom_foto: data.cnom_foto,
                ctxt_ruta: data.ctxt_ruta,
                cnom_usuario: data.cnom_usuario,
                dfec_creacion: data.dfec_creacion,
                nivel: data.nivel,
                flg_orden: data.flg_orden,
                flg_tipo: data.flg_tipo
            })
          })
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }


  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea anular el registro?',
      message: 'Se anulará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'medios/anularEquipoAdm', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 1){
          this.listEquipoAdm();
          this.props.handleClick("tl", "info", "El registro  ha sido anulado!", "pe-7s-smile")
      }else{
          this.props.handleClick("tl", "error", "No se puede anular el registro!", "pe-7s-close")
          this.setState({isLoading: false})
      }
    }else{
      this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
      this.setState({isLoading: false})
    }
  })
  .catch((error) =>{ 
    if(auth0Client.isAuthenticated() === true){
      auth0Client.signOut()
      this.props.history.replace('/');
    }else{
      console.log(error);
      this.setState({isLoading: false})
    }
  });
}

   foto (cell, row){
    if(row != undefined){
        return (
            <div>
              <span  data-tip="Ver Imagen" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(row.ctxt_ruta) }}>
                   <img src={row.ctxt_ruta} alt="" style={{width:"200px"}}></img>
                  </span>
            </div>
        );
      }
   }
   abrirImagen(ruta){
    window.open(ruta, '_blank');
  } 

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
             
              <span data-tip="Anular Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  async detalle(data){
    console.log(data)
    this.setState({
    nombres: data.ctxt_nombres,
    cargo: data.ctxt_cargo,
    nivel: {value: data.flg_nivel, label:data.nivel},
    nid: data.nid,
    foto: data.cnom_foto,
    orden: data.flg_nivel==1 ? "" :  data.flg_orden,
    archivo : data.cnom_foto
   })
  }
  async limpiar2(){
    this.setState({archivo: "", file: ""})
  } 

 
  async regresar(){
    this.props.history.push('/publicaciones');
  }

  async cancelar(){
    this.setState({archivo: "", file: "", nombres: "",cargo: "",orden: "", nid:"", isLoading:false})

  }

  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], archivo: files[0].name});
    }
  }

  async save(){
    if(this.state.nombres != "" && this.state.cargo != "" && this.state.archivo != "" ){
      if(this.state.nid == ''){
        this.guardar()
      }else{
        var estado = this.state.file.length==0 ? 1 : 0
        this.update(estado)
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  } 


  async guardar(){  
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('file', this.state.file);
    fromData.append('nombre', this.state.nombres);
    fromData.append('cargo', this.state.cargo);
    fromData.append('foto',this.state.archivo);
    fromData.append('nivel',this.state.nivel.value);
    fromData.append('orden',this.state.orden);
    fromData.append('usuario',this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'medios/insertarEquipoAdm', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.cancelar();         
          this.props.handleClick("tl", "info", "Se ha registrado correctamente!", "pe-7s-smile") 
          this.listEquipoAdm()
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
   
  }

  async update(estado){ 
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('file', this.state.file);
    fromData.append('nombre', this.state.nombres);
    fromData.append('cargo', this.state.cargo);
    fromData.append('foto',this.state.archivo);
    fromData.append('nivel',this.state.nivel.value);
    fromData.append('orden',this.state.orden);
    fromData.append('estado',estado);
    fromData.append('usuario',this.state.usuario);
    fromData.append('nid', this.state.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'medios/editarEquipoAdm', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Registro actualizado correctamente!", "pe-7s-close")
        this.cancelar();
        this.listEquipoAdm()
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

 

  render() {
    const columns = [
   {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "ctxt_nombres", text: "NOMBRES Y APELLIDOS", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "ctxt_cargo", text: "CARGO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "ctxt_ruta", text: "FOTO",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter:this.foto},
    {dataField: "nivel", text: "SECCIÓN",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align: 'center',formatter :this.veryoutube},
    {dataField: "flg_orden", text: "ORDEN",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align: 'center',formatter :this.veryoutube},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Equipo del CEC</h4>
                    <ControlLabel></ControlLabel><br></br>
                  <div className="row" style={{marginBottom: 10}}>
                    <div className="col-md-6" style={{marginBottom: 18}}>
                        <ControlLabel>NOMBRES Y APELLIDOS</ControlLabel>
                        <FormControl type="text" value={this.state.nombres} onChange= {this.handlePersona}/>
                    </div>
                    <div className="col-md-6" style={{marginBottom: 18}}>
                        <ControlLabel> CARGO</ControlLabel>
                        <FormControl type="text" value={this.state.cargo} onChange= {this.handleCargo}/>
                    </div>
                    <div className="col-lg-4 col-md-6" style={{marginBottom: 18}}>
                        <ControlLabel>SECCIÓN</ControlLabel>
                        <Select value={this.state.nivel} onChange={this.handleNiveles} options={this.state.niveles}/>
                    </div>
                    <div className="col-lg-4 col-md-6" style={{marginBottom: 18}}>
                        <ControlLabel>N° ORDEN</ControlLabel>
                        <FormControl type="text" value={this.state.orden} onChange= {this.handleOrden}/>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <ControlLabel>FOTO</ControlLabel>
                        <Alert bsStyle="info">
                          <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo}</span>
                        </Alert>
                        <Button style={{width: '100%', marginTop: -10}} bsStyle="info" pullRight fill type="submit">
                          <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar la foto</InputFiles>
                        </Button>
                        {this.state.archivo != "" ? (
                          <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar2() }}>Limpiar Archivo</Button>
                        ) : ( null )}
                    </div>
                    <div className="col-md-12">
                      <Button style={{width: 120,marginTop:20, marginBottom: 5 }} bsStyle="success" pullRight fill type="submit" onClick={() => { this.save() }}>Guardar</Button>
                      <Button style={{width: 120,marginTop:20, marginBottom: 5 , marginRight: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Cancelar</Button>
               
                    </div>
                    
                     </div>
                    <Button style={{width: 250, marginTop: 5,marginBottom: 10,marginRight: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                    {/* <Button style={{width: 250, marginTop: 5, marginBottom: 10,marginRight: 10}} bsStyle="info" fill type="submit" onClick={() => { this.detalle2() }}>Nuevo</Button> */}
              
            
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Equipo;