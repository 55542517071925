import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { jurisdiccional} from 'store/Apis'
import 'react-tabs/style/react-tabs.css';
import Calendar from "components/Calendar/Calendar.jsx";
import 'react-accessible-accordion/dist/fancy-example.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class EditarUltimasAudiencias extends Component {

    constructor(props) {
        super(props);
        this.state = {
          path: 'ultimasaudiencias',
          usuario: localStorage.getItem('nickname'),
          token: 'Bearer ' + localStorage.getItem('ConcurToken'),
          token2: 'Bearer ' + localStorage.getItem('AccessToken'),
          id: JSON.parse(localStorage.getItem('datos'))[0].Person,
          dfec_fecha:"",
          ctxt_mensaje: "",
          estado: 0,
          isLoading: true,
          show: true,
        }
        this.handleMensaje= this.handleMensaje.bind(this);
        this.handleFecha = this.handleFecha.bind(this);
      }
       
        handleMensaje(event) {this.setState({ctxt_mensaje: event.target.value});}
        handleFecha(event) {this.setState({dfec_fecha: event.target.value});}
        
    
      async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token2
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{
                    this.validarLocal();
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
      }
      async validarLocal(){
        if(JSON.parse(localStorage.getItem("jurisdiccional")).audiencia[0].data !== null){
            this.setState({
              ctxt_mensaje: JSON.parse(localStorage.getItem('jurisdiccional')).audiencia[0].data.ctxt_mensaje,
              dfec_fecha: Calendar.calendario11(JSON.parse(localStorage.getItem('jurisdiccional')).audiencia[0].data.dfec_fecha),
              nid: JSON.parse(localStorage.getItem('jurisdiccional')).audiencia[0].data.nid,
              isLoading:false
            });
            }else{
          this.props.history.push('/ultimasaudiencias');
        }
      }
    
      async actualizar(){  
        if(this.state.ctxt_mensaje != ''  && this.state.dfec_fecha != '' ){
          this.setState({isLoading: true})
          let fromData = new FormData();
          fromData.append('mensaje', this.state.ctxt_mensaje);
          fromData.append('fecha', this.state.dfec_fecha);
          fromData.append('nid',this.state.nid);
          axios.defaults.headers.common['Authorization'] = this.state.token
          axios.post(`${jurisdiccional}` + 'detalletexto/editarDetalletexto', fromData)
          .then((response) => {
            var datos = response.data
            if(datos.response != null){
              this.props.handleClick("tl", "success", "Registro actualizado correctamente!", "pe-7s-close")
                this.regresar();
            }else{
                this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
                this.setState({isLoading: false})
            }
            })
            .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
            }else{
                console.log(error);
                this.setState({isLoading: false})
            }
          });
        }else{
          this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close") 
        }
      }

      async regresar(){
        this.props.history.push('/ultimasaudiencias');
      }
    
      render() {
        return (
          <div className="content">
            <Grid fluid>
            <Row>
                <Col md={12}>
                  <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                  <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Editar Registro</h4>
                  {this.state.isLoading ? (
                          <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading}
                          />
                        ) : (
                        <div>
                           <div className="row">
                              <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-calendar"></i> FECHA</ControlLabel>
                                <FormControl type="date" value={this.state.dfec_fecha} onChange= {this.handleFecha}/>
                              </div>
                              <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel><i className="fa fa-envelope-o"></i> MENSAJE</ControlLabel>
                                <FormControl componentClass="textarea" style={{  height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.ctxt_mensaje} onChange= {this.handleMensaje} />
                              </div>
                            </div>
                            <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Actualización</Button>
                            <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.actualizar() }}>Actualizar Registro</Button>
                        </div>
                     )}
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        );
      }
    }
    
export default EditarUltimasAudiencias;