import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar";
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {sisperplus} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class AFP extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarAreas',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      codAfp:"",
      afpCard:"",
      afp:"",
      nid:""
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);

    this.handleCodAfp=this.handleCodAfp.bind(this)
    this.handleAfpCard  =this.handleAfpCard.bind(this);
    this.handleAfp =this.handleAfp.bind(this);
  }
    handleCodAfp(event) {this.setState({codAfp: event.target.value});}
    handleAfpCard(event) {this.setState({afpCard: event.target.value});}
    handleAfp(event) {this.setState({afp: event.target.value});}
 

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.listAFP();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listAFP() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'utilitarios/listAFP')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              descripcion: data.descripcion,
              afpCard: data.afpCard,
              fecha: Calendar.calendarioA(data.dfec_creacion),
              item: data.item,
              idafp: data.idafp,
              nid: data.nid,
            }) 
          })
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        
        this.setState({isLoading: false})
      }
    });
  }

  async save(){
    if(this.state.codAfp != '' && this.state.afp != '' ){
      if(this.state.nid == ''){
        this.guardar()
      }else{
        this.update()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  guardar(){
    this.setState({isLoading: true})
      let fromData = new FormData();
      fromData.append('idafp', this.state.codAfp);
      fromData.append('afpCard', this.state.afpCard);
      fromData.append('descripcion', this.state.afp.toUpperCase());
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${sisperplus}` + 'utilitarios/insertAFP', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.limpiar();
            this.props.handleClick("tl", "info", "El fondo de pensión ha sido registrado!", "pe-7s-smile")
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
          }else{
              console.log(error);
              this.setState({isLoading: false})
          }
        });
      }

    update(){
      this.setState({isLoading: true})
        let fromData = new FormData();
        fromData.append('nidafp', this.state.nid);
        fromData.append('idafp', this.state.codAfp);
        fromData.append('afpCard', this.state.afpCard);
        fromData.append('descripcion', this.state.afp.toUpperCase());
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'utilitarios/updateAFP', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
              this.limpiar();
              this.props.handleClick("tl", "info", "La registro ha sido actualizado!", "pe-7s-smile")
            }else{
              this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
              this.setState({isLoading: false})
          }
          })
          .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
            }else{
                console.log(error);
                this.setState({isLoading: false})
            }
          });
      }
    
    async limpiar(){
      this.listAFP();
      this.cancelar();
    }

    async cancelar(){
      this.setState({ codAfp: "", afp: "", afpCard:"", nid:""})
    }
    
    confirmarDelete = (data) => {
      confirmAlert({
        title: '¿Seguro que desea eliminar el registro?',
        message: 'Se anulará el registro seleccionado.',
        buttons: [
          {
            label: 'Aceptar',
            onClick: () => this.delete(data)
          },
          {
            label: 'Cancelar',
            onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
          }
        ]
      });
    };

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nidafp', data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'utilitarios/anularAFP', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listAFP()
        this.props.handleClick("tl", "info", "El registro ha sido eliminado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async regresar(){
    this.props.history.push("/administrarUtilitarios")
  }

  async obtener(data){
    this.setState({
      codAfp: data.idafp,
      afpCard: data.afpCard == null ? "" : data.afpCard,
      afp: data.descripcion,
      nid: data.nid,
      })
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Anular Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-black"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }
 
  render() {
    const columns = [
    {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "idafp", text: "CÓDIGO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "descripcion", text: "NOMBRE DEL AFP", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "afpCard", text: "AFP CARD ", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "fecha", text: "FECHA DE REGISTRO", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align: 'center'},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
      <Grid fluid>
      <Row>
          <Col md={12}>
            <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
            <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>LISTA DE AFP</h4>
            <div className="row" style={{marginBottom: 10}}>
              <div className="col-md-6" style={{marginBottom: 18}}>
                      <ControlLabel><i class="fa fa-map-o"></i> CÓDIGO DEL AFP</ControlLabel>
                      <FormControl type="text" value={this.state.codAfp} onChange= {this.handleCodAfp}/>
              </div>
              <div className="col-md-4" style={{marginBottom: 18}}>
                      <ControlLabel><i class="fa fa-map-o"></i> AFP CARD <a> (OPCIONAL)</a></ControlLabel>
                      <FormControl type="text" value={this.state.afpCard} onChange= {this.handleAfpCard}/>
              </div>
              <div className="col-md-10" style={{marginBottom: 18}}>
                      <ControlLabel><i class="fa fa-map"></i> NOMBRE DEL AFP</ControlLabel>
                      <FormControl type="text" value={this.state.afp} onChange= {this.handleAfp}/>
              </div>
              <div className="col-md-2" style={{ marginTop: -55}}>
                    <Button style={{width: 200, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.save() }}>GUARDAR</Button>
                    <Button style={{width: 200, marginBottom: 10}} bsStyle="danger" pullRight type="submit" onClick={() => { this.cancelar() }}>CANCELAR</Button>
              </div>
             </div> 
              
            
           {/* 
            <div className="row">
              <div className="col-md-6" style={{marginBottom: 18}}>
               
                  <Button style={{width: 250, marginTop: 5, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>

              </div>
            </div> */}
            {this.state.isLoading ? (
                    <ClipLoader
                      css={override}
                      sizeUnit={"px"}
                      size={50}
                      color={'#123abc'}
                      loading={this.state.loading}
                    />
                  ) : (
                    <PaginationProvider
                      pagination={
                        paginationFactory(options)
                      }
                    >
                      { contentTable }
                    </PaginationProvider>
               )}
            </div>
            <Button style={{width: 250, marginTop: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
          </Col>
        </Row>
      </Grid>
    </div>
    );
  }
}

export default AFP;