import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col , Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';

import { validator } from 'store/Apis'

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ReactExport from 'react-export-excel';
import Calendar from "components/Calendar/Calendar.jsx";

import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { sisper, sisperplus } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Marcaciones extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'marcaciones',
      nombre: JSON.parse(localStorage.getItem('datos'))[0].nombre,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      totTard: '',
      totExc: '',
      totRet: '',
      totSal: '',
      totPer: '',
      multiDataSet: [],
      marcaciones: [],
      listaDet: [],
      listaPap: [],
      listaTel:[],
      isLoading: false,
      isLoading3: false,
      fechaini: Calendar.calendario10(new Date().setDate(new Date().getDate() -7)),
      fechafin: moment(new Date()).format('YYYY-MM-DD'),
      anio: '',
      mes: '',
      years: [],
      meses: [],
    }

    this.handleFechaIni = this.handleFechaIni.bind(this);
    this.handleFechaFin = this.handleFechaFin.bind(this);
    this.estado = this.estado.bind(this);
    this.estado1 = this.estado1.bind(this);
    this.color = this.color.bind(this);
  }

  handleFechaIni(event) {
    this.setState({fechaini: event.target.value});
  }
  handleFechaFin(event) {
    this.setState({fechafin: event.target.value});
  }
  handleAnio = anio => {
    this.setState(
    { anio },
    );
  };

  handleMes = mes => {
    this.setState(
    { mes },
    );
  };


  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
            this.generarPeriodos();
           
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  generarPeriodos(){
    let year =  2020;
    let cont = 6;
    let a = Array.from(new Array(cont),(val, index) => index + year);
    let array = a.reverse()
    let arrayMes = [
      {"nid": "01", "descripcion": "ENERO"},
      {"nid": "02", "descripcion": "FEBRERO"},
      {"nid": "03", "descripcion": "MARZO"},
      {"nid": "04", "descripcion": "ABRIL"},
      {"nid": "05", "descripcion": "MAYO"},
      {"nid": "06", "descripcion": "JUNIO"},
      {"nid": "07", "descripcion": "JULIO"},
      {"nid": "08", "descripcion": "AGOSTO"},
      {"nid": "09", "descripcion": "SETIEMBRE"},
      {"nid": "10", "descripcion": "OCTUBRE"},
      {"nid": "11", "descripcion": "NOVIEMBRE"},
      {"nid": "12", "descripcion": "DICIEMBRE"},
    ]
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
                  })
        if(data == (new Date()).getFullYear())
          {
            this.setState({ anio: {value: cont2, label: data}})
          }
        })
    arrayMes.map((data) => {
        cont3++
        lista2.push({
         item: data.cont3,
          value: data.nid,
          label: data.descripcion,
                   })
        if(cont3 == (new Date()).getMonth() + 1)
        {
          this.setState({
          mes: {value: data.nid, label: data.descripcion}})
        }
    })
    this.setState({years: lista, meses: lista2})
    this.listarMarcaciones();
  }


  cambioFormat(fecha){
    var dia = fecha.substring(8,10);
    var mes = fecha.substring(5,7);
    var anio = fecha.substring(0,4);
    var newfecha = dia + '-' + mes + '-' + anio
    return newfecha
  }

  covertirHora(min){
    var h = min / 60 | 0
    var m = min % 60 | 0
    var time = h + ' h : ' + m + ' m'
    return time
  }

  generarExcel(lista, totTard, totExc, totRet, totSal, totPer){
    let dataExcel = [];
    lista.map((data) => {
      dataExcel.push(
        [
          {value: JSON.stringify(data.item), style: {font: {sz: "10.5", bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFFFFF00"}}, alignment: {horizontal: "center"}}},
          {value: data.fecha, style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.datebegin == null || data.datebegin == 0 ? '' : data.datebegin, style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          // {value: data.datebeginlounch == null ? '' : data.datebeginlounch, style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          // {value: data.dateendlounch == null ? '' : data.dateendlounch, style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.dateend == null || data.dateend == 0? '' : data.dateend, style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.late == null || data.late == 0? '' : JSON.stringify(data.late), style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          // {value: data.latelounch == null ? '' : JSON.stringify(data.latelounch), style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.previoushours == null || data.previoushours == 0 ? '' : JSON.stringify(data.previoushours), style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.hoursnotautorized == null || data.hoursnotautorized == 0 ? '' : JSON.stringify(data.hoursnotautorized), style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.restextrahours == null || data.restextrahours == 0 ? '' : JSON.stringify(data.restextrahours), style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.comments == null  ? '' : data.comments, style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}},
          {value: data.detail == null ? '' : data.detail, style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}}
      ]
      )
    });
    const dataSet = [
      {
        xSteps: 5,
        columns: ["REPORTE DE MARCACIONES Y TELETRABAJOS"],
        data: []
      },
      {
          columns: ["TRIBUNAL CONSTITUCIONAL DEL PERÚ"],
          data: [
              [{value: "RUC: 20217267618", style: {font: {sz: "10.5", bold: true}}}],
              [{value: "COLABORADOR: " + this.state.nombre, style: {font: {sz: "10.5", bold: true}}}]
          ]
      },
      {
          columns: ["ITEM", "FECHA", "HORA ING.", "HORA SAL.", "TARD", "RET.ANT.", "SAL.N.A.", "PERM.ADI.", "OBSERVACION", "DETALLE SAL.NO AUTORIZADAS"],
          data: dataExcel
      },
      {
          columns: ["TOTAL", "", "", "", "", "", totTard, totExc, totRet, totSal, totPer, "", ""],
          data: []
        },
    ];
    this.setState({
      marcaciones: lista, 
      multiDataSet: dataSet,
      totTard: totTard, 
      totExc: totExc, 
      totRet: totRet, 
      totSal: totSal, 
      totPer: totPer, 
      isLoading: false
    })
  }

  async detalleMarcas(fecha){
    this.setState({listaDet: [], isLoading2: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'marcaciones/detalleMarca?id=' + this.state.id + '&fecha=' + Calendar.calendario8(fecha))
    .then((res) => {
      let lista = [];
      let listaDet = res.data.response;
      if(listaDet != null){
        listaDet.map((data) => {
          lista.push(
            { CheckDate: Calendar.calendario9(data.CheckDate),
              descripcion: data.descripcion
          })
        });
        this.setState({listaDet: lista, isLoading2: false})
      }else{
        this.setState({listaDet: [], isLoading2: false})
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading2: false})
      }
    });
  }

  async detalleTeletrabajo(fecha){
    this.setState({listaTel: [], isLoading3: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'marcaciones/detalleTeletrabajo?id=' + this.state.id + '&fecha=' + Calendar.calendario8(fecha))
    .then((res) => {
      let lista = [];
      let listaTel = res.data.response;
      if(listaTel != null){
        listaTel.map((data) => {
          lista.push(
            { producto: data.producto,
              actividad: data.actividad
          })
        });
        this.setState({listaTel: lista, isLoading3: false})
      }else{
        this.setState({listaTel: [], isLoading3: false})
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading3: false})
      }
    });
  }

  async detallePapeleta(fecha){
    this.setState({listaPap: [], isLoading4: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'marcaciones/detallePapeleta?id=' + this.state.id + '&fecha=' + Calendar.calendario8(fecha))
    .then((res) => {
      let lista = [];
      let listaPap = res.data.response;
      if(listaPap != null){
        listaPap.map((data) => {
          lista.push(
            { descripcion: data.descripcion,
              observacion: data.observacion
          })
        });
        this.setState({listaPap: lista, isLoading4: false})
      }else{
        this.setState({listaPap: [], isLoading4: false})
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading4: false})
      }
    });
  }
  
  async listarMarcaciones() {
    if(this.state.fechaini != '' && this.state.fechafin != ''){
      let fechainicial = this.cambioFormat(this.state.fechaini)
      let fechafinal = this.cambioFormat(this.state.fechafin)
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${sisperplus}` + 'marcaciones/lista?id=' + this.state.id + '&fechaini=' + this.state.anio.label + '&fechafin=' + this.state.mes.value)
      .then((res) => {
        var totTard = 0
        var totExc = 0
        var totRet = 0
        var totSal = 0
        var totPer = 0
        let lista = [];
        let lista2 = res.data.response;
        
        if(lista2.length != 0){
          lista2.map((data) => {
        
            if (data.tardanza && typeof data.tardanza === 'string') {
                // Convierte la cadena en minutos y suma a totTard
                const partes = data.tardanza.split(":");
                const minutos = parseInt(partes[0]) * 60 + parseInt(partes[1]);
                totTard += minutos;
                console.log(totTard)
              }

            totExc = totExc + data.latelounch
            totRet = totRet + data.previoushours
            totSal = totSal + data.hoursnotautorized
            totPer = totPer + data.restextrahours
            lista.push(
              { comments: data.comments,
                datebegin: data.horainicio!= null ? data.horainicio : Calendar.calendario7(data.datebegin),
                datebeginlounch: Calendar.calendario7(data.datebeginlounch),
                dateend: data.horafinal!= null ? data.horafinal : Calendar.calendario7(data.dateend),
                dateendlounch: Calendar.calendario7(data.dateendlounch),
                detail: data.detail,
                fecha: data.fecha,
                fechacondia: data.fechacondia,
                hoursnotautorized: data.hoursnotautorized,
                item: data.item,
                late: data.late,
                latelounch: data.latelounch,
                previoushours: data.previoushours,
                restextrahours: data.restextrahours,
                tardanza: data.tardanza,
                papeleta: data.papeleta
               
            })
          });
          const horas = Math.floor(totTard / 60);
        const minutos = totTard % 60;
        const totTardFormat = `${horas} h : ${minutos} m`;
        this.generarExcel(lista, totTardFormat, this.covertirHora(totExc), this.covertirHora(totRet), this.covertirHora(totSal), this.covertirHora(totPer))
        }else{
          this.setState({marcaciones: [], isLoading: false})
       //  this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar los campos solicitados!", "pe-7s-info")
    }
  }

  estado(cell, row) {

    if(row != undefined){
      return (
          <div>
            { row.comments == "TELETRABAJO" ? (
              <Button bsStyle="success" fill type="submit" style={{width: 180}}>{row.comments}</Button>
             ) : row.comments == "FIN DE SEMANA" || row.comments == "FERIADO" ? (
              <Button  fill  type="submit" style={{width: 180, backgroundColor:'B6B1AD'}}>{row.comments}</Button>
             )
              : row.comments == "PRESENCIAL" ?
                <Button bsStyle="info" fill type="submit" style={{width: 180}}>{row.comments}</Button>
              : null
            }
          </div>
      );
    }else{}
  }

  estado1(cell, row) {

    if(row != undefined){
      return (
          <div>
            { row.papeleta == "PAPELETA" ? 
              <Button bsStyle="warning" fill type="submit" style={{width: 180}}>{row.papeleta}</Button>
              :  null
            }
          </div>
      );
    }else{}
  }
  color(cell, row) {

    if(row != undefined){
      return (
          <div>
            { row.tardanza != null ? 
           <span style={{color: 'red'}}>{row.tardanza}</span>  
              :  null
            }
          </div>
      );
    }else{}
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, footer: 'TOTAL', headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', footerAlign: 'center'},
      {dataField: "fechacondia", text: "FECHA", sort: true, footer: '', filter: textFilter(), headerStyle: {backgroundColor: '#337AB7',width:200, color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "datebegin", text: "INGRESO", sort: true, footer: '', headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "datebeginlounch", text: "INI.REF.", sort: true, footer: '', headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "dateendlounch", text: "FIN.REF.", sort: true, footer: '', headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "dateend", text: "SALIDA", sort: true, footer: '', headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tardanza", text: "TARDANZA", sort: true, footer: this.state.totTard, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', footerAlign: 'center',formatter: this.color},
      // {dataField: "latelounch", text: "EXC.REF.", sort: true, footer: this.state.totExc, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', footerAlign: 'center'},
      {dataField: "previoushours", text: "RET.ANT.", sort: true, footer: this.state.totRet, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', footerAlign: 'center'},
      {dataField: "hoursnotautorized", text: "SAL.NAUT.", sort: true, footer: this.state.totSal, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', footerAlign: 'center'},
      {dataField: "restextrahours", text: "PER.ADI.", sort: true, footer: this.state.totPer, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', footerAlign: 'center'},
      {dataField: "comments", text: "OBSERVACIÓN", sort: true, footer: '', headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.estado},//
      {dataField: "papeleta", text: "PAPELETA", sort: true, footer: '', headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.estado1},//
      // {dataField: "detail", text: "DET.SAL.NO AUTORIZADAS", sort: true, footer: '', headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.marcaciones.length
    };
    const columns2 = [{
      dataField: 'CheckDate',
      headerAlign: (column, colIndex) => 'center',
      text: 'Fecha'
    }, {
      dataField: 'descripcion',
      headerAlign: (column, colIndex) => 'center',
      text: 'Observación'
    }];

    const columns3 = [{
      dataField: 'producto',
      headerAlign: (column, colIndex) => 'center',
      text: 'Actividad'
     
    }, {
       dataField: 'actividad',
      headerAlign: (column, colIndex) => 'center',
      text: 'Producto'
    }];
    const columns4 = [
        {
            dataField: 'descripcion',
           headerAlign: (column, colIndex) => 'center',
           text: 'Descripcion'
         },{
        dataField: 'observacion',
        headerAlign: (column, colIndex) => 'center',
        text: 'Observacion'
       
      }];
    const expandRow = {
      onlyOneExpanding: true,
      renderer: row => (
        <div style={{textAlign: 'center'}}>
            {row.comments=="FERIADO" || row.comments=="FIN DE SEMANA"? 
            null
            :  row.comments=="TELETRABAJO"  ? 
            <div>
            <h4 style={{marginTop: 5, fontSize: 18}} className="title">{ `Detalle de Teletrabajo: ${row.fecha}` }</h4>
            <Button style={{width: 150, marginLeft: 10, marginBottom: 20}} bsStyle="success" fill type="submit" onClick={() => {this.detalleTeletrabajo(row.fecha)}}>Ver Detalle</Button> 
            {this.state.isLoading3 ? (
                <ClipLoader
                  css={override}
                  sizeUnit={"px"}
                  size={50}
                  color={'#123abc'}
                  loading={this.state.loading3}
                />
              ) : (
                <div className="row">
                
                  <div className="col-md-12">
                    <BootstrapTable keyField='item' data={ this.state.listaTel } columns={ columns3 } />
                  </div>
                </div>
            )}
          </div>
            : row.comments=="PRESENCIAL" ?

            <div>
              <h4 style={{marginTop: 5, fontSize: 18}} className="title">{ `Detalle de Marcación: ${row.fecha}` }</h4>
              <Button style={{width: 150, marginLeft: 10, marginBottom: 20}} bsStyle="info" fill type="submit" onClick={() => {this.detalleMarcas(row.fecha)}}>Ver Detalle</Button> 
              {this.state.isLoading2 ? (
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading2}
                  />
                ) : (
                  <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                      <BootstrapTable keyField='item' data={ this.state.listaDet } columns={ columns2 } />
                    </div>
                  </div>
              )}
                
            </div>: null
        }

{row.papeleta != ""  && (
        <div>
          <h4 style={{marginTop: 5, fontSize: 18}} className="title">{ `Detalle de Papeleta: ${row.fecha}` }</h4>
              <Button style={{width: 150, marginLeft: 10, marginBottom: 20}} bsStyle="warning" fill type="submit" onClick={() => {this.detallePapeleta(row.fecha)}}>Ver Detalle</Button> 
              {this.state.isLoading4 ? (
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading4}
                  />
                ) : (
                  <div className="row">
                   
                    <div className="col-md-12">
                      <BootstrapTable keyField='item' data={ this.state.listaPap } columns={ columns4 } />
                    </div>
                  </div>
              )}
                
        </div>
      )}
        </div>
      ),
      onExpand: (row, isExpand, rowIndex, e) => {
         if(this.state.listaDet.length != 0 || this.state.listaTel.length != 0|| this.state.listaPap.length != 0){
          setTimeout(
              function() {
                this.setState({listaDet: [], listaTel:[], listaPap:[]})
              }
              .bind(this),
              100
          );
         }
      },
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
        {/* <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div> */}
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.marcaciones }
              columns={ columns }
              expandRow={ expandRow }
              filter={ filterFactory() }
              // cellEdit={ cellEditFactory({ mode: 'click' }) }
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <Card
                title="Marcaciones del Colaborador"
                content={
                  <div >
                    {/* <FormInputs
                      ncols={["col-md-4", "col-md-4"]}
                      properties={[
                        {label: "FECHA DE INICIO", type: "date", bsClass: "form-control", value: this.state.fechaini, onChange: this.handleFechaIni},
                        {label: "FECHA DE TÉRMINO", type: "date", bsClass: "form-control", value: this.state.fechafin, onChange: this.handleFechaFin}
                      ]}
                    /> */}
                    <div className="row">
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>PERIODO</ControlLabel>
                            <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>MES</ControlLabel>
                            <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses}/>
                        </div>
                        <div className="col-md-6" style={{marginTop:22}} >
                        
                          {this.state.multiDataSet != '' ? (
                          <ExcelFile element={<Button style={{width: 150, marginLeft: 10}} bsStyle="success" pullRight fill type="submit">Descargar Excel</Button>}>
                              <ExcelSheet dataSet={this.state.multiDataSet} name="Organization"/>
                          </ExcelFile>
                          ) : (null)}
                          <Button style={{width: 150, marginLeft: 10}} bsStyle="info" pullRight fill type="submit"  disabled={this.state.isLoading} onClick={() => { this.listarMarcaciones() }}>Ver Marcaciones</Button>
                        </div>
                    </div> 
                    </div>}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Lista de Marcaciones</h4>
              <h4 className="category" style={{fontSize: 15, marginTop: -10}}>Para descargar el reporte en excel primero deberás realizar la consulta de tus marcaciones.</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
          <Row>
          <h5 style={{marginTop: 20, marginLeft: 15}}>Leyenda</h5>
              <Col md={6} style={{letterSpacing: 0.5}}>
                <Alert bsStyle="warning"><span>RET.ANT. = Retiro antes de horario de salida.</span></Alert>
                <Alert bsStyle="warning"><span>SAL.NAUT. = Periodo acumulado por salidas no autorizadas (Salidas no sustentadas con papeletas).</span></Alert>
                {/* <Alert bsStyle="info"><span>INI.REF. = Salida por Refrigerio.</span></Alert>
                <Alert bsStyle="info"><span>FIN.REF. = Retorno de Refrigerio.</span></Alert>
                <Alert bsStyle="info"><span>EXC.REF. = Exceso de tiempo en Refrigerio (Lo permitido es una hora máximo).</span></Alert> */}
                {/* <Alert bsStyle="info"><span>RET.ANT. = Retiro antes de horario de salida.</span></Alert> */}
              </Col>
              <Col md={6} style={{letterSpacing: 0.5}}>
              
                <Alert bsStyle="warning"><span>PER.ADL. = Permanencia adicional.</span></Alert>
                <Alert bsStyle="warning"><span>DET.SAL. NO AUTORIZADAS = Detalle en caso tenga salidas no autorizadas.</span></Alert>
              </Col>
            </Row>
        </Grid>
      </div>
    );
  }
}

export default Marcaciones;