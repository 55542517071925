import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';

import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import InputFiles from 'react-input-files';

import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { canaltv } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Horarios extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'canales',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      banners: [],
      horarios: [],
      file1: [],
      file2: [],
      canal: "",
      descripcion: "",
      oldarchivo1: "",
      oldarchivo2: "",
      archivo1: "",
      archivo2: "",
      nidcarga: "",
      estado: 0,
      isLoading: false,
      isLoading2: false,
      show: true,
      canal: '',
      nidcanal: '',
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);
    this.GetActionFormat3= this.GetActionFormat3.bind(this);

    this.handleDescripcion = this.handleDescripcion.bind(this);

  }

    handleDescripcion(event) {
        this.setState({descripcion: event.target.value});
    }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(localStorage.getItem("canal") !== null){
        this.setState({nidcanal: JSON.parse(localStorage.getItem('canal')).nidcanal, 
                       canal: JSON.parse(localStorage.getItem('canal')).titulo
                     });
        this.cargaView(1);
    }else{
      this.props.history.push('/canales');
    }
  }

  async cargaView(param) {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${canaltv}` + 'private/listhorarioadm')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { item: data.item,
                nid: data.nid,
                descripcion: data.ctxt_descripcion,
                archivo: data.cnom_archivo,
                ruta: data.ctxt_ruta
            })
          });
          this.setState({horarios: lista, isLoading: false})
          if(param == 1){
            this.listarBanneres();
          }
      }else{
        this.setState({horarios: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarBanneres() {
      this.setState({isLoading2: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${canaltv}` + 'private/listbannersxcanal?nid=' + this.state.nidcanal)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { item: data.item,
                nid: data.nid,
                archivo: data.ctxt_archivo,
                ruta: data.ctxt_ruta
            })
          })
          this.setState({banners: lista, isLoading2: false})
        }else{
          this.setState({banners: [], isLoading2: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async delete(data){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${canaltv}` + 'private/anularbanner?nidbanner=' + data.nid)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarBanneres();
        this.props.handleClick("tl", "info", "El banner ha sido anulado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async limpiar(param){
      if(param == 0){
        this.cargaView(0);
        this.cancelar(0);
        this.props.handleClick("tl", "info", "El horario ha sido registrado!", "pe-7s-smile")
      }else{
        this.listarBanneres();
        this.cancelar(1);
        this.props.handleClick("tl", "info", "El banner ha sido registrado!", "pe-7s-smile")
      }
  }

  async cancelar(param){
      if(param == 0){
        this.setState({descripcion: "", archivo1: "", file1: []})
      }else{
        this.setState({archivo2: "", file2: []})
      }
    
  }

  async guardar(param){
      if(param == 0){
        let fromData = new FormData();
        fromData.append('file', this.state.file1);
        fromData.append('descripcion', this.state.descripcion);
        fromData.append('archivo', this.state.archivo1);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${canaltv}` + 'private/insertarhorario', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
              this.limpiar(0);
          }else{
              this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
              this.setState({isLoading: false})
          }
          })
          .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
          }else{
              console.log(error);
              this.setState({isLoading: false})
          }
        });
      }else{
        let fromData = new FormData();
        fromData.append('file', this.state.file2);
        fromData.append('archivo', this.state.archivo2);
        fromData.append('canal', this.state.nidcanal);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${canaltv}` + 'private/insertarbanner', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
              this.limpiar(1);
          }else{
              this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
              this.setState({isLoading: false})
          }
          })
          .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
          }else{
              console.log(error);
              this.setState({isLoading: false})
          }
        });
      }
  }

  async save(param){
    if(param == 0){
        if(this.state.descripcion != "" && this.state.archivo1 != ""){
            this.guardar(0)
        }else{
            this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
        }
    }else{
        if(this.state.archivo2 != ""){
            this.guardar(1)
        }else{
            this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
        }
    }
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Remover Banner" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  GetActionFormat2(cell, row) {
    return (
          <div>
              {row.ruta == null || row.ruta == ""? (
              <p style={{fontSize: 14}}>SIN BANNER</p>) : (
              <span data-tip="Ver Banner" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.mostrar(row.ruta) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                <i className="fa fa-file-image-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              )}
          </div>
      );
  }

  GetActionFormat3(cell, row) {
    return (
          <div>
              {row.ruta == null || row.ruta == "" ? (
              <p style={{fontSize: 14}}>SIN HORARIO</p>) : (
              <span data-tip="Ver Horario" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.mostrar(row.ruta) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                <i className="fa fa-file-image-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              )}
          </div>
      );
  }

  async volver(){
    this.props.history.push('/canales');
  }

  async mostrar(ruta){
    window.open(ruta, "_blank")
  }

  subir = (files) => {
    if(files.length !== 0){
        this.setState({file1: files[0], archivo1: files[0].name});
    }
  }

  subir2 = (files) => {
    if(files.length !== 0){
        this.setState({file2: files[0], archivo2: files[0].name});
    }
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "descripcion", text: "DESCRIPCIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "archivo", text: "ARCHIVO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "archivo", text: "VER ARCHIVO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat3},
    ]
    const columns2 = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "archivo", text: "ARCHIVO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "archivo", text: "VER ARCHIVO", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},
      {dataField: "detail", text: "ANULAR", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.horarios.length
    };
    const options2 = {
        custom: true,
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'Primera',
        prePageText: 'Anterior',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        showTotal: true,
        totalSize: this.state.banners.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.horarios }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const contentTable2 = ({ paginationProps, paginationTableProps }) => (
        <div>
          <div>
            <div>
              <BootstrapTable
                striped
                hover
                keyField="item"
                data={ this.state.banners }
                columns={ columns2 }
                filter={ filterFactory() }
                noDataIndication="No existen registros para mostrar"
                { ...paginationTableProps }
              />
            </div>
            <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
          </div>
        </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Horario Semanal</h4>
              <div className="row">
                    <div className="col-md-4">
                        <ControlLabel>DESCRIPCIÓN</ControlLabel>
                        <FormControl type="text" value={this.state.descripcion} onChange= {this.handleDescripcion} style={{marginBottom: 18}}/>
                    </div>
                    <div className="col-md-4">
                        {this.state.archivo1 !== "" ? (
                          <div>
                            <ControlLabel>ARCHIVO</ControlLabel>
                            <Alert bsStyle="warning">
                              <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo1}</span>
                            </Alert>
                          </div>
                          ) : (
                          <div>
                            <ControlLabel>ARCHIVO</ControlLabel>
                            <Button style={{width: '100%', marginBottom: 18}} bsStyle="success" pullRight fill type="submit">
                              <InputFiles onChange={files => this.subir(files)}>Seleccionar el archivo</InputFiles>
                            </Button>
                          </div>
                        )}
                    </div>
                    <div className="col-md-4" style={{marginBottom: 18}}>
                        <ControlLabel>ACCIONES</ControlLabel><br></br>
                        <Button style={{width: 200, marginRight: 10}} bsStyle="info" fill type="submit" onClick={() => { this.save(0) }}>Guardar Archivo</Button>
                        <Button style={{width: 200}} bsStyle="danger" fill type="submit" onClick={() => { this.cancelar(0) }}>Limpiar o Cancelar</Button>
                    </div>
                </div>
                {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
                 <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Banners de Horarios del Canal: {this.state.canal}</h4>
                 <div className="row">
                    <div className="col-md-4">
                        {this.state.archivo2 !== "" ? (
                          <div>
                            <ControlLabel>BANNER</ControlLabel>
                            <Alert bsStyle="warning">
                            <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo2}</span>
                            </Alert>
                          </div>
                          ) : (
                          <div>
                            <ControlLabel>BANNER</ControlLabel>
                            <Button style={{width: '100%', marginBottom: 18}} bsStyle="success" pullRight fill type="submit">
                                <InputFiles onChange={files => this.subir2(files)}>Seleccionar el banner</InputFiles>
                            </Button>
                          </div>
                        )}
                    </div>
                    <div className="col-md-4" style={{marginBottom: 18}}>
                        <ControlLabel>ACCIONES</ControlLabel><br></br>
                        <Button style={{width: 200, marginRight: 10}} bsStyle="info" fill type="submit" onClick={() => { this.save(1) }}>Guardar Banner</Button>
                        <Button style={{width: 200}} bsStyle="danger" fill type="submit" onClick={() => { this.cancelar(1) }}>Limpiar o Cancelar</Button>
                    </div>
                </div>
                 {this.state.isLoading2 ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading2}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options2)
                        }
                      >
                        { contentTable2 }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
            <Button style={{width: 150, marginRight: 25}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.volver() }}>Volver</Button>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Horarios;