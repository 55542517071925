import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, fechasBusqueda,paramRRHH} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { confirmAlert } from 'react-confirm-alert';
import ReactExport from 'react-export-excel';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ConsultarPapeletasAdm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarPapeletas',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      anio: '',
      mes: '',
      years: [],
      meses: [],
      multiDataSet: [],
      isLoading: true
    }
    this.verdocumento= this.verdocumento.bind(this);
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.descargar= this.descargar.bind(this);
 }
  handleAnio = anio => {
    this.setState(
    { anio },
    );
  };
  handleMes = mes => {
    this.setState(
    { mes },
    );
  };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.generarPeriodos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
  generarPeriodos(){
    let year =  2000;
    let cont = 26;
    let array = Array.from(new Array(cont),(val, index) => index + year);
    let arrayMes = [
      {"nid": "01", "descripcion": "ENERO"},
      {"nid": "02", "descripcion": "FEBRERO"},
      {"nid": "03", "descripcion": "MARZO"},
      {"nid": "04", "descripcion": "ABRIL"},
      {"nid": "05", "descripcion": "MAYO"},
      {"nid": "06", "descripcion": "JUNIO"},
      {"nid": "07", "descripcion": "JULIO"},
      {"nid": "08", "descripcion": "AGOSTO"},
      {"nid": "09", "descripcion": "SETIEMBRE"},
      {"nid": "10", "descripcion": "OCTUBRE"},
      {"nid": "11", "descripcion": "NOVIEMBRE"},
      {"nid": "12", "descripcion": "DICIEMBRE"},
    ]
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    if(fechasBusqueda.papeletas[0].nidperiodo != "" && fechasBusqueda.papeletas[1].periodo != ""){
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
      })
      this.setState({anio: {
        value: fechasBusqueda.papeletas[0].nidperiodo, 
        label: fechasBusqueda.papeletas[1].periodo
      }})
    }else{
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
          this.setState({anio: {value: cont2, label: data}})
        }
      })
    }
    if(fechasBusqueda.papeletas[2].nidmes != "" && fechasBusqueda.papeletas[3].mes != ""){
      arrayMes.map((data) => {
        cont3++
        lista2.push({
            item: data.cont3,
            value: data.nid,
            label: data.descripcion,
        })
      })
      this.setState({mes: {
        value: fechasBusqueda.papeletas[2].nidmes, 
        label: fechasBusqueda.papeletas[3].mes, 
      }})
    }else{
      arrayMes.map((data) => {
        cont3++
        lista2.push({
            item: data.cont3,
            value: data.nid,
            label: data.descripcion,
        })
        if(cont3 == (new Date()).getMonth() + 1){
          this.setState({mes: {value: data.nid, label: data.descripcion}})
        }
      })
    }
    this.setState({years: lista, meses: lista2})
    this.listPapeletas()
 }

  async listPapeletas() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'papeletaAdm/listPapeletasAdm?anio=' + this.state.anio.label + '&mes=' + this.state.mes.value)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              fechaIni : Calendar.calendario9(data.FechaInicio),
              fechaFin: Calendar.calendario9(data.fechaFin),
              archivo: data.adjunto,
              anio: data.anio,
              tipopapeleta: data.descripcion,
              idtipopapeleta: data.tipopapeleta,
              documentcomments: data.documentcomments== null ? "":data.documentcomments, 
              estado: data.estado,
              flagjustifica: data.flagjustifica,
              idpapeleta: data.idpapeleta,
              mes: data.mes,
              observacion: data.observacion,
              nombres : data.personname,
              dni: data.DocumentNumber,
              fecharegistro : Calendar.calendario9(data.registerdate),
              item: data.item,
              idperson: data.person,
              ruta:"P"
            }) 
          }) 
          fechasBusqueda.papeletas[0].nidperiodo = this.state.anio.value
          fechasBusqueda.papeletas[1].periodo = this.state.anio.label
          fechasBusqueda.papeletas[2].nidmes = this.state.mes.value
          fechasBusqueda.papeletas[3].mes = this.state.mes.label
          localStorage.setItem('fechasBusqueda', JSON.stringify(fechasBusqueda))
          this.setState({movimientos: lista, isLoading: false})
          //// Para permitir exportar a excel
          this.generarExcel(lista);
        
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
    
  }

  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea eliminar la papeleta?',
      message: 'Se anulará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('pape', data.idpapeleta);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'papeletaAdm/deletePapeletaAdm', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response == null){
        this.listPapeletas()
        this.props.handleClick("tl", "info", "La papeleta ha sido eliminada!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async mostrar(data){
    let archivo = data.archivo;
    let anio = Calendar.calendario11(data.fecharegistro).substr(0,4);
    let mes = Calendar.calendario11(data.fecharegistro).substr(5,2);
    window.open("https://gestordocumentaltc.sedetc.gob.pe/index.php/s/ag7Dq3JcdRKr5Zg/download?path=%2F&files="  + anio + "/" + mes + "/" + archivo)
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
    return (
        <div>
            <span data-tip="Ver Papeleta" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20,float:"left"}} onClick={() => { this.ver(row) }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x text-primary"></i>
              <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>
            {row.estado == 'PENDIENTE' ? (
            <span  data-tip="Eliminar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x"></i>
              <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>) : (null)}
        </div>
    );
  }else{}
}


verdocumento(cell, row) {
  return (
        <div>
            {row.archivo == null ? 
            ( 
               <p style={{fontSize: 14,borderRadius: 2}}>{row.estado}</p>
            ) : (
              <Button data-tip="Ver Autorización" bsStyle="success" type="submit" onClick={() => { this.mostrar(row) }}>{row.estado}<ReactTooltip /></Button>
            )}
        </div>
    );
}
descargar(cell, row) {
  return (
        <div>
            {row.archivo == null ? 
            ( 
               <p style={{fontSize: 14,borderRadius: 2}}>{row.archivo}</p>
            ) : (
              <span data-tip="Descargar Autorización" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.download(row) }}>
              <ReactTooltip />
              <i className="fa fa-download fa-stack-1x fa-inverse" style={{fontSize: 25, color:"#1B4F72"}}></i>
            </span>
            )}
        </div>
    );
}


download(data){
 
  let anio = Calendar.calendario11(data.fecharegistro).substr(0,4)
  let mes =  Calendar.calendario11(data.fecharegistro).substr(5,2) 
  let archivo = data.archivo
  window.open("https://gestordocumentaltc.sedetc.gob.pe/index.php/s/ag7Dq3JcdRKr5Zg/download?path=%2F&files="+ anio + "/"+ mes +"/"+ archivo, "_blank")
}






async mostrar(data){
  let data2={file : data.archivo,
             anio : Calendar.calendario11(data.fecharegistro).substr(0,4),
             mes : Calendar.calendario11(data.fecharegistro).substr(5,2),
             "tipo" : "/adjuntos/",
             "estado" : "1"} 
             
  window.open('http://181.177.234.7/papeletas/adjuntos/' + data2.anio + "/" + data2.mes + "/" + data2.file)
  //  localStorage.setItem('visor', JSON.stringify(data2))
  //  this.props.history.push('/visorautorizacion');
 }



async ver(data){
  paramRRHH.papeletaAdm[1].enlace = "P"
  paramRRHH.trabajadoresAdm[1].enlace = "P"
  paramRRHH.papeletaAdm[0].data = data
  localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH))
  this.props.history.push('/verPapeletaAdm');
}

async detalle(){
  paramRRHH.papeletaAdm[1].enlace = "P"
  paramRRHH.trabajadoresAdm[1].enlace = "P"
  localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH))
  this.props.history.push('/crearPapeletaAdm');
}

generarExcel(lista){
  let dataExcel = [];
  lista.map((data) => {
    dataExcel.push(
      [          
        {value: JSON.stringify(data.item), style: {font: {sz: "10.5", bold: true}, alignment: {horizontal: "center"}}},
        {value: data.dni == null ? '' : data.dni},
        {value: data.nombres == null ? '' : data.nombres},
        {value: data.tipopapeleta == null ? '' : data.tipopapeleta},
        {value: data.fechaIni == null ? '' : data.fechaIni},
        {value: data.fechaFin == null ? '' : data.fechaFin},
        {value: data.fecharegistro == null ? '' : data.fecharegistro},  
        {value: data.estado == null ? '' : data.estado},
      ]
    )
  });
  const dataSet = [
    {
      xSteps: 5,
      columns: ["REPORTE DE PAPELETAS DE SALIDA"],
      data: []
    },
    {
      columns: ["TRIBUNAL CONSTITUCIONAL DEL PERÚ"],   
      data: []       
    },     
    {
      columns: ["ITEM", "DNI", "NOMBRES Y APELLIDOS", "TIPO DE PAPELETA", "FEC. INICIO", "FEC. TÉRMINO", "FEC. REGISTRO", "AUTORIZACIÓN"],
      data: dataExcel
    },     
  ];
  this.setState({
    movimientos: lista, 
    multiDataSet: dataSet,      
    isLoading: false
  })
}



  render() {
    const columns = [
      {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white",width:50}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "dni", text: "DNI", filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white",width:120}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nombres", text: "NOMBRES Y APELLIDOS",  filter: textFilter(),sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:250}, headerAlign: (column, colIndex) => 'center', align: 'justify'},
      {dataField: "tipopapeleta", text: "TIPO DE PAPELETA", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechaIni", text: "FEC. INICIO", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fechaFin", text: "FEC. TÉRMINO", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    //  {dataField: "observacion", text: "MOTIVO",  filter : textFilter(),sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:300}, headerAlign: (column, colIndex) => 'center', align: 'justify'},
      {dataField: "fecharegistro", text: "FEC. REGISTRO", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "estado", text: "AUTORIZACIÓN",  filter : textFilter(),sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.verdocumento},
      {dataField: "estado", text: "DESCARGAR",sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.descargar},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white", width:120}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              expandRow={ expandRow }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const expandRow = {
      onlyOneExpanding: true,
      renderer: row => (
        <div> 
          <div className="row">
            <div className="col-md-12">
              <p style={{fontWeight: 'bold', fontSize: 15}}>MOTIVO: <a style={{color: '#333'}}>{ row.observacion }</a></p>
            </div>
          </div>
        </div>
      )
    };
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Papeletas</h4>
              <div className="row">


                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>PERIODO</ControlLabel>
                    <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>MES</ControlLabel>
                    <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses}/>
                </div>

                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 200, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.listPapeletas() }}>Buscar Papeletas</Button>
                     <Button style={{width: 200, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.detalle() }}>Nueva Papeleta</Button>

                     <ExcelFile filename="ReportePapeletas" element={<Button style={{width: 200, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" pullRight fill type="submit">Exportar a Excel</Button>} >
                      <ExcelSheet dataSet={this.state.multiDataSet} name="Reporte">                      
                      </ExcelSheet>
                    </ExcelFile>
                    
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ConsultarPapeletasAdm;