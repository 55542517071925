import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus,fechasBusqueda,paramJurisdiccional} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Swal from 'sweetalert2'
import { confirmAlert } from 'react-confirm-alert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ListarEnviado extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'boletines',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer '+localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      cnum_dni: '',
      nidBoletin: '',
      nidPersona:'',
      item: "",
      flg_envio:"",
      nid:"",
      nombre: "",
      ctxt_correo : "",
      nidper: "",   
      isLoading: true,
      descripcion:"",
      enlace:"",
      fecha:""
    }
    this.GetActionFormat= this.GetActionFormat.bind(this); 
    this.estado= this.estado.bind(this);  
 }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{           
               this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(JSON.parse(localStorage.getItem("jurisdiccional")).boletin[0].data !== null){
        this.setState({
            nidBoletin: JSON.parse(localStorage.getItem('jurisdiccional')).boletin[0].data.nid,
            descripcion: JSON.parse(localStorage.getItem('jurisdiccional')).boletin[0].data.ctxt_descripcion,
            enlace: JSON.parse(localStorage.getItem('jurisdiccional')).boletin[0].data.ctxt_enlace,
            fecha: JSON.parse(localStorage.getItem('jurisdiccional')).boletin[0].data.ctxt_fecha,
            

        });
        this.listBoletinEnviado();
    }else{
      this.props.history.push('/boletines');
    }
  }

  async listBoletinEnviado(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'boletin/listBoletinEnvio?nidboletin='+this.state.nidBoletin)
    .then((res) => {                                                                                    
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({
              cnum_dni: data.cnum_dni,
              item: data.item,
              flg_envio: data.flg_envio,
              nidPersona: data.nid, 
              nombre: data.nombre,
              ctxt_correo: data.ctxt_correo             
            })
        })
          this.setState({movimientos: lista, isLoading: false})       
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
    .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
    });
  } 
  confirmarEnvio = (data) => {
    confirmAlert({
      title: '¿Seguro que desea enviar el boletín?',
      message: 'Se enviará el boletín seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.confirmar2(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  confirmar2 = (data) => {
    Swal.fire({
      title: "El correo se está enviando a " + data.nombre ,
      text: " Espere por favor...",
        showCancelButton: false,
        showCloseButton: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        },
        allowOutsideClick:false
      })
      this.sendMessageSingle(data)
    
    };
  

  async sendMessageSingle(data){   
    let fromData = new FormData();
    fromData.append('nidboletin', this.state.nidBoletin);
    fromData.append('descripcion', this.state.descripcion);
    fromData.append('enlace', this.state.enlace);
    fromData.append('fecha', this.state.fecha);
    fromData.append('dni', data.cnum_dni);
    fromData.append('nidper', data.nidPersona);
    fromData.append('nombre', data.nombre);
    fromData.append('correo',data.ctxt_correo);// "abigailgutierrez108@gmail.com"
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'boletin/insertEnviosIndividual', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Boletin enviado correctamente!", "pe-7s-close")
        this.listBoletinEnviado();
        Swal.close(); 
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema con el envío!", "pe-7s-close")
          this.setState({isLoading: false})
      }
        })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }
  
  async Cancelar(){
    this.props.history.push('/boletines');
   }

  GetActionFormat(cell, row) {
    if(row != undefined){
    return (
        <div>
            {row.flg_envio == 'NO ENVIADO'?(
              <span data-tip="Enviar Boletín" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarEnvio(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-warning"></i>
                <i className="fa fa-envelope-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span> 
              ):(
              <span data-tip="Boletín enviado" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}}>
                <ReactTooltip />
                <i className="fa fa-check-circle-o fa-stack-1x fa-inverse" style={{fontSize:37, color:"#5BD14B"}}></i>
              </span>               
              
              
              
              )}           
        </div> 
    );
  }else{}
}

estado(cell, row){
  return(
    <div>
       {row.flg_envio== 'ENVIADO' ?
        <Button style={{width: 115}} bsStyle="success" >ENVIADO</Button>    
      :
        <Button style={{width: 115}} bsStyle="danger" >NO ENVIADO</Button>
      }
    </div>
  )
}

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white",width:40}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nombre", text: "NOMBRE", sort: true, filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white",width:250}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "cnum_dni", text: "DNI", filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white",width:100}, headerAlign: (column, colIndex) => 'center', align: 'center'},   
      {dataField: "ctxt_correo", text: "CORREO", filter : textFilter(),sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center'},  
      {dataField: "flg_envio", text: "ESTADO", filter : textFilter(),sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.estado},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}    
  ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de personas</h4>
              <div className="row">
              
                <div className="col-md-6" style={{marginBottom: 18}}>
                <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.Cancelar() }}>Volver</Button>
                </div>
                <div style={{marginBottom: 18}}>            
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ListarEnviado;