import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import axios from 'axios';

import { validator } from 'store/Apis'
import { Card } from "components/Card/Card.jsx";
import { sisper, sisperplus } from 'store/Apis'

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import { PDFViewer, Page, Text, View, Document, StyleSheet, Font, Link, Image } from '@react-pdf/renderer';

import logo from "assets/img/imgtc.png";

const styles = StyleSheet.create({
    body: {
      paddingTop: 20,
      paddingBottom: 45,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      paddingBottom: 15,
    },
    author: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 14,
      margin: 12,
      marginTop: 20
    },
    subtitle2: {
        fontSize: 10,
        margin: 12,
        marginTop: 5
    },
    text: {
      margin: 12,
      fontSize: 12,
      fontFamily: 'Arial',
    },
    image: {
      height: 50,
      width: 80,
      marginBottom: 20,
      marginHorizontal: 210,
    },
    header: {
      fontSize: 12,
      color: 'grey',
      marginBottom: 15,
      textAlign: 'center',
    },
    footer: {
      position: 'absolute',
      fontSize: 12,
      bottom: 25,
      left: 35,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
});
  
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Trabajadores extends Component {

    state = {
      path: 'administrador',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      vista: [],
      general: [],
      isLoading: true
    }

async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.cargaView();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async cargaView(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'perfil/listAdministrador')
    .then((res) => {
       let lista = res.data.response
       this.setState({vistas: lista})
       this.listaAreas();
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listaAreas(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'administrador/listaAreas')
    .then((res) => {
      let lista = res.data.response;
      if(lista != null){
        this.listaPersonasAreas(lista);
      }else{
        this.setState({areas: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listaPersonasAreas(areas){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'administrador/listaPersonasAreas')
    .then((res) => {
      let lista = res.data.response;
      if(lista != null){
        this.agruparData(areas, lista)
      }else{
        this.setState({personas: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async agruparData(areas, personas){
      let dataTot = []
      areas.map((data) => { 
        let itemA = data.item  
        let nidArea = data.id
        let area = data.descripcion
        let personal = []
        personas.map((data2) => {
            if(data2.ReplicationUnit == nidArea){
                personal.push(
                    {item: data2.item, dni: data2.dni, nombre: data2.nombre}
                )
            }
        });
        dataTot.push(
            {item: itemA, descripcion: area, persona: personal},
        )
        personal = []
      });
      console.log(dataTot[1])
      console.log(dataTot[2])
      this.setState({general: dataTot, isLoading: false})
  }
  
  render() {
    const MyDocument = () => (
        <PDFViewer style={{width: '100%', height: 1200}}>
            <Document>
            <Page style={styles.body} wrap>
                {/* <Text style={styles.header} fixed>
                    ~ Tribunal Constitucional del Perú ~
                </Text> */}
                <Image
                    style={styles.image}
                    src={logo}
                />
                <Text style={styles.title}>Reporte de Trabajadores por Área</Text>
                    {this.state.general.map((data, i) => {
                        return (
                            <View>
                                <Text style={styles.subtitle}>{data.item + '. ' + data.descripcion + ':'}</Text>
                                {data.persona.map((data, i) => {
                                    return (
                                        <Text style={styles.subtitle2}>{data.item + '  -  ' + data.dni + '  -  ' + data.nombre}</Text>
                                    );
                                })}
                            </View>
                        );
                    })}
                <Text
                    style={styles.footer}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </Page>
            </Document>
        </PDFViewer>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
                {!this.state.isLoading ? (
                <Card
                    title="Trabajadores por Área"
                    content={
                        <MyDocument/>
                    }/>
                ):(null)}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Trabajadores;