import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Route,Switch, Redirect} from 'react-router-dom'
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import InputFiles from 'react-input-files';

import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";
import store from "store/Store.jsx";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { concursos } from 'store/Apis'

import moment from 'moment'
import swal from 'sweetalert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Programas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'programas',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      selectedOption: {value: "0", label: "VIRTUAL"},
      selectedOption2: {value: "0", label: "NUEVA"},
      concursos: [],
      estadosconcurso: [{value: "0", label: "NUEVA"},{value: "1", label: "EN PROCESO"},{value: "2", label: "DESIERTA"},{value: "3", label: "FINALIZADA"},{value: "4", label: "SUSPENDIDA"}],
      tiposmodalidad: [{value: "0", label: "VIRTUAL"},{value: "1", label: "PRESENCIAL"}],
      concurso: "",
      coordinador: "",
      periodo: new Date().getFullYear(),
      mes: "",
      nidcarga: "",
      estado: 0,
      isLoading: true,
      show: true,
      fechaini: moment(new Date()).format('YYYY-MM-DD'),
      fechafin: moment(new Date()).format('YYYY-MM-DD'),
      fechainipostulacion: moment(new Date()).format('YYYY-MM-DD'),
      fechafinpostulacion: moment(new Date()).format('YYYY-MM-DD')
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);

    this.handleConcurso = this.handleConcurso.bind(this);
    this.handleCoordinador = this.handleCoordinador.bind(this);
    this.handlePeriodo = this.handlePeriodo.bind(this);
    this.handleMes = this.handleMes.bind(this);

    this.handleFechaIni = this.handleFechaIni.bind(this);
    this.handleFechaFin = this.handleFechaFin.bind(this);
    this.handleFechaIniPostulacion = this.handleFechaIniPostulacion.bind(this);
    this.handleFechaFinPostulacion = this.handleFechaFinPostulacion.bind(this);

  }

    handleConcurso(event) {
        this.setState({concurso: event.target.value});
    }

    handleCoordinador(event) {
        this.setState({coordinador: event.target.value});
    }

    handlePeriodo(event) {
        this.setState({periodo: event.target.value});
    }

    handleMes(event) {
        this.setState({mes: event.target.value});
    }

    handleChange2 = selectedOption2 => {
      this.setState(
      { selectedOption2 },
      );
    };

    handleChange = selectedOption => {
        this.setState(
        { selectedOption },
        );
    };

    handleFechaIni(event) {
        this.setState({fechaini: event.target.value});
    }
    handleFechaFin(event) {
        this.setState({fechafin: event.target.value});
    }
    handleFechaIniPostulacion(event) {
        this.setState({fechainipostulacion: event.target.value});
    }
    handleFechaFinPostulacion(event) {
        this.setState({fechafinpostulacion: event.target.value});
    }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.calcularMes();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async calcularMes(){
    let mesx = new Date().getMonth() + 1;
    if(mesx == 13){
      this.setState({mes: '1'})
    }else{
      this.setState({mes: mesx})
    }
    this.listarPasantias();
  }

  formatoMes(mes){
    if(mes <= 9){
      mes = '0' + mes
    }else if(mes >= 13){
      mes = '01'
    }
    return mes
  }

  async listarPasantias() {
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${concursos}` + 'private/listpasantiasx?anio=' + this.state.periodo)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { item: data.item,
                nidconcurso: data.nid,
                nidtipoconcurso: data.nid_tipo,
                concurso: data.ctxt_descripcion,
                coordinador: data.ctxt_coordinador,
                modalidad: data.ctxt_modalidad,
                estado: data.ctxt_estado,
                tipo: data.ctxt_tipo,
                anio: data.cnum_anio,
                mes: data.cnum_mes,
                fecha: Calendar.calendario9(data.dfec_iniciopostulacion),
                fecha2: Calendar.calendario9(data.dfec_finalpostulacion),
                fecha3: Calendar.calendario10(data.dfec_iniciopostulacion),
                fecha4: Calendar.calendario10(data.dfec_finalpostulacion),
                fecha5: data.dfec_inicio,
                fecha6: data.dfec_final,
                publicado: data.flg_publicado
            })
          })
          this.setState({concursos: lista, isLoading: false})
          this.cancelar();
        }else{
          this.setState({concursos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async delete(data){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${concursos}` + 'private/removerpasantia?nid=' + data.nidconcurso + '&anio=' + data.anio + '&mes=' + data.mes)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarPasantias();
        this.props.handleClick("tl", "info", "La pasantía ha sido eliminada!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async publicar(data){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${concursos}` + 'private/publicarpasantia?nid=' + data.nidconcurso)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarPasantias();
        this.props.handleClick("tl", "info", "La pasantía ha sido publicada!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al publicar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

 async limpiar(){
    this.listarPasantias();
    this.cancelar();
    this.props.handleClick("tl", "info", "La pasantía ha sido registrada!", "pe-7s-smile")
  }

  async cancelar(){
    this.setState({concurso: "", coordinador: "", nidcarga: ""})
  }
  
  async obtener(data){
    localStorage.setItem('pasantia', JSON.stringify(data))
    this.props.history.push('/postulantesPas');
  }

  async update(){
    let fromData = new FormData();
    fromData.append('fechaini', this.state.fechaini);
    fromData.append('fechafin', this.state.fechafin);
    fromData.append('fechainipostulacion', this.state.fechainipostulacion);
    fromData.append('fechafinpostulacion', this.state.fechafinpostulacion + ' 16:45:00');
    fromData.append('descripcion', this.state.concurso);
    fromData.append('coordinador', this.state.coordinador);
    fromData.append('modalidad', this.state.selectedOption.label);
    fromData.append('estado', this.state.selectedOption2.label);
    fromData.append('anio', this.state.periodo);
    fromData.append('mes', this.formatoMes(this.state.mes));
    fromData.append('nids', this.state.nidcarga);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${concursos}` + 'private/editarpasantia', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
          this.limpiar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al editar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async guardar(){
      let fromData = new FormData();
      fromData.append('fechaini', this.state.fechaini);
      fromData.append('fechafin', this.state.fechafin);
      fromData.append('fechainipostulacion', this.state.fechainipostulacion);
      fromData.append('fechafinpostulacion', this.state.fechafinpostulacion + ' 16:45:00');
      fromData.append('descripcion', this.state.concurso);
      fromData.append('coordinador', this.state.coordinador);
      fromData.append('modalidad', this.state.selectedOption.label);
      fromData.append('estado', this.state.selectedOption2.label);
      fromData.append('anio', this.state.periodo);
      fromData.append('mes', this.formatoMes(this.state.mes));
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${concursos}` + 'private/insertarpasantia', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.limpiar();
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
      });
  }

  async editar(datos){
    var mes = datos.mes;
    if(mes.substr(0,1) == '0'){
      mes = mes.substr(1,1)
    }
    this.setState({
      selectedOption: {value: '', label: datos.modalidad},
      selectedOption2: {value: '', label: datos.estado},
      concurso: datos.concurso,
      coordinador: datos.coordinador,
      periodo: datos.anio,
      mes: mes,
      nidcarga: datos.nidconcurso,
      fechainipostulacion: datos.fecha3,
      fechafinpostulacion: datos.fecha4,
      fechaini: datos.fecha5,
      fechafin: datos.fecha6
    })
  }

  async buscar(){
    swal({
      title: "Búsqueda de Pasantías",
      text: 'Ingresa el año de búsqueda',
      content: "input",
      button: {
        text: "Buscar",
        closeModal: false,
      },
    })
    .then((value) => {
      if(value == null){
        swal.close();
      }else{
        this.setState({periodo: value})
        this.listarPasantias()
        swal.close();
      }
    });
  }

  async obtener(data){
    localStorage.setItem('concurso', JSON.stringify(data))
    this.props.history.push('/postulantesPas');
  }

  async detalle(data){
    localStorage.setItem('concurso', JSON.stringify(data))
    this.props.history.push('/detPrograma');
  }

  async documentos(data){
    localStorage.setItem('concurso', JSON.stringify(data))
    this.props.history.push('/documentacion');
  }

  async save(){
    if(this.state.concurso != "" && this.state.periodo != "" && this.state.mes != ""){
      if(this.state.nidcarga == ''){
        this.guardar()
      }else{
        this.update()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              {row.publicado == '1' ? (
              <span data-tip="Ver Detalle" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-info"></i>
                <i className="fa fa-folder-open-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
              {row.publicado == '1' ? (
              <span data-tip="Ver Documentación" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.documentos(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-warning"></i>
                <i className="fa fa-file-pdf-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
              {row.publicado == '1' ? (
              <span data-tip="Ver Postulantes" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-graduation-cap fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
              {row.publicado == '0' ? (
              <span data-tip="Publicar Pasantía" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.publicar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                <i className="fa fa-paper-plane fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
              <span data-tip="Editar Pasantía" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              {row.publicado == '0' ? (
              <span data-tip="Remover Pasantía" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>) : (null)}
          </div>
      );
    }else{}
  }

  render() {
    const { selectedOption, selectedOption2 } = this.state;
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "estado", text: "ESTADO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "concurso", text: "CONCURSO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "coordinador", text: "COORDINADOR", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "modalidad", text: "MODALIDAD", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "anio", text: "PERIODO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "mes", text: "MES", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha5", text: "FECHA DE INICIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha6", text: "FECHA DE TÉRMINO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha", text: "INICIO DE POSTULACIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha2", text: "FINAL DE POSTULACIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.concursos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.concursos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Pasantías Públicados</h4>
              <div className="row" style={{marginBottom: 10}}>
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-6" style={{marginBottom: 18}}>
                        <ControlLabel>PROGRAMA DE EXTENSIÓN</ControlLabel>
                        <FormControl type="text" value={this.state.concurso} onChange= {this.handleConcurso}/>
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel>ESTADO DEL PROGRAMA</ControlLabel>
                        <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.estadosconcurso}/>
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE INICIO</ControlLabel>
                        <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaIni}/>
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                        <FormControl type="date" value={this.state.fechafin} onChange= {this.handleFechaFin}/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4" style={{marginBottom: 18}}>
                        <ControlLabel>COORDINADOR</ControlLabel>
                        <FormControl type="text" value={this.state.coordinador} onChange= {this.handleCoordinador}/>
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel>MODALIDAD</ControlLabel>
                        <Select value={selectedOption} onChange={this.handleChange} options={this.state.tiposmodalidad}/>
                    </div>
                    <div className="col-md-1" style={{marginBottom: 18}}>
                        <ControlLabel>PERIODO</ControlLabel>
                        <FormControl type="number" value={this.state.periodo} onChange= {this.handlePeriodo} disabled/>
                    </div>
                    <div className="col-md-1" style={{marginBottom: 18}}>
                        <ControlLabel>MES</ControlLabel>
                        <FormControl type="number" value={this.state.mes} onChange= {this.handleMes} disabled/>
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel>INICIO DE POSTULACIÓN</ControlLabel>
                        <FormControl type="date" value={this.state.fechainipostulacion} onChange= {this.handleFechaIniPostulacion}/>
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel>FINAL DE POSTULACIÓN</ControlLabel>
                        <FormControl type="date" value={this.state.fechafinpostulacion} onChange= {this.handleFechaFinPostulacion}/>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                      <Button style={{width: 150, marginRight: 10, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.buscar() }}>Buscar</Button>
                      <Button style={{width: 150, marginRight: 10, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.save() }}>Guardar</Button>
                      <Button style={{width: 150, marginRight: 10, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Limpiar</Button>
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Programas;