import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, gestor,paramRRHH} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { confirmAlert } from 'react-confirm-alert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class VerVacacionesTrabajador extends Component {

    constructor(props) {
        super(props);
        this.state = {
          path: 'administrarVacaciones',
          usuario: localStorage.getItem('nickname'),
          token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
          id: JSON.parse(localStorage.getItem('datos'))[0].Person,
          movimientos: [],
          person:"",
          nombre:"",
          isLoading: true,  
        }
        this.icon= this.icon.bind(this);
        this.GetActionFormat= this.GetActionFormat.bind(this);
     }

      async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{
                    this.validarLocal()
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
      }

      async validarLocal(){ 
        if(JSON.parse(localStorage.getItem("recursoshumanos")).trabajadoresAdm[0].data !== null){
              this.setState({
                      person: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.person ,
                      dni: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.documentNumber,
                      nombre: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.nombreCompleto,
                      ruta: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[0].data.ruta,
                      enlace: JSON.parse(localStorage.getItem('recursoshumanos')).trabajadoresAdm[1].enlace,          
              });
              this.listVacaciones();
        }}
   
    
      async listVacaciones() {
        this.setState({isLoading: true})
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'vacaciones/listVacacionesTrabajador?persona=' + this.state.person)
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({ 
                    item: data.item,
                    person: data.Person,
                    fechainicio: Calendar.calendarioA(data.Datebegin),
                    fechafin : Calendar.calendarioA(data.Dateend),
                    dias: data.Days,
                    fechaC: Calendar.calendarioB(data.XLastDate),
                    secuencia: data.Secuence,
                    fileName:data.FileName,
                    fileRoot:data.FileRoot,
                    line:data.line
                }) 
              }) 
             this.setState({movimientos: lista, isLoading: false})
            }else{
              this.setState({movimientos: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
        
      }

    confirmarDelete = (data) => {
        confirmAlert({
          title: '¿Seguro que desea eliminar el registro?',
          message: 'Se eliminará el registro seleccionado.',
          buttons: [
            {
              label: 'Aceptar',
              onClick: () => this.delete(data)
            },
            {
              label: 'Cancelar',
              onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
            }
          ]
        });
    };
  
    async delete(data){
      this.setState({isLoading: true})
      let fromData = new FormData();
      fromData.append('persona', data.person);
      fromData.append('line', data.line);
      fromData.append('secuencia', data.secuencia);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${sisperplus}` + 'vacaciones/deleteDetalleVacaciones', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
           if(data.fileName==null){
                this.listVacaciones()
                this.props.handleClick("tl", "info", "El registro ha sido eliminado!", "pe-7s-smile")
           }else{
                this.removerArchivo(data,1)
           }
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
          this.setState({isLoading: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
    }
    
    removerArchivo(archivo,param){
        let anio = param ==1 ? (archivo.fechaC).substring(6,10) : (this.state.fechaC).substring(6,10)
        let mes = param ==1 ? (archivo.fechaC).substring(3,5) : (this.state.fechaC).substring(3,5)
        let fromData = new FormData();
        fromData.append('usuario', this.state.usuario);
        fromData.append('anio', anio);
        fromData.append('mes', mes);
        fromData.append('cnomArchivo', param ==1 ? archivo.fileRoot : this.state.fileRoot);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${gestor}` + 'document/bajarArchivoVac', fromData)
        .then((response) => {
          if(response.data.result == null){
            if(param==1){
              this.props.handleClick("tl", "info", "Ha sido eliminado con éxito!", "pe-7s-smile")
              this.listVacaciones()
            }else{
              this.props.handleClick("tl", "info", "El archivo ha sido eliminado con éxito!", "pe-7s-smile")
              this.setState({isLoadingFile:false})
            }    
          }else{
              this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
              this.setState({isLoading: false})
          }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
        });
    }
    
    async mostrar(data){
        console.log(data.fechaC.substring(8,12))
        let datosvisor = [{filename: data.fileName,
                          filesource: data.fileRoot,
                          mes: (data.fechaC).substring(3,5),
                          anio: (data.fechaC).substring(6,10),
                          }]
        window.open("https://gestordocumentaltc.sedetc.gob.pe/index.php/s/N7aJpcXjDyBAXNb/download?path=%2F&files="  + datosvisor[0].anio + "/" + datosvisor[0].mes + "/" + datosvisor[0].filesource)
      }
    
      GetActionFormat(cell, row) {
        if(row != undefined){
        return (
            <div>
                <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver(row) }}>
                  <ReactTooltip />
                  <i className="fa fa-circle fa-stack-2x text-primary"></i>
                  <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                </span>             
                <span  data-tip="Eliminar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                  <ReactTooltip />
                  <i className="fa fa-circle fa-stack-2x"></i>
                  <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                </span>
            </div>
        );
      }else{}
    }

  icon(cell, row) {
      if(row != undefined){
      return (
          <div>
            {row.fileRoot != null ?
            <span  className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20, marginRight: 5}} onClick ={()=>{this.mostrar(row)}} >
              <i className="fa fa-circle fa-stack-2x text-danger"></i>
              <i className="fa fa-file-pdf-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>
           : null }          
          </div>
      );
    }else{}
  }
     

  async ver(data){
      paramRRHH.trabajadoresAdm[1].enlace = "VT"
      paramRRHH.vacacionesxTrabajador[1].enlace = "VT"
      paramRRHH.vacacionesxTrabajador[0].data = data
      localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH))
      this.props.history.push('/vacacionesDetalle');
   }
    
  async detalle(){
      paramRRHH.trabajadoresAdm[1].enlace = "T"
      paramRRHH.vacaciones[1].enlace = "T"
      localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH))
      this.props.history.push('/vacacionesDetalle');
  }

  async regresar(){
      this.props.history.push('/administrarTrabajador');
  }
    
  render() {
    const columns = [
            {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "fechainicio", text: "FECHA INICIO", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "fechafin", text: "FECHA FIN", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "dias", text: "DIAS", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "fileRoot", text: "ARCHIVO ADJUNTO",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter:this.icon},
            {dataField: "fechaC", text: "FECHA DE CREACIÓN", filter : textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:120}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
          ]
    const options = {
          custom: true,
          paginationSize: 4,
          pageStartIndex: 1,
          firstPageText: 'Primera',
          prePageText: 'Anterior',
          nextPageText: 'Siguiente',
          lastPageText: 'Última',
          showTotal: true,
          totalSize: this.state.movimientos.length
    };
        const contentTable = ({ paginationProps, paginationTableProps }) => (
          <div>
            <div>
              <div>
                <BootstrapTable
                  striped
                  hover
                  keyField="item"
                  data={ this.state.movimientos }
                  columns={ columns }
                  filter={ filterFactory() }
                  noDataIndication="No existen registros para mostrar"
                  { ...paginationTableProps }
                />
              </div>
              <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
            </div>
          </div>
        );
        return (
          <div className="content">
            <Grid fluid>
            <Row>
                <Col md={12}>
                  <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                  <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Vacaciones de <a style={{fontWeight: "bold", color: "black",fontSize: 20}}>{this.state.nombre}</a></h4>
                  <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Ver Trabajadores</Button>                
                  <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.detalle() }}>Nueva Vacación</Button>             
              {this.state.isLoading ? (
                          <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading}
                          />
                        ) : (
                          <PaginationProvider
                            pagination={
                              paginationFactory(options)
                            }
                          >
                            { contentTable }
                          </PaginationProvider>
                     )}
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        );
      }
    }
    
export default VerVacacionesTrabajador;