import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {cec} from 'store/Apis'
import 'react-tabs/style/react-tabs.css';
import InputFiles from 'react-input-files';
import moment from 'moment'
import Select from 'react-select';
import 'react-accessible-accordion/dist/fancy-example.css';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class CrearNoticia extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'noticias',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      titulo : "", 
      descripcion:"",
      fecha:  moment(new Date()).format('YYYY-MM-DD'),
      inscripcion: "",
      transmision:"",
      informativo:"",
      estado: 0,
      isLoading: false,
      show: true,
      archivo: "",
      file: [],
      archivo2: "",
      file2: [],
      tipo :  {value : "n", label: "NOTA DE PRENSA"},
      tipos : [
              {value : "n", label: "NOTA DE PRENSA"},
              {value : "c", label: "COMUNICADO"}
              ],
      seccion : JSON.parse(localStorage.getItem("cec")).noticia[0].data==1 ? "ACTIVIDADES DEL CEC" : "ACTUALIDAD INTERNACIONAL",
      numseccion: JSON.parse(localStorage.getItem("cec")).noticia[0].data
    
    }
    this.handleDescripcion = this.handleDescripcion.bind(this);
    this.handleTitulo = this.handleTitulo.bind(this);
    this.handleFecha = this.handleFecha.bind(this);
    this.handleInscripcion = this.handleInscripcion.bind(this);
    this.handleTransmision = this.handleTransmision.bind(this);
    this.handleInformativo = this.handleInformativo.bind(this);
  }
  handleTipo = tipo => {
    this.setState(
        {tipo},
    );
  };
    handleDescripcion(event) {this.setState({descripcion: event.target.value});}
    handleTitulo(event) {this.setState({titulo: event.target.value});}
    handleFecha(event) {this.setState({fecha: event.target.value});}
    handleInscripcion(event) {this.setState({inscripcion: event.target.value});}
    handleTransmision(event) {this.setState({transmision: event.target.value});}
    handleInformativo(event) {this.setState({informativo: event.target.value});}
   
async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validar(){
   if(this.state.descripcion != '' && this.state.titulo != ''  && this.state.fecha != ''  && this.state.archivo != ''){
      if(this.state.tipo.value=="c" && this.state.archivo2 ==""){
        this.props.handleClick("tl", "error", "Adjunte el archivo del comunicado!", "pe-7s-close") 
      }else{
        this.agregar();
      }  
    }else{     
      this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close") 
    }
  }

  async agregar(){  
   
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('file', this.state.file);
    fromData.append('titulo', this.state.titulo);
    fromData.append('descripcion', this.state.descripcion);
    fromData.append('imagen', this.state.archivo)
    fromData.append('fecha', this.state.fecha);
    fromData.append('inscripcion', this.state.inscripcion=="" ? "--" : this.state.inscripcion);
    fromData.append('transmision', this.state.transmision=="" ? "--" : this.state.transmision);
    fromData.append('informativo', this.state.informativo=="" ? "--" : this.state.informativo);
    fromData.append('file2', this.state.file2  );
    fromData.append('archivo', this.state.archivo2 );
    fromData.append('seccion', this.state.numseccion);
    fromData.append('usuario',this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'noticias/insertarNoticia', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Noticia registrada correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async regresar(){
    this.props.history.push('/noticias');
  }

  subir = (files) => {
    if(files.length !== 0){
        this.setState({file: files[0], archivo: files[0].name});
    }
  }
  subir2 = (files2) => {
    if(files2.length !== 0){
        this.setState({file2: files2[0], archivo2: files2[0].name});
    }
  }
  async limpiar2(){
    this.setState({archivo2: "", file2: ""})
  }


  async limpiar(){
    this.setState({archivo: "", file: ""})
  }

  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>NUEVA PUBLICACIÓN</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                    <div>
                       <div className="row">
                            <div className="col-md-8" style={{marginBottom: 18}}>
                                <ControlLabel>TÍTULO</ControlLabel>
                                <FormControl type="text" value={this.state.titulo} onChange= {this.handleTitulo}/>
                            </div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA</ControlLabel>
                                <FormControl type="date" value={this.state.fecha} onChange= {this.handleFecha}/>
                            </div>
                       </div>
                        <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>DESCRIPCIÓN</ControlLabel>
                                <FormControl componentClass="textarea" style={{  height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.descripcion} onChange= {this.handleDescripcion} />
                            </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4" style={{marginBottom:18}}>
                            <ControlLabel>TIPO DE NOTICIA</ControlLabel>
                            <Select value={this.state.tipo} onChange={this.handleTipo} options={this.state.tipos}/>
                          </div>
                          <div className="col-md-4" style={{marginBottom:18}}>
                            <ControlLabel>SECCIÓN</ControlLabel>
                            <FormControl type="text" value={this.state.seccion}  disabled/>
                          </div>
                        </div>
                        <div className="row">
                        {this.state.tipo.value=="n"? 
                          <div>
                            <div className="col-md-4" style={{marginBottom: 18}}>
                                  <ControlLabel>LINK DE INSCRIPCIÓN</ControlLabel>
                                  <FormControl type="text" value={this.state.inscripcion} onChange= {this.handleInscripcion}/>
                              </div>
                              <div className="col-md-4" style={{marginBottom: 18}}>
                                  <ControlLabel>LINK DE TRANSMISIÓN</ControlLabel>
                                  <FormControl type="text" value={this.state.transmision} onChange= {this.handleTransmision}/>
                              </div>
                              <div className="col-md-4" style={{marginBottom: 18}}>
                                  <ControlLabel>LINK INFORMATIVO</ControlLabel>
                                  <FormControl type="text" value={this.state.informativo} onChange= {this.handleInformativo}/>
                            </div>
                          </div>
                          :
                          <div className="col-md-6" style={{marginBottom:18}}>
                                <ControlLabel>ARCHIVO ADJUNTO</ControlLabel>
                                <Alert bsStyle="info">
                                    <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo2}</span>
                                </Alert>
                                <Button style={{width: '100%', marginTop: -10}} bsStyle="info" pullRight fill type="submit">
                                    <InputFiles accept={'pdf/*'} onChange={files2 => this.subir2(files2)}>Dale click aquí para seleccionar el archivo</InputFiles>
                                </Button>
                                {this.state.archivo2 != "" ? (
                                  <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar2() }}>Limpiar Archivo</Button>
                                ) : ( 
                                  null
                                )}
                          </div>
                        }
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <ControlLabel>IMAGEN DE NOTICIA</ControlLabel>
                                <Alert bsStyle="success">
                                    <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo}</span>
                                </Alert>
                                <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                                    <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                                </Button>
                                {this.state.archivo != "" ? (
                                  <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar Archivo</Button>
                                ) : ( 
                                  null
                                )}
                            </div>
                        </div>
                        <div>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Registro</Button>
                          <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Guardar Registro</Button>
                        </div>
                   </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
export default CrearNoticia;