import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { jurisdiccional, paramJurisdiccional} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Evento extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'votaciones',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      nid:"",
      evento:"",
      isLoading: true
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);
    this.veropciones=this.veropciones.bind(this); 
    this.verListaVotante=this.verListaVotante.bind(this)

    this.handleEvento=this.handleEvento.bind(this); 
  }

  handleEvento(event) {this.setState({evento: event.target.value});}
  
  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
           this.listarEventos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
  async listarEventos() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${jurisdiccional}` + 'votaciones/listEvento')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
                evento: data.ctxt_evento,
                estado: data.flg_estado == '1' ? 'ACTIVO' : 'INACTIVO',
                item: data.item,
                nid: data.nid,
             })
          })
         this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

async guardar(){  
  if(this.state.evento != "" ){
  this.setState({isLoading: true})
  let fromData = new FormData();
  fromData.append('evento', this.state.evento);
  axios.defaults.headers.common['Authorization'] = this.state.token
  axios.post(`${jurisdiccional}` + 'votaciones/insertarEvento', fromData)
  .then((response) => {
    var datos = response.data
    if(datos.response != null){
        this.limpiar();
        this.props.handleClick("tl", "info", "El evento ha sido registrado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
        this.setState({isLoading: false})
    }
    })
    .catch((error) =>{ 
    if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
    }else{
        console.log(error);
        this.setState({isLoading: false})
    }
  });
}else {
this.props.handleClick("tl", "warning", "Debe completar el campo solicitado!", "pe-7s-info")
 }
}

confirmarDelete = (data) => {
  if(data.estado == 'INACTIVO')
  confirmAlert({
    title: '¿Seguro que desea habilitar el evento?',
    message: 'Se habilitará el registro seleccionado.',
    buttons: [
      {
        label: 'Aceptar',
        onClick: () => this.delete(data)
      },
      {
        label: 'Cancelar',
        onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
      }
    ]
  });
};


async delete(data){
this.setState({isLoading: true})
let fromData = new FormData();
fromData.append('nid', data.nid);
axios.defaults.headers.common['Authorization'] = this.state.token
axios.post(`${jurisdiccional}` + 'votaciones/manejarEvento', fromData)
.then((response) => {
  var datos = response.data
  if(datos.response != null){
    if(datos.response.estado == 1){
        this.props.handleClick("tl", "info", "El evento ha sido habilitado!", "pe-7s-smile")
        this.listarEventos();
    }else{
        this.props.handleClick("tl", "error", "No se puede activar el evento!", "pe-7s-close")
        this.setState({isLoading: false})
    }
  }else{
    this.props.handleClick("tl", "error", "Hubo un problema al activar!", "pe-7s-close")
    this.setState({isLoading: false})
  }
})
.catch((error) =>{ 
  if(auth0Client.isAuthenticated() === true){
    auth0Client.signOut()
    this.props.history.replace('/');
  }else{
    console.log(error);
    this.setState({isLoading: false})
  }
});
}



confirmarDelete2 = (data) => {
  if(data.estado == 'ACTIVO')
  confirmAlert({
    title: '¿Seguro que desea deshabilitar el evento?',
    message: 'Se deshabilitará el registro seleccionado.',
    buttons: [
      {
        label: 'Aceptar',
        onClick: () => this.delete2(data)
      },
      {
        label: 'Cancelar',
        onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
      }
    ]
  });
};


async delete2(data){
this.setState({isLoading: true})
let fromData = new FormData();
fromData.append('nid', data.nid);
axios.defaults.headers.common['Authorization'] = this.state.token
axios.post(`${jurisdiccional}` + 'votaciones/anularEvento', fromData)
.then((response) => {
  var datos = response.data
  if(datos.response != null){
    if(datos.response.estado == 1){
        this.props.handleClick("tl", "info", "El evento ha sido deshabilitado!", "pe-7s-smile")
        this.listarEventos();
    }else{
        this.props.handleClick("tl", "error", "No se puede anular el evento!", "pe-7s-close")
        this.setState({isLoading: false})
    }
  }else{
    this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
    this.setState({isLoading: false})
  }
})
.catch((error) =>{ 
  if(auth0Client.isAuthenticated() === true){
    auth0Client.signOut()
    this.props.history.replace('/');
  }else{
    console.log(error);
    this.setState({isLoading: false})
  }
});
}



async obtener(data, param){
  paramJurisdiccional.evento[0].data = data
localStorage.setItem('jurisdiccional', JSON.stringify(paramJurisdiccional))
  if(param == "O"){
    this.props.history.push('/opciones');
  }else{
    this.props.history.push('/listadoVotantes');
}
}


  veropciones(cell, row){
    if(row != undefined){
      return (
        <div>
          <span data-tip="Ver Opciones" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row, "O") }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x text-info"></i>
              <i className="fa fa-th-list fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
            </span>
           
        </div>
      );
    }else{}
  }
  verListaVotante(cell, row){
    if(row != undefined){
      return (
        <div>
           
            <span data-tip="Ver Lista de Votantes" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row, "V") }}>
              <ReactTooltip />
              <i className="fa fa-circle fa-stack-2x text-info"></i>
              <i className="fa fa-address-book-o fa-stack-1x fa-inverse" style={{fontSize: 20}}></i>
            </span>
           
        </div>
      );
    }else{}
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              {row.estado == 'INACTIVO'  ? (
              <span data-tip="Habilitar Evento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x" style={{ color:"green"}}></i>
                <i className="fa fa-check fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              ) : (
                <span data-tip="Deshabilitar Evento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete2(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x" ></i>
                <i className="fa fa-times  fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>



              )}
          </div>
      );
    }else{}
  }

  GetActionFormat2(cell, row) {
    return (
        <div>
            {row.estado == 'ACTIVO' ? (
            <Button bsStyle="success"  style={{width:100}} >{row.estado}</Button>
            ) : (
            <Button bsStyle="danger"   style={{width:100}}>{row.estado}</Button>
            )}
        </div>
    );
  }

  async limpiar(){
    this.listarEventos();
    this.cancelar();
  }

  async cancelar(){
    this.setState({ evento: "", nid: ""})
  } 
  
  async regresar(){
    this.props.history.push('/votaciones');
  }
  render() {

    const columns = [
    {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "evento", text: "EVENTO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "lista", text: "LISTA DE VOTANTES",   headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter:this.verListaVotante},
    {dataField: "opciones", text: "OPCIONES",  headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter:this.veropciones},
    {dataField: "estado", text: "ESTADO",headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length

    };        
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div >
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Eventos</h4>

              <div className="row" style={{marginBottom: 10}}>
                <div className="col-md-10">

                  <div className="row">
                    <div className="col-md-12" style={{marginBottom: 18}}>
                        <ControlLabel><i class="fa fa-align-justify"></i> Título de Evento</ControlLabel>
                        <FormControl type="text" value={this.state.evento} onChange= {this.handleEvento}/>
                    </div>
                  </div>
                </div>

                <div className="col-md-2">
                      <Button style={{width: 150, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.guardar() }}>Guardar Registro</Button>
                      <Button style={{width: 150, marginBottom: 10}} bsStyle="danger" pullRight type="submit" onClick={() => { this.cancelar() }}>Cancelar</Button>
                </div>
              
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }

                      </PaginationProvider>
                      
                 )}
              </div>
              <Button style={{width: 150, marginTop:15}} bsStyle="danger"  fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>    
                        
           </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Evento;