import React, { Component } from "react";
import {FormControl,ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, fechasBusqueda, pensionistas, sisperplus} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import Swal from 'sweetalert2'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ConsultarBoletasAdm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarBoletas',
      usuario: localStorage.getItem('nickname'),
      token2: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      anio: '',
      mes: '',
      years: [],
      meses: [],
      isLoading: true,
      ver : false,
      cnummes: "",
      cnumanio:"",
      mesLetra :""
    }
    this.verdocumento= this.verdocumento.bind(this);
    this.check = this.check.bind(this);
    this.check2 = this.check2.bind(this);
 }
    handleAnio = anio => {
      this.setState(
      { anio },
      );
    };

    handleMes = mes => {
      this.setState(
      { mes },
      );
    };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.generarPeriodos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
  generarPeriodos(){
    let year =  2000;
    let cont = 26;
    let array = Array.from(new Array(cont),(val, index) => index + year);
    let arrayMes = [
      {"nid": "01", "descripcion": "ENERO"},
      {"nid": "02", "descripcion": "FEBRERO"},
      {"nid": "03", "descripcion": "MARZO"},
      {"nid": "04", "descripcion": "ABRIL"},
      {"nid": "05", "descripcion": "MAYO"},
      {"nid": "06", "descripcion": "JUNIO"},
      {"nid": "07", "descripcion": "JULIO"},
      {"nid": "08", "descripcion": "AGOSTO"},
      {"nid": "09", "descripcion": "SETIEMBRE"},
      {"nid": "10", "descripcion": "OCTUBRE"},
      {"nid": "11", "descripcion": "NOVIEMBRE"},
      {"nid": "12", "descripcion": "DICIEMBRE"},
    ]
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    if(fechasBusqueda.pensionistas[0].nidperiodo != "" && fechasBusqueda.pensionistas[1].periodo != ""){
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
      })
      this.setState({anio: {
        value: fechasBusqueda.pensionistas[0].nidperiodo, 
        label: fechasBusqueda.pensionistas[1].periodo
      }})
    }else{
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
          this.setState({anio: {value: cont2, label: data}})
        }
      })
    }
    if(fechasBusqueda.pensionistas[2].nidmes != "" && fechasBusqueda.pensionistas[3].mes != ""){
      arrayMes.map((data) => {
        cont3++
        lista2.push({
            item: data.cont3,
            value: data.nid,
            label: data.descripcion,
        })
      })
      this.setState({mes: {
        value: fechasBusqueda.pensionistas[2].nidmes, 
        label: fechasBusqueda.pensionistas[3].mes, 
      }})
    }else{
      arrayMes.map((data) => {
        cont3++
        lista2.push({
            item: data.cont3,
            value: data.nid,
            label: data.descripcion,
        })
        if(cont3 == (new Date()).getMonth() + 1){
          this.setState({mes: {value: data.nid, label: data.descripcion}})
        }
      })
    }
    this.setState({years: lista, meses: lista2})
    this.listPensionistas()
 }

    async listPensionistas() {
        this.setState({isLoading: true, ver : false})
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'pensionistas/listPensionistas?anio=' + this.state.anio.label + '&mes=' + this.state.mes.value)
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
            lista2.map((data) => {
                lista.push({ 
                nombres: data.nombres,
                dni: data.dni,
                archivo: data.ruta,
                item: data.item,
                nid: data.nid,
                sms:data.sms,
                correo:data.correo,
                envio: Calendar.calendario9(data.envio)
                })
            })
            fechasBusqueda.pensionistas[0].nidperiodo = this.state.anio.value
            fechasBusqueda.pensionistas[1].periodo = this.state.anio.label
            fechasBusqueda.pensionistas[2].nidmes = this.state.mes.value
            fechasBusqueda.pensionistas[3].mes = this.state.mes.label
            localStorage.setItem('fechasBusqueda', JSON.stringify(fechasBusqueda))
            this.setState({movimientos: lista, isLoading: false})
            }else{
            this.setState({movimientos: [], isLoading: false})
            this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
      });
    }

    verdocumento(cell , row){
       if(row != undefined){
          return (
            <div>
              <span  data-tip="Ver Boleta" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(row.archivo) }}>
                <ReactTooltip />
                <i className="fa fa-file-pdf-o fa-3x" style={{fontSize: 35, color:"red"}}></i>
              </span>
            </div>
          )
        }
    }
        
    abrirImagen(archivo){
        window.open(  archivo, "_blank")
    } 

    check(cell, row){
      return(
          (row.sms ==1 ? 
            <div>
              <span  data-tip="Enviado" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} >
                <ReactTooltip />
                <i className="fa fa-check-circle fa-3x" style={{fontSize: 35, color:"green"}}></i>
              </span>
            </div>
          :
            <div>
              <span  data-tip="No enviado" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} >
                <ReactTooltip />
                <i className="fa fa-times-circle fa-3x" style={{fontSize: 35, color:"red"}}></i>
              </span>
            </div>
        ) ) 
    }

    check2(cell, row){
        return(
          (row.correo ==1 ? 
            <div>
              <span  data-tip="Enviado" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} >
                <ReactTooltip />
                <i className="fa fa-check-circle fa-3x" style={{fontSize: 35, color:"green"}}></i>
              </span>
             </div>
          :
             <div>
              <span  data-tip="No enviado" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} >
                <ReactTooltip />
                <i className="fa fa-times-circle fa-3x" style={{fontSize: 35, color:"red"}}></i>
              </span>
            </div>
         ))
    }

    async generar(){
        this.setState({ver : true})
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'pensionistas/listVerifica')
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({ 
                  anio: data.anio,
                  mes: data.mes,
                  mesN : Calendar.calendario13(data.mes)
                })
                 })
             this.setState({cnummes : lista[0].mes, cnumanio : lista[0].anio,mesLetra : lista[0].mesN, isLoading: false})
            }else{
              this.setState({movimientos: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            
            this.setState({isLoading: false})
          }
        });}

        confirmarEnvio = () => {
          confirmAlert({
            title: '¿Seguro que desea enviar las boletas?',
            message: 'Se enviarán las boletas a los pensionistas.',
            buttons: [
              {
                label: 'Aceptar',
                onClick: () => this.confirmar2()
              },
              {
                label: 'Cancelar',
                onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
              }
            ]
          });
        };
      
        confirmar2 = () => {
        Swal.fire({
            title: "Las boletas se están enviando",
            text: "Este proceso dura aproximadamente 5 minutos. Espere por favor...",
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading()
            },
            allowOutsideClick:false
          })
          this.envio()
         };

       async envio(){
            let fromData = new FormData();
            fromData.append('anio',this.state.cnumanio);
            fromData.append('mes', this.state.cnummes);
            axios.defaults.headers.common['Authorization'] = this.state.token
            axios.post(`${sisperplus}` + 'pensionistas/generarEnvio', fromData)
            .then((response) => {
              var datos = response.data
                if(datos.response != null){
                     this.props.handleClick("tl", "success", "Boletas enviadas correctamente!", "pe-7s-close")
                      this.generar();
                      Swal.close();  
                  }else{
                      this.props.handleClick("tl", "error", "Hubo un problema al enviar!", "pe-7s-close")
                      Swal.close(); 
                      this.setState({isLoading: false})
                  }
                })
                .catch((error) =>{ 
                  if(auth0Client.isAuthenticated() === true){
                      auth0Client.signOut()
                      this.props.history.replace('/');
                  }else{
                    // if(error.response.status!=200){
                       this.props.handleClick("tl", "error","Ocurrió un error inesperado o las boletas no han sido generadas")
                       Swal.close(); 
                       this.setState({isLoading: false})
                     }
                   // }
                });
              }
  
    regresar(){
        this.setState({ver:false})
    }

    async regresar2(){
      this.props.history.push('/administrarBoletas');
  }
  
  render() {
    const columns = [
    {dataField: "item", text: "ITEM",  headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "dni", text: "DNI", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "nombres", text: "NOMBRES Y APELLIDOS", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white", width:450}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "archivo", text: "BOLETA",  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', formatter : this.verdocumento, align:'center'},
    {dataField: "sms", text: "SMS",  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.check},
    {dataField: "correo", text: "CORREO", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.check2},
    {dataField: "envio", text: "FECHA DE ENVIO", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'}

    ]

    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>

            {this.state.ver==false ?    
            <div>
               <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Boletas de Pensionistas</h4>
               <div className="row"> 
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>PERIODO</ControlLabel>
                        <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                    </div>
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>MES</ControlLabel>
                        <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses}/>
                    </div>
                    <div className="col-md-6" style={{marginBottom: 18}}>
                        <ControlLabel></ControlLabel><br></br>
                        <Button style={{width: 230, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.listPensionistas() }}>Buscar Boletas</Button>
                        <Button style={{width: 230, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.generar() }}>Generar</Button>
                    </div>
                </div>
            </div>
                :
            <div>
                <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Generar Boletas de Pensionistas</h4>
                <div className="col-md-2" style={{marginBottom: 18}}>
                    <p style={{marginTop:30}}>PERIODO DISPONIBLE : </p>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>AÑO</ControlLabel>
                    <FormControl  type="text" value={this.state.cnumanio}  disabled/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>MES</ControlLabel>
                    <FormControl  type="text" value={this.state.mesLetra}   disabled/>
                </div>
                  <ControlLabel></ControlLabel><br></br>
                  <Button style={{width: 200, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.confirmarEnvio() }}>Enviar Boletas</Button>
                  <Button style={{width: 200, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
            </div>
            }
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                
                <div>
                    {this.state.ver==false ? 
                    <div>
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                      <Button style={{ width: 230, marginTop: 5, marginRight: 20, marginBottom: 10 }} bsStyle="danger" fill type="submit" onClick={() => { this.regresar2() }}>Regresar</Button>
                    </div>
                      :
                      null
                    }
                </div>
                    )
                }
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ConsultarBoletasAdm;