import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import Select from 'react-select';
import Autocomplete from "react-autocomplete-select";

import axios from 'axios';
import { paramTesoreria, tesoreria } from 'store/Apis'
import Calendar from "components/Calendar/Calendar.jsx";

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { saveAs } from 'file-saver';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class EditarComprobante extends Component {

  inputRef = React.createRef();
  inputRef2 = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      path: 'comprobantes',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      selectedOption: "",
      selectedOption2: "",
      selectedOption4: "",
      selectedOption5: "",
      selectedOption6: "",
      selectedOption7: "",
      selectedOption8: "",
      beneficiarios: [],
      conceptos: [],
      clasificadores: [],
      retenciones: [],
      conceptosCombo: [],
      estadistica: [],
      deducciones: [],
      codificaciones: [],
      metas: [],
      patrimonial: [],
      nidcarga: "",
      nidcarga2: "",
      movimiento: '',
      numero: '',
      fecha: '',
      usuariox: '',
      monto: '',
      monto2: '',
      cuentadebe: '',
      cuentahaber: '',
      importedebe: '',
      importehaber: '',
      cuentadebe2: '',
      cuentahaber2: '',
      importedebe2: '',
      importehaber2: '',
      liquido: '',
      totalgastos: '',
      totalretenciones: '',
      numerocaja: '',
      nombre: '',
      documento: '--',
      concepto: '',
      nididentificador: '',
      nidpresupuestal: '',
      nidlibro: '',
      ordenservicio: '',
      registrosiaf: '',
      apunta: true,
      apunta2: true,
      apunta3: true,
      isLoading1: true,
      isLoading2: true,
      isLoading3: true,
      isLoading4: true,
      isLoading5: true,
      isLoading6: true,
      isLoading7: true,
      showRet: false,
      beneficiariocheque: "",
      tipocheque: "",
      nidanterior: 0,
      nidsuperanterior: 0,
      nidsiguiente: 0,
      nidsupersiguiente: 0,
      tiposgiro: [
        {value: "CHEQUE", label: "CHEQUE"},
        {value: "CO.", label: "CARTA ORDEN"},
        {value: "O-S/CH.", label: "OPERACIONES SIN CHEQUE"},
        {value: "CE.", label: "CARTA ORDEN ELECTRÓNICA"},
        {value: "OPE.", label: "ORDEN DE PAGO ELECTRÓNICO"},
        {value: "CCI.", label: "TRANSFERENCIA A CUENTA DE TERCEROS (CCI)"},
        {value: "PE. AFP", label: "PAGO ELECTRÓNICO DE APORTES AFP"},
        {value: "--", label: "OTROS"} 
      ],
      tiposorden: [
        {value: "O/C.", label: "ORDEN DE COMPRA"},
        {value: "O/S.", label: "ORDEN DE SERVICIO"},
        {value: "PLA.", label: "PLANILLA"},
        {value: "--", label: "OTROS"} 
      ],
      selectedOption9: "",
      selectedOption10: "",
      nuevaretencion: ""
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.GetActionFormat2= this.GetActionFormat2.bind(this);
    this.GetActionFormat3= this.GetActionFormat3.bind(this);
    this.GetActionFormat4= this.GetActionFormat4.bind(this);

    this.handleFecha = this.handleFecha.bind(this);
    this.handleUsuario = this.handleUsuario.bind(this);
    this.handleMonto = this.handleMonto.bind(this);
    this.handleMonto2 = this.handleMonto2.bind(this);
    this.handleCuentadebe = this.handleCuentadebe.bind(this);
    this.handleCuentahaber = this.handleCuentahaber.bind(this);
    this.handleImportedebe = this.handleImportedebe.bind(this);
    this.handleImportehaber = this.handleImportehaber.bind(this);
    this.handleCuentadebe2 = this.handleCuentadebe2.bind(this);
    this.handleCuentahaber2 = this.handleCuentahaber2.bind(this);
    this.handleImportedebe2 = this.handleImportedebe2.bind(this);
    this.handleImportehaber2 = this.handleImportehaber2.bind(this);
    this.handleTotalgastos = this.handleTotalgastos.bind(this);
    this.handleTotalretenciones = this.handleTotalretenciones.bind(this);
    this.handleLiquido = this.handleLiquido.bind(this);
    this.handleNumerocaja = this.handleNumerocaja.bind(this);
    this.handleNombre = this.handleNombre.bind(this);
    this.handleDocumento = this.handleDocumento.bind(this);
    this.handleConcepto = this.handleConcepto.bind(this);
    this.handleOrdenservicio = this.handleOrdenservicio.bind(this);
    this.handleRegistrosiaf = this.handleRegistrosiaf.bind(this);
    this.handleNumero = this.handleNumero.bind(this);
    this.handleNuevaretencion = this.handleNuevaretencion.bind(this);
  }

  handleChange = selectedOption => {
    this.setState(
    { selectedOption },
    );
  };

  handleChange2 = selectedOption2 => {
    this.setState(
    { selectedOption2 },
    );
  };

  handleChange4 = selectedOption4 => {
    this.setState(
    { selectedOption4 },
    );
  };

  handleChange5 = selectedOption5 => {
    this.setState(
    { selectedOption5 },
    ); 
  };

  handleChange6 = selectedOption6 => {
    this.setState(
    { selectedOption6 },
    );
  };

  handleChange7 = selectedOption7 => {
    this.setState(
    { selectedOption7 },
    );
  };

  handleChange8 = selectedOption8 => {
    this.setState(
    { selectedOption8 },
    );
  };

  handleChange9 = selectedOption9 => {
    this.setState(
    { selectedOption9 },
    );
  };

  handleChange10 = selectedOption10 => {
    this.setState(
    { selectedOption10 },
    );
  };

  handleFecha(event) {
    this.setState({fecha: event.target.value});
  }

  handleUsuario(event) {
    this.setState({usuariox: event.target.value});
  }

  handleMonto(event) {
    this.setState({monto: event.target.value});
  }

  handleMonto2(event) {
    this.setState({monto2: event.target.value});
  }

  handleCuentadebe(event) {
    this.setState({cuentadebe: event.target.value});
  }

  handleCuentahaber(event) {
    this.setState({cuentahaber: event.target.value});
  }

  handleImportedebe(event) {
    this.setState({importedebe: event.target.value});
  }

  handleImportehaber(event) {
    this.setState({importehaber: event.target.value});
  }

  handleCuentadebe2(event) {
    this.setState({cuentadebe2: event.target.value});
  }

  handleCuentahaber2(event) {
    this.setState({cuentahaber2: event.target.value});
  }

  handleImportedebe2(event) {
    this.setState({importedebe2: event.target.value});
  }

  handleImportehaber2(event) {
    this.setState({importehaber2: event.target.value});
  }

  handleTotalgastos(event) {
    this.setState({totalgastos: event.target.value});
  }

  handleTotalretenciones(event) {
    this.setState({totalretenciones: event.target.value});
  }

  handleLiquido(event) {
    this.setState({liquido: event.target.value});
  }

  handleNumerocaja(event) {
    this.setState({numerocaja: event.target.value.toUpperCase()});
  }

  handleNombre(event) {
    this.setState({nombre: event.target.value});
  }

  handleDocumento(event) {
    this.setState({documento: event.target.value});
  }

  handleConcepto(event) {
    this.setState({concepto: event.target.value});
  }

  handleOrdenservicio(event) {
    this.setState({ordenservicio: event.target.value.toUpperCase()});
  }

  handleRegistrosiaf(event) {
    this.setState({registrosiaf: event.target.value});
  }

  handleNumero(event) {
    this.setState({numero: event.target.value});
  }

  handleNuevaretencion(event) {
    this.setState({nuevaretencion: event.target.value.toUpperCase()});
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.validarLocal();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(localStorage.getItem("movimientocomp") !== null){
        this.setState({movimiento: JSON.parse(localStorage.getItem('movimientocomp')).nidmovimiento});
        this.obtenerDatos();
    }else{
      this.ultimoMov()
      // this.props.history.push('/comprobantes');
    }
  }

  async ultimoMov() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listUltimoMovimiento?tipo=0')
    .then((res) => {
      let lista = res.data.response;
      this.setState({movimiento: lista[0].nid});
      this.obtenerDatos();
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async obtenerDatos(){
    if(paramTesoreria.clasificadores[0].data.length != 0){
      this.setState({
        clasificadores: paramTesoreria.clasificadores[0].data, 
        selectedOption4: {
          value: paramTesoreria.clasificadores[0].data[0].value, 
          label: paramTesoreria.clasificadores[0].data[0].label
        },
        metas: paramTesoreria.codificaciones[0].data, 
        selectedOption2: {
          value: paramTesoreria.codificaciones[0].data[0].value, 
          label: paramTesoreria.codificaciones[0].data[0].label
        },
        retenciones: paramTesoreria.retenciones[0].data, 
        conceptosCombo: paramTesoreria.retenciones[1].data2, 
        selectedOption: {
          value: paramTesoreria.retenciones[0].data[0].value, 
          label: paramTesoreria.retenciones[0].data[0].label
        }, 
        selectedOption8: {
          value: paramTesoreria.retenciones[1].data2[0].value, 
          label: paramTesoreria.retenciones[1].data2[0].label
        },
        beneficiarios: paramTesoreria.beneficiarios[0].data, 
        conceptos: paramTesoreria.conceptos[0].data, 
      })
      this.listarMovimiento(0, "")
    }else{
      this.listarClasificadores()
    }
  }

  async listarClasificadores() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listClasificadores?admin=0')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_descripcion,       
            })
        })
        this.setState({clasificadores: lista, selectedOption4: {value: lista[0].value, label: lista[0].label}})
        this.listarCodificaciones()
      }else{
        this.setState({clasificadores: []})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarCodificaciones() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listMetas')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_meta + " " + data.ctxt_fn + " " + data.ctxt_pf + " " + data.ctxt_spf + " " + data.ctxt_prg + " " + data.ctxt_actproy + " " + data.ctxt_comp + " " + data.ctxt_fin,       
            })
        })
        this.setState({metas: lista, selectedOption2: {value: lista[0].value, label: lista[0].label}})
        this.listarRetenciones()
      }else{
        this.setState({metas: []})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarRetenciones() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listRetenciones?admin=0')
    .then((res) => {
      let lista = [];
      let lista3 = [{value: 0, label: 'NINGUNO'}];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_descripcion,       
            })
          lista3.push(
            { value: data.nid,
              label: data.ctxt_descripcion,       
            })
        })
        this.setState({
          retenciones: lista, 
          conceptosCombo: lista3, 
          selectedOption: {value: lista[0].value, label: lista[0].label}, 
          selectedOption8: {value: lista3[0].value, label: lista3[0].label}
        })
        this.listarBeneficiarios()
      }else{
        this.setState({retenciones: [], conceptosCombo: []})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarBeneficiarios() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'beneficiarios/listBeneficiarios')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.cnum_documento + ' (' + data.ctxt_descripcion + ')', 
              tipo: data.nid_tipo,
              documento: data.cnum_documento,  
              clase: data.ctxt_descripcion    
            })
        })
        this.setState({beneficiarios: lista})
        this.listarConceptos()
      }else{
        this.setState({beneficiarios: []})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarConceptos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'beneficiarios/listConceptos')
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      if(lista2.length != 0){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_descripcion,       
            })
        })
        this.setState({conceptos: lista})
        this.listarMovimiento(0, "")
      }else{
        this.setState({conceptos: []})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async obtenerTipos(param1, param2){
    if(param1.substr(0,6) == "CHEQUE"){
      this.setState({
        selectedOption9: {
          value: "CHEQUE", 
          label: "CHEQUE"
        }
      })
    }else if(param1.substr(0,3) == "CO." || param1.substr(0,2) == "CO"){
      this.setState({
        selectedOption9: {
          value: "CO.", 
          label: "CARTA ORDEN"
        },
      })
    }else if(param1.substr(0,7) == "O-S/CH." || param1.substr(0,6) == "O-S/CH"){
      this.setState({
        selectedOption9: {
          value: "O-S/CH.", 
          label: "OPERACIONES SIN CHEQUE"
        },
      })
    }else if(param1.substr(0,3) == "CE." || param1.substr(0,2) == "CE"){
      this.setState({
        selectedOption9: {
          value: "CE.", 
          label: "CARTA ORDEN ELECTRÓNICA"
        },
      })
    }else if(param1.substr(0,4) == "OPE." || param1.substr(0,3) == "OPE"){
      this.setState({
        selectedOption9: {
          value: "OPE.", 
          label: "ORDEN DE PAGO ELECTRÓNICO"
        },
      })
    }else if(param1.substr(0,4) == "CCI." || param1.substr(0,3) == "CCI"){
      this.setState({
        selectedOption9: {
          value: "CCI.", 
          label: "TRANSFERENCIA A CUENTA DE TERCEROS (CCI)"
        },
      })
    }else if(param1.substr(0,7) == "PE. AFP" || param1.substr(0,2) == "AFP" || param1.substr(0,6) == "PE AFP"){
      this.setState({
        selectedOption9: {
          value: "PE. AFP", 
          label: "PAGO ELECTRÓNICO DE APORTES AFP"
        },
      })
    }else{
      this.setState({
        selectedOption9: {
          value: "--", 
          label: "OTROS"
        } 
      })
    }
    let cont2 = 0
    this.state.tiposorden.map((data) => {
      if(param2 != null && param2 != ""){
        if(data.value.substr(0,3) == param2.substr(0,3) || data.value == param2.substr(0,4)){
          cont2 = 1
          this.setState({
            selectedOption10: {
              value: data.value, 
              label: data.label
            }
          })
        }else{
          if(cont2 == 0){
            this.setState({
              selectedOption10: {
                value: "--", 
                label: "OTROS"
              }
            })
          }
        }
      }else{
        this.setState({
          selectedOption10: {
            value: "--", 
            label: "OTROS"
          }
        })
      }
    })
  }

  listarMovimiento(param1, param2) {
      let mov = ""
      if(param1 == 0){
        mov = this.state.movimiento;
      }else{
        mov = param2;
      }
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${tesoreria}` + 'comprobantes/listMovimientoxNid?nidmovimiento=' + mov + '&tipo=0')
      .then((res) => {
        let lista = res.data.response;
        let listestadistica = [];
        let listpatrimonial = [];
        let listdeducciones = [];
        let listcodificaciones = [];
        if(lista != null){
              this.setState({
                nidsupersiguiente: lista.listMovimientoxNid[0].supersiguiente,
                nidsiguiente: lista.listMovimientoxNid[0].nidsiguiente,
                nidanterior: lista.listMovimientoxNid[0].nidanterior, 
                nidsuperanterior: lista.listMovimientoxNid[0].superanterior,  
                nidmovimiento: lista.listMovimientoxNid[0].nid,
                numero: lista.listMovimientoxNid[0].cnum_numero,
                numerocaja: lista.listMovimientoxNid[0].ctxt_cheque_carta != null && lista.listMovimientoxNid[0].ctxt_cheque_carta != "" ? lista.listMovimientoxNid[0].ctxt_cheque_carta : "",
                ordenservicio: lista.listMovimientoxNid[0].cnum_orden != null && lista.listMovimientoxNid[0].cnum_orden != "" ? lista.listMovimientoxNid[0].cnum_orden : "",
                nidlibro: lista.listMovimientoxNid[0].nidlibro,
                registrosiaf: lista.listMovimientoxNid[0].cnum_siaf,
                // documento: lista.listMovimientoxNid[0].cnum_documento,
                // nombre: lista.listMovimientoxNid[0].ctxt_nombre,
                // concepto: lista.listMovimientoxNid[0].ctxt_concepto,
                totalgastos: lista.listMovimientoxNid[0].cnum_total_gastos != null ? 'S/.    ' + lista.listMovimientoxNid[0].cnum_total_gastos.toFixed(2) : 'S/. 0.00', 
                totalretenciones: lista.listMovimientoxNid[0].cnum_total_retenciones != null ? 'S/.    ' + lista.listMovimientoxNid[0].cnum_total_retenciones.toFixed(2) : 'S/. 0.00', 
                liquido: lista.listMovimientoxNid[0].cnum_liquido != null ? 'S/.    ' + lista.listMovimientoxNid[0].cnum_liquido.toFixed(2) : 'S/. 0.00', 
                fecha: lista.listMovimientoxNid[0].dfec_ingreso,
                usuariox: lista.listMovimientoxNid[0].usuario_registro,
                nidpresupuestal: lista.listPresupuestal[0] != undefined ? lista.listPresupuestal[0].nid : 0,
                cuentadebe2: lista.listPresupuestal[0] != undefined ? lista.listPresupuestal[0].ctxt_cuenta_debe != '0' ? lista.listPresupuestal[0].ctxt_cuenta_debe : '8401.01' : '8401.01', 
                cuentahaber2: lista.listPresupuestal[0] != undefined ? lista.listPresupuestal[0].ctxt_cuenta_haber != '0' ? lista.listPresupuestal[0].ctxt_cuenta_haber : '8601.01' : '8601.01', 
                importedebe2: lista.listPresupuestal[0] != undefined ? lista.listPresupuestal[0].ctxt_importe_debe == 0 ? "" : 'S/.    ' + lista.listPresupuestal[0].ctxt_importe_debe.toFixed(2) : "",
                importehaber2: lista.listPresupuestal[0] != undefined ? lista.listPresupuestal[0].ctxt_importe_debe == 0 ? "" : 'S/.    ' + lista.listPresupuestal[0].ctxt_importe_haber.toFixed(2) : "",
                nididentificador: lista.listIdentificadores[0].nid,
                nombre: lista.listIdentificadores[0].ctxt_nombre,
                concepto: lista.listIdentificadores[0].ctxt_concepto,
                documento: lista.listIdentificadores[0].cnum_documento,
                beneficiariocheque: lista.listIdentificadores[0].nid_beneficiario, 
                tipocheque: lista.listIdentificadores[0].nid_tipo_beneficiario
                // selectedOption6: {
                //   value: lista.listIdentificadores[0].nid_beneficiario, 
                //   label: lista.listIdentificadores[0].cnum_documento + ' (' + lista.listIdentificadores[0].ctxt_nombre + ')', 
                //   tipo: lista.listIdentificadores[0].nid_tipo_beneficiario, 
                //   documento: lista.listIdentificadores[0].cnum_documento,
                //   clase: lista.listIdentificadores[0].ctxt_nombre,
                // },
                // selectedOption7: {
                //   value: lista.listIdentificadores[0].nid_concepto, 
                //   label: lista.listIdentificadores[0].ctxt_concepto
                // }
              })
              this.obtenerTipos(lista.listMovimientoxNid[0].ctxt_cheque_carta, lista.listMovimientoxNid[0].cnum_orden);
              if(lista.listEstadistica != null){
                lista.listEstadistica.map((data) => {
                    listestadistica.push(
                      { nidestadistica: data.nid,
                        nidclasificador: data.nid_clasificador,
                        partida: data.ctxt_partida,
                        parcial: 'S/.    ' + data.ctxt_parcial.toFixed(2),
                        descripcion: data.ctxt_descripcion
                      })
                })
                this.setState({estadistica: listestadistica})
              }else{
                this.setState({estadistica: []})
              }
              if(lista.listDeducciones != null){
                lista.listDeducciones.map((data) => {
                    listdeducciones.push(
                      { niddeduccion: data.nid,
                        deduccion: data.ctxt_descripcion,
                        parcial: 'S/.    ' + data.ctxt_importe.toFixed(2),
                      })
                })
                this.setState({deducciones: listdeducciones})
              }else{
                this.setState({deducciones: []})
              }
              if(lista.listCodificacion != null){
                lista.listCodificacion.map((data) => {
                    listcodificaciones.push(
                      { nidcodificacion: data.nid,
                        actproy: data.ctxt_actproy,
                        comp: data.ctxt_comp,
                        fin: data.ctxt_fin,
                        fn: data.ctxt_fn,
                        meta: data.ctxt_meta,
                        pf: data.ctxt_pf,
                        prg: data.ctxt_prg,
                        spf: data.ctxt_spf
                      })
                })
                this.setState({codificaciones: listcodificaciones})
              }else{
                this.setState({codificaciones: []})
              }
              if(lista.listPatrimonial != null){
                lista.listPatrimonial.map((data) => {
                    listpatrimonial.push(
                      { nidpatrimonial: data.nid,
                        concepto: data.concepto != null ? data.concepto : '--',
                        cuentadebe: data.ctxt_cuenta_debe,
                        importedebe: 'S/.    ' + data.ctxt_importe_debe.toFixed(2),
                        cuentahaber: data.ctxt_cuenta_haber,
                        importehaber: 'S/.    ' + data.ctxt_importe_haber.toFixed(2),
                        nidpatri: data.nid_patrimonial
                      })
                })
                this.setState({
                  patrimonial: listpatrimonial, 
                  isLoading7: false, 
                  isLoading6: false, 
                  isLoading5: false, 
                  isLoading1: false, 
                  isLoading2: false, 
                  isLoading3: false, 
                  isLoading4: false, 
                  apunta3: true, 
                  apunta2: true,
                  apunta: true
                })
              }else{
                this.setState({
                  patrimonial: [], 
                  isLoading7: false, 
                  isLoading6: false, 
                  isLoading5: false, 
                  isLoading1: false, 
                  isLoading2: false, 
                  isLoading3: false, 
                  isLoading4: false, 
                  apunta3: true, 
                  apunta2: true,
                  apunta: true
                })
              }
        }else{
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
        }
      });
  }

  async limpiar(){
    this.listarMovimiento(0, "");
    this.setState({monto: '', monto2: '', cuentadebe: '', importedebe: '', cuentahaber: '', importehaber: '', nidcarga2: ''})
    this.props.handleClick("tl", "info", "Los datos han sido actualizados!", "pe-7s-smile")
  }

  async guardar8(){
      this.setState({isLoading7: true})
      let fromData = new FormData();
      fromData.append('meta', this.state.selectedOption2.value);
      fromData.append('comprobante', this.state.nidmovimiento);
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'detallecomprobante/insertarCodificacion', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          this.limpiar();
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading7: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading7: false})
        }
      });
  }

  async listarRetenciones2() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'tipos/listRetenciones?admin=0')
    .then((res) => {
      let lista = [];
      let lista3 = [{value: 0, label: 'NINGUNO'}];
      let lista2 = res.data.response;
      if(lista2 != null){
        lista2.map((data) => {
          lista.push(
            { value: data.nid,
              label: data.ctxt_descripcion,       
            })
          lista3.push(
            { value: data.nid,
              label: data.ctxt_descripcion,       
            })
        })
        paramTesoreria.retenciones[0].data = lista
        paramTesoreria.retenciones[1].data2 = lista3
        this.setState({
          retenciones: paramTesoreria.retenciones[0].data, 
          conceptosCombo: paramTesoreria.retenciones[1].data2
        })
        localStorage.setItem('data', JSON.stringify(paramTesoreria))
      }else{
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async guardar7(){
    if(this.state.monto2 != ''){
      this.setState({isLoading6: true})
      let fromData = new FormData();
      fromData.append('nuevo',  this.state.nuevaretencion != "" ? this.state.nuevaretencion : "--");
      fromData.append('cantidad',  this.state.monto2);
      fromData.append('retencion', this.state.selectedOption.value);
      fromData.append('comprobante', this.state.nidmovimiento);
      fromData.append('tipo', 0);
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'detallecomprobante/insertarDeduccion', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          if(this.state.nuevaretencion != "--"){
            this.listarRetenciones2()
          }
          this.actualizarComprobante(0);
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading6: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading6: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async guardar6(){
    this.setState({isLoading5: true})
    let fromData = new FormData();
    fromData.append('concepto',  this.state.selectedOption8.value);
    fromData.append('cuentadebe',  this.state.cuentadebe);
    fromData.append('importedebe', this.state.importedebe);
    fromData.append('cuentahaber', this.state.cuentahaber);
    fromData.append('importehaber',  this.state.importehaber);
    fromData.append('comprobante', this.state.nidmovimiento);
    fromData.append('tipo', 0);
    fromData.append('usuario', this.state.usuario);
    fromData.append('nidcontabilidad', this.state.nidcarga);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'detallecomprobante/editarContabilidad', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.limpiar();
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
        this.setState({isLoading5: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading5: false})
      }
    });
  }

  async guardar5(){
    this.setState({isLoading5: true})
    let fromData = new FormData();
    fromData.append('concepto',  this.state.selectedOption8.value);
    fromData.append('cuentadebe',  this.state.cuentadebe);
    fromData.append('importedebe', this.state.importedebe);
    fromData.append('cuentahaber', this.state.cuentahaber);
    fromData.append('importehaber',  this.state.importehaber);
    fromData.append('comprobante', this.state.nidmovimiento);
    fromData.append('tipox', 15);
    fromData.append('tipo', 0);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'detallecomprobante/insertarContabilidad', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.limpiar();
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
        this.setState({isLoading5: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading5: false})
      }
    });
  }

  async guardar4(){
    if(this.state.cuentadebe2 != '' && this.state.importedebe2 != '' && this.state.cuentahaber2 != '' && this.state.importehaber2 != ''){
      this.setState({isLoading4: true})
      let fromData = new FormData();
      fromData.append('concepto',  0);
      fromData.append('cuentadebe',  this.state.cuentadebe2);
      fromData.append('importedebe', this.state.importedebe2);
      fromData.append('cuentahaber', this.state.cuentahaber2);
      fromData.append('importehaber',  this.state.importehaber2);
      fromData.append('comprobante', this.state.nidmovimiento);
      fromData.append('tipo', 0);
      fromData.append('usuario', this.state.usuario);
      fromData.append('nidcontabilidad', this.state.nidpresupuestal);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'detallecomprobante/editarContabilidad', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          this.limpiar();
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading4: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading4: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async guardar3(){
    this.setState({isLoading3: true})
    let fromData = new FormData();
    fromData.append('cantidad',  this.state.monto);
    fromData.append('clasificador', this.state.selectedOption4.value);
    fromData.append('comprobante', this.state.nidmovimiento);
    fromData.append('tipo', 0);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'detallecomprobante/insertarEstadistica', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.actualizarComprobante(0);
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
        this.setState({isLoading3: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading3: false})
      }
    });
  }

  async guardar9(){
    this.setState({isLoading3: true})
    let fromData = new FormData();
    fromData.append('parcial',  this.state.monto);
    fromData.append('clasificador', this.state.selectedOption4.value);
    fromData.append('nidcomprobante', this.state.nidmovimiento);
    fromData.append('tipo', 0);
    fromData.append('usuario', this.state.usuario);
    fromData.append('nidestadistica', this.state.nidcarga2);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'detallecomprobante/editarEstadistica', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.actualizarComprobante(0);
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
        this.setState({isLoading3: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading3: false})
      }
    });
  }

  validaCadena(numero){
    if(numero.length == 1){
      return "0000" + numero
    }else if(numero.length == 2){
      return "000" + numero
    }else if(numero.length == 3){
      return "00" + numero
    }else if(numero.length == 4){
      return "0" + numero
    }else if(numero.length == 5){
      return numero
    }else{
      return "00000"
    }
  }

  validaCadena2(numero){
    if(numero == ""){
      return "--"
    }else{
      if(Number.isInteger(numero) == false){
        return this.validaCadena(numero)
      }else{
        return "--"
      }
    }
  }

  validaCadena3(numero){
    if(numero == ""){
      if(this.state.selectedOption9.value == "--"){
        return "--"
      }else{
        return this.state.selectedOption9.value
      }
    }else{
      if(this.state.selectedOption9.value == "--"){
        return numero
      }else{
        return this.state.selectedOption9.value + " N° " + numero
      }
    }
  }

  validaCadena4(numero){
    if(numero == ""){
      if(this.state.selectedOption10.value == "--"){
        return "--"
      }else{
        return this.state.selectedOption10.value
      }
    }else{
      if(this.state.selectedOption10.value == "--"){
        return numero
      }else{
        return this.state.selectedOption10.value + " N° " + numero
      }
    }
  }

  async guardar2(){
    if(this.state.fecha != '' && this.state.numero != '' && this.validaCadena(this.state.numero) != "00000"){
      this.setState({isLoading2: true})
      let fromData = new FormData();
      fromData.append('numero',  this.validaCadena(this.state.numero));
      fromData.append('fecha',  this.state.fecha);
      fromData.append('cheque', this.validaCadena3(this.state.numerocaja));
      fromData.append('orden',  this.validaCadena4(this.state.ordenservicio));
      fromData.append('registro', this.validaCadena2(this.state.registrosiaf));
      fromData.append('usuario', this.state.usuario);
      fromData.append('nidcomprobante', this.state.nidmovimiento);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${tesoreria}` + 'comprobantes/editarComprobante', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          this.actualizarComprobante(0);
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading2: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading2: false})
        }
      });
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async guardar(){
      let temp = 0;
      let fromData = new FormData();
      if(this.state.selectedOption6.value != undefined){
        if(this.state.concepto != ""){
          fromData.append('beneficiario',  this.state.selectedOption6.value);
          fromData.append('tipo', this.state.selectedOption6.tipo);
          fromData.append('documento', this.state.selectedOption6.documento);
          fromData.append('nombre', this.state.selectedOption6.clase);
          // fromData.append('nidconcepto',  this.state.selectedOption7.value);
          // fromData.append('concepto', this.state.selectedOption7.label);
          fromData.append('nidconcepto',  1189);
          fromData.append('concepto', this.state.concepto);
          fromData.append('usuario', this.state.usuario);
          fromData.append('nididentificador', this.state.nididentificador);
          temp = 1;
        }else{
          fromData.append('beneficiario',  this.state.selectedOption6.value);
          fromData.append('tipo', this.state.selectedOption6.tipo);
          fromData.append('documento', this.state.selectedOption6.documento);
          fromData.append('nombre', this.state.selectedOption6.clase);
          fromData.append('nidconcepto',  0);
          fromData.append('concepto', '--');
          fromData.append('usuario', this.state.usuario);
          fromData.append('nididentificador', this.state.nididentificador);
          temp = 1;
        }
      }else{
        if(this.state.concepto != ""){
          fromData.append('beneficiario',  0);
          fromData.append('tipo', 0);
          fromData.append('documento', '--');
          fromData.append('nombre', '--');
          fromData.append('nidconcepto',  1189);
          fromData.append('concepto', this.state.concepto);
          fromData.append('usuario', this.state.usuario);
          fromData.append('nididentificador', this.state.nididentificador);
          temp = 1;
        }else{
          this.props.handleClick("tl", "warning", "Debe seleccionar algún campo!", "pe-7s-info")
        }
      }
      if(temp == 1){
        this.setState({isLoading1: true})
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${tesoreria}` + 'detallecomprobante/editarIdentificador', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            this.actualizarComprobante(0);
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading1: false})
          }
          })
          .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
          }else{
              console.log(error);
              this.setState({isLoading1: false})
          }
        });
      }
  }

  async actualizarComprobante(param) {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${tesoreria}` + 'comprobantes/listMovimientos2?tipo=0&fechaini=' + paramTesoreria.comprobantes[0].fechaini + 
    '&fechafin=' + paramTesoreria.comprobantes[1].fechafin + 
    '&mes=' + paramTesoreria.libro[1].mes + 
    '&anio=' + paramTesoreria.libro[0].anio)
    .then((res) => {
      let lista = [];
      let lista2 = [];
      let lista3 = res.data.response;
      if(lista3.datos != null){
        lista3.datos.map((data) => {
          lista.push(
            { 
              item: data.item,
              nidmovimiento: data.nid,
              numero: data.cnum_numero, 
              cheque: data.ctxt_cheque_carta,
              registro: data.cnum_siaf,
              orden: data.cnum_orden,
              documento: data.cnum_documento,
              nombre: data.ctxt_nombre,
              concepto: data.ctxt_concepto,
              liquido: data.cnum_liquido != null ? 'S/.    ' + data.cnum_liquido.toFixed(2) : 'S/. 0.00', 
              fecha: Calendar.calendario12(data.dfec_ingreso),
              fecha2: data.dfec_ingreso,
              activo: data.item == 1 ? '1' : '0',
              documentox: data.documento
          })
        })
        paramTesoreria.comprobantes[2].data = lista
      }else{
        paramTesoreria.comprobantes[2].data = []
      }
      if(lista3.libro != null){
        lista3.libro.map((data) => {
          lista2.push(
            { 
              item: data.item,
              nidlibro: data.nid,
              cp: data.cp,
              registro: data.siaf,
              orden: data.orden,
              nombre: data.nombre,
              concepto: data.concepto,
              cheque: data.cheque,
              metas: data.metas,
              partidas: data.partidas,
              presupuestal: data.ctxt_presupuestal != null ? 'S/.    ' + data.ctxt_presupuestal.toFixed(2) : 'S/. 0.00', 
              patrimonial: data.ctxt_patrimonial != null ? 'S/.    ' + data.ctxt_patrimonial.toFixed(2) : 'S/. 0.00', 
              cuarta: data.ctxt_reten_cuarta != null ? 'S/.    ' + data.ctxt_reten_cuarta.toFixed(2) : 'S/. 0.00', 
              retenciones: data.ctxt_reten_otros != null ? 'S/.    ' + data.ctxt_reten_otros.toFixed(2) : 'S/. 0.00', 
          })
        })
        paramTesoreria.libro[2].data = lista2
      }else{
        paramTesoreria.libro[2].data = []
      }
      localStorage.setItem('data', JSON.stringify(paramTesoreria))
      if(param == 0){
        this.limpiar();
      }else{
        this.listarMovimiento(0, "");
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading1: false, isLoading2: false})
      }
    });
  }

  // async actualizarComprobante(param) {
  //   axios.defaults.headers.common['Authorization'] = this.state.token
  //   axios.get(`${tesoreria}` + 'comprobantes/listMovimientos?tipo=0&fechaini=' + paramTesoreria.comprobantes[0].fechaini + '&fechafin=' + paramTesoreria.comprobantes[1].fechafin)
  //   .then((res) => {
  //     let lista = [];
  //     let lista2 = res.data.response;
  //     if(lista2 != null){
  //       lista2.map((data) => {
  //         lista.push(
  //           { 
  //             item: data.item,
  //             nidmovimiento: data.nid,
  //             numero: data.cnum_numero, 
  //             cheque: data.ctxt_cheque_carta,
  //             registro: data.cnum_siaf,
  //             orden: data.cnum_orden,
  //             documento: data.cnum_documento,
  //             nombre: data.ctxt_nombre,
  //             concepto: data.ctxt_concepto,
  //             liquido: data.cnum_liquido != null ? 'S/.    ' + data.cnum_liquido.toFixed(2) : 'S/. 0.00', 
  //             fecha: Calendar.calendario12(data.dfec_ingreso),
  //             fecha2: data.dfec_ingreso,
  //             activo: data.item == 1 ? '1' : '0',
  //             documentox: data.documento
  //         })
  //       })
  //       paramTesoreria.comprobantes[2].data = lista
  //     }else{
  //       paramTesoreria.comprobantes[2].data = []
  //     }
  //     localStorage.setItem('data', JSON.stringify(paramTesoreria))
  //     this.actualizarLibro(param);
  //   })
  //   .catch((error) => { 
  //     if(auth0Client.isAuthenticated() === true){
  //       auth0Client.signOut()
  //       this.props.history.replace('/');
  //     }else{
  //       console.log(error);
  //       this.setState({isLoading: false})
  //     }
  //   });
  // }

  // actualizarLibro(param){
  //   axios.defaults.headers.common['Authorization'] = this.state.token
  //   axios.get(`${tesoreria}` + 'comprobantes/listLibro?mes=' + paramTesoreria.libro[1].mes+ '&anio=' + paramTesoreria.libro[0].anio)
  //   .then((res) => {
  //     let lista = [];
  //     let lista2 = res.data.response;
  //     if(lista2 != null){
  //       lista2.map((data) => {
  //         lista.push(
  //           { 
  //             item: data.item,
  //             nidlibro: data.nid,
  //             cp: data.cp,
  //             registro: data.siaf,
  //             orden: data.orden,
  //             nombre: data.nombre,
  //             concepto: data.concepto,
  //             cheque: data.cheque,
  //             metas: data.metas,
  //             partidas: data.partidas,
  //             presupuestal: data.ctxt_presupuestal != null ? 'S/.    ' + data.ctxt_presupuestal.toFixed(2) : 'S/. 0.00', 
  //             patrimonial: data.ctxt_patrimonial != null ? 'S/.    ' + data.ctxt_patrimonial.toFixed(2) : 'S/. 0.00', 
  //             cuarta: data.ctxt_reten_cuarta != null ? 'S/.    ' + data.ctxt_reten_cuarta.toFixed(2) : 'S/. 0.00', 
  //             retenciones: data.ctxt_reten_otros != null ? 'S/.    ' + data.ctxt_reten_otros.toFixed(2) : 'S/. 0.00', 
  //         })
  //       })
  //       paramTesoreria.libro[2].data = lista
  //     }else{
  //       paramTesoreria.libro[2].data = []
  //     }
  //     localStorage.setItem('data', JSON.stringify(paramTesoreria))
  //     if(param == 0){
  //       this.limpiar();
  //     }else{
  //       this.listarMovimiento(0, "");
  //     }
  //   })
  //   .catch((error) => { 
  //     if(auth0Client.isAuthenticated() === true){
  //       auth0Client.signOut()
  //       this.props.history.replace('/');
  //     }else{
  //       console.log(error);
  //       this.setState({isLoading: false})
  //     }
  //   });
  // }

  async delete(data){
    this.setState({isLoading3: true})
    let fromData = new FormData();
    fromData.append('nidcomprobante', this.state.movimiento);
    fromData.append('tipo', 1);
    fromData.append('nidestadistica', data.nidestadistica);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'detallecomprobante/anularEstadistica', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.actualizarComprobante(1);
        this.props.handleClick("tl", "info", "La partida ha sido eliminada!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading3: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading3: false})
      }
    });
  }

  async delete2(data){
    this.setState({isLoading5: true})
    let fromData = new FormData();
    fromData.append('nidcomprobante', this.state.movimiento);
    fromData.append('tipo', 1);
    fromData.append('nidcontabilidad', data.nidpatrimonial);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'detallecomprobante/anularContabilidad', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarMovimiento(0, "");
        this.props.handleClick("tl", "info", "El importe ha sido eliminado!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading5: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading5: false})
      }
    });
  }

  async delete3(data){
    this.setState({isLoading6: true})
    let fromData = new FormData();
    fromData.append('nidcomprobante', this.state.movimiento);
    fromData.append('tipo', 0);
    fromData.append('niddeduccion', data.niddeduccion);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'detallecomprobante/anularDeduccion', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.actualizarComprobante(1);
        this.props.handleClick("tl", "info", "La retención ha sido eliminada!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading6: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading6: false})
      }
    });
  }

  async delete4(data){
    this.setState({isLoading7: true})
    let fromData = new FormData();
    fromData.append('nidcodificacion', data.nidcodificacion);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'detallecomprobante/anularCodificacion', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarMovimiento(0, "");
        this.props.handleClick("tl", "info", "La codificación ha sido eliminada!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading7: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading7: false})
      }
    });
  }

  confirmarDelete = (datos) => {
    confirmAlert({
      title: '¿Seguro de eliminar?',
      message: 'Se eliminará la partida seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(datos)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  confirmarDelete2 = (datos) => {
    confirmAlert({
      title: '¿Seguro de eliminar?',
      message: 'Se eliminará el importe seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete2(datos)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  confirmarDelete3 = (datos) => {
    confirmAlert({
      title: '¿Seguro de eliminar?',
      message: 'Se eliminará la retención seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete3(datos)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  confirmarDelete4 = (datos) => {
    confirmAlert({
      title: '¿Seguro de eliminar?',
      message: 'Se eliminará la codificación seleccionada.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete4(datos)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  agregarRetencion(){
     if(this.state.showRet == true){
      this.setState({showRet : false, nuevaretencion : ""})
     }else{
      this.setState({showRet : true, nuevaretencion : ""})
     }
  }

  async save(){
    if(this.state.cuentadebe != "" && this.state.cuentahaber != "" && this.state.importedebe != "" && this.state.importehaber != ""){
      if(this.state.nidcarga == ''){
        this.guardar5()
      }else{
        this.guardar6()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async editar(datos){
    this.setState({
      cuentadebe: datos.cuentadebe,
      cuentahaber: datos.cuentahaber,
      importedebe: datos.importedebe.replace('S/.    ', ''),
      importehaber: datos.importehaber.replace('S/.    ', ''),
      selectedOption8: datos.nidpatri != 0 ? {value: datos.nidpatri, label: datos.concepto} : {value: datos.nidpatri, label: 'NINGUNO'}, 
      nidcarga: datos.nidpatrimonial,
    })
  }

  async editar5(datos){
    this.setState({
      monto: datos.parcial.replace('S/.    ', ''),
      selectedOption4: {value: datos.nidclasificador, label: datos.partida + " (" + datos.descripcion + ")"}, 
      nidcarga2: datos.nidestadistica,
    })
  }

  async save2(){
    if(this.state.monto != ""){
      if(this.state.nidcarga2 == ''){
        this.guardar3()
      }else{
        this.guardar9()
      }
    }else{
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  async editar2(){
    if(this.state.apunta == true){
      if(this.state.nidpresupuestal == 0){
        this.setState({
          apunta: false
        })
      }else{
        this.setState({
          apunta: false,
          importedebe2: this.state.importedebe2.replace('S/.    ', ''),
          importehaber2: this.state.importehaber2.replace('S/.    ', '')
        })
      }
    }else{
      if(this.state.nidpresupuestal == 0){
        this.setState({
          apunta: true
        })
      }else{
        let impdebe2 = "";
        let imphaber2 = "";
        if(this.state.importedebe2 != 0){
          impdebe2 = 'S/.    ' + this.state.importedebe2
        }else{
          impdebe2 = this.state.importedebe2
        }
        if(this.state.importehaber2 != 0){
          imphaber2 = 'S/.    ' + this.state.importehaber2
        }else{
          imphaber2 = this.state.importehaber2
        }
        this.setState({
          apunta: true,
          importedebe2: impdebe2,
          importehaber2: imphaber2
        })
      }
    }
  }

  editar3(param1, param2){
    if(this.state.apunta2 == true){
      if(param1.substr(0,9) == "CHEQUE N°" || param1.substr(0,6) == "CHEQUE"){
        this.setState({numerocaja: param1.replace("CHEQUE N° ", "").replace(" N° ", "")})
      }else if(param1.substr(0,6) == "CO. N°" || param1.substr(0,6) == "CO."){
        this.setState({numerocaja: param1.replace("CO.", "").replace(" N° ", "")})
      }else if(param1.substr(0,10) == "O-S/CH. N°" || param1.substr(0,6) == "O-S/CH."){
        this.setState({numerocaja: param1.replace("O-S/CH.", "").replace(" N° ", "")})
      }else if(param1.substr(0,6) == "CE. N°" || param1.substr(0,6) == "CE."){
        this.setState({numerocaja: param1.replace("CE.", "").replace(" N° ", "")})
      }else if(param1.substr(0,7) == "OPE. N°" || param1.substr(0,6) == "OPE."){
        this.setState({numerocaja: param1.replace("OPE.", "").replace(" N° ", "")})
      }else if(param1.substr(0,7) == "CCI. N°" || param1.substr(0,6) == "CCI."){
        this.setState({numerocaja: param1.replace("CCI.", "").replace(" N° ", "")})
      }else if(param1.substr(0,10) == "PE. AFP N°" || param1.substr(0,6) == "PE. AFP"){
        this.setState({numerocaja: param1.replace("PE. AFP", "").replace(" N° ", "")})
      }else if(param1.substr(0,5) == "-- N°" || param1.substr(0,6) == "--"){
        this.setState({numerocaja: param1.replace("--", "").replace(" N° ", "")})
      }else{
        this.setState({numerocaja: param1})
      }
      if (param2 != null && param2 != ""){
        if(param2.substr(0,7) == "O/C. N°" || param2.substr(0,4) == "O/C."){
          this.setState({ordenservicio: param2.replace("O/C.", "").replace(" N° ", "")})
        }else if(param2.substr(0,7) == "O/S. N°" || param2.substr(0,4) == "O/S."){
          this.setState({ordenservicio: param2.replace("O/S.", "").replace(" N° ", "")})
        }else if(param2.substr(0,7) == "PLA. N°" || param2.substr(0,4) == "PLA."){
          this.setState({ordenservicio: param2.replace("PLA.", "").replace(" N° ", "")})
        }else if(param2.substr(0,5) == "-- N°" || param2.substr(0,2) == "--"){
          this.setState({ordenservicio: param2.replace("--", "").replace(" N° ", "")})
        }else{
          this.setState({ordenservicio: param2})
        }
      }else{
        this.setState({ordenservicio: ""})
      }
      this.setState({apunta2: false})
    }else{
      this.setState({apunta2: true})
    }
  }

  async editar4(){
    if(this.state.apunta3 == true){
      this.setState({apunta3: false})
    }else{
      this.setState({apunta3: true})
    }
  }

  async cancelar(){
    this.setState({monto: "", selectedOption4: {value: this.state.clasificadores[0].value, label: this.state.clasificadores[0].label, nidcarga2: ""}})
  }

  async cancelar2(){
    this.setState({cuentadebe: "", cuentahaber: "", importedebe: "", importehaber: "", nidcarga: ""})
  }

  async volver(){
    this.props.history.push('/comprobantes');
  }

  async superadelante(){
    if(this.state.nidsupersiguiente != null){
      let data = {
        "nidmovimiento": this.state.nidsupersiguiente
      }
      localStorage.setItem('movimientocomp', JSON.stringify(data))
      this.setState({movimiento: this.state.nidsupersiguiente})
      this.listarMovimiento(1, this.state.nidsupersiguiente)
    }
  }

  async adelante(){
    if(this.state.nidsiguiente != null){
      let data = {
        "nidmovimiento": this.state.nidsiguiente
      }
      localStorage.setItem('movimientocomp', JSON.stringify(data))
      this.setState({movimiento: this.state.nidsiguiente})
      this.listarMovimiento(1, this.state.nidsiguiente)
    }
  }

  async atras(){
    if(this.state.nidanterior != null){
      let data = {
        "nidmovimiento": this.state.nidanterior
      }
      localStorage.setItem('movimientocomp', JSON.stringify(data))
      this.setState({movimiento: this.state.nidanterior})
      this.listarMovimiento(1, this.state.nidanterior)
    }
  }

  async superatras(){
    if(this.state.nidsuperanterior != null){
      let data = {
        "nidmovimiento": this.state.nidsuperanterior
      }
      localStorage.setItem('movimientocomp', JSON.stringify(data))
      this.setState({movimiento: this.state.nidsuperanterior})
      this.listarMovimiento(1, this.state.nidsuperanterior)
    }
  }

  async generarSelect(tipo, param){
    if(tipo == 1){
      this.state.beneficiarios.map((data) => {
        if(data.label == param){
          this.setState({
            selectedOption6: {
              value: data.value, 
              label: data.label, 
              tipo: data.tipo, 
              documento: data.documento,
              clase: data.clase
            }
          })
        }
      })
    }else{
      this.state.conceptos.map((data) => {
        if(data.label == param){
          this.setState({
            selectedOption7: {
              value: data.value, 
              label: data.label
            }
          })
        }
      })
    }
  }

  async generarChange(tipo, param){
    if(tipo == 1){
      if(param == ''){
        this.setState({selectedOption6: ''})
       }
    }else{
      if(param == ''){
        this.setState({selectedOption7: ''})
       }
    }
  }

  async detalle3(){
    confirmAlert({
      title: '¿Desea generar un cheque unitario?',
      message: 'Se generará un cheque con los datos del comprobante.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.detalle5()
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  }

  async detalle5(){
    this.setState({isLoading4: true})
    let fromData = new FormData();
    fromData.append('numero',  this.state.numero);
    fromData.append('fecha', this.state.fecha);
    fromData.append('monto', this.state.liquido != 'S/. 0.00' ? this.state.liquido.replace("S/.    ", "") : this.state.liquido.replace("S/. ", ""));
    fromData.append('comprobante', this.state.nidmovimiento);
    fromData.append('beneficiario',  this.state.beneficiariocheque);
    fromData.append('tipo', this.state.tipocheque);
    fromData.append('cheque', this.state.numerocaja);
    fromData.append('usuario', this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${tesoreria}` + 'cheques/insertarCheque', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.detalle4();
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
        this.setState({isLoading4: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading4: false})
      }
    });
  }

  async detalle4(){
    let numerocaja = this.state.numerocaja
    numerocaja = numerocaja.replace("CHEQUE N° ", "")
    numerocaja = numerocaja.replace("CO. N° ", "")
    numerocaja = numerocaja.replace("O-S/CH. N° ", "")
    numerocaja = numerocaja.replace("CE. N° ", "")
    numerocaja = numerocaja.replace("OPE. N° ", "")
    numerocaja = numerocaja.replace("CCI. N° ", "")
    numerocaja = numerocaja.replace("PE. AFP N° ", "")
    let data2 = {
      "nidmovimiento": this.state.nidmovimiento,
      "fecha": Calendar.calendario12D(this.state.fecha),
      "liquido": this.state.liquido != 'S/. 0.00' ? this.state.liquido.replace("S/.    ", "") : this.state.liquido.replace("S/. ", ""),
      "numero": this.state.numero,
      "nombre": this.state.nombre,
      "cheque": numerocaja
    }
    let cheque = data2.cheque + '|' + data2.numero + '|' + data2.fecha + '|' + data2.nombre + '|' + data2.liquido;               
    const blob = new Blob([cheque], { type: 'text/plain; charset=utf-8'})
    saveAs(blob, 'Cheques.txt')
    setTimeout(
      function() {
        window.open("generador2://")
      }
      .bind(this),
      1000
    );
    // localStorage.setItem('movimientocomp', JSON.stringify(data2))
    // this.props.history.push('/generarcheque')
  }

  async detalle6(){
    let data2 = {
      "nidlibro": this.state.nidlibro,
      "origen": 'C'
    }
    localStorage.setItem('libro', JSON.stringify(data2))
    this.props.history.push('/editarlibro');
  }

  async detalle7(){
    let data2 = {
      "nidmovimiento": this.state.movimiento,
      "tiporeporte": 'COMPROBANTE DE PAGO',
      "origen": 'E'
    }
    localStorage.setItem('movimientocomp', JSON.stringify(data2))
    this.props.history.push('/generarreporte');
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Partida" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar5(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Remover Partida" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  GetActionFormat2(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Importe" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.editar(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Remover Importe" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete2(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  GetActionFormat3(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Remover Retención" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete3(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  GetActionFormat4(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Remover Codificación" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete4(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  render() {
    const columnsEstadistica = [
      {dataField: "partida", text: "PARTIDA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "parcial", text: "IMPORTE", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const columnsPatrimonial = [
        {dataField: "concepto", text: "CONCEPTO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cuentadebe", text: "CTA DEBE", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "importedebe", text: "IMP DEBE", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "cuentahaber", text: "CTA HABER", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "importehaber", text: "IMP HABER", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2}
    ]
    const columnsDeducciones = [
        {dataField: "deduccion", text: "RETENCIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "parcial", text: "IMPORTE", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat3}
    ]
    const columnsCodificaciones = [
        {dataField: "meta", text: "META", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "fn", text: "FN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "pf", text: "P/F", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "spf", text: "SP/F", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "prg", text: "PRG", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "actproy", text: "PROY", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "comp", text: "COMP", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "fin", text: "FIN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
        {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat4}
    ]
    const {numero, selectedOption, selectedOption2, selectedOption4, selectedOption8, selectedOption9, selectedOption10, apunta, apunta2, apunta3} = this.state
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                <div className="row">
                  <div className="col-md-8">
                      <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Detalle del Comprobante de Pago: N° {numero}</h4>
                  </div>
                  <div className="col-md-4" style={{marginTop: 10}}>
                    <Button style={{width: 50, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.superadelante() }}>
                       <i className="fa fa-fast-forward" style={{fontSize: 18, paddingTop: 5}}></i>
                    </Button>
                    <Button style={{width: 50, marginRight: 10, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.adelante() }}>
                       <i className="fa fa-forward" style={{fontSize: 18, paddingTop: 5}}></i>
                    </Button>
                    <Button style={{width: 50, marginRight: 10, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.atras() }}>
                       <i className="fa fa-backward" style={{fontSize: 18, paddingRight: 10, paddingTop: 5}}></i>
                    </Button>   
                    <Button style={{width: 50, marginRight: 10, marginBottom: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.superatras() }}>
                       <i className="fa fa-fast-backward" style={{fontSize: 18, paddingRight: 10, paddingTop: 5}}></i>
                    </Button>              
                  </div>
                </div>
                <div className="row" style={{marginBottom: 10}}>
                  <div className="col-md-6">
                  <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Datos del Beneficiario y Concepto</h4>
                  {this.state.isLoading1 ? (
                    <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading1}
                    />
                  ) : (
                    apunta3 == false ? (
                      <div>
                        <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>SELECCIONE EL BENEFICIARIO</ControlLabel>
                              {/* <Select value={selectedOption6} onChange={this.handleChange6} options={this.state.beneficiarios}/> */}
                              <Autocomplete 
                                  inputRef={this.inputRef}
                                  searchPattern={'containsString'}
                                  selectOnBlur = {false}
                                  placeholder = "Buscar beneficiario"
                                  maxOptionsLimit = {10} 
                                  searchEnabled = {true}
                                  getItemValue={(item)=>{ return item.label }} 
                                  onChange = {
                                    changedValue=>{ 
                                    this.generarChange(1, changedValue)
                                  }}
                                  onSelect = {
                                    selectedValue => { 
                                    this.generarSelect(1, selectedValue)
                                  }}
                                  itemsData = {this.state.beneficiarios}
                                  globalStyle = ".autocomplete {position: relative; display: inline-block; width: 100%} input {font-size: 14px} body {font-size: 14px; font-family: 'Roboto','Helvetica Neue',Arial,sans-serif; line-height: 1.42857143}"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>INGRESE EL CONCEPTO</ControlLabel>
                              <FormControl componentClass="textarea" style={{  height: 204, maxHeight: 204, minHeight: 204, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.concepto} onChange= {this.handleConcepto}/>
                            </div>
                        </div> 
                        {/* <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                              <ControlLabel>SELECCIONE EL CONCEPTO</ControlLabel>
                              <Select value={selectedOption7} onChange={this.handleChange7} options={this.state.conceptos}/>
                              <Autocomplete 
                                  inputRef={this.inputRef2}
                                  searchPattern={'containsString'}
                                  selectOnBlur = {false}
                                  placeholder = "Buscar concepto"
                                  maxOptionsLimit = {10} 
                                  searchEnabled = {true}
                                  getItemValue={(item)=>{ return item.label }} 
                                  onChange = {
                                    changedValue=>{ 
                                    this.generarChange(2, changedValue)
                                  }}
                                  onSelect = {
                                    selectedValue => { 
                                    this.generarSelect(2, selectedValue)
                                  }}
                                  itemsData = {this.state.conceptos}
                                  globalStyle = ".autocomplete {position: relative; display: inline-block; width: 100%} input {font-size: 14px} body {font-size: 14px; font-family: 'Roboto','Helvetica Neue',Arial,sans-serif; line-height: 1.42857143}"
                                />
                            </div>
                        </div>  */}
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-md-12" style={{marginBottom: 18}}>
                            <ControlLabel>NOMBRE O RAZON SOCIAL</ControlLabel>
                            <FormControl type="text" value={this.state.nombre} onChange= {this.handleNombre} disabled/>
                        </div>
                        {/* <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>DOCUMENTO</ControlLabel>
                            <FormControl type="text" value={this.state.documento} onChange= {this.handleDocumento} disabled/>
                        </div> */}
                        <div className="col-md-12" style={{marginBottom: 18}}>
                            <ControlLabel>CONCEPTO</ControlLabel>
                            <FormControl componentClass="textarea" style={{  height: 204, maxHeight: 204, minHeight: 204, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.concepto} onChange= {this.handleConcepto} disabled/>
                        </div>
                      </div> 
                    )
                    )}
                    {apunta3 == true ? (
                      <Button style={{width: 200, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.editar4() }}>Editar</Button>
                      ) : (
                      <div>
                        <Button style={{width: 200, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.guardar() }}>Actualizar</Button>
                        <Button style={{width: 200, marginRight: 10, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.editar4() }}>Cancelar</Button>
                      </div>
                    )}
                  </div>
                {/* </div>
                <hr></hr>
                <div className="row" style={{marginBottom: 10}}> */}
                  <div className="col-md-6">
                  <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Datos del Comprobante</h4>
                      {this.state.isLoading2 ? (
                        <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading2}
                        />
                      ) : (
                        <div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>HECHO POR</ControlLabel>
                                <FormControl type="text" style={{textTransform: 'uppercase'}} value={this.state.usuariox} onChange= {this.handleUsuario} disabled/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>N° CORRELATIVO</ControlLabel>
                                <FormControl type="text" style={{textTransform: 'uppercase'}} value={numero} onChange= {this.handleNumero} disabled={apunta2}/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>FECHA DE GIRO</ControlLabel>
                                <FormControl type="date" value={this.state.fecha} onChange= {this.handleFecha} disabled={apunta2}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>REGISTRO SIAF</ControlLabel>
                                <FormControl type="number" value={this.state.registrosiaf} onChange= {this.handleRegistrosiaf} disabled={apunta2}/>                            
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>TIPO DE GIRO</ControlLabel>
                                <Select value={selectedOption9} onChange={this.handleChange9} options={this.state.tiposgiro} disabled={apunta2}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE GIRO</ControlLabel>
                                <FormControl type="text" value={this.state.numerocaja} onChange= {this.handleNumerocaja} disabled={apunta2}/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>TIPO DE ORDEN</ControlLabel>
                                <Select value={selectedOption10} onChange={this.handleChange10} options={this.state.tiposorden} disabled={apunta2}/>
                            </div>
                            <div className="col-md-6" style={{marginBottom: 18}}>
                                <ControlLabel>N° DE ORDEN</ControlLabel>
                                <FormControl type="text" value={this.state.ordenservicio} onChange= {this.handleOrdenservicio} disabled={apunta2}/>
                            </div>
                          </div>
                        </div>
                      )}
                      {apunta2 == true ? (
                        <Button style={{width: 200, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.editar3(this.state.numerocaja, this.state.ordenservicio) }}>Editar</Button>
                        ) : (
                        <div>
                          <Button style={{width: 200, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.guardar2() }}>Actualizar</Button>
                          <Button style={{width: 200, marginRight: 10, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.editar3() }}>Cancelar</Button>
                        </div>
                      )}
                  </div>
                </div>
                <hr></hr>
                <div className="row" style={{marginBottom: 10}}>
                    <div className="col-md-6">
                        <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Codificación Programática</h4>
                        <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>CODIFICACIÓN</ControlLabel>
                                <div class="input-group">
                                  <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.metas}/>
                                  <span class="input-group-btn">
                                    <Button style={{height: 38, width: 50, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.guardar8() }}>
                                      <i className="fa fa-plus" style={{fontSize: 18, paddingTop: 2, paddingRight: 15}}></i>
                                    </Button> 
                                  </span>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                        {this.state.isLoading7 ? (
                            <ClipLoader
                                css={override}
                                sizeUnit={"px"}
                                size={50}
                                color={'#123abc'}
                                loading={this.state.loading7}
                            />
                        ) : (
                            <BootstrapTable striped hover keyField="item" data={ this.state.codificaciones } columns={ columnsCodificaciones }/>
                        )}
                    </div>
                {/* </div>
                <hr></hr>
                <div className="row" style={{marginBottom: 10}}> */}
                    <div className="col-md-6">
                      <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Estadística Objeto del Gasto</h4>
                      <div className="row">
                          <div className="col-md-7" style={{marginBottom: 18}}>
                              <ControlLabel>PARTIDA</ControlLabel>
                              <Select value={selectedOption4} onChange={this.handleChange4} options={this.state.clasificadores}/>
                          </div>
                          <div className="col-md-5" style={{marginBottom: 18}}>
                              <ControlLabel>IMPORTE</ControlLabel>
                              <div class="input-group">
                                  <FormControl type="number" value={this.state.monto} onChange= {this.handleMonto}/>
                                  <span class="input-group-btn">
                                    <Button style={{height: 40, width: 50, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.save2() }}>
                                      <i className="fa fa-plus" style={{fontSize: 18, paddingTop: 2, paddingRight: 15}}></i>
                                    </Button> 
                                  </span>
                                  <span class="input-group-btn">
                                    <Button style={{height: 40, width: 50, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.cancelar() }}>
                                      <i className="fa fa-trash" style={{fontSize: 18, paddingTop: 2, paddingRight: 15}}></i>
                                    </Button> 
                                  </span>
                              </div>
                          </div>
                      </div>
                      {this.state.isLoading3 ? (
                          <ClipLoader
                              css={override}
                              sizeUnit={"px"}
                              size={50}
                              color={'#123abc'}
                              loading={this.state.loading3}
                          />
                      ) : (
                        <div>
                          <BootstrapTable striped hover keyField="item" data={ this.state.estadistica } columns={ columnsEstadistica }/>
                          <div className="row" style={{marginBottom: 10}}>
                              <div className="col-md-9"></div>
                              <div className="col-md-3">
                                  <ControlLabel>TOTAL GASTOS</ControlLabel>
                                  <FormControl type="text" value={this.state.totalgastos} onChange= {this.handleTotalgastos} disabled/>
                              </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row" style={{marginBottom: 10}}>
                    <div className="col-md-6">
                      <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Retenciones y/o Deducciones</h4>
                      <div className="row">
                          <div className="col-md-8" style={{marginBottom: 18}}>
                              <ControlLabel>RETENCIÓN</ControlLabel>
                              {this.state.showRet ? (
                                <div class="input-group">
                                    <FormControl type="text" value={this.state.nuevaretencion} onChange= {this.handleNuevaretencion}/>
                                    <span class="input-group-btn">
                                      <Button style={{height: 40, width: 50, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.agregarRetencion() }}>
                                        <i className="fa fa-trash" style={{fontSize: 18, paddingTop: 2, paddingRight: 15}}></i>
                                      </Button> 
                                    </span>
                                </div>
                              ) : (
                                <div class="input-group">
                                    <Select value={selectedOption} onChange={this.handleChange} options={this.state.retenciones}/>
                                    <span class="input-group-btn">
                                      <Button style={{height: 38, width: 50, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.agregarRetencion() }}>
                                        <i className="fa fa-pencil" style={{fontSize: 18, paddingTop: 2, paddingRight: 15}}></i>
                                      </Button> 
                                    </span>
                                </div>
                              )}
                          </div>
                          <div className="col-md-4" style={{marginBottom: 18}}>
                              <ControlLabel>IMPORTE</ControlLabel>
                              <div class="input-group">
                                  <FormControl type="number" value={this.state.monto2} onChange= {this.handleMonto2}/>
                                  <span class="input-group-btn">
                                    <Button style={{height: 40, width: 50, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.guardar7() }}>
                                      <i className="fa fa-plus" style={{fontSize: 18, paddingTop: 2, paddingRight: 15}}></i>
                                    </Button> 
                                  </span>
                              </div>
                          </div>
                      </div>
                      {this.state.isLoading6 ? (
                          <ClipLoader
                              css={override}
                              sizeUnit={"px"}
                              size={50}
                              color={'#123abc'}
                              loading={this.state.loading6}
                          />
                      ) : (
                        <div>
                          <BootstrapTable striped hover keyField="item" data={ this.state.deducciones } columns={ columnsDeducciones }/>
                          <div className="row" style={{marginBottom: 10}}>
                              <div className="col-md-9"></div>
                              <div className="col-md-3">
                                  <ControlLabel>TOTAL RETENCIONES</ControlLabel>
                                  <FormControl type="text" value={this.state.totalretenciones} onChange= {this.handleTotalretenciones} disabled/>
                              </div>
                          </div>
                        </div>
                      )}
                    </div>
                  {/* </div>
                  <hr></hr>
                  <div className="row" style={{marginBottom: 10}}> */}
                    <div className="col-md-6">
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Contabilidad Presupuestal</h4>
                      {this.state.isLoading4 ? (
                          <ClipLoader
                              css={override}
                              sizeUnit={"px"}
                              size={50}
                              color={'#123abc'}
                              loading={this.state.loading4}
                          />
                      ) : (
                      <div className="row">
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>CTA DEBE</ControlLabel>
                              <FormControl type="text" value={this.state.cuentadebe2} onChange= {this.handleCuentadebe2} disabled={apunta}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>IMPORTE DEBE</ControlLabel>
                              <FormControl type="text" value={this.state.importedebe2} onChange= {this.handleImportedebe2} disabled={apunta}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>CTA HABER</ControlLabel>
                              <FormControl type="text" value={this.state.cuentahaber2} onChange= {this.handleCuentahaber2} disabled={apunta}/>
                          </div>
                          <div className="col-md-6" style={{marginBottom: 18}}>
                              <ControlLabel>IMPORTE HABER</ControlLabel>
                              <FormControl type="text" value={this.state.importehaber2} onChange= {this.handleImportehaber2} disabled={apunta}/>
                          </div>
                      </div>
                      )}
                      <div>
                      {apunta == true ? (
                        <Button style={{width: 200, marginBottom: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.editar2() }}>Editar</Button>
                        ) : (
                        <div>
                          <Button style={{width: 200, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.guardar4() }}>Actualizar</Button>
                          <Button style={{width: 200, marginRight: 10, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.editar2() }}>Cancelar</Button>
                        </div>
                      )}
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row" style={{marginBottom: 10}}>
                    <div className="col-md-12">
                    <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Contabilidad Patrimonial</h4>
                      <div className="row">
                          <div className="col-md-3" style={{marginBottom: 18}}>
                              <ControlLabel>CONCEPTO</ControlLabel>
                              <Select value={selectedOption8} onChange={this.handleChange8} options={this.state.conceptosCombo}/>
                          </div>
                          <div className="col-md-2" style={{marginBottom: 18}}>
                              <ControlLabel>CTA DEBE</ControlLabel>
                              <FormControl type="text" value={this.state.cuentadebe} onChange= {this.handleCuentadebe}/>
                          </div>
                          <div className="col-md-2" style={{marginBottom: 18}}>
                              <ControlLabel>IMP DEBE</ControlLabel>
                              <FormControl type="number" value={this.state.importedebe} onChange= {this.handleImportedebe}/>
                          </div>
                          <div className="col-md-2" style={{marginBottom: 18}}>
                              <ControlLabel>CTA HABER</ControlLabel>
                              <FormControl type="text" value={this.state.cuentahaber} onChange= {this.handleCuentahaber}/>
                          </div>
                          <div className="col-md-3" style={{marginBottom: 18}}>
                              <ControlLabel>IMP HABER</ControlLabel>
                              <div class="input-group">
                                  <FormControl type="number" value={this.state.importehaber} onChange= {this.handleImportehaber}/>
                                  <span class="input-group-btn">
                                    <Button style={{height: 40, width: 50, marginBottom: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.save() }}>
                                      <i className="fa fa-plus" style={{fontSize: 18, paddingTop: 2, paddingRight: 15}}></i>
                                    </Button> 
                                  </span>
                                  <span class="input-group-btn">
                                    <Button style={{height: 40, width: 50, marginBottom: 10}} bsStyle="warning" pullRight fill type="submit" onClick={() => { this.cancelar2() }}>
                                      <i className="fa fa-trash" style={{fontSize: 18, paddingTop: 2, paddingRight: 15}}></i>
                                    </Button> 
                                  </span>
                              </div>
                          </div>
                      </div>
                      <div>
                      </div>
                      {this.state.isLoading5 ? (
                          <ClipLoader
                              css={override}
                              sizeUnit={"px"}
                              size={50}
                              color={'#123abc'}
                              loading={this.state.loading5}
                          />
                          ) : (
                          <div>
                            <BootstrapTable striped hover keyField="item" data={ this.state.patrimonial } columns={ columnsPatrimonial }/>
                            <div className="row" style={{marginBottom: 10}}>
                              <div className="col-md-9"></div>
                              <div className="col-md-3">
                                  <ControlLabel>LIQUIDO A PAGAR</ControlLabel>
                                  <FormControl type="text" value={this.state.liquido} onChange= {this.handleLiquido} disabled/>
                              </div>
                            </div>
                          </div>
                      )}
                    </div>
                  </div>
              </div>
            </Col>
            <div className="row">
              <div className="col-md-3"></div>
              {/* <div className="col-md-9" style={{paddingLeft: 140}}> */}
              <div className="col-md-9">
                <Button style={{width: 200, marginTop: 15, marginRight: 15}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.volver() }}>Ir a Comprobantes</Button>
                <Button style={{width: 200, marginTop: 15, marginRight: 15}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.detalle6() }}>Consultar Libro</Button>
                <Button style={{width: 200, marginTop: 15, marginRight: 15}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.detalle7() }}>Ver Documento</Button>
                <Button style={{width: 200, marginTop: 15, marginRight: 15}} bsStyle="primary" pullRight fill type="submit" onClick={() => { this.detalle4() }}>Generar Cheque</Button>
                {/* <Button style={{width: 200, marginTop: 15, marginRight: 15}} bsStyle="info" fill type="submit" onClick={() => { this.detalle4() }}>Consultar Cheques</Button> */}
              </div>
            </div>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarComprobante;