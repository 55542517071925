import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import { validator } from 'store/Apis'

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from 'react-select';

import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ReactExport from 'react-export-excel';
import Calendar from "components/Calendar/Calendar.jsx";

import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import { sisper, sisperplus ,fechasBusqueda} from 'store/Apis'


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class VerReportes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            path: 'produccion',
            nombre: JSON.parse(localStorage.getItem('datos'))[0].nombre,
            id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
            usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
            token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
            totTard: '',
            totExc: '',
            totRet: '',
            totSal: '',
            totPer: '',
            multiDataSet: [],
            marcaciones: [],
            listaDet: [],
            isLoading: false,
            movimientos: [],
            isLoading:true,  
            comisiones :[],
            tproyectos :[],
            asesores : [],
            asignaciones: [],
            proyectos: [],
            activeTab: 1,
            anio: '', 
            years: [],
            isLoading: true

        }
    }
    handleAnio = anio => {
        this.setState(
        { anio },
        );
      };

      async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{
                    this.generarPeriodos();
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
      }

      generarPeriodos(){
        let year =  2000;
        let cont = 26;
        let a = Array.from(new Array(cont),(val, index) => index + year);
        let array = a.reverse()
        let cont2 = 0;
        let lista = [];  
        if(fechasBusqueda.produccion[0].nidperiodo != "" && fechasBusqueda.produccion[1].periodo != ""){
          array.map((data) => {
            cont2++
            lista.push({ 
                value: cont2,
                label: data,
            })
          })
          this.setState({anio: {
            value: fechasBusqueda.produccion[0].nidperiodo, 
            label: fechasBusqueda.produccion[1].periodo
          }})
        }else{
          array.map((data) => {
            cont2++
            lista.push({ 
                value: cont2,
                label: data,
            })
            if(data == (new Date()).getFullYear()){
              this.setState({anio: {value: cont2, label: data}})
            }
          })
        }
        this.setState({years: lista, isLoading:false})
     }
     

     async regresar(){
        this.props.history.push('/produccion');
      }
    

    render() {
        return (
            <div className="content">
                <Grid fluid>
                <div className="row">
                        <h4 className="title text-center" style={{ fontWeight: "250", marginTop: '18px', marginBottom: '30px', fontSize: '30px' }}>REPORTES DE PRODUCCIÓN</h4>
                    </div>
                    <div style={{ marginBottom: 60 }}>
                    <Button style={{width: 170, marginLeft: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                    </div>
                    <Row>               
                        <Col md={6}>
                        <Card
                                title={<h5>PRODUCCIÓN MENSUAL POR TIPO DE PROYECTO</h5>}
                                content={
                                    <div style={{ marginBottom: 60 }}>
                                        {this.state.multiDataSet != '' ? (
                                            <ExcelFile element={<Button style={{ width: 150, marginLeft: 10 }} bsStyle="success" pullRight fill type="submit">Descargar Excel</Button>}>
                                                <ExcelSheet dataSet={this.state.multiDataSet} name="Organization" />
                                            </ExcelFile>
                                        ) : (null)}
                                        <div className= "col-md-4" style={{marginBottom: 18}}>
                                            <ControlLabel>PERIODO</ControlLabel>
                                            <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                                        </div>
                                        <Button style={{ width: 150, marginLeft: 10,marginTop: '24px' }} bsStyle="success" pullRight fill type="submit" onClick={() => { this.listarMarcaciones() }}>Ver Reporte</Button>
                                        
                                    </div>
                                }
                            >
                            </Card>
                            
                            <Card
                                title={<h5 style={{ textAlign: 'left', marginTop: '10px', marginBottom: '10px', fontSize: '16px' }}>PRODUCCIÓN MENSUAL POR ASESOR</h5>}
                                content={
                                    <div style={{ marginBottom: 79 }}>
                                        {/* <p style={{ textAlign: 'left', fontSize: '14px', color: 'gray' }}>Información adicional del reporte de producción...</p> */}

                                        {this.state.multiDataSet != '' ? (
                                            <ExcelFile element={<Button style={{ width: 150, marginLeft: 10 }} bsStyle="success" pullRight fill type="submit">Descargar Excel</Button>}>
                                                <ExcelSheet dataSet={this.state.multiDataSet} name="Organization" />
                                            </ExcelFile>
                                        ) : (null)}
                                         <div className= "col-md-4" style={{marginBottom: 18}}>
                                           <ControlLabel>PERIODO</ControlLabel>
                                           <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                                          </div>

                                        <Button style={{ width: 150, marginLeft: 10, marginTop: '28px' }} bsStyle="success" pullRight fill type="submit" onClick={() => { this.listarMarcaciones() }}>Ver Reporte</Button>
                                    </div>
                                }
                            >
                            </Card>
                            <Card
                                title={<h5>RESUMEN DE PRODUCCIÓN MENSUAL POR TIPO DE PROYECTO</h5>}
                                content={
                                    <div style={{ marginBottom: 60 }}>
                                        {this.state.multiDataSet != '' ? (
                                            <ExcelFile element={<Button style={{ width: 150, marginLeft: 10 }} bsStyle="success" pullRight fill type="submit">Descargar Excel</Button>}>
                                                <ExcelSheet dataSet={this.state.multiDataSet} name="Organization" />
                                            </ExcelFile>
                                        ) : (null)}
                                        <div className="col-md-4" style={{ marginBottom: 18 }}>
                                            <ControlLabel>PERIODO</ControlLabel>
                                            <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years} />
                                        </div>
                                        <Button style={{ width: 150, marginLeft: 10,marginTop: '24px' }} bsStyle="success" pullRight fill type="submit" onClick={() => { this.listTipoProyecto() }}>Ver Reporte</Button>
                                    </div>
                                }
                            />
                             <Card
                                title={<h5>PRODUCCIÓN GENERAL MENSUAL</h5>}
                                content={
                                    <div style={{ marginBottom: 60  }}>

                                        {this.state.multiDataSet != '' ? (
                                            <ExcelFile element={<Button style={{ width: 150, marginLeft: 10 }} bsStyle="success" pullRight fill type="submit">Descargar Excel</Button>}>
                                                <ExcelSheet dataSet={this.state.multiDataSet} name="Organization" />
                                            </ExcelFile>
                                        ) : (null)}
                                         <div className= "col-md-4" style={{marginBottom: 18}}>
                                            <ControlLabel>PERIODO</ControlLabel>
                                            <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                                        </div>
                                        <Button style={{ width: 150, marginLeft: 10,marginTop: '24px' }} bsStyle="success" pullRight fill type="submit" onClick={() => { this.listarMarcaciones() }}>Ver Reporte</Button>
                                        
                                    </div>}
                            />
                         
                            </Col>
                            <Col md={6}>        
                               <Card
                                title={<h5>PRODUCCIÓN SEMANAL POR TIPO DE PROYECTO</h5>}
                                content={
                                    <div style={{ marginBottom: 60 }}>
                                        {this.state.multiDataSet != '' ? (
                                            <ExcelFile element={<Button style={{ width: 150, marginLeft: 10 }} bsStyle="success" pullRight fill type="submit">Descargar Excel</Button>}>
                                                <ExcelSheet dataSet={this.state.multiDataSet} name="Organization" />
                                            </ExcelFile>
                                        ) : (null)}
                                        <Button style={{ width: 150, marginLeft: 10,marginTop: '24px' }} bsStyle="success" pullRight fill type="submit" onClick={() => { this.listarMarcaciones() }}>Ver Reporte</Button>
                                    </div>
                                }
                            >
                            </Card>
                            <Card
                                title={<h5>PRODUCCIÓN SEMANAL POR ASESOR</h5>}
                                content={
                                    <div style={{ marginBottom: 73 }}>

                                        {this.state.multiDataSet != '' ? (
                                            <ExcelFile element={<Button style={{ width: 150, marginLeft: 10 }} bsStyle="success" pullRight fill type="submit">Descargar Excel</Button>}>
                                                <ExcelSheet dataSet={this.state.multiDataSet} name="Organization" />
                                            </ExcelFile>
                                        ) : (null)}
                                        <Button style={{ width: 150, marginLeft: 10,marginTop:28 }} bsStyle="success" pullRight fill type="success" onClick={() => { this.listarMarcaciones() }}>Ver Reporte</Button>

                                    </div>}
                            />
                            
                            <Card
                                title={<h5 style={{ textAlign: 'left', marginTop: '10px', marginBottom: '10px', fontSize: '16px' }}>RESUMEN DE PRODUCCIÓN SEMANAL POR TIPO DE PROYECTO</h5>}
                                content={
                                    <div style={{ marginBottom: 68 }}>
                                        {/* <p style={{ textAlign: 'left', fontSize: '14px', color: 'gray' }}>Información adicional del reporte de producción...</p> */}

                                        {this.state.multiDataSet != '' ? (
                                            <ExcelFile element={<Button style={{ width: 150, marginLeft: 10 }} bsStyle="success" pullRight fill type="submit">Descargar Excel</Button>}>
                                                <ExcelSheet dataSet={this.state.multiDataSet} name="Organization" />
                                            </ExcelFile>
                                        ) : (null)}

                                        <Button style={{ width: 150, marginLeft: 10, marginTop: '28px' }} bsStyle="success" pullRight fill type="submit" onClick={() => { this.listarMarcaciones() }}>Ver Reporte</Button>
                                    </div>
                                }
                            />   
                        </Col>
                    </Row>
                  </Grid>
            </div>
        );
    }
}

export default VerReportes;