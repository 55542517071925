import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";
import logo from "assets/img/icon.png";

class Sidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: 0,
      visible2: 0,
      width: window.innerWidth,
      opera: localStorage.getItem('operaciones') != null ? JSON.parse(localStorage.getItem('operaciones')) : []
    };

  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentDidMount() {
      this.updateDimensions();
      window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  despliegue = (data) => {
     if(data.idOperacion !== this.state.visible){
      this.setState({
        visible: data.idOperacion
      })
     }else{
      this.setState({
        visible: 0
      })
     }
  }

  despliegue2 = (data) => {
    if(data.idOperacion !== this.state.visible2){
      this.setState({
        visible2: data.idOperacion
      })
     }else{
      this.setState({
        visible2: 0
      })
     }
  }

  render() {
    const sidebarBackground = {
      backgroundImage: "url(" + this.props.image + ")"
    };
    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color={this.props.color}
        data-image={this.props.image}
      >
          {this.props.hasImage ? (
            <div className="sidebar-background" style={sidebarBackground} />
          ) : (
            null
          )}
        <div className="logo">
          <a className="simple-text logo-mini">
            <div className="logo-img">
              <img style={{height: 35, width: 32}} src={logo} alt="logo_image" />
            </div>
          </a>
          <a className="simple-text logo-normal"
            style={{letterSpacing: 1}}>
            SIAJ
          </a>
        </div>
        <div className="sidebar-wrapper">
        {this.state.opera !== null ?
          <ul className="nav">
            {this.state.width <= 991 ? <AdminNavbarLinks /> : null}
            {this.state.opera.map((data, key) => {
              return (
                data.flagPrincipal == 1 ?
                  <li className={this.activeRoute(data.enlaceWeb)} key={key} style={{marginBottom: -20}}>
                    {data.enlaceWeb !== '-' ?
                    <NavLink
                      style={{letterSpacing: 1}}
                      to={data.enlaceWeb}
                      className="nav-link"
                      activeClassName="active">
                      <i className={data.icono} />
                      <p>{data.descripcion} </p>
                    </NavLink> : 
                    <NavLink
                      onClick={() => { this.despliegue(data) }}
                      style={{letterSpacing: 1}}
                      className="nav-link"
                      activeClassName="active">
                      <i className={data.icono} />
                      <p>{data.descripcion} </p>
                    </NavLink> }
                    <ul className="nav">
                    {/* {this.state.width <= 991 ? <AdminNavbarLinks /> : null} */}
                    {this.state.visible == data.idOperacion ?
                    this.state.opera.map((data2, key2) => {
                      return (
                       data2.idOpeSup == data.idOperacion ?
                        <li className={this.activeRoute(data2.enlaceWeb)} key={key2} style={{marginTop: -20}}>
                        {data2.enlaceWeb !== '-' ?
                          <NavLink
                            style={{letterSpacing: 1}}
                            to={data2.enlaceWeb}
                            className="nav-link"
                            activeClassName="active">
                            <i style={{marginRight: 3}} className={data2.icono} />
                            <p>{data2.descripcion}</p>
                          </NavLink>: 
                          <NavLink
                            onClick={() => { this.despliegue2(data2) }}
                            style={{letterSpacing: 1}}
                            className="nav-link"
                            activeClassName="active">
                            <i style={{marginRight: 3}} className={data2.icono} />
                            <p>{data2.descripcion}</p>
                          </NavLink> }
                          <ul className="nav">
                          {/* {this.state.width <= 991 ? <AdminNavbarLinks /> : null} */}
                          {this.state.visible2 == data2.idOperacion ?
                          this.state.opera.map((data3, key3) => {
                            return (
                              data3.idOpeSup == data2.idOperacion ?
                                <li className={this.activeRoute(data3.enlaceWeb)} key={key3} style={{marginTop: -20, marginBottom: 25}}>
                                  <NavLink
                                    style={{letterSpacing: 1}}
                                    to={data3.enlaceWeb}
                                    className="nav-link"
                                    activeClassName="active">
                                    <i style={{marginRight: -5}} className={data3.icono} />
                                    <p>{data3.descripcion}</p>
                                  </NavLink>
                                </li>
                              : null
                              )
                            }) : null}
                            </ul>
                        </li>
                       : null
                       )
                    }) : null}
                    </ul>
                  </li>
                : null
                );
            }) }
          </ul>
          : null }
        </div>
      </div>
    );
  }
}

export default Sidebar;
