import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import moment from 'moment';
import 'moment/locale/es';

const styles = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 45,
      paddingHorizontal: 35,
      flexGrow: 1,
    },
    title: {
      fontSize: 15,
      textAlign: 'center',
    },
    author2: {
      fontSize: 16,
      textAlign: 'center',
      marginTop: 5
    },
    author3: {
      fontSize: 16,
      textAlign: 'center',
      marginTop: 10,
      marginBottom: 20,
    },
    subtitle3: {
      fontSize: 10,
      marginBottom: 8,
      textAlign: 'right',
    },
    subtitle4: {
      fontSize: 10,
      marginBottom: 8,
      textAlign: 'right',
      marginTop: 40,
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    image: {
      marginVertical: 25,
      marginHorizontal: 350,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        borderBottomWidth: 0,
        marginTop: 20, 
        marginBottom: 20
      }, 
      tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
      }, 
      tableColHeader: { 
        width: "7%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      }, 
      tableColHeader2: { 
        width: "8%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },   
      tableColHeader3: { 
        width: "9%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      }, 
      tableCol: { 
        width: "7%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        borderBottomWidth: 0,
        textAlign: 'right' 
      },  
      tableCol2: { 
        width: "8%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderTopWidth: 0,
        borderBottomWidth: 0,
        textAlign: 'center' 
      }, 
      tableCol3: { 
        width: "9%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        borderBottomWidth: 0,
        textAlign: 'right' 
      }, 
      tableCol4: { 
        width: "7%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderRightWidth: 0, 
        borderBottomWidth: 0,
        textAlign: 'right' 
      },  
      tableCol5: { 
        width: "8%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderRightWidth: 0, 
        borderBottomWidth: 0,
        textAlign: 'center' 
      }, 
      tableCol6: { 
        width: "9%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderRightWidth: 0, 
        borderBottomWidth: 0,
        textAlign: 'right' 
      }, 
      tableCellHeader: {
        margin: "auto", 
        margin: 5, 
        fontSize: 9,
        fontWeight: 500,
        textAlign: 'center'
      },  
      tableCell: { 
        margin: "auto", 
        margin: 5, 
        fontSize: 8
      },
      row: {
        flexDirection: 'row',
        marginBottom: 30,
      },
});

export function reporteCuadro(movimientos, row) {
    const fecha = moment(new Date()).locale('es').format('LLLL')
    let anios = []
    let anio = 0
    let cont = (row[0].aniofin - row[0].anioini) + 1
    if(row[0].anioini == row[0].aniofin){
       anios.push(row[0].anioini)
    }else{
        for (var i = 0; i < cont; i++) {
            anio = row[0].anioini + i;
            anios.push(anio)
        }
    }
    return (
        <Document>
        <Page size="A4" orientation="landscape" style={styles.body}>
          <Image style={styles.image} src="./icon.png"/>
          <Text style={styles.title}>Tribunal Constitucional del Perú</Text>
          <Text style={styles.author3}>Cuadro de Gastos Mensuales Correspondiente
          {anios.map((data, i) => {
            if(row[0].anioini == row[0].aniofin){
                return (" al Año " + data);
            }else{
                if(row[0].anioini == data){
                    return (" a los Años " + data);
                }else if(row[0].aniofin == data){
                    return (" y " + data);
                }else{
                    return (", " + data);
                }
            }
          })} del Fondo para Pagos en Efectivo</Text>
          {movimientos.map((data, i) => {
            return (
                <View> 
                    <Text style={styles.author2}>{data.nombre}</Text>
                    <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColHeader2}> 
                            <Text style={styles.tableCellHeader}>PERIODO</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>ENERO</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>FEBRERO</Text> 
                        </View> 
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>MARZO</Text> 
                        </View>
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>ABRIL</Text> 
                        </View>
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>MAYO</Text> 
                        </View>
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>JUNIO</Text> 
                        </View>
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>JULIO</Text> 
                        </View>
                        <View style={styles.tableColHeader}> 
                            <Text style={styles.tableCellHeader}>AGOSTO</Text> 
                        </View>
                        <View style={styles.tableColHeader3}> 
                            <Text style={styles.tableCellHeader}>SETIEMBRE</Text> 
                        </View>
                        <View style={styles.tableColHeader3}> 
                            <Text style={styles.tableCellHeader}>OCTUBRE</Text> 
                        </View>
                        <View style={styles.tableColHeader3}> 
                            <Text style={styles.tableCellHeader}>NOVIEMBRE</Text> 
                        </View>
                        <View style={styles.tableColHeader3}> 
                            <Text style={styles.tableCellHeader}>DICIEMBRE</Text> 
                        </View>
                    </View>
                    {anios.map((data2, i) => {
                        return (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol2}> 
                                    <Text style={styles.tableCell}>{data2}</Text> 
                                </View>  
                                {data.reporte.map((data3, i) => {
                                    if(data3.anio == data2){
                                        if(data3.mes == 'setiembre' || data3.mes == 'octubre' || data3.mes == 'noviembre' || data3.mes == 'diciembre'){
                                            return (
                                                <View style={styles.tableCol3}> 
                                                    <Text style={styles.tableCell}>{data3.monto.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                                                </View> 
                                            );
                                        }else{
                                            return (
                                                <View style={styles.tableCol}> 
                                                    <Text style={styles.tableCell}>{data3.monto.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                                                </View> 
                                            );
                                        }
                                    }
                                })}
                            </View> 
                        );
                    })}
                    <View style={styles.tableRow}> 
                        <View style={styles.tableCol5}> 
                            <Text style={styles.tableCell}></Text> 
                        </View>  
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol4}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCell}></Text> 
                        </View>
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCell}></Text> 
                        </View> 
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCell}></Text> 
                        </View>  
                    </View> 
                </View>
            </View>
            );
          })}
          <Text style={styles.subtitle4}>
            Lima, {fecha}
          </Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
        </Page>
      </Document>
    );
}