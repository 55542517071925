import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, fechasBusqueda,paramRRHH,gestor} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { confirmAlert } from 'react-confirm-alert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ListadoDescansoMedico extends Component{

    constructor(props){
        super(props);
        this.state = {
          path: 'descansosMedicos',
          usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
          token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
          id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
          movimientos: [],
          isLoading: true,
        }
        this.verdocumento= this.verdocumento.bind(this);
        this.GetActionFormat= this.GetActionFormat.bind(this);
    }

    async componentDidMount() {        
        if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{
                  /*let product = store.getState().detalle
                  store.dispatch({
                    type: "REMOVE_DETALLE",
                    product
                  });*/
                  this.listDescansoMedicoxPersona();
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }

    async listDescansoMedicoxPersona() {
        this.setState({isLoading: true})
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'descanso/listDescansoMedicoxPersona?persona=' + this.state.id)
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({ 
                    fechaIni : Calendar.calendarioA(data.DateBegin),
                    fechaFin: Calendar.calendarioA(data.DateEnd),
                    dias : data.Days,
                    nomTipoDescanso :data.Description,
                    idTipoDescanso: data.MedicalRestType,
                    idperson :data.Person,
                    dni: data.DocumentNumber,
                    nombres:data.Name,
                    estado : data.Status=="A" ? "AUTORIZADO" : data.Status=="P" ? "PENDIENTE" : "DESAUTORIZADO",
                    fechaCreacion : Calendar.calendarioA(data.XLastDate),
                    centro : data. centromedico,
                    medico: data.medico,
                    diagnostico: data.diagnostico,
                    line:data.line,
                    item:data.item,
                    ruta:"P",
                    observacion: data.observacion,
                    idcontingencia: data.id_contingencia,
                    descontingencia: data.des_contingencia,
                    diasAcumulados: data.dias_acumulados
                }) 
              }) 
             this.setState({movimientos: lista, isLoading: false})
            
            }else{
              this.setState({movimientos: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }
    
    confirmarDelete = (data) => {
        confirmAlert({
          title: '¿Seguro que desea eliminar el registro?',
          message: 'Se eliminará el registro seleccionado.',
          buttons: [
            {
              label: 'Aceptar',
              onClick: () => this.delete(data)
            },
            {
              label: 'Cancelar',
              onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
            }
          ]
        });
    };
    
    async delete(data){
        this.setState({isLoading: true})
        let fromData = new FormData();
        fromData.append('persona', data.idperson);
        fromData.append('line', data.line);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'descanso/deleteDescansoMedico', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            this.remove(data)
            //this.listDescansoMedicoxPersona()
            //this.props.handleClick("tl", "info", "El registro ha sido eliminado!", "pe-7s-smile")
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
            this.setState({isLoading: false})
          }
        })
        .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }

    remove(data){
      this.setState({isLoading2: true})
      let fromData = new FormData();
      fromData.append('persona', data.idperson);
      fromData.append('line', data.line);
      fromData.append('secuencia', "0");
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${sisperplus}` + 'descanso/eliminarArchivo', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          this.listDescansoMedicoxPersona()
          this.props.handleClick("tl", "info", "El registro ha sido eliminado!", "pe-7s-smile")
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
          this.setState({isLoading2: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading2: false})
        }
      });
     }
    
    GetActionFormat(cell, row) {
        if(row != undefined){
        return (
            <div>
                <span data-tip="Ver Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20,float:"left"}} onClick={() => { this.ver(row) }}>
                  <ReactTooltip />
                  <i className="fa fa-circle fa-stack-2x text-primary"></i>
                  <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                </span>
                {row.estado == 'PENDIENTE' || row.estado == 'DESAUTORIZADO' ? (
                <span  data-tip="Eliminar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                  <ReactTooltip />
                  <i className="fa fa-circle fa-stack-2x"></i>
                  <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                </span>) : (null)}
            </div>
        );
      }else{}
    }
    
    verdocumento(cell, row) {
      return (
            <div>
                {row.archivo == null ? 
                ( <p style={{fontSize: 14,borderRadius: 2}}>{row.estado}</p>) : 
                ( <Button data-tip="Ver Autorización" bsStyle="success" type="submit" onClick={() => { this.mostrar(row) }}>{row.estado}<ReactTooltip /></Button> )}
            </div>
        );
    }

    async ver(data){
      paramRRHH.trabajadoresAdm[1].enlace = "T"
      paramRRHH.descansoM[1].enlace = "T"
      paramRRHH.descansoM[0].data = data
      localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH))
      this.props.history.push('/modificarDescansoMedico');
    }
    
    async detalle(){
      //paramRRHH.trabajadoresAdm[1].enlace = "T"
      //paramRRHH.descansoM[1].enlace = "T"
      //localStorage.setItem('recursoshumanos', JSON.stringify(paramRRHH))
      this.props.history.push('/agregarDescansoMedico');
    }

       
    render() {
        const columns = [            
            {dataField: "item", text: "ITEM", headerStyle: {backgroundColor: '#337AB7', color: "white",width:40}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "nomTipoDescanso", text: "DESCANSO MÉDICO",  filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white", width:230}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "fechaIni", text: "FEC. INICIO",  filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "fechaFin", text: "FEC. TERMINO", filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "dias", text: "DÍAS DESCANSO", filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "diasAcumulados", text: "DÍAS ACUMULADOS", filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "centro", text: "CENTRO MEDICO",  filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white",width:185}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "medico", text: "MÉDICO", filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white",width:160}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "diagnostico", text: "DIAGNOSTICO",  filter : textFilter(),headerStyle: {backgroundColor: '#337AB7', color: "white",width:185}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "estado", text: "ESTADO", filter : textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white",width:130}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white", width:120}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
          ]
        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'Primera',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Última',
            showTotal: true,
            totalSize: this.state.movimientos.length
        };
        const contentTable = ({ paginationProps, paginationTableProps }) => (
          <div>
            <div>
              <div>
                <BootstrapTable
                  striped
                  hover
                  keyField="item"
                  data={ this.state.movimientos }
                  columns={ columns }
                  filter={ filterFactory() }
                  noDataIndication="No existen registros para mostrar"
                  { ...paginationTableProps }
                />
              </div>
              <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
            </div>
          </div>
        );
        return (
          <div className="content">
            <Grid fluid>
            <Row>
                <Col md={12}>
                  <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                  <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Descansos médicos del colaborador</h4>                    
                    <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.detalle() }}>Nuevo Descanso Médico</Button>
                   {this.state.isLoading ? (
                          <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading}
                          />
                        ) : (
                          <PaginationProvider
                            pagination={
                              paginationFactory(options)
                            }
                          >
                            { contentTable }
                          </PaginationProvider>
                     )}
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        );
    }

}

export default ListadoDescansoMedico;