import React, { Component } from "react";
import { FormControl,ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import axios from 'axios';
import { sisperplus } from 'store/Apis'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from 'react-select';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { validator } from 'store/Apis'

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import Calendar from "components/Calendar/Calendar.jsx";
import ReactTooltip from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
    margin-top :100px
`;

class Aprobar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'aprobar',
      dni: localStorage.getItem('dni'),
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      nombre: JSON.parse(localStorage.getItem('datos'))[0].nombre,
      area: {value: "1", label: JSON.parse(localStorage.getItem('datos'))[0].area},
      url: '',
      areas: [],
      listado: [],
      listadoApro: [],
      isLoading: false,
      personsP:"",
      accion:{value: 1, label: "APROBAR"},
      acciones:[{value: 1, label: "APROBAR"},{value: 3, label: "OBSERVAR"}],
      tipo:{value: 1, label: "APROBADOS"},
      tipos:[{value: 1, label: "APROBADOS"},{value: 3, label: "OBSERVADOS"}],
      observacion:"",
      mes :"",
      anio:"",
      years:[],
      meses:[]

    }
    this.color= this.color.bind(this);
    this.handleObservacion = this.handleObservacion.bind(this);
    this.GetActionFormat = this.GetActionFormat.bind(this);
  }

    handleObservacion(event) {
      this.setState({observacion: event.target.value});
    } 
    handleArea = area => {
      this.setState(
      { area },
      () => this.listar()
      );
    };

    handleAccion = accion => {
      this.setState( {accion});
    };
    
    handleAnio = anio => {
      this.setState(
      { anio },
      () => this.listarAprobados()
      );
    };

    handleMes = mes => {
      this.setState(
      { mes },
      () => this.listarAprobados()
      );
    };

    handleTipo = tipo => {
      this.setState( {tipo},
        () => this.listarAprobados());
    };


    submit = () => {

      let cuenta = this.node.selectionContext.selected
      if(cuenta.length >= 1){
        if(this.state.accion.value==3 && this.state.observacion!="" || this.state.accion.value==1 ){
         if (this.state.accion.value==1){
              var acc="APROBARÁN"
          }else{
              var acc="OBSERVARÁN"
          }
          confirmAlert({
            title: '¿Seguro de desea procesar?',
            message: 'Se '+ acc+' las actividades seleccionadas.',
            buttons: [
              {
                label: 'Aceptar',
                onClick: () => this.procesar()
              },
              {
                label: 'Cancelar',
                onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
              }
            ]
          });
        }else{
          this.props.handleClick("tl", "warning", "Debe ingresar el motivo de la observación!", "pe-7s-info")
        }
      }else{
        this.props.handleClick("tl", "warning", "Debe seleccionar algún registro para procesar!", "pe-7s-info")
      }
    };

  async componentDidMount() {
  if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
      headers: {
          Authorization: this.state.token
      }
      }).then((response) => {
          let permisos = response.data;
          if(permisos.codError != 1){
              this.props.history.goBack()
          }else{
              this.generarPeriodos();
          }
      },(error) => {
          if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
          }else{
          this.props.history.goBack()
          }
      });
  }else{
      auth0Client.signOut();
      this.props.history.replace('/');
  }
  }

  generarPeriodos(){
    let year =  2023;
    let cont = 3;
    let array = Array.from(new Array(cont),(val, index) => index + year);
  //  let array = a.reverse()
    let arrayMes = [
      {"nid": "01", "descripcion": "ENERO"},
      {"nid": "02", "descripcion": "FEBRERO"},
      {"nid": "03", "descripcion": "MARZO"},
      {"nid": "04", "descripcion": "ABRIL"},
      {"nid": "05", "descripcion": "MAYO"},
      {"nid": "06", "descripcion": "JUNIO"},
      {"nid": "07", "descripcion": "JULIO"},
      {"nid": "08", "descripcion": "AGOSTO"},
      {"nid": "09", "descripcion": "SETIEMBRE"},
      {"nid": "10", "descripcion": "OCTUBRE"},
      {"nid": "11", "descripcion": "NOVIEMBRE"},
      {"nid": "12", "descripcion": "DICIEMBRE"},
    ]
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
                  })
        if(data == (new Date()).getFullYear())
          {
            this.setState({ anio: {value: cont2, label: data}})
          }
        })
    arrayMes.map((data) => {
        cont3++
        lista2.push({
         item: data.cont3,
          value: data.nid,
          label: data.descripcion,
                   })
        if(cont3 == (new Date()).getMonth() + 1)
        {
          this.setState({
          mes: {value: data.nid, label: data.descripcion}})
        }
    })
    this.setState({years: lista, meses: lista2})
    this.cargaView()
  }

  cargaView(){
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'autorizar/areas?dni=' + this.state.dni)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.ReplicationUnit,
                label: data.name,
            })
          });
          this.setState({areas: lista, area: {value: lista[0].value, label: lista[0].label},})
          this.listar();
      }else{
        this.setState({areas: []})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listar(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'labores/listCargasxarea?nid=' + this.state.area.value)
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      const a = res.data.response.map(doc => doc);
      this.state.personsP = a.filter((item) => item.cnum_dni !=this.state.dni)
      if(this.state.personsP != null){
        this.state.personsP.map((data) => {
          lista.push(
            { item: data.item,
              idlabor: data.nid,
              oficina: data.oficina,
              producto: data.actividad,
              horas: data.horas + " Hrs",
              horas2: data.horas,
              horainicio: data.horainicio,
              horafinal: data.horafinal,
              minutos: data.minutos,
              actividad: data.producto,
              comentario: data.comentario,
              fecha: Calendar.calendario12(data.dfec_registro),
              fecha2: data.dfec_registro,
              trabajador: data.persona,
              tipo: data.tipo,
              oficina: data.oficina,
              estado: data.estado==""? "PENDIENTE" : data.estado,
              observacion: data.observacion
          })
        });
        this.setState({listado: lista, isLoading: false})
        this.listarAprobados()
      }else{
        this.setState({listado: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarAprobados(){
    this.setState({isLoading:true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'labores/listCargasxareaAprobados?nid=' + this.state.area.value + '&mes=' + this.state.mes.value + '&anio=' + this.state.anio.label + '&tipo=' +this.state.tipo.value )
    .then((res) => {
      let lista = [];
      let lista2 = res.data.response;
      const a = res.data.response.map(doc => doc);
      this.state.personsP = a.filter((item) => item.cnum_dni !=this.state.dni)
      if(this.state.personsP != null){
        this.state.personsP.map((data) => {
          lista.push(
            { item: data.item,
              idlabor: data.nid,
              oficina: data.oficina,
              producto: data.actividad,
              horas: data.horas + " Hrs",
              horas2: data.horas,
              horainicio: data.horainicio,
              horafinal: data.horafinal,
              minutos: data.minutos,
              actividad: data.producto,
              comentario: data.comentario,
              fecha: Calendar.calendario12(data.dfec_registro),
              fecha2: data.dfec_registro,
              trabajador: data.persona,
              tipo: data.tipo,
              oficina: data.oficina,
              estado: data.estado==""? "PENDIENTE" : data.estado,
              observacion: data.observacion
          })
        });
        this.setState({listadoApro: lista, isLoading: false})
      }else{
        this.setState({listadoApro: [], isLoading: false})
        this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async procesar(){
    this.setState({isLoading: true});
    let lista = this.node.selectionContext.selected;
    this.setState({url: 'labores/editarCarga'})
    setTimeout(
      function() {
      let cont = 1;  
      lista.map((data) => {
          for(var i = 0; i < this.state.listado.length; i++){
            if(this.state.listado[i].item == data){
                let fromData = new FormData();
                let pape = this.state.listado[i].trabajador;
                let a = this.state.accion.value==1 ? "aprobada": "observada";
                fromData.append('producto', this.state.listado[i].producto);
                fromData.append('horainicio', this.state.listado[i].horainicio);
                fromData.append('horafinal', this.state.listado[i].horafinal);
                fromData.append('horas', this.state.listado[i].horas2 );
                fromData.append('minutos', this.state.listado[i].minutos);
                fromData.append('actividad', this.state.listado[i].actividad);
                fromData.append('comentario', this.state.listado[i].comentario);
                fromData.append('fecha', this.state.listado[i].fecha2);
                fromData.append('tipo', this.state.listado[i].tipo);
                fromData.append('estado', this.state.accion.value == 1 ? 1 : 3);
                fromData.append('nid', this.state.listado[i].idlabor);
                fromData.append('usuario', this.state.usuario);
                fromData.append('observacion', this.state.observacion);
                axios.defaults.headers.common['Authorization'] = this.state.token
                axios.post(`${sisperplus}` + this.state.url, fromData)
                .then((response) => {
                  var datos = response.data
                  if(datos.response != null){
                    var noty = "La actividad de " + pape + " ha sido "+  a +"!"
                    if(a=="aprobada"){
                      this.props.handleClick("tl", "success" , noty, "pe-7s-smile")
                    }else{
                      this.props.handleClick("tl", "warning" , noty, "pe-7s-smile")
                    }                  
                    if(lista.length == cont++){
                      this.listar();
                       this.setState({accion:{value: 1, label: "APROBAR"}, observacion:""})
                    }
                  }else{
                    this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
                    this.setState({isLoading: false})
                  }
                })
                .catch((error) =>{ 
                  if(auth0Client.isAuthenticated() === true){
                    auth0Client.signOut()
                    this.props.history.replace('/');
                  }else{
                    console.log(error);
                    this.setState({isLoading: false})
                  }
                });
            }
          }
      });
      }
      .bind(this),
      3000
    );
  }
  color(cell, row){
    if(row != undefined){
      return (
          <div>
              {row.estado == "PENDIENTE" ? (
              <Button style={{width:125}} bsStyle="warning"  type="submit">{row.estado}</Button>
             ) :  row.estado == "APROBADO" ? 
              <Button style={{width:125}} bsStyle="success"  type="submit">{row.estado}</Button>
              : 
              <Button style={{width:125}} bsStyle="info"  type="submit">{row.estado}</Button>
              }
          </div>
      );
    }else{}
  }
  GetActionFormat(cell, row) {
    if (row != undefined) {
        return (
            <div>
               {row.estado == "APROBADO" ?
                <span data-tip="Anular Aprobación" className="fa-stack fa-2x" style={{ cursor: 'pointer', fontSize: 20 }} onClick={() => { this.confirmarDelete(row, 'APROBACIÓN') }}>
                    <ReactTooltip />
                    <i className="fa fa-circle fa-stack-2x " style={{color:'#071856'}}></i>
                    <i className="fa fa-ban fa-stack-1x fa-inverse" style={{ fontSize: 15 }}></i>
                </span>
                : 
                <span data-tip="Anular Observación" className="fa-stack fa-2x" style={{ cursor: 'pointer', fontSize: 20 }} onClick={() => { this.confirmarDelete(row, 'OBSERVACIÓN') }}>
                   <ReactTooltip />
                  <i className="fa fa-circle fa-stack-2x " style={{color:'#071856'}}></i>
                  <i className="fa fa-eye-slash fa-stack-1x fa-inverse" style={{ fontSize: 15 }}></i>
                </span>
             }
            </div>
        );
    } 
  }

  confirmarDelete = (row, param) => {

    confirmAlert({
      title: '¿Seguro que desea procesar el registro?',
      message: 'Se anulará la '+ param   +' del registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.Anulacion(row,param)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async Anulacion(data,param){
    this.setState({isLoading:true})
    let fromData = new FormData();
    fromData.append('nid', data.idlabor);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'labores/eliminarAprobacion', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listar()
        this.props.handleClick("tl", "success", "Se anuló la " + param + ' correctamente!', "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }


  render() {
    const columns = [
      {dataField: "tipo", text: "TIPO", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "trabajador", text: "TRABAJADOR", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white",width:280}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "oficina", text: "OFICINA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "actividad", text: "ACTIVIDAD", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "horainicio", text: "HORA INICIAL", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "horafinal", text: "HORA FINAL", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "horas", text: "HORAS LABORADAS", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "producto", text: "PRODUCTO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "comentario", text: "COMENTARIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha", text: "FECHA DE TELETRABAJO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "oficina", text: "ÁREA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:280}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "estado", text: "ESTADO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.color}
    ]

    const columnsA = [
      {dataField: "tipo", text: "TIPO", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "trabajador", text: "TRABAJADOR", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white",width:280}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "oficina", text: "OFICINA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "actividad", text: "ACTIVIDAD", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "horainicio", text: "HORA INICIAL", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "horafinal", text: "HORA FINAL", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "horas", text: "HORAS LABORADAS", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "producto", text: "PRODUCTO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "comentario", text: "COMENTARIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha", text: "FECHA DE TELETRABAJO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "oficina", text: "ÁREA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:280}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "estado", text: "ESTADO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.color},
      {dataField: "acciones", text: "ACCIONES", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:190}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    
    ]
 
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.listado.length
    };
    const options2 = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.listadoApro.length
    };
    const columns2 = [{
      dataField: 'CheckDate',
      headerAlign: (column, colIndex) => 'center',
      text: 'Fecha'
    }, {
      dataField: 'descripcion',
      headerAlign: (column, colIndex) => 'center',
      text: 'Observación'
    }];
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      clickToExpand: true
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
        {/* <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div> */}
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              ref={ n => this.node = n }
              data={ this.state.listado }
              columns={ columns }
              expandRow={ expandRow }
              selectRow={ selectRow }
              tabIndexCell
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              // cellEdit={ cellEditFactory({ mode: 'click' }) }
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const contentTable2 = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.listadoApro }
              columns={ columnsA }
              expandRow={ expandRow2 }
              filter={ filterFactory() }
              noDataIndication="No existen saldos para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const expandRow2 = {
      onlyOneExpanding: true,
      renderer: row => (
        <div> 
          <div className="row">
            <div className="col-md-12">
              <p style={{fontWeight: 'bold', fontSize: 15}}>ACTIVIDAD: <a style={{color: '#333'}}>{ row.actividad }</a></p>
              <p style={{fontWeight: 'bold', fontSize: 15}}>PRODUCTO: <a style={{color: '#333'}}>{ row.producto }</a></p>
              {row.estado=="OBSERVADO" ? 
              <p style={{fontWeight: 'bold', fontSize: 15}}>MOTIVO DE OBSERVACIÓN: <a style={{color: '#333'}}>{ row.observacion }</a></p>
              : null}
            </div>
          </div>
        </div>
      )
    };
    const expandRow = {
      onlyOneExpanding: true,
      renderer: row => (
        <div> 
          <div className="row">
            <div className="col-md-12">
              <p style={{fontWeight: 'bold', fontSize: 15}}>ACTIVIDAD: <a style={{color: '#333'}}>{ row.actividad }</a></p>
              <p style={{fontWeight: 'bold', fontSize: 15}}>PRODUCTO: <a style={{color: '#333'}}>{ row.producto }</a></p>
              {row.estado=="OBSERVADO" ? 
              <p style={{fontWeight: 'bold', fontSize: 15}}>MOTIVO DE OBSERVACIÓN: <a style={{color: '#333'}}>{ row.observacion }</a></p>
              : null}
            </div>
          </div>
        </div>
      )
    };
    const { area ,accion} = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <Card
                title="Procesar Teletrabajos del Personal"
                content={
                  <div style={{marginBottom: -20}}>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>ÁREAS DE APLICACIÓN</ControlLabel>
                            <Select value={area} onChange={this.handleArea} options={this.state.areas}/>
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>ACCIÓN</ControlLabel>
                            <Select value={accion} onChange={this.handleAccion} options={this.state.acciones}/>
                        </div>
                        {this.state.accion.value==3 ? 
                        <div className="col-md-12" style={{marginBottom: 18}}>
                          <ControlLabel>MOTIVO DE OBSERVACIÓN</ControlLabel>
                          <FormControl componentClass="textarea" style={{  height: 100, maxHeight: 100, minHeight: 100, width: "100%",
                          maxWidth:"100%", minWidth:"100%" }} value={this.state.observacion} onChange={this.handleObservacion} />
                         </div>
                        : null }
                    </div>
                   <Button style={{width: 300, marginLeft: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.submit() }}>Procesar</Button>
                  </div>}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Tabs style={{marginTop: 30}}>
                <TabList>
                  <Tab>TELETRABAJOS PENDIENTES</Tab>
                  <Tab>TELETRABAJOS APROBADOS Y OBSERVADOS</Tab>
                </TabList>
                <TabPanel>
                    <div className="content" style={{marginTop: 20}}>
                        <h4 className="title" style={{fontWeight: 200}}>Lista de Teletrabajos Pendientes</h4>
                        <h4 className="category" style={{fontSize: 15, marginTop: -10}}>Para autorizar las actividades deberás seleccionar los registros y luego pulsar el boton Aprobar para iniciar el proceso.</h4>
                        {this.state.isLoading ? (
                                <ClipLoader
                                  css={override}
                                  sizeUnit={"px"}
                                  size={50}
                                  color={'#123abc'}
                                  loading={this.state.isLoading}
                                />
                              ) : (
                                <PaginationProvider
                                  pagination={
                                    paginationFactory(options)
                                  }
                                >
                                  { contentTable }
                                </PaginationProvider>
                          )}
                    </div>
                </TabPanel>
                <TabPanel>
                <div className="content" style={{marginTop: 20}}>
                        <h4 className="title" style={{fontWeight: 200}}>Lista de Teletrabajos {this.state.tipo.value==1 ? 'Aprobados' : 'Observados'}</h4>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>PERIODO</ControlLabel>
                            <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>MES</ControlLabel>
                            <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses}/>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>TIPO</ControlLabel>
                            <Select value={this.state.tipo} onChange={this.handleTipo} options={this.state.tipos}/>
                        </div>
                        {this.state.isLoading ? (
                                <ClipLoader
                                  css={override}
                                  sizeUnit={"px"}
                                  size={50}
                                  color={'#123abc'}
                                  loading={this.state.isLoading}
                                />
                              ) : (
                                <PaginationProvider
                                  pagination={
                                    paginationFactory(options2)
                                  }
                                >
                                  { contentTable2 }
                                </PaginationProvider>
                          )}
                    </div>
                </TabPanel>

              </Tabs>
            </Col>
         </Row>
        </Grid>
      </div>
    );
  }
}

export default Aprobar;