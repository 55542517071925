import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, paramLegajo} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import { confirmAlert } from 'react-confirm-alert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class ListadoFormacion extends Component {

    constructor(props){
        super(props);
        this.state = {
            path: 'miLegajo',
            usuario: localStorage.getItem('nickname'),
            token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,          
            formacion: [],
            isLoading: true,   
            id: JSON.parse(localStorage.getItem('legajo')).legajo[0].idpersona,
            nombre: JSON.parse(localStorage.getItem('legajo')).legajo[1].nombre,
            codcategoria: JSON.parse(localStorage.getItem('legajo')).legajo[2].idcategoria,
            descategoria: JSON.parse(localStorage.getItem('legajo')).legajo[3].descategoria,
            enlace: JSON.parse(localStorage.getItem('legajo')).legajo[4].enlace,
            idlegajo: JSON.parse(localStorage.getItem('legajo')).legajo[5].idlegajo,
        }
        this.GetActionFormat= this.GetActionFormat.bind(this);
    }

    async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{
                    console.log("codigo: " + this.state.codcategoria);
                    this.listarFormacionAcademica();  
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }

    async listarFormacionAcademica() {        
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'legajo/listFormacionAcademica?idlegajo=' + this.state.idlegajo)        
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({ 
                    item: data.item,
                    idformacionacademica: data.nid_formacionacademica,
                    idlegajo: data.id_legajo,                    
                    motivoanula: data.motivo_anula,                    
                    idtipoestudio: data.id_tipoestudio,
                    destipoestudio: data.destipoestudio,
                    idnivelestudio: data.id_nivelestudio,
                    desnivelestudio: data.desnivelestudio,
                    nominstitucion: data.nombre_institucion,
                    especialidad: data.especialidad,
                    fechainicio: Calendar.calendarioA(data.fecha_inicio),
                    fechafin: Calendar.calendarioA(data.fecha_fin),
                    aniosestudio: data.anios_estudio,
                    idcolegio: data.id_colegioprofesional,
                    colegio: data.nomcolegioprofesional,
                    numcolegiatura: data.numero_colegiatura,
                    fechacolegiatura: data.fecha_colegiatura == '' ? '' : Calendar.calendarioA(data.fecha_colegiatura),
                    idestado: data.estado_solicitud,
                    nomestado: data.nomestado,
                    //idreg: data.id_registro,
                    niddocumento: data.nid_documento,
                    archivo: data.nom_archivo,
                    archivonuevo: data.nom_archivonuevo,
                    rutaarchivo: data.ruta_archivo, 
                })
              })    
              this.setState({formacion: lista, isLoading: false})
            }else{
              this.setState({formacion: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }

    async agregar(){
        this.props.history.push('/agregarFormacion');
    }

    async regresar(){
        if(this.state.enlace == "P"){
            this.props.history.push('/miLegajo');
        }else{
            this.props.history.push('/legajoTrabajador');
        }
    }
    
    GetActionFormat(cell, row) {
        if(row != undefined){
          return (
              <div>
                  <span data-tip="Ver formación" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20,float:"left"}} onClick={() => { this.ver(row) }}>
                    <ReactTooltip />
                    <i className="fa fa-circle fa-stack-2x" style={{color:"#751D26"}}></i>
                    <i className="fa fa-file-text fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                  </span>     
                  {row.nomestado == 'SIN VALIDAR' || row.nomestado == 'ANULADO' ? (
                  <span  data-tip="Eliminar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                  <ReactTooltip />
                  <i className="fa fa-circle fa-stack-2x"></i>
                  <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                  </span>) : (null)}             
              </div>
          );
        }else{}
    }

    confirmarDelete = (data) => {
        confirmAlert({
          title: '¿Seguro que desea eliminar el registro?',
          message: 'Se eliminará el registro seleccionado.',
          buttons: [
            {
              label: 'Aceptar',
              onClick: () => this.delete(data)
            },
            {
              label: 'Cancelar',
              onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
            }
          ]
        });
    };

    async delete(data){
        this.setState({isLoading: true})
        const iddoc = data.niddocumento
        const idleg = data.idlegajo
        let fromData = new FormData();
        fromData.append('nid', data.idformacionacademica);
        fromData.append('idlegajo', data.idlegajo);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'legajo/eliminarFormacionAcademica', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            this.deleteDocumento(iddoc,idleg)
            this.props.handleClick("tl", "info", "El registro ha sido eliminado!", "pe-7s-smile")
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
            this.setState({isLoading: false})
          }
        })
        .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }

    async deleteDocumento(iddoc,idleg){
        this.setState({isLoading: true})
        let fromData = new FormData();
        fromData.append('nid', iddoc);
        fromData.append('idlegajo', idleg);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'legajo/eliminarDocumentoLegajo', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            this.listarFormacionAcademica()
            this.props.handleClick("tl", "info", "El documento ha sido eliminado!", "pe-7s-smile")
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
            this.setState({isLoading: false})
          }
        })
        .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }

    async ver(data){
        paramLegajo.formacionAcademica[0].data = data
        paramLegajo.formacionAcademica[1].enlace = this.state.enlace
        localStorage.setItem('legajo', JSON.stringify(paramLegajo))        
        this.props.history.push('/editarFormacion');
    }

    render(){

        const columns = [
            {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "destipoestudio", text: "TIPO DE ESTUDIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "desnivelestudio", text: "NIVEL DE ESTUDIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "nominstitucion", text: "INSTITUCIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "especialidad", text: "ESPECIALIDAD", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:300}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "fechainicio", text: "FECHA INICIO", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
            {dataField: "fechafin", text: "FECHA FIN", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},            
            {dataField: "nomestado", text: "ESTADO",   headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align: 'center',formatter: this.color},            
            {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
        ]
        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'Primera',
            prePageText: 'Anterior',
            nextPageText: 'Siguiente',
            lastPageText: 'Última',
            showTotal: true,
            totalSize: this.state.formacion.length
        };
        const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div>
            <div>
                <div>
                    <BootstrapTable
                        striped
                        hover
                        keyField="item"
                        data={ this.state.formacion }
                        columns={ columns }
                        filter={ filterFactory() }
                        noDataIndication="No existen registros para mostrar"
                        { ...paginationTableProps }
                    />
                </div>
                <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
            </div>
        </div>
        );

        return(
            <div className="content">
            <Grid fluid>
              <Row>
                <Col md={12}>
                    <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>                    
                    {/* <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Legajo de <a style={{fontWeight: "bold", color: "black",fontSize: 20}}>{this.state.nombre}</a></h4>
                    <h5 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}><a style={{fontWeight: "bold", color: "black",fontSize: 20}}>{this.state.descategoria}</a></h5> */}
                    <h4 className="title" style={{fontWeight: 600, marginTop: 18, marginBottom: 25}}>LISTADO DE {this.state.descategoria}</h4>
                    <Button style={{width: 250, marginTop: 5, marginBottom: 10,marginRight: 10,}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                    <Button style={{width: 250, marginTop: 5, marginBottom: 10,marginRight: 10,}} bsStyle="info" fill type="submit" onClick={() => { this.agregar() }}>Agregar</Button>
                    {this.state.isLoading ? (
                        <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                        />
                    ) : (
                        <PaginationProvider
                        pagination={
                            paginationFactory(options)
                        }
                        >
                        { contentTable }
                        </PaginationProvider>
                    )}
                    </div>
                </Col>
              </Row>
            </Grid>
            </div>
        );
    }

}

export default ListadoFormacion;