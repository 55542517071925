import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory  from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, cec} from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { paramCec } from "store/Apis";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ConsultarCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
          path: 'utilitarioscec',
          usuario: localStorage.getItem('nickname'),
          token: 'Bearer ' + localStorage.getItem('ConcurToken'),
          token2: 'Bearer ' + localStorage.getItem('AccessToken'),
          id: JSON.parse(localStorage.getItem('datos'))[0].Person,
          movimientos: [],
          isLoading: true
        }
        this.GetActionFormat= this.GetActionFormat.bind(this);
        this.color= this.color.bind(this);
        this.verimagen=this.verimagen.bind(this);
        this.verenlace=this.verenlace.bind(this);
      }
     
      async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token2
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{
                 this.listarCarouselAdm()
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
      }

      validadorTipo(param){
        if(param != null){
          if(param.replace("https://cec.sedetc.gob.pe/#/detalleEvento/", "").length >= 5){
            if(param.includes('https://cec.sedetc.gob.pe/RECURSOS/CAROUSEL/')== true){
                return {value: "A", label: "CON ARCHIVO"}
            }else {
              return {value: "F", label: "LINK DE FORMULARIO"}
            } 
          }else{
            if(param == "--"){
              return {value: "N", label: "SIN ARCHIVO"}
           }else{
              return {value: "L", label: "LINK DE NOTICIA"}
           }
          }
        }else{
          return {value: "N", label: "SIN ARCHIVO"}
        }
      }
    
      async listarCarouselAdm() {
        this.setState({isLoading: true})
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${cec}` + 'carousel/listCarouselAdm')
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({ 
                    ctxt_archivo: data.ctxt_archivo,
                    ctxt_imagen: data.ctxt_imagen,
                    ctxt_ruta: data.ctxt_ruta,
                    ctxt_rutaarchivo: data.ctxt_rutaarchivo,
                    estado: data.estado,
                    nid: data.nid,
                    item: data.item,
                    tipo: data.tipo,
                    combo: this.validadorTipo(data.ctxt_rutaarchivo),
                })
              })
              this.setState({movimientos: lista, isLoading: false})
            }else{
              this.setState({movimientos: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
      }

      confirmarDelete = (data) => {
        var titulo = "";
        var mensaje = "";
        if(data.estado == 1){
            titulo = "¿Seguro que desea anular el carrousel?";
            mensaje = "Se anulará el carousel seleccionado.";
        }else{
            titulo = "¿Seguro que desea activar el carousel?";
            mensaje = "Se activará el carousel seleccionado"; 
      }

      confirmAlert({
          title: titulo,
          message: mensaje,
          buttons: [
            {
              label: 'Aceptar',
              onClick: () => this.delete(data)
            },
            {
              label: 'Cancelar',
              onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
            }
          ]
        });
      };

      async delete(data){
        this.setState({isLoading: true})
        let fromData = new FormData();
        fromData.append('nidcarousel', data.nid);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${cec}` + 'carousel/manejarCarousel', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            if(datos.response.estado == 1){
                this.listarCarouselAdm();
                if(data.tipo == "ACTIVO"){
                      this.props.handleClick("tl", "info", "El carousel ha sido anulado!", "pe-7s-smile")
                }else{
                      this.props.handleClick("tl", "info", "El carousel ha sido activado!", "pe-7s-smile")
                }  
            }else{
                this.props.handleClick("tl", "error", "No se puede manejar el carousel!", "pe-7s-close")
                this.setState({isLoading: false})
            }
          }else{
            this.props.handleClick("tl", "error", "Hubo un problema al manejar!", "pe-7s-close")
            this.setState({isLoading: false})
          }
        })
        .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
      }
    
      confirmarEliminacion = (data) => {
        confirmAlert({
          title: '¿Seguro que desea eliminar el carousel?',
          message: 'Se eliminará el registro seleccionado.',
          buttons: [
            {
              label: 'Aceptar',
              onClick: () => this.deleteCarrusel(data)
            },
            {
              label: 'Cancelar',
              onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
            }
          ]
        });
      };
    
      async deleteCarrusel(data){
        this.setState({isLoading: true})
        let fromData = new FormData();
        fromData.append('nidcarousel', data.nid);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${cec}` + 'carousel/eliminarCarrusel', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            if(datos.response.estado == 1){
              this.listarCarouselAdm();
              this.props.handleClick("tl", "info",  "El carousel ha sido eliminado!", "pe-7s-smile")
          }else{
              this.props.handleClick("tl", "error", "No se puede elimnar el carousel!", "pe-7s-close")
              this.setState({isLoading: false})
          }
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
          this.setState({isLoading: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
    }

    async detalle(data){
        paramCec.carousel[0].data = data
        localStorage.setItem('cec', JSON.stringify(paramCec))
        this.props.history.push('/editarcarousel');
    }
    
    async detalle2(){
        this.props.history.push('/crearcarousel');
    }
    
    GetActionFormat(cell, row) {
        if(row != undefined){
          return (
              <div>
                  <span data-tip="Editar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                    <ReactTooltip />
                    <i className="fa fa-circle fa-stack-2x text-info"></i>
                    <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 18}}></i>
                  </span>
                  {row.tipo == "INACTIVO" ? (
                  <span data-tip="Habilitar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                    <ReactTooltip />
                    <i className="fa fa-circle fa-stack-2x " style={{color:"#A30E0E"}}></i>
                    <i className="fa fa-power-off fa-stack-1x fa-inverse" style={{fontSize: 18}}></i>
                  </span>
                   ) : (null)}
                   {row.tipo == "ACTIVO" ? (
                  <span data-tip="Deshabilitar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                    <ReactTooltip />
                    <i className="fa fa-circle fa-stack-2x text-success"></i>
                    <i className="fa fa-power-off fa-stack-1x fa-inverse" style={{fontSize: 18}}></i>
                  </span>
                  ) : (null)}
                  <span data-tip="Eliminar" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarEliminacion(row) }}>
                    <ReactTooltip />
                    <i className="fa fa-circle fa-stack-2x "></i>
                    <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 18}}></i>
                  </span>

              </div>
          );
        }else{}
      }

      color(cell, row){
        if(row != undefined){
          return (
              <div>
                  {row.tipo == "INACTIVO" ? (
                  <Button style={{width:150}} bsStyle="danger"  type="submit">{row.tipo}</Button>
                 )   : 
              
                  <Button style={{width:150}} bsStyle="success"  type="submit">{row.tipo}</Button>
                 }
              </div>
          );
        }else{}
      }
    
      verimagen(cell, row) {
        if(row != undefined){
          return (
              <div>
                  <span data-tip="Ver Imagen" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(row.ctxt_ruta) }}>
                   <img src={row.ctxt_ruta} alt="" style={{width:"500px"}}></img>
                  </span>
                 
              </div>
          );
        }else{}
      }
      abrirImagen(link){
        window.open(link, '_blank');
      } 

      async regresar(){
        this.props.history.push('/utilitarioscec');
      }

      verenlace(cell, row) {
        if(row != undefined){
            return (
                <div>
                    {row.combo.value == "A" ? (
                      <span data-tip="Ver Documento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(row.ctxt_rutaarchivo) }}>
                        <ReactTooltip />
                        <i>
                        <i className="fa fa-file-pdf-o fa-stack-2x fa-inverse " style={{fontSize: 40, color: "red"}}></i>
                        </i>
                      </span>
                    ) : (
                      row.combo.value == "L" ? (
                        <span data-tip="Ver Noticia" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(row.ctxt_rutaarchivo) }}>
                          <ReactTooltip />
                          <i>
                          <i className="fa fa-external-link fa-stack-2x fa-inverse " style={{fontSize: 40, color: "#010BA1"}}></i>
                          </i>
                        </span>
                      ) : (
                        row.combo.value == "F" ? (
                          <span data-tip="Ver Formulario" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.abrirImagen(row.ctxt_rutaarchivo) }}>
                            <ReactTooltip />
                            <i>
                            <i className="fa fa-file-text-o fa-stack-2x fa-inverse " style={{fontSize: 40, color: "#5C066D"}}></i>
                            </i>
                          </span>
                        ) : (<p>Sin Archivo</p>) 
                      )
                    )}
                </div>
            );
          }}
     
      render() {
        const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
       // {dataField: "ctxt_imagen", text: "NOMBRE DE IMAGEN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'justify'},
        {dataField: "ctxt_ruta", text: "IMAGEN",  sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:600 }, headerAlign: (column, colIndex) => 'center',align: 'center',formatter: this.verimagen},
        {dataField: "ctxt_rutaarchivo", text: "ARCHIVO O LINK", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',align: 'center',formatter: this.verenlace},
        {dataField: "tipo", text: "ESTADO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.color},
        {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
        ]
        const options = {
          custom: true,
          paginationSize: 4,
          pageStartIndex: 1,
          firstPageText: 'Primera',
          prePageText: 'Anterior',
          nextPageText: 'Siguiente',
          lastPageText: 'Última',
          showTotal: true,
          totalSize: this.state.movimientos.length
        };
        const contentTable = ({ paginationProps, paginationTableProps }) => (
          <div>
            <div>
              <div>
                <BootstrapTable
                  striped
                  hover
                  keyField="item"
                  data={ this.state.movimientos }
                  columns={ columns }
                  filter={ filterFactory() }
                  noDataIndication="No existen registros para mostrar"
                  { ...paginationTableProps }
                />
              </div>
              <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
            </div>
          </div>
        );
        return (
          <div className="content">
            <Grid fluid>
            <Row>
                <Col md={12}>
                  <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
                  <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Carousel del Centro de Estudios Constitucionales</h4>
                  <div className="row">
                    <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel></ControlLabel><br></br>
                        <Button style={{width: 250, marginTop: 5, marginRight: 10,marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                        <Button style={{width: 250, marginTop: 5, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.detalle2() }}>Nuevo Carousel</Button>
                    </div>
                  </div>
                  {this.state.isLoading ? (
                          <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.loading}
                          />
                        ) : (
                          <PaginationProvider
                            pagination={
                              paginationFactory(options)
                            }
                          >
                            { contentTable }
                          </PaginationProvider>
                     )}
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        );
      }
    }
export default ConsultarCarousel;