import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator, sisperplus } from 'store/Apis';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { } from 'store/Apis';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ListadoTramite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: 'utilitariosStd',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      tramite: "",
      validadoTramite: "",
      listTramiteDetalle: [
        {
          TipoTramite: "",
          ape_materno: "",
          ape_paterno: "",
          nombres: "",
          cnom_representante: "",
          cnum_celular: "",
          cnum_dni: "",
          cnum_documento: "",
          cnum_ruc: "",
          cnum_tramite: "",
          ctxt_asunto: "",
          ctxt_cargo: "",
          ctxt_correo: "",
          ctxt_razon: "",
          ctxt_remitente: "",
          ctxt_telefono: "",
          procedencia: "",
          TipodocDescripcion: ""  
        }
      ],
      listTramiteLista: [],
      isLoading: false
    }
    this.handleTramite = this.handleTramite.bind(this);
  }
    handleTramite(event) { this.setState({tramite: event.target.value}); }

  async componentDidMount() {
    if (localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null) {
      axios.get(validator + this.state.path + '/' + this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
      }).then((response) => {
        let permisos = response.data;
        if (permisos.codError != 1) {
          this.props.history.goBack()

        }
      }, (error) => {
        if (auth0Client.isAuthenticated() === true) {
          auth0Client.signOut()
          this.props.history.replace('/');
        } else {
          this.props.history.goBack()
        }
      });
    } else {
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }
  listTramiteDetalle() {
    this.setState({ isLoading: true })
    axios.defaults.headers.common['Authorization'] = this.state.token
    let cadena = this.state.tramite;
    let patter = /-E/i;
    if(patter.test(cadena)==true){
      this.state.validadoTramite=this.state.tramite
    }else{
      this.state.validadoTramite=this.state.tramite+"-E"
    }
    axios.get(`${sisperplus}` + 'documentario/listTramite?numeroTramite=' + this.state.validadoTramite)
      .then((res) => {
        let lista = [];
        let lista2 = [];
        let lista_1 = res.data.response.listTramiteDetalle;
        let lista_2 = res.data.response.listTramiteLista;
        if (lista_1 != 0) {
          lista_1.map((data) => {
            lista.push(
              {
                item: data.item,
                TipoTramite: data.TipoTramite,
                TipodocDescripcion: data.TipodocDescripcion,
                ape_materno: data.ape_materno,
                ape_paterno: data.ape_paterno,
                nombres: data.cnom_nombres,
                cnom_representante: data.cnom_representante,
                cnum_celular: data.cnum_celular,
                cnum_dni: data.cnum_dni,
                cnum_documento: data.cnum_documento,
                cnum_ruc: data.cnum_ruc,
                cnum_tramite: data.cnum_tramite,
                ctxt_asunto: data.ctxt_asunto,
                ctxt_cargo: data.ctxt_cargo,
                ctxt_correo: data.ctxt_correo,
                ctxt_razon: data.ctxt_razon,
                ctxt_remitente: data.ctxt_remitente,
                ctxt_telefono: data.ctxt_telefono,
                procedencia: data.procedencia,
              })
          },
            lista_2.map((data) => {
              lista2.push({
                item2: data.item,
                cflg_destino: data.cflg_destino,
                cflg_estado: data.cflg_estado == 'P' ? 'PENDIENTE' : data.cflg_estado == 'D' ? "DERIVADO" : 'EN PROCESO',
                cnum_tramite: data.cnum_tramite,
                destino: data.destino,
                nflg_recibido: data.nflg_recibido == 1 ? 'RECIBIDO' : 'NO RECIBIDO',
                origen: data.origen
              })
            })
          )
          this.setState({ listTramiteDetalle: lista, listTramiteLista: lista2, isLoading: false })

        } else {

          this.setState({
            listTramiteLista: [], isLoading: false,
            listTramiteDetalle: [{
              TipoTramite: "", ape_paterno: "", ape_materno: "",
              cnom_representante: "", cnum_celular: "",
              cnum_dni: "", cnum_documento: "", cnum_ruc: "",
              cnum_tramite: "", ctxt_asunto: "", ctxt_cargo: "",
              ctxt_correo: "", ctxt_razon: "", ctxt_remitente: "",
              ctxt_telefono: "", procedencia: "", TipodocDescripcion: "",
              nombres: "",
            }]
          },
          )
          if (this.state.tramite != "") {
            this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
          }
        }
      }

      )
      .catch((error) => {
        if (auth0Client.isAuthenticated() === true) {
          auth0Client.signOut()
          this.props.history.replace('/');
        } else {
          console.log(error);
          this.setState({ isLoading: false })
        }
      });
      
  }

  async limpiar() {
    this.setState({ tramite: "" }, () => this.listTramiteDetalle())
    this.setState({listTramiteDetalle: [{
      TipoTramite: "", ape_paterno: "", ape_materno: "",
      cnom_representante: "", cnum_celular: "",
      cnum_dni: "", cnum_documento: "", cnum_ruc: "",
      cnum_tramite: "", ctxt_asunto: "", ctxt_cargo: "",
      ctxt_correo: "", ctxt_razon: "", ctxt_remitente: "",
      ctxt_telefono: "", procedencia: "", TipodocDescripcion: "",
      nombres: "",
    }]})
  }

  regresar() {
    this.props.history.push('/utilitariosStd');
  }

  render() {
    const columns = [
      { dataField: "item2", text: "ITEM", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white", width: 120 }, headerAlign: (column, colIndex) => 'center', align: 'center' },
      { dataField: "origen", text: "ORIGEN", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white" }, headerAlign: (column, colIndex) => 'center', align: 'center' },
      { dataField: "destino", text: "DESTINO", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white" }, headerAlign: (column, colIndex) => 'center', align: 'center' },
      { dataField: "cflg_estado", text: "ESTADO", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white" }, headerAlign: (column, colIndex) => 'center', align: 'center' },
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.listTramiteLista.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={this.state.listTramiteLista}
              columns={columns}
              filter={filterFactory()}
              noDataIndication="No existen registros para mostrar"
              {...paginationTableProps}
            />
          </div>
          <div style={{ float: "right" }}><PaginationListStandalone {...paginationProps} /></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col >
              <div style={{ borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5 }}>
                <h4 className="title" style={{ fontWeight: 200, marginTop: 18, marginBottom: 25 }}>Lista de tramites externos</h4>
                <div className="row" style={{ marginBottom: 18 }}>
                  <div>{console.log()}</div>
                  <div className="col-md-4">
                    <ControlLabel>NUMERO DE TRÁMITE</ControlLabel>
                    <FormControl type="text" value={this.state.tramite} onChange={this.handleTramite} />
                    <h5 className="" style={{ color: "#828282", fontSize: "11px" }}> (Por ejemplo : 02222-2022)</h5>
                  </div>
                  <div className="col-md-8">
                    <ControlLabel></ControlLabel>
                    <Button style={{ width: 150, marginRight: 40, marginBottom: 0, marginTop: 26 }} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.limpiar() }}>Limpiar</Button>
                    <Button style={{ width: 150, marginRight: 10, marginBottom: 0, marginTop: 26 }} bsStyle="success" pullRight fill type="submit" onClick={() => { this.listTramiteDetalle() }}>Buscar</Button>
                  </div>
                </div>
                <div className="row" >
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>NOMBRE COMPLETO</ControlLabel>
                    <FormControl type="text" value={this.state.listTramiteDetalle[0].nombres} disabled />
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>APELLIDOS PATERNO</ControlLabel>
                    <FormControl type="text" value={this.state.listTramiteDetalle[0].ape_paterno} disabled />
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>APELLIDO MATERNO</ControlLabel>
                    <FormControl type="text" value={this.state.listTramiteDetalle[0].ape_materno} disabled />
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>NÚMERO DE DNI</ControlLabel>
                    <FormControl type="text" value={this.state.listTramiteDetalle[0].cnum_dni} disabled />
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>CORREO</ControlLabel>
                    <FormControl type="text" value={this.state.listTramiteDetalle[0].ctxt_correo} disabled />
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>TELÉFONO</ControlLabel>
                    <FormControl type="text" value={this.state.listTramiteDetalle[0].ctxt_telefono} disabled />
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>CELULAR</ControlLabel>
                    <FormControl type="text" value={this.state.listTramiteDetalle[0].cnum_celular} disabled />
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>PROCEDENCIA</ControlLabel>
                    <FormControl type="text" value={this.state.listTramiteDetalle[0].procedencia} disabled />
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>NÚMERO DE DOCUMENTO</ControlLabel>
                    <FormControl type="text" value={this.state.listTramiteDetalle[0].cnum_documento} disabled />
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>TIPO DE DOCUMENTO</ControlLabel>
                    <FormControl type="text" value={this.state.listTramiteDetalle[0].TipodocDescripcion} disabled />
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>REMITENTE</ControlLabel>
                    <FormControl type="text" value={this.state.listTramiteDetalle[0].ctxt_remitente} disabled />
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>NÚMERO DE RUC</ControlLabel>
                    <FormControl type="text" value={this.state.listTramiteDetalle[0].cnum_ruc} disabled />
                  </div>
                  <div className="col-md-6" style={{ marginBottom: 18 }}>
                    <ControlLabel>ASUNTO</ControlLabel>
                    <FormControl componentClass="textarea" style={{ height: 124, maxHeight: 124, minHeight: 124, width: "100%", maxWidth: "100%", minWidth: "100%" }} value={this.state.listTramiteDetalle[0].ctxt_asunto} onChange={this.handleAsunto} disabled />
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>RAZÓN</ControlLabel>
                    <FormControl type="text" value={this.state.listTramiteDetalle[0].ctxt_razon} disabled />
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>REPRESENTANTE</ControlLabel>
                    <FormControl type="text" value={this.state.listTramiteDetalle[0].cnom_representante} disabled />
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>CARGO</ControlLabel>
                    <FormControl type="text" value={this.state.listTramiteDetalle[0].ctxt_cargo} disabled />
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>TIPO DE TRÁMITE</ControlLabel>
                    <FormControl type="text" value={this.state.listTramiteDetalle[0].TipoTramite} disabled />
                  </div>
                </div>
                {this.state.isLoading ? (
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading}
                  />
                ) : (
                  <div>
                    <PaginationProvider
                      pagination={
                        paginationFactory(options)
                      }
                    >
                      {contentTable}
                    </PaginationProvider>
                    <Button style={{ width: 250, marginTop: 18 }} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ListadoTramite;