import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import moment from 'moment';
import 'moment/locale/es';

const styles = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 45,
      paddingHorizontal: 35,
      flexGrow: 1,
    },
    title: {
      fontSize: 15,
      textAlign: 'center',
    },
    author2: {
      fontSize: 16,
      textAlign: 'center',
      marginTop: 10
    },
    subtitle2: {
      fontSize: 10,
      marginTop: 8,
      marginBottom: 8,
      marginLeft: 8,
      textAlign: 'left',
    },
    subtitle3: {
      fontSize: 10,
      marginBottom: 8,
      textAlign: 'right',
    },
    subtitle4: {
      fontSize: 10,
      marginBottom: 8,
      textAlign: 'right',
      marginTop: 40,
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    image: {
      marginVertical: 10,
      marginHorizontal: 230,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderRightWidth: 0, 
        marginTop: 15, 
        marginBottom: 30,
        borderTopWidth: 0,
        borderBottomWidth: 0
      }, 
      tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
      }, 
      tableColHeader: { 
        width: "10%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      }, 
      tableColHeader5: { 
        width: "8%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      }, 
      tableColHeader6: { 
        width: "12%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      }, 
      tableColHeader2: { 
        width: "25%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },   
      tableColHeader3: { 
        width: "5%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },   
      tableColHeader4: { 
        width: "15%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#337AB7', 
        color: "white"
      },   
      tableCol: { 
        width: "10%", 
        borderStyle: "solid", 
        textAlign: 'center',
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        borderBottom: 0 
      },  
      tableCol9: { 
        width: "8%", 
        borderStyle: "solid", 
        textAlign: 'center',
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        borderBottom: 0 
      },  
      tableCol10: { 
        width: "12%", 
        borderStyle: "solid", 
        textAlign: 'center',
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        borderBottom: 0 
      },  
      tableCol2: { 
        width: "25%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        borderBottom: 0 
      }, 
      tableCol3: { 
        width: "15%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        borderBottom: 0 
      }, 
      tableCol4: { 
        width: "10%", 
        borderStyle: "solid", 
        textAlign: 'right',
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        borderBottomWidth: 0 
      }, 
      tableCol6: { 
        width: "70%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        paddingRight: 8,
        textAlign: 'right'
      }, 
      tableCol7: { 
        width: "10%", 
        borderStyle: "solid", 
        textAlign: 'right',
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
      }, 
      tableCol8: { 
        width: "100%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        paddingRight: 8,
        textAlign: 'left'
      }, 
      tableCellHeader: {
        margin: "auto", 
        margin: 5, 
        fontSize: 9,
        fontWeight: 500,
        textAlign: 'center'
      },  
      tableCell: { 
        margin: "auto", 
        margin: 5, 
        fontSize: 8
      },
      row: {
        flexDirection: 'row',
        marginBottom: 30,
      },
});

export function reporteFondo(cabeceras, row) {
    const fecha = moment(new Date()).locale('es').format('LLLL')
    let titulo = "";
    if(row[0].num1 == 0 && row[0].num2 == 0){
      titulo = "Fondo para Pagos en Efectivo: " + row[0].mes + " del " + row[0].anio;
    } else{
      titulo = "Fondo para Pagos en Efectivo del Correlativo N° " + row[0].num1 + " al Correlativo N° " + row[0].num2;
    }   
    return (
        <Document>
        <Page size="A4" orientation="portrait" style={styles.body}>
          <Image style={styles.image} src="./icon.png"/>
          <Text style={styles.title}>Tribunal Constitucional del Perú</Text>
          <Text style={styles.author2}>{titulo}</Text>
          <View style={styles.table}> 
                <View style={styles.tableRow}> 
                  <View style={styles.tableColHeader5}> 
                      <Text style={styles.tableCellHeader}>N°</Text> 
                  </View> 
                  <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>FECHA</Text> 
                  </View> 
                  <View style={styles.tableColHeader4}> 
                      <Text style={styles.tableCellHeader}>DOCUMENTO</Text> 
                  </View> 
                  <View style={styles.tableColHeader6}> 
                      <Text style={styles.tableCellHeader}>GASTOS</Text> 
                  </View>
                  <View style={styles.tableColHeader2}> 
                      <Text style={styles.tableCellHeader}>DESCRIPCIÓN</Text> 
                  </View>
                  <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>META 1</Text> 
                  </View>
                  <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>META 2</Text> 
                  </View>
                  <View style={styles.tableColHeader}> 
                      <Text style={styles.tableCellHeader}>META 3</Text> 
                  </View>
                </View>
                {cabeceras.map((data, i) => {
                    return (
                      <View> 
                      <View style={styles.tableRow}> 
                        <View style={styles.tableCol8}> 
                            <Text style={styles.tableCell}>CUENTA: {data.cuenta + " - " + data.descripcion}</Text> 
                        </View>  
                      </View> 
                      {data.reporte.map((data, i) => {
                      return (
                          <View style={styles.tableRow}> 
                            <View style={styles.tableCol9}> 
                                <Text style={styles.tableCell}>{data.numero}</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>{data.fecha}</Text> 
                            </View> 
                            <View style={styles.tableCol3}> 
                                <Text style={styles.tableCell}>{data.documento}</Text> 
                            </View> 
                            <View style={styles.tableCol10}> 
                                <Text style={styles.tableCell}>{data.clasificador}</Text> 
                            </View> 
                            <View style={styles.tableCol2}> 
                                <Text style={styles.tableCell}>{data.descripcion}</Text> 
                            </View> 
                            <View style={styles.tableCol4}> 
                                <Text style={styles.tableCell}>{data.meta1.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                            </View> 
                            <View style={styles.tableCol4}> 
                                <Text style={styles.tableCell}>{data.meta2.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                            </View> 
                            <View style={styles.tableCol4}> 
                                <Text style={styles.tableCell}>{data.meta3.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text> 
                            </View> 
                          </View> 
                      );
                    })}
                      <View style={styles.tableRow}> 
                        <View style={styles.tableCol6}> 
                            <Text style={styles.tableCell}>TOTALES:</Text> 
                        </View> 
                        <View style={styles.tableCol7}> 
                            <Text style={styles.tableCell}>{data.totales[0].meta1 != null ? 'S/.    ' + data.totales[0].meta1.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'S/.    0.00'}</Text> 
                        </View> 
                        <View style={styles.tableCol7}> 
                            <Text style={styles.tableCell}>{data.totales[0].meta2 != null ? 'S/.    ' + data.totales[0].meta2.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'S/.    0.00'}</Text> 
                        </View> 
                        <View style={styles.tableCol7}> 
                            <Text style={styles.tableCell}>{data.totales[0].meta3 != null ? 'S/.    ' + data.totales[0].meta3.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'S/.    0.00'}</Text> 
                        </View> 
                      </View> 
                    </View>
                  );
                })}
            </View>
          <Text style={styles.subtitle3}>
            TOTAL META 1: {row[0].meta1.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
          <Text style={styles.subtitle3}>
            TOTAL META 2: {row[0].meta2.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
          <Text style={styles.subtitle3}>
            TOTAL META 3: {row[0].meta3.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
          <Text style={styles.subtitle3}>
            TOTAL GENERAL: {row[0].total.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
          <Text style={styles.subtitle4}>
            Lima, {fecha}
          </Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
        </Page>
      </Document>
    );
}