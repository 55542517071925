import React, { Component } from "react";
import { ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { cec } from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';

import ReactExport from 'react-export-excel'
import { data } from "jquery";

const ExcelFile = ReactExport.ExcelFile; // indica el archivo excel que vamos a crear
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;//nombre de hoja de excel
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;//nombre de las columnas de excel



const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;


class ConsultarAnalytics extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'analytics',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      total: '',
      anio: '',
      mes: '',
      years: [],
      meses: [],
      isLoading: true,

  
    
    }
  }

  handleAnio = anio => {
    this.setState(
    { anio },
    );
  };
  handleMes = mes => {
    this.setState(
    { mes },
    );
  };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.generarPeriodos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  generarPeriodos(){
    let year =  2000;
    let cont = 26;
    let array = Array.from(new Array(cont),(val, index) => index + year);
    let arrayMes = [
      {"nid": "01", "descripcion": "ENERO"},
      {"nid": "02", "descripcion": "FEBRERO"},
      {"nid": "03", "descripcion": "MARZO"},
      {"nid": "04", "descripcion": "ABRIL"},
      {"nid": "05", "descripcion": "MAYO"},
      {"nid": "06", "descripcion": "JUNIO"},
      {"nid": "07", "descripcion": "JULIO"},
      {"nid": "08", "descripcion": "AGOSTO"},
      {"nid": "09", "descripcion": "SETIEMBRE"},
      {"nid": "10", "descripcion": "OCTUBRE"},
      {"nid": "11", "descripcion": "NOVIEMBRE"},
      {"nid": "12", "descripcion": "DICIEMBRE"},
    ]
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
                  })
        if(data == (new Date()).getFullYear())
          {
            this.setState({ anio: {value: cont2, label: data}})
          }
        })
    arrayMes.map((data) => {
        cont3++
        lista2.push({
         item: data.cont3,
          value: data.nid,
          label: data.descripcion,
                   })
        if(cont3 == (new Date()).getMonth() + 1)
        {
          this.setState({
          mes: {value: data.nid, label: data.descripcion}})
        }
    })
    this.setState({years: lista, meses: lista2})
    this.listarAnalytics()
  }


     validadorTipo(param){
      const MESES = [
        "ENERO", "FEBREO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO",
        "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"
      ];
      const date = new Date(param);
      return MESES[date.getMonth()];
    }

  async listarAnalytics() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${cec}` + 'medios/obtenerAnalytics?anio=' + this.state.anio.label + '&mes=' + this.state.mes.value)
    .then((res) => {
        let lista = [];
        let lista2 = [];
        lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              cnum_cantidad: data.cnum_cantidad,
              ctxt_nombre: data.ctxt_nombre,
              item: data.item ,
              cnum_anio: data.cnum_anio,
              cnum_mes: this.validadorTipo(data.cnum_mes),
              nid: data.nid,
          
            })
          })
        
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  
  }

  



  render() {
  
    const columns = [
    {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:150}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "ctxt_nombre", text: "NOMBRE",  filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width:300}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "cnum_cantidad", text: "CANTIDAD", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:300}, headerAlign: (column, colIndex) => 'center', align: 'center'} 
     ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Páginas de CEC</h4>
              <div className="row">
                  <div className="col-md-3" style={{marginBottom: 18}}>
                      <ControlLabel>PERIODO</ControlLabel>
                      <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                  </div>
                  <div className="col-md-3" style={{marginBottom: 18}}>
                      <ControlLabel>MES</ControlLabel>
                      <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses}/>
                  </div>
                  <div className="col-md-6" style={{marginBottom: 18}}>
                      <ControlLabel></ControlLabel><br></br>
                      <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.listarAnalytics() }}>Buscar Páginas</Button>
                    
                    <ExcelFile element={<Button style={{backgroundColor:"green" ,border:0,width: 250,height:40,marginBottom:5}}  fill type="submit">Exportar a Excel</Button>} filename= {"REPORTE DE PÁGINAS DE " + this.state.mes.label + " DEL " + this.state.anio.label  }>
                      <ExcelSheet data={this.state.movimientos}  name ={this.state.anio.label + "-" + this.state.mes.label }>
                          <ExcelColumn label="ITEM" value="item" ></ExcelColumn>
                          <ExcelColumn label="PÁGINAS" value="ctxt_nombre"  ></ExcelColumn>
                          <ExcelColumn label="CANTIDAD" value="cnum_cantidad"  ></ExcelColumn>
                        </ExcelSheet>
                    </ExcelFile>
                  </div>
              </div>
               {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
export default ConsultarAnalytics;