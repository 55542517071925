import React, { Component } from "react";
import {FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import moment from 'moment'
import Select from 'react-select';
import { Card } from "components/Card/Card.jsx";
import { validator, sisperplus} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';
import Calendar from "components/Calendar/Calendar.jsx";
import InputFiles from 'react-input-files';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class EditarFormacion extends Component {

    constructor(props){
        super(props);
        this.state = {
            path: 'miLegajo',
            usuario: localStorage.getItem('nickname'),
            token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,          
            movimientos: [],
            isLoading: true,   
            id: JSON.parse(localStorage.getItem('legajo')).legajo[0].idpersona,
            nombre: JSON.parse(localStorage.getItem('legajo')).legajo[1].nombre,
            codcategoria: JSON.parse(localStorage.getItem('legajo')).legajo[2].idcategoria,
            descategoria: JSON.parse(localStorage.getItem('legajo')).legajo[3].descategoria,
            enlace: JSON.parse(localStorage.getItem('legajo')).legajo[4].enlace,            
            tipoestudio: [], nivelestudio: [], colegioprofesional: [], 
            aniosestudio: "", especialidad: "", institucion: "",
            fechainicio: "",            
            fechafin: "",
            numcolegiatura: "", fechacolegiatura: "",
            selectedTipoEstudio: "", selectedNivelEstudio: "", selectedColegioProfesional: "",
            file: [],
            archivo: "",
            filename: "",            
            editable: true,
            selectedEstadoSolicitud: "",
            estadossolicitud: [    
                {value: "0", label: "SIN VALIDAR"},     
                {value: "1", label: "APROBADO"},           
                {value: "2", label: "ANULADO"}
            ],
            desmotivo: "",
            datos: JSON.parse(localStorage.getItem("legajo")).formacionAcademica[0].data,
        }

        this.handleEspecialidad = this.handleEspecialidad.bind(this);
        this.handleInstitucion = this.handleInstitucion.bind(this);
        this.handleFechaInicio = this.handleFechaInicio.bind(this);
        this.handleFechaFin = this.handleFechaFin.bind(this);
        this.handleAniosEstudio = this.handleAniosEstudio.bind(this);        
        this.handleNumColegiatura = this.handleNumColegiatura.bind(this);
        this.handleFecColegiatura = this.handleFecColegiatura.bind(this);     
        this.handleDesMotivo = this.handleDesMotivo.bind(this);     
    }

    changeTipoEstudio    = selectedTipoEstudio =>{ this.setState({selectedTipoEstudio});};    
    changeNivelEstudio   = selectedNivelEstudio =>{ this.setState({selectedNivelEstudio});};       
    changeColegioProfesional = selectedColegioProfesional =>{ this.setState({selectedColegioProfesional});};
    changeEstadoSolicitud = selectedEstadoSolicitud =>{ this.setState({selectedEstadoSolicitud});};  

    handleEspecialidad(event) { this.setState({especialidad: event.target.value});};
    handleInstitucion(event) { this.setState({institucion: event.target.value});};
    handleAniosEstudio(event) { this.setState({aniosestudio: event.target.value});};    
    handleFechaInicio(event) { this.setState({fechainicio: event.target.value});};
    handleFechaFin(event) { this.setState({fechafin: event.target.value});};    
    handleDesMotivo(event) { this.setState({desmotivo: event.target.value});};  
    handleNumColegiatura(event) { 
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
          this.setState({numcolegiatura: event.target.value})
        }
        //this.setState({numcolegiatura: event.target.value});
    };
    handleFecColegiatura(event) { this.setState({fechacolegiatura: event.target.value});};

    async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{
                    //console.log("codigo: " + this.state.codcategoria);
                    this.obtenerDatos();
                    this.listarTipoEstudio();
                    this.listarNivelEstudio();
                    this.listarColegioProfesional();
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }

    async obtenerDatos(){
        if(this.state.datos !== null){
            this.setState({
                idformacionacademica: this.state.datos.idformacionacademica,
                idlegajo: this.state.datos.idlegajo,
                selectedTipoEstudio: {value: this.state.datos.idtipoestudio, label: this.state.datos.destipoestudio},
                institucion: this.state.datos.nominstitucion,
                especialidad: this.state.datos.especialidad,
                selectedNivelEstudio: {value: this.state.datos.idnivelestudio, label: this.state.datos.desnivelestudio},
                fechainicio: Calendar.calendario11(this.state.datos.fechainicio),
                fechafin: Calendar.calendario11(this.state.datos.fechafin),
                aniosestudio: this.state.datos.aniosestudio,
                selectedColegioProfesional: {value: this.state.datos.idcolegio, label: this.state.datos.colegio},
                numcolegiatura: this.state.datos.numcolegiatura,
                fechacolegiatura: this.state.datos.fechacolegiatura == '' ? '' : Calendar.calendario11(this.state.datos.fechacolegiatura),
                //idreg: this.state.datos.idreg,
                selectedEstadoSolicitud: {value: this.state.datos.idestado, label: this.state.datos.nomestado},
                desmotivo: this.state.datos.motivoanula,
                nid: this.state.datos.niddocumento,
                archivo: this.state.datos.archivo,
                ruta: this.state.datos.rutaarchivo
            })            
        }
    }

    async listarTipoEstudio() {        
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'legajo/listTipoEstudio')        
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({ 
                    value: data.id_tipoestudio,
                    label: data.descripcion,     
                })
              })
    
              this.setState({tipoestudio: lista})
            }else{
              this.setState({tipoestudio: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }

    async listarNivelEstudio() {        
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'legajo/listNivelEstudio')        
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({ 
                    value: data.id_nivelestudio,
                    label: data.descripcion,     
                })
              })
    
              this.setState({nivelestudio: lista})
            }else{
              this.setState({nivelestudio: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }

    async listarColegioProfesional() {        
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'legajo/listColegioProfesional')        
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({ 
                    value: data.id_colegioprofesional,
                    label: data.descripcion,     
                })
              })
    
              this.setState({colegioprofesional: lista, isLoading: false})
            }else{
              this.setState({colegioprofesional: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
        })
        .catch((error) => { 
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            console.log(error);
            this.setState({isLoading: false})
          }
        });
    }

    subir = (files) => {
        if(files.length !== 0){
            this.setState({file: files[0], filename: files[0].name, type: files[0].type, visFile: true});
        }
    }

    async download(){
        let anio = Calendar.calendario10(this.state.fechaini).substr(0,4)
        let mes =  Calendar.calendario10(this.state.fechaini).substr(5,2) 
        let archivo = this.state.ruta        
        window.open(archivo, "_blank")
    }

    async regresar(){
        this.props.history.push('/formacionAcademica');
    }

    async validar(){
        if(this.state.institucion != '' && this.state.especialidad != '' && this.state.fechainicio != '' &&
        this.state.fechafin != '' && this.state.aniosestudio != ''){
            
            if(this.state.selectedColegioProfesional.value != ''){
                if(this.state.numcolegiatura != '' && this.state.fechacolegiatura != ''){
                    //this.guardar();
                }else{
                    this.props.handleClick("tl", "warning", "Debe completar los datos de colegiatura!", "pe-7s-info");
                    return;
                }
            }else{
                //this.guardar();
            }

            if(this.state.enlace = 'T'){                
                if(this.state.selectedEstadoSolicitud.value == '2' && this.state.desmotivo == ''){
                   this.props.handleClick("tl", "warning", "Debe ingresar motivo de anulación!", "pe-7s-info")
                   return;
                }
            }   

            if(this.state.filename != ''){
                if(this.state.type == '' || this.state.type == 'application/pdf'){
                    this.guardar();  
                    this.actualizarDocumento();
                }else{
                    this.props.handleClick("tl", "warning", "El archivo a subir debe ser un PDF!", "pe-7s-info")
                }
            }else{
                this.guardar();  
                this.regresar();
            }

        }else{
            this.props.handleClick("tl", "warning", "Debe completar los campos solicitados!", "pe-7s-info");
        }
    }

    async guardar(){
        this.setState({isLoading: true})
        let formdata = new FormData();
        formdata.append("nid", this.state.idformacionacademica); 
        formdata.append("idlegajo", this.state.idlegajo);        
        formdata.append("idtipoestudio", this.state.selectedTipoEstudio.value);
        formdata.append("centroestudio", this.state.institucion.toUpperCase());
        formdata.append("especialidad", this.state.especialidad.toUpperCase());
        formdata.append("idnivelestudio", this.state.selectedNivelEstudio.value);
        formdata.append("fechainicio", this.state.fechainicio);        
        formdata.append("fechafin", this.state.fechafin);
        formdata.append("anios", this.state.aniosestudio);
        formdata.append("idcolegio", this.state.selectedColegioProfesional != '' ? this.state.selectedColegioProfesional.value : '');
        formdata.append("numcolegiatura", this.state.numcolegiatura);
        formdata.append("fechacolegiatura", this.state.fechacolegiatura);   
        formdata.append("estado", this.state.selectedEstadoSolicitud.value);
        formdata.append("motivo", this.state.selectedEstadoSolicitud.value != '2' ? '' : this.state.desmotivo);     
        formdata.append("usuario", this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'legajo/updateFormacionAcademicaLegajo', formdata)
        .then((response) => {
            var datos = response.data
            if(datos.response != null){
                this.props.handleClick("tl", "success", "Datos actualizados correctamente!", "pe-7s-close")
                //this.regresar();
            }else{
                this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
                this.setState({isLoading: false})
            }
        })
        .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
            }else{
                console.log(error);
                this.setState({isLoading: false})
            }
        });
    }

    async actualizarDocumento(){
        this.setState({isLoading: true})
        let fromData = new FormData();                      
        //fromData.append('id', this.state.idreg);
        fromData.append('nid', this.state.nid);
        fromData.append('idlegajo', this.state.idlegajo);
        fromData.append('idseccion', this.state.codcategoria);
        fromData.append('idtipodocumento', '3');        
        fromData.append('file', this.state.file);
        fromData.append('archivo', this.state.filename)                      
        fromData.append('usuario', this.state.usuario);              
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'legajo/updateDocumentoLegajo', fromData)
        .then((response) => {                
            if(response.data.result == null){
                this.props.handleClick("tl", "info", "Se actualizó documento!", "pe-7s-smile")
                this.regresar();
            }else{
            this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
            this.setState({isLoading: false})
            }
        })
        .catch((error) =>{ 
            if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
            }else{
                console.log(error);
                this.setState({isLoading: false})
            }
        });  
    }
   
    render(){
        const {selectedTipoEstudio,selectedNivelEstudio,selectedColegioProfesional,selectedEstadoSolicitud} = this.state
        return(
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                        <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 5, paddingBottom: 5}}>
                            <h4 className="title" style={{fontWeight: 600, marginTop: 18, marginBottom: 25}}>EDITAR FORMACIÓN ACADÉMICA</h4>                            
                            <div className="row"> 
                                <div className="col-md-4" style={{marginBottom: 18}}>
                                    <ControlLabel>TIPO DE ESTUDIO<a style={{color:"red"}}> *</a></ControlLabel>
                                    <Select value={selectedTipoEstudio} onChange={this.changeTipoEstudio} options={this.state.tipoestudio} />
                                </div>                                  
                                <div className="col-md-4" style={{marginBottom: 18}}>
                                    <ControlLabel>CENTRO DE ESTUDIOS<a style={{color:"red"}}> *</a></ControlLabel>
                                    <FormControl type="text" value={this.state.institucion} onChange= {this.handleInstitucion} />
                                </div>
                                <div className="col-md-4" style={{marginBottom: 18}}>
                                    <ControlLabel>ESPECIALIDAD<a style={{color:"red"}}> *</a></ControlLabel>
                                    <FormControl type="text" value={this.state.especialidad} onChange= {this.handleEspecialidad} />
                                </div>                                 
                            </div>                   
                            <div className="row">    
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>NIVEL DE ESTUDIO<a style={{color:"red"}}> *</a></ControlLabel>
                                    <Select value={selectedNivelEstudio} onChange={this.changeNivelEstudio} options={this.state.nivelestudio} />
                                </div>                              
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>FECHA DE INICIO<a style={{color:"red"}}> *</a></ControlLabel>
                                    <FormControl type="date" value={this.state.fechainicio} onChange= {this.handleFechaInicio} />
                                </div>
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>FECHA FIN<a style={{color:"red"}}> *</a></ControlLabel>
                                    <FormControl type="date" value={this.state.fechafin} onChange= {this.handleFechaFin} />
                                </div>
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>AÑOS DE ESTUDIO<a style={{color:"red"}}> *</a></ControlLabel>
                                    <FormControl type="number" value={this.state.aniosestudio} onChange= {this.handleAniosEstudio} />
                                </div>                                 
                            </div>  
                            <div className="row">
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>COLEGIO <a>(OPCIONAL)</a></ControlLabel>
                                    <Select value={selectedColegioProfesional} onChange={this.changeColegioProfesional} options={this.state.colegioprofesional} />
                                </div>
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>NÚMERO COLEGIATURA <a>(OPCIONAL)</a></ControlLabel>
                                    <FormControl type="text" value={this.state.numcolegiatura} onChange= {this.handleNumColegiatura} disabled = {selectedColegioProfesional.value != '' ? false : this.state.editable} />
                                </div>
                                <div className="col-md-3" style={{marginBottom: 18}}>
                                    <ControlLabel>FECHA DE COLEGIATURA <a>(OPCIONAL)</a></ControlLabel>
                                    <FormControl type="date" value={this.state.fechacolegiatura} onChange= {this.handleFecColegiatura} disabled = {selectedColegioProfesional.value != '' ? false : this.state.editable} />
                                </div>
                            </div>
                            {this.state.enlace == 'T' ? (
                                <div>
                                    <p style={{borderBottom: "solid", backgroundColor: "#1E75C6", borderColor: "#1E75C6", borderWidth: 3, fontSize: 16, height:20, maxHeight: 20, minHeight: 20, textAlign: "center", color: "white", paddingBottom: 25}}><span>ESTADO DE SOLICITUD</span></p>                
                                    <div className="row">
                                        <div className="col-md-3" style={{marginBottom: 18}}>
                                            <ControlLabel>ESTADO<a style={{color:"red"}}> *</a></ControlLabel>
                                            <Select value={selectedEstadoSolicitud} onChange={this.changeEstadoSolicitud} options={this.state.estadossolicitud} />
                                        </div>
                                        {selectedEstadoSolicitud.value == '2' ? (
                                            <div className="col-md-9" style={{marginBottom: 18}}>                                    
                                                <ControlLabel>MOTIVO<a style={{color:"red"}}> *</a></ControlLabel>
                                                <FormControl componentClass="textarea" style={{  height: 60, maxHeight: 60, minHeight: 60, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.desmotivo} onChange= {this.handleDesMotivo} maxLength={200}/>                                    
                                            </div>    
                                        ) : (
                                            null
                                        )}                                        
                                    </div>
                                    <p style={{borderBottom: "solid", borderColor: "#1E75C6", borderWidth: 3, fontSize: 18}}><span></span></p>
                                </div>
                            ) : (
                                null
                            )}   
                            <div className="row" style={{marginTop: 18, marginBottom: 20}}>
                                <div className="col-md-3"></div>                            
                                <div className="col-md-6" style={{marginBottom: 18}}>
                                    <ControlLabel>ARCHIVOS SUBIDOS</ControlLabel>
                                    <ul className="todo-list">
                                    <li>                                   
                                        <div className="price">
                                            <h3>1</h3>
                                        </div>
                                        <div className="name">
                                            <span className="item-name">{this.state.archivo}</span>                                        
                                        </div>                                    
                                        <span className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20, marginRight: 5}} onClick={() => { this.download() }}>
                                            <i className="fa fa-circle fa-stack-2x" style={{color:"#1B4F72"}}></i>
                                            <i className="fa fa-download fa-stack-1x fa-inverse" style={{fontSize: 18, color:"white"}}></i>
                                        </span>                                                                     
                                        </li>
                                    </ul>
                                    <Alert bsStyle="warning">                                    
                                        <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.filename}</span>
                                    </Alert>
                                    <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit">
                                        <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                                    </Button>                              
                                </div>                            
                            </div>
                            <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Registro</Button>
                            <Button style={{marginRight: 10, marginTop: 20}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Editar Registro</Button>
                        </div>                        
                        </Col>
                    </Row>
                </Grid>

            </div>
        )
    }
}

export default EditarFormacion;