import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, paramLegajo} from 'store/Apis'
import { Card } from "components/Card/Card.jsx";
import { NavLink, Link } from 'react-router-dom';
import Button from "components/CustomButton/CustomButton.jsx";
import "views/inicio/inicio.css";
import "views/MiLegajo/milegajo.css";
import ReactExport from 'react-export-excel';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ConsultarLegajoTrabajador extends Component {

    constructor(props) {
        super(props);
        this.state = {
          path: 'legajo',
          usuario: localStorage.getItem('nickname'),
          token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,          
          vista: [],
          isLoading: true,          
          id: JSON.parse(localStorage.getItem('legajo')).legajo[0].idpersona,
          nombre: JSON.parse(localStorage.getItem('legajo')).legajo[1].nombre,
          multiDataSet: [],
          legajos: [],
          idlegajo:""
        }     
    }

    async componentDidMount() {
        if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
            axios.get(validator + this.state.path + '/'+ this.state.usuario, {
            headers: {
                Authorization: this.state.token
            }
            }).then((response) => {
                let permisos = response.data;
                if(permisos.codError != 1){
                    this.props.history.goBack()
                }else{
                  this.listarSecciones();
                  this.verificarLegajo();
                  //this.reporteLegajo(this.state.id);
                }
            },(error) => {
                if(auth0Client.isAuthenticated() === true){
                auth0Client.signOut()
                this.props.history.replace('/');
                }else{
                this.props.history.goBack()
                }
            });
        }else{
            auth0Client.signOut();
            this.props.history.replace('/');
        }
    }

    async listarSecciones(){
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'legajo/listSeccionesLegajo?filtro=1')
        .then((res) => {         
            let lista = res.data.response;           
            this.setState({vistas: lista, isLoading: false})            
          })
        .catch((error) => { 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              this.setState({isLoading: false})
            }
        });
    }

    async verificarLegajo(){
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'legajo/verificarLegajoPersona?idpersona=' + this.state.id)
        .then((res) => {         
            let lista2 = res.data.response;  
            if(lista2.length != 0){
                lista2.map((data) => {
                    this.setState(
                    {
                        idlegajo: data.id_legajo,
                        estadolegajo: data.estado
                    })  
                })
            }else{
                this.setState(
                {
                    idlegajo: 0,
                    estadolegajo: 0
                }) 
            }                   
        })
        .catch((error) => { 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              this.setState({isLoading: false})
            }
        });
    }

    async detalle(data){     
        paramLegajo.legajo[0].idpersona = this.state.id
        paramLegajo.legajo[1].nombre = this.state.nombre
        paramLegajo.legajo[2].idcategoria = data.id_seccion
        paramLegajo.legajo[3].descategoria = data.descripcion
        paramLegajo.legajo[4].enlace = "T"   
        paramLegajo.legajo[5].idlegajo = this.state.idlegajo
        localStorage.setItem('legajo', JSON.stringify(paramLegajo));
    }

    async regresar(){       
        this.props.history.push('/legajo');       
    }

    reporteLegajo(codigo){
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.get(`${sisperplus}` + 'legajo/generarReporteLegajo?idpersona='+ codigo)
        .then((res) => {
            let lista = [];
            let lista2 = res.data.response;
            if(lista2 != null){
              lista2.map((data) => {
                lista.push({                
                  item: data.item,    
                  nombre: data.nombrecompleto,
                  nomcategoria: data.nomcategoria,
                  nomtipodocumento: data.nomtipodocumento,
                  nomarchivo: data.nom_archivo,
                  nomdocumento: data.des_documento,
                  fecharegistro: data.fecha_registro,
                  estado: data.estado,
                  motivoanula: data.motivo_anula  
                })
            })           
              this.generarExcel(lista)
            }else{
              this.setState({legajos: [], isLoading: false})
              this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
            }
          })
        .catch((error) => { 
            if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
            }else{
              console.log(error);
              this.setState({isLoading: false})
            }
        });
      }
    
      generarExcel(lista){
        let dataExcel = [];
        lista.map((data) => {
          dataExcel.push(
            [          
              {value: JSON.stringify(data.item), style: {font: {sz: "10.5", bold: true}, alignment: {horizontal: "center"}}},
              {value: data.nombre == null ? '' : data.nombre},
              {value: data.nomcategoria == null ? '' : data.nomcategoria},
              {value: data.nomtipodocumento == null ? '' : data.nomtipodocumento},
              {value: data.nomarchivo == null ? '' : data.nomarchivo},
              {value: data.nomdocumento == null ? '' : data.nomdocumento},
              {value: data.fecharegistro == null ? '' : data.fecharegistro},
              {value: data.estado == null ? '' : data.estado},
              {value: data.motivoanula == null ? '' : data.motivoanula},        
            ]
          )
        });
        const dataSet = [
          {
            xSteps: 5,
            columns: ["REPORTE DE LEGAJOS"],
            data: []
          },
          {
              columns: ["TRIBUNAL CONSTITUCIONAL DEL PERÚ"],   
              data: []       
          },
          {
              columns: ["ITEM", "TRABAJADOR", "LEGAJO", "DOCUMENTO", "ARCHIVO", "DESCRIPCIÓN", "FECHA REGISTRO", "ESTADO", "MOTIVO ANULACIÓN"],
              data: dataExcel
          },     
        ];
        this.setState({
          legajos: lista, 
          multiDataSet: dataSet,      
          isLoading: false
        })
    }

    render(){
        return(
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <main role="main">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-6">
                                            <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Trabajador <a style={{fontWeight: "bold", color: "black",fontSize: 20}}>{this.state.nombre}</a></h4>
                                            </div>                                            
                                            <div className="col-md-6" style={{textAlign: 'right'}}>
                                                <Button style={{width: 250, marginTop: 5, marginBottom: 10,marginRight: 10,}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                                                {this.state.multiDataSet != '' ? (
                                                <ExcelFile filename="ReporteLegajo" element={<Button style={{width: 250, marginTop: 5, marginBottom: 10,marginRight: 10,}} bsStyle="success" pullRight fill type="submit">Exportar legajo</Button>} >
                                                    <ExcelSheet dataSet={this.state.multiDataSet} name="Reporte">                      
                                                    </ExcelSheet>
                                                </ExcelFile>
                                                ) : (null)}
                                            </div>                                            
                                        </div>                                       
                                    </div>
                                    <div className="album py-5 bg-light">
                                        <div style={{marginRight: 50, marginLeft: 50}}>
                                        <div className="row" style={{marginTop: 30}}>
                                        {this.state.isLoading ? (
                                        <ClipLoader
                                            css={override}
                                            sizeUnit={"px"}
                                            size={50}
                                            color={'#123abc'}
                                            loading={this.state.loading}
                                        />
                                        ) : (
                                        this.state.vistas.map((data, i) => {
                                                return (
                                           
                                                    <NavLink key={i} to={data.url} onClick = {() => {this.detalle(data)}} 
                                                        className={this.state.idlegajo == 0 && data.id_seccion != 1 ? "isDisabled" : data.url == '' ? "isDisabled" : ""}>
                                                        <div className="col-md-6" >
                                                            <div className="wrap">
                                                                <div className="ico-wrap" style={{width:65}}>
                                                                    <i className="pe-7s-check" style={{color:"black",fontSize: 40, width:20}}></i>
                                                                </div>
                                                                <div className="text-wrap vcenter">
                                                                    <h2 className="mbr-fonts-style" style={{fontSize:14}}>{data.descripcion}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                               
                                                );
                                            }))}
                                        </div>
                                        </div>
                                    </div>
                                    </main>
                                }
                            />

                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

}

export default ConsultarLegajoTrabajador;