import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, cec, fechasBusqueda, paramCec } from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ListaSubSecciones extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'publicaciones',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      seccion: JSON.parse(localStorage.getItem("cec")).secciones[0].data.cnum_seccion,
      nidcategoria: JSON.parse(localStorage.getItem("cec")).cuaderno[0].data.nidcategoria
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.color= this.color.bind(this);
    this.ver = this.ver.bind(this);
 }


  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
             this.listActCategorias();
             console.log(JSON.parse(localStorage.getItem("cec")).secciones[0].data.cnum_seccion)
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 
 
  async listActCategorias() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${cec}` + 'public/listActSubCategorias?categoria='+ this.state.nidcategoria +  '&subcategoria=1&seccion=' + this.state.seccion)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              ctxt_titulo: data.ctxt_titulo,
              ctxt_descripcion: data.ctxt_descripcion,
              cnum_seccion: data.cnum_seccion,
              ctxt_link: data.ctxt_link,
              item: data.item,
              nid: data.nid,
              seccion: "subcategoria"
            })
          })

          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea anular la publicación?',
      message: 'Se anulará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.delete(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };
  
  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', data.nid);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'medios/anularLineasCategoria', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 1){
            this.listActCategorias();
            this.props.handleClick("tl", "info", "El contenido ha sido anulado!", "pe-7s-smile")
        }else{
            this.props.handleClick("tl", "error", "No se puede manejar el contenido!", "pe-7s-close")
            this.setState({isLoading: false})
        }
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al manejar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }



  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ir(row,"E") }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-success"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Anular Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span> 
          </div>
      );
    }else{}
  }

  async ir(data, param){ 
    if(param=="N"){
      paramCec.internacional[0].data = {cnum_seccion: this.state.seccion, cate: "subcategoria"}
      localStorage.setItem('cec', JSON.stringify(paramCec))
      this.props.history.push('/adminLineas'); 
    }else{
      paramCec.internacional[0].data =data;
      localStorage.setItem('cec', JSON.stringify(paramCec))
      this.props.history.push('/adminLineas'); 
    }
  }

  async mostrar(imagen){
    window.open(imagen, "_blank")
  }

  ver(cell, row) {
    console.log(row.ctxt_link)
    if(row != undefined){
      return (
          <div>
            {row.ctxt_link!=null ? 
             <span data-tip="Ver Documento" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.mostrar(row.ctxt_link) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                <i className="fa fa-file-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>:null}
          </div>
            );
    }else{}
  }
  color(cell, row){
    if(row != undefined){
      return (
          <div>
              {row.nom_estado == "INACTIVO" ? (
              <Button style={{width:125}} bsStyle="danger"  type="submit">{row.nom_estado}</Button>
             ) : 
              <Button style={{width:125}} bsStyle="success"  type="submit">{row.nom_estado}</Button>
             }
          </div>
      );
    }else{}
  }

  regresar(){
    this.props.history.push('/listasecciones');
  }

  render() {
    const columns = [
   {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:90}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "ctxt_titulo", text: "TÍTULO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "ctxt_descripcion", text: "DESCRIPCIÓN",  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center',formatter: (cell, row) => <div dangerouslySetInnerHTML={{ __html: row.ctxt_descripcion }} />},
    {dataField: "ver", text: "DOCUMENTO", headerStyle: {backgroundColor: '#337AB7', color: "white",width:90}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.ver},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:120}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Sub-Secciones</h4>
                <Button style={{width: 250, marginTop: 5, marginBottom: 10,marginRight: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
               <Button style={{width: 250, marginTop: 5, marginBottom: 10,marginRight: 10}} bsStyle="info" fill type="submit" onClick={() => { this.ir([],"N") }}>Nuevo</Button>
             {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ListaSubSecciones;