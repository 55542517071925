import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";

import { pdf } from "@react-pdf/renderer";
import { PdfDocument } from "./mipdf";
import { PdfDocument2 } from "./mipdf2";

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import moment from 'moment'
import axios from 'axios';
import { sisperplus } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Cargar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'miTeletrabajo',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      organo: JSON.parse(localStorage.getItem('datos'))[0].area,
      cargo: JSON.parse(localStorage.getItem('datos'))[0].cargo,
      dni: localStorage.getItem('dni'),
      labores: [],
      producto: "",
      comentario: "NINGUNO",
      actividad: "",
      fecha: moment(new Date()).format('YYYY-MM-DD'),
      fechaP: moment(new Date()).format('YYYY-MM-DD'),
      fechaini: moment(moment().startOf('week').toDate()).format('YYYY-MM-DD'),
      fechafin: moment(moment().endOf('week').toDate()).format('YYYY-MM-DD'),
      tipoHorario: [{value: "0", label: "JORNADA LABORAL"},{value: "2", label: "HORARIO PRÁCTICAS PRE PROFESIONAL"},{value: "3", label: "HORARIO PRÁCTICAS PROFESIONAL"},{value: "1", label: "HORAS ADICIONALES"}],
      tipoHorario2: [{value: "0", label: "JORNADA LABORAL"},{value: "2", label: "HORARIO PRÁCTICAS PRE PROFESIONAL"},{value: "3", label: "HORARIO PRÁCTICAS PROFESIONAL"},{value: "1", label: "HORAS ADICIONALES"}],
      selectedOption: {value: "0", label: "JORNADA LABORAL"},
      selectedOption2: {value: "0", label: "JORNADA LABORAL"},
      horainicio: "08:00",
      horafinal: "16:45",
      horas: "07:45",
      minutos: 465,
      totalminutos: 0,
      nidcarga: "",
      estado: "",
      isLoading: false,
      base: "",
      persona: "",
      jefe: "",
      vista: false,
      isGoing: false,
    }
    this.handleCheck = this.handleCheck.bind(this);
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.handleProducto = this.handleProducto.bind(this);
    this.handleActividad = this.handleActividad.bind(this);
    this.handleComentario = this.handleComentario.bind(this);
    this.handleFecha = this.handleFecha.bind(this);
    this.handleFechaini = this.handleFechaini.bind(this);
    this.handleFechafin = this.handleFechafin.bind(this);
    this.handleHoraInicio = this.handleHoraInicio.bind(this);
    this.handleHoraFinal = this.handleHoraFinal.bind(this);
    this.color= this.color.bind(this);

  }
    handleCheck(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
    }

    handleProducto(event) {
        this.setState({producto: event.target.value});
    }

    handleHoraInicio(event) {
      this.setState({horainicio: event.target.value});
    }

    handleHoraFinal(event) {
      this.setState({horafinal: event.target.value});
    }

    handleActividad(event) {
        this.setState({actividad: event.target.value});
    }

    handleComentario(event) {
      this.setState({comentario: event.target.value});
    }

    handleFecha(event) {
      this.setState({fecha: event.target.value},() => this.cambiarHora());
    }
    
    handleFechaini(event) {
      this.setState({fechaini: event.target.value});
    }

    handleFechafin(event) {
      this.setState({fechafin: event.target.value});
    }

    handleChange = selectedOption => {
      this.setState(
      { selectedOption },
      () => this.cambiarHora()
      );
    };

    handleChange2 = selectedOption2 => {
      this.setState(
      { selectedOption2 },
      );
    };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.listarDatos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listarDatos() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'perfil/listDatos?usuario=' + this.state.usuario)
    .then((res) => {
      this.setState({
        persona: res.data.response[0].cnom_nombres + " " + res.data.response[0].cape_paterno + " " + res.data.response[0].cape_materno
      })
      this.naprob();
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async naprob() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'perfil/naprob?dni=' + this.state.dni)
    .then((res) => {
      let listaAprob = res.data.response
      listaAprob.map((data) => {
         if(data.nivel == 1){
          this.setState({jefe: data.aprobadorprincipal})
         }
      });
      this.listarLabores();
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async listarLabores() {
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${sisperplus}` + 'labores/listCargasxpersona?usuario=' + this.state.usuario + '&fechaini=' + this.state.fechaini + '&fechafin=' + this.state.fechafin + '&tipo=' + this.state.selectedOption2.value)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response.listCargas;
        let lista3 = res.data.response.listTotal[0].total;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { item: data.item,
                idlabor: data.nid,
                idarea: data.nid_area,
                oficina: data.oficina,
                producto: data.actividad,
                horas: data.horas + " Hrs",
                horas2: data.horas,
                horainicio: data.horainicio,
                horafinal: data.horafinal,
                actividad: data.producto,
                comentario: data.comentario,
                tipo: data.tipo,
                estado: data.estado == '' ? 'PENDIENTE' : data.estado,
                aprobador: data.aprobador,
                fechax: data.dfec_registro,
                fecha: Calendar.calendario12(data.dfec_registro),
                fecha2: data.dfec_modificacion==null?"":Calendar.calendario12(data.dfec_modificacion),
                observacion: data.observacion,
                creacion: Calendar.calendario9(data.dfecCreacion),
                
            })
          })
          this.setState({totalminutos: lista3, labores: lista, isLoading: false})
        }else{
          this.setState({labores: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  async delete(data){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', data.idlabor);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'labores/eliminarCarga', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.listarLabores();
        this.props.handleClick("tl", "info", "Tu actividad ha sido eliminada!", "pe-7s-smile")
      }else{
        this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
        this.setState({isLoading: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }

  async limpiar(){
    this.setState({selectedOption: {value: "0", label: "JORNADA LABORAL"}, horainicio: "08:00", horafinal: "16:45", horas: "07:45", minutos: 465, producto: "", actividad: "", comentario: "NINGUNO", nidcarga: "",estado:"", vista: false})
    this.listarLabores();
    this.props.handleClick("tl", "info", "Tu actividad ha sido registrada!", "pe-7s-smile")
  }

  async cancelar(){
    this.setState({selectedOption: {value: "0", label: "JORNADA LABORAL"}, horainicio: "08:00", horafinal: "16:45", horas: "07:45", minutos: 465, producto: "", actividad: "", comentario: "NINGUNO", nidcarga: "",estado:""})
  }

  async obtener(data){
    this.setState({
        producto: data.actividad, 
        horas: data.horas2, 
        horainicio: data.horainicio, 
        horafinal: data.horafinal, 
        actividad: data.producto, 
        comentario: data.comentario, 
        fecha: data.fechax,
        nidcarga: data.idlabor, 
        selectedOption: {value: '', label: data.tipo},
        estado: data.estado,
        vista: true,
        isGoing : data.estado=="CORREGIDO" ? true : false
    })
  }

  cambiarHora(){
    if(this.state.selectedOption.label == "JORNADA LABORAL"){
      this.setState({horainicio: "08:00", horafinal: "16:45", horas: "07:45", minutos: 465})
    }else if(this.state.selectedOption.label == "HORARIO PRÁCTICAS PRE PROFESIONAL"){
      this.setState({horainicio: "08:00", horafinal: "15:00", horas: "06:00", minutos: 375})
    }else if(this.state.selectedOption.label == "HORARIO PRÁCTICAS PROFESIONAL"){
      this.setState({horainicio: "08:00", horafinal: "16:45", horas: "07:45", minutos: 465})
    }else{ 
      const today = new Date(this.state.fecha);
      today.setDate(today.getDate() + 1);
      const dayOfWeek = today.getDay()
      if(dayOfWeek===6){
        this.setState({horainicio: "08:00", horafinal: "16:45", horas: "07:45", minutos: 465})
      }else{
        this.setState({horainicio: "15:00", horafinal: "20:45", horas: "05:45", minutos: 345})
      }
      
    }    
  }

  calcular(param){
    let divisiones = this.state.horainicio.split(":", 2);
    let divisiones2 = this.state.horafinal.split(":", 2);
    let horanumero = parseInt(divisiones[0])
    let minutonumero = parseInt(divisiones[1])
    let horanumero2 = parseInt(divisiones2[0])
    let minutonumero2 = parseInt(divisiones2[1])
    console.log(param)
    if(this.state.selectedOption.label == "JORNADA LABORAL" || this.state.selectedOption.label == "HORARIO PRÁCTICAS PROFESIONAL"){
      if(horanumero >= 8 && horanumero <= 15){
        if(horanumero2 == horanumero && minutonumero2 >= 1){
          this.calcular2(param)
        }else if(horanumero2 > horanumero && horanumero2 <= 16 && minutonumero2 <= 45){
          this.calcular2(param)
        }else{
          this.props.handleClick("tl", "warning", "El rango de horas es incorrecto!", "pe-7s-info")
        }
      }else if(horanumero == 16 && minutonumero <= 44){
        if(horanumero2 == horanumero && minutonumero2 <= 45){
          this.calcular2(param)
        }else{
          this.props.handleClick("tl", "warning", "El rango de horas es incorrecto!", "pe-7s-info")
        }
      }else{
        this.props.handleClick("tl", "warning", "El rango de horas es incorrecto!", "pe-7s-info")
      }
    }else if(this.state.selectedOption.label == "HORARIO PRÁCTICAS PRE PROFESIONAL"){
        if(horanumero >= 8 && horanumero <= 15){
          if(horanumero2 == horanumero && minutonumero2 >= 1){
            this.calcular2(param)
          }else if(horanumero2 > horanumero && horanumero2 <= 15 && minutonumero2 <= 0){
            this.calcular2(param)
          }else{
            this.props.handleClick("tl", "warning", "El rango de horas es incorrecto!", "pe-7s-info")
          }
        }else if(horanumero == 14 && minutonumero <= 59){
          if(horanumero2 == horanumero && minutonumero2 <= 0){
            this.calcular2(param)
          }else{
            this.props.handleClick("tl", "warning", "El rango de horas es incorrecto!", "pe-7s-info")
          }
        }else{
          this.props.handleClick("tl", "warning", "El rango de horas es incorrecto!", "pe-7s-info")
        }
    }else{
      console.log("horanumero", horanumero)
      if(horanumero >= 6 && horanumero <= 20){
        if(horanumero2 == horanumero && minutonumero2 >= 1){
          console.log("horanumero2 == horanumero", horanumero2 == horanumero)
          this.calcular2(param)
        }else if(horanumero2 > horanumero && horanumero2 <= 23 && minutonumero2 <= 45){
          this.calcular2(param)
        }else{
          this.props.handleClick("tl", "warning", "El rango de horas es incorrecto!", "pe-7s-info")
        }
      }else if(horanumero == 20 && minutonumero <= 44){
        if(horanumero2 == horanumero && minutonumero2 <= 45){
          this.calcular2(param)
        }else{
          this.props.handleClick("tl", "warning", "El rango de horas es incorrecto!", "pe-7s-info")
        }
      }else{
        this.props.handleClick("tl", "warning", "El rango de horas es incorrecto!", "pe-7s-info")
      }
    }
  }

  calcular2(param){
    const today = new Date(this.state.fecha);
      today.setDate(today.getDate() + 1);
      const dayOfWeek = today.getDay()
    var nuevahora = ""
    var nuevominutos = ""
    var hora1 = (this.state.horafinal).split(":")
    var hora2 = (this.state.horainicio).split(":")
    var t1 = new Date()
    var t2 = new Date()
    t1.setHours(hora1[0], hora1[1])
    t2.setHours(hora2[0], hora2[1])
    t1.setHours(t1.getHours() - t2.getHours(), t1.getMinutes() - t2.getMinutes());
    if((t1.getHours() * 60) + t1.getMinutes() >= 360){
      if(this.state.selectedOption.label == "JORNADA LABORAL" || 
      this.state.selectedOption.label == "HORARIO PRÁCTICAS PROFESIONAL" || 
      this.state.selectedOption.label == "HORARIO PRÁCTICAS PRE PROFESIONAL"){
        if(t1.getHours() < 10){ 
            nuevahora = "0" + (t1.getHours() - 1)
        }else{
            nuevahora = t1.getHours() - 1
        }
      }else{
        if(t1.getHours() < 10){ 
          console.log("t1.getHours()", t1.getHours())
          if(dayOfWeek===6){
            console.log(dayOfWeek,"dayOfWeek")
            nuevahora = "0" + (t1.getHours())//- 1)
          }else{
            nuevahora = "0" + (t1.getHours())
          }  
        }else{
          if(dayOfWeek===6){
            nuevahora = t1.getHours() //- 1
          }else{
            nuevahora = t1.getHours() 
          }     
        }
      }
      if(t1.getMinutes() < 10){ 
        nuevominutos = "0" + t1.getMinutes()
      }else{
        nuevominutos = t1.getMinutes() 
      }
    }else{
      if(t1.getHours() < 10){ 
        nuevahora = "0" + t1.getHours()
      }else{
        nuevahora = t1.getHours() 
      }
      if(t1.getMinutes() < 10){ 
        nuevominutos = "0" + t1.getMinutes()
      }else{
        nuevominutos = t1.getMinutes() 
      }
    }
    this.setState({horas: nuevahora + ":" + nuevominutos})
    this.calcular3(param, nuevahora + ":" + nuevominutos)
  }

  calcular3(param, tiempo){
    let divisiones = tiempo.split(":", 2);
    let num2 = divisiones[0].substring(1,2);
    let min = ((num2 * 60) + parseInt(divisiones[1]));
    // if(min >= 360){
    //   min = min - 60
    // }
    this.setState({minutos: min})
    if(param == 1){
      this.save(tiempo, min)
    }
  }

  async save(tiempo, min){
    this.setState({isLoading: true})
    if(this.state.nidcarga == ""){
        this.guardar(tiempo, min)
    }else{
        this.editar(tiempo, min)
    }
  }

  async guardar(tiempo, min){
   if(this.state.fecha.length==10 ){
    if(this.state.producto != "" && this.state.actividad != "" && this.state.comentario != "" && this.state.horainicio != "" && this.state.horafinal != ""   ){
        let fromData = new FormData();
        fromData.append('producto', this.state.producto);
        fromData.append('horainicio', this.state.horainicio);
        fromData.append('horafinal', this.state.horafinal);
        fromData.append('horas', tiempo);
        fromData.append('minutos', min);
        fromData.append('actividad', this.state.actividad);
        fromData.append('comentario', this.state.comentario);
        fromData.append('fecha', this.state.fecha);
        fromData.append('tipo', this.state.selectedOption.label);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'labores/insertarCarga', fromData)
        .then((response) => {
        var datos = response.data
        if(datos.response != null){
            this.limpiar();
        }else{
            this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
            this.setState({isLoading: false})
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            console.log(error);
            this.setState({isLoading: false})
        }
        });
    }else{
      this.setState({isLoading: false})
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
   }else{
      this.setState({isLoading: false})
      this.props.handleClick("tl", "warning", "La fecha ingresada no es válida!", "pe-7s-info")
   }
}
cambioCheck(check){
  if(check == true){
   return '4';
  }else{
    return '3';
  }
}

  async editar(tiempo, min){
    if(this.state.producto != "" && this.state.actividad != "" && this.state.comentario != "" && this.state.horainicio != "" && this.state.horafinal != ""){
      let fromData = new FormData();
      console.log(this.state.estado)
      fromData.append('producto', this.state.producto);
      fromData.append('horainicio', this.state.horainicio);
      fromData.append('horafinal', this.state.horafinal);
      fromData.append('horas', tiempo);
      fromData.append('minutos', min);
      fromData.append('actividad', this.state.actividad);
      fromData.append('comentario', this.state.comentario);
      fromData.append('fecha', this.state.fecha);
      fromData.append('tipo', this.state.selectedOption.label);
      fromData.append('estado',this.state.estado=="PENDIENTE" ? 0 : this.cambioCheck(this.state.isGoing) );
      fromData.append('nid', this.state.nidcarga);
      fromData.append('usuario', this.state.usuario);
      fromData.append('observacion', "");
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${sisperplus}` + 'labores/editarCarga', fromData)
      .then((response) => {
        var datos = response.data
        if(datos.response != null){
          this.limpiar();
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al eliminar!", "pe-7s-close")
          this.setState({isLoading: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
    }else{
      this.setState({isLoading: false})
      this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
    }
  }

  conversorHoras(minutos){
    var hours = Math.floor( minutos / 60);          
    var minutes = (minutos % 60);
    return hours + ":" + minutes;
  }

  async verificar(){
     if(this.state.selectedOption2.value == "0"){
       this.generarPDF1();
     }else{
       this.generarPDF2();
     }
  }

  async generarPDF1(){
    var row = [];
    console.log(this.state.totalminutos)
    row.push(
      { jefe: this.state.jefe,
        datos: this.state.persona,
        fechaini: Calendar.calendario6(this.state.fechaini),
        fechafin: Calendar.calendario6(this.state.fechafin),
        // tipo: this.state.selectedOption2.label,
        minutos: this.conversorHoras(this.state.totalminutos)
    })
    const blob = pdf(PdfDocument(this.state.labores, row)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data});
          }
        }
        return result;
    }); 
  }

  async generarPDF2(){
    var row = [];
     row.push(
      { organo: this.state.organo,
        area: this.state.organo,
        cargo: this.state.cargo,
        datos: this.state.persona,
        fechaini: Calendar.calendario6(this.state.fechaini),
        fechafin: Calendar.calendario6(this.state.fechafin),
        //tipo: this.state.selectedOption2.label,
        minutos: this.conversorHoras(this.state.totalminutos)
    })
    const blob = pdf(PdfDocument2(this.state.labores, row)).toBlob();
    function initPromise(numero) {
      return new Promise(function(res,rej) {
        res(numero);
      })
    }
    let data = initPromise(blob).then((result) => {
        if(result != null){
          var reader = new window.FileReader();
          reader.readAsDataURL(result); 
          reader.onloadend = () => {
              var base64data = reader.result;
              this.setState({base: base64data});
          }
        }
        return result;
    }); 
  }

  async regresar(){
    this.setState({base: ""})
  }

  async agregar(){
    this.cancelar()
    if(this.state.vista == true){
      this.setState({vista: false})
    }else{
      this.setState({vista: true})
    }
  }

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
        row.estado == 'PENDIENTE' || row.estado == 'OBSERVADO' || row.estado == 'CORREGIDO'  ? (
          <div>
              <span data-tip="Editar Actividad" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.obtener(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
              <span data-tip="Remover Actividad" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.delete(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
          ) : (null)
      );
    }else{}
  }
  color(cell, row){
    if(row != undefined){
      return (
          <div>
              {row.estado == "APROBADO" ? (
              <Button style={{width:110}} bsStyle="success"  type="submit">{row.estado}</Button>
             ) : row.estado == "OBSERVADO" ?           
             <Button style={{width:110}} bsStyle="warning"  type="submit">{row.estado}</Button>
             : row.estado == "PENDIENTE" ?  
             <Button style={{width:110}} bsStyle="primary"  type="submit">{row.estado}</Button>
             :
             <Button style={{width:110}} bsStyle="info"  type="submit">{row.estado}</Button>
             }
          </div>
      );
    }else{}
  }


  render() {
    const { selectedOption, selectedOption2 } = this.state;
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "tipo", text: "TIPO", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "oficina", text: "OFICINA", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "actividad", text: "ACTIVIDAD", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "horas", text: "HORAS LABORADAS", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      // {dataField: "producto", text: "PRODUCTO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "comentario", text: "COMENTARIO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "estado", text: "AUTORIZACIÓN", sort: true, filter: textFilter(), headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center',formatter: this.color},
      {dataField: "fecha", text: "FECHA DE TELETRABAJO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "aprobador", text: "APROBADO POR", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha2", text: "FECHA DE APROBACIÓN", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.labores.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
        {/* <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div> */}
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.labores }
              columns={ columns }
              expandRow={ expandRow }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              // cellEdit={ cellEditFactory({ mode: 'click' }) }
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    const expandRow = {
      onlyOneExpanding: true,
      renderer: row => (
        <div> 
          <div className="row">
            <div className="col-md-12">
              <p style={{fontWeight: 'bold', fontSize: 15}}>ACTIVIDAD: <a style={{color: '#333'}}>{ row.actividad }</a></p>
              <p style={{fontWeight: 'bold', fontSize: 15}}>PRODUCTO: <a style={{color: '#333'}}>{ row.producto }</a></p>
              {row.estado=="CORREGIDO" || row.estado=="OBSERVADO" ? 
              <p style={{fontWeight: 'bold', fontSize: 15}}>MOTIVO DE OBSERVACIÓN: <a style={{color: '#333'}}>{ row.observacion }</a></p>
              : null}
            </div>
          </div>
        </div>
      )
    };
    return (
      <div className="content">
        <Grid fluid>
        {this.state.vista == true ? (
          this.state.isLoading ? (
              <ClipLoader
                css={override}
                sizeUnit={"px"}
                size={50}
                color={'#123abc'}
                loading={this.state.loading}
              />
            ) : (
              <Row>
            <Col md={12}>
            <Card
                title="Registrar Actividad"
                content={
                  <div style={{marginBottom: -20}}>
                    <div className="row">
                        <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <ControlLabel>ACTIVIDAD</ControlLabel>
                            <FormControl componentClass="textarea" style={{  height: 150, maxHeight: 150, minHeight: 150, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.producto} onChange= {this.handleProducto}/>
                          </div>
                        </div>
                        <div className="row" style={{marginTop: 18}}>
                          <div className="col-md-12">
                            <ControlLabel>PRODUCTO</ControlLabel>
                            <FormControl componentClass="textarea" style={{  height: 150, maxHeight: 150, minHeight: 150, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.actividad} onChange= {this.handleActividad}/>
                          </div>
                        </div>
                        <div className="row" style={{marginTop: 18}}>
                          <div className="col-md-3" style={{marginBottom: 18}}>
                              <ControlLabel>TIPO DE HORARIO </ControlLabel>
                              <Select value={selectedOption} onChange={this.handleChange} options={this.state.tipoHorario}/>
                          </div>
                          <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>HORA INICIAL</ControlLabel>
                            <FormControl type="time" value={this.state.horainicio} onChange= {this.handleHoraInicio}/>
                          </div>
                          <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>HORA FINAL</ControlLabel>
                            <FormControl type="time" value={this.state.horafinal} onChange= {this.handleHoraFinal}/>
                          </div>
                          <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>Calcular Horas</ControlLabel><br></br>
                            <Button style={{width: 200, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.calcular(0) }}>Clic Aquí</Button>
                          </div>
                          <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>N° DE HORAS</ControlLabel>
                            <FormControl type="time" value={this.state.horas} disabled/>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-9">
                            <ControlLabel>COMENTARIO</ControlLabel>
                            <FormControl type="text" value={this.state.comentario} onChange= {this.handleComentario}/>
                          </div>
                          <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DEL TELETRABAJO</ControlLabel>
                            <FormControl type="date" value={this.state.fecha} onChange= {this.handleFecha} max={this.state.fechaP}/>
                          </div>
                        </div>
                        
                        </div>
                        {this.state.estado=="OBSERVADO" || this.state.estado=="CORREGIDO" ?  
                        <div className="col-md-12" style={{letterSpacing: 1}}>
                            <ControlLabel>CORRECIÓN DE ACTIVIDAD</ControlLabel><br></br>
                          <div>
                            <input onChange={this.handleCheck} name="isGoing" type="checkbox" checked={this.state.isGoing} style={{marginRight: 5, marginBottom:30}}
                            /><a>Marque solamente cuando la actividad ha sido corregida.</a>
                          </div>
                        </div>
                        :null}
                        <div className="col-md-12">
                          <ControlLabel>ACCIONES</ControlLabel><br></br>
                          <Button style={{width: 200, marginRight: 10, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.calcular(1) }}>Guardar Actividad</Button>
                          <Button style={{width: 200, marginRight: 10, marginBottom: 10}} bsStyle="warning" fill type="submit" onClick={() => { this.cancelar() }}>Limpiar Datos</Button>
                          <Button style={{width: 200, marginRight: 10, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.agregar() }}>Regresar</Button>
                        </div>
                    </div>
                    <h4 className="category" style={{fontSize: 15, marginTop: 20}}>NOTA: Solo se deberá agregar un registro por día de 7 Hrs y 45 Min como máximo para Jornada Laboral y 2 hrs como máximo para Horas Adicionales (Ley N° 31572)</h4>
                  </div>}
              />
            </Col>
          </Row>
          )
          ) : (
          this.state.base == "" ? (
          <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Listado de Actividades</h4>
                <div className="row">
                    <div className="col-md-3" style={{marginBottom: 18}}>
                        <ControlLabel>TIPO DE HORARIO</ControlLabel>
                        <Select value={selectedOption2} onChange={this.handleChange2} options={this.state.tipoHorario2}/>
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE INICIO</ControlLabel>
                        <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaini}/>
                    </div>
                    <div className="col-md-2" style={{marginBottom: 18}}>
                        <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                        <FormControl type="date" value={this.state.fechafin} onChange= {this.handleFechafin}/>
                    </div>
                    <div className="col-md-5" style={{marginBottom: 18}}>
                        <ControlLabel></ControlLabel><br></br>
                        <Button style={{width: 150, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.listarLabores() }}>Buscar Actividades</Button>
                        <Button style={{width: 150, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.verificar() }}>Generar PDF</Button>
                        <Button style={{width: 150, marginTop: 5, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.agregar() }}>Agregar Actividad</Button>
                    </div>
                </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
          ) : (
          <Row>
          <Col md={12}>
            <Card
                title="Ver Documento"
                content={
                  <div>
                    <iframe src={this.state.base} style={{height: 1200, width: "100%"}}></iframe>
                    <Button style={{width: 150, marginTop: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                  </div>
                 }
              />
            </Col>
        </Row>
        )
        )}
        </Grid>
      </div>
    );
  }
}

export default Cargar;