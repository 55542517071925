import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {sisperplus , paramPersonal} from 'store/Apis'
import { Card } from "components/Card/Card.jsx";

import 'react-confirm-alert/src/react-confirm-alert.css';
import { NavLink } from 'react-router-dom';
import Button from "components/CustomButton/CustomButton.jsx";
import InputFiles from 'react-input-files';
import logo from "assets/img/tc.jpg";
import fondo from "assets/img/fondo.jpg";
import fondo2 from "assets/img/fondo2.png";

import domtoimage from 'dom-to-image';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class GenerarFirma extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'generarFirma',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      isLoading: true,
      nombre:JSON.parse(localStorage.getItem('datos'))[0].nombre,
      cargo:JSON.parse(localStorage.getItem('datos'))[0].cargo,
      area:JSON.parse(localStorage.getItem('datos'))[0].area == null ? "" : JSON.parse(localStorage.getItem('datos'))[0].area,
      celular:"",
      correo:"",
      direccion:""
   
    }

    this.handleNombre = this.handleNombre.bind(this);
    this.handleCargo = this.handleCargo.bind(this);
    this.handleArea = this.handleArea.bind(this);
    this.handleCelular = this.handleCelular.bind(this);
    this.handleCorreo = this.handleCorreo.bind(this);
    this.handleDireccion = this.handleDireccion.bind(this);

  }

    handleNombre(event) {this.setState({nombre: event.target.value});}
    handleCargo(event) {this.setState({cargo: event.target.value});}
    handleArea(event) {this.setState({area: event.target.value});}
    handleCelular(event) {this.setState({celular: event.target.value});}
    handleCorreo(event) {this.setState({correo: event.target.value});}
    handleDireccion(event) {this.setState({direccion: event.target.value});}


  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
             this.setState({isLoading: false})
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }


    download(){
        if(this.state.nombre !="" && this.state.area !="" && this.state.cargo !="" && 
        this.state.celular !="" && this.state.correo !="" && this.state.direccion !=""  ){
            var ticket = document.getElementById("my-node");
                    domtoimage.toPng(ticket).then((data)=>{
                        var link = document.createElement("a");
                        link.download ="Firma";
                        link.href =data;
                        link.click();
                    })
        }else{
            this.props.handleClick("tl", "error", "Por favor complete todos los campos...", "pe-7s-close") 
        }
       
    }

 
  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>GENERAR FIRMA</h4>
                <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel><i className="fa fa-user"></i> NOMBRES Y APELLIDOS</ControlLabel>
                            <FormControl  type="text" value={this.state.nombre} onChange={this.handleNombre} />
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel><i className="fa fa-th-large" ></i> ÁREA</ControlLabel>
                            <FormControl type="text" value={this.state.area} onChange={this.handleArea} />
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel><i className="fa fa-briefcase" ></i> CARGO</ControlLabel>
                            <FormControl type="text" value={this.state.cargo} onChange={this.handleCargo} />
                        </div>
                        
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel><i className="fa fa-volume-control-phone" ></i> N° CELULAR</ControlLabel>
                            <FormControl type="number" value={this.state.celular} onChange={this.handleCelular} />
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel> <i className="fa fa-envelope-o" ></i> CORREO</ControlLabel>
                            <FormControl type="text" value={this.state.correo} onChange={this.handleCorreo} />
                        </div>
                        <div className="col-md-4" style={{marginBottom: 50}}>
                            <ControlLabel><i className="fa fa-building-o" ></i> SEDE </ControlLabel>
                            <FormControl type="text" value={this.state.direccion} onChange={this.handleDireccion} />
                        </div>  
                             
                </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                  
                    <div className="row">
                        <div className="col-md-2"></div>
                       <div className="col-md-10" >

                        <div className="card" style={{width:'80%'}} >
                            <table id="my-node">
                                <tbody>
                                    <tr>
                                        <td style={{backgroundColor:"white" }}>
                                            <div style={{display:"flex"}}>
                                                <div style={{paddingRight:6}}>
                                                    <a><img style={{width: 160, height: 160,marginTop:-20}} src={logo}></img></a>
                                                </div>
                                                <div style={{backgroundColor: '#871B24', width: 3, height: 135 , marginTop:-5}}> </div>                     
                                            </div>
                                        </td>
                                        <td style={{backgroundColor:"white"}}>
                                            <p  style={{fontFamily:"serif", fontSize:17,marginTop:20,fontWeight: "bold", color:"black",textAlign:"center"}}> {this.state.nombre.toUpperCase()}</p>
                                            <hr style={{ width:200 ,height:1, background:"#9B9A9A", marginTop:-10,marginBottom:10}}></hr>
                                            <p  style={{fontFamily:"serif", fontSize:14, color:"black",textAlign:"center"}}> {this.state.area.toUpperCase()}</p>
                                            <p  style={{fontFamily:"serif", fontSize:16,color:"black",textAlign:"center" ,marginBottom:-5}}> {this.state.cargo}</p>
                                            <img style={{width: 500, height: 70, float:"center"}} src={fondo}></img>
                                        </td>
                                        <td style={{backgroundColor:"white"}}>
                                            <div style={{backgroundColor: '#871B24', width: 3, height: 135,marginTop:5}}> </div> 
                                            <div><img style={{width: 90, height: 120, float:"center",marginTop:-155}} src={fondo2}></img></div>
                                        </td>
                                        <td style={{backgroundColor:"white" , width:450}}>
                                            <p  style={{fontFamily:"serif", fontSize:15, color:"black"}} >{this.state.celular}</p>
                                            <p  style={{fontFamily:"serif", fontSize:15, color:"black" ,marginTop:17}}> {this.state.correo}</p> 
                                            <p  style={{fontFamily:"serif", fontSize:15, color:"black",marginTop:18}}> {this.state.direccion}</p> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4">
                                            <div style={{backgroundColor: '#871B24', width: '100%', height: 3, marginTop:-20}}> </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                         </div>
                         <Button style={{width: 250}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.download() }}>DESCARGAR FIRMA</Button>
                         </div>

                         
                    </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
export default GenerarFirma;