import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import auth0Client from 'login/Auth';
import axios from 'axios';
import { gestor, sisper, sisperplus } from 'store/Apis'

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import InputFiles from 'react-input-files';
import Select from 'react-select';
import moment from 'moment';

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import CheckboxGroup from 'react-checkbox-group'
import { validator } from 'store/Apis'
import ReactTooltip from 'react-tooltip';
import { data } from "jquery";
import { confirmAlert } from 'react-confirm-alert';
import "react-confirm-alert/src/react-confirm-alert.css";
import { Unidades } from "views/efectivo/conversor";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class EditarArea extends Component {

constructor(props) {
super(props);
  this.state = {
    path: 'administrarAreas',
    dni: localStorage.getItem('dni'),
    usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
    id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
    token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
    descripcion :"",
    name:"",
    referencia: "",
    jefeF: "",
    jefeS: "",
    jefes: [],
    nid:"",
    isLoading:true,
    isGoing: false,
    dnijefeSecundario: "",
    disabledss: true,
    nombreReferencia: "",

  }
  
  this.handleDescripcion = this.handleDescripcion.bind(this);
  this.handleName = this.handleName.bind(this);
  this.handleReferencia = this.handleReferencia.bind(this);
  this.handleCheck = this.handleCheck.bind(this);


}

handleDescripcion(event) {this.setState({descripcion: event.target.value});}
handleName(event) {this.setState({name: event.target.value});}
handleReferencia(event) {this.setState({referencia: event.target.value});}


handleJefeF = jefeF => {
    this.setState(
    { jefeF },
    );
};

handleJefeS = jefeS => {
    if(jefeS.jefatura === 1){
      this.setState({isGoing: true})
    } else{
      this.setState({isGoing: false})
    }
    this.setState(
    { jefeS, dnijefeSecundario: jefeS.DocumentNumber, nombreReferencia: jefeS.Name},
    );
};
handleCheck = (event) => {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;
    this.confirmarActualizarJefaturaHabilitar(
      this.state.isGoing === true ? 1 : 0, 
      this.state.dnijefeSecundario, 
      this.state.usuario,
      this.state.nombreReferencia,
      name,
      value
    );
  
};
confirmarActualizarJefaturaHabilitar = (estado, dni, usuario, referenciaNombre, name, newValue) => {
  
  if (estado === 1) {
    confirmAlert({
      title: estado === 0 ? '¿Seguro que desea habilitar  la jefatura?' : '¿Seguro que desea deshabilitar la jefatura?',
      message: estado === 0 ? 'Se habilitará la jefatura de ' + referenciaNombre : 'Se deshabilitará la jefatura de ' + referenciaNombre,
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => {
            this.actualizarJefatura(estado, dni, usuario);
            this.setState({ [name]: newValue }); 
          } 
        },
        {
          label: 'Cancelar',
          onClick: () => {
            this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info");
            this.setState({ [name]: !newValue });
          }
        }
      ]
    });
  } else {
    this.actualizarJefatura(estado, dni, usuario);
    this.setState({ [name]: newValue }); 
  }
  
};


  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
              this.validarLocal();
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(JSON.parse(localStorage.getItem("recursoshumanos")).areaAdm[0].data !== null){
     this.setState({
          descripcion: JSON.parse(localStorage.getItem('recursoshumanos')).areaAdm[0].data.descripcion,
          name: JSON.parse(localStorage.getItem('recursoshumanos')).areaAdm[0].data.name,
          referencia: JSON.parse(localStorage.getItem('recursoshumanos')).areaAdm[0].data.referencia == null ? "" : JSON.parse(localStorage.getItem('recursoshumanos')).areaAdm[0].data.referencia ,
          nid: JSON.parse(localStorage.getItem('recursoshumanos')).areaAdm[0].data.nid,
          jefeF: {value: JSON.parse(localStorage.getItem('recursoshumanos')).areaAdm[0].data.firstperson,
                  label: JSON.parse(localStorage.getItem('recursoshumanos')).areaAdm[0].data.namefirstperson},
          jefeS: {value: JSON.parse(localStorage.getItem('recursoshumanos')).areaAdm[0].data.secondperson,
                  label: JSON.parse(localStorage.getItem('recursoshumanos')).areaAdm[0].data.namesecondperson},
        }); 
        this.listJefeF();
        }else{
      this.props.history.push('/adminAreas');
    }
  }

async actualizarJefatura(estado, dni, usuario){
  let fromData = new FormData();
  fromData.append('dni', dni);
  fromData.append('usuario', usuario);
  if (estado ===1) {
    fromData.append('estado', 0);
  } else {
    fromData.append('estado', 1);
  }

  axios.defaults.headers.common['Authorization'] = this.state.token
  axios.post(`${sisperplus}` + 'area/actualizarjefe', fromData)
   .then((response) => {
        var datos = response.data
        if(datos.response != null){
          this.listJefeF();
        }
        })
        .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
        }else{
            this.setState({isLoading: false})
        }
      }); 
}

  async listJefeF() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'area/listPerson')
    .then((res) => {
        let lista = [];
        
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.Person,
                label: data.Name,
                jefatura: data.jefatura,
                DocumentNumber: data.DocumentNumber,
                Name: data.Name
            })
          });
          const jefeEncontrado = lista2.find((data) => data.Person === this.state.jefeS.value);
          if (jefeEncontrado) {
            this.setState({ 
              isGoing: jefeEncontrado.jefatura === 1, 
              dnijefeSecundario: jefeEncontrado.DocumentNumber, 
              disabledss: jefeEncontrado.jefatura === 1 ? true : false,
              nombreReferencia: jefeEncontrado.Name 
            });
          }
          this.setState({jefes: lista, isLoading: false})
    }else{
        this.setState({jefes: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        this.setState({isLoading: false})
      }
    });
   }

async regresar(){
   this.props.history.push('/adminAreas');
}

  validar(){
    if(this.state.descripcion != ''  &&  this.state.name != ''  && this.state.jefeS.value != "" && this.state.jefeF.value != ""){
        this.guardar();
       }else{
         this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close") 
       }
  }
  async guardar(){
          this.setState({isLoading: true})
          let fromData = new FormData();
          fromData.append('nid', this.state.nid);
          fromData.append('descripcion', this.state.descripcion.toUpperCase());
          fromData.append('nameArea', this.state.name.toUpperCase());
          fromData.append('referencia', this.state.referencia);
          fromData.append('firstperson', this.state.jefeF.value);
          fromData.append('secondperson', this.state.jefeS.value);
          fromData.append('namefirstperson', this.state.jefeF.label);
          fromData.append('namesecondperson', this.state.jefeS.label);
          fromData.append('usuario', this.state.usuario);
          axios.defaults.headers.common['Authorization'] = this.state.token
          axios.post(`${sisperplus}` + 'area/editarArea', fromData)
           .then((response) => {
                  var datos = response.data
                  if(datos.response != null){
                    this.props.handleClick("tl", "success", "Área actualizada correctamente!", "pe-7s-close")
                    this.regresar();
                  }else{
                    this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
                    this.setState({isLoading: false})
                  }
                })
                .catch((error) =>{ 
                if(auth0Client.isAuthenticated() === true){
                    auth0Client.signOut()
                    this.props.history.replace('/');
                }else{
                    this.setState({isLoading: false})
                }
              });
        }


  render() {
  
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
            <Card
                title="EDITAR ÁREA DEL TC"
                content={
                  this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                  <div>
                    <div className="row">
                        <div className="col-md-12" style={{marginBottom: 18}}>
                            <ControlLabel>NOMBRE DEL ÁREA</ControlLabel>
                            <FormControl  type="text" value={this.state.descripcion}  onChange={this.handleDescripcion} />
                        </div>
                        <div className="col-md-12" style={{marginBottom: 18}}>
                            <ControlLabel>DESCRIPCIÓN</ControlLabel>
                            <FormControl componentClass="textarea" 
                            style={{ height: 50, maxHeight: 50, minHeight: 50, width: "100%", maxWidth:"100%", minWidth:"100%" }} 
                            value={this.state.name} onChange={this.handleName}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>JEFE PRINCIPAL</ControlLabel>
                            <Select value={this.state.jefeF} onChange={this.handleJefeF} options={this.state.jefes} />
                            
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>JEFE SECUNDARIO</ControlLabel>
                            <Select  value={this.state.jefeS} onChange={this.handleJefeS} options={this.state.jefes} />
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>REFERENCIA <a>(OPCIONAL)</a></ControlLabel>
                            <FormControl componentClass="textarea"
                             style={{ height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%" }} 
                             value={this.state.referencia} onChange= {this.handleReferencia}/>
                        </div>
                        {/* <div className="col-md-4" style={{letterSpacing: 1}}>
                        <ControlLabel>Habilitar Jefatura</ControlLabel><br></br>
                        <div>
                            <input onChange={this.handleCheck} name="isGoing" type="checkbox" checked={this.state.isGoing} style={{marginRight: 5}}/>
                        </div>
                        <a>Marque solamente cuando quiera Habilitar la opcion de jefatura al jefe secundario.</a>
                        </div> */}
                        
                        
                    </div>
                    {/* <div className="row">
                      <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>REFERENCIA <a>(OPCIONAL)</a></ControlLabel>
                            <FormControl componentClass="textarea"
                             style={{ height: 100, maxHeight: 100, minHeight: 100, width: "100%", maxWidth:"100%", minWidth:"100%" }} 
                             value={this.state.referencia} onChange= {this.handleReferencia}/>
                        </div>
                    </div> */}
                    <Button style={{width: 200, marginLeft: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Actualización</Button>
                    <Button style={{width: 200, marginLeft: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.validar() }}>Actualizar Registro</Button>
                  </div>
                 )}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarArea;