import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import ReactExport from 'react-export-excel';
import moment from 'moment'
import { sisperplus } from "store/Apis";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Consolidado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: 'consolidado',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      multiDataSet: [],
      consolidados: [],

      fechaini: moment().startOf('month').format('YYYY-MM-DD'),
      fechafin: moment().format('YYYY-MM-DD'),
      
      isLoading: true,
      trabajador: { value: null, label: 'Todos' },
      trabajadores: [],
      isTrabajadorEnabled: false,
      regimen: { value: null, label: 'Todos' },
      regimenes: [],
      isRegimenEnabled: false,
      evento: { value: null, label: 'Todos' },
      eventos: [],
      isEventoEnabled: false,
      modalidad: '',
      modalidades: [],
      area: { value: null, label: 'Todos' },
      areas: [],
      isAreaEnabled: false,

    }

    this.handleFechaini = this.handleFechaini.bind(this);
    this.handleFechafin = this.handleFechafin.bind(this);
    //this.handleRegimen = this.handleRegimen.bind(this);

  }

  handleFechaini(event) { 
    //this.setState({ fechaini: event.target.value }) 
    const selectedDate = event.target.value;    
    console.log("Fecha de inicio seleccionada:", selectedDate);
    this.setState({ fechaini: selectedDate });
  }

  handleFechafin = (event) => {
    const selectedDate = event.target.value;
    console.log("Fecha de término seleccionada:", selectedDate);
    const currentDate = moment().format('YYYY-MM-DD');

    if (selectedDate <= currentDate) {
      this.setState({ fechafin: selectedDate });
    } else {
      this.props.handleClick("tl", "warning", "La fecha de término no puede ser mayor a la fecha actual", "pe-7s-info")
    }
  };

  //handleRegimen(event) { this.setState({ regimen: event.target.value }); }

  changeTrabajador = trabajador => {    
    this.setState(
      { trabajador },
    );
  };

  changeRegimen = regimen => {
    console.log('regimen', regimen);
    this.setState(
      { regimen },
    );
  };

  changeModalidad = modalidad => {
    this.setState(
      { modalidad },
    );
  };

  changeEvento = evento => {
    console.log('evento', evento);
    this.setState(
      { evento },
    );
  };

  changeArea = area => {
    this.setState(
      { area },
      () => this.listarTrabajadoresxArea()
    );
  };

  toggleTrabajadorEnabled = () => {
    this.setState((prevState) => ({
      isTrabajadorEnabled: !prevState.isTrabajadorEnabled,
      trabajador: prevState.isTrabajadorEnabled ? prevState.trabajador : { value: '', label: 'Todos' },
    }));
  };

  toggleRegimenEnabled = () => {
    this.setState((prevState) => ({
      isRegimenEnabled: !prevState.isRegimenEnabled,
      regimen: prevState.isRegimenEnabled ? prevState.regimen : { value: '', label: 'Todos' },
    }));
  };

  toggleEventoEnabled = () => {
    this.setState((prevState) => ({
      isEventoEnabled: !prevState.isEventoEnabled,
      evento: prevState.isEventoEnabled ? prevState.evento : { value: '', label: 'Todos' },
    }));
  };

  
  toggleAreaEnabled = () => {
    this.setState((prevState) => ({
      isAreaEnabled: !prevState.isAreaEnabled,
      area: prevState.isAreaEnabled ? prevState.area : { value: '', label: 'Todos' },
    }), () => {
      // Si se desmarcó el checkbox de área, volvemos a listar los trabajadores
      if (!this.state.isAreaEnabled) {
        this.listarTrabajador();  
      }
    });
  };
  
/*
  toggleAreaEnabled = () => {
    this.setState(prevState => ({
      isAreaEnabled: !prevState.isAreaEnabled,
      area: prevState.isAreaEnabled ? prevState.area : { value: '', label: 'Todos' },
      trabajador: prevState.isAreaEnabled ? prevState.trabajador : { value: '', label: 'Todos' }, // Resetear trabajador
    }), () => {
      // Si se desmarcó el checkbox de área, volvemos a listar los trabajadores
      if (!this.state.isAreaEnabled) {
        this.listarTrabajador(); 
      }
    });
  };
  */


  async componentDidMount() {
    if (localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null  && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null) {
      axios.get(validator + this.state.path + '/' + this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
      }).then((response) => {
        let permisos = response.data;
        if (permisos.codError != 1) {
          this.props.history.goBack()
        } else {
          this.listarTrabajador();
          this.listarRegimen();
          this.listarEvento();
          this.listarAreas();
          //this.listarConsolidados();              
        }
      }, (error) => {
        if (auth0Client.isAuthenticated() === true) {
          auth0Client.signOut()
          this.props.history.replace('/');
        } else {
          this.props.history.goBack()
        }
      });
    } else {
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  async listarXfechas() {
    this.setState({ isLoading: true })
    let fecha1 = moment(this.state.fechaini).format('YYYY-MM-DD')
    let fecha2 = moment(this.state.fechafin).format('YYYY-MM-DD')
    if (fecha1 <= fecha2) {
      this.listarConsolidados()
    } else {
      this.props.handleClick("tl", "warning", "La fecha de inicio no puede ser mayor a la fecha de término", "pe-7s-info")
      this.setState({ isLoading: false })
    }
  }


  async listarConsolidados() {    
    console.log('listar', this.state.trabajador.value, this.state.fechaini, this.state.fechafin, this.state.evento.value, this.state.regimen.value)
    this.setState({ isLoading: true })
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'consolidados/listarConsolidados?id=' + this.state.trabajador.value + '&fechaini=' + this.state.fechaini + '&fechafin=' + this.state.fechafin + '&regimenlaboral=' + this.state.regimen.value + '&evento=' + this.state.evento.value)
      .then((res) => {
        console.log('respuesta', res)
        let lista = [];
        let lista2 = res.data.response;
        if (lista2 != null) {           
          lista2.map((data) => {
            lista.push({
              item: data.item,
              dni: data.dni,
              nombresApellidos: data.nombres,              
              dia: data.dia,
              diainicio: data.fechaInicioEvento,
              diafin: data.fechaFinEvento,
              horainicio: data.horaInicioEvento,
              horafin: data.horaFinEvento,               
              tiempo: data.tiempo,   
              evento: data.nombreEvento               
            })
          })          
          
          this.setState({ consolidados: lista, isLoading: false })
          this.generarExcel(lista)
        } else {
          this.setState({ consolidados: [], isLoading: false })
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => {
        if (auth0Client.isAuthenticated() === true) {
          auth0Client.signOut()
          this.props.history.replace('/');
        } else {
          this.setState({ isLoading: false })
        }
      });
  }



  async listarTrabajador() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'area/listPerson')
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if (lista2 != null) {
          lista2.map((data) => {
            lista.push(
              {
                value: data.Person,
                label: data.Name,
              })
          });
          this.setState({ trabajadores: lista, trabajador: { value: '', label: 'Todos' }, isLoading: false })          
        } else {
          this.setState({ trabajadores: [], isLoading: false })
          this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
        }
      })
      .catch((error) => {
        if (auth0Client.isAuthenticated() === true) {
          auth0Client.signOut()
          this.props.history.replace('/');
        } else {
          console.log(error);
          this.setState({ isLoading: false })
        }
      });
  }

  async listarRegimen() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'trabajadores/listTipoPerson')
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if (lista2 != null) {
          lista2.map((data) => {
            lista.push(
              {
                value: data.PayRollType,
                label: data.Description,
              })
          })
          this.setState({ regimenes: lista, regimen: { value: '', label: 'Todos' }, isLoading: false })
          //this.listarTipoTrabajo()
        } else {
          this.setState({ regimenes: [], isLoading: false })
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => {
        if (auth0Client.isAuthenticated() === true) {
          auth0Client.signOut()
          this.props.history.replace('/');
        } else {
          console.log(error);
          this.setState({ isLoading: false })
        }
      });
  }

  /////Trabajo presencial, teletrabajo total o parcial
  /*
  async listarTipoTrabajo() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'trabajadores/listModalidades')
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if (lista2 != null) {
          lista2.map((data) => {
            lista.push(
              {
                value: data.nid,
                label: data.cnom_modalidad,
              })
          })
          this.setState({ modalidades: lista, modalidad: { value: '', label: 'Todos' } })
        } else {
          this.setState({ modalidades: [], isLoading: false })
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => {
        if (auth0Client.isAuthenticated() === true) {
          auth0Client.signOut()
          this.props.history.replace('/');
        } else {
          console.log(error);
          this.setState({ isLoading: false })
        }
      });
  }
  */


  async listarEvento() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'papeletas/tiposPapeletas?param=')
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if (lista2 != null) {
          lista2.map((data) => {
            lista.push(
              {
                value: data.tipopapeleta,
                label: data.descripcion,
              })
          });
          //this.setState({eventos: lista, evento:{ value: null, label: 'Todos'} }) 
          this.setState({ eventos: lista, evento: { value: '', label: 'Todos' }, isLoading: false })
        } else {
          this.setState({ eventos: [], isLoading: false })
          this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
        }
      })
      .catch((error) => {
        if (auth0Client.isAuthenticated() === true) {
          auth0Client.signOut()
          this.props.history.replace('/');
        } else {
          console.log(error);
          this.setState({ isLoading: false })
        }
      });
  }

  async listarAreas(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'labores/listAreas')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.ReplicationUnit,
                label: data.name,
            })
          });
          this.setState({areas: lista, area: { value: '', label: 'Todos' }, isLoading: false})
          //this.listarTrabajadoresxArea()
      }else{
        this.setState({areas: [], isLoading: false })
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }

  async listarTrabajadoresxArea(){
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'labores/listPersonasxarea?nid=' + this.state.area.value)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.Person,
                label: data.nombre,
                //dni: data.dni
            })
          });    
          this.setState({trabajadores: lista, trabajador:{ value: '', label: 'Todos' }, isLoading: false})
      }else{
        this.setState({trabajadores: []})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
      }
    });
  }



  generarExcel(lista){
    let dataExcel = [];
    lista.map((data) => {
      dataExcel.push(
        [
          {value: JSON.stringify(data.item), alignment: {horizontal: "center"}},
          {value: data.dni === null || undefined? '--' : data.dni},
          {value: data.nombresApellidos === null || undefined? '--' : data.nombresApellidos},         
          {value: data.dia === null || undefined? '--' : data.dia},
          {value: data.diainicio=== null || undefined? '--' : data.diainicio},
          {value: data.diafin === null || undefined? '--' : data.diafin},
          {value: data.horainicio === null || undefined? '--' : data.horainicio},
          {value: data.horafin === null || undefined? '--' : data.horafin},
          {value: data.tiempo === null || undefined? '--' : data.tiempo},
          {value: data.evento === null || undefined? '--' : data.evento},
      ]   
      )
    });
    const dataSet = [
      {
        columns: [
          'N°', //1
          'DNI', 
          'Apellidos y nombres',//2          
          'Día',
          'Fecha Inicio',
          'Fecha Fin',
          'Hora Inicio',
          'Hora Fin',
          'Tiempo', 
          'Evento'],
        data: dataExcel
      },
    ];
    this.setState({multiDataSet: dataSet})
  }
  

  render() {
    const { trabajador, regimen, evento, area, isTrabajadorEnabled, isRegimenEnabled, isEventoEnabled, isAreaEnabled } = this.state;
    const columns = [
      { dataField: "item", text: "ITEM", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white", width: 50 }, headerAlign: (column, colIndex) => 'center', align: 'center' },
      { dataField: "dni", text: "DNI", filter: textFilter(), sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white", }, headerAlign: (column, colIndex) => 'center', align: 'center' },
      { dataField: "nombresApellidos", text: "NOMBRE", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white", }, headerAlign: (column, colIndex) => 'center', align: 'center' },
      { dataField: "dia", text: "DÍA", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white", }, headerAlign: (column, colIndex) => 'center', align: 'center' },
      { dataField: "diainicio", text: "INICIO", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white", }, headerAlign: (column, colIndex) => 'center', align: 'center' },
      { dataField: "diafin", text: "FIN", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white", }, headerAlign: (column, colIndex) => 'center', align: 'center' },
      { dataField: "horainicio", text: "Hora Ini", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white", }, headerAlign: (column, colIndex) => 'center', align: 'center' },
      { dataField: "horafin", text: "Hora Fin", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white", }, headerAlign: (column, colIndex) => 'center', align: 'center' },
      { dataField: "tiempo", text: "Tiempo", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white", }, headerAlign: (column, colIndex) => 'center', align: 'center' },
      { dataField: "evento", text: "EVENTO", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white", }, headerAlign: (column, colIndex) => 'center', align: 'center' }
      /*{ dataField: "aut", text: "AUT", sort: true, headerStyle: { backgroundColor: '#337AB7', color: "white", }, headerAlign: (column, colIndex) => 'center', align: 'center' },*/
      /*{dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}*/
    ]

    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.consolidados.length
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={this.state.consolidados}
              columns={columns}
              filter={filterFactory()}
              noDataIndication="No existen registros para mostrar"
              {...paginationTableProps}
            />
          </div>
          <div style={{ float: "right" }}><PaginationListStandalone {...paginationProps} /></div>
        </div>
      </div>
    );


    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div style={{ borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5 }}>
                <h4 className="title" style={{ fontWeight: 200, marginTop: 18, marginBottom: 25 }}>Reporte de Eventos Diarios</h4>
                <div className="row">
                  <div className="col-md-3" style={{ marginBottom: 18 }}>


                  
                        {/*<Select value={trabajador} onChange= {this.handleTrabajador} options={this.state.trabajadores} />*/}
                    


                    <ControlLabel>TRABAJADOR </ControlLabel>
                    <input type="checkbox" checked={isTrabajadorEnabled} onChange={() => this.toggleTrabajadorEnabled()} style={{ marginLeft: 10 }} />
                    {/* <Select value={this.state.trabajador} onChange={this.changeTrabajador} options={this.state.trabajadores}/> */}
                    <Select value={isTrabajadorEnabled ? this.state.trabajador : { value: '', label: "Todos" }} onChange={this.changeTrabajador}
                      options={this.state.trabajadores} isDisabled={!isTrabajadorEnabled} />
                  </div>
                  <div className="col-md-2" style={{ marginBottom: 18 }}>
                    <ControlLabel>FECHA DE INICIO</ControlLabel>
                    <FormControl type="date" value={this.state.fechaini} onChange={this.handleFechaini} />
                  </div>
                  <div className="col-md-2" style={{ marginBottom: 18 }}>
                    <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                    <FormControl type="date" value={this.state.fechafin} onChange={this.handleFechafin} />
                  </div>
                  <div className="col-md-2" style={{ marginBottom: 18 }}>
                    <ControlLabel>REGIMEN LABORAL<a style={{ color: "red" }}> </a></ControlLabel>
                    <input type="checkbox" checked={isRegimenEnabled} onChange={() => this.toggleRegimenEnabled()} style={{ marginLeft: 10 }} />
                    {/*<Select value={regimen} onChange={this.changeRegimen} options={this.state.regimenes}/>*/}
                    <Select value={isRegimenEnabled ? this.state.regimen : { value: '', label: "Todos" }} onChange={this.changeRegimen}
                      options={this.state.regimenes} isDisabled={!isRegimenEnabled} />
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{ width: 150, marginTop: 0, marginRight: 10, marginBottom: 10 }} bsStyle="success" fill type="submit" onClick={() => { this.listarXfechas() }}>Buscar</Button>                    
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ControlLabel>EVENTO </ControlLabel>
                    <input type="checkbox" checked={isEventoEnabled} onChange={() => this.toggleEventoEnabled()} style={{ marginLeft: 10 }} />
                    {/* <Select value={this.state.evento} onChange={this.changeEvento} options={this.state.eventos}/> */}
                    <Select value={isEventoEnabled ? this.state.evento : { value: '', label: "Todos" }} onChange={this.changeEvento}
                      options={this.state.eventos} isDisabled={!isEventoEnabled} />
                  </div>         

                  <div className="col-md-4" style={{marginBottom: 18}}>
                    <ControlLabel>LISTA DE ÁREAS</ControlLabel>
                    <input type="checkbox" checked={isAreaEnabled} onChange={() => this.toggleAreaEnabled()} style={{ marginLeft: 10 }} />
                    <Select value={isAreaEnabled ? this.state.area : { value: '', label: "Todos" }} onChange={this.changeArea} 
                    options={this.state.areas} isDisabled={!isAreaEnabled}/>
                  </div>
                  <div className="col-md-2" style={{ marginBottom: 18 }}>                    
                  </div>
                  <div className="col-md-3" style={{ marginBottom: 18 }}>
                    <ExcelFile element={<Button style={{ width: 150, marginRight: 10 }} bsStyle="success" /*pullRight*/ fill type="submit">Descargar Excel</Button>}>
                      <ExcelSheet dataSet={this.state.multiDataSet} name="Reporte Consolidados" />
                    </ExcelFile>
                    </div>
                </div>

                {this.state.isLoading ? (
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={50}
                    color={'#123abc'}
                    loading={this.state.loading}
                  />
                ) : (
                  <PaginationProvider
                    pagination={
                      paginationFactory(options)
                    }
                  >
                    { contentTable }
                  </PaginationProvider>
                )}

              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Consolidado;
