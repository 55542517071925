import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';

import Button from "components/CustomButton/CustomButton.jsx";

import { validator } from 'store/Apis';
import ReactTooltip from 'react-tooltip';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Calendar from "components/Calendar/Calendar.jsx";

import moment from 'moment'
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import axios from 'axios';
import Select from 'react-select';
import { paramTesoreria, tesoreria } from 'store/Apis'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class AudiComprobantes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'comprobantes',
      usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
      token: localStorage.getItem('ConcurToken') != null ? 'Bearer ' + localStorage.getItem('ConcurToken') : 0,
      token2: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
      movimientos: [],
      fechaini: moment(moment().startOf('week').toDate()).format('YYYY-MM-DD'),
      fechafin: moment(moment().endOf('week').toDate()).format('YYYY-MM-DD'),
      isLoading: true
    }
    this.GetActionFormat2= this.GetActionFormat2.bind(this);

    this.handleFechaini = this.handleFechaini.bind(this);
    this.handleFechafin = this.handleFechafin.bind(this);

  }

  handleFechaini(event) {
    this.setState({fechaini: event.target.value});
  }

  handleFechafin(event) {
    this.setState({fechafin: event.target.value});
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
              this.listarMovimientos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listarMovimientos() {
      this.setState({isLoading: true})
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.get(`${tesoreria}` + 'comprobantes/listMovimientos?tipo=0&fechaini=' + this.state.fechaini + '&fechafin=' + this.state.fechafin)
      .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { 
                item: data.item,
                nidmovimiento: data.nid,
                numero: data.cnum_numero, 
                cheque: data.ctxt_cheque_carta,
                registro: data.cnum_siaf,
                orden: data.cnum_orden,
                documento: data.cnum_documento,
                nombre: data.ctxt_nombre,
                concepto: data.ctxt_concepto,
                liquido: data.cnum_liquido != null ? 'S/.    ' + data.cnum_liquido.toFixed(2) : 'S/. 0.00', 
                fecha: Calendar.calendario12(data.dfec_ingreso),
                fecha2: data.dfec_ingreso,
                activo: data.item == 1 ? '1' : '0',
                documentox: data.documento
            })
          })
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
      })
      .catch((error) => { 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  GetActionFormat2(cell, row) {
    if(row != undefined){
      return (
        <div>
            {row.documentox == null || row.documentox == ''? (
            <p style={{fontSize: 14}}>SIN DOCUMENTO</p>) : (
            <i style={{color: "red", marginLeft: -20, cursor: "pointer"}} class="fa fa-file-pdf-o fa-3x" aria-hidden="true" onClick={() => { this.mostrar(row.documentox) }}></i>
            )}
        </div>
      );
    }else{}
  }

  mostrar(ruta){
    window.open(ruta, "_blank")
  }

  render() {
    const columns = [
      {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width: 100}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "numero", text: "NÚMERO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "registro", text: "SIAF", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "orden", text: "ORDEN", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "cheque", text: "GIRO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "nombre", text: "BENEFICIARIO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width: 200}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "concepto", text: "CONCEPTO", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white", width: 400}, headerAlign: (column, colIndex) => 'center', align: 'justify'},
      {dataField: "liquido", text: "TOTAL", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "fecha", text: "FECHA", filter: textFilter(), sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
      {dataField: "detail2", text: "DOCUMENTO", headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat2},
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Comprobantes de Pago</h4>
              <h5 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Búsqueda por Rango de Fechas</h5>
              <div className="row">
                <div className="col-md-2" style={{marginBottom: 18}}>
                    <ControlLabel>FECHA DE INICIO</ControlLabel>
                    <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaini}/>
                </div>
                <div className="col-md-2" style={{marginBottom: 18}}>
                    <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                    <FormControl type="date" value={this.state.fechafin} onChange= {this.handleFechafin}/>
                </div>
                <div className="col-md-5" style={{marginBottom: 18}}>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 200, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.listarMovimientos() }}>Buscar Movimientos</Button>
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AudiComprobantes;