import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import axios from 'axios';
import { gestor,  sisperplus } from 'store/Apis'
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Calendar from "components/Calendar/Calendar.jsx";
import InputFiles from 'react-input-files';
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { validator } from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class EditarDescansoMedico extends Component {

constructor(props) {
super(props);
  this.state = {
    path: 'administrarDesMedicos',
    dni: localStorage.getItem('dni'),
    usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
    id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
    token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
    fechaini: '',
    fechafin: '',
    line:"",
    idperson:"",
    archivos :[],
    centro:"",
    medico:"",
    diagnostico:"",
    isLoading: true,
    isLoading2: true,
    editable: true,
    enlace:"",
    descansos:[],
    descanso :"",
    fechaCreacion:"",
    estado:"",
    fileName:"",
    file:[],
    type:"",
    observacion:"",
    contingencia:[{value:"1", label: "ENFERMEDAD"},{value:"2", label: "MATERNIDAD"}],
    selectedContingencia:"",
    estados: [{value: "P", label: "PENDIENTE"},{value: "A", label: "AUTORIZADO"},{value: "D", label: "DESAUTORIZADO"}],
    estadoorigen:"",
  }
  this.handleCentro = this.handleCentro.bind(this);
  this.handleMedico = this.handleMedico.bind(this);
  this.handleDiagnostico = this.handleDiagnostico.bind(this);
  this.handleObservacion = this.handleObservacion.bind(this);
}
  handleCentro(event) {this.setState({centro: event.target.value});}
  handleMedico(event) {this.setState({medico: event.target.value});}
  handleDiagnostico(event) {this.setState({diagnostico: event.target.value});}

  handleDescanso = descanso => {this.setState( { descanso },);};

  changeContingencia = selectedContingencia =>{ this.setState({selectedContingencia}, () => this.listDescansos());}
  handleObservacion(event) {this.setState({observacion: event.target.value});}
  changeEstado = estado => {this.setState({estado})}

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
              this.validarLocal();
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(JSON.parse(localStorage.getItem("recursoshumanos")).descansoM[0].data !== null){
        this.setState({
            dni: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.dni,
            nombres: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.nombres,
            descanso: {value: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.idTipoDescanso, label: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.nomTipoDescanso},
            fechaini : Calendar.calendario11(JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.fechaIni),
            fechafin : Calendar.calendario11(JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.fechaFin),
            dias: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.dias,
            centro: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.centro,
            medico: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.medico,
            diagnostico: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.diagnostico,
            idperson: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.idperson,
            line: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.line,
            enlace: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[1].enlace,
            fechaCreacion: Calendar.calendario11(JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.fechaCreacion),
            estado: {value: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.idestado, label: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.estado},
            estadoorigen: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.estado,
            observacion: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.observacion,            
            selectedContingencia: {value: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.idcontingencia, label: JSON.parse(localStorage.getItem('recursoshumanos')).descansoM[0].data.descontingencia},
            isLoading: false,
            isLoading2: false,

          })
        this.listDescansos();
    }else{
      this.props.history.push('/administrarPapeletas');
    }
  }

  async listDescansos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    //axios.get(`${sisperplus}` + 'descanso/listTipoDescanso')
    axios.get(`${sisperplus}` + 'descanso/listTipoDescansoxContingencia?tipo=2&idcontingencia=' + this.state.selectedContingencia.value)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.MedicalRestType,
                label: data.Description,
            })
          });
          this.setState({descansos: lista})
          this.listArchivos()
      }else{
        this.setState({descansos: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
   }

   async listArchivos() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'descanso/listArchivos?persona=' + this.state.idperson + '&line='+this.state.line)
    .then((res) => {
      let lista = res.data.response;
        if(lista != null){
        this.setState({archivos: lista, isLoading: false,isLoading2:false})
        }else{
            this.setState({archivos: [], isLoading: false})
            this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
        }
    })
    .catch((error) => { 
    if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
    }else{
        console.log(error);
        this.setState({isLoading: false})
    }
    });
  }


  guardar(){
      if(this.state.type == '' || this.state.type == 'application/pdf' || this.state.type == 'image/jpeg' || this.state.type == 'image/png'){
       if(this.state.fechaini != '' && this.state.fechafin != '' && this.state.selectedContingencia.value != ''){
              this.setState({isLoading: true})
              let fromData = new FormData();
              fromData.append('persona', this.state.idperson);
              fromData.append('line', this.state.line);
              fromData.append('tipodescanso', this.state.descanso.value);
              fromData.append('usuario', this.state.usuario);
              fromData.append('centro', this.state.centro.toUpperCase());
              fromData.append('medico', this.state.medico.toUpperCase());
              fromData.append('diagnostico', this.state.diagnostico.toUpperCase());
              fromData.append('observacion', this.state.observacion.toUpperCase());
              fromData.append('estado', this.state.estado.value);
              axios.defaults.headers.common['Authorization'] = this.state.token
              axios.post(`${sisperplus}` + 'descanso/updateDescansoMedico', fromData)
              .then((response) => {
                var datos = response.data
                if(datos.response != null){         
                  axios.defaults.headers.common['Authorization'] = this.state.token
                  axios.get(`${sisperplus}` + 'descanso/listTop?person=' + this.state.idperson)
                  .then((res) => {
                    let lista = res.data.response;
                      if(this.state.file != ''){
                        this.subirArchivo(lista[0].Person,lista[0].line)
                      }else{
                        this.props.handleClick("tl", "info", "Ha sido actualizado con éxito!", "pe-7s-smile")
                        this.cancelar()
                      }
                  })
                  .catch((error) => { 
                    if(auth0Client.isAuthenticated() === true){
                      auth0Client.signOut()
                      this.props.history.replace('/');
                    }else{
                      console.log(error);
                      this.setState({isLoading: false})
                    }
                  });
                }else{
                    this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
                    this.setState({isLoading: false})
                }
              })
              .catch((error) =>{ 
                if(auth0Client.isAuthenticated() === true){
                  auth0Client.signOut()
                  this.props.history.replace('/');
                }else{
                  console.log(error);
                  this.setState({isLoading: false})
                }
              });
          }else{
            this.props.handleClick("tl", "warning", "Debe completar todos los campos!", "pe-7s-info")
          }
        }else{
          this.props.handleClick("tl", "warning", "El archivo a subir debe ser un pdf o una imagen!", "pe-7s-info")
        }
    }

    subirArchivo(person,line){
      let fromData = new FormData();
      fromData.append('persona', person);
      fromData.append('line', line);
      fromData.append('nombreFile', this.state.fileName);
      fromData.append('usuario', this.state.usuario);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${sisperplus}` + 'descanso/insertArchivo', fromData)
      .then((response) => {
        var datos = response.data.response 
        if(datos.archivo != null){
           this.uploadArchivo(datos.archivo)     
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al subir archivo!", "pe-7s-close")
          this.setState({isLoading: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
    }
   
  uploadArchivo(archivo){
      let anio = new Date().getFullYear()
      let mes = parseInt(new Date().getMonth()) + 1
      if(mes < 10){mes = '0'+ mes}
      let fromData = new FormData();
      fromData.append('usuario', this.state.usuario);
      fromData.append('archivo_file', this.state.file);
      fromData.append('anio', anio);
      fromData.append('mes', mes);
      fromData.append('cnomArchivo', archivo);
      axios.defaults.headers.common['Authorization'] = this.state.token
      axios.post(`${gestor}` + 'document/subirArchivoDes', fromData)
      .then((response) => {
        if(response.data.result == null){
          this.props.handleClick("tl", "info", "Ha sido actualizado con éxito!", "pe-7s-smile")
          this.cancelar();                
        }else{
          this.props.handleClick("tl", "error", "Hubo un problema al guardar archivo!", "pe-7s-close")
          this.setState({isLoading2: false})
        }
      })
      .catch((error) =>{ 
        if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
        }else{
          console.log(error);
          this.setState({isLoading: false})
        }
      });
  }

  confirmarDelete = (data) => {
    confirmAlert({
      title: '¿Seguro que desea eliminar el archivo?',
      message: 'Se eliminará el archivo seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.remove(data)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  remove(data){
    this.setState({isLoading2: true})
    let fromData = new FormData();
    fromData.append('persona', data.Person);
    fromData.append('line', data.Line);
    fromData.append('secuencia', data.Secuence);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${sisperplus}` + 'descanso/eliminarArchivo', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
         this.removerArchivo(data);
      }else{
         this.props.handleClick("tl", "error", "Hubo un problema en la conexión!", "pe-7s-close")
         this.setState({isLoading2: false})
      }
    })
    .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading2: false})
      }
    });
   }

   removerArchivo(archivo){
    let anio = Calendar.calendario10(archivo.XlastDate).substring(0,4)
    let mes = Calendar.calendario10(archivo.XlastDate).substring(5,7)
    let fromData = new FormData();
    fromData.append('usuario', this.state.usuario);
    fromData.append('anio', anio);
    fromData.append('mes', mes);
    fromData.append('cnomArchivo', archivo.FileRoot);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${gestor}` + 'document/bajarArchivoDes', fromData)
    .then((response) => {
    if(response.data.result == null){
        this.listArchivos()
        this.props.handleClick("tl", "info", "Tu archivo ha sido eliminado!", "pe-7s-smile")
        this.setState({isLoading2: false})
    }else{
        this.props.handleClick("tl", "error", "Hubo un problema al guardar archivo!", "pe-7s-close")
        this.setState({isLoading2: false})
    }
    })
    .catch((error) =>{ 
    if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
    }else{
        console.log(error);
        this.setState({isLoading: false})
    }
    });
  }

  async cancelar(){
    if(this.state.enlace == "T"){
      this.props.history.push('/verDescansoTrabajador');     
    }else{
         this.props.history.push('/administrarDesMedicos');
    }  
  }

  subir = (files) => {
      if(files.length !== 0){
          this.setState({file: files[0], fileName: files[0].name, type: files[0].type, visFile: true});
      }
  }

  cerrar(){
      this.setState({visFile: false});
  } 

  editar(enabled){
      if(enabled == true){
          this.setState({editable: false})
      }else{
          this.guardar()
      }
  }

  async limpiar(){
    this.setState({fileName: "", file: ""})
  }

  download(data){
    let datosvisor = [{filename: data.FileName,
                      filesource: data.FileRoot,
                      mes: Calendar.calendario10(data.XlastDate).substring(5,7),
                      anio: Calendar.calendario10(data.XlastDate).substring(0,4),
                      }]
    window.open("https://gestordocumentaltc.sedetc.gob.pe/index.php/s/LSEaMDdFp83SWwX/download?path=%2F&files="  + datosvisor[0].anio + "/" + datosvisor[0].mes + "/" + datosvisor[0].filesource)
  }

  async ver(data){
    let datosvisor={file : data.FileRoot,
               mes : Calendar.calendario10(data.XlastDate).substring(5,7),
               anio : Calendar.calendario10(data.XlastDate).substring(0,4),
               "tipo" : "/descansos/",
               "estado" : "3"
             } 
      // localStorage.setItem('visor', JSON.stringify(data2))
      // this.props.history.push('/visorautorizacion');
      window.open('http://181.177.234.7/papeletas/descansos/' + datosvisor.anio + "/" + datosvisor.mes + "/" + datosvisor.file)
   }
 
  

render() {
       return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
            <Card
                 content={
                  this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                  <div>
                   <h4 className="title" style={{ marginBottom: 25}}>DESCANSO MÉDICO DE  <a style={{fontWeight: "bold", color: "black",fontSize: 21}}>{this.state.nombres}</a></h4>
                    <div className="row">
                        <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>DNI</ControlLabel>
                            <FormControl type="text" value={this.state.dni} disabled/>
                        </div>
                        <div className="col-md-6" style={{marginBottom: 18}}>
                            <ControlLabel>NOMBRES Y APELLIDOS</ControlLabel>
                            <FormControl type="text" value={this.state.nombres} disabled/>
                        </div>
                        <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DE CREACIÓN</ControlLabel>
                            <FormControl type="date" value={this.state.fechaCreacion} disabled/>
                        </div>
                        <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>AUTORIZACIÓN</ControlLabel>
                            {/* <FormControl type="text" value={this.state.estado} disabled/> */}
                            <Select value={this.state.estado} onChange={this.changeEstado} options={this.state.estados} isDisabled={this.state.editable}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>CONTINGENCIA</ControlLabel>
                            <Select value={this.state.selectedContingencia} onChange={this.changeContingencia} options={this.state.contingencia} isDisabled/>
                        </div>
                        <div className="col-md-3" style={{marginBottom: 18}}>
                            <ControlLabel>DESCANSO MÉDICO</ControlLabel>
                            <Select value={this.state.descanso} onChange={this.handleDescanso} options={this.state.descansos} isDisabled/>
                        </div>
                        <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DE INICIO</ControlLabel>
                            <FormControl type="date" value={this.state.fechaini} onChange= {this.handleFechaIni} disabled/>
                        </div>
                        <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>FECHA DE TÉRMINO</ControlLabel>
                            <FormControl type="date" value={this.state.fechafin} onChange= {this.handleFechaFin} disabled/>
                        </div>
                        <div className="col-md-2" style={{marginBottom: 18}}>
                            <ControlLabel>DÍAS DE DESCANSO</ControlLabel>
                            <FormControl type="number" value={this.state.dias} disabled/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>CENTRO MÉDICO</ControlLabel>
                            <FormControl componentClass="textarea" style={{ height: 75, maxHeight: 75, minHeight: 75, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.centro} onChange= {this.handleCentro} disabled={this.state.editable}/>  
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>MÉDICO</ControlLabel>
                            <FormControl componentClass="textarea" style={{ height: 75, maxHeight: 75, minHeight: 75, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.medico} onChange= {this.handleMedico} disabled={this.state.editable}/>                      
                         </div>
                         <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>DIAGNÓSTICO</ControlLabel>
                            <FormControl componentClass="textarea" style={{ height: 120, maxHeight: 120, minHeight: 120, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.diagnostico} onChange= {this.handleDiagnostico} disabled={this.state.editable}/>
                        </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6" style={{marginBottom: 18}}>
                            <ControlLabel>OBSERVACIÓN <a>(OPCIONAL)</a></ControlLabel>
                            <FormControl componentClass="textarea" style={{ height: 75, maxHeight: 75, minHeight: 75, width: "100%", maxWidth:"100%", minWidth:"100%" }} value={this.state.observacion} onChange= {this.handleObservacion} disabled={this.state.editable}/>  
                        </div>
                    </div>
                    <div className="row" style={{marginTop: 18, marginBottom: 20}}>
                       <div className="col-md-3" style={{marginBottom: 18}}></div>
                        <div className="col-md-6" style={{marginBottom: 18}}>
                            <ControlLabel>ARCHIVOS SUBIDOS</ControlLabel>
                            {this.state.isLoading2 ? (
                            <ClipLoader
                                css={override}
                                sizeUnit={"px"}
                                size={50}
                                color={'#123abc'}
                                loading={this.state.isLoading2}
                            />
                            ) : (
                            this.state.archivos.length != 0 ? (
                            <ul className="todo-list">
                            {this.state.archivos.map((data,index) =>
                                <li key={index}>
                                    <div className="price">
                                        <h3>{data.item}</h3>
                                    </div>
                                    <div className="name">
                                        <span className="item-name">{data.filename}</span>
                                        <span className="item-price">{data.idpapeleta}</span>
                                    </div>      
                                    <span className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20, marginRight: 5}} onClick={() => { this.download(data) }}>
                                        <i className="fa fa-circle fa-stack-2x" style={{color:"#1B4F72"}}></i>
                                        <i className="fa fa-download fa-stack-1x fa-inverse" style={{fontSize: 18, color:"white"}}></i>
                                    </span>                          
                                    <span   className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20, marginRight: 5}} onClick={() => { this.ver(data) }}>
                                        <i className="fa fa-circle fa-stack-2x text-danger"></i>
                                        <i className="fa fa-file-pdf-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                    </span>
                                    {this.state.estadoorigen != 'AUTORIZADO' ? (
                                    <span   className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(data) }}>
                                        <i className="fa fa-circle fa-stack-2x"></i>
                                        <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
                                    </span>
                                    ) : (null)}
                                </li>
                                )
                            }
                            </ul>): (null)
                            )}
                            <Alert bsStyle="warning">
                                <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.fileName}</span>
                            </Alert>
                            {this.state.editable == false ? (
                              <Button style={{width: '100%', marginTop: -10}} bsStyle="success" pullRight fill type="submit" >
                                <InputFiles accept={'pdf/*'} onChange={files => this.subir(files)}>Dale click aquí para seleccionar el archivo</InputFiles>
                              </Button>
                            ): (null)}
                            {this.state.fileName != "" ? (
                                <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar Archivo</Button>
                            ) : (  null )}
                            <h4 className="category" style={{fontSize: 15, marginTop: 5}}>Los documentos adjuntos de tu descanso médico se reflejaran en el sistema despues de 15 minutos de haberse subido debido a la sincronización con la web.</h4>
                        </div>
                    </div>
                     <Button style={{width: 200, marginLeft: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.cancelar() }}>Cancelar Actualización</Button>
                      {this.state.estadoorigen != 'AUTORIZADO' ? (
                      this.state.editable == true ? (
                         <Button style={{width: 200, marginLeft: 10}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.editar(this.state.editable) }}>Editar Registro</Button>): (
                         <Button style={{width: 200, marginLeft: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.editar(this.state.editable) }}>Guardar Edición</Button>)
                      ): (null)} 
                  </div>
                 )}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EditarDescansoMedico;