import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import {cec} from 'store/Apis'
import 'react-tabs/style/react-tabs.css';
import InputFiles from 'react-input-files';

import 'react-accessible-accordion/dist/fancy-example.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class CrearCuadernos extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'publicaciones',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      titulo : "", 
      descripcion:"",
      tamano:"",
      archivo: "",
      file: [],
      archivo2:"",
      file2:[],
      estado: 0,
      isLoading: false,
      show: true,
      seccion: "1",
    
    }
    this.handleTitulo = this.handleTitulo.bind(this);
    this.handleDescripcion = this.handleDescripcion.bind(this);
  }
    handleTitulo(event) {this.setState({titulo: event.target.value});}
    handleDescripcion(event) {this.setState({descripcion: event.target.value});}
   

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  
  async validar(){
   if(this.state.titulo != ''  &&  this.state.archivo != ''  && this.state.archivo2 != ''){
     this.agregar();
    }else{
      this.props.handleClick("tl", "error", "Debe completar los campos solicitados!", "pe-7s-close") 
    }
  }

  async agregar(){  
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('file', this.state.file);
    fromData.append('file2', this.state.file2);
    fromData.append('titulo', this.state.titulo);
    fromData.append('descripcion', this.state.descripcion);
    fromData.append('tamano', this.state.tamano);
    fromData.append('imagen', this.state.archivo)
    fromData.append('archivo', this.state.archivo2)
    fromData.append('seccion', this.state.seccion);
    fromData.append('usuario',this.state.usuario);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'contenido/insertarContenido', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        this.props.handleClick("tl", "success", "Registrado correctamente!", "pe-7s-close")
          this.regresar();
      }else{
          this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
          this.setState({isLoading: false})
      }
      })
      .catch((error) =>{ 
      if(auth0Client.isAuthenticated() === true){
          auth0Client.signOut()
          this.props.history.replace('/');
      }else{
          console.log(error);
          this.setState({isLoading: false})
      }
    });
  }

  async regresar(){
    this.props.history.push('/normatividad');
  }



  subir2 = (files2) => {
    if(files2.length !== 0){
        if(files2[0].size < 999999){
        this.setState({file2: files2[0], archivo2: files2[0].name, tamano: (files2[0].size/1024).toFixed(2) + " KB"});
        }else{
        this.setState({file2: files2[0], archivo2: files2[0].name, tamano: (files2[0].size/1024/1024).toFixed(2)+ " MB"});
        }
    }
  }
  async limpiar2(){
    this.setState({archivo2: "", file2: "" , tamano : ""})
  }


  subir = (files) => {
    console.log(files)
    console.log(files.length)
      if(files.length !== 0){
          this.setState({file: files[0], archivo: files[0].name});
      }
  }
  async limpiar(){
    this.setState({archivo: "", file: ""})
  }

  render() {
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>NUEVO REGISTRO</h4>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                  <div>
                        <div className="row">
                            <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>TÍTULO</ControlLabel>
                                <FormControl type="text" value={this.state.titulo} onChange= {this.handleTitulo}/>
                            </div>
                            <div className="col-md-12" style={{marginBottom: 18}}>
                                <ControlLabel>DESCRIPCIÓN</ControlLabel>
                                <FormControl type="text" value={this.state.descripcion} onChange= {this.handleDescripcion}/>
                            </div>
                        </div>   
                        <div className="row"> 
                           <div className="col-md-5">
                               <ControlLabel >IMAGEN DE PORTADA</ControlLabel>
                                <Alert bsStyle="info">
                                    <span style={{letterSpacing: 1}}><b> Imagen: </b> {this.state.archivo}</span>
                                </Alert>
                                <Button style={{width: '100%', marginTop: -10}} bsStyle="info" pullRight fill type="submit">
                                    <InputFiles  onChange={files => this.subir(files)}>Dale click aquí para seleccionar la imagen</InputFiles>
                                </Button>
                                 {this.state.archivo != "" ? (
                                <Button style={{width: '100%', marginTop:10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar() }}>Limpiar Archivo</Button>
                                ) : (null)}
                            </div>
                       <div className="col-md-5" >
                            <ControlLabel>DOCUMENTO PDF </ControlLabel>
                              <Alert bsStyle="warning" >
                                <span style={{letterSpacing: 1}}><b> Archivo: </b> {this.state.archivo2}</span>
                              </Alert>
                            <Button style={{width: '100%', marginTop: -10 }} bsStyle="warning" pullRight fill type="submit">
                              <InputFiles accept={'pdf/*'} onChange={files2 => this.subir2(files2)}>Dale click aquí para seleccionar el archivo</InputFiles>
                            </Button>
                             {this.state.archivo2 != "" ? (
                              <Button style={{width: '100%', marginTop: 10}} bsStyle="danger" type="submit" onClick={() => { this.limpiar2() }}>Limpiar Archivo</Button>
                              ) : (null)}
                          </div>
                          <div className="col-md-2">
                            <ControlLabel >TAMAÑO DEL DOCUMENTO</ControlLabel>
                            <FormControl type="text" value={this.state.tamano} disabled/>
                          </div>
                          
                       </div>
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Cancelar Registro</Button>
                        <Button style={{marginRight: 10, marginTop: 20}} bsStyle="success" pullRight fill type="submit" onClick={() => { this.validar() }}>Guardar Registro</Button>
                  </div>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
export default CrearCuadernos;