import React, { Component } from "react";
import { FormControl, ControlLabel, Grid, Row, Col, Alert } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import auth0Client from 'login/Auth';
import axios from 'axios';
import { gestor, sisper, sisperplus } from 'store/Apis'

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import InputFiles from 'react-input-files';
import Select from 'react-select';
import moment from 'moment';
import Swal from 'sweetalert2'
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import CheckboxGroup from 'react-checkbox-group'
import { validator } from 'store/Apis'
import ReactTooltip from 'react-tooltip';
import { data } from "jquery";
import { confirmAlert } from 'react-confirm-alert';
import "react-confirm-alert/src/react-confirm-alert.css";
import { Unidades } from "views/efectivo/conversor";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class AsignacionJefes extends Component {

constructor(props) {
super(props);
  this.state = {
    path: 'administrador',
    dni: localStorage.getItem('dni'),
    nidarea:  JSON.parse(localStorage.getItem('datos'))[0].nidarea,
    usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
    id: localStorage.getItem('datos') != null ? JSON.parse(localStorage.getItem('datos'))[0].Person : 0,
    token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
    descripcion :"",
    name:"",
    referencia: "",
    jefeF: "",
    jefeS: "",
    jefes: [],
    nid:"",
    isLoading:true
   

  }
  
  this.handleDescripcion = this.handleDescripcion.bind(this);
  this.handleName = this.handleName.bind(this);
  this.handleReferencia = this.handleReferencia.bind(this);


}

handleDescripcion(event) {this.setState({descripcion: event.target.value});}
handleName(event) {this.setState({name: event.target.value});}
handleReferencia(event) {this.setState({referencia: event.target.value});}


handleJefeF = jefeF => {
    this.setState(
    { jefeF },
    );
};

handleJefeS = jefeS => {
    this.setState(
    { jefeS },
    );
};

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
              this.listJefeF();
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  async listJefeF() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'area/listTrabajadoresJefatura?dni='+ this.state.dni)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { value: data.idPerson,
                label: data.nombre,
            })
          });
          this.setState({jefes: lista})
          this.listJefesxArea()
    }else{
        this.setState({jefes: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
   }

   async listJefesxArea() {
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'area/listJefesxArea?descripcion='+ this.state.nidarea)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push(
              { 
                personJP: data.person1,
                nameJP: data.personname1,
                personJS: data.person2,
                nameJS: data.personname2
            })
          });
          this.setState({jefeF:{value:lista[0].personJP, label: lista[0].nameJP},
            jefeS: {value:lista[0].personJS, label: lista[0].nameJS}, isLoading: false })        
    }else{
        this.setState({jefes: [], isLoading: false})
        this.props.handleClick("tl", "error", "Error de Conexión!", "pe-7s-info")
      }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
   }




      // confirmarUpdate = (data) => {
      //   confirmAlert({
      //     title: <h3>¿Seguro que desea asignar la jefatura?</h3>,
      //     message: 'Recuerde que la sesión se cerrará automáticamente luego de realizar el cambio de jefatura. Vuelva a ingresar para ver los cambios',
      //     buttons: [
      //       {
      //         label: 'Aceptar',
      //         onClick: () => this.confirmar2(data)
      //         // onClick: () => this.actualizarReplUnit(data)
      //       },
      //       {
      //         label: 'Cancelar',
      //         onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
      //       }
      //     ]
      //   });
      // };

      // confirmar2 = (data) => {
      //   let countdown = 5; // Inicializamos el contador en 3 segundos
      
      //   const swal = Swal.fire({
      //     title: "Se están generando los registros del año " + this.state.anio.label + " ?",
      //     text: "La sesión se cerrará en " + countdown + " segundos",
      //     showCancelButton: false,
      //     showCloseButton: false,
      //     showConfirmButton: false,
      //     didOpen: () => {
      //       Swal.showLoading();
      //       const timer = setInterval(() => {
      //         countdown--; 
      //         if (countdown > 0) {    
      //           swal.update({
      //             text: "La sesión se cerrará en " + countdown + " segundos",
      //           });
      //         } else {
      //           clearInterval(timer);
      //           this.actualizarReplUnit(data);
      //         }
      //       }, 1000); 
      //     },
      //     allowOutsideClick: false,
      //   });
      // };






      // actualizarReplUnit(){
      //     this.setState({isLoading: true})
      //     let fromData = new FormData();
      //     fromData.append('nid', this.state.nidarea);
      //     fromData.append('descripcion',"");
      //     fromData.append('nameArea', "");
      //     fromData.append('referencia',"");
      //     fromData.append('firstperson', this.state.jefeF.value);
      //     fromData.append('secondperson', this.state.jefeS.value);
      //     fromData.append('namefirstperson', this.state.jefeF.label);
      //     fromData.append('namesecondperson', this.state.jefeS.label);
      //     fromData.append('usuario', "");
      //     axios.defaults.headers.common['Authorization'] = this.state.token
      //     axios.post(`${sisperplus}` + 'area/editarArea', fromData)
      //      .then((response) => {
      //           var datos = response.data
      //           if(datos.response != null){
      //               this.actualizarJefeSystem()
      //           }else{
      //               this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
      //               this.setState({isLoading: false})
      //           }
      //           })
      //           .catch((error) =>{ 
      //           if(auth0Client.isAuthenticated() === true){
      //               auth0Client.signOut()
      //               this.props.history.replace('/');
      //           }else{
      //               console.log(error);
      //               this.setState({isLoading: false})
      //           }
      //         });
      //   }

      //   async actualizarJefeSystem() {
      //     let fromData = new FormData();
      //     fromData.append('dni', this.state.dni);
      //     fromData.append('nidperson', this.state.jefeF.value);
      //     fromData.append('usuario', this.state.usuario);
      //     axios.defaults.headers.common['Authorization'] = this.state.token
      //     axios.post(`${sisperplus}` + 'area/updateJefeSystem', fromData)
      //      .then((response) => {
      //           var datos = response.data
      //           if(datos.response != null){
      //               this.props.handleClick("tl", "success", "Jefes actualizados correctamente!", "pe-7s-close")
      //               this.setState({isLoading: false})
      //               auth0Client.signOut()
      //               this.props.history.replace('/');
      //           }else{
      //               this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
      //               this.setState({isLoading: false})
      //           }
      //           })
      //           .catch((error) =>{ 
      //           if(auth0Client.isAuthenticated() === true){
      //               auth0Client.signOut()
      //               this.props.history.replace('/');
      //           }else{
      //               console.log(error);
      //               this.setState({isLoading: false})
      //           }
      //         });


      //   }

      confirmarUpdate = (data) => {
        confirmAlert({
          title: <h3>¿Seguro que desea asignar la jefatura?</h3>,
          message: 'Recuerde que la sesión se cerrará automáticamente luego de realizar el cambio de jefatura. Vuelva a ingresar para ver los cambios',
          buttons: [
            {
              label: 'Aceptar',
              onClick: () => {
                this.confirmar2(data)
                           
              }
            },
            {
              label: 'Cancelar',
              onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
            }
          ]
        });
      };
      
      confirmar2 = async (data) => {
        let countdown = 5; 
        let timerElement;
        const swal = Swal.fire({
          title: "La sesión se cerrará en " + '<div id="timer">5</div>' + " segundos",
          html: '',
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
            timerElement = document.getElementById("timer");
            this.actualizarReplUnit(data);
            const timer = setInterval(() => {
              countdown--;
              if (countdown >= 0) {
                timerElement.textContent = countdown; 
              }
              if (countdown === 0) {
                clearInterval(timer);
                auth0Client.signOut()
                this.props.history.replace('/');
                Swal.close();
              }
            }, 1000);
          },
          didRender: () => {
            if (timerElement) {
              timerElement.style.fontWeight = "bold";
              timerElement.style.fontSize = "24px";
            }
          },
          allowOutsideClick: false,
        });
        await swal;
      };


      async actualizarReplUnit(){
        let fromData = new FormData();
        fromData.append('nid', this.state.nidarea);
        fromData.append('descripcion',"");
        fromData.append('nameArea', "");
        fromData.append('referencia',"");
        fromData.append('firstperson', this.state.jefeF.value);
        fromData.append('secondperson', this.state.jefeS.value);
        fromData.append('namefirstperson', this.state.jefeF.label);
        fromData.append('namesecondperson', this.state.jefeS.label);
        fromData.append('usuario', "");
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'area/editarArea', fromData)
         .then((response) => {
              var datos = response.data
              if(datos.response != null){
                  this.actualizarJefeSystem()
              }else{
                  this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
                  this.setState({isLoading: false})
                  Swal.close();  
              }
              })
              .catch((error) =>{ 
              if(auth0Client.isAuthenticated() === true){
                  auth0Client.signOut()
                  this.props.history.replace('/');
                  Swal.close();
              }else{
                  console.log(error);
                  this.setState({isLoading: false})
                  Swal.close();
              }
            });
      }  
      
      async actualizarJefeSystem() {
        let fromData = new FormData();
        fromData.append('dni', this.state.dni);
        fromData.append('nidperson', this.state.jefeF.value);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${sisperplus}` + 'area/updateJefeSystem', fromData)
         .then((response) => {
              var datos = response.data
              if(datos.response != null){
                  this.props.handleClick("tl", "success", "Jefes actualizados correctamente!", "pe-7s-close") 
              }else{
                  this.props.handleClick("tl", "error", "Hubo un problema al actualizar!", "pe-7s-close")
                  this.setState({isLoading: false})
                  Swal.close();  
              }
              })
              .catch((error) =>{ 
              if(auth0Client.isAuthenticated() === true){
                  auth0Client.signOut()
                  this.props.history.replace('/');
                  Swal.close();
              }else{
                  console.log(error);
                  this.setState({isLoading: false})
                  Swal.close();
              }
            });


      }


  

  render() {
  
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
            <Card
                title="Asignación de Jefatura"
                content={
                  this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                  <div>
                    
                    <div className="row">
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>JEFE PRINCIPAL</ControlLabel>
                            <Select value={this.state.jefeF} onChange={this.handleJefeF} options={this.state.jefes} />
                            
                        </div>
                        <div className="col-md-4" style={{marginBottom: 18}}>
                            <ControlLabel>JEFE SECUNDARIO</ControlLabel>
                            <Select value={this.state.jefeS} onChange={this.handleJefeS} options={this.state.jefes} />
                        </div>
                       
                    </div>
                    {/* <Button style={{width: 200, marginLeft: 10}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.listJefesxArea() }}>Cancelar</Button> */}
                    <Button style={{width: 200, marginLeft: 10}} bsStyle="info" pullRight fill type="submit" onClick={() => { this.confirmarUpdate() }}>Asignar</Button>
                  </div>
                 )}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AsignacionJefes;