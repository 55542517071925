import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col,FormControl } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, cec, fechasBusqueda, paramCec } from 'store/Apis'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Listadelineas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'publicaciones',
      usuario: localStorage.getItem('nickname'),
      token: 'Bearer ' + localStorage.getItem('ConcurToken'),
      token2: 'Bearer ' + localStorage.getItem('AccessToken'),
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      descripcion: "",
      isLoading: true,
      nid:"",
    }
    this.GetActionFormat= this.GetActionFormat.bind(this);
    this.handleDescripcion= this.handleDescripcion.bind(this);
    this.verContenido= this.verContenido.bind(this);
 }

    handleDescripcion(event) {this.setState({descripcion: event.target.value});}
 

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null || localStorage.getItem('expires_at') != null || localStorage.getItem('operaciones') != null || localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token2
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
             this.listarLineas();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }

  async listarLineas() {
    this.setState({isLoading: true})
    axios.get(`${cec}` + 'public/listSeccionesPrincipales?seccion=44')
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({
              descripcion: data.ctxt_descripcion,           
              categoria: data.categoria,
              item: data.item,
              nid: data.nid
            })
          })  
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        this.setState({isLoading: false})
      }
    });
  }
  async guardar(){ 
    if(this.state.descripcion!=""){
        this.setState({isLoading: true})
        let fromData = new FormData();
        fromData.append('titulo', this.state.descripcion);
        fromData.append('descripcion', "");
        fromData.append('numseccion', "");
        fromData.append('seccion', 44);
        fromData.append('usuario', this.state.usuario);
        axios.defaults.headers.common['Authorization'] = this.state.token
        axios.post(`${cec}` + 'medios/insertarLineasInvestigacion', fromData)
        .then((response) => {
          var datos = response.data
          if(datos.response != null){
            this.props.handleClick("tl", "success", "Ha sido registrado correctamente!", "pe-7s-close")
            this.cancelar()
              this.listarLineas();
          }else{
              this.props.handleClick("tl", "error", "Hubo un problema al registrar!", "pe-7s-close")
              this.setState({isLoading: false})
          }
          })
          .catch((error) =>{ 
          if(auth0Client.isAuthenticated() === true){
              auth0Client.signOut()
              this.props.history.replace('/');
          }else{
              console.log(error);
              this.setState({isLoading: false})
          }
        });
    }else{
        this.props.handleClick("tl", "warning", "Complete el campo de descripción!", "pe-7s-close")
    }
   
  }

  confirmarDelete = (data, param) => {
      confirmAlert({
      title: '¿Seguro que desea anular el registro?',
      message: 'Se anulará el registro seleccionado.',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.actualizar(data,param)
        },
        {
          label: 'Cancelar',
          onClick: () => this.props.handleClick("tl", "warning", "Se canceló la operación!", "pe-7s-info")
        }
      ]
    });
  };

  async actualizar(data,param){
    this.setState({isLoading: true})
    let fromData = new FormData();
    fromData.append('nid', param==1 ? data.nid :this.state.nid );
    fromData.append('titulo', this.state.descripcion);
    fromData.append('estado', param==1 ? 1 : 0);
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.post(`${cec}` + 'medios/anularLineasInvestigacion', fromData)
    .then((response) => {
      var datos = response.data
      if(datos.response != null){
        if(datos.response.estado == 1){
          this.cancelar()
          this.listarLineas();
          this.props.handleClick("tl", "success", "Se ejecutó el proceso correctamente !", "pe-7s-smile")
      }else{
          this.props.handleClick("tl", "error", "No se puede manejar la publicación!", "pe-7s-close")
          this.setState({isLoading: false})
      }
    }else{
      this.props.handleClick("tl", "error", "Hubo un problema al anular!", "pe-7s-close")
      this.setState({isLoading: false})
    }
  })
  .catch((error) =>{ 
    if(auth0Client.isAuthenticated() === true){
      auth0Client.signOut()
      this.props.history.replace('/');
    }else{
      console.log(error);
      this.setState({isLoading: false})
    }
  });
}

async detalle(data){
 this.setState({
    descripcion: data.descripcion,
    nid : data.nid 
})
console.log(this.state.descripcion)
}

  GetActionFormat(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Editar Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.detalle(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-pencil-square-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
             
              <span data-tip="Anular Registro" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.confirmarDelete(row, 1) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x"></i>
                <i className="fa fa-trash-o fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }

  verContenido(cell, row) {
    if(row != undefined){
      return (
          <div>
              <span data-tip="Ver Contenido" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver(row) }}>
                <ReactTooltip />
                <i className="fa fa-circle fa-stack-2x text-info"></i>
                <i className="fa fa-eye fa-stack-1x fa-inverse" style={{fontSize: 15}}></i>
              </span>
          </div>
      );
    }else{}
  }
save(){
    if(this.state.nid==""){
        this.guardar()
    }else{
        this.actualizar("",0)
    }
}

  async regresar(){
    this.props.history.push('/publicaciones');
  }

  async ver(data){
     paramCec.secciones[0].data = {cnum_seccion:data.categoria}
    localStorage.setItem('cec', JSON.stringify(paramCec))
    this.props.history.push('/listasecciones');
  }

  async cancelar(){
    this.setState({descripcion:"", nid:""})
  }

  render() {
    const columns = [
    {dataField: "item", text: "ITEM", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "descripcion", text: "TÍTULO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "contenido", text: "CONTENIDO", sort: true, headerStyle: {backgroundColor: '#337AB7', color: "white",width:300}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.verContenido},
    {dataField: "detail", text: "ACCIONES", headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center', formatter: this.GetActionFormat}
    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>LÍNEAS DE INVESTIGACIÓN</h4>
              <div className="row">
                <div className="col-md-9" style={{marginBottom: 18}}>
                    <ControlLabel>DESCRIPCIÓN</ControlLabel>
                    <FormControl type="text" value={this.state.descripcion} onChange= {this.handleDescripcion}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 120, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.save() }}>Guardar </Button>
                    <Button style={{width: 120, marginTop: 5, marginBottom: 10,marginRight: 10,}} bsStyle="danger"  type="submit" onClick={() => { this.cancelar() }}>Cancelar</Button>
                </div>
                <div className="col-md-12">
                    <Button style={{width: 250, marginTop: 5,marginBottom: 10}} bsStyle="danger" fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Listadelineas;