import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import auth0Client from 'login/Auth';
import axios from 'axios';

import { Card } from "components/Card/Card.jsx";
import Calendar from "components/Calendar/Calendar.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { validator } from 'store/Apis'

import PDFViewer from 'mgr-pdf-viewer-react'
import Iframe from 'react-iframe'
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class Visor extends Component {

  constructor(props) {
      super(props);
      this.state = {
        path: 'papeletas',
        usuario: localStorage.getItem('nickname') != null ? localStorage.getItem('nickname') : 0,
        token: localStorage.getItem('AccessToken') != null ?  localStorage.getItem('AccessToken') : 0,
        visor: JSON.parse(localStorage.getItem('visor')),
        filename: '',
        filesource: '',
        mes: '',
        anio: '',
        tipo:"",
        isLoading: true
      }
  }

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
      axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
          Authorization: 'Bearer ' + this.state.token
        }
        }).then((response) => {
           let permisos = response.data;
           if(permisos.codError != 1){
              this.props.history.goBack()
           }else{
              this.validarLocal();
           }
        },(error) => {
          if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
          }else{
            this.props.history.goBack()
          }
        });
    }else{
      auth0Client.signOut();
      this.props.history.replace('/');
    }
  }

  async validarLocal(){
    if(this.state.visor == null){
      this.props.history.goBack();
    }else{
      this.setState({
        filename: this.state.visor[0].filename,
        filesource: this.state.visor[0].filesource,
        mes: this.state.visor[0].mes,
        anio: this.state.visor[0].anio,
        tipo :this.state.visor[0].tipo == undefined ? this.state.visor.tipo : this.state.visor[0].tipo,
        isLoading: false
      });
      console.log("http://181.177.234.7/papeletas"+this.state.tipo + this.state.anio + "/" + this.state.mes + "/" + this.state.filesource)
    
    }
  }

  async regresar(){
    if(this.state.visor[0].estado=="1"){
     this.props.history.push('/detalle');  
    } else if(this.state.visor[0].estado=="3"){
      this.props.history.push('/vacacionesDetalle');  
    }
     else{
       this.props.history.push('/verPapeletaAdm');
     }
     localStorage.removeItem('visor')
   }

   
  render() {
    const { filename } = this.state;
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18}}>Visualiza tu documento {filename}</h4>
              <Button style={{width: 200,marginTop:18,marginLeft:5,marginBottom:18}} bsStyle="danger" pullRight fill type="submit" onClick={() => { this.regresar() }}>Regresar</Button>   

                 {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.isLoading}
                      />
                    ) : (
                      <Iframe url= {"http://181.177.234.7/papeletas"+this.state.tipo + this.state.anio + "/" + this.state.mes + "/" + this.state.filesource}
                        width="100%"
                        height="1200px"
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative"
                      />
                 )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(Visor);